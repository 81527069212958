import { Checkbox, DialogActions, DialogContent, Grid, IconButton, makeStyles, TextField, Theme, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography/Typography";
import { Close, Warning } from "@material-ui/icons";
import withTheme from "@material-ui/styles/withTheme";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { ActiveState, Contract } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import SelectAccount from "../accountComponents/SelectAccount";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import ConfirmDeleteDialog from "../deleteComponent/ConfirmDeleteDialog";
import SelectContractSupplier from "./SelectContractSupplier";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export interface Props {
  contract: Contract | undefined;
  open: boolean;
  onClose: (mutatedContract: Contract | null) => void;
  handleNewContract?: boolean;
}

const EditContractDialog: React.FC<Props> = ({ contract, open, onClose, handleNewContract }) => {
  handleNewContract = handleNewContract ?? false;
  const languageContext = useLanguageContext();
  const accountContext = useAccountContext();
  const classes = useStyles();
  const userRoleContext = useUserRoleContext();
  const users = userRoleContext.getUsers();

  const [contractToEdit, setContractToEdit] = useState<Contract | undefined>();
  const procurementIsLargerThenBudget = (contractToEdit?.procurement ?? 0) > (contractToEdit?.budget ?? 0);

  const handleOnClickSave = () => {
    const mutatedContract: Contract = { ...contractToEdit };
    console.log("NEw contract: ", mutatedContract);
    if (!contractToEdit?.accountId) {
      console.log("No account selected");
      return;
    }
    onClose(mutatedContract);
  };

  const handleOnClickClose = () => {
    onClose(null);
  };

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      const mutatedContract = { ...contract };
      mutatedContract.state = ActiveState.INACTIVE;
      onClose(mutatedContract);
    }
    setOpenConfirmDeleteDialog(false);
  };

  useEffect(() => {
    if (contract) {
      setContractToEdit({ ...contract });
    }
  }, [contract]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth={"xs"} onClose={handleOnClickClose} open={open} disableBackdropClick={true}>
        <DialogTitle>
          {handleNewContract ? languageContext.getMessage("newContract") : languageContext.getMessage("editContract")}
          <IconButton className={classes.closeButton} onClick={handleOnClickClose} title={languageContext.getMessage("cancel")}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectAccount
                selectedAccount={accountContext.getAccount(contractToEdit?.accountId)}
                onSelectedAccountChange={(newSelectedAccount) => {
                  // setAccountId(newSelectedAccount?.id);
                  setContractToEdit({ ...contractToEdit, accountId: newSelectedAccount?.id });
                }}
                label={`${languageContext.getMessage("account")} *`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage("name")}
                defaultValue={contractToEdit?.name}
                onChange={(event) => setContractToEdit({ ...contractToEdit, name: event.target.value })}
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <SelectContractSupplier
                selectedContract={{ supplier: contractToEdit?.supplier ?? "" }}
                selectedAccount={undefined}
                onSelectedSupplierChange={(newSelectedSupplier) => {
                  // setSupplier(newSelectedSupplier ?? '')
                  setContractToEdit({ ...contractToEdit, supplier: newSelectedSupplier ?? "" });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyInput
                label={languageContext.getMessage("contractBudget")}
                value={contractToEdit?.budget ?? 0}
                onChange={(amount) => setContractToEdit({ ...contractToEdit, budget: amount })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyInput
                label={
                  <>
                    {languageContext.getMessage("contractProcurement")}
                    {procurementIsLargerThenBudget && (
                      <>
                        &nbsp;
                        <Tooltip title={languageContext.getMessage("warningContractProcurementIsLargerThenBudget")}>
                          <Warning fontSize={"small"} />
                        </Tooltip>
                      </>
                    )}
                  </>
                }
                value={contractToEdit?.procurement ?? 0}
                onChange={(amount) => {
                  if (amount > (contractToEdit?.budget ?? 0)) {
                    setContractToEdit({ ...contractToEdit, procurement: amount, useContractProcurementWithForecast: true });
                  } else {
                    setContractToEdit({ ...contractToEdit, procurement: amount });
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"caption"}>{languageContext.getMessage("includeContractProcurementInForecast")}</Typography>
              <Checkbox
                disabled={procurementIsLargerThenBudget}
                checked={procurementIsLargerThenBudget ? true : contractToEdit?.useContractProcurementWithForecast}
                onChange={(event, checked) => setContractToEdit({ ...contractToEdit, useContractProcurementWithForecast: checked })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage("description")}
                multiline
                value={contractToEdit?.contractDescription}
                onChange={(event) => setContractToEdit({ ...contractToEdit, contractDescription: event.target.value })}
                fullWidth
              />
            </Grid>
            {!handleNewContract && (
              <Grid item xs={12}>
                <Typography variant="body2">{`${languageContext.getMessage("lastChangedBy")} ${users.find((user) => user.id === contractToEdit?.changedBy)?.name}`}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!handleNewContract && (
            <Button onClick={handleOnClickDelete} color="primary" variant="contained">
              {languageContext.getMessage("delete")}
            </Button>
          )}
          <Button onClick={handleOnClickSave} color="primary" variant="contained" disabled={contractToEdit?.accountId === undefined}>
            {languageContext.getMessage("save")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog title={languageContext.getMessage("doYouWantToDeleteContract")} open={openConfirmDeleteDialog} onClose={async (deleteIsConfirmed) => handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)} />
    </>
  );
};

export default withTheme(EditContractDialog);
