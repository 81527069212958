import { Button, Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import DateRangePicker from "../../component/dateComponents/DateRangePicker";
import AccrualAndOperationDetailsView from "./AccrualAndOperationsDetailsView";
import AccrualAndOperationOverview from "./AccrualAndOperationsOverview";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { Account, AccrualAndOperations, DocumentType } from "../../contracts/contracts";
import AccrualAndOperationAllView from "./AccrualAndOperationsAllView";
import ViewHeading from "../../component/viewComponents/ViewHeading";
import { useAccrualAndOperationsContext } from "../../contexts/accrualAndOperations/accrualAndOperationsContext";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { datesAreOnSameMonth, getStartOfMonth } from "../../utils/dateTools";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    titleSection: {
        margin: theme.spacing(3, 2),
    },
    dateRangePickerSection: {
        margin: theme.spacing(3, 2, 0),
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const AccrualAndOperationsView: React.FC<{}> = () => {

    const urlContext = useUrlContext();
    const languageContext = useLanguageContext();
    const classes = useStyles();
    const accountContext = useAccountContext();
    const accrualAndOperationContext = useAccrualAndOperationsContext();
    const projectContext = useProjectContext();
    const ticketContext = useTicketContext();

    const urlState = urlContext.getUrlState();
    const tabIndex = urlState.tab ? urlState.tab as string : 'all';
    const urlStateIsEditing = (urlState.isEditing ? urlState.isEditing as string : 'false') === 'true';

    const accountSearch = accountContext.getAccountSearch();
    const accrualAndOperationSearch = accrualAndOperationContext.getAccrualAndOperationsSearch();

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.ACCOUNT,
            DocumentType.ACCRUAL_AND_OPERATIONS,
            DocumentType.ACCRUAL_AND_OPERATIONS_EXPENSES]);
        accountContext.searchAccounts(accountSearch);
        accrualAndOperationContext.searchAccrualAndOperations(accrualAndOperationSearch);
    }, [urlContext.currentLocation, projectContext.getSelectedProject()])

    const selectedAccount = accountContext.getAccount(accrualAndOperationSearch.parentDocumentId);

    const updateUrlState = (newAccrualAndOperationSearch: AccrualAndOperations,
        newTabIndex: string,
        isEditing: boolean): void => {
        newAccrualAndOperationSearch = { ...newAccrualAndOperationSearch }
        const newUrlState = {
            ...urlState,
            ...newAccrualAndOperationSearch,
            ...{ 'tab': newTabIndex },
            ...{ isEditing: isEditing },
        }
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
        urlContext.pushUrlQuery(urlQuery);
    }

    const handleTabIndexChange = (event: React.ChangeEvent<{}> | null, newTabIndex: string) => {
        updateUrlState(accrualAndOperationSearch, newTabIndex, urlStateIsEditing);
    };

    const handleToAndFromDateLimit = (newAccrualAndOperationSearch: AccrualAndOperations) => {
        if (!newAccrualAndOperationSearch.fromDate || !newAccrualAndOperationSearch.toDate) {
            return;
        }
        if (getStartOfMonth(newAccrualAndOperationSearch.fromDate) > getStartOfMonth(newAccrualAndOperationSearch.toDate)) {
            newAccrualAndOperationSearch.fromDate = new Date(newAccrualAndOperationSearch.toDate);
        }
    }

    const updateAccrualAndOperationSearch = (newAccrualAndOperationSearch: AccrualAndOperations, 
                                             newTabIndex?: string,
                                             isEditing?: boolean): void => {
        handleToAndFromDateLimit(newAccrualAndOperationSearch);
        newAccrualAndOperationSearch.fromDateIsEmpty = newAccrualAndOperationSearch.fromDate ? false : true;
        newAccrualAndOperationSearch.toDateIsEmpty = newAccrualAndOperationSearch.toDate ? false : true;
        updateUrlState(newAccrualAndOperationSearch, newTabIndex ?? tabIndex, isEditing ?? urlStateIsEditing);
    }

    const onSelectedAccountChange = (newSelectedAccount: Account | undefined, newTab: string): void => {
        accrualAndOperationSearch.parentDocumentId = newSelectedAccount?.id;
        updateUrlState(accrualAndOperationSearch, newTab, urlStateIsEditing);
    };

    let tabHeadingTitle = `${languageContext.getMessage('accrualAndOperations')}`
    if (tabIndex === 'overview') {
        tabHeadingTitle = `${languageContext.getMessage('accrualAndOperations')}`
    }

    const popDateMonth = (popToDate: boolean) => {
        const defaultDate = popToDate ? (accrualAndOperationSearch.toDate ?? accrualAndOperationSearch.fromDate) : (accrualAndOperationSearch.fromDate ?? accrualAndOperationSearch.toDate);
        const oldDate = new Date(defaultDate ?? new Date());
        const newDate = new Date(oldDate.setMonth(oldDate.getMonth() - 1))
        if (popToDate) {
            accrualAndOperationSearch.toDate = newDate;
        }
        else {
            accrualAndOperationSearch.fromDate = newDate;
        }
        updateAccrualAndOperationSearch(accrualAndOperationSearch);
    }

    const pushDateMonth = (pushToDate: boolean) => {
        const defaultDate = pushToDate ? (accrualAndOperationSearch.toDate ?? accrualAndOperationSearch.fromDate) : (accrualAndOperationSearch.fromDate ?? accrualAndOperationSearch.toDate);
        const oldDate = new Date(defaultDate ?? new Date());
        const newDate = new Date(oldDate.setMonth(oldDate.getMonth() + 1))
        if (pushToDate) {
            accrualAndOperationSearch.toDate = newDate;
        }
        else {
            accrualAndOperationSearch.fromDate = newDate;
        }
        updateAccrualAndOperationSearch(accrualAndOperationSearch);
    }

    const updateStartAndEndDateInAccrualAndOperationSearch = (newStartDate: Date | undefined, newEndDate: Date | undefined) => {
        if (newStartDate) {
            newStartDate = new Date(newStartDate.setDate(1));
        }
        if (newEndDate) {
            newEndDate = new Date(newEndDate.setDate(1));
        }
        accrualAndOperationSearch.fromDate = newStartDate;
        accrualAndOperationSearch.toDate = newEndDate;
    };

    const updateStartAndEndDate = (newStartDate: Date | undefined, newEndDate: Date | undefined, 
                                   isEditing?: boolean) => {
        updateStartAndEndDateInAccrualAndOperationSearch(newStartDate, newEndDate);
        updateAccrualAndOperationSearch(accrualAndOperationSearch, tabIndex, isEditing);
    }

    const onEditAccrualAndOperations = (editing: boolean) => {
        const newStartDate = editing ? accrualAndOperations?.firstDate : accrualAndOperationSearch.fromDate;
        const newEndDate = editing ? accrualAndOperations?.lastDate : accrualAndOperationSearch.toDate;
        updateStartAndEndDate(newStartDate, newEndDate, editing);
    };

    const disablePusAndPopDateButtons = getStartOfMonth(accrualAndOperationSearch.fromDate ?? new Date(0)) >= getStartOfMonth(accrualAndOperationSearch.toDate ?? new Date(0));
    const accrualAndOperations = accrualAndOperationContext.getAccrualAndOperations().find(accrual => accrual.parentDocumentId === selectedAccount?.id);
    const language = languageContext.getLanguage();

    const disablePopStartDateButton = !urlStateIsEditing && getStartOfMonth(accrualAndOperationSearch.fromDate ?? new Date(0)) <= getStartOfMonth(accrualAndOperations?.firstDate ?? new Date(0));
    const disablePushEndDateButton = !urlStateIsEditing && getStartOfMonth(accrualAndOperationSearch.toDate ?? new Date(0)) >= getStartOfMonth(accrualAndOperations?.lastDate ?? new Date(0));
    if (disablePopStartDateButton) {
        accrualAndOperationSearch.fromDate = datesAreOnSameMonth(accrualAndOperations?.firstDate ?? new Date(0), new Date(0)) ? undefined : accrualAndOperations?.firstDate;
    }
    if (disablePushEndDateButton) {
        accrualAndOperationSearch.toDate = datesAreOnSameMonth(accrualAndOperations?.lastDate ?? new Date(0), new Date(0)) ? undefined : accrualAndOperations?.lastDate;
    }

    useEffect(() => {
        let defaultSearchIsUpdated = false;
        if (!accrualAndOperationSearch.fromDate && !accrualAndOperationSearch.fromDateIsEmpty && accrualAndOperations?.id !== undefined) {
            accrualAndOperationSearch.fromDate = new Date(accrualAndOperations?.firstDate ?? new Date(0));
            accrualAndOperationSearch.fromDate = datesAreOnSameMonth(accrualAndOperationSearch.fromDate, new Date(0)) ? undefined : accrualAndOperationSearch.fromDate;
            accrualAndOperationSearch.fromDate = accrualAndOperationSearch.fromDate ? new Date(accrualAndOperationSearch.fromDate.setDate(1)) : undefined;
            defaultSearchIsUpdated = true;
        }
        if (accrualAndOperationSearch.fromDate && !accrualAndOperationSearch.toDate && !accrualAndOperationSearch.toDateIsEmpty && accrualAndOperations?.id !== undefined) {
            accrualAndOperationSearch.toDate = new Date(accrualAndOperations?.lastDate ?? new Date(0));
            accrualAndOperationSearch.toDate = datesAreOnSameMonth(accrualAndOperationSearch.toDate, new Date(0)) ? undefined : accrualAndOperationSearch.toDate;
            accrualAndOperationSearch.toDate = accrualAndOperationSearch.toDate ? new Date(accrualAndOperationSearch.toDate.setDate(1)) : undefined;
            defaultSearchIsUpdated = true;
        }
        if (defaultSearchIsUpdated) {
            updateAccrualAndOperationSearch(accrualAndOperationSearch);
        }
    }, [accrualAndOperations])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
                    <Tabs value={tabIndex} onChange={handleTabIndexChange}>
                        <Tab label={languageContext.getMessage('all')} value='all' />
                        <Tab label={languageContext.getMessage('details')} value='details' />
                        <Tab label={languageContext.getMessage('overview')} value='overview' />
                    </Tabs>
                </Toolbar>
            </Grid>
            <Grid item xs={12}>
                <ViewHeading title={tabHeadingTitle}>
                    {tabIndex !== 'all' &&
                        <Grid container>
                            <Grid item xs={2} >
                                <Button variant="outlined"
                                    disabled={
                                        datesAreOnSameMonth(accrualAndOperations?.firstDate ?? new Date(0), new Date(0)) ||
                                        datesAreOnSameMonth(accrualAndOperations?.lastDate ?? new Date(0), new Date(0))}
                                    onClick={() => {
                                        updateStartAndEndDate(accrualAndOperations?.firstDate, accrualAndOperations?.lastDate);
                                    }} >
                                    {languageContext.getMessage('showAllMonths')}
                                </Button>
                            </Grid>
                            <Grid item xs={10} >
                                <DateRangePicker
                                    fromLabel={`${languageContext.getMessage('from')} - ${accrualAndOperationSearch?.fromDate?.toLocaleString(language, { month: 'long' })}`}
                                    toLabel={`${languageContext.getMessage('to')} - ${accrualAndOperationSearch?.toDate?.toLocaleString(language, { month: 'long' })}`}
                                    startDate={accrualAndOperationSearch.fromDate}
                                    endDate={accrualAndOperationSearch.toDate}
                                    monthPicker={true}
                                    yearPicker={true}
                                    onChange={(newStartDate, newEndDate) => {
                                        updateStartAndEndDate(newStartDate, newEndDate);
                                    }}
                                    onPopStartDate={() => popDateMonth(false)}
                                    onPushStartDate={() => pushDateMonth(false)}
                                    onPopEndDate={() => popDateMonth(true)}
                                    onPushEndDate={() => pushDateMonth(true)}
                                    popStartDateDisabled={disablePopStartDateButton}
                                    pushStartDateDisabled={disablePusAndPopDateButtons}
                                    popEndDateDisabled={disablePusAndPopDateButtons}
                                    pushEndDateDisabled={disablePushEndDateButton} />
                            </Grid>
                        </Grid>
                    }
                </ViewHeading>
            </Grid>
            <Grid item xs={12}>
                {tabIndex === 'all' && <AccrualAndOperationAllView
                    selectedAccount={selectedAccount}
                    onSelectedAccountChange={newSelectedAccount => onSelectedAccountChange(newSelectedAccount, 'details')} />}
                {tabIndex === 'details' && <AccrualAndOperationDetailsView
                    from={accrualAndOperationSearch.fromDate}
                    to={accrualAndOperationSearch.toDate}
                    editingExpenses={urlStateIsEditing}
                    setEditingExpenses={editing => onEditAccrualAndOperations(editing)}
                    selectedAccount={selectedAccount}
                    onSelectedAccountChange={newSelectedAccount => onSelectedAccountChange(newSelectedAccount, 'details')} />}
                {tabIndex === 'overview' && <AccrualAndOperationOverview
                    from={accrualAndOperationSearch.fromDate}
                    to={accrualAndOperationSearch.toDate}
                    selectedAccount={selectedAccount}
                    onSelectedAccountChange={newSelectedAccount => onSelectedAccountChange(newSelectedAccount, 'overview')} />}
            </Grid>
        </Grid>
    );
}

export default withTheme(AccrualAndOperationsView);