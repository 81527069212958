import { Grid, makeStyles, Paper, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Account, AccountType } from "../../contracts/contracts";
import SelectAccount from "../../component/accountComponents/SelectAccount";
import ForecastList from "../../component/forecastComponents/ForecastList";
import ForecastsGraph from "../../component/forecastComponents/ForecastsGraph";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useForecastsContext } from "../../contexts/forecast/forecastsContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { Dictionary } from "../../global-types";
import { ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";


const useStyles = makeStyles(() => ({
    card: {
        padding: '1em'
    }
}));

type props = {
    selectedAccount: Account | undefined;
    onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
}

const ForecastDetailsView: React.FC<props> = ({
    selectedAccount,
    onSelectedAccountChange}) => {
        
    const urlContext = useUrlContext();
    const accountContext = useAccountContext();
    const forecastsContext = useForecastsContext();
    const projectContext = useProjectContext();
    const menuContext = useMenuContext();

    const classes = useStyles();

    const account = accountContext.getAccount(selectedAccount?.id);
    const forecastSearch = forecastsContext.getForecastsSearch();

    useEffect(() => {
        forecastsContext.searchForecasts(forecastSearch);
    }, [urlContext.currentLocation, projectContext.getSelectedProject()])

    const forecasts = forecastsContext.getForecasts(account?.id) ?? {};
    const accountRoute = menuContext.getApplicationRouteById(ApplicationRouteId.Accounts);

    const updateUrlStateToAccountDetails = (newSelectedAccount: Account | undefined): void => {
        const newUrlState = {
            ...{ 'accountId': newSelectedAccount?.id },
            ...{'tab': 'details'}}
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
        urlContext.pushUrlQuery(urlQuery, accountRoute.route);
    }
    
    return (<>
        <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
                <Paper className={classes.card} elevation={4}>
                    <SelectAccount 
                        selectedAccount={account}
                        onSelectedAccountChange={onSelectedAccountChange} />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <ForecastList 
                    forecasts={forecasts.forecasts ?? []}
                    showLastForecast={true}
                    isProjectForecasts={false}
                    onSelectedAccountChange={updateUrlStateToAccountDetails}
                />
            </Grid>
            <Grid item xs={12}>
                <ForecastsGraph 
                    forecasts={forecasts}
                    isProjectForecasts={false}
                    showMonthUnits={true} 
                />
            </Grid>
        </Grid>
    </>
    );
}

export default withTheme(ForecastDetailsView);