export const navigateToExternalUrl = (url: string, shouldOpenNewTab: boolean = true) => {
    shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
}

export const base64UrlEncode = (value: string): string => {
    return btoa(value).replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '.');
}

export const base64UrlDecode = (value: string): string => {
    return atob(value.replaceAll('-', '+').replaceAll('_', '/').replaceAll('.', '='));
}

export const tryDecodeAndSplitFromUrl = (value: string | undefined, splitString: string, useBase64: boolean = false): string[] => {
    try {
        return value ? (useBase64 ? base64UrlDecode(value) : value).split(splitString) : [];
    } catch (error) {
        return value ? (value as string).split(splitString) : [];
    }
}


export const SplitAndEncodeToUrl = (value: string[], splitString: string, useBase64: boolean = false): string => {
    const mergedValue = value.join(splitString);
    return useBase64 ? base64UrlEncode(mergedValue) : mergedValue;
}
