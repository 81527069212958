import React from 'react';
import { createStyles, Theme, makeStyles, withTheme } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';
import { Account, Contract } from '../../contracts/contracts';
import ChangeOrderSubContractorsPerMonthGraph from './ChangeOrderSubContractorsPerMonthGraph';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootChange: {
      width: '100%',
    },
    card: {
      padding: '1em',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'initial',
    },
  }),
);

type props = {
  selectedContract: Contract | undefined;
  selectedAccount: Account | undefined;
}

const ChangeOrderSubContractorOverviewGraphsCard: React.FC<props> = ({
  selectedContract, 
  selectedAccount}) => {

  const classes = useStyles();
  
  return (<>
        <Card className={classes.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ChangeOrderSubContractorsPerMonthGraph selectedContract={selectedContract} selectedAccount={selectedAccount} showTotalCosts={true} defaultExpanded={true} />
            </Grid>
            <Grid item xs={12}>
              <ChangeOrderSubContractorsPerMonthGraph selectedContract={selectedContract} selectedAccount={selectedAccount} showCosts={true} />
            </Grid>
            <Grid item xs={12}>
              <ChangeOrderSubContractorsPerMonthGraph selectedContract={selectedContract} selectedAccount={selectedAccount} />
            </Grid>
          </Grid>
        </Card>
      </>);
}

export default withTheme(ChangeOrderSubContractorOverviewGraphsCard);