import React, { createContext, useContext, useEffect, useState } from "react";
import { ChangeOrderLandlord } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_CHANGE_ORDER_LANDLORD, getChangeOrderLandlordsGraphqlQueryOptions } from './changeOrderLandlordQueries'
import { useAuthContext } from "../../auth/authContext";

export interface ChangeOrderLandlordQueriesContext {
    fetchedChangeOrderLandlords: Array<ChangeOrderLandlord>,
    queryChangeOrderLandlords: (changeOrderLandlord: ChangeOrderLandlord) => void,
}

const ChangeOrderLandlordQueriesContext = createContext<ChangeOrderLandlordQueriesContext>(null as unknown as ChangeOrderLandlordQueriesContext);

export const ChangeOrderLandlordQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedChangeOrderLandlords, setFetchedChangeOrderLandlords] = useState<Array<ChangeOrderLandlord>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_CHANGE_ORDER_LANDLORD);

    const queryChangeOrderLandlords = (changeOrderLandlord: ChangeOrderLandlord): void => {
        const queryOptions = getChangeOrderLandlordsGraphqlQueryOptions(changeOrderLandlord);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.changeOrderLandlords) {
            const newFetchedChangeOrderLandlords: Array<ChangeOrderLandlord> = queryResponse.data.changeOrderLandlords;
            setFetchedChangeOrderLandlords(newFetchedChangeOrderLandlords);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedChangeOrderLandlords([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const changeOrderLandlordQueriesContext: ChangeOrderLandlordQueriesContext = {
        fetchedChangeOrderLandlords,
        queryChangeOrderLandlords,
    };

    return (
        <ChangeOrderLandlordQueriesContext.Provider value={changeOrderLandlordQueriesContext}>
            {children}
        </ChangeOrderLandlordQueriesContext.Provider>
    );
}

export const useChangeOrderLandlordQueriesContext = (): ChangeOrderLandlordQueriesContext => {
    return useContext(ChangeOrderLandlordQueriesContext);
}
