import React, { createContext, useContext } from "react";
import { ChangeOrderExpected, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getChangeOrderExpectedsGraphqlMutationOptions, MUTATE_CHANGE_ORDER } from "./changeOrderExpectedMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface ChangeOrderExpectedMutationsContext {
    mutateChangeOrderExpected: (accout: ChangeOrderExpected, onTicketSubscribed: (documentId: Guid, variables: any) => void) => void;
}

const ChangeOrderExpectedMutationsContext = createContext<ChangeOrderExpectedMutationsContext>(null as unknown as ChangeOrderExpectedMutationsContext);

export const ChangeOrderExpectedMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [changeOrderExpectedMutation] = useMutation(MUTATE_CHANGE_ORDER);

    const handleChangeOrderExpectedMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateChangeOrderExpected.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                        onTicketSubscribed(ticket.parentDocumentId, variables);
                    }
                }
            }
        });
    }

    const mutateChangeOrderExpected = (changeOrderExpected: ChangeOrderExpected, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        const options = getChangeOrderExpectedsGraphqlMutationOptions(changeOrderExpected);
        changeOrderExpectedMutation(options).then((result) => handleChangeOrderExpectedMutationResult(result, options.variables, onTicketSubscribed));
    }

    const changeOrderExpectedMutationsContext: ChangeOrderExpectedMutationsContext = {
        mutateChangeOrderExpected
    };

    return (
        <ChangeOrderExpectedMutationsContext.Provider value={changeOrderExpectedMutationsContext}>
            {children}
        </ChangeOrderExpectedMutationsContext.Provider>
    );
}

export const useChangeOrderExpectedMutationsContext = (): ChangeOrderExpectedMutationsContext => {
    return useContext(ChangeOrderExpectedMutationsContext);
}
