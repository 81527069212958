import React, { createContext, useContext, useEffect, useState } from "react";
import { Subject } from 'rxjs';
import Keycloak from 'keycloak-js'
import { AuthContext, AuthProviderContext, AccountInfo, AuthSettings, localStorageAccessTokenKey, localStorageIdTokenKey } from '../../interfaces';
import { ApplicationRoute } from "../../../menu/menuContext";
import { generalAuthorizedCheck, jwtAccessToken, jwtIdToken } from "../../authTools";
import { useHistory } from "react-router-dom";
import { AIMZ_CLIENT_ID } from "../../../graphql/graphqlAuthSettingsContext";
import { ProjectContext } from "../../../project/projectContext";
import { useBrowserContext } from "../../../browserContext/browserContext";

const KeycloakAuthContext = createContext<AuthContext>(null as unknown as AuthContext);

export const KeycloakAuthContextProvider: React.FC<AuthProviderContext> = ({ 
    getAuthProvider, 
    children }) => {

    const defaultKeycloakSettings: Keycloak.KeycloakInstance = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URI ?? "",
        realm: process.env.REACT_APP_KEYCLOAK_REALM ?? "",
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? ""
    });

    const browserContext = useBrowserContext();
    const history = useHistory();
    const [authInitialized, setAuthInitialized] = useState(false);
    const [authReady, setAuthReady] = useState(false);
    const [insecure, setInsecure] = useState(false);
    const [authSettings, setAuthSettingsState] = useState<AuthSettings | undefined>(undefined);
    const [keycloak, setKeycloak] = useState(defaultKeycloakSettings);
    const [updatedAuthenticatedState, setUpdatedAuthenticatedState] = useState(keycloak.authenticated ?? false);
    const [authenticated, setAuthenticated] = useState(updatedAuthenticatedState);
    const [tokenIsReady, setTokenIsReady] = useState(keycloak.token ? true : false);
    const [accountInfo, setAccountInfo] = useState<AccountInfo | undefined>(undefined);
    const [onTokenRefreshSubject, setOnTokenRefreshSubject] = useState<Subject<string>>(new Subject<string>());

    const login = (): void => {
        keycloak.login({ redirectUri: window.location.href });
    }

    const logout = (): void => {
        cleanUrlBeforeLogout();
        // Keycloak upgrade to logout procedure: https://www.keycloak.org/2022/04/keycloak-1800-released
        // Uncomment keycloak.logout(); to use old version of keycloak (<v18)
        // keycloak.logout();
        const oldKeycloakLogoutUrl = keycloak.createLogoutUrl();
        const logoutUrl = `${oldKeycloakLogoutUrl.substring(0, oldKeycloakLogoutUrl.indexOf('?'))}?post_logout_redirect_uri=${window.location}&id_token_hint=${keycloak.idToken}`;
        window.location.href = logoutUrl;
    }

    const cleanUrlBeforeLogout = () => {
        localStorage.setItem(localStorageAccessTokenKey, "");
        localStorage.setItem(localStorageIdTokenKey, "");
    }


    const setAuthContext = (): void => {
        localStorage.setItem(localStorageAccessTokenKey, keycloak.token ?? "");
        localStorage.setItem(localStorageIdTokenKey, keycloak.idToken ?? "");
        onTokenRefreshSubject.next(jwtAccessToken());
    }

    const refreshToken = (): void => {
        keycloak.updateToken(60).then((refreshed: boolean) => {
            const timeoutSeconds =  Math.round((keycloak.tokenParsed?.exp ?? 0) + (keycloak.timeSkew ?? 0) - new Date().getTime() / 1000);
            if (refreshed){
                console.log(`Token refreshed ${new Date()} and valid for ${timeoutSeconds} seconds`);
                setAuthContext();
            }
        }).catch(() => {
            console.error('Failed to refresh token ' + new Date());
        });
    }

    useEffect(() => {
        if (authenticated !== updatedAuthenticatedState) {
            if (!updatedAuthenticatedState) {
                logout();
            }
            setAuthenticated(updatedAuthenticatedState);
        }
    }, [updatedAuthenticatedState])

    useEffect(() => {
        if (authenticated) {
            setAuthContext();
            setTokenIsReady(true);
            loadAccountInfo().then(info => {
                setAccountInfo(info);
            });
            setInterval(async () => {
                refreshToken();
            }, 10000);
        }
    }, [authenticated])

    useEffect(() => {
        if (authReady && !authenticated && !insecure) {
            login();
        }
    }, [authenticated, authReady])

    const setAuthSettings = (authSettings: AuthSettings): void => {
        if (!authSettings || !authSettings.clientId || authSettings.clientId.length === 0) {
            return;
        }

        const keycloakSettings: Keycloak.KeycloakInstance = new Keycloak({
            url: authSettings?.url ?? "",
            realm: authSettings?.realm ?? "",
            clientId: authSettings?.clientId ?? ""
        });

        keycloakSettings.onAuthSuccess = () => {
            console.log('AUTH SUCCESS');
            setUpdatedAuthenticatedState(true);
        }
        keycloakSettings.onAuthError = () => {
            console.log('AUTH ERROR');
            setUpdatedAuthenticatedState(false);
        }
        keycloakSettings.onAuthLogout = () => {
            console.log('AUTH LOGOUT');
            setUpdatedAuthenticatedState(false);
        }
        keycloakSettings.onAuthRefreshError = () => {
            console.log('AUTH REFRESH ERROR');
            setUpdatedAuthenticatedState(false);
        }
        keycloakSettings.onAuthRefreshSuccess = () => {
            console.log('AUTH REFRESH SUCCESS');
            setUpdatedAuthenticatedState(true);
        }
        keycloakSettings.onReady = () => {
            console.log('AUTH READY');
            setAuthReady(true);
        }
        keycloakSettings.onTokenExpired = () => {
            console.log('AUTH TOKEN EXPIRED');
            setUpdatedAuthenticatedState(false);
        }
        keycloakSettings.init({});
        setAuthSettingsState(authSettings);
        setInsecure(authSettings?.authOff ?? false);
        setKeycloak(keycloakSettings);
        setAuthInitialized(true);
    }

    const authorized = (applicationRoute: ApplicationRoute, projectContext: ProjectContext): boolean => {
        return generalAuthorizedCheck(authenticated, insecure, applicationRoute, projectContext);
    }

    const loadAccountInfo = (): Promise<AccountInfo> => {
        return new Promise<AccountInfo>((resolve, reject) => {
            const tokenParsed = keycloak.tokenParsed as any;
            const accountInfo: AccountInfo = {
                id: tokenParsed?.sub ?? "",
                email: tokenParsed?.email ?? "",
                name: tokenParsed?.name ?? (tokenParsed?.preferred_username ?? ""),
            }
            resolve(accountInfo);
        });
    }

    const accountRoles = (): string[] => {
        let roles: string[] = [];
        if (keycloak.resourceAccess && AIMZ_CLIENT_ID in keycloak.resourceAccess) {
            roles = keycloak.resourceAccess[AIMZ_CLIENT_ID]['roles'] ?? []
        }
        if (keycloak.resourceAccess && '.roles' in keycloak.resourceAccess) {
            roles = roles.concat(((keycloak.resourceAccess['.roles'] as any) ?? []) as string[]);
        }
        return roles;
    }

    const value = {
        insecure,
        getAuthProvider,
        authInitialized,
        authReady,
        setAuthSettings,
        authSettings,
        login,
        logout,
        authenticated,
        authorized,
        tokenIsReady,
        onTokenRefreshSubject,
        jwtAccessToken,
        jwtIdToken,
        accountInfo,
        accountRoles,
    };

    const keycloakAuthContext = <KeycloakAuthContext.Provider value={value}>
                                {children}
                            </KeycloakAuthContext.Provider>
    return keycloakAuthContext;
}

export const useKeycloakAuthContext = (): AuthContext => {
    return useContext(KeycloakAuthContext);
}
