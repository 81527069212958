import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { useChangeOrderLandlordContext } from "../../contexts/changeOrderLandlord/changeOrderLandlordContext";
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
    selectedChangeOrderLandlordGroup: string | undefined;
    onSelectedChangeOrderLandlordGroupChange: (newSelectedChangeOrderLandlordGroup: string | undefined) => void;
    freeSolo?: boolean;
}

const SelectChangeOrderLandlordGroup: React.FC<props> = ({
    selectedChangeOrderLandlordGroup,
    onSelectedChangeOrderLandlordGroupChange, 
    freeSolo }) => {

    const [inputValue, setInputValue] = React.useState('');
    const languageContext = useLanguageContext();
    const changeOrderLandlordContext = useChangeOrderLandlordContext();

    const changeOrderLandlordGroups: (string | undefined)[] = [];
    changeOrderLandlordContext.getChangeOrderLandlords().forEach(changeOrderLandlord => {
        if (changeOrderLandlordGroups.indexOf(changeOrderLandlord.group) < 0) {
            changeOrderLandlordGroups.push(changeOrderLandlord.group);
        }
    });
    const defaultChangeOrderLandlordGroup = selectedChangeOrderLandlordGroup ?? null;

    const handleChange = (event: any, newSelectedChangeOrderLandlordGroup: string | undefined | null) => {
        onSelectedChangeOrderLandlordGroupChange(newSelectedChangeOrderLandlordGroup ?? undefined);
    }

    return <Autocomplete
        options={freeSolo ? changeOrderLandlordGroups.filter(group => (group ?? "").toLowerCase().includes(inputValue.toLowerCase())) : changeOrderLandlordGroups}
        freeSolo={freeSolo}
        getOptionLabel={(changeOrderLandlordGroup) => changeOrderLandlordGroup ?? ''}
        value={defaultChangeOrderLandlordGroup}
        onChange={handleChange}
        inputValue={freeSolo ? inputValue : undefined}
        onInputChange={freeSolo ? (event, newInputValue) => {
          setInputValue(newInputValue);
          handleChange(event, newInputValue);
        } : undefined}
        renderInput={(params) => <TextField {...params}
            label={languageContext.getMessage('group')}
            variant="standard" />}
    />
}

export default SelectChangeOrderLandlordGroup;