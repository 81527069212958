import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ChangeOrderSubContractor, ActiveState } from "../../../contracts/contracts";

export const QUERY_CHANGE_ORDER_SUB_CONTRACTOR: DocumentNode = gql`
    query changeOrderSubContractors(
            $id: UUID
            $state: ActiveState
            $projectId: UUID
            $contractId: UUID
            $accountId: UUID
            $changeOrderExpectedId: UUID
            $name: String
            $group: String
            $received: DateTime
            $answered: DateTime
            $changeOrderDescription: String
            $changeOrderComment: String
            $contractor: String
            $costRequirements: Float
            $cost: Float
            $status: ChangeOrderStatus
            $progressConsequenceEnabled: Boolean
            $progressConsequenceDescription: String
        ) {
        changeOrderSubContractors(
            id: $id
            state: $state
            projectId: $projectId
            contractId: $contractId
            accountId: $accountId
            changeOrderExpectedId: $changeOrderExpectedId
            name: $name
            group: $group
            received: $received
            answered: $answered
            changeOrderDescription: $changeOrderDescription
            changeOrderComment: $changeOrderComment
            contractor: $contractor
            costRequirements: $costRequirements
            cost: $cost
            status: $status
            progressConsequenceEnabled: $progressConsequenceEnabled
            progressConsequenceDescription: $progressConsequenceDescription
        ) {
            id
            documentType
            stateTime
            state
            created
            changedBy
            projectId
            contractId
            accountId
            changeOrderExpectedId
            contractName
            name
            group
            received
            answered
            changeOrderDescription
            changeOrderComment
            contractor
            costRequirements
            cost
            sumInvoicedAmount
            sumInvoicedWithheldAmount
            remainingCost
            status
            progressConsequenceEnabled
            progressConsequenceDescription
        }
    }
`;

export const getChangeOrderSubContractorsGraphqlQueryOptions = (changeOrderSubContractor: Partial<ChangeOrderSubContractor>): QueryHookOptions => {
    changeOrderSubContractor.state = changeOrderSubContractor.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (changeOrderSubContractor.id !== undefined) options.variables['id'] = changeOrderSubContractor.id;
    if (changeOrderSubContractor.state !== undefined) options.variables['state'] = changeOrderSubContractor.state;
    if (changeOrderSubContractor.projectId !== undefined) options.variables['projectId'] = changeOrderSubContractor.projectId;
    if (changeOrderSubContractor.contractId !== undefined) options.variables['contractId'] = changeOrderSubContractor.contractId;
    if (changeOrderSubContractor.accountId !== undefined) options.variables['accountId'] = changeOrderSubContractor.accountId;
    if (changeOrderSubContractor.changeOrderExpectedId !== undefined) options.variables['changeOrderExpectedId'] = changeOrderSubContractor.changeOrderExpectedId;
    if (changeOrderSubContractor.name !== undefined) options.variables['name'] = changeOrderSubContractor.name;
    if (changeOrderSubContractor.received !== undefined) options.variables['received'] = changeOrderSubContractor.received.toISOString();
    if (changeOrderSubContractor.changeOrderDescription !== undefined) options.variables['changeOrderDescription'] = changeOrderSubContractor.changeOrderDescription;
    if (changeOrderSubContractor.changeOrderComment !== undefined) options.variables['changeOrderComment'] = changeOrderSubContractor.changeOrderComment;
    if (changeOrderSubContractor.contractor !== undefined) options.variables['contractor'] = changeOrderSubContractor.contractor;
    if (changeOrderSubContractor.costRequirements !== undefined) options.variables['costRequirements'] = changeOrderSubContractor.costRequirements;
    if (changeOrderSubContractor.cost !== undefined) options.variables['cost'] = changeOrderSubContractor.cost;
    if (changeOrderSubContractor.status !== undefined) options.variables['status'] = changeOrderSubContractor.status;
    if (changeOrderSubContractor.progressConsequenceEnabled !== undefined) options.variables['progressConsequenceEnabled'] = changeOrderSubContractor.progressConsequenceEnabled;
    if (changeOrderSubContractor.progressConsequenceDescription !== undefined) options.variables['progressConsequenceDescription'] = changeOrderSubContractor.progressConsequenceDescription;

    return options;
}