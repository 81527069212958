import { DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withTheme from '@material-ui/styles/withTheme';
import React from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';

const useStyles = makeStyles((theme: Theme) => ({
}));

export interface Props {
    title?: string,
    open: boolean;
    onClose: (deleteIsConfirmed: boolean) => Promise<void>;
}

const ConfirmDeleteDialog: React.FC<Props> = ({
    title,
    open,
    onClose
}) => {
    const classes = useStyles();
    const languageContext = useLanguageContext();

    title = title ?? languageContext.getMessage('doYouWantToDeleteElement');

    const handleOnClickDelete = async (): Promise<void> => {
        return onClose(true);
    };

    const handleOnClickClose = async (): Promise<void> => {
        return onClose(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            onClose={async () => await handleOnClickClose()}
            open={open}
            disableBackdropClick={true}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogActions>
                <Button onClick={async () => await handleOnClickDelete()} color="primary" variant="contained">
                    {languageContext.getMessage('delete')}
                </Button>
                <Button onClick={async () => await handleOnClickClose()} color="primary" variant="contained" autoFocus>
                    {languageContext.getMessage('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme(ConfirmDeleteDialog);