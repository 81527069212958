import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, AccrualAndOperations, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface AccrualAndOperationsSubscriptionsContext {
    subscribedAccrualAndOperations: Array<AccrualAndOperations>,
}

const AccrualAndOperationsSubscriptionsContext = createContext<AccrualAndOperationsSubscriptionsContext>(null as unknown as AccrualAndOperationsSubscriptionsContext);

export const AccrualAndOperationsSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedAccrualAndOperations, setSubscribedAccrualAndOperations] = useState<Array<AccrualAndOperations>>([]);

    const updateSubscribedAccrualAndOperations = (stateDocuments: StateDocument[]) => {
        const subscribedAccrualAndOperations = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.ACCRUAL_AND_OPERATIONS && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as AccrualAndOperations);
        setSubscribedAccrualAndOperations(subscribedAccrualAndOperations);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedAccrualAndOperations([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedAccrualAndOperations(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const accrualAndOperationsContext: AccrualAndOperationsSubscriptionsContext = {
        subscribedAccrualAndOperations,
    };

    return (
        <AccrualAndOperationsSubscriptionsContext.Provider value={accrualAndOperationsContext}>
            {children}
        </AccrualAndOperationsSubscriptionsContext.Provider>
    );
}

export const useAccrualAndOperationsSubscriptionsContext = (): AccrualAndOperationsSubscriptionsContext => {
    return useContext(AccrualAndOperationsSubscriptionsContext);
}
