import { Divider, Grid, Input, makeStyles, MenuItem, Select, Switch, TextField, Theme, Tooltip, Typography, withTheme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Cancel, Check, Info } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import ToggleButton from "@material-ui/lab/ToggleButton";
import React, { ReactElement, useEffect, useState } from "react";
import { useCrewResourceContext } from "../../contexts/crewResource/crewResourceContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { CrewList, CrewDetails, CrewResource, CrewResourceDetails, CrewResourceRole, HealthStatus, ActiveState } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { datesAreOnSameMonth, fillEmptyMonths, getDateArray, getYearMonthStringArray, sortDatesAndExtractYears } from "../../utils/dateTools";
import CurrencyFormat, { displayCurrency } from "../currencyComponents/CurrencyFormat";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import StickyColumnTable, { StickyColumnTableData } from "../generalComponents/StickyColumnTable";
import TicketLoading from "../generalComponents/TicketLoading";
import PercentageFormat from "../percentageComponents/PercentageFormat";
import PercentageInput from "../percentageComponents/PercentageInput";
import { extractUsedMonths, GetNewCrewResourceDetails, MutateDefaultCrewResourceMonths } from "./CrewResourceTools";
import { useThemeContext } from "../../contexts/theme/ThemeContext";

export function getRemainsToDistributeHealthStatus(value: number): HealthStatus {
  if (value === 0) {
    return HealthStatus.HEALTHY;
  } else if (value >= 0) {
    return HealthStatus.DEGRADED;
  } else {
    return HealthStatus.UNHEALTHY;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: 800,
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: 0,
  },
  tableCellHeader: {
    minWidth: theme.spacing(20),
    position: "sticky",
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 951,
  },
  tableCellHeaderFixed: {
    minWidth: theme.spacing(20),
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 951,
    background: theme.palette.background.paper,
  },
  tableCellFixed: {
    minWidth: theme.spacing(20),
    position: "sticky",
    left: 0,
    background: theme.palette.background.paper,
    zIndex: 950,
  },
}));

type props = {
  from: Date | undefined;
  to: Date | undefined;
  crewList: CrewList | undefined;
  crewResources: CrewResource[];
  setCrewListToUpdate: (crewList: CrewList | undefined) => void;
  crewResourcesToUpdate: Dictionary<CrewResource>;
  setCrewResourcesToUpdate: (crewResources: Dictionary<CrewResource>) => void;
  editingCrewResources: boolean;
  showSalaryState: boolean;
  isSavingCrewList: boolean;
  setIsSavingCrewList: (saving: boolean) => void;
  registerCrewResourceToUpdate: (crewResource: CrewResource) => void;
  resetCrewResource: (crewResource: CrewResource) => void;
};

const CrewResourceDetailsList: React.FC<props> = ({
  from,
  to,
  crewList,
  crewResources,
  setCrewListToUpdate,
  crewResourcesToUpdate,
  setCrewResourcesToUpdate,
  editingCrewResources,
  showSalaryState,
  isSavingCrewList,
  setIsSavingCrewList,
  registerCrewResourceToUpdate,
  resetCrewResource,
}) => {
  const languageContext = useLanguageContext();
  const crewResourceContext = useCrewResourceContext();
  const classes = useStyles();
  const language = languageContext.getLanguage();
  const theme = useThemeContext().getTheme();

  const [crewResourcesVerifyDelete, setCrewResourcesVerifyDelete] = useState<Dictionary<boolean>>({});
  const [disabledMonths, setDisabledMonths] = useState<Dictionary<boolean>>({});
  const [defaultMutatedMonths, setDefaultMutatedMonths] = useState<Dictionary<Dictionary<CrewResourceDetails>>>({});

  const monthlyCrewResourceDetails: Dictionary<Dictionary<CrewResourceDetails>> = {};
  const monthlyCrewDetails: Dictionary<CrewDetails> = {};

  const getCrewResourcesDuringEditing = (defaultCrewResource: CrewResource): CrewResource => {
    if (!defaultCrewResource.id || !(defaultCrewResource.id in crewResourcesToUpdate)) {
      return defaultCrewResource;
    }
    return crewResourcesToUpdate[defaultCrewResource.id];
  };

  for (let i = 0; i < crewResources.length; i++) {
    crewResources[i] = getCrewResourcesDuringEditing(crewResources[i]);
  }

  let usedDates = extractUsedMonths(crewList, crewResources, monthlyCrewResourceDetails, monthlyCrewDetails);
  const emptyMonths: Date[] = [];
  usedDates = fillEmptyMonths(from, to, usedDates, emptyMonths);
  const [sortedUsedDates, usedYears] = sortDatesAndExtractYears(usedDates);

  useEffect(() => {
    if (!editingCrewResources) {
      return;
    }
    MutateDefaultCrewResourceMonths(
      crewResourceContext,
      crewList,
      crewResources,
      usedDates,
      emptyMonths,
      monthlyCrewResourceDetails,
      defaultMutatedMonths,
      setDefaultMutatedMonths,
      crewResourcesToUpdate,
      setCrewResourcesToUpdate
    );
  }, [to, from]);

  useEffect(() => {
    if (!editingCrewResources) {
      return;
    }
    sortedUsedDates.forEach((date) => {
      disabledMonths[date.toISOString()] = monthlyCrewDetails[date.toISOString()]?.disabled ?? false;
    });
    setDisabledMonths({ ...disabledMonths });
  }, [editingCrewResources]);

  const onDeleteCrewResource = async (crewResource: CrewResource) => {
    if (!crewResource.id) {
      return;
    }
    if (crewResource.id in crewResourcesVerifyDelete && crewResourcesVerifyDelete[crewResource.id] === true) {
      crewResource.state = ActiveState.INACTIVE;
      setIsSavingCrewList(true);
      await crewResourceContext.mutateCrewResource(crewResource);
      crewResourcesVerifyDelete[crewResource.id] = false;
      setIsSavingCrewList(false);
    }
    setCrewResourcesVerifyDelete({ ...crewResourcesVerifyDelete });
  };

  const calculatePercentageSalary = (crewResourceDetails: CrewResourceDetails | undefined): number => {
    const salary = crewResourceDetails?.salary ?? 0.0;
    const percentage = (crewResourceDetails?.assignmentPercentage ?? 0.0) / 100.0;
    return salary * percentage;
  };

  const dateColumnData = React.useMemo(() => {
    if (sortedUsedDates) {
      var monthYearStringArray = getYearMonthStringArray(sortedUsedDates, language);
      return { dateArray: sortedUsedDates, monthYearStringArray: monthYearStringArray };
    } else {
      return { dateArray: [], monthYearStringArray: [] };
    }
  }, [sortedUsedDates]);
 
  const mainColumn = React.useMemo(() => {
    return (
      <Grid container spacing={2} style={{ minWidth: editingCrewResources ? "30vw" : "20vw" }}>
        {editingCrewResources && (
          <>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("name")}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("role")}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography style={{ fontWeight: "bold" }}>{showSalaryState ? languageContext.getMessage("monthlySalaryShort") : languageContext.getMessage("monthlyPercentageShort")}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("yearlyWageGrowthShort")}</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </>
        )}
        {!editingCrewResources && (
          <>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("name")}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("role")}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  }, [editingCrewResources, showSalaryState]);

  const tableBodyData = React.useMemo(() => {
    var resolvedTopData: StickyColumnTableData[] = [];
    crewResources?.forEach((crewResource) => {
      resolvedTopData.push({
        label: (
          <Grid container spacing={2} style={{ minWidth: editingCrewResources ? "30vw" : "20vw" }}>
            {editingCrewResources && (
              <>
                <Grid item xs={1}>
                  {editingCrewResources && crewResource.id && (!(crewResource.id in crewResourcesVerifyDelete) || crewResourcesVerifyDelete[crewResource.id] === false) && (
                    <Grid container wrap="nowrap" spacing={1} justify="center">
                      <Grid item>
                        <ToggleButton
                          color="inherit"
                          size="small"
                          className={classes.button}
                          value="check"
                          disabled={isSavingCrewList}
                          onChange={() => {
                            if (crewResource.id) {
                              crewResourcesVerifyDelete[crewResource.id] = true;
                              setCrewResourcesVerifyDelete({ ...crewResourcesVerifyDelete });
                            }
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </ToggleButton>
                      </Grid>
                      {/* <Grid item></Grid> */}
                    </Grid>
                  )}
                  {editingCrewResources && crewResource.id && crewResource.id in crewResourcesVerifyDelete && crewResourcesVerifyDelete[crewResource.id] === true && (
                    <Grid container wrap="nowrap" spacing={1} justify="center">
                      <Grid item>
                        <ToggleButton disabled={isSavingCrewList} color="inherit" size="small" className={classes.button} value="check" onChange={async () => await onDeleteCrewResource(crewResource)}>
                          <Check fontSize="small" />
                        </ToggleButton>
                      </Grid>
                      <Grid item>
                        <ToggleButton
                          color="inherit"
                          size="small"
                          className={classes.button}
                          value="check"
                          disabled={isSavingCrewList}
                          onChange={() => {
                            if (crewResource.id) {
                              crewResourcesVerifyDelete[crewResource.id] = false;
                              setCrewResourcesVerifyDelete({ ...crewResourcesVerifyDelete });
                            }
                          }}
                        >
                          <Cancel fontSize="small" />
                        </ToggleButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={crewResource.name ?? ""}
                    onChange={(event) => {
                      crewResource.name = event.target.value;
                      registerCrewResourceToUpdate(crewResource);
                    }}
                    disabled={isSavingCrewList}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    value={crewResource?.defaultCrewResourceDetails?.role}
                    onChange={(event) => {
                      if (crewResource) {
                        crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                        crewResource.defaultCrewResourceDetails.role = event.target.value as CrewResourceRole;
                        registerCrewResourceToUpdate(crewResource);
                      }
                    }}
                    input={<Input />}
                    renderValue={(role) => <div>{crewResourceContext.convertCrewResourceRoleToString(role as CrewResourceRole)}</div>}
                    fullWidth
                    disabled={isSavingCrewList}
                  >
                    {Object.values(CrewResourceRole).map((crewResourceRole) => (
                      <MenuItem key={crewResourceRole} value={crewResourceRole}>
                        {crewResourceContext.convertCrewResourceRoleToString(crewResourceRole)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <>
                    {!showSalaryState && (
                      <PercentageInput
                        percentage={crewResource.defaultCrewResourceDetails?.assignmentPercentage ?? 0.0}
                        onChange={(newAssignmentPercentage) => {
                          crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                          crewResource.defaultCrewResourceDetails.assignmentPercentage = newAssignmentPercentage;
                          registerCrewResourceToUpdate(crewResource);
                        }}
                        fullWidth={true}
                        updateOnDefaultPercentageChange={true}
                        disabled={isSavingCrewList}
                      />
                    )}
                    {showSalaryState && (
                      <CurrencyInput
                        value={crewResource.defaultCrewResourceDetails?.salary ?? 0.0}
                        onChange={(newSalary) => {
                          crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                          crewResource.defaultCrewResourceDetails.salary = newSalary;
                          registerCrewResourceToUpdate(crewResource);
                        }}
                        fullWidth
                        disabled={isSavingCrewList}
                      />
                    )}
                  </>
                </Grid>
                <Grid item xs={2}>
                  <PercentageInput
                    percentage={crewResource.defaultCrewResourceDetails?.salaryAdjustmentPercentage ?? 0.0}
                    onChange={(newSalaryAdjustmentPercentage) => {
                      crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                      crewResource.defaultCrewResourceDetails.salaryAdjustmentPercentage = newSalaryAdjustmentPercentage;
                      registerCrewResourceToUpdate(crewResource);
                    }}
                    fullWidth={true}
                    updateOnDefaultPercentageChange={true}
                    disabled={isSavingCrewList}
                  />
                </Grid>
                <Grid item xs={1}>
                  <ToggleButton
                    color="inherit"
                    size="small"
                    className={classes.button}
                    value="check"
                    disabled={isSavingCrewList}
                    onChange={() => {
                      if (crewResource.id) {
                        resetCrewResource(crewResource);
                      }
                    }}
                  >
                    <RotateLeftIcon fontSize="small" />
                  </ToggleButton>
                </Grid>
              </>
            )}
            {!editingCrewResources && (
              <>
                <Grid item xs={6}>
                  <Typography>{crewResource.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{crewResourceContext.convertCrewResourceRoleToString(crewResource.defaultCrewResourceDetails?.role)}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        ),
        cellValues: sortedUsedDates.map((usedDate) => {
          const dateStr = usedDate.toISOString();
          if (!crewResource.id || !(crewResource.id ?? "" in monthlyCrewResourceDetails)) {
            return <></>;
          }
          let crewResourceDetails: CrewResourceDetails = { date: usedDate };
          if (monthlyCrewResourceDetails[crewResource.id] && dateStr in monthlyCrewResourceDetails[crewResource.id]) {
            crewResourceDetails = monthlyCrewResourceDetails[crewResource.id][dateStr];
          }
          return (
            <>
              {!editingCrewResources && (
                <>
                  {!showSalaryState && <PercentageFormat percentage={(crewResourceDetails.assignmentPercentage ?? 0.0) / 100} />}
                  {showSalaryState && <CurrencyFormat amount={calculatePercentageSalary(crewResourceDetails)} />}
                </>
              )}
              {editingCrewResources && (
                <>
                  {!showSalaryState && (
                    <PercentageInput
                      percentage={crewResourceDetails.assignmentPercentage ?? 0.0}
                      onChange={(newAssignmentPercentage) => {
                        crewResourceDetails.assignmentPercentage = newAssignmentPercentage;
                        crewResource.monthlyCrewResourceDetails = crewResource.monthlyCrewResourceDetails ?? [];
                        const index = crewResource.monthlyCrewResourceDetails.findIndex((item: CrewResourceDetails) => item.date?.toISOString() === dateStr);
                        if (index >= 0) {
                          crewResource.monthlyCrewResourceDetails[index] = crewResourceDetails;
                        } else {
                          crewResource.monthlyCrewResourceDetails.push(crewResourceDetails);
                        }
                        registerCrewResourceToUpdate(crewResource);
                      }}
                      fullWidth={true}
                      updateOnDefaultPercentageChange={true}
                      disabled={isSavingCrewList}
                    />
                  )}
                  {showSalaryState && (
                    <CurrencyInput
                      value={crewResourceDetails.salary ?? 0.0}
                      onChange={(newSalary) => {
                        crewResourceDetails.salary = newSalary;
                        crewResource.monthlyCrewResourceDetails = crewResource.monthlyCrewResourceDetails ?? [];
                        const index = crewResource.monthlyCrewResourceDetails.findIndex((item) => item.date?.toISOString() === dateStr);
                        if (index >= 0) {
                          crewResource.monthlyCrewResourceDetails[index] = crewResourceDetails;
                        } else {
                          crewResource.monthlyCrewResourceDetails.push(crewResourceDetails);
                        }
                        registerCrewResourceToUpdate(crewResource);
                      }}
                      fullWidth
                      disabled={isSavingCrewList}
                    />
                  )}
                </>
              )}
            </>
          );
        }),
      });
    });

    return resolvedTopData;
  }, [dateColumnData, editingCrewResources]);

  const tableSumData = React.useMemo(() => {
    var resolvedTopData: StickyColumnTableData[] = [];
    resolvedTopData.push({
      label: <Typography style={{ fontWeight: "bold" }}>{`${languageContext.getMessage("sum")} %`}</Typography>,
      cellValues: sortedUsedDates.map((usedDate) => {
        const dateStr = usedDate.toISOString();
        let sumAssignedPercentages = 0.0;
        if (dateStr in monthlyCrewDetails) {
          sumAssignedPercentages = monthlyCrewDetails[dateStr].sumAssignedPercentages ?? 0.0;
        }
        return <PercentageFormat percentage={sumAssignedPercentages / 100} showHealthStatus={false} />;
      }),
    });
    resolvedTopData.push({
      label: <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("expectedPostedExpense")}</Typography>,
      cellValues: sortedUsedDates.map((usedDate) => {
        const dateStr = usedDate.toISOString();
        let expectedExpense: number = 0.0;
        if (dateStr in monthlyCrewDetails) {
          expectedExpense = monthlyCrewDetails[dateStr].expectedExpense ?? 0.0;
        }
        return <CurrencyFormat amount={expectedExpense} />;
      }),
    });
    resolvedTopData.push({
      label: <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("expectedAccumulatedPostedExpense")}</Typography>,
      cellValues: sortedUsedDates.map((usedDate) => {
        const dateStr = usedDate.toISOString();
        let expectedAccumulatedExpense: number = 0.0;
        if (dateStr in monthlyCrewDetails) {
          expectedAccumulatedExpense = monthlyCrewDetails[dateStr].expectedAccumulatedExpense ?? 0.0;
        }
        return <CurrencyFormat amount={expectedAccumulatedExpense} />;
      }),
    });
    resolvedTopData.push({
      label: <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("actuallyPostedExpense")}</Typography>,
      cellValues: sortedUsedDates.map((usedDate) => {
        const dateStr = usedDate.toISOString();
        let actuallyPostedExpense: number = 0.0;
        if (dateStr in monthlyCrewDetails) {
          actuallyPostedExpense = monthlyCrewDetails[dateStr].actuallyPostedExpense ?? 0.0;
        }
        return <CurrencyFormat amount={actuallyPostedExpense} />;
      }),
    });
    resolvedTopData.push({
      label: <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("actuallyAccumulatedPostedExpense")}</Typography>,
      cellValues: sortedUsedDates.map((usedDate) => {
        const dateStr = usedDate.toISOString();
        let actuallyAccumulatedPostedExpense: number = 0.0;
        if (dateStr in monthlyCrewDetails) {
          actuallyAccumulatedPostedExpense = monthlyCrewDetails[dateStr].actuallyAccumulatedPostedExpense ?? 0.0;
        }
        return <CurrencyFormat amount={actuallyAccumulatedPostedExpense} />;
      }),
    });
    resolvedTopData.push({
      label: <Typography style={{ fontWeight: "bold" }}>{languageContext.getMessage("revisedForecast")}</Typography>,
      cellValues: sortedUsedDates.map((usedDate) => {
        const dateStr = usedDate.toISOString();
        let revisedForecast: number = 0.0;
        let aboveForecast = false;
        if (dateStr in monthlyCrewDetails) {
          revisedForecast = monthlyCrewDetails[dateStr].revisedForecast ?? 0.0;
          aboveForecast = monthlyCrewDetails[dateStr].revisedForecastIsAboveConstructionAdministrationBudget ?? false;
        }
        return (
          <Grid container wrap="nowrap" alignItems="center" spacing={1} justify="flex-end">
            {aboveForecast && (
              <Grid item>
                <Tooltip title={languageContext.getMessage("revisedForecastIsAboveConstructionAdministrationCosts")}>
                  <Info style={{ color: "#FFA500" }} />
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <CurrencyFormat amount={revisedForecast} />
            </Grid>
          </Grid>
        );
      }),
    });
    return resolvedTopData;
  }, [dateColumnData]);

  return (
    <>
      <StickyColumnTable theme={theme} dateColumnData={dateColumnData} mainColumn={mainColumn} tableSumData={tableSumData} tableBodyData={tableBodyData} />
      {/* <TableContainer component={Paper} className={classes.container}>
        <Table size={"small"}>
          <TableHead>
            <TableRow key="mainHeader">
              <TableCell align="left" colSpan={1}></TableCell>
              <TableCell align="left" colSpan={1}></TableCell>
              <TableCell align="left" colSpan={editingCrewResources ? 3 : 2} />
              {usedYears.map((usedYear) => (
                <TableCell align="center" colSpan={1}>
                  {usedYear?.getFullYear() ?? ""}
                </TableCell>
              ))}
            </TableRow>
            <TableRow key="subHeader">
              {editingCrewResources && (
                <TableCell align="center" size="small" className={classes.tableCellHeader}>
                  {languageContext.getMessage("delete")}
                </TableCell>
              )}
              <TableCell align="left" className={classes.tableCellHeaderFixed}>
                {languageContext.getMessage("name")}
              </TableCell>
              <TableCell align="left" className={classes.tableCellHeader}>
                {languageContext.getMessage("role")}
              </TableCell>
              <TableCell align="left" className={classes.tableCellHeader}>
                {showSalaryState ? languageContext.getMessage("monthlySalary") : languageContext.getMessage("monthlyPercentage")}
              </TableCell>
              <TableCell align="left" className={classes.tableCellHeader}>
                {languageContext.getMessage("yearlyWageGrowth")}
              </TableCell>
              {sortedUsedDates.map((usedDate) => (
                <TableCell align="center" className={classes.tableCellHeader}>
                  {usedDate.toLocaleString(language, { month: "long" })}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {crewResources.map((crewResource) => (
              <TableRow key={crewResource.id}>
                {editingCrewResources && crewResource.id && (!(crewResource.id in crewResourcesVerifyDelete) || crewResourcesVerifyDelete[crewResource.id] === false) && (
                  <TableCell align="center" size="small">
                    <Grid container wrap="nowrap" spacing={1} justify="center">
                      <Grid item>
                        <ToggleButton
                          color="inherit"
                          size="small"
                          className={classes.button}
                          value="check"
                          disabled={isSavingCrewList}
                          onChange={() => {
                            if (crewResource.id) {
                              crewResourcesVerifyDelete[crewResource.id] = true;
                              setCrewResourcesVerifyDelete({ ...crewResourcesVerifyDelete });
                            }
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </ToggleButton>
                      </Grid>
                      <Grid item>
                        <ToggleButton
                          color="inherit"
                          size="small"
                          className={classes.button}
                          value="check"
                          disabled={isSavingCrewList}
                          onChange={() => {
                            if (crewResource.id) {
                              resetCrewResource(crewResource);
                            }
                          }}
                        >
                          <RotateLeftIcon fontSize="small" />
                        </ToggleButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                )}
                {editingCrewResources && crewResource.id && crewResource.id in crewResourcesVerifyDelete && crewResourcesVerifyDelete[crewResource.id] === true && (
                  <TableCell align="center" size="small">
                    <Grid container wrap="nowrap" spacing={1} justify="center">
                      <Grid item>
                        <ToggleButton disabled={isSavingCrewList} color="inherit" size="small" className={classes.button} value="check" onChange={async () => await onDeleteCrewResource(crewResource)}>
                          <Check fontSize="small" />
                        </ToggleButton>
                      </Grid>
                      <Grid item>
                        <ToggleButton
                          color="inherit"
                          size="small"
                          className={classes.button}
                          value="check"
                          disabled={isSavingCrewList}
                          onChange={() => {
                            if (crewResource.id) {
                              crewResourcesVerifyDelete[crewResource.id] = false;
                              setCrewResourcesVerifyDelete({ ...crewResourcesVerifyDelete });
                            }
                          }}
                        >
                          <Cancel fontSize="small" />
                        </ToggleButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                )}
                <TableCell className={classes.tableCellFixed} align="left">
                  {!editingCrewResources && <TicketLoading documentId={crewResource.id} component={crewResource.name} />}
                  {editingCrewResources && (
                    <TextField
                      value={crewResource.name ?? ""}
                      onChange={(event) => {
                        crewResource.name = event.target.value;
                        registerCrewResourceToUpdate(crewResource);
                      }}
                      disabled={isSavingCrewList}
                    />
                  )}
                </TableCell>
                <TableCell align="left">
                  {!editingCrewResources && <>{crewResourceContext.convertCrewResourceRoleToString(crewResource.defaultCrewResourceDetails?.role)}</>}
                  {editingCrewResources && (
                    <Select
                      value={crewResource?.defaultCrewResourceDetails?.role}
                      onChange={(event) => {
                        if (crewResource) {
                          crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                          crewResource.defaultCrewResourceDetails.role = event.target.value as CrewResourceRole;
                          registerCrewResourceToUpdate(crewResource);
                        }
                      }}
                      input={<Input />}
                      renderValue={(role) => <div>{crewResourceContext.convertCrewResourceRoleToString(role as CrewResourceRole)}</div>}
                      fullWidth
                      disabled={isSavingCrewList}
                    >
                      {Object.values(CrewResourceRole).map((crewResourceRole) => (
                        <MenuItem key={crewResourceRole} value={crewResourceRole}>
                          {crewResourceContext.convertCrewResourceRoleToString(crewResourceRole)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </TableCell>
                <TableCell align="left">
                  {!editingCrewResources && (
                    <>
                      {!showSalaryState && <PercentageFormat percentage={(crewResource.defaultCrewResourceDetails?.assignmentPercentage ?? 0.0) / 100} />}
                      {showSalaryState && <CurrencyFormat amount={calculatePercentageSalary(crewResource.defaultCrewResourceDetails)} />}
                    </>
                  )}
                  {editingCrewResources && (
                    <>
                      {!showSalaryState && (
                        <PercentageInput
                          percentage={crewResource.defaultCrewResourceDetails?.assignmentPercentage ?? 0.0}
                          onChange={(newAssignmentPercentage) => {
                            crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                            crewResource.defaultCrewResourceDetails.assignmentPercentage = newAssignmentPercentage;
                            registerCrewResourceToUpdate(crewResource);
                          }}
                          fullWidth={true}
                          updateOnDefaultPercentageChange={true}
                          disabled={isSavingCrewList}
                        />
                      )}
                      {showSalaryState && (
                        <CurrencyInput
                          value={crewResource.defaultCrewResourceDetails?.salary ?? 0.0}
                          onChange={(newSalary) => {
                            crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                            crewResource.defaultCrewResourceDetails.salary = newSalary;
                            registerCrewResourceToUpdate(crewResource);
                          }}
                          fullWidth
                          disabled={isSavingCrewList}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell align="left">
                  {!editingCrewResources && (
                    <>
                      <PercentageFormat percentage={(crewResource.defaultCrewResourceDetails?.salaryAdjustmentPercentage ?? 0.0) / 100} />
                    </>
                  )}
                  {editingCrewResources && (
                    <>
                      <PercentageInput
                        percentage={crewResource.defaultCrewResourceDetails?.salaryAdjustmentPercentage ?? 0.0}
                        onChange={(newSalaryAdjustmentPercentage) => {
                          crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                          crewResource.defaultCrewResourceDetails.salaryAdjustmentPercentage = newSalaryAdjustmentPercentage;
                          registerCrewResourceToUpdate(crewResource);
                        }}
                        fullWidth={true}
                        updateOnDefaultPercentageChange={true}
                        disabled={isSavingCrewList}
                      />
                    </>
                  )}
                </TableCell>
                {sortedUsedDates.map((usedDate) => {
                  const dateStr = usedDate.toISOString();
                  if (!crewResource.id || !(crewResource.id ?? "" in monthlyCrewResourceDetails)) {
                    return <></>;
                  }
                  let crewResourceDetails: CrewResourceDetails = { date: usedDate };
                  if (monthlyCrewResourceDetails[crewResource.id] && dateStr in monthlyCrewResourceDetails[crewResource.id]) {
                    crewResourceDetails = monthlyCrewResourceDetails[crewResource.id][dateStr];
                  }
                  return (
                    <TableCell align="center">
                      {!editingCrewResources && (
                        <>
                          {!showSalaryState && <PercentageFormat percentage={(crewResourceDetails.assignmentPercentage ?? 0.0) / 100} />}
                          {showSalaryState && <CurrencyFormat amount={calculatePercentageSalary(crewResourceDetails)} />}
                        </>
                      )}
                      {editingCrewResources && (
                        <>
                          {!showSalaryState && (
                            <PercentageInput
                              percentage={crewResourceDetails.assignmentPercentage ?? 0.0}
                              onChange={(newAssignmentPercentage) => {
                                crewResourceDetails.assignmentPercentage = newAssignmentPercentage;
                                crewResource.monthlyCrewResourceDetails = crewResource.monthlyCrewResourceDetails ?? [];
                                const index = crewResource.monthlyCrewResourceDetails.findIndex((item: CrewResourceDetails) => item.date?.toISOString() === dateStr);
                                if (index >= 0) {
                                  crewResource.monthlyCrewResourceDetails[index] = crewResourceDetails;
                                } else {
                                  crewResource.monthlyCrewResourceDetails.push(crewResourceDetails);
                                }
                                registerCrewResourceToUpdate(crewResource);
                              }}
                              fullWidth={true}
                              updateOnDefaultPercentageChange={true}
                              disabled={isSavingCrewList}
                            />
                          )}
                          {showSalaryState && (
                            <CurrencyInput
                              value={crewResourceDetails.salary ?? 0.0}
                              onChange={(newSalary) => {
                                crewResourceDetails.salary = newSalary;
                                crewResource.monthlyCrewResourceDetails = crewResource.monthlyCrewResourceDetails ?? [];
                                const index = crewResource.monthlyCrewResourceDetails.findIndex((item) => item.date?.toISOString() === dateStr);
                                if (index >= 0) {
                                  crewResource.monthlyCrewResourceDetails[index] = crewResourceDetails;
                                } else {
                                  crewResource.monthlyCrewResourceDetails.push(crewResourceDetails);
                                }
                                registerCrewResourceToUpdate(crewResource);
                              }}
                              fullWidth
                              disabled={isSavingCrewList}
                            />
                          )}
                        </>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            <TableRow key="summaryDivider">
              <TableCell colSpan={sortedUsedDates.length + (editingCrewResources ? 5 : 4)}>
                <Divider />
              </TableCell>
            </TableRow>
            <TableRow key="sumAssignedPercentages">
              {editingCrewResources && <TableCell colSpan={1} />}
              <TableCell colSpan={1}></TableCell>
              <TableCell colSpan={1}></TableCell>
              <TableCell colSpan={1} />
              <TableCell className={classes.tableCellFixed} colSpan={1}>{`${languageContext.getMessage("sum")} %`}</TableCell>
              {sortedUsedDates.map((usedDate) => {
                const dateStr = usedDate.toISOString();
                let sumAssignedPercentages = 0.0;
                if (dateStr in monthlyCrewDetails) {
                  sumAssignedPercentages = monthlyCrewDetails[dateStr].sumAssignedPercentages ?? 0.0;
                }
                return (
                  <TableCell align="center">
                    <PercentageFormat percentage={sumAssignedPercentages / 100} showHealthStatus={false} />
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow key="expectedExpense">
              {editingCrewResources && <TableCell colSpan={1} />}
              <TableCell colSpan={2}></TableCell>
              <TableCell colSpan={1} />
              <TableCell className={classes.tableCellFixed} colSpan={1}>
                {languageContext.getMessage("expectedPostedExpense")}
              </TableCell>
              {sortedUsedDates.map((usedDate) => {
                const dateStr = usedDate.toISOString();
                let expectedExpense: number = 0.0;
                if (dateStr in monthlyCrewDetails) {
                  expectedExpense = monthlyCrewDetails[dateStr].expectedExpense ?? 0.0;
                }
                return (
                  <TableCell align="center">
                    <CurrencyFormat amount={expectedExpense} />
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow key="expectedAccumulatedExpense">
              {editingCrewResources && <TableCell colSpan={1} />}

              <TableCell colSpan={2}></TableCell>
              <TableCell colSpan={1} />
              <TableCell className={classes.tableCellFixed} colSpan={1}>
                {languageContext.getMessage("expectedAccumulatedPostedExpense")}
              </TableCell>
              {sortedUsedDates.map((usedDate) => {
                const dateStr = usedDate.toISOString();
                let expectedAccumulatedExpense: number = 0.0;
                if (dateStr in monthlyCrewDetails) {
                  expectedAccumulatedExpense = monthlyCrewDetails[dateStr].expectedAccumulatedExpense ?? 0.0;
                }
                return (
                  <TableCell align="center">
                    <CurrencyFormat amount={expectedAccumulatedExpense} />
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow key="actuallyPostedExpense">
              <TableCell colSpan={editingCrewResources ? 4 : 3} />
              <TableCell className={classes.tableCellFixed} colSpan={1}>
                {languageContext.getMessage("actuallyPostedExpense")}
              </TableCell>
              {sortedUsedDates.map((usedDate) => {
                const dateStr = usedDate.toISOString();
                let actuallyPostedExpense: number = 0.0;
                if (dateStr in monthlyCrewDetails) {
                  actuallyPostedExpense = monthlyCrewDetails[dateStr].actuallyPostedExpense ?? 0.0;
                }
                return (
                  <TableCell align="center">
                    <CurrencyFormat amount={actuallyPostedExpense} />
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow key="actuallyAccumulatedPostedExpense">
              <TableCell colSpan={editingCrewResources ? 4 : 3} />
              <TableCell className={classes.tableCellFixed} colSpan={1}>
                {languageContext.getMessage("actuallyAccumulatedPostedExpense")}
              </TableCell>
              {sortedUsedDates.map((usedDate) => {
                const dateStr = usedDate.toISOString();
                let actuallyAccumulatedPostedExpense: number = 0.0;
                if (dateStr in monthlyCrewDetails) {
                  actuallyAccumulatedPostedExpense = monthlyCrewDetails[dateStr].actuallyAccumulatedPostedExpense ?? 0.0;
                }
                return (
                  <TableCell align="center">
                    <CurrencyFormat amount={actuallyAccumulatedPostedExpense} />
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow key="revisedForecast">
              <TableCell colSpan={editingCrewResources ? 4 : 3} />
              <TableCell className={classes.tableCellFixed} colSpan={1}>
                {languageContext.getMessage("revisedForecast")}
              </TableCell>
              {sortedUsedDates.map((usedDate) => {
                const dateStr = usedDate.toISOString();
                let revisedForecast: number = 0.0;
                let aboveForecast = false;
                if (dateStr in monthlyCrewDetails) {
                  revisedForecast = monthlyCrewDetails[dateStr].revisedForecast ?? 0.0;
                  aboveForecast = monthlyCrewDetails[dateStr].revisedForecastIsAboveConstructionAdministrationBudget ?? false;
                }
                return (
                  <TableCell align="right">
                    <Grid container wrap="nowrap" alignItems="center" spacing={1} justify="flex-end">
                      {aboveForecast && (
                        <Grid item>
                          <Tooltip title={languageContext.getMessage("revisedForecastIsAboveConstructionAdministrationCosts")}>
                            <Info style={{ color: "#FFA500" }} />
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item>
                        <CurrencyFormat amount={revisedForecast} />
                      </Grid>
                    </Grid>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer> */}
    </>
  );
};

export default withTheme(CrewResourceDetailsList);
