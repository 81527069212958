import { Button, Divider, Grid, IconButton, Paper, Snackbar, Tab, Tabs, TextField, Typography, withTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SelectAccount from "../../component/accountComponents/SelectAccount";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { Account, AccountType, ChangeOrderCounterClaim, ChangeOrderExpected, ChangeOrderSubContractor } from "../../contracts/contracts";
import { useAccountContext } from "../../contexts/account/accountContext";
import ContractList from "../../component/contractComponents/ContractList";
import InvoiceGraph from "../../component/invoiceComponents/InvoiceGraph";
import { InvoiceTabs, useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { useForecastsContext } from "../../contexts/forecast/forecastsContext";
import EditAccountDialog from "../../component/accountComponents/EditAccountDialog";
import { useProjectContext } from "../../contexts/project/projectContext";
import ChangeOrderExpectedList from "../../component/changeOrderExpectedComponents/ChangeOrderExpectedList";
import ChangeOrderSubContractorList from "../../component/changeOrderSubContractorComponents/ChangeOrderSubContractorList";
import { Dictionary } from "../../global-types";
import { ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import ChangeOrderCounterClaimList from "../../component/changeOrderCounterClaimComponents/ChangeOrderCounterClaimList";
import { Edit, Save, Add, GetApp } from "@material-ui/icons";
import CurrencyTable, { CurrencyTableElement, CurrencyTableGroup } from "../../component/accountComponents/CurrencyTable";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";
import EditContractDialog from "../../component/contractComponents/EditContractDialog";
import { useContractContext } from "../../contexts/contract/contractContext";
import { Alert } from "@material-ui/lab";
import EditChangeOrderSubContractorDialog from "../../component/changeOrderSubContractorComponents/EditChangeOrderSubContractorDialog";
import EditChangeOrderExpectedDialog from "../../component/changeOrderExpectedComponents/EditChangeOrderExpectedDialog";
import EditChangeOrderCounterClaimDialog from "../../component/changeOrderCounterClaimComponents/EditChangeOrderCounterClaimDialog";
import { downloadAccountReport } from "../../utils/accountTools";
import { useTemplateEngineQueriesContext } from "../../contexts/templateEngine/queries/templateEngineQueriesContext";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useChangeOrderCounterClaimContext } from "../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext";

export interface AccountDialogStates {
  contract?: boolean;
  changeOrderExpected?: boolean;
  changeOrderSubContractor?: boolean;
  changeOrderCounterClaim?: boolean;
}

type props = {
  selectedAccount: Account | undefined;
  onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
  accountTypeFocus?: AccountType;
  detailTabIndex: string;
  handleDetailTabIndexChange: (event: React.ChangeEvent<{}>, newTabIndex: string) => void;
};

const AccountDetailsView: React.FC<props> = ({ selectedAccount, onSelectedAccountChange, accountTypeFocus, detailTabIndex, handleDetailTabIndexChange }) => {
  const languageContext = useLanguageContext();

  const urlContext = useUrlContext();
  const projectContext = useProjectContext();
  const invoiceContext = useInvoiceContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const forecastContext = useForecastsContext();
  const menuContext = useMenuContext();
  const userRoleContext = useUserRoleContext();
  const templateEngineQueriesContext = useTemplateEngineQueriesContext();
  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();

  const users = userRoleContext.getUsers();
  const urlState = urlContext.getUrlState();

  const showOnlyForecast = urlState.showOnlyForecast === "true";
  const setShowOnlyForecast = (updateShowOnlyForecast: boolean) => {
    const newUrlState = {
      ...urlState,
      ...{ showOnlyForecast: updateShowOnlyForecast },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.replaceUrlQuery(urlQuery);
  };

  const [dialogStates, setDialogStates] = useState<AccountDialogStates>({ contract: false, changeOrderExpected: false, changeOrderSubContractor: false, changeOrderCounterClaim: false });

  const [accountNotes, setAccountNotes] = useState<string | undefined>(selectedAccount?.comment ?? "");
  const [accountNotesChanged, setAccountNotesChanged] = useState<boolean>(false);
  const [accountNotesSaved, setAccountNotesSaved] = useState<boolean>(false);

  function notesSaved() {
    setAccountNotesSaved(true);
  }

  function saveNotes() {
    if (selectedAccount) {
      accountContext.mutateAccount({ ...selectedAccount, comment: accountNotes }, notesSaved);
      setAccountNotesChanged(false);
    }
  }

  const tableData: CurrencyTableElement[] = [
    {
      group: languageContext.getMessage("account"),
      label: languageContext.getMessage("revisedAccountBudget"),
      value: selectedAccount?.forecast?.revisedAccountBudget,
      markedValue: selectedAccount?.forecast?.revisedAccountBudget,
      isMarked: !selectedAccount?.forecast?.contractCostScopeUsedInForecast,
    },
    {
      group: languageContext.getMessage("account"),
      label: languageContext.getMessage("accountBudget"),
      value: selectedAccount?.budget,
    },
    { group: languageContext.getMessage("account"), label: languageContext.getMessage("netAccountBudget"), value: selectedAccount?.forecast?.netAccountBudget },
    {
      group: languageContext.getMessage("account"),
      label: languageContext.getMessage("invoicedTotal"),
      value: selectedAccount?.invoicedAmountTotal ?? 0,
    },
    { group: languageContext.getMessage("account"), label: languageContext.getMessage("payedOut"), value: selectedAccount?.invoicedAmount },
    { group: languageContext.getMessage("account"), label: languageContext.getMessage("whereAsWithheld"), value: selectedAccount?.invoicedWithheldAmount },
    {
      group: languageContext.getMessage("contracts"),
      label: languageContext.getMessage("sumContractBudget"),
      value: selectedAccount?.sumContractBudget,
      markedValue: selectedAccount?.forecast?.contractBudgetUsedInCostScope,
      isMarked: selectedAccount?.forecast?.contractCostScopeUsedInForecast,
    },
    {
      group: languageContext.getMessage("contracts"),
      label: languageContext.getMessage("sumContractProcurement"),
      value: selectedAccount?.sumContractProcurement,
      markedValue: selectedAccount?.forecast?.contractProcurementUsedInCostScope,
      isMarked: selectedAccount?.forecast?.contractCostScopeUsedInForecast,
    },
    {
      group: languageContext.getMessage("contracts"),
      label: languageContext.getMessage("invoicedTotal"),
      value: selectedAccount?.sumContractInvoicedAmountTotal ?? 0,
    },
    { group: languageContext.getMessage("contracts"), label: languageContext.getMessage("payedOut"), value: selectedAccount?.sumContractInvoicedAmount },
    { group: languageContext.getMessage("contracts"), label: languageContext.getMessage("whereAsWithheld"), value: selectedAccount?.sumContractInvoicedWithheldAmount },
    {
      group: languageContext.getMessage("changeOrder"),
      label: languageContext.getMessage("cost"),
      value: selectedAccount?.forecast?.changeOrderSubContractorCosts,
      isMarked: selectedAccount?.forecast?.contractCostScopeUsedInForecast,
      markedValue: selectedAccount?.forecast?.changeOrderSubContractorCosts,
    },
    {
      group: languageContext.getMessage("changeOrder"),
      label: languageContext.getMessage("invoicedTotal"),
      value: (selectedAccount?.sumChangeOrderSubContractorInvoicedAmount ?? 0) + (selectedAccount?.sumChangeOrderSubContractorInvoicedWithheldAmount ?? 0),
    },
    {
      group: languageContext.getMessage("adjustmentsRisksAndUnforeseen"),
      label: languageContext.getMessage("riskAdjustments"),
      value: selectedAccount?.riskCostAdjustment,
    },
    {
      group: languageContext.getMessage("adjustmentsRisksAndUnforeseen"),
      label: languageContext.getMessage("expectedChange"),
      value: selectedAccount?.forecast?.changeOrderExpectedCosts,
      isMarked: true,
      markedValue: selectedAccount?.forecast?.changeOrderExpectedCosts,
    },
    {
      group: languageContext.getMessage("adjustmentsRisksAndUnforeseen"),
      label: languageContext.getMessage("counterClaim"),
      value: selectedAccount?.forecast?.changeOrderCounterClaimCosts,
      isMarked: selectedAccount?.forecast?.contractCostScopeUsedInForecast,
      markedValue: (selectedAccount?.forecast?.changeOrderCounterClaimCosts ?? 0.0) * -1,
    },
  ];

  const tableDataGroups: CurrencyTableGroup[] = [
    { value: languageContext.getMessage("account"), isMarked: !selectedAccount?.forecast?.contractCostScopeUsedInForecast },
    { value: languageContext.getMessage("contracts"), isMarked: selectedAccount?.forecast?.contractCostScopeUsedInForecast },
    { value: languageContext.getMessage("changeOrder"), isMarked: true },
    { value: languageContext.getMessage("adjustmentsRisksAndUnforeseen"), isMarked: true },
  ];

  const changeOrderExpectedRoute = menuContext.getApplicationRouteById(ApplicationRouteId.changeOrderExpected);
  const updateUrlStateToChangeOrderExpectedDetails = (newSelectedChangeOrder: ChangeOrderExpected | undefined): void => {
    const newUrlState = {
      ...{ changeOrderExpectedId: newSelectedChangeOrder?.id },
      ...{ tab: "details" },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery, changeOrderExpectedRoute.route);
  };

  const changeOrderSubContractorRoute = menuContext.getApplicationRouteById(ApplicationRouteId.changeOrderSubContractor);
  const updateUrlStateToChangeOrderSubContractorDetails = (newSelectedChangeOrder: ChangeOrderSubContractor | undefined): void => {
    const newUrlState = {
      ...{ changeOrderSubContractorId: newSelectedChangeOrder?.id },
      ...{ tab: "details" },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery, changeOrderSubContractorRoute.route);
  };

  const changeOrderCounterClaimRoute = menuContext.getApplicationRouteById(ApplicationRouteId.changeOrderCounterClaim);
  const updateUrlStateToChangeOrderCounterClaimDetails = (newSelectedChangeOrder: ChangeOrderCounterClaim | undefined): void => {
    const newUrlState = {
      ...{ changeOrderCounterClaimId: newSelectedChangeOrder?.id },
      ...{ tab: "details" },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery, changeOrderCounterClaimRoute.route);
  };

  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const openEditDialog = () => {
    setShowEditDialog(true);
  };

  const onCloseEditDialog = (mutatedAccount?: Account) => {
    if (mutatedAccount) {
      accountContext.mutateAccount(mutatedAccount);
    }

    setShowEditDialog(false);
  };

  useEffect(() => {
    if (selectedAccount?.id) {
      const forecastSearch = forecastContext.getForecastsSearch();
      forecastSearch.accountId = selectedAccount?.id;
      forecastContext.searchForecasts(forecastSearch);

      const invoiceSearch = invoiceContext.getInvoiceSearch();
      invoiceSearch.accountId = selectedAccount.id;
      invoiceContext.searchInvoices(invoiceSearch);
    }
  }, [selectedAccount]);

  const invoices = selectedAccount?.id ? invoiceContext.getFilteredInvoices({ accountId: selectedAccount.id }) : [];

  return (
    <>
      {dialogStates.contract && (
        <EditContractDialog
          open={dialogStates.contract}
          onClose={(mutatedContract) => {
            if (mutatedContract) {
              contractContext.mutateContract(mutatedContract);
            }
            setDialogStates({ ...dialogStates, contract: false });
          }}
          contract={{ accountId: selectedAccount?.id, projectId: selectedAccount?.projectId }}
          handleNewContract={true}
        />
      )}
      {dialogStates.changeOrderCounterClaim && (
        <EditChangeOrderCounterClaimDialog
          open={dialogStates.changeOrderCounterClaim}
          onClose={() => setDialogStates({ ...dialogStates, changeOrderCounterClaim: false })}
          changeOrderCounterClaim={{ accountId: selectedAccount?.id, projectId: selectedAccount?.projectId }}
        />
      )}
      {dialogStates.changeOrderExpected && (
        <EditChangeOrderExpectedDialog
          open={dialogStates.changeOrderExpected}
          onClose={() => setDialogStates({ ...dialogStates, changeOrderExpected: false })}
          changeOrderExpected={{ accountId: selectedAccount?.id, projectId: selectedAccount?.projectId }}
        />
      )}
      {dialogStates.changeOrderSubContractor && (
        <EditChangeOrderSubContractorDialog
          open={dialogStates.changeOrderSubContractor}
          onClose={() => setDialogStates({ ...dialogStates, changeOrderSubContractor: false })}
          changeOrderSubContractor={{ accountId: selectedAccount?.id, projectId: selectedAccount?.projectId }}
        />
      )}
      {showEditDialog && <EditAccountDialog open={showEditDialog} onClose={onCloseEditDialog} account={selectedAccount} accountTypeFocus={accountTypeFocus}></EditAccountDialog>}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper style={{ padding: "1em" }} elevation={2}>
            <Grid container wrap="nowrap" spacing={1} alignItems="flex-end">
              <Grid item>
                <SelectAccount selectedAccount={selectedAccount} accountTypeFocus={accountTypeFocus} onSelectedAccountChange={onSelectedAccountChange} minWidth={200} />
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={openEditDialog} startIcon={<Edit />}>
                  {languageContext.getMessage("edit")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setDialogStates({ ...dialogStates, contract: true })} startIcon={<Add />}>
                  {languageContext.getMessage("contract")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setDialogStates({ ...dialogStates, changeOrderExpected: true })} startIcon={<Add />}>
                  {languageContext.getMessage("expectedChange")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setDialogStates({ ...dialogStates, changeOrderSubContractor: true })} startIcon={<Add />}>
                  {`${languageContext.getMessage("changeOrder")} ${languageContext.getMessage("subContractorShort")}`}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setDialogStates({ ...dialogStates, changeOrderCounterClaim: true })} startIcon={<Add />}>
                  {languageContext.getMessage("counterClaim")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={!selectedAccount?.id}
                  onClick={() => {
                    downloadAccountReport(
                      templateEngineQueriesContext,
                      contractContext,
                      changeOrderCounterClaimContext,
                      changeOrderExpectedContext,
                      changeOrderSubContractorContext,
                      languageContext,
                      accountContext,
                      selectedAccount?.id
                    );
                  }}
                  startIcon={<GetApp />}
                >
                  {languageContext.getMessage("report")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 0 }} elevation={2}>
            <Tabs
              value={detailTabIndex}
              onChange={(event, value) => {
                handleDetailTabIndexChange(event, value);
              }}
            >
              <Tab label={languageContext.getMessage("general")} value="general" />
              <Tab label={languageContext.getMessage("contracts")} value="contracts" />
              <Tab label={languageContext.getMessage("expectedChange")} value="changeOrderExpected" />
              <Tab label={`${languageContext.getMessage("changeOrder")} ${languageContext.getMessage("subContractorShort")}`} value="changeOrderSubContractor" />
              <Tab label={languageContext.getMessage("counterClaim")} value="changeOrderCounterClaim" />
              <Tab label={languageContext.getMessage("invoices")} value="Invoices" />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {detailTabIndex === "general" && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CurrencyTable
                  tableData={tableData}
                  groups={tableDataGroups}
                  title={languageContext.getMessage("summary")}
                  showMarked={showOnlyForecast}
                  setShowMarked={setShowOnlyForecast}
                  markedTitle={languageContext.getMessage("showForecastOnly")}
                  totalTitle={languageContext.getMessage("finalForecast")}
                  totalValue={selectedAccount?.forecast?.forecast}
                />
              </Grid>
              <Grid item xs={6}>
                <Paper style={{ padding: "1em" }} elevation={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container wrap="nowrap" spacing={1} alignItems="center">
                        <Grid item>
                          <Typography variant="h5">{languageContext.getMessage("notes")}</Typography>
                        </Grid>
                        <Grid item>
                          <IconButton size="small" disabled={!accountNotesChanged} onClick={saveNotes}>
                            <Save />
                          </IconButton>
                        </Grid>
                        <Grid item>{languageContext.getMessage("changedBy") + ": " + users.find((user) => user.id === selectedAccount?.changedBy)?.name ?? ""}</Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={accountNotes}
                        onChange={(event) => {
                          setAccountNotes(event.target.value);
                          if (accountNotesChanged === false) {
                            setAccountNotesChanged(true);
                          }
                        }}
                        fullWidth
                        multiline
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
          {detailTabIndex === "contracts" && <ContractList accountId={selectedAccount?.id} hideToolbar={true} />}
          {detailTabIndex === "changeOrderExpected" && (
            <ChangeOrderExpectedList selectedAccount={selectedAccount} showTitle={true} onSelectedChangeOrderExpectedChange={updateUrlStateToChangeOrderExpectedDetails} hideToolbar={true} />
          )}
          {detailTabIndex === "changeOrderSubContractor" && (
            <ChangeOrderSubContractorList
              selectedAccount={selectedAccount}
              showTitle={true}
              onSelectedChangeOrderSubContractorChange={updateUrlStateToChangeOrderSubContractorDetails}
              hideToolbar={true}
            />
          )}
          {detailTabIndex === "changeOrderCounterClaim" && (
            <ChangeOrderCounterClaimList
              selectedAccount={selectedAccount}
              showTitle={true}
              onSelectedChangeOrderCounterClaimChange={updateUrlStateToChangeOrderCounterClaimDetails}
              hideToolbar={true}
            />
          )}
          {detailTabIndex === "Invoices" && (
            <InvoiceGraph
              invoices={invoices}
              onShowInvoicesFilter={{
                selectedAccountIdFilter: selectedAccount?.id,
                tab: InvoiceTabs.processed,
              }}
              fullWidth={true}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar open={accountNotesSaved} autoHideDuration={1800} onClose={() => setAccountNotesSaved(false)}>
        <Alert severity="success">{languageContext.getMessage("notesSaved")}</Alert>
      </Snackbar>
    </>
  );
};

export default withTheme(AccountDetailsView);
