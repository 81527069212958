import React, { createContext, useContext, useEffect, useState } from "react";
import { Account, AccountType, ActiveState, DocumentType, GroupedAccount } from "../../contracts/contracts";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { receiveForecast, receiveForecastReport } from "../forecast/forecastsTools";
import { InterimContext } from "../interim/InterimContext";
import { receiveInterimExpense } from "../interim/InterimTools";
import { useLanguageContext } from "../language/LanguageContext";
import { useProjectContext } from "../project/projectContext";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { useUrlContext } from "../url/urlContext";
import { accountListTemplateAlternatives, queryTemplateEngineToProduceAccountExcelList } from "./AccountListExportTools";
import { AccountMutationsContextProvider, useAccountMutationsContext } from "./mutations/accountMutationsContext";
import { AccountQueriesContextContext, useAccountQueriesContext } from "./queries/accountQueriesContext";
import { AccountSubscriptionsContextProvider, useAccountSubscriptionsContext } from "./subscriptions/accountSubscriptionsContext";
import { sortAccountByAccountNumber, sortAccountByGroup } from "../../utils/accountTools";

export interface AccountContext {
    getAccountSearch: () => Account,
    searchAccounts: (accountSearch: Account) => void,
    getAllAccounts: () => Account[],
    getAccounts: (accountType?: AccountType) => Array<Account>,
    getAccount: (id: string | undefined) => Account | undefined,
    mutateAccount: (account: Account, onMutated?: (account: Account) => void, onFinished?: () => void) => void,
    accountTypeToMessage: (accountType: AccountType | string) => string,
    downloadAccounts: (accountsToDownload: GroupedAccount[], exportType: accountListTemplateAlternatives, interimContext: InterimContext) => void,
    loadingAccounts: boolean,
}

const AccountContext = createContext<AccountContext>(null as unknown as AccountContext);

export const AccountContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <AccountMutationsContextProvider>
            <AccountQueriesContextContext>
                <AccountSubscriptionsContextProvider>
                    <AccountSubContextProvider>
                        {children}
                    </AccountSubContextProvider>
                </AccountSubscriptionsContextProvider>
            </AccountQueriesContextContext>
        </AccountMutationsContextProvider>
    );
}

export const AccountSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const projectContext = useProjectContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const languageContext = useLanguageContext();
    const accountMutationsContext = useAccountMutationsContext();
    const accountQueriesContext = useAccountQueriesContext();
    const accountSubscriptionsContext = useAccountSubscriptionsContext();
    const [currentAccountSearch, setCurrentAccountSearch] = useState<Account | undefined>(undefined);
    const [accounts, setAccounts] = useState<Array<Account>>([]);

    const mergeAccounts = (oldAccounts: Array<Account>, newAccounts: Array<Account>): Array<Account> => {
        let updatedAccounts = oldAccounts.slice();
        if (!newAccounts) {
            console.error(`Received undefined set of accounts: ${newAccounts}`);
            return [];
        }
        newAccounts.forEach(newAccount => {
            if (newAccount.projectId !== currentAccountSearch?.projectId) {
                return;
            }
            newAccount.created = new Date(newAccount.created ?? 0);
            receiveForecast(newAccount.forecast, newAccount.projectId, newAccount.id, languageContext);
            receiveForecastReport(newAccount.forecastReport, newAccount.projectId, newAccount.id, languageContext)
            receiveInterimExpense({parentDocumentId: newAccount.id, parentDocumentType: DocumentType.ACCOUNT}, newAccount.interimExpense);
            const index = updatedAccounts.findIndex(account => account.id === newAccount.id);
            if (index >= 0) {
                if (newAccount.state === ActiveState.ACTIVE) {
                    updatedAccounts[index] = newAccount;
                }
                else {
                    updatedAccounts.splice(index, 1);
                }
            } 
            else {
                if (newAccount.state === ActiveState.ACTIVE) {
                    updatedAccounts.push(newAccount); 
                }
            }
        });
        return updatedAccounts.sort(sortAccountByAccountNumber).sort(sortAccountByGroup);
    }

    const getAccountSearch = (): Account => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
    
        return {
            projectId: projectId,
        }
    }

    const searchAccounts = (accountSearch: Account): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(accountSearch, currentAccountSearch);
        matched = matched && accountSearch?.projectId === currentAccountSearch?.projectId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && !matched) {
            setCurrentAccountSearch(accountSearch);
            setAccounts([]);
        }
    }

    const getAllAccounts = (): Account[] => {
        const projectId = projectContext.getSelectedProject()?.id;
        if (!projectId) return [];

        return accounts
            .filter(account => 
                account.projectId === projectId);
    }

    const getAccounts = (accountType?: AccountType): Array<Account> => {
        const projectId = projectContext.getSelectedProject()?.id;
        if (!projectId) return [];

        return accounts
            .filter(account => 
                account.projectId === projectId && 
                (!accountType || account.accountType === accountType))
    }

    const getAccount = (id: string | undefined): Account | undefined => {
        return accounts.find(account => account.id === id);
    }

    const accountTypeToMessage = (accountType: AccountType | string): string => {
        if (accountType === AccountType.NORMAL) {
            return languageContext.getMessage('normal');
        }
        else if (accountType === AccountType.ACCRUAL) {
            return languageContext.getMessage('accrual');
        }
        else if (accountType === AccountType.ADMINISTRATION) {
            return languageContext.getMessage('administration');
        }
        else {
            return languageContext.getMessage('normal');
        }
    }

    const mutateAccount = (account: Account, onMutated?: (account: Account) => void, onFinished?: () => void): void => {
        accountMutationsContext.mutateAccount(account, (documentId, variables) => {
            account = {...account, ...variables};
            account.id = documentId;
            setAccounts(mergeAccounts(accounts, [account]));
            if (onMutated) {
                onMutated(account);
            }
        }, onFinished);
    }

    const downloadAccounts = (accountsToDownload: GroupedAccount[], exportType: accountListTemplateAlternatives, interimContext: InterimContext): void => {
        queryTemplateEngineToProduceAccountExcelList(
            accountsToDownload, 
            exportType,
            templateEngineQueriesContext, 
            languageContext, 
            interimContext);
    }

    useEffect(() => {
        if (currentAccountSearch) {
            accountQueriesContext.queryAccounts(currentAccountSearch);
        }
    }, [currentAccountSearch]);
    
    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setAccounts([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setAccounts(mergeAccounts([], accountQueriesContext.fetchedAccounts));
    }, [accountQueriesContext.fetchedAccounts]);

    useEffect(() => {
        setAccounts(mergeAccounts(accounts, accountSubscriptionsContext.subscribedAccounts));
    }, [accountSubscriptionsContext.subscribedAccounts]);
    
    const accountContext: AccountContext = {
        getAccountSearch,
        searchAccounts,
        getAllAccounts,
        getAccounts,
        getAccount,
        mutateAccount,
        accountTypeToMessage,
        downloadAccounts,
        loadingAccounts: accountQueriesContext.loadingAccounts,
    };

    return (
        <AccountContext.Provider value={accountContext}>
            {children}
        </AccountContext.Provider>
    );
}

export const useAccountContext = (): AccountContext => {
    return useContext(AccountContext);
}
