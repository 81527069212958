import { Grid, withTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DatePicker } from "../dateComponents/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import { defaultAimzExternalProviderId, useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { Invoice, InvoiceFilter, InvoiceFlag } from "../../contracts/contracts";
import { useUrlContext } from "../../contexts/url/urlContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { splitExternalId } from "../../utils/externalIdTools";
import { useAccountContext } from "../../contexts/account/accountContext";
import { Dictionary } from "../../global-types";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { guidIsNullOrEmpty } from "../../utils/guidTools";
import { tryDecodeAndSplitFromUrl, SplitAndEncodeToUrl } from "../../utils/urlTools";

type props = {
  onInvoicesUpdate: (invoices: Invoice[]) => void;
  showApprovedInvoices?: boolean;
  showInterimAccountedInvoices?: boolean;
  showProcessedInvoices?: boolean;
  showRejectedInvoices?: boolean;
  fromDateOverride?: Date;
  toDateOverride?: Date;
  skipFromDateFilter?: boolean;
  skipToDateFilter?: boolean;
};

const InvoicesFilterTab: React.FC<props> = ({
  onInvoicesUpdate,
  showApprovedInvoices,
  showInterimAccountedInvoices,
  showProcessedInvoices,
  showRejectedInvoices,
  fromDateOverride,
  toDateOverride,
  skipFromDateFilter,
  skipToDateFilter,
}) => {
  const urlContext = useUrlContext();
  const languageContext = useLanguageContext();
  const projectContext = useProjectContext();
  const invoiceContext = useInvoiceContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();

  const [supplierFilter, setSupplierFilter] = useState<string[]>([]);
  const [accountIdFilter, setAccountIdFilter] = useState<string[]>([]);
  const [contractIdFilter, setContractIdFilter] = useState<string[]>([]);
  const [ChangeOrderSubContractorIdFilter, setChangeOrderSubContractorIdFilter] = useState<string[]>([]);
  const [externalProjectIdFilter, setExternalProjectIdFilter] = useState<string[]>([]);
  const [externalProviderFilter, setExternalProviderFilter] = useState<string[]>([]);

  const urlState = urlContext.getUrlState();
  const splitString = ";";
  const fromDate = urlState.fromDate ? new Date(urlState.fromDate as string) : undefined;
  const toDate = urlState.toDate ? new Date(urlState.toDate as string) : undefined;
  const selectedSupplierFilter = tryDecodeAndSplitFromUrl(urlState.selectedSupplierFilter, splitString, true);
  const selectedAccountIdFilter = tryDecodeAndSplitFromUrl(urlState.selectedAccountIdFilter, splitString);
  const selectedContractIdFilter = tryDecodeAndSplitFromUrl(urlState.selectedContractIdFilter, splitString);
  const selectedChangeOrderSubContractorIdFilter = tryDecodeAndSplitFromUrl(urlState.selectedChangeOrderSubContractorIdFilter, splitString);
  const selectedExternalProjectIdFilter = tryDecodeAndSplitFromUrl(urlState.selectedExternalProjectIdFilter, splitString);
  const selectedExternalProviderFilter = tryDecodeAndSplitFromUrl(urlState.selectedExternalProviderFilter, splitString);
  const selectedInvoiceFlagsFilterRaw = tryDecodeAndSplitFromUrl(urlState.selectedInvoiceFlagsFilter, splitString);

  const selectedInvoiceFlagsFilter = selectedInvoiceFlagsFilterRaw.filter(
    (invoiceFlag) => Object.values(InvoiceFlag).findIndex((actualInvoiceFlag) => actualInvoiceFlag === invoiceFlag) >= 0
  ) as InvoiceFlag[];

  const updateUrlState = (
    newFromDate: Date | undefined,
    newToDate: Date | undefined,
    newSelectedSupplierFilter: string[],
    newSelectedAccountIdFilter: string[],
    newSelectedContractIdFilter: string[],
    newSelectedChangeOrderSubContractorIdFilter: string[],
    newSelectedExternalProjectIdFilter: string[],
    newSelectedExternalProviderFilter: string[],
    newSelectedInvoiceFlagsFilter: string[]
  ): void => {
    const newUrlState = {
      ...urlState,
      ...{ fromDate: newFromDate },
      ...{ toDate: newToDate },
      ...{
        selectedSupplierFilter: SplitAndEncodeToUrl(newSelectedSupplierFilter, splitString, true),
      },
      ...{
        selectedAccountIdFilter: SplitAndEncodeToUrl(newSelectedAccountIdFilter, splitString),
      },
      ...{
        selectedContractIdFilter: SplitAndEncodeToUrl(newSelectedContractIdFilter, splitString),
      },
      ...{
        selectedChangeOrderSubContractorIdFilter: SplitAndEncodeToUrl(newSelectedChangeOrderSubContractorIdFilter, splitString),
      },
      ...{
        selectedExternalProjectIdFilter: SplitAndEncodeToUrl(newSelectedExternalProjectIdFilter, splitString),
      },
      ...{
        selectedExternalProviderFilter: SplitAndEncodeToUrl(newSelectedExternalProviderFilter, splitString),
      },
      ...{
        selectedInvoiceFlagsFilter: SplitAndEncodeToUrl(newSelectedInvoiceFlagsFilter, splitString),
      },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const handleChangeSupplierFilter = (value: string[]) => {
    updateUrlState(fromDate, toDate, value, selectedAccountIdFilter, selectedContractIdFilter, selectedChangeOrderSubContractorIdFilter, selectedExternalProjectIdFilter, selectedExternalProviderFilter, selectedInvoiceFlagsFilter);
  };

  const handleChangeAccountIdFilter = (value: string[]) => {
    updateUrlState(fromDate, toDate, selectedSupplierFilter, value, selectedContractIdFilter, selectedChangeOrderSubContractorIdFilter, selectedExternalProjectIdFilter, selectedExternalProviderFilter, selectedInvoiceFlagsFilter);
  };

  const handleChangeContractIdFilter = (value: string[]) => {
    updateUrlState(fromDate, toDate, selectedSupplierFilter, selectedAccountIdFilter, value, selectedChangeOrderSubContractorIdFilter, selectedExternalProjectIdFilter, selectedExternalProviderFilter, selectedInvoiceFlagsFilter);
  };

  const handleChangeChangeOrderSubContractorIdFilter = (value: string[]) => {
    updateUrlState(fromDate, toDate, selectedSupplierFilter, selectedAccountIdFilter, selectedContractIdFilter, value, selectedExternalProjectIdFilter, selectedExternalProviderFilter, selectedInvoiceFlagsFilter);
  };

  const handleChangeExternalProjectIdFilter = (value: string[]) => {
    updateUrlState(fromDate, toDate, selectedSupplierFilter, selectedAccountIdFilter, selectedContractIdFilter, selectedChangeOrderSubContractorIdFilter, value, selectedExternalProviderFilter, selectedInvoiceFlagsFilter);
  };

  const handleChangeExternalProviderFilter = (value: string[]) => {
    updateUrlState(fromDate, toDate, selectedSupplierFilter, selectedAccountIdFilter, selectedContractIdFilter, selectedChangeOrderSubContractorIdFilter, selectedExternalProjectIdFilter, value, selectedInvoiceFlagsFilter);
  };

  const handleChangeInvoiceFlagsFilter = (value: string[]) => {
    updateUrlState(fromDate, toDate, selectedSupplierFilter, selectedAccountIdFilter, selectedContractIdFilter, selectedChangeOrderSubContractorIdFilter, selectedExternalProjectIdFilter, selectedExternalProviderFilter, value);
  };

  const updateSupplierFilter = () => {
    const result: string[] = [];
    const invoicesToCheck = invoiceContext.invoices;
    invoicesToCheck.forEach((invoice) => {
      const test = invoice?.supplier ?? "";
      if (result.indexOf(test) === -1) {
        result.push(test);
      }
    });
    setSupplierFilter(result.sort());
  };

  const getDefaultExternalProjectIdFilter = (): string[] => {
    return splitExternalId(projectContext.getSelectedProject()?.externalId);
  };

  const getDefaultExternalProviderFilter = (): string[] => {
    const uniqueExternalProviders: string[] = [defaultAimzExternalProviderId];
    invoiceContext.invoices.map(invoice => invoice.externalProvider).forEach(externalProvider => {
      if (externalProvider && !guidIsNullOrEmpty(externalProvider) && !uniqueExternalProviders.includes(externalProvider)) {
        uniqueExternalProviders.push(externalProvider);
      }
    });
    return uniqueExternalProviders;
  }

  const updateAccountNumberFilter = () => {
    setAccountIdFilter(accountContext.getAccounts().map((account) => account.id ?? ""));
  };

  const updateContractNameFilter = () => {
    setContractIdFilter(contractContext.getContracts(undefined).map((contract) => contract.id ?? ""));
  };

  const updateChangeOrderSubContractorNameFilter = () => {
    setChangeOrderSubContractorIdFilter(changeOrderSubContractorContext.getChangeOrderSubContractors(undefined).map((ChangeOrderSubContractor) => ChangeOrderSubContractor.id ?? ""));
  };

  const updateExternalProjectIdFilter = () => {
    setExternalProjectIdFilter(getDefaultExternalProjectIdFilter().sort());
  };

  const updateExternalProviderFilter = () => {
    setExternalProviderFilter(getDefaultExternalProviderFilter().sort());
  };

  useEffect(() => {
    const invoiceFilter: InvoiceFilter = {
      approved: showApprovedInvoices,
      showInterimAccountedInvoices: showInterimAccountedInvoices,
      showProcessedInvoices: showProcessedInvoices,
      showRejectedInvoices: showRejectedInvoices,
      fromDate: skipFromDateFilter ? undefined : fromDate ?? fromDateOverride,
      toDate: skipToDateFilter ? undefined : toDate ?? toDateOverride,
      supplierFilter: selectedSupplierFilter,
      accountIdFilter: selectedAccountIdFilter,
      contractIdFilter: selectedContractIdFilter,
      changeOrderSubContractorIdFilter: selectedChangeOrderSubContractorIdFilter,
      externalProjectIdFilter: selectedExternalProjectIdFilter,
      externalProviderFilter: selectedExternalProviderFilter,
      accountId: undefined,
      invoiceFlags: selectedInvoiceFlagsFilter,
    };
    onInvoicesUpdate(invoiceContext.getFilteredInvoices(invoiceFilter));
  }, [urlContext.currentLocation, invoiceContext.invoices]);

  useEffect(() => {
    updateSupplierFilter();
    updateAccountNumberFilter();
    updateContractNameFilter();
    updateChangeOrderSubContractorNameFilter();
    updateExternalProjectIdFilter();
    updateExternalProviderFilter();
  }, [invoiceContext.invoices, urlContext.currentLocation]);

  return (
    <>
      <Grid container spacing={1}>
        {!fromDateOverride && !skipFromDateFilter && (
          <Grid item xs={4}>
            <DatePicker
              style={{ maxWidth: 600 }}
              label={`${languageContext.getMessage("fromDate")} (${languageContext.getMessage("voucherDate")})`}
              date={fromDate}
              fullWidth
              onChange={(newFromDate) =>
                updateUrlState(newFromDate, toDate, selectedSupplierFilter, selectedAccountIdFilter, selectedContractIdFilter, selectedChangeOrderSubContractorIdFilter, selectedExternalProjectIdFilter, selectedExternalProviderFilter, selectedInvoiceFlagsFilter)
              }
            />
          </Grid>
        )}
        {!toDateOverride && !skipToDateFilter && (
          <Grid item xs={4}>
            <DatePicker
              style={{ maxWidth: 600 }}
              label={`${languageContext.getMessage("toDate")} (${languageContext.getMessage("voucherDate")})`}
              date={toDate}
              fullWidth
              onChange={(newToDate) =>
                updateUrlState(fromDate, newToDate, selectedSupplierFilter, selectedAccountIdFilter, selectedContractIdFilter, selectedChangeOrderSubContractorIdFilter, selectedExternalProjectIdFilter, selectedExternalProviderFilter, selectedInvoiceFlagsFilter)
              }
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <Autocomplete
            style={{ maxWidth: 600, minWidth: 200 }}
            options={supplierFilter}
            multiple={true}
            getOptionLabel={(supplier) => supplier}
            value={selectedSupplierFilter}
            onChange={(event, newSelectedSupplierFilter) => handleChangeSupplierFilter(newSelectedSupplierFilter)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <>
                    {languageContext.getMessage("supplier")}
                    {invoiceContext.loadingInvoices && <CircularProgress size={12} style={{ marginLeft: 5 }} />}
                  </>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            style={{ maxWidth: 600, minWidth: 200 }}
            options={accountIdFilter}
            multiple={true}
            getOptionLabel={(accountId) => accountContext.getAccount(accountId)?.accountNumber ?? ""}
            value={selectedAccountIdFilter}
            onChange={(event, newSelectedAccountIdFilter) => handleChangeAccountIdFilter(newSelectedAccountIdFilter)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <>
                    {languageContext.getMessage("accountNumber")}
                    {invoiceContext.loadingInvoices && <CircularProgress size={12} style={{ marginLeft: 5 }} />}
                  </>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            style={{ maxWidth: 600, minWidth: 200 }}
            options={contractIdFilter}
            multiple={true}
            getOptionLabel={(contractId) => contractContext.getContract(contractId)?.name ?? ""}
            value={selectedContractIdFilter}
            onChange={(event, newSelectedContractIdFilter) => handleChangeContractIdFilter(newSelectedContractIdFilter)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <>
                    {languageContext.getMessage("contract")}
                    {invoiceContext.loadingInvoices && <CircularProgress size={12} style={{ marginLeft: 5 }} />}
                  </>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            style={{ maxWidth: 600, minWidth: 200 }}
            options={ChangeOrderSubContractorIdFilter}
            multiple={true}
            getOptionLabel={(changeOrderSubContractorId) => changeOrderSubContractorContext.getChangeOrderSubContractor(changeOrderSubContractorId)?.name ?? ""}
            value={selectedChangeOrderSubContractorIdFilter}
            onChange={(event, newSelectedChangeOrderSubContractorIdFilter) => handleChangeChangeOrderSubContractorIdFilter(newSelectedChangeOrderSubContractorIdFilter)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <>
                    {`${languageContext.getMessage("changeOrder")} ${languageContext.getMessage("subContractorShort")}`}
                    {invoiceContext.loadingInvoices && <CircularProgress size={12} style={{ marginLeft: 5 }} />}
                  </>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            style={{ maxWidth: 600, minWidth: 200 }}
            options={externalProjectIdFilter}
            multiple={true}
            getOptionLabel={(externalProjectId) => externalProjectId}
            value={selectedExternalProjectIdFilter}
            onChange={(event, newSelectedExternalProjectIdFilter) => handleChangeExternalProjectIdFilter(newSelectedExternalProjectIdFilter)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <>
                    {languageContext.getMessage("projectCode")}
                    {invoiceContext.loadingInvoices && <CircularProgress size={12} style={{ marginLeft: 5 }} />}
                  </>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            style={{ maxWidth: 600, minWidth: 200 }}
            options={externalProviderFilter}
            multiple={true}
            getOptionLabel={(externalProvider) => externalProvider}
            value={selectedExternalProviderFilter}
            onChange={(event, newSelectedExternalProviderFilter) => handleChangeExternalProviderFilter(newSelectedExternalProviderFilter)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <>
                    {languageContext.getMessage("system")}
                    {invoiceContext.loadingInvoices && <CircularProgress size={12} style={{ marginLeft: 5 }} />}
                  </>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            style={{ maxWidth: 600, minWidth: 200 }}
            options={Object.values(InvoiceFlag)}
            multiple={true}
            getOptionLabel={(invoiceFlag) => languageContext.getMessage(invoiceFlag)}
            value={selectedInvoiceFlagsFilter}
            onChange={(event, newSelectedInvoiceFlags) => handleChangeInvoiceFlagsFilter(newSelectedInvoiceFlags)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <>
                    {languageContext.getMessage("flags")}
                    {invoiceContext.loadingInvoices && <CircularProgress size={12} style={{ marginLeft: 5 }} />}
                  </>
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withTheme(InvoicesFilterTab);
