import { ChangeOrderExpected, ChangeOrderStatus } from "../contracts/contracts";
import { Guid } from "./common-types";
import { getEmptyGuid, getRandomGuid } from "./guidTools";

export const getEmptyChangeOrderExpected = (projectId: Guid, accountId?: Guid, contractId?: Guid): ChangeOrderExpected => {
    return {
        id: getRandomGuid(),
        projectId: projectId,
        name: "",
        cost: 0,
        accountId: accountId ? accountId : undefined,
        contractId: contractId ? contractId : undefined,
        status: ChangeOrderStatus.NOT_PROCESSED, 
        expected: new Date(),
    }
}
