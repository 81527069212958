import { DocumentType, ForecastReport, Forecasts, Project } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { datesAreOnSameMonth } from "../../utils/dateTools";
import { capitalizeString } from "../../utils/stringTools";
import { AccountContext } from "../account/accountContext";
import { LanguageContext } from "../language/interfaces";
import { ProjectContext } from "../project/projectContext";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

const FORECAST_REPORT_TEMPLATE_FILENAME = 'aimz/template_forecast_report.xlsx';
const FORECAST_REPORT_TEMPLATE_SHEET_NAME = 'forecast_report';

export const queryTemplateEngineToProduceForecastExcelReport = (
    forecasts: Forecasts,
    templateEngineQueriesContext: TemplateEngineQueriesContext,
    languageContext: LanguageContext,
    accountContext: AccountContext,
    projectContext: ProjectContext): void => {
    const templateFilename: string = FORECAST_REPORT_TEMPLATE_FILENAME;
    const templateVariables: object[] = new Array<object>();
    forecasts.monthlyForecastReports = forecasts.monthlyForecastReports ?? [];
    forecasts.monthlyForecastGroupsReports = forecasts.monthlyForecastGroupsReports ?? [];
    const lastMonthlyAccountForecastReports: Dictionary<ForecastReport> = {};
    for (let i = 0; i < forecasts.monthlyForecastReports.length; i++) {
        const monthlyForecastReports = forecasts.monthlyForecastReports[i];
        templateVariables.push(getTemplateEngineForecastReportVariables(lastMonthlyAccountForecastReports, monthlyForecastReports, forecasts.monthlyForecastGroupsReports, languageContext, accountContext, projectContext))
    }
    const reversedTemplateVariables = templateVariables.reverse();
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, reversedTemplateVariables, languageContext.getMessage('forecastReport'));
}

const getTemplateEngineForecastReportVariables = (
    lastMonthlyAccountForecastReports: Dictionary<ForecastReport>,
    monthlyForecastReports: Array<ForecastReport>,
    monthlyForecastGroupReports: Array<Array<Array<ForecastReport>>>,
    languageContext: LanguageContext,
    accountContext: AccountContext,
    projectContext: ProjectContext): object => {
    const date: Date = (monthlyForecastReports.length > 0 ? monthlyForecastReports[0].date : undefined) ?? new Date();
    const title: string = languageContext.getMessage('forecastReport');
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return {
        'tpl_name': FORECAST_REPORT_TEMPLATE_SHEET_NAME,
        'sheet_name': `${monthString}`,
        'ctx': getForecastReportsVariables(title, monthString, lastMonthlyAccountForecastReports, monthlyForecastReports, monthlyForecastGroupReports, languageContext, accountContext, projectContext),
    }
}

const getForecastReportsVariables = (
    title: string,
    monthString: string,
    lastMonthlyAccountForecastReports: Dictionary<ForecastReport>,
    monthlyForecastReports: Array<ForecastReport>,
    monthlyForecastGroupReports: Array<Array<Array<ForecastReport>>>,
    languageContext: LanguageContext,
    accountContext: AccountContext,
    projectContext: ProjectContext): object => {
    const forecastReportsVariables = {
        'language': {},
        'title': title,
        'date': monthString,
        'forecastReports': new Array<object>()
    }
    monthlyForecastReports.forEach(forecastReport => {
        if (forecastReport.parentDocumentType === DocumentType.PROJECT) {
            const project = projectContext.getProject(forecastReport.parentDocumentId);
            if (!project?.id) {
                return;
            }
            const externalId = project?.externalId ?? '';
            const projectName = project?.name ?? '';
            const projectForecastReport = {
                'project': getForecastReportVariables(forecastReport, externalId, projectName),
                'accountGroups': new Array<object>(),
            }

            const accountGroups = new Array<object>();
            const usedAccountsInMonth: Dictionary<Array<string>> = {};
            monthlyForecastReports.forEach(accountForecastReport => {
                if (accountForecastReport.parentDocumentType !== DocumentType.ACCOUNT) {
                    return;
                }
                populateProjectForecastReport(accountForecastReport,
                    accountGroups,
                    project,
                    usedAccountsInMonth,
                    lastMonthlyAccountForecastReports,
                    monthlyForecastGroupReports,
                    languageContext,
                    accountContext);
            })

            for (const [accountId, accountForecastReport] of Object.entries(lastMonthlyAccountForecastReports)) {
                populateProjectForecastReport(accountForecastReport,
                    accountGroups,
                    project,
                    usedAccountsInMonth,
                    lastMonthlyAccountForecastReports,
                    monthlyForecastGroupReports,
                    languageContext,
                    accountContext);
            }
            
            const sortByAccountName = (a: any, b: any) => {
                if ((a['accountNumber'] ?? '') < (b['accountNumber'] ?? '')) { return -1; }
                if ((a['accountNumber'] ?? '') > (b['accountNumber'] ?? '')) { return 1; }
                return 0;
            }

            const sortByGroupName = (a: any, b: any) => {
                if ((a['accountGroup']['name'] ?? '') < (b['accountGroup']['name'] ?? '')) { return -1; }
                if ((a['accountGroup']['name'] ?? '') > (b['accountGroup']['name'] ?? '')) { return 1; }
                return 0;
            }

            accountGroups.sort(sortByGroupName);
            accountGroups.forEach((accountGroup: any) => accountGroup['accounts'].sort(sortByAccountName));
            projectForecastReport['accountGroups'] = accountGroups;
            forecastReportsVariables['forecastReports'].push(projectForecastReport);
        }
    });
    return forecastReportsVariables;
}

const populateProjectForecastReport = (
    accountForecastReport: ForecastReport,
    accountGroups: Array<object>,
    project: Project,
    usedAccountsInMonth: Dictionary<Array<string>>,
    lastMonthlyAccountForecastReports: Dictionary<ForecastReport>,
    monthlyForecastGroupReports: Array<Array<Array<ForecastReport>>>,
    languageContext: LanguageContext,
    accountContext: AccountContext) => {

    if (!accountForecastReport.date) {
        return;
    }

    const account = accountContext.getAccount(accountForecastReport.parentDocumentId);
    const accountProjectId = account?.projectId;
    if (!accountProjectId || accountProjectId !== project.id) {
        return;
    }
    const accountNumber = account?.accountNumber ?? '';
    const accountName = account?.name ?? '';
    const accountGroupName = account?.accountGroup ?? languageContext.getMessage('general');

    if (!(accountGroupName in usedAccountsInMonth)) {
        usedAccountsInMonth[accountGroupName] = new Array<string>();
    }

    if (usedAccountsInMonth[accountGroupName].findIndex(usedAccountNumber => usedAccountNumber === accountNumber) >= 0) {
        return;
    }

    lastMonthlyAccountForecastReports[account?.id ?? ''] = accountForecastReport;
    usedAccountsInMonth[accountGroupName].push(accountNumber);
    const accountForecastReportVariables = getForecastReportVariables(accountForecastReport, accountNumber, accountName);
    const accountGroup: any = accountGroups.find((accountGroup: any) => accountGroup['accountGroup']['name'] === accountGroupName);
    if (accountGroup) {
        accountGroup['accounts'].push(accountForecastReportVariables);
    }
    else {
        let accountGroupForecastReport: ForecastReport | undefined = undefined;
        for (let i = 0; i < monthlyForecastGroupReports.length; i++) {
            for (let j = 0; j < monthlyForecastGroupReports[i].length; j++) {
                accountGroupForecastReport = monthlyForecastGroupReports[i][j].find(datedGroupReport =>
                    datedGroupReport.projectId === project.id &&
                    (datedGroupReport.group ?? languageContext.getMessage('general')) === accountGroupName &&
                    datedGroupReport.date && accountForecastReport.date &&
                    datesAreOnSameMonth(new Date(datedGroupReport.date), new Date(accountForecastReport.date)))
                if (accountGroupForecastReport) {
                    break;
                }
            }
            if (accountGroupForecastReport) {
                break;
            }
        };
        accountGroupForecastReport = accountGroupForecastReport ?? {};
        accountGroups.push({
            'accountGroup': getForecastReportVariables(accountGroupForecastReport, '', accountGroupName),
            'accounts': [accountForecastReportVariables],
        })
    }
}

const getForecastReportVariables = (forecastReport: ForecastReport, externalId: string, name: string): object => {
    return {
        'externalId': externalId,
        'accountNumber': externalId,
        'name': name,
        'budget': (forecastReport.budget ?? 0.0),
        'revisedBudget': (forecastReport.revisedBudget ?? 0.0),
        'forecast': (forecastReport.forecast ?? 0.0),
        'forecastDeviationFromBudget': (forecastReport.forecastDeviationFromBudget ?? 0.0),
        'previousForecast': (forecastReport.previousForecast ?? 0.0),
        'forecastChange': (forecastReport.forecastChange ?? 0.0),
        'forecastWithSurcharge': (forecastReport.forecastWithSurcharge ?? 0.0),
        'projectSurcharge': (forecastReport.projectSurcharge ?? 0.0),
        'surchargePercent': (forecastReport.surchargePercent ?? 0.0) / 100.0,
        'projectBudgetSurcharge': (forecastReport.projectBudgetSurcharge ?? 0.0),
        'projectRevisedBudgetSurcharge': (forecastReport.projectRevisedBudgetSurcharge ?? 0.0),
        'projectForecastDeviationSurcharge': (forecastReport.projectForecastDeviationSurcharge ?? 0.0),
        'projectPreviousForecastSurcharge': (forecastReport.projectPreviousForecastSurcharge ?? 0.0),
        'projectForecastChangeSurcharge': (forecastReport.projectForecastChangeSurcharge ?? 0.0),
        'projectBudgetWithSurcharge': (forecastReport.projectBudgetWithSurcharge ?? 0.0),
        'projectRevisedBudgetWithSurcharge': (forecastReport.projectRevisedBudgetWithSurcharge ?? 0.0),
        'projectForecastDeviationWithSurcharge': (forecastReport.projectForecastDeviationWithSurcharge ?? 0.0),
        'projectPreviousForecastWithSurcharge': (forecastReport.projectPreviousForecastWithSurcharge ?? 0.0),
        'projectForecastChangeWithSurcharge': (forecastReport.projectForecastChangeWithSurcharge ?? 0.0),
        'comments': []
    }
}