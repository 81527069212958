import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { AccrualAndOperations, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";

export const QUERY_ACCRUAL_AND_OPERATIONS: DocumentNode = gql(`
    query accrualAndOperations(
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $parentDocumentId: UUID
        $parentDocumentType: DocumentType
        $fromDate: Date
        $toDate: Date
    ) {
        accrualAndOperations(
        id: $id
        documentType: $documentType
        state: $state
        created: $created
        parentDocumentId: $parentDocumentId
        parentDocumentType: $parentDocumentType
        fromDate: $fromDate
        toDate: $toDate
        ) {
            id
            documentType
            stateTime
            state
            created
            parentDocumentId
            parentDocumentType
            fromDate
            toDate
            firstDate
            lastDate
            expectedPostedTotalExpense
            actuallyPostedTotalExpense
            sumExpenseForecasts
            remainingForecastsToDistribute
            monthlyPostedExpenseItems {
                date
                expectedPostedExpense
                expectedAccumulatedPostedExpense
                actuallyPostedExpense
                actuallyAccumulatedPostedExpense
                revisedForecast
                revisedForecastIsAboveAccountForecast
                disabled
            }
        }
    }
`);

export const getAccrualAndOperationsGraphqlQueryOptions = (accrualAndOperations: AccrualAndOperations): QueryHookOptions => {
    accrualAndOperations.state = accrualAndOperations.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {variables: {}}
    options.variables = {};
    if (accrualAndOperations.id !== undefined) options.variables['id'] = accrualAndOperations.id
    if (accrualAndOperations.state !== undefined) options.variables['state'] = accrualAndOperations.state
    if (accrualAndOperations.parentDocumentId !== undefined) options.variables['parentDocumentId'] = accrualAndOperations.parentDocumentId
    if (accrualAndOperations.parentDocumentType !== undefined) options.variables['parentDocumentType'] = accrualAndOperations.parentDocumentType
    if (accrualAndOperations.fromDate !== undefined) options.variables['fromDate'] = toISODate(accrualAndOperations.fromDate);
    if (accrualAndOperations.toDate !== undefined) options.variables['toDate'] = toISODate(accrualAndOperations.toDate);
    return options;
}