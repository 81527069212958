import { Button, CircularProgress, Grid, makeStyles, Theme, Toolbar, Tooltip, Typography, withTheme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { defaultAimzExternalProviderId, useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { Invoice, RoleType } from "../../contracts/contracts";
import { tableIcons } from "../../ui/table-icons";
import { CustomTableStyle, Guid } from "../../utils/common-types";
import { getEmptyGuid, guidIsNullOrEmpty } from "../../utils/guidTools";
import { getPageSizeOptions, resolveDefaultPageSize } from "../../utils/randomTools";
import SelectAccount from "../accountComponents/SelectAccount";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import TicketLoading from "../generalComponents/TicketLoading";
import InvoiceChangeOrderSelectWrapper from "./InvoiceChangeOrderSelectWrapper";
import NewInvoiceDialog from "./NewInvoiceDialog";
import EditInvoiceWithheldAmount from "./EditInvoiceWithheldAmount";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { getInvoiceFlags } from "../../utils/InvoiceFlagsTools";
import { getRenderedInvoiceFlag } from "../../utils/statusToPalettes";
import OverflowTip from "../generalComponents/OverFlowTip";
import CommentToolTip from "../generalComponents/CommentTooltip";
import { useRememberTableScroll } from "../../hooks/useRememberTableScroll";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type props = {
  invoices: Invoice[];
  onClickInvoice?: (invoiceId: Guid) => void;
  enableEditAccount?: (invoice: Invoice) => boolean;
  showAsInterim?: boolean;
  hideChangeOrderAndContract?: boolean;
};

const InvoiceList: React.FC<props> = ({ invoices, onClickInvoice, enableEditAccount, showAsInterim, hideChangeOrderAndContract }) => {
  showAsInterim = showAsInterim ?? false;
  const classes = useStyles();
  const { getLocalization } = useMaterialTableLanguageContext();
  const languageContext = useLanguageContext();
  const invoiceContext = useInvoiceContext();
  const accountContext = useAccountContext();
  const projectContext = useProjectContext();
  const themeContext = useThemeContext();
  const theme = themeContext.getTheme();

  const [showNewDialog, setShowNewDialog] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>(undefined);

  const openNewDialog = (invoice?: Invoice | undefined) => {
    setSelectedInvoice(invoice ?? {});
    setShowNewDialog(true);
  };

  const onCloseNewDialog = (mutatedInvoice?: Invoice) => {
    setShowNewDialog(false);
  };

  const onClickRow = (selectedRow: Invoice | undefined) => {
    const id = selectedRow?.id || "";
    if (onClickInvoice) {
      onClickInvoice(id);
    }
  };

  const [pageSize, setPageSize] = useState<number>(1);
  const tableRef = useRef(null) as any;
  useRememberTableScroll(tableRef, "invoiceList");
  const pageSizeOptions = getPageSizeOptions(invoices.length);
  useEffect(() => {
    if ((pageSize === 1 || pageSizeOptions.indexOf(pageSize) < 0) && pageSizeOptions.length > 1) {
      const newPageSize = resolveDefaultPageSize(pageSizeOptions);
      tableRef.current.dataManager.changePageSize(newPageSize);
      setPageSize(newPageSize);
    }
  }, [invoices]);

  const renderComments = (invoice: Invoice) => {
    const comments = invoice?.comments ?? [];
    const firstComment = comments.length > 0 ? comments[0] : "";
    return <CommentToolTip>{comments}</CommentToolTip>;
  };

  const renderToolbar = (props: any) => (
    <div>
      <MTableToolbar {...props} />
      {projectContext.hasProjectAccess(RoleType.WRITER) && (
        <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
          <Button color="inherit" size="small" className={classes.button} startIcon={<Add />} onClick={() => openNewDialog(undefined)}>
            {languageContext.getMessage("addInvoice")}
          </Button>
        </Toolbar>
      )}
    </div>
  );

  return (
    <>
      <NewInvoiceDialog open={showNewDialog} onClose={onCloseNewDialog} invoice={selectedInvoice} hideChangeOrderAndContract={hideChangeOrderAndContract}></NewInvoiceDialog>
      <MaterialTable
        components={{ Toolbar: renderToolbar }}
        tableRef={tableRef}
        icons={tableIcons}
        localization={getLocalization()}
        columns={[
          {
            title: languageContext.getMessage("supplier"),
            field: "supplier",
            cellStyle: CustomTableStyle,
            render: (invoice) => <TicketLoading documentId={invoice.id} component={<CommentToolTip>{[invoice.supplier]}</CommentToolTip>} />,
          },
          // { title: languageContext.getMessage('scannedDate'), field: 'scannedDate', type: 'date' },
          { title: languageContext.getMessage("voucherDate"), field: "voucherDate", type: "date", cellStyle: CustomTableStyle, },
          { title: languageContext.getMessage("dueDate"), field: "dueDate", type: "date", cellStyle: CustomTableStyle, },
          { title: languageContext.getMessage("interimAccountedDate"), field: "interimAccountedDate", type: "date", hidden: !showAsInterim, cellStyle: CustomTableStyle, },
          {
            title: languageContext.getMessage("accountNumber"),
            field: "accountNumber",
            type: "string",
            cellStyle: CustomTableStyle,
            render: (invoice) => {
              var selectedAccount = accountContext.getAccount(invoice?.accountId);
              if (enableEditAccount && enableEditAccount(invoice)) {
                return (
                  <SelectAccount
                    selectedAccount={selectedAccount}
                    readonly={enableEditAccount ? !enableEditAccount(invoice) : true}
                    onSelectedAccountChange={(account) => {
                      invoice.accountId = account?.id ?? getEmptyGuid();
                      invoiceContext.updateInvoice(invoice.id, invoice);
                    }}
                    hideLabel={true}
                  />
                );
              } else {
                return selectedAccount?.accountNumber + " - " + selectedAccount?.name;
              }
            },
          },
          {
            title: languageContext.getMessage("contractOrChange"),
            field: "accountNumber",
            type: "string",
            cellStyle: CustomTableStyle,
            hidden: hideChangeOrderAndContract,
            render: (invoice) => <InvoiceChangeOrderSelectWrapper invoiceId={invoice?.id} />,
          },
          {
            title: languageContext.getMessage("comments"),
            cellStyle: CustomTableStyle,
            render: renderComments,
          },
          {
            title: languageContext.getMessage("invoiceNumber"),
            field: "invoiceNumber",
            cellStyle: CustomTableStyle,
            type: "string",
          },
          {
            title: languageContext.getMessage("interimAccountedAmount"),
            render: (invoice) => <CurrencyFormat amount={invoice.interimAccountedAmount} />,
            hidden: !showAsInterim,
            cellStyle: CustomTableStyle,
            type: "numeric",
          },
          {
            title: languageContext.getMessage("amount"),
            type: "numeric",
            cellStyle: CustomTableStyle,
            render: (invoice) => (
              <div style={{ display: "inline-flex", alignItems: "middle" }}>
                <CurrencyFormat amount={invoice.amount} />
                &nbsp;{getRenderedInvoiceFlag(getInvoiceFlags(invoice), theme, languageContext)}
              </div>
            ),
          },
          {
            title: languageContext.getMessage("withheldAmount"),
            type: "numeric",
            cellStyle: CustomTableStyle,
            render: (invoice) => <EditInvoiceWithheldAmount invoice={invoice} />,
          },
          {
            title: languageContext.getMessage("projectCode"),
            field: "externalProjectId",
            cellStyle: CustomTableStyle,
            type: "string",
          },
          {
            title: languageContext.getMessage("system"),
            field: "externalProvider",
            cellStyle: CustomTableStyle,
            type: "string",
            render: (invoice) => !guidIsNullOrEmpty(invoice.externalProvider) ? invoice.externalProvider : defaultAimzExternalProviderId,
          },
        ]}
        actions={[
          {
            icon: () => <NavigateNextIcon />,
            tooltip: languageContext.getMessage("details"),
            position: "row",
            onClick: (event, selectedRow) => onClickRow(selectedRow as Invoice),
          },
        ]}
        data={invoices}
        title={
          <Grid container>
            <Grid item xs={10} style={{ display: "flex" }}>
              <Typography variant="h6">{languageContext.getMessage("invoices")}</Typography>
            </Grid>
            {invoiceContext.loadingInvoices && (
              <Grid item xs={2}>
                <CircularProgress size={25} />
              </Grid>
            )}
          </Grid>
        }
        onChangeRowsPerPage={(newPageSize: number) => {
          setPageSize(newPageSize);
        }}
        options={{
          padding: "dense",
          paging: true,
          search: true,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptions,
          exportButton: { csv: true, pdf: false },
          exportCsv: (columns, renderData) => {
            invoiceContext.downloadInvoices(invoices);
          },
          columnsButton: true,
          emptyRowsWhenPaging: false,
          toolbarButtonAlignment: "right",
          searchFieldAlignment: "right",
          detailPanelColumnAlignment: "right",
          actionsColumnIndex: 0,
          headerStyle: { position: "sticky", top: 0, fontWeight: 'bold' },
          maxBodyHeight: 800,
        }}
      />
    </>
  );
};

export default withTheme(InvoiceList);
