import React from "react";
import { Typography, Table, TableCell, TableRow, TableBody, Paper, Grid, Divider, Checkbox, FormControlLabel } from "@material-ui/core";
import { displayCurrency } from "../currencyComponents/CurrencyFormat";
import { Equalizer } from "@material-ui/icons";
import { useThemeContext } from "../../contexts/theme/ThemeContext";

export interface CurrencyTableElement {
  group?: string;
  value?: number;
  markedValue?: number;
  label?: string;
  isMarked?: boolean;
}

export interface CurrencyTableGroup {
  value?: string;
  isMarked?: boolean;
}

type props = {
  tableData: CurrencyTableElement[];
  groups: CurrencyTableGroup[];
  title: string;
  totalTitle?: string;
  totalValue?: number;
  showMarked?: boolean;
  setShowMarked?: (checked: boolean) => void;
  markedTitle?: string;
};

const CurrencyTable: React.FC<props> = ({ tableData, groups, title, totalTitle, totalValue, showMarked, setShowMarked, markedTitle }) => {
  const theme = useThemeContext().getTheme();
  return (
    <Paper style={{ padding: "1em" }} elevation={2}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container wrap="nowrap" spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            {setShowMarked && (
              <Grid item style={{ marginLeft: "auto", marginRight: "1em" }}>
                <FormControlLabel control={<Checkbox style={{margin: 0, padding: 0, paddingLeft: '0.5em', paddingRight: '0.5em'}} checked={showMarked} onChange={(event, checked) => setShowMarked(checked)} />} label={markedTitle} labelPlacement="start"  />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Table aria-label="simple table" size="small" padding="none">
            <TableBody>
              {groups
                .filter((filteredGroup) => (showMarked === true ? filteredGroup.isMarked === true : true))
                .map((group, index) => {
                  return (
                    <React.Fragment key={'__fragment' + group + index.toString() }>
                      <TableRow key={"__group_row_" + index.toString() + group}>
                        <TableCell colSpan={3}>
                          <Typography variant="subtitle1">
                            <b>{group.value}</b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {showMarked === false &&
                        tableData
                          .filter((tableElement) => tableElement.group === group.value)
                          .map((row, index) => {
                            return (
                              <TableRow key={"__group_row_data_" + index.toString() + group}>
                                <TableCell key={"__group_row_data__1" + group + "_" + row.label}>
                                  <Typography>{row.label}</Typography>
                                </TableCell>
                                <TableCell key={"__group_row_data__2" + group + "_" + row.value} align="right">
                                  <Typography>{displayCurrency(row.value)}</Typography>
                                </TableCell>
                                <TableCell key={"__group_row_data__3" + group + "_" + row.label} width={20} style={{paddingLeft: '1em', paddingRight: '1em'}}>
                                  {row.isMarked ? <Equalizer style={{color: theme.palette.primary.dark }} /> : " "}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      {showMarked === true &&
                        tableData
                          .filter((tableElement) => tableElement.group === group.value && tableElement.isMarked === true)
                          .map((row, index) => {
                            return (
                              <TableRow key={"__group_row_data_" + index.toString() + group}>
                                <TableCell key={"__group_row_data__1" + group + "_" + row.label}>
                                  <Typography>{row.label}</Typography>
                                </TableCell>
                                <TableCell key={"__group_row_data__2" + group + "_" + row.markedValue} align="right">
                                  <Typography>{displayCurrency(row.markedValue)}</Typography>
                                </TableCell>
                                <TableCell key={"__group_row_data__3" + group + "_" + row.label} width={20} style={{paddingLeft: '1em', paddingRight: '1em'}}>
                                  {row.isMarked ? <Equalizer style={{color: theme.palette.primary.dark }} /> : " "}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </React.Fragment>
                  );
                })}
              <TableRow key={"__group_row_total"}>
                <TableCell key={"__group_row_total_label"}>
                  <Typography variant="subtitle1">
                    <b>{totalTitle ?? "Total"}</b>
                  </Typography>
                </TableCell>
                <TableCell key={"__group_row_total_value"} align="right">
                  <Typography variant="subtitle1">
                    <b>{displayCurrency(totalValue ?? 0)}</b>
                  </Typography>
                </TableCell>
                <TableCell key={"__group_row_total_marked"} width={20} style={{paddingLeft: '1em', paddingRight: '1em'}}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CurrencyTable;
