import { TemplateEngineQueriesContext } from "../contexts/templateEngine/queries/templateEngineQueriesContext";
import { Account } from "../contracts/contracts";
import { capitalizeString } from "./stringTools";
import { LanguageContext } from "../contexts/language/interfaces";
import { AccountContext } from "../contexts/account/accountContext";
import { InterimContext } from '../contexts/interim/InterimContext';

const ACCOUNT_REPORT_TEMPLATE_FILENAME = "aimz/template_account_interim_list.xlsx";
const ACCOUNT_REPORT_TEMPLATE_SHEET_NAME = "account_list";

export function downloadInterimReport(templateEngineQueriesContext: TemplateEngineQueriesContext, languageContext: LanguageContext, interimContext: InterimContext, accountContext: AccountContext, date?: Date) {
  const month: string = capitalizeString(date?.toLocaleString(languageContext.getLanguage(), { month: "long" }) ?? "");
  const monthString: string = `${month} ${date?.getFullYear()}`;
  const accounts = accountContext.getAccounts();
  console.log('Accounts: ', accounts)
  var reportVariables: object[] = [
    {
      tpl_name: ACCOUNT_REPORT_TEMPLATE_SHEET_NAME,
      sheet_name: ACCOUNT_REPORT_TEMPLATE_SHEET_NAME,
      ctx: getAccountReportVariables(interimContext, accounts, monthString),
    },
  ];
  var fileName = `Interim - rapport`;
  templateEngineQueriesContext.queryTemplateEngine(ACCOUNT_REPORT_TEMPLATE_FILENAME, reportVariables, fileName);
}

function getAccountReportVariables(interimContext: InterimContext ,accounts: Account[], monthString?: string) {
  return {
    title: "Interim kontoliste",
    date: monthString ?? "",
    accounts: accounts.map(account => {
      var interimExpense = interimContext.getInterimExpense(account);
      return {
          ...account,
          forecast: account.forecast ?? {},
          interimExpense: interimExpense,
          interimExpenseDate: interimExpense.date ? interimExpense.date.toDateString() : "",
      }
  }),
    language: {},
  };
}
