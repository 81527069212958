import { Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import InvoiceDetails from "../../component/invoiceComponents/InvoiceDetails";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { useContractContext } from "../../contexts/contract/contractContext";
import { InvoiceTabs, useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { DocumentType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import AllInvoicesView from "./AllInvoicesView";


const useStyles = makeStyles((theme: Theme) => ({
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
}));

const InvoiceView: React.FC<{}> = () => {
    const classes = useStyles();
    const languageContext = useLanguageContext();
    const urlContext = useUrlContext();
    const accountContext = useAccountContext();
    const invoiceContext = useInvoiceContext();
    const projectContext = useProjectContext();
    const contractContext = useContractContext();
    const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
    const ticketContext = useTicketContext();

    const urlState = urlContext.getUrlState();
    const tabIndex = urlState.tab ? urlState.tab as string : 'unprocessed';
    const selectedInvoiceId = urlState.invoiceId ? urlState.invoiceId as string : null;
    const invoiceSearch = invoiceContext.getInvoiceSearch();

    const changeOrderSubContractorSearch = changeOrderSubContractorContext.getChangeOrderSubContractorSearch();
    
    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.ACCOUNT, 
            DocumentType.INVOICE,
            DocumentType.CONTRACT,
            DocumentType.CHANGE_ORDER_SUB_CONTRACTOR,
            DocumentType.CHANGE_ORDER_LANDLORD]);
        invoiceContext.searchInvoices(invoiceSearch);
        accountContext.searchAccounts(accountContext.getAccountSearch());
        contractContext.searchContracts(contractContext.getContractSearch());
        changeOrderSubContractorContext.searchChangeOrderSubContractors(changeOrderSubContractorSearch);
    }, [urlContext.currentLocation, projectContext.getSelectedProject()])

    const updateUrlState = (newTabIndex: string, newInvoiceId: string | null): void => {
        const newUrlState = {
            ...urlState,
            ...invoiceSearch,
            ...{ 'invoiceId': newInvoiceId },
            ...{ 'tab': newTabIndex },
        }
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
        urlContext.pushUrlQuery(urlQuery);
    }

    const handleTabIndexChange = (event: React.ChangeEvent<{}>, newTabIndex: string) => {
        updateUrlState(newTabIndex, selectedInvoiceId);
    };

    const onChangeSelectedInvoice = (invoiceId?: string): void => {
        updateUrlState(tabIndex, invoiceId || "");
    };

    const onClickInvoice = (invoiceId: string) => {
        updateUrlState('details', invoiceId);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
                    <Tabs value={tabIndex} onChange={handleTabIndexChange}>
                        <Tab label={languageContext.getMessage('invoiceMissingComment')} value={InvoiceTabs.unprocessed} />
                        <Tab label={languageContext.getMessage('invoiceComplete')} value={InvoiceTabs.processed} />
                        <Tab label={languageContext.getMessage('invoiceRejected')} value={InvoiceTabs.rejected} />
                        <Tab label={languageContext.getMessage('details')} value={InvoiceTabs.details} />
                    </Tabs>
                </Toolbar>
            </Grid>
            {tabIndex === "unprocessed" && <Grid item xs={12}>
                <AllInvoicesView 
                    onClickInvoice={onClickInvoice}
                    showProcessedInvoices={false}
                />
            </Grid>}
            {tabIndex === "processed" && <Grid item xs={12}>
                <AllInvoicesView 
                    onClickInvoice={onClickInvoice}
                    showProcessedInvoices={true}
                />
            </Grid>}
            {tabIndex === "rejected" && <Grid item xs={12}>
                <AllInvoicesView 
                    onClickInvoice={onClickInvoice}
                    showRejectedInvoices={true}
                />
            </Grid>}
            {tabIndex === "details" && <Grid item xs={12}>
                <InvoiceDetails 
                selectedInvoiceId={selectedInvoiceId}
                onChangeSelectedInvoice={onChangeSelectedInvoice}/>
            </Grid>}
        </Grid>
    );
}

export default withTheme(InvoiceView);