import { getRandomNumber } from './randomTools'
import moment from 'moment'

export const getRandomDate = (start: Date, end: Date): Date => {
    return new Date(getRandomNumber(start.getTime(), end.getTime()));
}

export const dateFrom = (start: Date, days: number): Date => {
    let ms = start.getTime() + 86400000 * days;
    return new Date(ms);
}

export const toISODate = (date: Date): string => {
    let isoStr = date.toISOString();
    isoStr = isoStr.slice(0, isoStr.indexOf('T'))
    return isoStr;
}

export const datesAreOnSameDay = (first: Date, second: Date): boolean =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();
    

export const datesAreOnSameMonth = (first: Date, second: Date): boolean =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth();


export const getStartOfDay = (date: Date): Date => {
    const result = new Date(date.valueOf());
    result.setHours(0, 0, 0, 0);
    return result;
};

export const getEndOfDay = (date: Date): Date => {
    const result = new Date(date.valueOf());
    result.setHours(23,59,59,999);
    return result;
};

export const getStartOfMonth = (date: Date): Date => {    
    date = new Date(date);
    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);   
    return date;     
  }

export const getEndOfMonth = (date: Date): Date => {
    date = new Date(date);
    date.setMonth(date.getMonth() + 1)
    date.setDate(0);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
}

export const getMinDate = (): Date => {
    return new Date(-8640000000000000);
}

export const getMaxDate = (): Date => {
    return new Date(8640000000000000);
}

export const diffDatesInDays = (startDate: Date, endDate: Date): number => {
    return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - 
                       Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) 
                       / (1000 * 60 * 60 * 24));
}

export const diffDatesInYears = (startDate: Date, endDate: Date): number => {
    return (endDate.getFullYear() - startDate.getFullYear()) + ((endDate.getMonth() - startDate.getMonth()) / 12.0);
}

export const sortDates = (firstDate: Date | undefined, secondDate: Date | undefined): number => {
    firstDate = firstDate ?? new Date();
    secondDate = secondDate ?? new Date();
    if (firstDate < secondDate) return -1;
    else if (firstDate > secondDate) return  1;
    else return  0;
}

export const fillEmptyMonths = (from: Date | undefined, to: Date | undefined, usedDates: Date[], emptyMonths?: Date[]): Date[] => {
    emptyMonths = emptyMonths ?? [];
    usedDates = usedDates.sort(sortDates);

    if (from && from.getUTCFullYear() > new Date(0).getUTCFullYear() && to && to.getUTCFullYear() > new Date(0).getUTCFullYear()) {
        let previousMonth = new Date(from);
        while (usedDates.length > 0 && !datesAreOnSameMonth(previousMonth, usedDates[0]) && previousMonth > usedDates[0])
        {
            usedDates.splice(0, 1);
        }
        while (usedDates.length > 0 && !datesAreOnSameMonth(previousMonth, usedDates[0]) && previousMonth < usedDates[0]) {
            emptyMonths.push(new Date(previousMonth));
            previousMonth = new Date(previousMonth.setMonth(previousMonth.getMonth() + 1));
        }
    }

    for (let i = 1; i < usedDates.length; i++) {
        let previousMonth = new Date(usedDates[i-1]);
        previousMonth = new Date(previousMonth.setMonth(previousMonth.getMonth() + 1));
        const currentMonth = new Date(usedDates[i]);
        while (previousMonth < currentMonth && !datesAreOnSameMonth(previousMonth, currentMonth)) {
            emptyMonths.push(new Date(previousMonth));
            previousMonth = new Date(previousMonth.setMonth(previousMonth.getMonth() + 1));
        }
    }

    if (usedDates.length === 0) {
        const defaultMonth = from ? new Date(from) : new Date();
        usedDates.push(new Date(defaultMonth.setDate(1)));
    }

    if (from && from.getUTCFullYear() > new Date(0).getUTCFullYear() && to && to.getUTCFullYear() > new Date(0).getUTCFullYear()) {
        let nextMonth = new Date(to);
        while (usedDates.length > 0 && !datesAreOnSameMonth(nextMonth, usedDates[usedDates.length-1]) && nextMonth < usedDates[usedDates.length-1])
        {
            usedDates.splice(usedDates.length-1, 1);
        }
        while (usedDates.length > 0 && !datesAreOnSameMonth(nextMonth, usedDates[usedDates.length-1]) && nextMonth > usedDates[usedDates.length-1]) {
            emptyMonths.push(new Date(nextMonth));
            nextMonth = new Date(nextMonth.setMonth(nextMonth.getMonth() - 1));
        }
    }

    usedDates = usedDates.concat(emptyMonths);

    return usedDates;
}

export const sortDatesAndExtractYears = (usedDates: Date[]): [Date[], (Date | undefined)[]] => {
    const usedYears: (Date | undefined)[] = [];
    const detectedYears: Date[] = []
    const sortedUsedDates = usedDates.sort(sortDates);

    sortedUsedDates.forEach(usedDate => {
        if (detectedYears.findIndex(usedYear => usedYear.getFullYear() === usedDate.getFullYear()) < 0) {
            detectedYears.push(usedDate);
            usedYears.push(usedDate);
        } 
        else {
            usedYears.push(undefined);
        }
    })
    return [sortedUsedDates, usedYears];
}

export function getMonthDiff(startDate: Date, endDate: Date) {
    var months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    return months <= 0 ? 0 : months;
  }
  
  export function getDateArray(startDate: Date, endDate: Date) {
    var resolvedDates: Date[] = [];
    var i = 0,
      j = 0,
      numberOfMonths = getMonthDiff(startDate, endDate),
      startMonth = startDate.getMonth(),
      startYear = startDate.getFullYear();
    while (i <= numberOfMonths) {
      var newDate = new Date(startDate.setMonth(startMonth + j));
      resolvedDates.push(newDate);
      i += 1;
      j += 1;
      var currentYear = newDate.getFullYear();
      if (startYear !== currentYear) {
        j = 1;
        startMonth = 0;
        startYear = currentYear;
      }
    }
    return resolvedDates;
  }
  
  export function getYearMonthStringArray( dateArray: Date[], locales?: any) {
    var resolvedDateArray: { year: string; month: string }[] = [];
    dateArray.forEach((date, index) => {
      var usedYear = date.getFullYear().toString();
      if (dateArray[index - 1] && dateArray[index - 1].getFullYear() !== date.getFullYear()) {
        usedYear = date.getFullYear().toString();
      } else if (index > 0 && dateArray[index - 1].getFullYear() === date.getFullYear()) {
        usedYear = "";
      }
      resolvedDateArray.push({ year: usedYear, month: date.toLocaleString(locales, { month: "long" }) });
    });
    return resolvedDateArray;
  }