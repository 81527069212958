import { useEffect, useRef } from "react";
import { useUrlContext } from "../contexts/url/urlContext";

export const useRememberTableScroll = (tableRef: any, tableKey: string): void => {
    const urlContext = useUrlContext();
    const scrollHeightTooHighNumber = useRef(0);
    const currentLocationSearch = useRef('');
    const scrollPositionKey = `${tableKey}ScrollPosition`;

    const handleScrollPosition = () => {
        const scrollPosition = localStorage.getItem(scrollPositionKey);
        if (scrollPosition) {
            localStorage.setItem(scrollPositionKey, '0');
            let scrollPositionNumber = 0;
            try {
                scrollPositionNumber = Number.parseInt(scrollPosition);
            } catch (error) {
                console.log(error);
            }
            if (tableRef.current.tableContainerDiv.current.scrollHeight <= scrollPositionNumber) {
                scrollHeightTooHighNumber.current = scrollPositionNumber;
            }
            else {
                tableRef.current.tableContainerDiv.current.scroll(0, scrollPositionNumber);
                scrollHeightTooHighNumber.current = 0;
            }
        }
    }

    useEffect(() => {
        if (!tableRef) {
            return;
        }
        
        if (currentLocationSearch.current.length > 0 && currentLocationSearch.current !== urlContext.currentLocation.search) {
            handleScrollPosition();
        }

        currentLocationSearch.current = urlContext.currentLocation.search;
    }, [tableRef, urlContext.currentLocation])

    useEffect(() => {
        if (!tableRef) {
            return;
        }
        if (!tableRef.current.tableContainerDiv.current.onscroll) {
            handleScrollPosition();
            tableRef.current.tableContainerDiv.current.onscroll = (e: any) => {
                if (scrollHeightTooHighNumber.current > 0) {
                    tableRef.current.tableContainerDiv.current.scroll(0, scrollHeightTooHighNumber.current);
                    scrollHeightTooHighNumber.current = 0;
                }
                else {
                    const scrollNumber = e.target.scrollTop as number;
                    localStorage.setItem(scrollPositionKey, scrollNumber.toString());
                }
            }
        }
    }, [tableRef, tableKey]);
}