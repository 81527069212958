import { Button, Grid, List, ListItem, ListItemText, ListSubheader, makeStyles, Paper, TextField, Theme, withTheme } from "@material-ui/core";
import { CallSplit, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { defaultAimzExternalProviderId, useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useStorageFileMutationsContext } from "../../contexts/storageFile/mutations/storageFileMutationsContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { Invoice, DocumentType, RoleType, StorageFile } from "../../contracts/contracts";
import { getStorageFileKey, onUploadFile } from "../../utils/fileTools";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import { DatePicker } from "../dateComponents/DatePicker";
import TicketLoading from "../generalComponents/TicketLoading";
import ViewHeading from "../viewComponents/ViewHeading";
import InvoiceChangeOrderSelect from "./InvoiceChangeOrderSelect";
import InvoiceExternalProjectIdSelect from "./InvoiceExternalProjectIdSelect";
import InvoiceSplitDialog from "./InvoiceSplitDialog";
import NewInvoiceDialog from "./NewInvoiceDialog";
import SelectInvoice from "./SelectInvoice";
import ShowInvoiceImages from "./ShowInvoiceImages";
import { QUERY_EXTERNAL_INVOICE_IMAGES } from "../../contexts/invoice/queries/invoiceQueries";
import { getStorageFilesGraphqlQueryOptions, QUERY_STORAGE_FILES } from "../../contexts/storageFile/queries/storageFileQueries";
import { useAccountContext } from "../../contexts/account/accountContext";
import SelectAccount from "../accountComponents/SelectAccount";
import { getInvoiceFlags } from "../../utils/InvoiceFlagsTools";
import { getRenderedInvoiceFlag } from "../../utils/statusToPalettes";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import PublishIcon from "@material-ui/icons/Publish";
import StorageFileDialog from "../generalComponents/StorageFileDialog";
import FileUploadDialog from "../generalComponents/FileUploadDialog";
import { guidIsNullOrEmpty } from "../../utils/guidTools";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(1),
  },
  alertIcon: {
    color: theme.palette.error.main,
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
}));

type props = {
  selectedInvoiceId: string | null;
  onChangeSelectedInvoice: (invoiceId?: string) => void;
  showAsInterim?: boolean;
};

const InvoiceDetails: React.FC<props> = ({ selectedInvoiceId, onChangeSelectedInvoice, showAsInterim }) => {
  showAsInterim = showAsInterim ?? false;
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const invoiceContext = useInvoiceContext();
  const projectContext = useProjectContext();
  const accountContext = useAccountContext();
  const storageMutationContext = useStorageFileMutationsContext();
  const screenIsMobile = useWidthContext().isMobileScreen();
  const themeContext = useThemeContext();
  const theme = themeContext.getTheme();

  const invoice = selectedInvoiceId ? invoiceContext.getInvoice(selectedInvoiceId) : undefined;
  const invoiceAccount = accountContext.getAccount(invoice?.accountId);

  const [openInvoiceSplitDialog, setOpenInvoiceSplitDialog] = useState<boolean>(false);

  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>(undefined);

  const openEditDialog = (invoice?: Invoice | undefined) => {
    setSelectedInvoice(invoice ?? {});
    setShowEditDialog(true);
  };

  const onCloseEditDialog = (mutatedInvoice?: Invoice) => {
    setShowEditDialog(false);
  };

  const getMdSize = () => {
    return invoice ? 7 : 12;
  };

  const handleOnClickOpenInvoiceSplitDialog = () => {
    if (invoice) {
      setOpenInvoiceSplitDialog(true);
    }
  };

  const handleOnCloseInvoiceSplitDialog = () => {
    setOpenInvoiceSplitDialog(false);
  };

  const [invoiceIsFromExternalSource, setInvoiceIsFromExternalSource] = useState<boolean>(false);
  const [invoiceImagesLoading, setInvoiceImagesLoading] = useState<boolean>();
  const [invoiceImagesError, setInvoiceImagesError] = useState<string>();
  const [invoiceImages, setInvoiceImages] = useState<string[]>([]);
  const [invoiceImageFilenames, setInvoiceImageFilenames] = useState<string[]>([]);


  const [loadExternalInvoiceImagesQuery, queryExternalInvoiceImagesResponse] = useLazyQuery(QUERY_EXTERNAL_INVOICE_IMAGES);
  const [loadInvoiceImagesQuery, queryInvoiceImagesResponse] = useLazyQuery(QUERY_STORAGE_FILES);
  const topParentInvoice = invoiceContext.getTopParentInvoice(invoice ?? {});



  const [invoiceFiles, setInvoiceFiles] = useState<StorageFile[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [openFileList, setOpenFileList] = useState<boolean>(false);

  const ChangeOrderImagesStorageFileKey = getStorageFileKey(DocumentType.INVOICE, selectedInvoiceId ?? "");

  function loadInvoiceFiles(selectedInvoiceId: string) {
    var storageFileKey = getStorageFileKey(DocumentType.INVOICE, selectedInvoiceId);
    loadInvoiceImagesQuery(getStorageFilesGraphqlQueryOptions({ includeContent: true, searchKey: storageFileKey }));
  }

  const refreshInvoiceFiles = () => {
    if (!selectedInvoiceId) {
      return;
    }
    loadInvoiceImagesQuery(getStorageFilesGraphqlQueryOptions({ includeContent: true, searchKey: ChangeOrderImagesStorageFileKey }));
  };

  const onDeletedInvoiceFile = (deletedFile: StorageFile): void => {
    setInvoiceFiles(invoiceFiles.filter((file) => file.id !== deletedFile.id));
  };

  useEffect(() => {
    var storageFiles: StorageFile[] = queryInvoiceImagesResponse.data?.storageFiles ?? [];
    setInvoiceFiles(storageFiles);
  }, [queryInvoiceImagesResponse.data]);

  useEffect(() => {
    setInvoiceImages(invoiceFiles.map(invoiceFile => invoiceFile.content ?? ''));
  }, [invoiceFiles]);

  useEffect(() => {
    const externalAttachmentId = guidIsNullOrEmpty(topParentInvoice?.externalAttachmentId) ? topParentInvoice?.externalId : topParentInvoice?.externalAttachmentId;
    if (topParentInvoice?.externalProvider && topParentInvoice.externalProvider.trim().length > 0 && 
        externalAttachmentId && externalAttachmentId.trim().length > 0) {
      loadExternalInvoiceImagesQuery({
        variables: {
          externalId: externalAttachmentId,
          externalProvider: topParentInvoice.externalProvider.toUpperCase()
        },
      });
      setInvoiceImagesLoading(true);
      setInvoiceImagesError(undefined);
      setInvoiceIsFromExternalSource(true);
    } else if (projectContext.getSelectedProject()?.id && topParentInvoice?.id && selectedInvoiceId) {
      loadInvoiceFiles(selectedInvoiceId)
    }
  }, [invoice]);

  useEffect(() => {
    if (queryExternalInvoiceImagesResponse.error) {
      console.log(queryExternalInvoiceImagesResponse.error);
      setInvoiceImagesError(queryExternalInvoiceImagesResponse.error.message);
      return;
    }
    if (queryExternalInvoiceImagesResponse.loading || !queryExternalInvoiceImagesResponse.data) {
      setInvoiceImagesLoading(true);
      return;
    }
    setInvoiceImagesError(undefined);
    setInvoiceImagesLoading(false);
    if (queryExternalInvoiceImagesResponse?.data.externalInvoiceImages) {
      const base64Images = queryExternalInvoiceImagesResponse.data.externalInvoiceImages.map((externalInvoiceImage: any) => externalInvoiceImage.image);
      setInvoiceImages(base64Images.slice());
    }
  }, [queryExternalInvoiceImagesResponse.loading, queryExternalInvoiceImagesResponse.error, queryExternalInvoiceImagesResponse.data]);

  useEffect(() => {
    if (queryInvoiceImagesResponse.error) {
      console.log(queryInvoiceImagesResponse.error);
      setInvoiceImagesError(queryInvoiceImagesResponse.error.message);
      return;
    }
    if (queryInvoiceImagesResponse.loading || !queryInvoiceImagesResponse.data) {
      setInvoiceImagesLoading(true);
      return;
    }
    setInvoiceImagesError(undefined);
    setInvoiceImagesLoading(false);
  }, [queryInvoiceImagesResponse.loading, queryInvoiceImagesResponse.error,]);

  const renderButtonRow = () => {
    const xs = screenIsMobile ? 12 : false;
    return (
      <Grid container item xs={12} spacing={1}>
        {projectContext.hasProjectAccess(RoleType.WRITER) && (
          <Grid item xs={xs}>
            <Button fullWidth color="primary" variant="contained" onClick={() => openEditDialog(invoice)} startIcon={<Edit />}>
              {languageContext.getMessage("editInvoice")}
            </Button>
          </Grid>
        )}
        {projectContext.hasProjectAccess(RoleType.WRITER) && (
          <Grid item xs={xs}>
            <Button fullWidth color="primary" variant="contained" onClick={() => handleOnClickOpenInvoiceSplitDialog()} startIcon={<CallSplit />}>
              {languageContext.getMessage("splitInvoice")}
            </Button>
          </Grid>
        )}
        {projectContext.hasProjectAccess(RoleType.WRITER) && topParentInvoice?.id && !invoiceIsFromExternalSource && (
          <>
            <Grid item xs={xs}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => {
                  setIsDialogOpen(true);
                  //   setCurrentInvoiceId(invoice.id ?? "");
                }}
                startIcon={<PublishIcon />}
              >
                {languageContext.getMessage("uploadImage")}
              </Button>
            </Grid>

            {/* <FileSelect
                            inputId={`fileSelect_${topParentInvoice.id}`}
                            onSelectedFiles={newImageFiles => {
                                setIsPublishingImages(true);
                                onUploadFiles(
                                    storageMutationContext,
                                    projectContext,
                                    newImageFiles,
                                    getStorageFileFullAimzProjectKey(invoice?.projectId ?? '', invoice?.id ?? ''),
                                    (invoiceImages, filenames) => {
                                        setIsPublishingImages(false);
                                        setInvoiceImages(invoiceImages);
                                        setInvoiceImageFilenames(filenames);
                                    })
                            }}
                            buttonTitle={<>{languageContext.getMessage('uploadImage')}{isPublishingImages ? <CircularProgress size={20} color={'secondary'} /> : <></>}</>}
                            fileAcceptance={'image/jpeg,image/png'}
                            multiple={true}
                            useRegularButton={true} /> */}
          </>
        )}
        <Grid item xs={xs}>
          <Button
            color="primary"
            variant="contained"
            disabled={invoiceImages.length === 0}
            onClick={() => {
              loadInvoiceFiles(selectedInvoiceId ?? "");
              setOpenFileList(true);
            }}
            style={{ marginRight: 5, marginBottom: 5 }}
          >
            {languageContext.getMessage("downloadImage")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {openFileList && <StorageFileDialog open={openFileList} onClose={() => setOpenFileList(false)} files={invoiceFiles} onDeletedFile={onDeletedInvoiceFile} />}
      {isDialogOpen && (
        <FileUploadDialog
          title={languageContext.getMessage("uploadFiles")}
          description={""}
          dropAreaText={languageContext.getMessage("dragAndDropFiles")}
          imagesStorageFileKey={getStorageFileKey(DocumentType.INVOICE, selectedInvoiceId ?? "")}
          onClose={() => setIsDialogOpen(false)}
          groups={[
            {
              key: languageContext.getMessage("files"),
              name: languageContext.getMessage("file"),
              pluralName: languageContext.getMessage("files"),
              extensions: [".png", ".jpg", ".jpeg", ".md", ".txt", ".pdf", ".xls", ".xlxs", ".csv"],
            },
          ]}
          defaultGroup={{
            key: "default",
            name: "document",
            pluralName: "documents",
            extensions: [".jpeg", ".png", ".jpeg", ".pdf"],
          }}
          onUploadFile={onUploadFile}
          setImages={setInvoiceImages}
          setImageFilenames={setInvoiceImageFilenames}
          existingFileNames={invoiceImageFilenames}
          onUploadCompleted={() => refreshInvoiceFiles()}
          multiple={false}
        />
      )}
      <NewInvoiceDialog open={showEditDialog} onClose={onCloseEditDialog} invoice={selectedInvoice} editMode={true} isDeletable={!invoiceIsFromExternalSource}></NewInvoiceDialog>
      <InvoiceSplitDialog invoice={invoice} open={openInvoiceSplitDialog} onClose={handleOnCloseInvoiceSplitDialog} />
      <Grid container direction={"row"} spacing={1} alignItems={"stretch"}>
        <Grid item xs={12} md={getMdSize()}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ViewHeading title={languageContext.getMessage("invoice")}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={"auto"}>
                    <SelectInvoice selectedInvoiceId={selectedInvoiceId} onChange={onChangeSelectedInvoice} />
                  </Grid>
                </Grid>
              </ViewHeading>
            </Grid>

            {invoice && (invoice.invoiceIsSplit === true || invoiceContext.invoiceIsChild(invoice)) && (
              <Grid item xs={12}>
                <Paper className={classes.card} elevation={2}>
                  <Grid container spacing={1} style={{ padding: "1em" }}>
                    {invoiceContext.invoiceIsChild(invoice) && (
                      <>
                        <Grid container item xs={12} spacing={1}>
                          <Grid item xs={2}>
                            <Button color="primary" variant="contained" onClick={() => onChangeSelectedInvoice(invoice?.parentId)}>
                              <NavigateNextIcon />
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <CurrencyInput
                              fullWidth
                              label={`${languageContext.getMessage("parentInvoice")} ${languageContext.getMessage("amount")}`}
                              readOnly={true}
                              value={invoiceContext.getInvoice(invoice?.parentId ?? "")?.amount ?? 0.0}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CurrencyInput
                              fullWidth
                              label={`${languageContext.getMessage("parentInvoice")} ${languageContext.getMessage("withheldAmount")}`}
                              readOnly={true}
                              value={invoiceContext.getInvoice(invoice?.parentId ?? "")?.withheldAmount ?? 0.0}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {invoice.invoiceIsSplit === true && (
                      <>
                        {invoiceContext.getInvoicesByParentId(invoice.id).map((childInvoice) => (
                          <>
                            <Grid container item xs={12} spacing={1}>
                              <Grid item xs={2}>
                                <Button color="primary" variant="contained" onClick={() => onChangeSelectedInvoice(childInvoice.id)}>
                                  <NavigateNextIcon />
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <CurrencyInput
                                  fullWidth
                                  label={`${languageContext.getMessage("childInvoice")} ${languageContext.getMessage("amount")}`}
                                  readOnly={true}
                                  value={childInvoice.amount ?? 0.0}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <CurrencyInput
                                  fullWidth
                                  label={`${languageContext.getMessage("childInvoice")} ${languageContext.getMessage("withheldAmount")}`}
                                  readOnly={true}
                                  value={childInvoice.withheldAmount ?? 0.0}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            )}

            {invoice && (
              <Grid item xs={12}>
                <Paper className={classes.card} elevation={2}>
                  <Grid container spacing={1} style={{ padding: "1em" }}>
                    <Grid item xs={12} sm={6}>
                      <TextField fullWidth label={languageContext.getMessage("project")} value={projectContext.getProject(invoice?.projectId)?.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InvoiceExternalProjectIdSelect externalProjectId={invoice.externalProjectId} readonly={true} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectAccount selectedAccount={accountContext.getAccount(invoice.accountId)} onSelectedAccountChange={() => { }} readonly={true} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InvoiceChangeOrderSelect accountId={invoice.accountId} contractId={invoice.contractId} changeOrderId={invoice.changeOrderId} readonly={true} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField label={languageContext.getMessage("supplier")} value={invoice.supplier} InputProps={{ readOnly: true }} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField label={languageContext.getMessage("system")} value={!guidIsNullOrEmpty(invoice.externalProvider) ? invoice.externalProvider : defaultAimzExternalProviderId} InputProps={{ readOnly: true }} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CurrencyInput
                        fullWidth
                        label={
                          <div style={{ display: "inline-flex", alignItems: "middle" }}>
                            {languageContext.getMessage("amount")}
                            &nbsp;{getRenderedInvoiceFlag(getInvoiceFlags(invoice), theme, languageContext)}
                          </div>
                        }
                        readOnly={true}
                        value={invoice.amount ?? 0.0}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CurrencyInput fullWidth label={languageContext.getMessage("withheldAmount")} readOnly={true} value={invoice.withheldAmount ?? 0.0} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker fullWidth readOnly={true} label={languageContext.getMessage("voucherDate")} date={invoice.voucherDate} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker fullWidth readOnly={true} label={languageContext.getMessage("dueDate")} date={invoice.dueDate} />
                    </Grid>
                    <TicketLoading documentId={invoice?.id} circularProgressProps={{ size: 25 }} setComponentBeforeProgress={true} component={renderButtonRow()} />
                  </Grid>
                </Paper>
              </Grid>
            )}

            {invoice && (
              <Grid item xs={12}>
                <Paper className={classes.card} elevation={2}>
                  <Grid container spacing={1}>
                    <List subheader={<ListSubheader>{`${languageContext.getMessage("comments")} ${invoice?.comments?.length}`}</ListSubheader>} dense={true}>
                      {invoice?.comments?.map((comment) => (
                        <ListItem key={comment}>
                          <ListItemText primary={comment} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>

        {invoice && (
          <Grid item xs={12} md={5}>
            <ShowInvoiceImages loading={invoiceImagesLoading} error={invoiceImagesError} base64Images={invoiceImages} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withTheme(InvoiceDetails);
