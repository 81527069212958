import { CircularProgress, CircularProgressProps, withTheme } from "@material-ui/core";
import React from "react";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { Guid } from "../../utils/common-types";

type props = {
    documentId?: Guid;
    component?: any; 
    setComponentBeforeProgress?: boolean;
    circularProgressProps?: CircularProgressProps;
}

const TicketLoading: React.FC<props> = ({
    documentId,
    component, 
    setComponentBeforeProgress,
    circularProgressProps }) => {
    setComponentBeforeProgress = setComponentBeforeProgress ?? false;
    const ticketContext = useTicketContext();
    
    // const ticketReceived = !documentId || ticketContext.checkReceivedDocumentId(documentId);
    const ticketReceived = true;

    if (ticketReceived) {
        return component ?? <></>;
    }

    if (!circularProgressProps) {
        circularProgressProps = {
            size: 15,
        }
    }

    if (!component) {
        return <CircularProgress {...circularProgressProps} />
    }

    if (setComponentBeforeProgress) {
        return (
            <>
                {component}
                &nbsp;
                <CircularProgress {...circularProgressProps} />
            </>
        );
    }

    return (
        <>
            <CircularProgress {...circularProgressProps} />
            &nbsp;
            {component}
        </>
    );
}

export default withTheme(TicketLoading);