import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, Account, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface AccountSubscriptionsContext {
    subscribedAccounts: Array<Account>,
}

const AccountSubscriptionsContext = createContext<AccountSubscriptionsContext>(null as unknown as AccountSubscriptionsContext);

export const AccountSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedAccounts, setSubscribedAccounts] = useState<Array<Account>>([]);

    const updateSubscribedAccounts = (stateDocuments: StateDocument[]) => {
        const subscribedAccounts = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.ACCOUNT && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Account);
        setSubscribedAccounts(subscribedAccounts);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedAccounts([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedAccounts(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: AccountSubscriptionsContext = {
        subscribedAccounts,
    };

    return (
        <AccountSubscriptionsContext.Provider value={projectContext}>
            {children}
        </AccountSubscriptionsContext.Provider>
    );
}

export const useAccountSubscriptionsContext = (): AccountSubscriptionsContext => {
    return useContext(AccountSubscriptionsContext);
}
