import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { AccountType } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
    selectedAccountGroup: string | undefined;
    accountTypeFocus?: AccountType;
    onSelectedAccountGroupChange: (newSelectedAccountGroup: string | undefined) => void;
    freeSolo?: boolean;
}

const SelectAccountGroup: React.FC<props> = ({
    selectedAccountGroup,
    accountTypeFocus,
    onSelectedAccountGroupChange, 
    freeSolo }) => {

    const [inputValue, setInputValue] = React.useState('');
    const languageContext = useLanguageContext();
    const accountContext = useAccountContext();

    const accountGroups: (string | undefined)[] = [];
    accountContext.getAccounts(accountTypeFocus).forEach(account => {
        if (accountGroups.indexOf(account.accountGroup) < 0) {
            accountGroups.push(account.accountGroup);
        }
    });
    const defaultAccountGroup = selectedAccountGroup ?? null;

    const handleChange = (event: any, newSelectedAccountGroup: string | undefined | null) => {
        onSelectedAccountGroupChange(newSelectedAccountGroup ?? undefined);
    }

    return <Autocomplete
        options={freeSolo ? accountGroups.filter(group => (group ?? "").toLowerCase().includes(inputValue.toLowerCase())) : accountGroups}
        freeSolo={freeSolo}
        getOptionLabel={(accountGroup) => accountGroup ?? ''}
        value={defaultAccountGroup}
        onChange={handleChange}
        inputValue={freeSolo ? inputValue : undefined}
        onInputChange={freeSolo ? (event, newInputValue) => {
          setInputValue(newInputValue);
          handleChange(event, newInputValue);
        } : undefined}
        renderInput={(params) => <TextField {...params}
            label={languageContext.getMessage('accountGroup')}
            variant="standard" />}
    />
}

export default SelectAccountGroup;