import { Button, IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useState } from 'react'; 

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
    fileSelect: {
      color: theme.palette.primary.light,
    },
}));
  

type props = {
    inputId: string;
    onSelectedFiles?: (files: File[]) => void;
    buttonTitle?: string | React.ReactNode;
    startIcon?: string | React.ReactNode;
    useStartIcon?: boolean;
    fileAcceptance?: string;
    useRegularButton?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    fullWidth?: boolean;
    style?: React.CSSProperties;
}

const FileSelect: React.FC<props> = ({
    inputId,
    onSelectedFiles,
    buttonTitle,
    startIcon,
    useStartIcon,
    fileAcceptance,
    useRegularButton,
    disabled,
    multiple,
    fullWidth,
    style,
}) => {

    const classes = useStyles();
    const [inputKey, setInputKey] = useState<React.Key>(Date.now().toString());
     
    const onFileSelected = (event: any) => {
        const fileList: FileList = event.target.files;
        const files: File[] = [];
        for (let i = 0; i < fileList.length; i++) {
            if (fileList.item(i)) {
                files.push(fileList.item(i) as File);
            }
        }
        if (onSelectedFiles && files.length > 0) {
            onSelectedFiles(files);
        }
    };
     
    return ( 
        <>
        <input
            accept={fileAcceptance ?? ''}
            className={classes.input}
            id={inputId}
            type="file"
            key={inputKey}
            onChange={onFileSelected}
            multiple={multiple}
            style={style}
        />
        {!useRegularButton && <Tooltip title={buttonTitle ?? ''}>
            <label htmlFor={inputId}>
            <IconButton
                className={classes.fileSelect}
                color="primary"
                component="span"
                disabled={disabled}
                style={style}
                onClick={() => setInputKey(Date.now().toString())}
            >
                <PublishIcon fontSize="large" />
            </IconButton>
            </label>
        </Tooltip>}
        {useRegularButton && <label htmlFor={inputId}> 
            <Button
                fullWidth={fullWidth ?? true}
                color="primary"
                component="span"
                variant="contained"
                disabled={disabled}
                onClick={() => setInputKey(Date.now().toString())}
                style={style}
                startIcon={useStartIcon ? (startIcon ?? <PublishIcon fontSize="large" />) : undefined}>
                {buttonTitle}
            </Button>
            </label>}
        </>
    ); 
  } 
  
  export default FileSelect; 