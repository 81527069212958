import { withTheme } from '@material-ui/core';
import { ChartOptions } from 'chart.js';
import React from 'react';
import LineGraph from '../../../component/graphComponents/LineGraph';
import { dateFrom, getRandomDate } from '../../../utils/dateTools';
import { getRandomInt, getRandomNumber } from '../../../utils/randomTools';

const StackGraphView: React.FC<{}> = () => {

  return (
    <LineGraph chartComponentProps={{data: data1, height: 400}} chartOptions={options1} chartTitle={'Stack Graph'} chartSubHeader={'Usefull for transactions grouped by change orders'} />
  );
}

export default withTheme(StackGraphView);



const sortByX = (a: any, b: any) => {
  return a.x.getTime() - b.x.getTime();
}

const createData = (endAmount: number, startDate: Date, endDate: Date, numTransactions: number) => {

  let result: { x: Date, y: number }[] = [];

  let sumDelta = endAmount / numTransactions;

  for (let i = 0; i < numTransactions; i++) {
    result.push({
      x: getRandomDate(startDate, endDate),
      y: sumDelta
    });
  }

  for (let i = 0; i < result.length; i++) {
    const a = result[i];
    const b = result[getRandomInt(numTransactions - 1)];

    const amount = getRandomNumber(0, a.y * 4 / 5);
    a.y -= amount;
    b.y += amount;
  }

  result[0].x = dateFrom(startDate, 0);
  result[0].y = 0;
  result[1].x = dateFrom(endDate, 0);
  
  return result.sort(sortByX);
}

const borderColors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
]

const backgroundColors = [
  'rgba(255, 99, 132, 0.8)',
  'rgba(54, 162, 235, 0.8)',
  'rgba(255, 206, 86, 0.8)',
  'rgba(75, 192, 192, 0.8)',
  'rgba(153, 102, 255, 0.8)',
  'rgba(255, 159, 64, 0.8)'
];

const now = new Date();
const test = [
  createData(getRandomNumber(250000, 500000), dateFrom(now, -100), dateFrom(now, 30), 30),
  createData(getRandomNumber(80000, 100000), dateFrom(now, -60), dateFrom(now, 30), 10),
  createData(getRandomNumber(80000, 100000), dateFrom(now, -45), dateFrom(now, 30), 10),
  createData(getRandomNumber(80000, 100000), dateFrom(now, -30), dateFrom(now, 30), 10),
];

const all = test[0].concat(test[1], test[2], test[3]);

all.forEach(element => {

  test.forEach(t => {
    if(t.indexOf(element) !== -1) return;
    t.push({
      x: element.x,
      y: 0
    });
  });

});

test[0] = test[0].sort(sortByX);
test[1] = test[1].sort(sortByX);
test[2] = test[2].sort(sortByX);
test[3] = test[3].sort(sortByX);

test.forEach(t => {
  let sum = t[0].y;
  t.forEach(a => {
    sum += a.y;
    a.y = sum;
  });
});

const data1 = {
  datasets: [{
    label: 'Transaksjoner',
    backgroundColor: backgroundColors[0],
    borderColor: borderColors[0],
    lineTension: 0,
    data: test[0].sort(sortByX),
  },
  {
    label: 'Endringordre 1',
    backgroundColor: backgroundColors[1],
    borderColor: borderColors[1],
    lineTension: 0,
    data: test[1].sort(sortByX),
  },
  {
    label: 'Endringordre 2',
    backgroundColor: backgroundColors[2],
    borderColor: borderColors[2],
    lineTension: 0,
    data: test[2].sort(sortByX),
  },
  {
    label: 'Endringordre 3',
    backgroundColor: backgroundColors[3],
    borderColor: borderColors[3],
    lineTension: 0,
    data: test[3].sort(sortByX),
  }]
};


let options1: ChartOptions = {
  legend: {
    position: 'bottom',
  },
  responsive: true,
  scales: {
    xAxes: [{
      type: 'time',
      display: true,
      scaleLabel: {
        display: true,
        labelString: "Date",
      },
      time: {
        isoWeekday: false,
        unit: "day"
      }
    }],
    yAxes: [{
      ticks: {
        beginAtZero: true,
      },
      stacked: true,
      display: true,
      scaleLabel: {
        display: true,
        labelString: 'NOK',
      }
    }]
  }
};