import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { AccrualAndOperationsExpenses, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";

export const QUERY_ACCRUAL_AND_OPERATIONS_EXPENSES: DocumentNode = gql(`
    query accrualAndOperationsExpenses(
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $projectId: UUID
        $accountId: UUID
        $name: String
        $fromDate: Date
        $toDate: Date
    ) {
        accrualAndOperationsExpenses(
        id: $id
        documentType: $documentType
        state: $state
        created: $created
        projectId: $projectId
        accountId: $accountId
        name: $name
        fromDate: $fromDate
        toDate: $toDate
        ) {
            id
            documentType
            stateTime
            state
            created
            projectId
            accountId
            name
            fromDate
            toDate
            forecast
            remainingForecastsToDistribute
            monthlyExpenseItems {
                date
                expense
                disabled
            }
        }
    }  
`);

export const getAccrualAndOperationsExpensesGraphqlQueryOptions = (accrualAndOperationsExpenses: AccrualAndOperationsExpenses): QueryHookOptions => {
    accrualAndOperationsExpenses.state = accrualAndOperationsExpenses.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {variables: {}}
    options.variables = {};
    if (accrualAndOperationsExpenses.id !== undefined) options.variables['id'] = accrualAndOperationsExpenses.id
    if (accrualAndOperationsExpenses.state !== undefined) options.variables['state'] = accrualAndOperationsExpenses.state
    if (accrualAndOperationsExpenses.name !== undefined) options.variables['name'] = accrualAndOperationsExpenses.name
    if (accrualAndOperationsExpenses.fromDate !== undefined) options.variables['fromDate'] = toISODate(accrualAndOperationsExpenses.fromDate);
    if (accrualAndOperationsExpenses.toDate !== undefined) options.variables['toDate'] = toISODate(accrualAndOperationsExpenses.toDate);
    return options;
}