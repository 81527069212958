import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';

type PercentageFormatCustomInputProps = {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const PercentageFormatCustomInput = (props: PercentageFormatCustomInputProps) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: "percentageValue",
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={false} 
            isNumericString={true}
            allowNegative={false}
            fixedDecimalScale={true}
            decimalScale={2}
            decimalSeparator={','}
            prefix={`${props.name} `}
        />
    );
}

type props = {
    label?: string;
    percentage: number;
    onChange?: (percentage: number) => void;
    isCost?: boolean;
    readOnly?: boolean;
    fullWidth?: boolean;
    updateOnDefaultPercentageChange?: boolean;
    disabled?: boolean;
}

const PercentageInput: React.FC<props> = ({
    label,
    percentage,
    onChange,
    readOnly,
    fullWidth,
    updateOnDefaultPercentageChange,
    disabled,
}) => {

    const [values, setValues] = React.useState({
        percentageValue: percentage,
    });

    useEffect(() => {
        if (updateOnDefaultPercentageChange) {
            setValues({
                percentageValue: percentage,
            });
        }
    }, [percentage]);
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
        if (event.target.name === "percentageValue") {
            const value = event.target.value.replaceAll(',', '.')
            percentage = parseFloat(value);
            if (onChange) {
                onChange(percentage);
            }
        }
    };

    const incorrectEntry = values.percentageValue > 100;
    return (<TextField
            label={label}
            fullWidth={fullWidth}
            value={values.percentageValue}
            onChange={event => {
                handleChange(event as React.ChangeEvent<HTMLInputElement>);
            }}
            name={'%'}
            InputProps={{
                readOnly: readOnly,
                inputComponent: PercentageFormatCustomInput as any,
            }}
            error={incorrectEntry}
            disabled={disabled}
        />
    );
}

export default PercentageInput