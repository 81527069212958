import { Divider, Paper, Typography, withTheme, makeStyles, Theme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  divider: {
    marginBottom: "1em",
  },
}));

type props = {
  title?: any;
  children?: React.ReactNode;
};

const ViewHeading: React.FC<props> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={2}>
      {title && (
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
      )}
      {title && <Divider className={classes.divider} />}
      {children && <div>{children}</div>}
    </Paper>
  );
};

export default withTheme(ViewHeading);
