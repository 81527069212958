import { ChartData, ChartDataSets, ChartOptions, ChartPoint } from "chart.js";
import React from "react";
import ScatterGraph from "../graphComponents/ScatterGraph";
import { LanguageContext, useLanguageContext } from "../../contexts/language/LanguageContext";
import { CurrencyType } from "../../contracts/contracts";

const data = (invoices: number[], lol: number[], label?: string): ChartData => {
    let index50 = 0;
    let index85 = 0;
    const dataPoints: ChartPoint[] = invoices.map(invoice =>{
        let tmp = lol[invoices.indexOf(invoice)];
        if (tmp == 0.5) {
            index50 = invoices.indexOf(invoice);
        }
        if (tmp == 0.85) {
            index85 = invoices.indexOf(invoice);
        }
        return {
            x: Math.floor(invoice),
            y: tmp
        }
    });

    const dataSet: ChartDataSets = {
        label: 'Cost',
        fill: true,
        lineTension: 0,
        borderColor: '#1976d2',
        pointBorderColor: '#666',
        pointBackgroundColor: '#fff',
        pointRadius: 0,
        showLine: true,
        data: dataPoints,
        order: 3
    }

    const dataSet50: ChartDataSets = {
        label: 'P50',
        fill: false,
        lineTension: 0,
        backgroundColor: '#31d655',
        borderColor: '#30a645',
        pointBorderColor: '#666',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        pointRadius: 5,
        pointHitRadius: 10,
        showLine: true,
        data: [{x: Math.floor(invoices[index50]), y:0}, {x: Math.floor(invoices[index50]), y:0.5}],
        order: 1
    }

    const dataSet85: ChartDataSets = {
        label: 'P85',
        fill: false,
        lineTension: 0,
        backgroundColor: '#FF4646',
        borderColor: '#FF4756',
        pointBorderColor: '#666',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        pointRadius: 5,
        pointHitRadius: 10,
        showLine: true,
        data: [{x: Math.floor(invoices[index85]), y:0}, {x: Math.floor(invoices[index85]), y:0.85}],
        order: 2
    }

    return {
        datasets: [dataSet, dataSet50, dataSet85]
    }
};

let options = (languageContext: LanguageContext, maxTick: number, minTick: number, currency?: String): ChartOptions => {
    currency = currency ?? CurrencyType.UNDEFINED;
    const amountLabel = languageContext.getMessage('amount') + ` (${currency})`;
    return {
        legend: {
            position: 'bottom',
        },
        responsive: true,
        tooltips: {enabled: true},
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: "Cost",
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Probability',
                }
            }]
        }
    }
};

type props = {
    invoices: number[];
    lol: number[];
}

const RiskGraph: React.FC<props> = ({ 
    invoices,
    lol}) => {

    const languageContext = useLanguageContext();

    return (
        <ScatterGraph
            chartComponentProps={{
                data: data(invoices, lol, languageContext.getMessage('invoices')),
                // height: 400,
            }}
            chartOptions={options(languageContext, Math.floor(invoices[50]), Math.floor(invoices[60]))}
            chartTitle='Cost' />

    );
}

export default RiskGraph;