import { withTheme } from '@material-ui/core';
import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

// https://nivo.rocks/bar/

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const BarGraphNivoView: React.FC<{}> = () => {
  return <ResponsiveBar
        data={data}
        keys={[ 'hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut' ]}
        indexBy="country"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        colors={{ scheme: 'nivo' }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'fries'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'sandwich'
                },
                id: 'lines'
            }
        ]}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'country',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'food',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
}

export default withTheme(BarGraphNivoView);

const data = [
  {
    "country": "AD",
    "hot dog": 86,
    "hot dogColor": "hsl(37, 70%, 50%)",
    "burger": 169,
    "burgerColor": "hsl(280, 70%, 50%)",
    "sandwich": 117,
    "sandwichColor": "hsl(135, 70%, 50%)",
    "kebab": 96,
    "kebabColor": "hsl(267, 70%, 50%)",
    "fries": 128,
    "friesColor": "hsl(295, 70%, 50%)",
    "donut": 43,
    "donutColor": "hsl(249, 70%, 50%)"
  },
  {
    "country": "AE",
    "hot dog": 62,
    "hot dogColor": "hsl(321, 70%, 50%)",
    "burger": 13,
    "burgerColor": "hsl(115, 70%, 50%)",
    "sandwich": 86,
    "sandwichColor": "hsl(136, 70%, 50%)",
    "kebab": 12,
    "kebabColor": "hsl(146, 70%, 50%)",
    "fries": 113,
    "friesColor": "hsl(124, 70%, 50%)",
    "donut": 59,
    "donutColor": "hsl(2, 70%, 50%)"
  },
  {
    "country": "AF",
    "hot dog": 129,
    "hot dogColor": "hsl(64, 70%, 50%)",
    "burger": 38,
    "burgerColor": "hsl(289, 70%, 50%)",
    "sandwich": 195,
    "sandwichColor": "hsl(93, 70%, 50%)",
    "kebab": 9,
    "kebabColor": "hsl(21, 70%, 50%)",
    "fries": 74,
    "friesColor": "hsl(340, 70%, 50%)",
    "donut": 85,
    "donutColor": "hsl(244, 70%, 50%)"
  },
  {
    "country": "AG",
    "hot dog": 165,
    "hot dogColor": "hsl(59, 70%, 50%)",
    "burger": 108,
    "burgerColor": "hsl(267, 70%, 50%)",
    "sandwich": 38,
    "sandwichColor": "hsl(104, 70%, 50%)",
    "kebab": 11,
    "kebabColor": "hsl(183, 70%, 50%)",
    "fries": 15,
    "friesColor": "hsl(340, 70%, 50%)",
    "donut": 38,
    "donutColor": "hsl(142, 70%, 50%)"
  },
  {
    "country": "AI",
    "hot dog": 180,
    "hot dogColor": "hsl(187, 70%, 50%)",
    "burger": 76,
    "burgerColor": "hsl(48, 70%, 50%)",
    "sandwich": 135,
    "sandwichColor": "hsl(31, 70%, 50%)",
    "kebab": 136,
    "kebabColor": "hsl(75, 70%, 50%)",
    "fries": 24,
    "friesColor": "hsl(274, 70%, 50%)",
    "donut": 141,
    "donutColor": "hsl(250, 70%, 50%)"
  },
  {
    "country": "AL",
    "hot dog": 117,
    "hot dogColor": "hsl(243, 70%, 50%)",
    "burger": 88,
    "burgerColor": "hsl(237, 70%, 50%)",
    "sandwich": 194,
    "sandwichColor": "hsl(333, 70%, 50%)",
    "kebab": 21,
    "kebabColor": "hsl(321, 70%, 50%)",
    "fries": 105,
    "friesColor": "hsl(296, 70%, 50%)",
    "donut": 153,
    "donutColor": "hsl(84, 70%, 50%)"
  },
  {
    "country": "AM",
    "hot dog": 145,
    "hot dogColor": "hsl(123, 70%, 50%)",
    "burger": 152,
    "burgerColor": "hsl(298, 70%, 50%)",
    "sandwich": 170,
    "sandwichColor": "hsl(331, 70%, 50%)",
    "kebab": 36,
    "kebabColor": "hsl(75, 70%, 50%)",
    "fries": 176,
    "friesColor": "hsl(218, 70%, 50%)",
    "donut": 146,
    "donutColor": "hsl(13, 70%, 50%)"
  }
]