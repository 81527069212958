import { withTheme, Container, Link, Typography } from "@material-ui/core";
import React from "react";
import { useLanguageContext, LanguageContext } from "../../contexts/language/LanguageContext";

const NeateCopyright = (languageContext: LanguageContext): JSX.Element => {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      <Link color="inherit" href={languageContext.getMessage('companySite')}>
        {languageContext.getMessage('company')}
      </Link>{' '}
      {2020}
      {'. '}
    </Typography>
  );
}

const ApplicationAppFooter: React.FC<{}> = () => {
  const languageContext = useLanguageContext();

  return (
    <footer>
        <Container>
          {NeateCopyright(languageContext)}
        </Container>
    </footer>
  );
}

export default withTheme(ApplicationAppFooter);