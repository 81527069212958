import React, { createContext, useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollMemory from 'react-router-scroll-memory';
import { alphaNumericString } from "../../utils/stringTools";

export interface BrowserContext {
    getTenantName: (useDefaultTenantFromLocalStorage?: boolean) => string;
    setTenantName: (tenant: string) => void;
    isTenantNameAvailable: () => boolean;
    getTenantBaseUrl: () => string;
}

const BrowserContext = createContext<BrowserContext>(null as unknown as BrowserContext);

export const BrowserContextProvider: React.FC<{}> = ({ children }) => {

    const baseUrl: string = '/client';
    
    const getTenantName = (useDefaultTenantFromLocalStorage?: boolean): string => {
        useDefaultTenantFromLocalStorage = useDefaultTenantFromLocalStorage ?? false;
        const defaultTenant = useDefaultTenantFromLocalStorage ? (localStorage.getItem('tenant') || '') : '';
        let tenant = defaultTenant;
        const urlPath = window.location.pathname;
        if (urlPath.indexOf(baseUrl) >= 0) {
            tenant = urlPath.substring(baseUrl.length);
            let slashIndex = tenant.indexOf('/');
            if (slashIndex === 0 && tenant.length > 1) {
                tenant = tenant.slice(1);
            }
            slashIndex = tenant.indexOf('/');
            if (slashIndex > 0) {
                tenant = tenant.slice(0, slashIndex);
            }
            tenant = tenant.replace('/', '');
            if (tenant.length === 0) {
                tenant = defaultTenant;
            }
        }
        return alphaNumericString(tenant).toLowerCase();
    }

    const setTenantName = (tenant: string): void => {
        localStorage.setItem('tenant', tenant);
        window.location.replace(`${baseUrl}/${tenant}`);
    }

    const isTenantNameAvailable = (): boolean => {
        const urlPath = window.location.pathname;
        return urlPath.startsWith(baseUrl) && getTenantName() !== '';
    }

    const getTenantBaseUrl = (): string => {
        return `${baseUrl}/${getTenantName()}`;
    }

    // useEffect(() => {
    //     if (!isTenantNameAvailable()) {
    //         window.location.replace(getTenantBaseUrl());
    //     }
    // }, [])

    const authProviderContextValue: BrowserContext = {
        getTenantName,
        setTenantName,
        isTenantNameAvailable,
        getTenantBaseUrl,
    }
    
    return  <BrowserContext.Provider value={authProviderContextValue}>
                <BrowserRouter forceRefresh={false} basename={getTenantBaseUrl()}>
                    <ScrollMemory />
                    {children}
                </BrowserRouter>
            </BrowserContext.Provider>
}


export const useBrowserContext = (): BrowserContext => {
    return useContext(BrowserContext);
}