import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, Contract } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useProjectContext } from "../project/projectContext";
import { useUrlContext } from "../url/urlContext";
import { ContractMutationsContextProvider, useContractMutationsContext } from "./mutations/contractMutationsContext";
import { ContractQueriesContextProvider, useContractQueriesContext } from "./queries/contractQueriesContext";
import { ContractSubscriptionsContextProvider, useContractSubscriptionsContext } from "./subscriptions/contractSubscriptionsContext";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { useAccountContext } from "../account/accountContext";
import { useLanguageContext } from "../language/LanguageContext";
import { queryTemplateEngineToProduceContractExcelList } from "./ContractExportTools";

export interface ContractContext {
    getContractSearch: () => Contract,
    searchContracts: (contractSearch: Contract) => void,
    contracts: Contract[],
    getContract: (contractId: Guid | undefined) => Contract | undefined,
    getContracts: (accountId: Guid | undefined) => Contract[],
    mutateContract: (contract: Contract) => void,
    downloadContracts: (contracts: Contract[]) => void,
}

const ContractContext = createContext<ContractContext>(null as unknown as ContractContext);

export const sortContractByName = (a: Contract, b: Contract) => {
    if ((a.name ?? '') < (b.name ?? '')) { return -1; }
    if ((a.name ?? '') > (b.name ?? '')) { return 1; }
    return 0;
}

export const sortContractByCreated = (a: Contract, b: Contract) => {
    if ((a.created ?? '') < (b.created ?? '')) { return -1; }
    if ((a.created ?? '') > (b.created ?? '')) { return 1; }
    return 0;
}

export const ContractContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ContractMutationsContextProvider>
            <ContractQueriesContextProvider>
                <ContractSubscriptionsContextProvider>
                    <ContractSubContextProvider>
                            {children}
                    </ContractSubContextProvider>
                </ContractSubscriptionsContextProvider>
            </ContractQueriesContextProvider>
        </ContractMutationsContextProvider>
    );
}

export const ContractSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const accountContext = useAccountContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const contractMutationsContext = useContractMutationsContext();
    const contractQueriesContext = useContractQueriesContext();
    const contractSubscriptionsContext = useContractSubscriptionsContext();
    const [currentContractSearch, setCurrentContractSearch] = useState<Contract | undefined>(undefined);
    const [contracts, setContracts] = useState<Contract[]>([]);

    const mergeContracts = (oldContracts: Contract[], newContracts: Contract[]): Contract[] => {
        let updatedContracts = oldContracts.slice();
        newContracts.forEach(newContract => {
            if (newContract.projectId !== currentContractSearch?.projectId) {
                return;
            }
            if (currentContractSearch?.accountId && newContract.accountId !== currentContractSearch?.accountId) {
                return;
            }
            const index = updatedContracts.findIndex(contract => contract.id === newContract.id);
            if (index >= 0) {
                if (newContract.state === ActiveState.ACTIVE) {
                    updatedContracts[index] = newContract;
                }
                else {
                    updatedContracts.splice(index, 1);
                }
            } 
            else {
                if (newContract.state === ActiveState.ACTIVE) {
                    updatedContracts.push(newContract); 
                }
            }
        });
        return updatedContracts.sort(sortContractByName);
    }

    const getContractSearch = (): Contract => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
        const accountId = urlState.accountId ? urlState.accountId as string : undefined;
    
        return {
            projectId: projectId,
            accountId: accountId,
        }
    }

    const searchContracts = (contractSearch: Contract): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(contractSearch, currentContractSearch);
        matched = matched && contractSearch?.projectId === currentContractSearch?.projectId;
        matched = matched && contractSearch?.accountId === currentContractSearch?.accountId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && !matched) {
            setCurrentContractSearch(contractSearch);
            setContracts([]);
        }
    }

    const getContract = (contractId: Guid | undefined): Contract | undefined => {
        return contracts.find(contract => contract.id === contractId);
    }

    const getContracts = (accountId: Guid | undefined): Contract[] => {
        if (!accountId) {
            return contracts;
        }
        return contracts.filter(contract => contract.accountId === accountId);
    }

    const mutateContract = (contract: Contract): void => {
        contractMutationsContext.mutateContract(contract, (documentId, variables) => {
            contract = {...contract, ...variables};
            contract.id = documentId;
            setContracts(mergeContracts(contracts, [contract]));
        });
    }

    const downloadContracts = (contracts: Contract[]): void => {
        queryTemplateEngineToProduceContractExcelList(
            contracts, 
            templateEngineQueriesContext, 
            languageContext, 
            accountContext);
    }

    useEffect(() => {
        if (currentContractSearch) {
            contractQueriesContext.queryContracts(currentContractSearch);
        }
    }, [currentContractSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setContracts([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        if(!contractQueriesContext.fetchedContracts) return;
        setContracts(mergeContracts(contracts, contractQueriesContext.fetchedContracts));
    }, [contractQueriesContext.fetchedContracts]);

    
    useEffect(() => {
        setContracts(mergeContracts(contracts, contractSubscriptionsContext.subscribedContracts));
    }, [contractSubscriptionsContext.subscribedContracts]);

    const contractContext: ContractContext = {
        getContractSearch,
        searchContracts,
        contracts,
        getContract,
        getContracts,
        mutateContract,
        downloadContracts,
    };

    return (
        <ContractContext.Provider value={contractContext}>
            {children}
        </ContractContext.Provider>
    );
}

export const useContractContext = (): ContractContext => {
    return useContext(ContractContext);
}
