import React, { useEffect, useState } from "react";
import { Account } from "../../contracts/contracts";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { Grid, TextField } from "@material-ui/core";
import ViewHeading from "../viewComponents/ViewHeading";
import SelectAccount from "../accountComponents/SelectAccount";
import { Autocomplete } from "@material-ui/lab";
import { useContractContext } from "../../contexts/contract/contractContext";

type props = {
  selectedAccount?: Account | undefined;
  selectedSuppliers?: string[] | undefined;
  onSelectedAccountChange?: (newSelectedAccount: Account | undefined) => void;
  onSelectedSuppliersChange?: (newSelectedSuppliers: string[] | undefined) => void;
};

const SelectContractFilter: React.FC<props> = ({ selectedAccount, selectedSuppliers, onSelectedAccountChange, onSelectedSuppliersChange }) => {
  const languageContext = useLanguageContext();
  const contractContext = useContractContext();
  const contracts = contractContext.getContracts(selectedAccount?.id);

  const [supplierFilter, setSupplierFilter] = useState<string[]>([]);

  const updateSupplierFilter = () => {
    const result: string[] = [];
    const contractsToCheck = contracts;
    contractsToCheck.forEach((contract) => {
      const test = contract?.supplier ?? "";
      if (test.trim().length > 0 && result.indexOf(test) === -1) {
        result.push(test);
      }
    });
    setSupplierFilter(result.sort());
  };

  useEffect(() => {
    updateSupplierFilter();
  }, [contractContext.getContracts(undefined), selectedAccount]);

  return (
    <ViewHeading title={`${languageContext.getMessage("contract")}`}>
      <Grid container spacing={2}>
        {onSelectedAccountChange && (
          <Grid item xs={12} lg={3}>
            <SelectAccount selectedAccount={selectedAccount} onSelectedAccountChange={onSelectedAccountChange} />
          </Grid>
        )}
        {onSelectedSuppliersChange && (
          <Grid item xs={12} lg={3}>
            <Autocomplete
              style={{ width: 400 }}
              options={supplierFilter}
              multiple={true}
              getOptionLabel={(supplier) => supplier}
              value={selectedSuppliers}
              onChange={(event, newSelectedSuppliersFilter) => onSelectedSuppliersChange(newSelectedSuppliersFilter)}
              renderInput={(params) => <TextField {...params} label={<>{languageContext.getMessage("supplier")}</>} />}
            />
          </Grid>
        )}
      </Grid>
    </ViewHeading>
  );
};

export default SelectContractFilter;
