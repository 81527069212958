import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles, Paper, Theme, Typography, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { DocumentType, RoleType } from "../../contracts/contracts";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useAccountContext } from "../../contexts/account/accountContext";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GeneralSettings from "../../component/settingsComponents/GeneralSettings";
import ThemeSettings from "../../component/settingsComponents/ThemeSettings";
import RiskAccountSettings from "../../component/settingsComponents/RiskAccountSettings";
import RiskProjectSettings from "../../component/settingsComponents/RiskProjectSettings";
import AuthSettings from "../../component/settingsComponents/AuthSettings";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { Guid } from "../../utils/common-types";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    titleSection: {
        margin: theme.spacing(3, 2),
    },
    dateRangePickerSection: {
        margin: theme.spacing(3, 2, 0),
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    paper: {
        padding: '1em'
    },
    divider: {
        marginBottom: '1em'
    },
}));

const ProjectSettingsView: React.FC<{}> = () => {

    const urlContext = useUrlContext();
    const languageContext = useLanguageContext();
    const classes = useStyles();
    const projectContext = useProjectContext();

    const accountContext = useAccountContext();
    const ticketContext = useTicketContext();
    const userRoles = useUserRoleContext();

    const urlState = urlContext.getUrlState();
    const selectedAccountId = urlState.accountId ? urlState.accountId as Guid : null;
    const accountSearch = accountContext.getAccountSearch();

    useEffect(() => {
      userRoles.queryRoles();
      userRoles.queryUsers();
      ticketContext.setDocumentTypesToWatch([
          DocumentType.ACCOUNT]);
      accountContext.searchAccounts(accountSearch);
    }, [urlContext.currentLocation, projectContext.getSelectedProject()])

    const users = userRoles.getUsers(); 

    const selectedProject = projectContext.getSelectedProject();
    
    const title = `${languageContext.getMessage('project')} ${languageContext.getMessage('settings')}`

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={2}>
                <Typography gutterBottom variant="h5">
                  {title}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header" >
                  <Typography >{languageContext.getMessage('general')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={1}>
            <Grid item xs={12}>
                  <GeneralSettings project={selectedProject}></GeneralSettings>
                  </Grid>
                  <Grid item xs={12}>
                  <ThemeSettings project={selectedProject}></ThemeSettings>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {projectContext.hasProjectAccess(RoleType.ADMIN) && <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header" >
                  <Typography>{languageContext.getMessage('accessControl')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <AuthSettings users={users}></AuthSettings>
                </AccordionDetails>
              </Accordion>}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel2a-header" >
                  <Typography>{languageContext.getMessage('RISK')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                  <RiskAccountSettings></RiskAccountSettings>
                  </Grid>
                  <Grid item xs={12}>
                  <RiskProjectSettings project={selectedProject}></RiskProjectSettings>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
        </Grid>
    );
}

export default withTheme(ProjectSettingsView);