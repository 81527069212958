import { Invoice } from "../../contracts/contracts";
import { capitalizeString } from "../../utils/stringTools";
import { AccountContext } from "../account/accountContext";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

const INVOICE_LIST_TEMPLATE_FILENAME = 'aimz/template_invoice_list.xlsx'
const INVOICE_LIST_TEMPLATE_SHEET_NAME = 'invoice_list'

const sortInvoiceBySupplier = (a: Invoice, b: Invoice) => {
    if ((a.supplier ?? '') < (b.supplier ?? '')) { return -1; }
    if ((a.supplier ?? '') > (b.supplier ?? '')) { return 1; }
    return 0;
}

export const queryTemplateEngineToProduceInvoiceExcelList = (
    invoices: Invoice[], 
    accountContext: AccountContext,
    templateEngineQueriesContext: TemplateEngineQueriesContext, 
    languageContext: LanguageContext): void => {
    const templateFilename: string = INVOICE_LIST_TEMPLATE_FILENAME;
    const templateVariables: object[] = getTemplateEngineInvoiceListReportVariables(invoices, languageContext, accountContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, `${languageContext.getMessage('invoices')}`);
}

const getTemplateEngineInvoiceListReportVariables = (
    invoices: Invoice[], 
    languageContext: LanguageContext, 
    accountContext: AccountContext): object[] => {
    const date: Date = new Date();
    const title: string = `${languageContext.getMessage('invoices')}`;
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return [{
        'tpl_name': INVOICE_LIST_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': getInvoiceListVariables(title, monthString, invoices, accountContext),
    }]
}

const getInvoiceListVariables = (
    title: string, 
    monthString: string, 
    invoices: Invoice[], 
    accountContext: AccountContext): object => {
    const listVariables = {
        'language': {},
        'title': title,
        'date': monthString,
        'invoices': invoices.sort(sortInvoiceBySupplier).map(invoice => {return {
            ...invoice, 
            accountNumber: accountContext.getAccount(invoice.accountId)?.accountNumber ?? (invoice.accountNumber ?? "unkown"), 
            mergedComments: invoice.comments?.join('\n') ?? ''
        }}),
    }
    return listVariables;
} 
