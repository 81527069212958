import { Guid } from "./common-types";

export const getEmptyGuid = (): Guid => {
    return "00000000-0000-0000-0000-000000000000";
};

//uuidv4
export const getRandomGuid = (): Guid => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment     
    // @ts-ignore
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
}

export const guidIsNullOrEmpty = (guid: Guid | string | null | undefined): boolean => {
    if (guid === undefined || guid === null || guid === "" || guid === getEmptyGuid()) return true;
    return false;
}