import React, {  } from 'react';
import { createStyles, Theme, makeStyles, withTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Card, Grid, Box, CircularProgress, TextField } from '@material-ui/core';
import { Risk, RiskType } from '../../contracts/contracts';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import RiskMatrix from './RiskMatrix';
import RiskGraph from './RiskCostGraph';
import RiskMatrixOpportunity from './RiskMatrixOpportunity';
import { useAccountContext } from '../../contexts/account/accountContext';
import { displayCurrency } from '../currencyComponents/CurrencyFormat';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootChange: {
      width: '100%',
    },
    card: {
      padding: '1em',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'initial',
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.dark,
    },
    analysing: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    section: {
      marginTop: theme.spacing(1),
    },
    section1: {
      margin: theme.spacing(2, 2),
    },
    section2: {
      margin: theme.spacing(2),
    },
    section3: {
      margin: theme.spacing(1, 1, 1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    fieldContainer: {
      marginBottom: theme.spacing(1),
    },
    icon: {
      alignItems: 'center',
      margin: 'auto',
      color: '#ffffff',
    },
  }),
);

type props = {
  selectedRisk: Risk;
}

const RiskDetailCard: React.FC<props> = ({
      selectedRisk}) => {

  const classes = useStyles();
  const languageContext = useLanguageContext();
  const accountContext = useAccountContext();

  const account = accountContext.getAccount(selectedRisk.accountId)

  let accountString = '';
  
  if (account != undefined) {
    accountString = account?.accountNumber + ' - ' + account?.name;
  }


  let varValue: number = 0.0;
  let P50Value: number = 0.0;
  let P85Value: number = 0.0;

  const readonly = { readOnly: true };

  if (selectedRisk.riskCostDistribution) {
    varValue = selectedRisk?.riskCostVar ?? 0;
    if (selectedRisk.riskCostDistribution?.length > 0) {
      P50Value = selectedRisk.riskCostDistribution[50];
      P85Value = selectedRisk.riskCostDistribution[85];
    }
  } 
  
  return (<>
    <Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12}>
          <div className={classes.section1}>
            <Typography gutterBottom variant="h5">
              {languageContext.getMessage(selectedRisk.riskType ?? RiskType.RISK)} {selectedRisk.name}
            </Typography>
            <Divider variant="middle" color="textSecondary" />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={2} xs={12}>
          <Box justifyContent="center">
            {selectedRisk?.riskType === RiskType.RISK &&
              <RiskMatrix
                selectedRisk={selectedRisk}
                riskCol={selectedRisk.consequenceMatrix ?? 1}
                riskRow={selectedRisk.probabilityMatrix ?? 1} />
            }
            {selectedRisk?.riskType === RiskType.OPPORTUNITY &&
              <RiskMatrixOpportunity 
                selectedRisk={selectedRisk}
                riskCol={selectedRisk.consequenceMatrix ?? 1}
                riskRow={selectedRisk.probabilityMatrix ?? 1} />
            }
          </Box>
        </Grid>
        <Grid item lg={3} xs={12}>

          <div className={classes.fieldContainer}>
          <TextField
             fullWidth
             multiline
             label={languageContext.getMessage('details')}
             value={selectedRisk.comment}
             InputProps={readonly}
                  />
            </div>
            <div className={classes.fieldContainer}>
          <TextField
             fullWidth
             label={'Status'}
             value={languageContext.getMessage(selectedRisk.state ?? 'INACTIVE')}
             InputProps={readonly}
                  />
            </div>
            {accountString !== '' &&
            <div className={classes.fieldContainer}>
          <TextField
             fullWidth
             label={languageContext.getMessage('account')}
             value={accountString}
             InputProps={readonly}
                  />
            </div>
            }

        </Grid>
        <Grid item lg={3} xs={12}>
          {(selectedRisk?.riskCost !== undefined && selectedRisk?.riskCost !== -1 && selectedRisk?.riskCost !== null) ? 
            <>
            <div className={classes.fieldContainer}>
            <TextField
              fullWidth
              label={languageContext.getMessage('expectation')}
              value={displayCurrency(selectedRisk.riskCost)}
              InputProps={readonly}
                />
            </div>

            <div className={classes.fieldContainer}>
            <TextField
              fullWidth
              label={languageContext.getMessage('variance')}
              value={displayCurrency(varValue)}
              InputProps={readonly}
                />
            </div>

            <div className={classes.fieldContainer}>
            <TextField
              fullWidth
              label={'P50'}
              value={displayCurrency(P50Value)}
              InputProps={readonly}
                />
            </div>

            <div className={classes.fieldContainer}>
            <TextField
              fullWidth
              label={'P85'}
              value={displayCurrency(P85Value)}
              InputProps={readonly}
                />
            </div>

            </>
          :
            <div className={classes.analysing}>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('simulating')}</b>
              </Typography>
              <div className={classes.section}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  size={40}
                  thickness={4}
                />
              </div>
            </div>
          }
        </Grid>
          <Grid item lg={4} xs={12}>
            <RiskGraph invoices={selectedRisk.riskCostDistribution ?? []} lol={selectedRisk.riskCostDistributionP ?? []}></RiskGraph>
            {/* <RiskCommentList changes={riskChanges}></RiskCommentList> */}
          </Grid>
        </Grid>
      </Card>
    </>);
}

export default withTheme(RiskDetailCard);