import { CrewResource, CrewResourceDetails } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { datesAreOnSameMonth } from "../../utils/dateTools";

export const receiveCrewResource = (
    currentCrewResourceSearch: CrewResource | undefined,
    newCrewResource: CrewResource | undefined): CrewResource => {
    if (!newCrewResource) {
        console.error(`Received undefined set of crew entry list: ${newCrewResource}`);
        return {};
    }
    newCrewResource.fromDate = new Date(newCrewResource.fromDate ?? 0);
    newCrewResource.toDate = new Date(newCrewResource.toDate ?? 0);
    newCrewResource.defaultCrewResourceDetails = newCrewResource.defaultCrewResourceDetails ?? {};
    newCrewResource.monthlyCrewResourceDetails = newCrewResource.monthlyCrewResourceDetails ?? [];
    receiveCrewResourceDetails(newCrewResource.defaultCrewResourceDetails, newCrewResource.projectId);
    newCrewResource.monthlyCrewResourceDetails.forEach(crewResourceDetails => {
        receiveCrewResourceDetails(crewResourceDetails, newCrewResource.projectId);
    })
    return newCrewResource;
}

export const receiveCrewResourceDetails = (crewResourceDetails: CrewResourceDetails | undefined, projectId: Guid | undefined) => {
    if (!crewResourceDetails) {
        return;
    }
    crewResourceDetails.date = new Date(crewResourceDetails.date ?? 0);
    crewResourceDetails.projectId = projectId;
}
