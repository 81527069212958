import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Theme, Tooltip } from "@material-ui/core";
import { GridDirection } from "@material-ui/core/Grid";
import { Warning } from "@material-ui/icons/";
import React from "react";
import { useAuthContext } from "../../contexts/auth/authContext";
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useWidthContext } from "../../contexts/WidthContext";

const ApplicationDrawer: React.FC<{ className: string }> = (props) => {

    const isMobileScreen = useWidthContext().isMobileScreen();
    const menuContext = useMenuContext();
    const authContext = useAuthContext();
    const projectContext = useProjectContext();

    const handleOnClickMenu = (id: ApplicationRouteId) => {
        const applicationRoute = menuContext.getApplicationRouteById(id);
        menuContext.setSelectedApplicationRoute(id, applicationRoute.params ?? null);
        if (isMobileScreen) {
            menuContext.setFullMenuVisible(!menuContext.fullMenuVisible);
        }
    }

    const showDrawer = (): boolean => {
        if (isMobileScreen) return menuContext.fullMenuVisible;
        return true;
    }

    const getGridDirection = (): GridDirection => {
        if (!isMobileScreen)
            return "row";
        return "column";
    };

    const classes = makeStyles((theme: Theme) => ({
        content: {
            padding: '1em',
            flexGrow: 1,
            flexShrink: 1,
            flexWrap: "nowrap",
            overflowY: "auto"
        },
        drawer: {
            [theme.breakpoints.up('md')]: {
                flexShrink: 0,
                width: menuContext.fullMenuVisible ? 200 : 60,
            }
        },
    }))();

    const changeMenuTextVisible = (visible: boolean): void => {
        if (visible !== menuContext.fullMenuVisible && isMobileScreen) {
            menuContext.setFullMenuVisible(visible);
        }
    }

    const getMenuItem = (applicationRoute: ApplicationRoute, menuTitle: string): JSX.Element => {
        return <ListItem
            button
            key={applicationRoute.id}
            selected={applicationRoute.id === menuContext.selectedApplicationRoute.id}
            onClick={() => handleOnClickMenu(applicationRoute.id)}>
            <ListItemIcon>{applicationRoute.icon ?? <Warning />}</ListItemIcon>
            {menuContext.fullMenuVisible &&
                <ListItemText primary={menuTitle} />
            }
        </ListItem>
    }

    const drawer = (
        <Paper elevation={6} className={classes.drawer} onMouseOver={() => changeMenuTextVisible(true)} onMouseLeave={() => changeMenuTextVisible(false)}>
            <List dense>
                {menuContext.applicationRoutes.filter(applicationRoute => applicationRoute && !applicationRoute.hidden && authContext.authorized(applicationRoute, projectContext))
                    .map((applicationRoute, index) => {
                    const menuTitle = applicationRoute.menuTitle;
                    if (!menuContext.fullMenuVisible) {
                        return (
                            <Tooltip title={menuTitle} key={applicationRoute.id}>
                                {getMenuItem(applicationRoute, menuTitle)}
                            </Tooltip>
                        )
                    }
                    return getMenuItem(applicationRoute, menuTitle)
                }
                )}
            </List>
            <Divider />
        </Paper>
    );

    return (
        <Grid container direction={getGridDirection()} wrap="nowrap" className={props.className}>
            {showDrawer() && drawer}
            <Grid item container direction="column" className={classes.content}>
                {props.children}
            </Grid>
        </Grid>
    );
}

export default ApplicationDrawer;