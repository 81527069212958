import { Box, Button, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import { Add, Remove, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React from "react";
import { DatePicker } from "./DatePicker";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

type props = {
  label: React.ReactNode;
  date?: Date;
  monthPicker?: boolean;
  yearPicker?: boolean;
  onChange?: (date: Date | undefined) => void;
  onPopDate?: () => void;
  onPushDate?: () => void;
  popDateDisabled?: boolean;
  pushDateDisabled?: boolean;
  disabled?: boolean;
};

export const DatePickerWithPushAndPop: React.FC<props> = ({ label, date, monthPicker, yearPicker, onChange, onPopDate, onPushDate, popDateDisabled, pushDateDisabled, disabled }) => {
  const classes = useStyles();

  return (
    <Grid container wrap="nowrap" spacing={1} alignItems="center">
      <Grid item>
        <IconButton onClick={onPopDate} size="small" disabled={popDateDisabled}>
          <KeyboardArrowLeft />
        </IconButton>
      </Grid>
      <Grid item>
        <DatePicker
          label={label}
          date={date}
          monthPicker={monthPicker}
          yearPicker={yearPicker}
          onChange={(newDate) => {
            if (onChange) {
              onChange(newDate);
            }
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <IconButton onClick={onPushDate} size="small" disabled={pushDateDisabled}>
          <KeyboardArrowRight />
        </IconButton>
      </Grid>
    </Grid>
  );
};
