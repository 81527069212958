import { Grid } from '@material-ui/core';
import React from "react";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { ChangeOrderStatus } from "../../contracts/contracts";
import ViewHeading from '../viewComponents/ViewHeading';
import SelectStatus from './SelectStatus';

type props = {
    selectedStatus?: ChangeOrderStatus | undefined;
    onSelectedStatusChange?: (newSelectedStatus: ChangeOrderStatus | undefined) => void;
}

const SelectChangeOrderLandlordFilter: React.FC<props> = ({
    selectedStatus,
    onSelectedStatusChange
}) => {
        
    const languageContext = useLanguageContext();

    return  <ViewHeading title={`${languageContext.getMessage('changeOrder')} ${languageContext.getMessage('landlordShort')}`}>
                <Grid container spacing={2}>
                    {onSelectedStatusChange &&
                    <Grid item xs={12} lg={3}>
                        <SelectStatus
                            selectedStatus={selectedStatus}
                            onSelectedStatusChange={onSelectedStatusChange} />
                    </Grid>}
                </Grid>
            </ViewHeading>
}

export default SelectChangeOrderLandlordFilter;