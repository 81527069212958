import React, { createContext, useContext } from "react";
import { Interim, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getInterimGraphqlMutationOptions, MUTATE_INTERIM } from "./InterimMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface InterimMutationsContext {
    mutateInterim: (interim: Interim, onTicketSubscribed: (documentId: Guid, variables: any) => void) => void;
}

const InterimMutationsContext = createContext<InterimMutationsContext>(null as unknown as InterimMutationsContext);

export const InterimMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [interimMutation] = useMutation(MUTATE_INTERIM);

    const handleInterimsMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateInterim.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
    }

    const mutateInterim = (interim: Interim, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        const options = getInterimGraphqlMutationOptions(interim)
        interimMutation(options).then((result) => handleInterimsMutationResult(result, options.variables, onTicketSubscribed));
    }

    const interimMutationsContext: InterimMutationsContext = {
        mutateInterim,
    };

    return (
        <InterimMutationsContext.Provider value={interimMutationsContext}>
            {children}
        </InterimMutationsContext.Provider>
    );
}

export const useInterimMutationsContext = (): InterimMutationsContext => {
    return useContext(InterimMutationsContext);
}
