import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, CrewResource, CrewResourceDetails } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";


export const MUTATE_CREW_RESOURCE: DocumentNode = gql`
    mutation crewResource(
            $id: UUID
            $state: ActiveState
            $projectId: UUID! 
            $name: String
            $defaultCrewResourceDetails: CrewResourceDetailsInput
            $monthlyCrewResourceDetails: [CrewResourceDetailsInput]
        ) {
        mutateCrewResource(
            id: $id
            state: $state
            projectId: $projectId
            name: $name
            defaultCrewResourceDetails: $defaultCrewResourceDetails
            monthlyCrewResourceDetails: $monthlyCrewResourceDetails
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getCrewResourceGraphqlMutationOptions = (crewResource: Partial<CrewResource>): QueryHookOptions => {
    crewResource.state = crewResource.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (crewResource.id !== undefined) options.variables['id'] = crewResource.id;
    if (crewResource.state !== undefined) options.variables['state'] = crewResource.state;
    if (crewResource.projectId !== undefined) options.variables['projectId'] = crewResource.projectId;
    if (crewResource.name !== undefined) options.variables['name'] = crewResource.name;
    if (crewResource.defaultCrewResourceDetails !== undefined) options.variables['defaultCrewResourceDetails'] = GetCrewResourceDetailsInput(crewResource.defaultCrewResourceDetails);
    if (crewResource.monthlyCrewResourceDetails !== undefined) options.variables['monthlyCrewResourceDetails'] = crewResource.monthlyCrewResourceDetails.map(crewResourceDetails => GetCrewResourceDetailsInput(crewResourceDetails))
    return options;
}

export const GetCrewResourceDetailsInput = (crewResourceDetails: CrewResourceDetails): Record<string, any> => {
    const crewResourceDetailsInput: Record<string, any> = {}
    crewResourceDetailsInput.date = toISODate(crewResourceDetails.date ?? new Date());
    if (crewResourceDetails.role !== undefined) crewResourceDetailsInput.role = crewResourceDetails.role;
    if (crewResourceDetails.salaryAdjustmentPercentage !== undefined) crewResourceDetailsInput.salaryAdjustmentPercentage = crewResourceDetails.salaryAdjustmentPercentage;
    if (crewResourceDetails.assignmentPercentage !== undefined) crewResourceDetailsInput.assignmentPercentage = crewResourceDetails.assignmentPercentage;
    if (crewResourceDetails.salary !== undefined) crewResourceDetailsInput.salary = crewResourceDetails.salary;
    if (crewResourceDetails.disabled !== undefined) crewResourceDetailsInput.disabled = crewResourceDetails.disabled;
    return crewResourceDetailsInput;
}
