import { Divider, Theme, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CancelOutlined, CheckCircleOutline, ErrorOutline, NotInterested } from '@material-ui/icons';
import { makeStyles, withTheme } from '@material-ui/styles';
import React, { ReactElement } from 'react';
import { LanguageKey, useLanguageContext } from '../../contexts/language/LanguageContext';
import { ElementStatusEnum } from '../../contracts/contracts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'initial',
    marginLeft: '30px',
    marginTop: '30px',
  },
  contentInfo: {
    marginLeft: '-30px',
    marginTop: '-30px',
    background: 'linear-gradient(147deg, #0086ad 0%, #1276bd 74%)', //'linear-gradient(147deg, #31d655 0%, #31d655 74%)'
    maxWidth: '80px',
    maxHeight: '80px',
    borderRadius: '5px',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    color: '#ffffff',
  },
  contentSucc: {
    marginLeft: '-30px',
    marginTop: '-30px',
    background: 'linear-gradient(147deg, #31d655 0%, #30a645 74%)', //'linear-gradient(147deg, #31d655 0%, #31d655 74%)'
    maxWidth: '80px',
    maxHeight: '80px',
    borderRadius: '5px',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    color: '#ffffff',
  },
  contentAlert: {
    marginLeft: '-30px',
    marginTop: '-30px',
    background: 'linear-gradient(147deg, #ff4e50 0%, #fe4e62 74%)', //'linear-gradient(147deg, #31d655 0%, #31d655 74%)'
    maxWidth: '80px',
    maxHeight: '80px',
    borderRadius: '5px',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    color: '#ffffff',
  },
  icon: {
    alignItems: 'center',
    margin: 'auto',
    color: '#ffffff',
  },
  words: {
    marginLeft: '90px',
    marginTop: '-30px',
  },
  wordsSub: {
    marginLeft: '90px',
    marginTop: '10px',
  },
}));

function getRenderedStatus(status: ElementStatusEnum): ReactElement {
    switch (status) {
        case ElementStatusEnum.INFORMATION: return <ErrorOutline style={{ fontSize: 80, color: '#ffffff' }} />;
        case ElementStatusEnum.ALERT: return <CancelOutlined style={{ fontSize: 80, color: '#ffffff' }} />;
        case ElementStatusEnum.SUCCESS: return <CheckCircleOutline style={{ fontSize: 80, color: '#ffffff' }} />;
    }

    return <NotInterested />;
}

function getClassStatus(status: ElementStatusEnum, theme:any): string {
    switch (status) {
        case ElementStatusEnum.INFORMATION: return theme.contentInfo;
        case ElementStatusEnum.ALERT: return theme.contentAlert;
        case ElementStatusEnum.SUCCESS: return theme.contentSucc;
    }

    return theme.contentSucc;
}

type props = {
    status: ElementStatusEnum;
    head: LanguageKey;
    content: LanguageKey;
    data: any;
  }

const StatusCard: React.FC<props> = ({
    status, head, content, data}) => {

  const styles = useStyles();
  const languageContext = useLanguageContext();

  return (
        <Card className={styles.root}>
            <CardContent>
                <div className={getClassStatus(status, styles)}>
                    <div className={styles.icon}>
                        {getRenderedStatus(status)}
                    </div>
                </div>
                <Typography className={styles.words} gutterBottom variant="h6">
                    <b>{languageContext.getMessage(head)}</b>
                </Typography>
                <Divider variant="inset" />
                <Typography className={styles.wordsSub} gutterBottom >
                    {languageContext.getMessage(content)}: {data}
                </Typography>
            </CardContent>
        </Card>
  );
};

export default withTheme(StatusCard);