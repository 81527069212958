import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
    selectedChangeOrderSubContractorGroup: string | undefined;
    onSelectedChangeOrderSubContractorGroupChange: (newSelectedChangeOrderSubContractorGroup: string | undefined) => void;
    freeSolo?: boolean;
}

const SelectChangeOrderSubContractorGroup: React.FC<props> = ({
    selectedChangeOrderSubContractorGroup,
    onSelectedChangeOrderSubContractorGroupChange, 
    freeSolo }) => {

    const [inputValue, setInputValue] = React.useState('');
    const languageContext = useLanguageContext();
    const changeOrderSubContractorContext = useChangeOrderSubContractorContext();

    const changeOrderSubContractorGroups: (string | undefined)[] = [];
    changeOrderSubContractorContext.getChangeOrderSubContractors().forEach(changeOrderSubContractor => {
        if (changeOrderSubContractorGroups.indexOf(changeOrderSubContractor.group) < 0) {
            changeOrderSubContractorGroups.push(changeOrderSubContractor.group);
        }
    });
    const defaultChangeOrderSubContractorGroup = selectedChangeOrderSubContractorGroup ?? null;

    const handleChange = (event: any, newSelectedChangeOrderSubContractorGroup: string | undefined | null) => {
        onSelectedChangeOrderSubContractorGroupChange(newSelectedChangeOrderSubContractorGroup ?? undefined);
    }

    return <Autocomplete
        options={freeSolo ? changeOrderSubContractorGroups.filter(group => (group ?? "").toLowerCase().includes(inputValue.toLowerCase())) : changeOrderSubContractorGroups}
        freeSolo={freeSolo}
        getOptionLabel={(changeOrderSubContractorGroup) => changeOrderSubContractorGroup ?? ''}
        value={defaultChangeOrderSubContractorGroup}
        onChange={handleChange}
        inputValue={freeSolo ? inputValue : undefined}
        onInputChange={freeSolo ? (event, newInputValue) => {
          setInputValue(newInputValue);
          handleChange(event, newInputValue);
        } : undefined}
        renderInput={(params) => <TextField {...params}
            label={languageContext.getMessage('group')}
            variant="standard" />}
    />
}

export default SelectChangeOrderSubContractorGroup;