import {  Grid, Paper, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Account, ChangeOrderCounterClaim, ChangeOrderStatus, Contract } from "../../contracts/contracts";
import ChangeOrderCounterClaimList from "../../component/changeOrderCounterClaimComponents/ChangeOrderCounterClaimList";
import ChangeOrderCounterClaimMobileList from "../../component/changeOrderCounterClaimComponents/ChangeOrderCounterClaimMobileList";
import SelectChangeOrderCounterClaimFilter from "../../component/changeOrderCounterClaimComponents/SelectChangeOrderCounterClaimFilter";
import SelectStatus from "../../component/changeOrderCounterClaimComponents/SelectStatus";

type props = {
    selectedAccount: Account | undefined;
    selectedContract: Contract | undefined;
    selectedStatus: ChangeOrderStatus | undefined;
    onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
    onSelectedContractChange: (newSelectedContract: Contract | undefined) => void;
    onSelectedStatusChange: (newSelectedStatus: ChangeOrderStatus | undefined) => void;
    onSelectedChangeOrderCounterClaimChange?: (newSelectedChangeOrderCounterClaim: ChangeOrderCounterClaim | undefined) => void;
}

const ChangeOrderCounterClaimDetailsView: React.FC<props> = ({
    selectedAccount,
    selectedContract,
    selectedStatus,
    onSelectedAccountChange,
    onSelectedContractChange,
    onSelectedStatusChange,
    onSelectedChangeOrderCounterClaimChange}) => {

    return (<>
            {(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 400 ?
            <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SelectChangeOrderCounterClaimFilter 
                        selectedAccount={selectedAccount}
                        selectedContract={selectedContract}
                        selectedStatus={selectedStatus}
                        onSelectedAccountChange={onSelectedAccountChange}
                        onSelectedContractChange={onSelectedContractChange}
                        onSelectedStatusChange={onSelectedStatusChange}/>
                </Grid>
                <Grid item xs={12}>
                    <ChangeOrderCounterClaimList 
                        selectedContract={selectedContract}
                        selectedStatus={selectedStatus}
                        onSelectedChangeOrderCounterClaimChange={onSelectedChangeOrderCounterClaimChange} />
                </Grid>
            </Grid>
            </>
            :
            <>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={3}>
                    <Paper elevation={2}>
                        <SelectStatus
                            selectedStatus={selectedStatus}
                            onSelectedStatusChange={onSelectedStatusChange} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <ChangeOrderCounterClaimMobileList 
                            selectedContract={selectedContract}
                            selectedStatus={selectedStatus}
                            onSelectedChangeOrderCounterClaimChange={onSelectedChangeOrderCounterClaimChange} />
                    </Paper>
                </Grid>
            </Grid>
            </>
            }
        </>
    );
}

export default withTheme(ChangeOrderCounterClaimDetailsView);