import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
    selectedChangeOrderExpectedGroup: string | undefined;
    onSelectedChangeOrderExpectedGroupChange: (newSelectedChangeOrderExpectedGroup: string | undefined) => void;
    freeSolo?: boolean;
}

const SelectChangeOrderExpectedGroup: React.FC<props> = ({
    selectedChangeOrderExpectedGroup,
    onSelectedChangeOrderExpectedGroupChange, 
    freeSolo }) => {

    const [inputValue, setInputValue] = React.useState('');
    const languageContext = useLanguageContext();
    const changeOrderExpectedContext = useChangeOrderExpectedContext();

    const changeOrderExpectedGroups: (string | undefined)[] = [];
    changeOrderExpectedContext.getChangeOrderExpecteds().forEach(changeOrderExpected => {
        if (changeOrderExpectedGroups.indexOf(changeOrderExpected.group) < 0) {
            changeOrderExpectedGroups.push(changeOrderExpected.group);
        }
    });
    const defaultChangeOrderExpectedGroup = selectedChangeOrderExpectedGroup ?? null;

    const handleChange = (event: any, newSelectedChangeOrderExpectedGroup: string | undefined | null) => {
        onSelectedChangeOrderExpectedGroupChange(newSelectedChangeOrderExpectedGroup ?? undefined);
    }

    return <Autocomplete
        options={freeSolo ? changeOrderExpectedGroups.filter(group => (group ?? "").toLowerCase().includes(inputValue.toLowerCase())) : changeOrderExpectedGroups}
        freeSolo={freeSolo}
        getOptionLabel={(changeOrderExpectedGroup) => changeOrderExpectedGroup ?? ''}
        value={defaultChangeOrderExpectedGroup}
        onChange={handleChange}
        inputValue={freeSolo ? inputValue : undefined}
        onInputChange={freeSolo ? (event, newInputValue) => {
          setInputValue(newInputValue);
          handleChange(event, newInputValue);
        } : undefined}
        renderInput={(params) => <TextField {...params}
            label={languageContext.getMessage('group')}
            variant="standard" />}
    />
}

export default SelectChangeOrderExpectedGroup;