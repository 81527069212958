import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, Project, RoleType, DocumentType, StorageFile } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { useAuthContext } from "../auth/authContext";
import { ProjectSubscriptionsContextProvider, useProjectSubscriptionsContext } from "./subscriptions/projectSubscriptionsContext";
import { ProjectQueriesContextContext, useProjectQueriesContext } from "./queries/projectQueriesContext";
import { ProjectMutationsContextProvider, useProjectMutationsContext } from "./mutations/projectMutationsContext";
import { useUrlContext } from "../url/urlContext";
import { Dictionary } from "../../global-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { receiveForecast, receiveForecastReport } from "../forecast/forecastsTools";
import { checkProjectAccess } from "../userRole/userRoleTools";
import { receiveInterimExpense } from "../interim/InterimTools";
import { useLanguageContext } from "../language/LanguageContext";
import { sortByName } from "../../utils/devTools";
import { useLazyQuery } from '@apollo/react-hooks';
import { getStorageFileKey } from "../../utils/fileTools";
import { getStorageFilesGraphqlQueryOptions, QUERY_STORAGE_FILES } from "../storageFile/queries/storageFileQueries";

export interface ProjectContext {
    getProjectSearch: () => Project,
    searchProjects: (projectSearch: Project) => void,
    getProjects: () => Array<Project>,
    getProject: (id: Guid | undefined) => Project | undefined,
    addProject: (project: Project) => void,
    updateProject: (projectToUpdate: Project) => void,
    getSelectedProject: () => Project | undefined,
    setSelectedProject: (id: Guid) => void,
    hasProjectAccess: (roleType: RoleType, id?: Guid) => boolean,
    existingProjectFiles: StorageFile[],
    addToExistingProjectFiles: (storagefile: StorageFile) => void;
    checkIfProjectFileExists: (documentId: Guid | undefined) => boolean;
    loadingProjects: boolean,
}

const ProjectContext = createContext<ProjectContext>(null as unknown as ProjectContext);

export const ProjectContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ProjectMutationsContextProvider>
            <ProjectQueriesContextContext>
                <ProjectSubscriptionsContextProvider>
                    <ProjectSubContextProvider>
                        {children}
                    </ProjectSubContextProvider>
                </ProjectSubscriptionsContextProvider>
            </ProjectQueriesContextContext>
        </ProjectMutationsContextProvider>
    );
}

export const ProjectSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const projectMutationsContext = useProjectMutationsContext();
    const projectQueriesContext = useProjectQueriesContext();
    const projectSubscriptionsContext = useProjectSubscriptionsContext();
    const [currentProjectSearch, setCurrentProjectSearch] = useState<Project | undefined>(undefined);
    const [projects, setProjects] = useState<Array<Project>>([]);

    const urlState = urlContext.getUrlState();
    const [selectedProjectId, setSelectedProjectId] = useState<Guid|undefined>(urlState.projectId ?? undefined);
    const localStorageSelectedProjectId = 'selected_project_id'

    const replaceUrlState = (newProjectId: Guid, cleanUrlState?: boolean): void => {
        let newUrlState = {...urlState, ...{'projectId': newProjectId}}
        if (cleanUrlState)
        {
            newUrlState = {'projectId': newProjectId}
        }
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
        urlContext.replaceUrlQuery(urlQuery);
    }

    const mergeProjects = (oldProjects: Array<Project>, newProjects: Array<Project>): Array<Project> => {
        const updatedProjects = oldProjects.slice();
        if (!newProjects) {
            console.error(`Received undefined set of Projects: ${newProjects}`);
            return [];
        }
        newProjects.forEach(newProject => {
            newProject.created = new Date(newProject.created ?? 0);
            newProject.changed = new Date(newProject.changed ?? 0);
            newProject.projectStart = new Date(newProject.projectStart ?? 0);
            newProject.projectEnd = new Date(newProject.projectEnd ?? 0);
            receiveForecast(newProject.forecast, newProject.id, undefined, languageContext);
            newProject.activeForecastGroups = newProject.activeForecastGroups ?? [];
            newProject.forecastGroups = newProject.forecastGroups?.filter(forecast => newProject.activeForecastGroups?.includes(forecast.accountGroup ?? ''))
            newProject.forecastGroups = newProject.forecastGroups?.sort((a, b) => sortByName(a.accountGroup, b.accountGroup));
            newProject.forecastGroups?.forEach(forecast => receiveForecast(forecast, newProject.id, undefined, languageContext));
            receiveForecastReport(newProject.forecastReport, newProject.id, undefined, languageContext);
            newProject.forecastGroupsReport = newProject.forecastGroupsReport?.filter(forecast => newProject.activeForecastGroups?.includes(forecast.group ?? ''))
            newProject.forecastGroupsReport = newProject.forecastGroupsReport?.sort((a, b) => sortByName(a.group, b.group));
            newProject.forecastGroupsReport?.forEach(forecastReport => receiveForecastReport(forecastReport, newProject.id, undefined, languageContext));
            receiveInterimExpense({parentDocumentId: newProject.id, parentDocumentType: DocumentType.PROJECT}, newProject.interimExpense);
            const index = updatedProjects.findIndex(project => project.id === newProject.id);
            if (index >= 0) {
                if (newProject.state === ActiveState.ACTIVE) {
                    updatedProjects[index] = newProject;
                }
                else {
                    updatedProjects.splice(index, 1);
                }
            } else {
                if (newProject.state === ActiveState.ACTIVE) {
                    updatedProjects.push(newProject);
                }
            }
        });
        const sortedProjects = updatedProjects.sort((a, b) => sortByName(a.name, b.name));
        if (!selectedProjectId && sortedProjects.length > 0) {
            let storedSelectedProjectId = localStorage.getItem(localStorageSelectedProjectId) ?? undefined;
            if (storedSelectedProjectId && sortedProjects.findIndex(sortedProject => sortedProject.id === storedSelectedProjectId) >= 0) {
                setSelectedProjectId(storedSelectedProjectId);
            }
            else {
                setSelectedProjectId(sortedProjects[0].id);
            }
        }
        return sortedProjects;
    }

    const getProjectSearch = (): Project => {
        const urlState = urlContext.getUrlState();
    
        return {
        }
    }
    
    const searchProjects = (projectSearch: Project): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(projectSearch, currentProjectSearch);
        if (!matched) {
            setCurrentProjectSearch(projectSearch);
            setProjects([]);
        }
    }

    const getProjects = (): Array<Project> => {
        return projects;
    }

    const getProject = (id: Guid | undefined): Project | undefined => {
        if (id) {
            return projects.find(project => project.id === id);
        }
        return undefined;
    }

    const mutateProject = (projectToUpdate: Project) => {
        projectMutationsContext.mutateProject(projectToUpdate, (documentId, variables) => {
            projectToUpdate = {...projectToUpdate, ...variables};
            projectToUpdate.id = documentId;
            setProjects(mergeProjects(projects, [projectToUpdate]));
        });
    }

    const addProject = (project: Project): void => {
        mutateProject(project);
    }

    const getSelectedProject = (): Project | undefined => {
        return projects.find(project => project.id === selectedProjectId);
    }

    const setSelectedProject = (id: Guid): void => {
        setSelectedProjectId(id);
        localStorage.setItem(localStorageSelectedProjectId, id);
        replaceUrlState(id, true);
    }

    const updateProject = (projectToUpdate: Project) => {
        mutateProject(projectToUpdate);
    }

    const hasProjectAccess = (roleType: RoleType, id?: Guid): boolean => {
        id = id ?? getSelectedProject()?.id;
        return checkProjectAccess(roleType, authContext.accountRoles(), id);
    }

    useEffect(() => {
        const selectedProjectId = getSelectedProject()?.id;
        if (selectedProjectId && !('projectId' in urlState))
        {
            replaceUrlState(selectedProjectId)
        }
    }, [urlContext.currentLocation, getSelectedProject()])

    useEffect(() => {
        if (currentProjectSearch) {
            projectQueriesContext.queryProjects(currentProjectSearch);
        }
    }, [currentProjectSearch]);
    
    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setProjects([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setProjects(mergeProjects([], projectQueriesContext.fetchedProjects));
    }, [projectQueriesContext.fetchedProjects]);

    useEffect(() => {
        setProjects(mergeProjects(projects, projectSubscriptionsContext.subscribedProjects));
    }, [projectSubscriptionsContext.subscribedProjects]);
    
    useEffect(() => {
        if (authContext.tokenIsReady || authContext.insecure) {
            searchProjects({});
        }
    }, [authContext.tokenIsReady, authContext.insecure]);

    const maxProjectFilesToFetch = 100;
    const [currentProjectFilesSearch, setCurrentProjectFilesSearch] = useState<StorageFile>({});
    const [existingProjectFiles, setExistingProjectFiles] = useState<StorageFile[]>([]);
    const [loadProjectFilesSearchQuery, queryProjectFilesSearchResponse] = useLazyQuery(QUERY_STORAGE_FILES);
    const projectStorageFileKey = getStorageFileKey();

    const addToExistingProjectFiles = (storageFile: StorageFile): void => {
        const storageFileCopy = {...storageFile, content: undefined};
        existingProjectFiles.push(storageFileCopy);
        setExistingProjectFiles(existingProjectFiles.slice());
    }

    const checkIfProjectFileExists = (documentId: Guid | undefined): boolean => {
        if (!documentId) {
            return false;
        }
        return existingProjectFiles.findIndex(projectFile => projectFile.key?.includes(documentId) ?? false) >= 0;
    }

    useEffect(() => {
        if (!selectedProjectId) {
            return;
        }
        setExistingProjectFiles([]);
        const projectFilesSearch: StorageFile = {
            searchIndexStart: 0, 
            searchIndexStop: maxProjectFilesToFetch,
            searchKey: projectStorageFileKey,
        }
        setCurrentProjectFilesSearch(projectFilesSearch);
    }, [selectedProjectId])

    useEffect(() => {
        if (!currentProjectFilesSearch.searchKey) {
            return;
        }
        loadProjectFilesSearchQuery(getStorageFilesGraphqlQueryOptions(currentProjectFilesSearch))
    }, [currentProjectFilesSearch])

    useEffect(() => {
        const storageFiles: StorageFile[] = queryProjectFilesSearchResponse.data?.storageFiles ?? [];
        setExistingProjectFiles(existingProjectFiles.concat(storageFiles));
        if (storageFiles.length > 0 && currentProjectFilesSearch.searchKey) {
            currentProjectFilesSearch.searchIndexStart = (currentProjectFilesSearch.searchIndexStart ?? 0) + storageFiles.length;
            currentProjectFilesSearch.searchIndexStop = currentProjectFilesSearch.searchIndexStart + maxProjectFilesToFetch;
            setCurrentProjectFilesSearch({...currentProjectFilesSearch});
        }
    }, [queryProjectFilesSearchResponse.data])

    const projectContext = {
        getProjectSearch,
        searchProjects,
        getProjects,
        getProject,
        addProject,
        updateProject,
        getSelectedProject,
        setSelectedProject,
        hasProjectAccess,
        existingProjectFiles,
        addToExistingProjectFiles,
        checkIfProjectFileExists,
        loadingProjects: projectQueriesContext.queryResponse.loading,
    };

    return (
        <ProjectContext.Provider value={projectContext}>
            {children}
        </ProjectContext.Provider>
    );
}

export const useProjectContext = (): ProjectContext => {
    return useContext(ProjectContext);
}
