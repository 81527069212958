import { ChangeOrderLandlordContext } from "../contexts/changeOrderLandlord/changeOrderLandlordContext";
import { LanguageContext } from "../contexts/language/LanguageContext";
import { ProjectContext } from "../contexts/project/projectContext";
import { TemplateEngineQueriesContext } from "../contexts/templateEngine/queries/templateEngineQueriesContext";
import { UserRoleContext } from "../contexts/userRole/userRoleContext";
import { ChangeOrderLandlord, ChangeOrderStatus, ElementStatusEnum, RelationType } from "../contracts/contracts";
import { Guid } from "./common-types";
import { getEmptyGuid, getRandomGuid } from "./guidTools";

const CHANGE_ORDER_LANDLORD_REPORT_TEMPLATE_FILENAME = "aimz/template_change_order_landlord_report.xlsx";
const CHANGE_ORDER_LANDLORD_REPORT_TEMPLATE_SHEET_NAME = "report";

function getDateFormatString(date?: Date) {
  if (date) {
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date.toLocaleDateString(undefined, { month: "2-digit", day: "2-digit", year: "numeric" });
    }
  } else return "";
}

export function downloadChangeOrderLandlordReport(
  templateEngineQueriesContext: TemplateEngineQueriesContext,
  changeOrderLandlordContext: ChangeOrderLandlordContext,
  projectContext: ProjectContext,
  userRoleContext: UserRoleContext,
  changeOrderLandlordId?: string
) {
  var changeOrderLandlord = changeOrderLandlordContext.getChangeOrderLandlord(changeOrderLandlordId);
  var surcharge = projectContext.getProject(changeOrderLandlord?.projectId)?.surchargePercent ?? 0;
  var users = userRoleContext.getUsers();
  var createdBy = users.find((user) => changeOrderLandlord?.changedBy === user.id)?.name
  if (changeOrderLandlord) {
    var reportVariables: object[] = [
      {
        tpl_name: CHANGE_ORDER_LANDLORD_REPORT_TEMPLATE_SHEET_NAME,
        sheet_name: CHANGE_ORDER_LANDLORD_REPORT_TEMPLATE_SHEET_NAME,
        ctx: getChangeOrderLandlordReportVariables(changeOrderLandlord, createdBy ?? "", surcharge),
      },
    ];
    var fileName = `EM - ${changeOrderLandlord?.name}`;
    templateEngineQueriesContext.queryTemplateEngine(CHANGE_ORDER_LANDLORD_REPORT_TEMPLATE_FILENAME, reportVariables, fileName);
  }
}

function getChangeOrderLandlordReportVariables(changeOrderLandlord: ChangeOrderLandlord, createdBy: string, surCharge: number) {
  return {
    changeOrder: {
      name: changeOrderLandlord?.name ?? "",
      createdBy: createdBy,
      date: changeOrderLandlord?.sent ? getDateFormatString(changeOrderLandlord?.sent) : "",
      description: changeOrderLandlord?.changeOrderDescription ?? "",
      comment: changeOrderLandlord?.changeOrderComment ?? "",
      cost: changeOrderLandlord?.cost ?? 0,
      surcharge: surCharge / 100,
    },
  };
}

export const getEmptyChangeOrderLandlord = (projectId: Guid): ChangeOrderLandlord => {
  return {
    id: getRandomGuid(),
    projectId: projectId,
    relationType: RelationType.PARENT,
    name: "",
    sent: new Date(),
    answered: new Date(),
    costRequirements: 0,
    cost: 0,
    accountId: getEmptyGuid(),
    status: ChangeOrderStatus.NOT_PROCESSED,
  };
};

export const getEmptySubChangeOrderLandlord = (parentId: Guid, accountId: Guid): ChangeOrderLandlord => {
  return {
    parentId: parentId,
    relationType: RelationType.CHILD,
    id: getRandomGuid(),
    accountId: accountId,
    costRequirements: 0,
    cost: 0,
    billed: 0,
    sent: new Date(),
    answered: new Date(),
  };
};

export const mergeSubChangeOrderLandlordWithParent = (parent: ChangeOrderLandlord, child: ChangeOrderLandlord): ChangeOrderLandlord => {
  const filteredProperties: Partial<ChangeOrderLandlord> = {
    id: child.id,
    state: child.state,
    parentId: parent.id,
    relationType: child.relationType,
    accountId: child.accountId,
    costRequirements: child.costRequirements,
    cost: child.cost,
    billed: child.billed,
  };
  return { ...parent, ...filteredProperties };
};
