import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, AccrualAndOperationsExpenses, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface AccrualAndOperationsExpensesSubscriptionsContext {
    subscribedAccrualAndOperationsExpenses: Array<AccrualAndOperationsExpenses>,
}

const AccrualAndOperationsExpensesSubscriptionsContext = createContext<AccrualAndOperationsExpensesSubscriptionsContext>(null as unknown as AccrualAndOperationsExpensesSubscriptionsContext);

export const AccrualAndOperationsExpensesSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedAccrualAndOperationsExpenses, setSubscribedAccrualAndOperationsExpenses] = useState<Array<AccrualAndOperationsExpenses>>([]);

    const updateSubscribedAccrualAndOperationsExpenses = (stateDocuments: StateDocument[]) => {
        const subscribedAccrualAndOperationsExpenses = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.ACCRUAL_AND_OPERATIONS_EXPENSES && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as AccrualAndOperationsExpenses);
        setSubscribedAccrualAndOperationsExpenses(subscribedAccrualAndOperationsExpenses);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedAccrualAndOperationsExpenses([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedAccrualAndOperationsExpenses(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const accrualAndOperationsExpensesContext: AccrualAndOperationsExpensesSubscriptionsContext = {
        subscribedAccrualAndOperationsExpenses,
    };

    return (
        <AccrualAndOperationsExpensesSubscriptionsContext.Provider value={accrualAndOperationsExpensesContext}>
            {children}
        </AccrualAndOperationsExpensesSubscriptionsContext.Provider>
    );
}

export const useAccrualAndOperationsExpensesSubscriptionsContext = (): AccrualAndOperationsExpensesSubscriptionsContext => {
    return useContext(AccrualAndOperationsExpensesSubscriptionsContext);
}
