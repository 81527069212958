import { withTheme, makeStyles, Theme, Grid } from "@material-ui/core";
import React from "react";
import { ChangeOrderExpected } from "../../contracts/contracts";
import SelectChangeOrderExpected from "../../component/changeOrderExpectedComponents/SelectChangeOrderExpected";
import ChangeOrderExpectedDetailCard from "../../component/changeOrderExpectedComponents/ChangeOrderExpectedDetailCard";
import ViewHeading from "../../component/viewComponents/ViewHeading";
import { useLanguageContext } from "../../contexts/language/LanguageContext";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    button: {
        margin: theme.spacing(1),
    },
    demoGraph: {
        maxHeight: '399px',
        padding: '1em',
        marginTop: '30px',
    },
    wrapper: {
        height: '310px',
        margin: 'auto', 
    }
}));

type props = {
    selectedChangeOrderExpected: ChangeOrderExpected | undefined;
    onSelectedChangeOrderExpectedChange: (newSelectedChangeOrderExpected: ChangeOrderExpected | undefined) => void;
}

const ChangeOrderExpectedViewAll: React.FC<props> = ({
    selectedChangeOrderExpected,
    onSelectedChangeOrderExpectedChange}) => {

    const classes = useStyles();
    const languageContext = useLanguageContext();

    return (<>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ViewHeading title={`${languageContext.getMessage('expectedChange')}`}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                            <SelectChangeOrderExpected
                                selectedChangeOrderExpected={selectedChangeOrderExpected}
                                onSelectedChangeOrderExpectedChange={onSelectedChangeOrderExpectedChange} />
                        </Grid>
                    </Grid>
                </ViewHeading>
            </Grid>
        </Grid>
        {selectedChangeOrderExpected &&
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ChangeOrderExpectedDetailCard
                        selectedChangeOrderExpected={selectedChangeOrderExpected} />
                </Grid>
            </Grid>
        }
    </>
    );
}

export default withTheme(ChangeOrderExpectedViewAll);