import React, { createContext, useContext, useEffect, useState } from "react";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { ChangeOrderCounterClaim, ActiveState, ElementStatusEnum, ChangeOrderStatus, RelationType } from "../../contracts/contracts";
import { useProjectContext } from "../project/projectContext";
import { useLanguageContext } from "../language/LanguageContext";
import { ChangeOrderCounterClaimMutationsContextProvider, useChangeOrderCounterClaimMutationsContext } from "./mutations/changeOrderCounterClaimMutationsContext";
import { ChangeOrderCounterClaimQueriesContextContext, useChangeOrderCounterClaimQueriesContext } from "./queries/changeOrderCounterClaimQueriesContext";
import { ChangeOrderCounterClaimSubscriptionsContextProvider, useChangeOrderCounterClaimSubscriptionsContext } from "./subscriptions/changeOrderCounterClaimSubscriptionsContext";
import { useUrlContext } from "../url/urlContext";
import { useAuthContext } from "../auth/authContext";
import { Guid } from "../../utils/common-types";
import { filterAbles } from "react-moveable/declaration/utils";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { queryTemplateEngineToProduceChangeOrderCounterClaimExcelList } from "./ChangeOrderCounterClaimExportTools";
import { useAccountContext } from "../account/accountContext";
import { useContractContext } from "../contract/contractContext";

export interface ChangeOrderCounterClaimContext {
    getChangeOrderCounterClaimSearch: () => ChangeOrderCounterClaim,
    searchChangeOrderCounterClaims: (changeOrderCounterClaimSearch: ChangeOrderCounterClaim) => void,
    getChangeOrderCounterClaimsByAccountId: (accountId?: Guid) => Array<ChangeOrderCounterClaim>, //Remove this if needed. Only used to get data for changeOrderCounterClaimMiniList
    getChangeOrderCounterClaims: (contractId?: string, status?: ChangeOrderStatus, accountId?: string) => Array<ChangeOrderCounterClaim>,
    getChangeOrderCounterClaim: (id?: string) => ChangeOrderCounterClaim | undefined,
    updateChangeOrderCounterClaim:  (changeOrderCounterClaim: ChangeOrderCounterClaim) => void,
    convertChangeOrderStatusToString: (status?: ChangeOrderStatus) => string,
    convertChangeOrderStatusToElementStatus: (status?: ChangeOrderStatus) => ElementStatusEnum,
    getContractors: () => Array<string>,
    downloadChangeOrderCounterClaims: (changeOrderCounterClaims: ChangeOrderCounterClaim[]) => void,
}

const ChangeOrderCounterClaimContext = createContext<ChangeOrderCounterClaimContext>(null as unknown as ChangeOrderCounterClaimContext);

export const sortChangeOrderCounterClaimByName = (a: ChangeOrderCounterClaim, b: ChangeOrderCounterClaim) => {
    if ((a?.name ?? '') < (b?.name ?? '')) { return -1; }
    if ((a?.name ?? '') > (b?.name ?? '')) { return 1; }
    return 0;
}

export const sortChangeOrderCounterClaimByGroup = (a: ChangeOrderCounterClaim, b: ChangeOrderCounterClaim) => {
    if ((a?.group ?? '') < (b?.group ?? '')) { return -1; }
    if ((a?.group ?? '') > (b?.group ?? '')) { return 1; }
    return 0;
}

export const sortChangeOrderCounterClaimByDate = (a: ChangeOrderCounterClaim, b: ChangeOrderCounterClaim) => {
    if ((a?.sent ?? '') < (b?.sent ?? '')) { return -1; }
    if ((a?.sent ?? '') > (b?.sent ?? '')) { return 1; }
    return 0;
}

export const ChangeOrderCounterClaimContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ChangeOrderCounterClaimMutationsContextProvider>
            <ChangeOrderCounterClaimQueriesContextContext>
                <ChangeOrderCounterClaimSubscriptionsContextProvider>
                    <ChangeOrderCounterClaimSubContextProvider>
                        {children}
                    </ChangeOrderCounterClaimSubContextProvider>
                </ChangeOrderCounterClaimSubscriptionsContextProvider>
            </ChangeOrderCounterClaimQueriesContextContext>
        </ChangeOrderCounterClaimMutationsContextProvider>
    );
}

export const ChangeOrderCounterClaimSubContextProvider : React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const accountContext = useAccountContext();
    const contractContext = useContractContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const changeOrderCounterClaimMutationsContext = useChangeOrderCounterClaimMutationsContext();
    const changeOrderCounterClaimQueriesContext = useChangeOrderCounterClaimQueriesContext();
    const changeOrderCounterClaimSubscriptionsContext = useChangeOrderCounterClaimSubscriptionsContext();
    const [currentChangeOrderCounterClaimSearch, setCurrentChangeOrderCounterClaimSearch] = useState<ChangeOrderCounterClaim | undefined>(undefined);
    const [changeOrderCounterClaims, setChangeOrderCounterClaims] = useState<Array<ChangeOrderCounterClaim>>([]);

    const mergeChangeOrderCounterClaims = (oldChangeOrderCounterClaims: Array<ChangeOrderCounterClaim>, newChangeOrderCounterClaims: Array<ChangeOrderCounterClaim>): Array<ChangeOrderCounterClaim> => {
        let updatedChangeOrderCounterClaims = oldChangeOrderCounterClaims.slice();
        if (!newChangeOrderCounterClaims) {
            console.error(`Received undefined set of changeOrderCounterClaims: ${newChangeOrderCounterClaims}`);
            return [];
        }
        newChangeOrderCounterClaims.forEach(newChangeOrderCounterClaim => {
            if (newChangeOrderCounterClaim.projectId !== currentChangeOrderCounterClaimSearch?.projectId) {
                return;
            }
            newChangeOrderCounterClaim.created = new Date(newChangeOrderCounterClaim.created ?? 0);
            newChangeOrderCounterClaim.sent = new Date(newChangeOrderCounterClaim.sent ?? 0);
            newChangeOrderCounterClaim.answered = new Date(newChangeOrderCounterClaim.answered ?? 0);
            newChangeOrderCounterClaim.elementStatus = convertChangeOrderStatusToElementStatus(newChangeOrderCounterClaim.status);
            const index = updatedChangeOrderCounterClaims.findIndex(changeOrderCounterClaim => changeOrderCounterClaim.id === newChangeOrderCounterClaim.id);
            if (index >= 0) {
                if (newChangeOrderCounterClaim.state === ActiveState.ACTIVE) {
                    updatedChangeOrderCounterClaims[index] = newChangeOrderCounterClaim;
                }
                else {
                    updatedChangeOrderCounterClaims.splice(index, 1);
                }
            } 
            else {
                if (newChangeOrderCounterClaim.state === ActiveState.ACTIVE) {
                    updatedChangeOrderCounterClaims.push(newChangeOrderCounterClaim); 
                }
            }
        });
        return updatedChangeOrderCounterClaims.sort(sortChangeOrderCounterClaimByName).sort(sortChangeOrderCounterClaimByGroup);
    }

    const getChangeOrderCounterClaimSearch = (): ChangeOrderCounterClaim => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
        const accountId = urlState.accountId ? urlState.accountId as string : undefined;
        const contractId = urlState.contractId ? urlState.contractId as string : undefined;

        return {
            projectId: projectId,
            accountId: accountId,
            contractId: contractId,
        }
    }

    const searchChangeOrderCounterClaims = (changeOrderCounterClaimSearch: ChangeOrderCounterClaim): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(changeOrderCounterClaimSearch, currentChangeOrderCounterClaimSearch);
        matched = matched && changeOrderCounterClaimSearch?.projectId === currentChangeOrderCounterClaimSearch?.projectId;
        matched = matched && changeOrderCounterClaimSearch?.accountId === currentChangeOrderCounterClaimSearch?.accountId;
        matched = matched && changeOrderCounterClaimSearch?.contractId === currentChangeOrderCounterClaimSearch?.contractId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && !matched) {
            setCurrentChangeOrderCounterClaimSearch(changeOrderCounterClaimSearch);
            setChangeOrderCounterClaims([]);
        }
    }

    const getChangeOrderCounterClaims = (contractId?: string, status?: ChangeOrderStatus, accountId?: string): Array<ChangeOrderCounterClaim> => {
        let resolvedChangeOrderCounterClaims = changeOrderCounterClaims;

        if (contractId) {
            resolvedChangeOrderCounterClaims = resolvedChangeOrderCounterClaims.filter(changeOrderCounterClaim => changeOrderCounterClaim.contractId === contractId);
        }

        if (status) {
            resolvedChangeOrderCounterClaims = resolvedChangeOrderCounterClaims.filter(changeOrderCounterClaim => changeOrderCounterClaim.status === status);
        }

        if (accountId) {
            resolvedChangeOrderCounterClaims = resolvedChangeOrderCounterClaims.filter(changeOrderCounterClaim => changeOrderCounterClaim.accountId === accountId);
        }

        return resolvedChangeOrderCounterClaims
    }

    const getChangeOrderCounterClaimsByAccountId = (accountId?: Guid): ChangeOrderCounterClaim[] => {
        if(!accountId) return []; 
        return changeOrderCounterClaims.filter(changeOrderCounterClaim => changeOrderCounterClaim.accountId === accountId);
    }

    const getChangeOrderCounterClaim = (id?: string) => {
        return changeOrderCounterClaims.find(changeOrderCounterClaim => changeOrderCounterClaim.id === id);
    }

    const updateChangeOrderCounterClaim = (changeOrderCounterClaim: ChangeOrderCounterClaim) => {
        changeOrderCounterClaimMutationsContext.mutateChangeOrderCounterClaim(changeOrderCounterClaim, (documentId, variables) => {
            changeOrderCounterClaim = {...changeOrderCounterClaim, ...variables};
            changeOrderCounterClaim.id = documentId;
            setChangeOrderCounterClaims(mergeChangeOrderCounterClaims(changeOrderCounterClaims, [changeOrderCounterClaim]));
        });
    }

    const convertChangeOrderStatusToString = (status?: ChangeOrderStatus): string => {
        switch (status) {
            case ChangeOrderStatus.APPROVED: return languageContext.getMessage('approved');
            case ChangeOrderStatus.NOT_APPROVED: return languageContext.getMessage('notApproved');
            case ChangeOrderStatus.NOT_PROCESSED: return languageContext.getMessage('notProcessed');
        }
        return languageContext.getMessage('notProcessed')
    }

    const convertChangeOrderStatusToElementStatus = (status?: ChangeOrderStatus): ElementStatusEnum => {
        switch (status) {
            case ChangeOrderStatus.APPROVED: return ElementStatusEnum.SUCCESS;
            case ChangeOrderStatus.NOT_APPROVED: return ElementStatusEnum.CANCEL;
            case ChangeOrderStatus.NOT_PROCESSED: return ElementStatusEnum.INFORMATION;
        }

        return ElementStatusEnum.ALERT;
    }

    const getContractors = (): Array<string> => {
        const contractors: Array<string> = []
        getChangeOrderCounterClaims().forEach(changeOrderCounterClaim => {
            if (changeOrderCounterClaim.contractor && !contractors.includes(changeOrderCounterClaim.contractor)) {
                contractors.push(changeOrderCounterClaim.contractor);
            }
        });
        return contractors;
    }

    const downloadChangeOrderCounterClaims = (changeOrderCounterClaims: ChangeOrderCounterClaim[]): void => {
        queryTemplateEngineToProduceChangeOrderCounterClaimExcelList(
            changeOrderCounterClaims, 
            templateEngineQueriesContext, 
            languageContext, 
            accountContext, 
            contractContext);
    }

    useEffect(() => {
        if (currentChangeOrderCounterClaimSearch) {
            changeOrderCounterClaimQueriesContext.queryChangeOrderCounterClaims(currentChangeOrderCounterClaimSearch);
        }
    }, [currentChangeOrderCounterClaimSearch]);
    
    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setChangeOrderCounterClaims([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setChangeOrderCounterClaims(mergeChangeOrderCounterClaims([], changeOrderCounterClaimQueriesContext.fetchedChangeOrderCounterClaims));
    }, [changeOrderCounterClaimQueriesContext.fetchedChangeOrderCounterClaims]);

    useEffect(() => {
        setChangeOrderCounterClaims(mergeChangeOrderCounterClaims(changeOrderCounterClaims, changeOrderCounterClaimSubscriptionsContext.subscribedChangeOrderCounterClaims));
    }, [changeOrderCounterClaimSubscriptionsContext.subscribedChangeOrderCounterClaims]);

    const changeOrderCounterClaimContext: ChangeOrderCounterClaimContext = {
        getChangeOrderCounterClaimSearch,
        searchChangeOrderCounterClaims,
        getChangeOrderCounterClaims,
        getChangeOrderCounterClaimsByAccountId,
        getChangeOrderCounterClaim,
        updateChangeOrderCounterClaim,
        convertChangeOrderStatusToString,
        convertChangeOrderStatusToElementStatus,
        getContractors,
        downloadChangeOrderCounterClaims,
    };

    return (
        <ChangeOrderCounterClaimContext.Provider value={ changeOrderCounterClaimContext }>
            { children }
        </ChangeOrderCounterClaimContext.Provider>
    );
}

export const useChangeOrderCounterClaimContext = (): ChangeOrderCounterClaimContext => {
    return useContext(ChangeOrderCounterClaimContext);
}
