import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, Invoice, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface InvoiceSubscriptionsContext {
    subscribedInvoices: Array<Invoice>,
}

const InvoiceSubscriptionsContext = createContext<InvoiceSubscriptionsContext>(null as unknown as InvoiceSubscriptionsContext);

export const InvoiceSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedInvoices, setSubscribedInvoices] = useState<Array<Invoice>>([]);

    const updateSubscribedInvoices = (stateDocuments: StateDocument[]) => {
        const subscribedInvoices = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.INVOICE && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Invoice);
        setSubscribedInvoices(subscribedInvoices);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedInvoices([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedInvoices(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: InvoiceSubscriptionsContext = {
        subscribedInvoices,
    };

    return (
        <InvoiceSubscriptionsContext.Provider value={projectContext}>
            {children}
        </InvoiceSubscriptionsContext.Provider>
    );
}

export const useInvoiceSubscriptionsContext = (): InvoiceSubscriptionsContext => {
    return useContext(InvoiceSubscriptionsContext);
}
