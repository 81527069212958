import React from 'react';
import DateFormat from './DateFormat';

type props = {
    from: Date | undefined;
    to: Date | undefined;
    format?: string;
}

const DateFormatFromTo: React.FC<props> = (dateFormatProps) => {
    return (<>
        <DateFormat date={dateFormatProps.from} /> 
        {"\t - \t"}
        <DateFormat date={dateFormatProps.to} />
    </>)
}

export default DateFormatFromTo;