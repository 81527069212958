import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ChangeOrderExpected, ActiveState } from "../../../contracts/contracts";
import { guidIsNullOrEmpty } from "../../../utils/guidTools";

export const MUTATE_CHANGE_ORDER: DocumentNode = gql`
    mutation changeOrderExpected(
            $id: UUID
            $state: ActiveState
            $projectId: UUID
            $accountId: UUID
            $contractId: UUID
            $name: String
            $group: String
            $expected: DateTime
            $changeOrderDescription: String
            $changeOrderComment: String
            $cost: Float
            $status: ChangeOrderStatus
            $progressConsequenceEnabled: Boolean
            $progressConsequenceDescription: String
        ) {
        mutateChangeOrderExpected(
            id: $id
            state: $state
            projectId: $projectId
            accountId: $accountId
            contractId: $contractId
            name: $name
            group: $group
            expected: $expected
            changeOrderDescription: $changeOrderDescription
            changeOrderComment: $changeOrderComment
            cost: $cost
            status: $status
            progressConsequenceEnabled: $progressConsequenceEnabled
            progressConsequenceDescription: $progressConsequenceDescription
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getChangeOrderExpectedsGraphqlMutationOptions = (changeOrderExpected: ChangeOrderExpected): QueryHookOptions => {

    changeOrderExpected.state = changeOrderExpected.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (changeOrderExpected.id !== undefined) options.variables['id'] = changeOrderExpected.id;
    if (changeOrderExpected.state !== undefined) options.variables['state'] = changeOrderExpected.state;
    if (!guidIsNullOrEmpty(changeOrderExpected.projectId)) options.variables['projectId'] = changeOrderExpected.projectId;
    if (!guidIsNullOrEmpty(changeOrderExpected.accountId)) options.variables['accountId'] = changeOrderExpected.accountId;
    if (changeOrderExpected.contractId !== undefined) options.variables['contractId'] = changeOrderExpected.contractId;
    if (changeOrderExpected.name !== undefined) options.variables['name'] = changeOrderExpected.name;
    if (changeOrderExpected.group !== undefined) options.variables['group'] = changeOrderExpected.group;
    if (changeOrderExpected.expected !== undefined) options.variables['expected'] = changeOrderExpected.expected.toISOString();
    if (changeOrderExpected.changeOrderDescription !== undefined) options.variables['changeOrderDescription'] = changeOrderExpected.changeOrderDescription;
    if (changeOrderExpected.changeOrderComment !== undefined) options.variables['changeOrderComment'] = changeOrderExpected.changeOrderComment;
    if (changeOrderExpected.cost !== undefined) options.variables['cost'] = changeOrderExpected.cost;
    if (changeOrderExpected.status !== undefined) options.variables['status'] = changeOrderExpected.status;
    if (changeOrderExpected.progressConsequenceEnabled !== undefined) options.variables['progressConsequenceEnabled'] = changeOrderExpected.progressConsequenceEnabled;
    if (changeOrderExpected.progressConsequenceDescription !== undefined) options.variables['progressConsequenceDescription'] = changeOrderExpected.progressConsequenceDescription;
    return options;
}
