import { Button, Grid, IconButton, makeStyles, Theme, Toolbar, Tooltip, Typography, withTheme } from "@material-ui/core";
import { Add, FileCopy } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable, { Components, MTableToolbar } from "material-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import { useChangeOrderLandlordContext } from "../../contexts/changeOrderLandlord/changeOrderLandlordContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { getStorageFilesGraphqlQueryOptions, QUERY_STORAGE_FILES } from "../../contexts/storageFile/queries/storageFileQueries";
import { useWidthContext } from "../../contexts/WidthContext";
import { Account, DocumentType, ChangeOrderLandlord, ChangeOrderStatus, RoleType, StorageFile } from "../../contracts/contracts";
import { tableIcons } from "../../ui/table-icons";
import { getStorageFileKey, onUploadFile } from "../../utils/fileTools";
import { getPageSizeOptions, getRandomGuid, resolveDefaultPageSize } from "../../utils/randomTools";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import TicketLoading from "../generalComponents/TicketLoading";
import EmptyGroupbar from "../materialTable/EmptyGroupbar";
import ChangeOrderLandlordStatus from "./ChangeOrderLandlordStatus";
import EditChangeOrderLandlordDialog from "./EditChangeOrderLandlordDialog";
import { useStorageFileMutationsContext } from "../../contexts/storageFile/mutations/storageFileMutationsContext";
import EditChangeOrderLandlordGroupDialog from "./EditChangeOrderLandlordGroupDialog";
import StorageFileDialog from "../generalComponents/StorageFileDialog";
import FileUploadDialog from "../generalComponents/FileUploadDialog";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { CustomTableStyle } from "../../utils/common-types";
import { useTemplateEngineQueriesContext } from "../../contexts/templateEngine/queries/templateEngineQueriesContext";
import { downloadChangeOrderLandlordReport } from "../../utils/changeOrderLandlordTools";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";
import ContentTooltip from "../generalComponents/ContentTooltip";
import { useRememberTableScroll } from "../../hooks/useRememberTableScroll";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const noneParentGroup = "None";
export type GroupedChangeOrderLandlord = ChangeOrderLandlord & {
  parentGroup?: string;
  numberInGroup?: number;
};

type props = {
  selectedAccount?: Account | undefined;
  selectedStatus?: ChangeOrderStatus | undefined;
  showTitle?: boolean;
};

const ChangeOrderLandlordList: React.FC<props> = ({ selectedAccount, selectedStatus, showTitle }) => {
  const classes = useStyles();

  const changeOrderLandlordContext = useChangeOrderLandlordContext();
  const languageContext = useLanguageContext();
  const projectContext = useProjectContext();
  const userRoleContext = useUserRoleContext();
  const storageMutationContext = useStorageFileMutationsContext();
  const templateEngineQueriesContext = useTemplateEngineQueriesContext()

  const smallWindow = useWidthContext().isMobileScreen();

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [changeOrderLandlordToEdit, setChangeOrderLandlordToEdit] = useState<ChangeOrderLandlord>();
  const [showEditChangeOrderGroupDialog, setShowEditChangeOrderGroupDialog] = useState<boolean>(false);
  const [selectedChangeOrderGroup, setSelectedChangeOrderGroup] = useState<string | undefined>(undefined);

  // const changeOrderLandlords = changeOrderLandlordContext.getChangeOrderLandlords(selectedStatus, selectedAccount?.id);
  const urlContext = useUrlContext();
  const urlState = urlContext.getUrlState();
  const expandedGroupsSeparator = ";";
  const expandedGroups = urlState.expandedGroups ? (urlState.expandedGroups as string).split(expandedGroupsSeparator) : [];

  const getSecureGroupName = (group: string): string => (group = group.trim().length > 0 ? group : noneParentGroup);

  const groupIsExpanded = (group: string): boolean => {
    group = getSecureGroupName(group);
    return expandedGroups.findIndex((expandedGroup) => expandedGroup === group) >= 0;
  };

  const updateUrlStateWithExpandedGroups = (group: string, expanded: boolean): void => {
    group = getSecureGroupName(group);
    const index = expandedGroups.findIndex((expandedGroup) => expandedGroup === group);
    if (index >= 0 && !expanded) {
      expandedGroups.splice(index, 1);
    } else if (index < 0 && expanded) {
      expandedGroups.push(group);
    }
    const newUrlState = {
      ...urlState,
      ...{ expandedGroups: expandedGroups.length > 0 ? expandedGroups.join(expandedGroupsSeparator) : undefined },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const changeOrderLandlords = useMemo(() => {
    var resolvedChangeOrders: GroupedChangeOrderLandlord[] = [];

    var resolvedGroups: GroupedChangeOrderLandlord[] = [];
    var foundChangeOrder: GroupedChangeOrderLandlord = {};
    var groupId: string = "";

    resolvedChangeOrders = changeOrderLandlordContext.getChangeOrderLandlords(selectedStatus, selectedAccount?.id) as GroupedChangeOrderLandlord[];
    resolvedChangeOrders.forEach((changeOrder) => {
      var foundIndex = resolvedGroups.findIndex((existingGroup) => existingGroup.group === changeOrder.group);
      if (foundIndex < 0) {
        groupId = getRandomGuid();
        foundChangeOrder = {
          parentGroup: noneParentGroup,
          numberInGroup: 0,
          id: groupId,
          group: changeOrder.group,
          name: "Total",
          cost: 0,
          costRequirements: 0,
          costSurcharge: 0,
          costWithSurcharge: 0,
          billed: 0,
        };
        foundIndex = resolvedGroups.push(foundChangeOrder);
      } else {
        foundChangeOrder = resolvedGroups[foundIndex];
      }
      if (foundChangeOrder && foundChangeOrder.id) {
        foundChangeOrder.numberInGroup = (foundChangeOrder.numberInGroup ?? 0) + 1;
        foundChangeOrder.cost = (foundChangeOrder?.cost ?? 0) + (changeOrder.cost ?? 0);
        foundChangeOrder.costRequirements = (foundChangeOrder?.costRequirements ?? 0) + (changeOrder.costRequirements ?? 0);
        foundChangeOrder.costSurcharge = (foundChangeOrder?.costSurcharge ?? 0) + (changeOrder.costSurcharge ?? 0);
        foundChangeOrder.costWithSurcharge = (foundChangeOrder?.costWithSurcharge ?? 0) + (changeOrder.costWithSurcharge ?? 0);
        foundChangeOrder.billed = (foundChangeOrder?.billed ?? 0) + (changeOrder.billed ?? 0);
        groupId = foundChangeOrder.id;
      }
      changeOrder.parentGroup = groupId;
    });
    resolvedChangeOrders = [...resolvedChangeOrders, ...resolvedGroups];

    return resolvedChangeOrders;
  }, [changeOrderLandlordContext]);

  const [currentChangeOrderId, setCurrentChangeOrderId] = useState<string>("");
  const [changeOrderFiles, setChangeOrderFiles] = useState<StorageFile[]>([]);
  const [changeOrderImages, setChangeOrderImages] = useState<string[]>([]);
  const [changeOrderImageFilenames, setChangeOrderImageFilenames] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [openFileList, setOpenFileList] = useState<boolean>(false);

  const ChangeOrderImagesStorageFileKey = getStorageFileKey(DocumentType.CHANGE_ORDER_LANDLORD, currentChangeOrderId ?? "");

  function loadChangeOrderFiles(changeOrderId: string) {
    var storageFileKey = getStorageFileKey(DocumentType.CHANGE_ORDER_LANDLORD, changeOrderId);
    loadChangeOrderImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: storageFileKey }));
  }

  const refreshChangeOrderFiles = () => {
    if (!currentChangeOrderId) {
      return;
    }
    loadChangeOrderImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: ChangeOrderImagesStorageFileKey }));
  };

  const [loadChangeOrderImagesQuery, queryChangeOrderImagesResponse] = useLazyQuery(QUERY_STORAGE_FILES);

  const onDeletedChangeOrderFile = (deletedFile: StorageFile): void => {
    setChangeOrderFiles(changeOrderFiles.filter((file) => file.id !== deletedFile.id));
  };

  useEffect(() => {
    var storageFiles: StorageFile[] = queryChangeOrderImagesResponse.data?.storageFiles ?? [];
    setChangeOrderFiles(storageFiles);
  }, [queryChangeOrderImagesResponse.data]);

  const openEditChangeOrderLandlordDialog = (changeOrderLandlordToEdit?: ChangeOrderLandlord) => {
    setChangeOrderLandlordToEdit(changeOrderLandlordToEdit ?? { accountId: selectedAccount?.id });
    setShowDialog(true);
  };

  const onCloseChangeOrderLandlordDialog = () => {
    setShowDialog(false);
    setShowEditChangeOrderGroupDialog(false);
  };

  const onEditChangeOrderGroup = (changeOrderGroup: string | undefined) => {
    setSelectedChangeOrderGroup(changeOrderGroup);
    setShowEditChangeOrderGroupDialog(true);
  };

  const [pageSize, setPageSize] = useState<number>(1);
  const tableRef = useRef(null) as any;
  useRememberTableScroll(tableRef, "changeOrderLandlordList");
  const pageSizeOptions = getPageSizeOptions(changeOrderLandlords.length);

  useEffect(() => {
    if (pageSizeOptions.length > 1 && pageSize < pageSizeOptions[pageSizeOptions.length - 1]) {
      const newPageSize = resolveDefaultPageSize(pageSizeOptions, changeOrderLandlords.length);
      tableRef.current.dataManager.changePageSize(newPageSize);
      setPageSize(newPageSize);
    }
  }, [changeOrderLandlordContext.getChangeOrderLandlords()]);

  const renderToolbar = (props: any) => (
    <div>
      <MTableToolbar {...props} />
      <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
        <Button color="inherit" size="small" className={classes.button} startIcon={<Add />} onClick={() => openEditChangeOrderLandlordDialog()}>
          {`${languageContext.getMessage("new")} ${languageContext.getMessage("changeOrder")} ${languageContext.getMessage("landlordShort")}`}
        </Button>
      </Toolbar>
    </div>
  );

  const components: Components = {
    Groupbar: EmptyGroupbar,
  };
  if (projectContext.hasProjectAccess(RoleType.WRITER)) {
    components.Toolbar = renderToolbar;
  }

  return (
    <>
      {openFileList && <StorageFileDialog open={openFileList} onClose={() => setOpenFileList(false)} files={changeOrderFiles} onDeletedFile={onDeletedChangeOrderFile} />}
      {isDialogOpen && (
        <FileUploadDialog
          title={languageContext.getMessage("uploadFiles")}
          description={""}
          dropAreaText={languageContext.getMessage("dragAndDropFiles")}
          imagesStorageFileKey={getStorageFileKey(DocumentType.CHANGE_ORDER_SUB_CONTRACTOR, currentChangeOrderId)}
          onClose={() => setIsDialogOpen(false)}
          groups={[
            {
              key: languageContext.getMessage("files"),
              name: languageContext.getMessage("file"),
              pluralName: languageContext.getMessage("files"),
              extensions: [".png", ".jpg", ".jpeg", ".md", ".txt", ".pdf", ".xls", ".xlxs", ".csv"],
            },
          ]}
          defaultGroup={{
            key: "default",
            name: "document",
            pluralName: "documents",
            extensions: [".jpeg", ".png", ".jpeg", ".pdf"],
          }}
          onUploadFile={onUploadFile}
          setImages={setChangeOrderImages}
          setImageFilenames={setChangeOrderImageFilenames}
          existingFileNames={changeOrderImageFilenames}
          onUploadCompleted={() => refreshChangeOrderFiles()}
        />
      )}
      <EditChangeOrderLandlordGroupDialog open={showEditChangeOrderGroupDialog} onClose={onCloseChangeOrderLandlordDialog} changeOrderLandlordGroup={selectedChangeOrderGroup} />
      <EditChangeOrderLandlordDialog changeOrderLandlord={changeOrderLandlordToEdit} open={showDialog} onClose={onCloseChangeOrderLandlordDialog} />
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        localization={useMaterialTableLanguageContext().getLocalization()}
        columns={[
          // {
          //   title: languageContext.getMessage("group"),
          //   field: "group",
          //   defaultGroupOrder: 0,
          //   render: (changeOrderGroup) => (
          //     <>
          //       <Tooltip title={languageContext.getMessage("edit")} key={changeOrderGroup as string | undefined}>
          //         <IconButton onClick={() => onEditChangeOrderGroup(changeOrderGroup as string | undefined)}>
          //           <EditIcon />
          //         </IconButton>
          //       </Tooltip>
          //     </>
          //   ),
          // },
          // {
          //   title: languageContext.getMessage("changeOrder"),
          //   field: "name",
          //   render: (changeOrderLandlord) => <TicketLoading documentId={changeOrderLandlord.id} component={changeOrderLandlord.name} />,
          // },
          // {
          //   title: languageContext.getMessage("document"),
          //   field: "name",
          //   render: (changeOrder) => (
          //     <>
          //       <div style={{ alignContent: "right" }}>
          //         <IconButton
          //           color="primary"
          //           disabled={!projectContext.checkIfProjectFileExists(changeOrder.id)}
          //           size='small'
          //           onClick={() => {
          //             if (changeOrder.id) {
          //               loadChangeOrderFiles(changeOrder.id);
          //               setOpenFileList(true);
          //             }
          //           }}
          //         >
          //           <GetAppIcon />
          //         </IconButton>
          //         <IconButton
          //           color="primary"
          //           size='small'
          //           onClick={() => {
          //             setIsDialogOpen(true);
          //             setCurrentChangeOrderId(changeOrder.id ?? "");
          //           }}
          //         >
          //           <PublishIcon />
          //         </IconButton>
          //       </div>
          //     </>
          //   ),
          // },
          {
            title: <b>{languageContext.getMessage("group")}</b>,
            field: "parentGroup",
            cellStyle: CustomTableStyle,
            render: (changeOrder) => (
              <>
                <Grid container wrap="nowrap" spacing={1} alignItems="center">
                  {changeOrder.parentGroup === noneParentGroup ? (
                    <>
                      <Grid item>
                        <Tooltip title={languageContext.getMessage("edit")} key={changeOrder?.group as string | undefined}>
                          <IconButton onClick={() => onEditChangeOrderGroup(changeOrder?.group as string | undefined)} size="small">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Typography>{changeOrder?.group}</Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                    <Grid item>
                        <Tooltip title={languageContext.getMessage("report")} key={changeOrder?.group as string | undefined}>
                          <IconButton
                            onClick={() => {
                              downloadChangeOrderLandlordReport(templateEngineQueriesContext, changeOrderLandlordContext, projectContext, userRoleContext, (changeOrder as ChangeOrderLandlord)?.id);
                            }}
                            disabled={!projectContext.hasProjectAccess(RoleType.WRITER)}
                            size="small"
                          >
                            <FileCopy />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item></Grid>
                      <Grid item>
                        <Tooltip title={languageContext.getMessage("edit")} key={changeOrder?.group as string | undefined}>
                          <IconButton
                            onClick={() => {
                              setChangeOrderLandlordToEdit({ ...(changeOrder as ChangeOrderLandlord) });
                              setShowDialog(true);
                            }}
                            disabled={!projectContext.hasProjectAccess(RoleType.WRITER)}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Typography>{`${changeOrder.name}`}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            ),
          },
          {
            title: languageContext.getMessage("document"),
            field: "name",
            align: 'center',
            cellStyle: CustomTableStyle,
            render: (changeOrder) => {
              if (changeOrder.parentGroup !== noneParentGroup) {
                return (
                  <div style={{ alignContent: "right" }}>
                    <IconButton
                      color="primary"
                      disabled={!projectContext.checkIfProjectFileExists(changeOrder.id)}
                      size="small"
                      onClick={() => {
                        if (changeOrder.id) {
                          loadChangeOrderFiles(changeOrder.id);
                          setOpenFileList(true);
                        }
                      }}
                    >
                      <GetAppIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        setIsDialogOpen(true);
                        setCurrentChangeOrderId(changeOrder.id ?? "");
                      }}
                    >
                      <PublishIcon />
                    </IconButton>
                  </div>
                );
              }
            },
          },
          {
            title: languageContext.getMessage("costRequirements"),
            field: "costRequirements",
            cellStyle: CustomTableStyle,
            type: "numeric",
            render: (changeOrderLandlord) => <CurrencyFormat amount={changeOrderLandlord.costRequirements} />,
          },
          {
            title: languageContext.getMessage("cost"),
            field: "cost",
            type: "numeric",
            cellStyle: CustomTableStyle,
            render: (changeOrderLandlord) => <CurrencyFormat amount={changeOrderLandlord.cost} />,
          },
          {
            title: languageContext.getMessage("surcharge"),
            field: "costSurcharge",
            type: "numeric",
            cellStyle: CustomTableStyle,
            render: (changeOrderLandlord) => <CurrencyFormat amount={changeOrderLandlord.costSurcharge} />,
          },
          {
            title: languageContext.getMessage("costWithSurcharge"),
            field: "costWithSurcharge",
            type: "numeric",
            cellStyle: CustomTableStyle,
            render: (changeOrderLandlord) => <CurrencyFormat amount={changeOrderLandlord.costWithSurcharge} />,
          },
          {
            title: languageContext.getMessage("billed"),
            field: "billed",
            type: "numeric",
            cellStyle: CustomTableStyle,
            render: (changeOrderLandlord) => <CurrencyFormat amount={changeOrderLandlord.billed} />,
          },
          {
            title: languageContext.getMessage("columnStatus"),
            field: "elementStatus",
            cellStyle: CustomTableStyle,
            render: (changeOrderLandlord) => {
              if (changeOrderLandlord.parentGroup === noneParentGroup) {
                return "";
              } else {
                return <ChangeOrderLandlordStatus changeOrderLandlord={changeOrderLandlord} />;
              }
            },
          },
          {
            title: languageContext.getMessage("description"),
            cellStyle: CustomTableStyle,
            field: "changeOrderDescription",
            render: (changeOrder) => <ContentTooltip>{changeOrder.changeOrderDescription}</ContentTooltip>
          },
          {
            title: languageContext.getMessage("comments"),
            cellStyle: CustomTableStyle,
            field: "changeOrderComment",
            render: (changeOrder) => <ContentTooltip>{changeOrder.changeOrderComment}</ContentTooltip>
          },
          {
            title: languageContext.getMessage("created"),
            field: "created",
            cellStyle: CustomTableStyle,
            type: "date",
          },
          {
            title: languageContext.getMessage("sentDate"),
            cellStyle: CustomTableStyle,
            field: "sent",
            type: "date",
          },
          {
            title: languageContext.getMessage("answeredDate"),
            field: "answered",
            cellStyle: CustomTableStyle,
            type: "date",
          },
        ]}
        onTreeExpandChange={(data: GroupedChangeOrderLandlord, isExpanded: boolean) => {
          updateUrlStateWithExpandedGroups(data.group ?? "", isExpanded);
        }}
        data={changeOrderLandlords}
        parentChildData={(row, rows) => {
          const rowData = row as any;
          rowData.tableData.isTreeExpanded = groupIsExpanded(rowData.group ?? "");
          return rows.find((a) => a.id === row.parentGroup);
        }}
        title={showTitle ? `${languageContext.getMessage("changeOrder")} ${languageContext.getMessage("landlordShort")}` : ""}
        onChangeRowsPerPage={(newPageSize: number) => {
          setPageSize(newPageSize);
        }}
        options={{
          padding: "dense",
          paging: true,
          search: true,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptions,
          exportButton: { csv: true, pdf: false },
          exportCsv: (columns, renderData) => {
            changeOrderLandlordContext.downloadChangeOrderLandlords(changeOrderLandlords);
          },
          columnsButton: true,
          emptyRowsWhenPaging: false,
          toolbarButtonAlignment: "left",
          searchFieldAlignment: "left",
          detailPanelColumnAlignment: "left",
          actionsColumnIndex: smallWindow ? 0 : 0,
          // grouping: true,
          // groupTitle: (groupData) => {
          //   return groupData.value && groupData.value.length > 0 ? groupData.value : languageContext.getMessage("general");
          // },
          // groupRowSeparator: " ",
          headerStyle: { position: "sticky", top: 0, fontWeight: "bold" },
          maxBodyHeight: 800,
        }}
        components={components}
        // actions={[
        //   {
        //     icon: () => <EditIcon />,
        //     tooltip: languageContext.getMessage("edit"),
        //     position: "row",
        //     hidden: !projectContext.hasProjectAccess(RoleType.WRITER),
        //     onClick: (event, changeOrderLandlord) => {
        //       if (changeOrderLandlord) {
        //         openEditChangeOrderLandlordDialog(changeOrderLandlord as ChangeOrderLandlord);
        //       }
        //     },
        //   },
        // ]}
      />
    </>
  );
};

export default withTheme(ChangeOrderLandlordList);
