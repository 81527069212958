import { CrewList, CrewDetails, DocumentType } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";

export const receiveCrewList = (
    currentCrewListSearch: CrewList | undefined,
    newCrewList: CrewList | undefined): CrewList => {
    if (!newCrewList) {
        console.error(`Received undefined set of crew entry list: ${newCrewList}`);
        return {};
    }
    newCrewList.fromDate = new Date(newCrewList.fromDate ?? 0);
    newCrewList.toDate = new Date(newCrewList.toDate ?? 0);
    newCrewList.firstDate = new Date(newCrewList.firstDate ?? 0);
    newCrewList.lastDate = new Date(newCrewList.lastDate ?? 0);
    newCrewList.salaryAdjustmentPercentageDate = new Date(newCrewList.salaryAdjustmentPercentageDate ?? 0);
    newCrewList.monthlyCrewDetails = newCrewList.monthlyCrewDetails ?? [];
    newCrewList.projectId = newCrewList.parentDocumentType === DocumentType.PROJECT ? newCrewList.parentDocumentId : undefined;
    newCrewList.monthlyCrewDetails.forEach(crewDetails => {
        receiveCrewDetails(crewDetails, newCrewList.projectId);
    })
    return newCrewList;
}

export const receiveCrewDetails = (crewDetails: CrewDetails | undefined, projectId: Guid | undefined) => {
    if (!crewDetails) {
        return;
    }
    crewDetails.date = new Date(crewDetails.date ?? 0);
    crewDetails.projectId = projectId;
}
