import { Button, IconButton, makeStyles, Theme, Toolbar, withTheme } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import MaterialTable, { Action, Components, MTableToolbar, Options } from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { DocumentType, Contract, RoleType, StorageFile } from "../../contracts/contracts";
import { tableIcons } from "../../ui/table-icons";
import { CustomTableStyle, Guid } from "../../utils/common-types";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import TicketLoading from "../generalComponents/TicketLoading";
import StatusTooltip from "../statusComponents/StatusTooltip";
import EditContractDialog from "./EditContractDialog";
import { useStorageFileMutationsContext } from "../../contexts/storageFile/mutations/storageFileMutationsContext";
import { useLazyQuery } from "@apollo/react-hooks";
import { getStorageFilesGraphqlQueryOptions, QUERY_STORAGE_FILES } from "../../contexts/storageFile/queries/storageFileQueries";
import { getStorageFileKey, onUploadFile } from "../../utils/fileTools";
import { useAccountContext } from "../../contexts/account/accountContext";
import { getPageSizeOptions, notUndefined, resolveDefaultPageSize } from "../../utils/randomTools";
import FileUploadDialog from "../generalComponents/FileUploadDialog";
import StorageFileDialog from "../generalComponents/StorageFileDialog";
import ContentTooltip from "../generalComponents/ContentTooltip";
import { sortAccountByAccountName, sortAccountByAccountNumber, sortContractByAccountNumber } from "../../utils/accountTools";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type props = {
  accountId?: Guid;
  selectedSuppliers?: string[] | undefined;
  onSelectedContractChange?: (newSelectedContract: Contract | undefined) => void;
  hideToolbar?: boolean;
};

const ContractList: React.FC<props> = ({ accountId, selectedSuppliers, onSelectedContractChange, hideToolbar }) => {
  const classes = useStyles();
  const projectContext = useProjectContext();
  const accountContext = useAccountContext();
  const widthContext = useWidthContext();
  const storageMutationContext = useStorageFileMutationsContext();
  const width = widthContext.getWidth();
  const smallWindow = widthContext.isMobileScreen();
  const { getLocalization } = useMaterialTableLanguageContext();

  const [contractFiles, setContractFiles] = useState<StorageFile[]>([]);
  const [contractImages, setContractImages] = useState<string[]>([]);
  const [contractImageFilenames, setContractImageFilenames] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [currentContractId, setCurrentContractId] = useState<string>("");

  const [openFileList, setOpenFileList] = useState<boolean>(false);

  const ContractImagesStorageFileKey = getStorageFileKey(DocumentType.CONTRACT, accountId ?? "");

  const refreshContractFiles = () => {
    if (!accountId) {
      return;
    }
    loadContractImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: ContractImagesStorageFileKey }));
  };

  const languageContext = useLanguageContext();

  const contractContext = useContractContext();
  let contracts = contractContext.getContracts(accountId);

  function loadContractFiles(contractId: string) {
    var storageFileKey = getStorageFileKey(DocumentType.CONTRACT, contractId);
    loadContractImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: storageFileKey }));
  }

  if (selectedSuppliers && selectedSuppliers.length > 0) {
    contracts = contracts.filter((contract) => selectedSuppliers.findIndex((supplier) => supplier === contract.supplier) >= 0);
  }

  const [selectedContract, setSelectedContract] = useState<Contract | undefined>();

  const [loadContractImagesQuery, queryContractImagesResponse] = useLazyQuery(QUERY_STORAGE_FILES);
  const [contractImagesCurrentlyDownloading, setContractImagesCurrentlyDownloading] = useState<Guid[]>([]);

  const [contractImagesCurrentlyUploading, setContractImagesCurrentlyUploading] = useState<Guid[]>([]);
  const [contractImagesFinishedUploading, setContractImagesFinishedUploading] = useState<Guid[]>([]);

  const [pageSize, setPageSize] = useState<number>(1);
  const tableRef = useRef(null) as any;
  const pageSizeOptions = getPageSizeOptions(contracts.length);

  useEffect(() => {
    if (pageSizeOptions.length > 1 && pageSize < pageSizeOptions[pageSizeOptions.length - 1]) {
      const newPageSize = resolveDefaultPageSize(pageSizeOptions, contracts.length);
      tableRef.current.dataManager.changePageSize(newPageSize);
      setPageSize(newPageSize);
    }
  }, [contracts]);

  const onDeletedContractFile = (deletedFile: StorageFile): void => {
    setContractFiles(contractFiles.filter((contractFile) => contractFile.id !== deletedFile.id));
  };

  const updateDownloadRegister = (contractId: Guid, downloading: boolean) => {
    if (contractId.length === 0) {
      return;
    }
    const index = contractImagesCurrentlyDownloading.findIndex((registeredContractId) => registeredContractId === contractId);
    if (index >= 0) {
      contractImagesCurrentlyDownloading.splice(index);
    }
    if (downloading) {
      contractImagesCurrentlyDownloading.push(contractId);
    }
    setContractImagesCurrentlyDownloading(contractImagesCurrentlyDownloading.slice());
  };

  useEffect(() => {
    var storageFiles: StorageFile[] = queryContractImagesResponse.data?.storageFiles ?? [];
    setContractFiles(storageFiles);
  }, [queryContractImagesResponse.data]);

  const hasWriteAccess = projectContext.hasProjectAccess(RoleType.WRITER);

  const options: Options<Contract> = {
    padding: "dense",
    paging: true,
    exportButton: { csv: true, pdf: false },
    exportCsv: (columns, renderData) => {
      contractContext.downloadContracts(contracts.filter(notUndefined));
    },
    columnsButton: true,
    emptyRowsWhenPaging: false,
    toolbarButtonAlignment: smallWindow ? "left" : "right",
    searchFieldAlignment: smallWindow ? "left" : "right",
    pageSize: pageSize,
    pageSizeOptions: pageSizeOptions,
    headerStyle: { position: "sticky", top: 0, fontWeight: "bold" },
    // actionsCellStyle: { borderRight: 0, borderStyle: 'none' },
    maxBodyHeight: 800,
  };

  const actions: Action<Contract>[] = hasWriteAccess
    ? [
        {
          icon: () => <NavigateNextIcon />,
          tooltip: languageContext.getMessage("details"),
          position: "row",
          hidden: !onSelectedContractChange,
          onClick: (event, contract) => {
            contract = contract as Contract;
            if (contract && onSelectedContractChange) {
              onSelectedContractChange(contract);
            }
          },
        },
        {
          icon: () => <Edit />,
          tooltip: languageContext.getMessage("editContract"),
          position: "row",
          onClick: (event, selectedRow) => {
            handleOnClickOpenEditDialog(selectedRow as Contract);
          },
        },
      ]
    : [];

  const [handleNewContract, setHandleNewContract] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const handleOnClickOpenEditDialog = (contract: Contract, isNewContract?: boolean) => {
    setSelectedContract(contract);
    setHandleNewContract(isNewContract ?? false);
    setOpenEditDialog(true);
  };

  const onCloseEditDialog = (mutatedContract: Contract | null) => {
    if (mutatedContract) {
      contractContext.mutateContract(mutatedContract);
    }
    setOpenEditDialog(false);
  };

  const handleOnClickOpenNewDialog = () => {
    const newContract: Contract = { accountId: accountId, projectId: projectContext.getSelectedProject()?.id };
    handleOnClickOpenEditDialog(newContract, true);
  };

  const renderToolbar = (props: any) => (
    <div>
      <MTableToolbar {...props} />
      <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
        <Button color="inherit" size="small" className={classes.button} startIcon={<Add />} onClick={handleOnClickOpenNewDialog}>
          {languageContext.getMessage("addContract")}
        </Button>
      </Toolbar>
    </div>
  );

  const components: Components | undefined = hasWriteAccess
    ? {
        Toolbar: renderToolbar,
      }
    : undefined;

  const invoicedAmountIsGreaterThanProcurement = (contract: Contract): boolean => {
    const invoicedAmount = contract.sumInvoicedAmount ?? 0;
    const procurement = contract.procurement ?? 0;
    return invoicedAmount > procurement;
  };

  return (
    <>
      {openFileList && <StorageFileDialog open={openFileList} onClose={() => setOpenFileList(false)} files={contractFiles} onDeletedFile={onDeletedContractFile} />}
      {isDialogOpen && (
        <FileUploadDialog
          title={languageContext.getMessage("uploadFiles")}
          description={""}
          dropAreaText={languageContext.getMessage("dragAndDropFiles")}
          imagesStorageFileKey={getStorageFileKey(DocumentType.CONTRACT, currentContractId)}
          onClose={() => setIsDialogOpen(false)}
          groups={[
            {
              key: languageContext.getMessage("files"),
              name: languageContext.getMessage("file"),
              pluralName: languageContext.getMessage("files"),
              extensions: [".png", ".jpg", ".jpeg", ".md", ".txt", ".pdf", ".xls", ".xlxs", ".csv"],
            },
          ]}
          defaultGroup={{
            key: "default",
            name: "document",
            pluralName: "documents",
            extensions: [".jpeg", ".png", ".jpeg", ".pdf"],
          }}
          onUploadFile={onUploadFile}
          setImages={setContractImages}
          setImageFilenames={setContractImageFilenames}
          existingFileNames={contractImageFilenames}
          onUploadCompleted={() => refreshContractFiles()}
        />
      )}
      <EditContractDialog contract={selectedContract} open={openEditDialog} onClose={onCloseEditDialog} handleNewContract={handleNewContract} />
      <MaterialTable
        tableRef={tableRef}
        onChangeRowsPerPage={(newPageSize: number) => {
          setPageSize(newPageSize);
        }}
        icons={tableIcons}
        localization={getLocalization()}
        columns={[
          {
            title: languageContext.getMessage("accountNumber"),
            customSort: (a, b) => sortAccountByAccountNumber(accountContext.getAccount(a.accountId) ?? {}, accountContext.getAccount(b.accountId) ?? {}),
            render: (contract) => accountContext.getAccount(contract.accountId)?.accountNumber,
            cellStyle: CustomTableStyle,
          },
          {
            title: languageContext.getMessage("account"),
            field: "accountId",
            customSort: (a, b) => sortAccountByAccountName(accountContext.getAccount(a.accountId) ?? {}, accountContext.getAccount(b.accountId) ?? {}),
            render: (contract) => accountContext.getAccount(contract.accountId)?.name,
            cellStyle: CustomTableStyle,
          },
          {
            title: languageContext.getMessage("name"),
            field: "name",
            render: (contract) => <TicketLoading documentId={contract.id} component={contract.name} />,
            cellStyle: CustomTableStyle,
          },
          {
            title: languageContext.getMessage("supplier"),
            field: "supplier",
            render: (contract) => contract.supplier,
            cellStyle: CustomTableStyle,
          },
          {
            title: languageContext.getMessage("document"),
            field: "name",
            align: "center",
            cellStyle: CustomTableStyle,
            render: (contract) => (
              <div style={{ alignContent: "right" }}>
                <IconButton
                  color="primary"
                  disabled={!projectContext.checkIfProjectFileExists(contract.id)}
                  size="small"
                  onClick={() => {
                    if (contract.id) {
                      loadContractFiles(contract.id);
                      setOpenFileList(true);
                    }
                  }}
                >
                  <GetAppIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setIsDialogOpen(true);
                    setCurrentContractId(contract.id ?? "");
                  }}
                  size="small"
                >
                  <PublishIcon />
                </IconButton>
              </div>
            ),
          },
          {
            title: languageContext.getMessage("contractBudget"),
            align: "right",
            cellStyle: CustomTableStyle,
            render: (contract) => (
              <StatusTooltip hidden={contract.contractProcurementIsUsedWithForecast === true} placement="left" tooltip={languageContext.getMessage("usedWithForecast")}>
                {<CurrencyFormat amount={contract.budget} />}
              </StatusTooltip>
            ),
          },
          {
            title: languageContext.getMessage("contractProcurement"),
            align: "right",
            cellStyle: CustomTableStyle,
            render: (contract) => (
              <StatusTooltip hidden={contract.contractProcurementIsUsedWithForecast === false} placement="left" tooltip={languageContext.getMessage("usedWithForecast")}>
                {<CurrencyFormat amount={contract.procurement} />}
              </StatusTooltip>
            ),
          },
          {
            title: languageContext.getMessage("payedOut"),
            align: "right",
            cellStyle: CustomTableStyle,
            render: (contract) => <CurrencyFormat amount={contract.sumInvoicedAmount ?? 0} />,
          },
          {
            title: languageContext.getMessage("whereAsWithheld"),
            align: "right",
            cellStyle: CustomTableStyle,
            render: (contract) => <CurrencyFormat amount={contract.sumInvoicedWithheldAmount ?? 0} />,
          },
          {
            title: languageContext.getMessage("invoicedTotal"),
            align: "right",
            cellStyle: CustomTableStyle,
            render: (contract) => (
              <StatusTooltip status="error" placement="left" hidden={!invoicedAmountIsGreaterThanProcurement(contract)} tooltip={languageContext.getMessage("invoicedAmountIsGreaterThanProcurment")}>
                {<CurrencyFormat amount={(contract.sumInvoicedAmount ?? 0) + (contract.sumInvoicedWithheldAmount ?? 0)} />}
              </StatusTooltip>
            ),
          },
          {
            title: languageContext.getMessage("approvedChanges"),
            align: "right",
            cellStyle: CustomTableStyle,
            render: (contract) => <CurrencyFormat amount={contract.sumChangeOrderSubContractorApprovedCost ?? 0} />,
          },
          {
            title: languageContext.getMessage("totalInvoicedChangeOrder"),
            align: "right",
            cellStyle: CustomTableStyle,
            render: (contract) => <CurrencyFormat amount={(contract.sumChangeOrderSubContractorInvoicedAmount ?? 0) + (contract.sumChangeOrderSubContractorInvoicedWithheldAmount ?? 0)} />,
          },
          // {
          //     title: languageContext.getMessage('unforeseen'),
          //     field: 'unforeseenBudget',
          //     render: contract => <CurrencyFormat amount={contract.unforeseenBudget} />
          // },
          {
            title: languageContext.getMessage("description"),
            field: "contractDescription",
            cellStyle: CustomTableStyle,
            render: (contract) => <ContentTooltip>{contract.contractDescription}</ContentTooltip>,
          },
        ]}
        title={languageContext.getMessage("contracts")}
        data={contracts.sort((a, b) => sortContractByAccountNumber(a, b, accountContext))}
        options={options}
        actions={actions}
        components={hideToolbar === true ? undefined : components}
        style={{
          width: width,
        }}
      />
    </>
  );
};

export default withTheme(ContractList);
