import { Grid, withTheme } from "@material-ui/core";
import React from "react";
import { Account } from "../../contracts/contracts";
import { useAccountContext } from "../../contexts/account/accountContext";
import AccountList from "../../component/accountComponents/AccountList";
import ProjectInterimDetailsList from "../../component/interimComponents/ProjectInterimDetailsList";

type props = {
  onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
};

const InterimAllView: React.FC<props> = ({ onSelectedAccountChange }) => {
  const accountContext = useAccountContext();

  return (
    <>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={12}>
          <ProjectInterimDetailsList hideToolbar={true} />
        </Grid>
        <Grid item xs={12}>
          <AccountList onClickAccount={(accountId) => onSelectedAccountChange(accountContext.getAccount(accountId))} showInterims={true} />
        </Grid>
      </Grid>
    </>
  );
};

export default withTheme(InterimAllView);
