import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, ChangeOrderCounterClaim, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ChangeOrderCounterClaimSubscriptionsContext {
    subscribedChangeOrderCounterClaims: Array<ChangeOrderCounterClaim>,
}

const ChangeOrderCounterClaimSubscriptionsContext = createContext<ChangeOrderCounterClaimSubscriptionsContext>(null as unknown as ChangeOrderCounterClaimSubscriptionsContext);

export const ChangeOrderCounterClaimSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedChangeOrderCounterClaims, setSubscribedChangeOrderCounterClaims] = useState<Array<ChangeOrderCounterClaim>>([]);

    const updateSubscribedChangeOrderCounterClaims = (stateDocuments: StateDocument[]) => {
        const subscribedChangeOrderCounterClaims = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.CHANGE_ORDER_COUNTER_CLAIM && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as ChangeOrderCounterClaim);
        setSubscribedChangeOrderCounterClaims(subscribedChangeOrderCounterClaims);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedChangeOrderCounterClaims([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedChangeOrderCounterClaims(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: ChangeOrderCounterClaimSubscriptionsContext = {
        subscribedChangeOrderCounterClaims,
    };

    return (
        <ChangeOrderCounterClaimSubscriptionsContext.Provider value={projectContext}>
            {children}
        </ChangeOrderCounterClaimSubscriptionsContext.Provider>
    );
}

export const useChangeOrderCounterClaimSubscriptionsContext = (): ChangeOrderCounterClaimSubscriptionsContext => {
    return useContext(ChangeOrderCounterClaimSubscriptionsContext);
}
