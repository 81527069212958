import { AccrualAndOperationExpense, AccrualAndOperationsExpenses } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";

export const receiveAccrualAndOperationsExpenses = (
    currentAccrualAndOperationSearch: AccrualAndOperationsExpenses | undefined,
    newAccrualAndOperationsExpenses: AccrualAndOperationsExpenses | undefined): AccrualAndOperationsExpenses => {
    if (!newAccrualAndOperationsExpenses) {
        console.error(`Received undefined set of accrualAndOperationExpenses: ${newAccrualAndOperationsExpenses}`);
        return {};
    }
    newAccrualAndOperationsExpenses.fromDate = new Date(newAccrualAndOperationsExpenses.fromDate ?? 0);
    newAccrualAndOperationsExpenses.toDate = new Date(newAccrualAndOperationsExpenses.toDate ?? 0);
    newAccrualAndOperationsExpenses.monthlyExpenseItems = newAccrualAndOperationsExpenses.monthlyExpenseItems ?? [];
    newAccrualAndOperationsExpenses.monthlyExpenseItems.forEach(accrualAndOperationExpense => {
        receiveAccrualAndOperationExpense(accrualAndOperationExpense, newAccrualAndOperationsExpenses.projectId, newAccrualAndOperationsExpenses.accountId);
    })
    return newAccrualAndOperationsExpenses;
}

export const receiveAccrualAndOperationExpense = (accrualAndOperationExpense: AccrualAndOperationExpense | undefined, projectId: Guid | undefined, accountId: Guid | undefined) => {
    if (!accrualAndOperationExpense) {
        return;
    }
    accrualAndOperationExpense.date = new Date(accrualAndOperationExpense.date ?? 0);
    accrualAndOperationExpense.projectId = projectId;
    accrualAndOperationExpense.accountId = accountId;
}
