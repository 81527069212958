import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, Contract } from "../../../contracts/contracts";

export const MUTATE_CONTRACT: DocumentNode = gql`
    mutation contract(
            $id: UUID
            $state: ActiveState
            $accountId: UUID! 
            $projectId: UUID
            $name: String
            $comment: String
            $contractDescription: String
            $budget: Float
            $procurement: Float
            $useContractProcurementWithForecast: Boolean
            $supplier: String
        ) {
        mutateContract(
            id: $id
            state: $state
            accountId: $accountId
            projectId: $projectId
            name: $name
            comment: $comment
            contractDescription: $contractDescription
            budget: $budget
            procurement: $procurement
            useContractProcurementWithForecast: $useContractProcurementWithForecast
            supplier: $supplier
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getContractGraphqlMutationOptions = (contract: Partial<Contract>): QueryHookOptions => {
    contract.state = contract.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (contract.id !== undefined) options.variables['id'] = contract.id;
    if (contract.state !== undefined) options.variables['state'] = contract.state;
    if (contract.accountId !== undefined) options.variables['accountId'] = contract.accountId;
    if (contract.projectId !== undefined) options.variables['projectId'] = contract.projectId;
    if (contract.name !== undefined) options.variables['name'] = contract.name;
    if (contract.comment !== undefined) options.variables['comment'] = contract.comment;
    if (contract.contractDescription !== undefined) options.variables['contractDescription'] = contract.contractDescription;
    if (contract.budget !== undefined) options.variables['budget'] = contract.budget;
    if (contract.procurement !== undefined) options.variables['procurement'] = contract.procurement;
    if (contract.useContractProcurementWithForecast !== undefined) options.variables['useContractProcurementWithForecast'] = contract.useContractProcurementWithForecast;
    if (contract.supplier !== undefined) options.variables['supplier'] = contract.supplier;

    return options;
}
