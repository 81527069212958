import React from "react";
import { makeStyles, Theme, withTheme, Paper, Typography, Link, Grid } from "@material-ui/core";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import companyLogo from '../../data/companyLogo.png';

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    }
}));

const AboutView: React.FC<{}> = () => {
    const classes = useStyles();
    const languageContext = useLanguageContext();

    return (
        <Paper className={classes.card} elevation={4}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '100vh' }}
            >
                <Grid item>
                    <Typography variant="h1" component="h1">AIMZ</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" component="h5">By Neate</Typography>
                </Grid>
                <Grid item>
                    <Typography component="p">
                        <Link href={languageContext.getMessage('companySite')}>
                            <img src={companyLogo} alt={languageContext.getMessage('company')}/>
                        </Link>
                    </Typography>
                </Grid>   
            </Grid> 
        </Paper>
    );
}

export default withTheme(AboutView);