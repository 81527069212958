import React from "react";

const formatter = new Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK', maximumFractionDigits: 0 })

export const displayCurrency = (amount: number | undefined): string => {
    amount = amount ?? 0.0;
    return formatter.format(amount);
};

type props = {
    amount?: number;
    negate?: boolean;
    useDefaultAmount?: boolean;
    defaultAmount?: number;
}

const CurrencyFormat: React.FC<props> = ({
    amount,
    negate,
    useDefaultAmount,
    defaultAmount}) => {
    useDefaultAmount = useDefaultAmount ?? true;
    defaultAmount = defaultAmount ?? 0.0;
    
    if (useDefaultAmount) {
        amount = amount ?? defaultAmount;
    }

    if (amount === undefined) {
        return (<></>)
    }

    negate = negate ?? false;
    amount = negate ? - amount : amount;
    return (<>
        {displayCurrency(amount)}
        </>);
}

export default CurrencyFormat