import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Card, Grid } from '@material-ui/core';
import { Project } from '../../contracts/contracts';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import PercentageFormat from "../percentageComponents/PercentageFormat";
import { useWidthContext } from '../../contexts/WidthContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    card: {
      padding: '1em'
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.dark,
    },
    section1: {
      margin: theme.spacing(3, 2),
    },
    section2: {
      margin: theme.spacing(2),
    },
    section3: {
      margin: theme.spacing(3, 1, 1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    alignItemsAndJustifyContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    alignItemsAndJustifyContentWithMargin: {
      margin: theme.spacing(3, 2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

type props = {
  project?: Project | undefined;
}

const ProjectDashboardContent: React.FC<props> = ({project}) => {
  
  const isMobileScreen = useWidthContext().isMobileScreen();
  const classes = useStyles();
  const projectContext = useProjectContext();
  const languageContext = useLanguageContext();
  project = project ?? projectContext.getSelectedProject();

  return (<>
    <Card>
      <div className={classes.root}>
        <div className={classes.alignItemsAndJustifyContentWithMargin}>
          <Typography gutterBottom variant="h4">
            {languageContext.getMessage('projectInformation')}
          </Typography>
        </div>
        <Divider variant="middle" color="textSecondary" />
        <div className={classes.section1}>
          <Grid item container direction="row" spacing={3}>
            <Grid item container xs={isMobileScreen ? 12 : 6}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <b>{`${languageContext.getMessage('finalForecast')}`}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <CurrencyFormat amount={project?.forecast?.forecast}/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={isMobileScreen ? 12 : 6}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <b>{`${languageContext.getMessage('changesInForecastLastMonth')}`}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <CurrencyFormat amount={project?.forecastReport?.forecastChange}/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={isMobileScreen ? 12 : 6}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <b>{`${languageContext.getMessage('sumProducedValue')}`}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <CurrencyFormat amount={project?.interimExpense?.accumulatedConstructionCosts}/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={isMobileScreen ? 12 : 6}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <b>{`${languageContext.getMessage('producedPercentageOfFinalForecast')}`}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <PercentageFormat percentage={(project?.interimExpense?.producedPercentageOfForecast ?? 0.0)}/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={isMobileScreen ? 12 : 6}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <b>{`${languageContext.getMessage('revisedAccountBudget')}`}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <CurrencyFormat amount={project?.forecast?.revisedAccountBudget}/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={isMobileScreen ? 12 : 6}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <b>{`${languageContext.getMessage('approved_plural')} ${languageContext.getMessage('changes')} ${languageContext.getMessage('landlordShort')}`}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <CurrencyFormat amount={project?.forecast?.changeOrderLandlordApprovedCosts}/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={isMobileScreen ? 12 : 6}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <b>{`${languageContext.getMessage('payedOut')} ${languageContext.getMessage('contract').toLowerCase()}`}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <CurrencyFormat amount={project?.forecast?.sumContractInvoicedAmount}/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={isMobileScreen ? 12 : 6}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <b>{`${languageContext.getMessage('whereAsWithheld')}`}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" className={classes.alignItemsAndJustifyContent}>
                  <CurrencyFormat amount={project?.forecast?.sumContractInvoicedWithheldAmount}/>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Card>
  </>);
}

export default ProjectDashboardContent