import * as React from "react";

import { StorageFile } from "../../contracts/contracts";

import { useEffect, useState } from "react";
import StorageFileListItem from "./StorageFileListItem";
import { downloadBase64File } from "../../utils/fileTools";
import { useLazyQuery } from "@apollo/react-hooks";
import { getStorageFilesGraphqlQueryOptions, QUERY_STORAGE_FILES } from "../../contexts/storageFile/queries/storageFileQueries";
import { useStorageFileMutationsContext } from "../../contexts/storageFile/mutations/storageFileMutationsContext";
import { Typography, List } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import ConfirmDeleteDialog from "../deleteComponent/ConfirmDeleteDialog";
import { useLanguageContext } from "../../contexts/language/LanguageContext";

type props = {
  files: StorageFile[];
  onDeletedFile?: (deletedFile: StorageFile) => void;
  hidePagination?: boolean;
};

const StorageFileList: React.FC<props> = ({ files, onDeletedFile, hidePagination }) => {
  const storageFileMutationContext = useStorageFileMutationsContext();
  const languageContext = useLanguageContext();
  const filesPerPage = 20;

  const [filesToShow, setFilesToShow] = React.useState<StorageFile[]>([]);
  const [loadContractImagesQuery, queryContractImagesResponse] = useLazyQuery(QUERY_STORAGE_FILES);

  useEffect(() => {
    const storageFiles: StorageFile[] = queryContractImagesResponse.data?.storageFiles ?? [];
    storageFiles.forEach((storageFile) => {
      if (storageFile.content && storageFile.metaData) {
        downloadBase64File(undefined, storageFile.content, storageFile.metaData);
      }
    });
  }, [queryContractImagesResponse.data]);

  useEffect(() => {
    setFilesToShow(files.slice(0, hidePagination ? files.length : filesPerPage));
  }, [files]);

  const onFileDownloadClick = (file: StorageFile) => {
    if (file.content && file.metaData) {
      downloadBase64File(undefined, file.content, file.metaData);
    } else if (file.id) {
      loadContractImagesQuery(getStorageFilesGraphqlQueryOptions({ id: file.id }));
    }
  };
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<StorageFile>({});
  const handleOnClickDelete = (deletedFile: StorageFile) => {
    setFileToDelete(deletedFile);
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = async (deleteIsConfirmed: boolean): Promise<void> => {
    if (deleteIsConfirmed) {
      await onFileDeleteClick(fileToDelete);
    }
    setOpenConfirmDeleteDialog(false);
  }

  const onFileDeleteClick = async (deletedFile: StorageFile) => {
    if (deletedFile.id && onDeletedFile) {
      await storageFileMutationContext.mutateStorageFile({ delete: true, id: deletedFile.id });
      onDeletedFile(deletedFile);
    }
  };

  const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    let index = (value - 1) * filesPerPage;
    setFilesToShow(files.slice(index, index + filesPerPage));
  };

  function getContent(file: StorageFile) {
    return (
      <div style={{ display: "grid", gridTemplate: "4fr", paddingTop: "3px", paddingBottom: "3px" }}>
        <div style={{ position: "relative" }}>
          <Typography variant="body2" color="textPrimary">
            {file.metaData}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <>
      <List
        style={{
          width: "100%",
          backgroundColor: "background.paper",
        }}
        // dense
        key='storage_file_list'
      >
        {filesToShow.length > 0 &&
          filesToShow.map((file, index) => {
            return <StorageFileListItem onFileDownloadClick={onFileDownloadClick} onFileDeleteClick={handleOnClickDelete} content={getContent(file)} file={file} lastLine={index >= files.length - 1} />;
          })}
      </List>
      {!hidePagination && files && files.length > 0 && (
        <div style={{ display: "flex", padding: "1em", alignContent: "center" }}>
          <Pagination count={Math.ceil(files.length / filesPerPage)} page={page} onChange={handleChange} />
        </div>
      )}
      <ConfirmDeleteDialog
        title={languageContext.getMessage('doYouWantToDeleteFile')}
        open={openConfirmDeleteDialog}
        onClose={handleOnCloseConfirmDeleteDialog}
      />
    </>
  );
};

export default StorageFileList;
