
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import FileUploadItem, { FileQueueItemInterface } from "./FileUploadItem";
import FileDragAndDrop from "./FileDragAndDrop";
import { getRandomGuid } from "../../utils/randomTools";
import { StorageFileMutationsContext, useStorageFileMutationsContext } from "../../contexts/storageFile/mutations/storageFileMutationsContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";

import Draggable from 'react-draggable';
import { PaperProps, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, capitalize, DialogActions, Button } from "@material-ui/core";
import { ProjectContext, useProjectContext } from "../../contexts/project/projectContext";

interface FileGroup {
  key: string;
  name: string;
  pluralName: string;
  extensions: string[];
}

interface UploadDialogProps {
  title: string;
  description: string;
  dropAreaText: string;
  onClose: () => void;
  groups?: FileGroup[];
  defaultGroup: FileGroup;
  imagesStorageFileKey: string;
  onUploadFile(
    storageMutationContext: StorageFileMutationsContext,
    projectContext: ProjectContext,
    newFiles: File,
    key: string | undefined,
    onMutatedStorageFile?: ((base64Image: string, filename: string) => void) | undefined
  ): Promise<void>;
  multiple?: boolean;
  queueSize?: number;
  existingFileNames: string[];
  onUploading?: () => void;
  onUploadCompleted?: () => void;
  allowedExtensions?: string[];
  setImages: React.Dispatch<React.SetStateAction<string[]>>;
  setImageFilenames: React.Dispatch<React.SetStateAction<string[]>>;
}

const getFileExt = (fileName: string) => `.${fileName.split(".").pop()}`;

const FileUploadDialog = ({
  title,
  description,
  dropAreaText,
  allowedExtensions,
  defaultGroup,
  imagesStorageFileKey,
  onClose,
  onUploadFile,
  groups = [],
  multiple = true,
  queueSize = 5,
  existingFileNames = [],
  onUploadCompleted = () => {},
  onUploading = () => {},
  setImages,
  setImageFilenames,
}: UploadDialogProps) => {
  const languageContext = useLanguageContext();
  const [mode, setMode] = useState<"edit" | "uploading" | "finished">("edit");
  const [queuedFiles, setQueuedFiles] = useState<FileQueueItemInterface[]>([]);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [errorString, setErrorString] = useState<string>("");

  // why props in a ref? Because we want to call the current props from useEffects that have these as dependencies. Like calling onUploading when mode changes.
  const propRefs = useRef({ onUploadCompleted, onUploading, onUploadFile });
  propRefs.current = { onUploadCompleted, onUploading, onUploadFile };

  function getNextFileKey(fileKey: string, fileId: string) {
    return multiple ? fileKey + "_file_" + fileId : fileKey;
  }

  const projectContext = useProjectContext();
  const storageMutationContext = useStorageFileMutationsContext();

  const groupedItems = useMemo(() => {
    return queuedFiles.reduce((current, item) => {
      const group = groups.find((group) => group.extensions.includes(item.ext)) || defaultGroup;
      return {
        ...current,
        [group.key]: [...(current[group.key] || []), item],
      };
    }, {} as { [key: string]: FileQueueItemInterface[] });
  }, [queuedFiles, groups, defaultGroup]);

  const hasFilesWithError = useMemo(() => {
    return !!queuedFiles.filter((file) => !!file.error).length;
  }, [queuedFiles]);

  // item actions
  const itemActions = useMemo(() => {
    const setData = (item: FileQueueItemInterface, data: {}) =>
      setQueuedFiles((currentSelectedFiles) =>
        currentSelectedFiles.map((currentFile) => {
          if (currentFile.id === item.id) {
            return { ...currentFile, ...data };
          }
          return currentFile;
        })
      );

    return {
      resolveDuplicateByRename: (item: FileQueueItemInterface) => {
        setQueuedFiles((currentFiles) => {
          return currentFiles.map((currentFile) => {
            if (currentFile.id === item.id) {
              return {
                ...currentFile,
                isUnresolvedDuplicate: false,
                // https://stackoverflow.com/questions/21720390/how-to-change-name-of-file-in-javascript-from-input-file
                file: new File([item.file], item.file.name, {
                  type: item.file.type,
                  lastModified: item.file.lastModified,
                }),
              };
            }
            return currentFile;
          });
        });
      },
      remove: (item: FileQueueItemInterface) => {
        setQueuedFiles((currentSelectedFiles) => currentSelectedFiles.filter((currentFile) => currentFile !== item));
      },
      setIsUploading: (item: FileQueueItemInterface) => {
        setData(item, { isUploading: true });
      },
      updateProgress: (item: FileQueueItemInterface, progress: number) => {
        setData(item, { progress });
      },
      setUploaded: (item: FileQueueItemInterface) => {
        setData(item, { isUploading: false, isUploaded: true });
      },
      setError: (item: FileQueueItemInterface, error: string) => {
        setData(item, { error, isUploading: false, isUploaded: false });
      },
    };
  }, []);

  const onFiles = useCallback((files: File[]) => {
    setQueuedFiles((currentSelectedFiles) => {
      const newFiles: FileQueueItemInterface[] = [];
      const duplicates: File[] = [];
      files.forEach((file) => {
        const isDuplicate = currentSelectedFiles.some((item) => item.file.name === file.name) || files.some((f) => f !== file && f.name === file.name);

        if (isDuplicate) {
          duplicates.push(file);
        } else {
          newFiles.push({
            id: getRandomGuid(),
            file,
            name: file.name,
            type: file.type,
            ext: getFileExt(file.name),
            lastModified: file.lastModified,
            size: file.size,
            progress: 0,
            error: null,
            isUploading: false,
            isUploaded: false,
          } as FileQueueItemInterface);
        }
      });
      const mergedFiles = [...currentSelectedFiles, ...newFiles];
      return mergedFiles;
    });
  }, []);

  const startUpload = useCallback(async () => {
    if (mode === "edit") {
      setMode("uploading");
      let uploadQueue = queuedFiles.slice();
      let listOffImage: string[] = [];
      let listOffImageFileNames: string[] = existingFileNames;

      const next = async (count: number): Promise<boolean> => {
        const nextItem = uploadQueue.pop();
        if (nextItem) {
          itemActions.setIsUploading(nextItem);
          try {
            await propRefs.current.onUploadFile(storageMutationContext, projectContext, nextItem.file, getNextFileKey(imagesStorageFileKey, nextItem.id), (fileImage, imageFilename) => {
              listOffImage.push(fileImage);
              listOffImageFileNames.push(imageFilename);
            });
            itemActions.setUploaded(nextItem);
          } catch (error: any) {
            itemActions.setError(nextItem, error.toString());
            return false;
          }
        }
        return true;
      };

      let count = 0;
      for (var i = 0; i < queuedFiles.length; i++) {
        if (await next(count)) {
          count++;
        }
      }
      setMode("finished");
      setImageFilenames(listOffImageFileNames);
      setImages(listOffImage);
    }
  }, [mode, queuedFiles, queueSize, itemActions]);

  useEffect(() => {
    if (mode === "uploading") {
      const fn = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = languageContext.getMessage("filesAreStillUploading");
        return languageContext.getMessage("filesAreStillUploading");
      };
      window.addEventListener("beforeunload", fn);
      return () => {
        window.removeEventListener("beforeunload", fn);
      };
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "uploading") {
      propRefs.current.onUploading();
    } else if (mode === "finished") {
      propRefs.current.onUploadCompleted();
    }
  }, [mode]);

  function PaperComponent(props: PaperProps) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <Dialog open aria-labelledby="draggable-dialog-title" fullWidth maxWidth="md" PaperComponent={PaperComponent}>
      <DialogTitle>
        {mode !== "finished" && title}
        {mode === "finished" && languageContext.getMessage("importFinished")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "edit" && description}
          {mode === "uploading" && (
            <>
              <CircularProgress size={20} /> {languageContext.getMessage("importingFiles")}...
            </>
          )}
          {mode === "finished" && !uploadError && languageContext.getMessage("allFilesWhereSuccessfullyImported")}
          {mode === "finished" && uploadError && languageContext.getMessage("someFilesCouldNotBeImported")}
        </DialogContentText>
        {mode === "edit" && (
          <FileDragAndDrop
            allowedExtensions={allowedExtensions}
            disabled={mode !== "edit"}
            onFiles={onFiles}
            multiple={multiple}
            text={dropAreaText}
            dragOverText={languageContext.getMessage("dropFiles")}
          />
        )}
        <div key={languageContext.getMessage("files")} className="queueGroup">
          <h3>{capitalize(languageContext.getMessage("files"))}</h3>
          {mode !== "finished" && (
            <p>
              {`${languageContext.getMessage("youHaveAdded")} ${queuedFiles.length} `}
              {queuedFiles.length > 1 ? languageContext.getMessage("files") : languageContext.getMessage("file")}
            </p>
          )}
          <div className="queueItemList">
            {queuedFiles.map((item) => (
              <FileUploadItem key={item.id} item={item} onDelete={itemActions.remove} editMode={mode === "edit"} />
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={queuedFiles.length < 1 || hasFilesWithError || mode === "uploading"} onClick={mode === "finished" ? onClose : startUpload}>
          {mode === "finished" ? languageContext.getMessage("ok") : languageContext.getMessage("confirm")}
        </Button>
        <Button disabled={mode !== "edit"} onClick={onClose}>
          {languageContext.getMessage("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
