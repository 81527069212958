import {  Grid, Paper, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Account, ChangeOrderSubContractor, ChangeOrderStatus, Contract } from "../../contracts/contracts";
import ChangeOrderSubContractorList from "../../component/changeOrderSubContractorComponents/ChangeOrderSubContractorList";
import ChangeOrderSubContractorMobileList from "../../component/changeOrderSubContractorComponents/ChangeOrderSubContractorMobileList";
import SelectChangeOrderSubContractorFilter from "../../component/changeOrderSubContractorComponents/SelectChangeOrderSubContractorFilter";
import SelectStatus from "../../component/changeOrderSubContractorComponents/SelectStatus";

type props = {
    selectedAccount: Account | undefined;
    selectedContract: Contract | undefined;
    selectedStatus: ChangeOrderStatus | undefined;
    onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
    onSelectedContractChange: (newSelectedContract: Contract | undefined) => void;
    onSelectedStatusChange: (newSelectedStatus: ChangeOrderStatus | undefined) => void;
    onSelectedChangeOrderSubContractorChange?: (newSelectedChangeOrderSubContractor: ChangeOrderSubContractor | undefined) => void;
}

const ChangeOrderSubContractorDetailsView: React.FC<props> = ({
    selectedAccount,
    selectedContract,
    selectedStatus,
    onSelectedAccountChange,
    onSelectedContractChange,
    onSelectedStatusChange,
    onSelectedChangeOrderSubContractorChange}) => {

    return (<>
            {(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 400 ?
            <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SelectChangeOrderSubContractorFilter 
                        selectedAccount={selectedAccount}
                        selectedContract={selectedContract}
                        selectedStatus={selectedStatus}
                        onSelectedAccountChange={onSelectedAccountChange}
                        onSelectedContractChange={onSelectedContractChange}
                        onSelectedStatusChange={onSelectedStatusChange}/>
                </Grid>
                <Grid item xs={12}>
                    <ChangeOrderSubContractorList 
                        selectedContract={selectedContract}
                        selectedStatus={selectedStatus}
                        onSelectedChangeOrderSubContractorChange={onSelectedChangeOrderSubContractorChange} />
                </Grid>
            </Grid>
            </>
            :
            <>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={3}>
                    <Paper elevation={2}>
                        <SelectStatus
                            selectedStatus={selectedStatus}
                            onSelectedStatusChange={onSelectedStatusChange} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <ChangeOrderSubContractorMobileList 
                            selectedContract={selectedContract}
                            selectedStatus={selectedStatus}
                            onSelectedChangeOrderSubContractorChange={onSelectedChangeOrderSubContractorChange} />
                    </Paper>
                </Grid>
            </Grid>
            </>
            }
        </>
    );
}

export default withTheme(ChangeOrderSubContractorDetailsView);