import { Grid, makeStyles, Paper, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Account, AccountType } from "../../contracts/contracts";
import SelectAccount from "../../component/accountComponents/SelectAccount";
import ForecastList from "../../component/forecastComponents/ForecastList";
import ForecastsGraph from "../../component/forecastComponents/ForecastsGraph";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useForecastsContext } from "../../contexts/forecast/forecastsContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import SelectAccountGroup from "../../component/accountComponents/SelectAccountGroup";
import AccountList from "../../component/accountComponents/AccountList";


const useStyles = makeStyles(() => ({
    card: {
        padding: '1em'
    }
}));

type props = {
    selectedAccountGroup: string | undefined;
    onSelectedAccountGroupChange: (newSelectedAccountGroup: string | undefined) => void;
    onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
}

const ForecastGroupDetailsView: React.FC<props> = ({
    selectedAccountGroup,
    onSelectedAccountGroupChange,
    onSelectedAccountChange }) => {

    const urlContext = useUrlContext();
    const forecastsContext = useForecastsContext();
    const projectContext = useProjectContext();
    const accountContext = useAccountContext();

    const classes = useStyles();

    const project = projectContext.getSelectedProject();
    const forecastSearch = forecastsContext.getForecastsSearch();
    forecastSearch.parentDocumentId = project?.id;

    useEffect(() => {
        forecastsContext.searchForecasts(forecastSearch);
    }, [urlContext.currentLocation, projectContext.getSelectedProject()])

    const forecasts = forecastsContext.getForecasts(project?.id) ?? {};
    const forecastsGroup = forecasts.forecastsGroups?.find(forecasts => forecasts.length > 0 && forecasts[0].accountGroup === selectedAccountGroup) ?? [];

    return (<>
        <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
                <Paper className={classes.card} elevation={4}>
                    <SelectAccountGroup
                        selectedAccountGroup={selectedAccountGroup}
                        onSelectedAccountGroupChange={accountGroup => onSelectedAccountGroupChange(accountGroup)} />
                </Paper>
            </Grid>
            {/* <Grid item xs={12}>
                <ForecastList
                    forecasts={forecastsGroup}
                    showLastForecast={true}
                    isGroupForecasts={true}
                />
            </Grid> */}
            <Grid item xs={12}>
                <AccountList
                    onClickAccount={(accountId) => onSelectedAccountChange(accountContext.getAccount(accountId))}
                    showForecasts={true}
                    selectedGroup={selectedAccountGroup} />
            </Grid>
            <Grid item xs={12}>
                <ForecastsGraph
                    forecasts={{ ...forecasts, forecasts: forecastsGroup }}
                    isGroupForecasts={true}
                />
            </Grid>
        </Grid>
    </>
    );
}

export default withTheme(ForecastGroupDetailsView);