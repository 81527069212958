import { IconButton, Grid } from "@material-ui/core";
import withTheme from "@material-ui/styles/withTheme";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { useInterimContext } from "../../contexts/interim/InterimContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { InterimExpense, Account } from "../../contracts/contracts";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";

export interface EditInterimProps {
  account: Account;
}

const EditInterimProducedNotBilled: React.FC<EditInterimProps> = ({ account }) => {
  const languageContext = useLanguageContext();
  const interimContext = useInterimContext();

  const [editing, setEditing] = React.useState<boolean>(false);
  const [editedInterimExpense, setEditedInterimExpense] = React.useState<InterimExpense | undefined>({});

  const handleSaveOrEdit = () => {
    if (editing && editedInterimExpense && account?.id) {
      const interim = interimContext.getInterims().find((interim) => interim.parentDocumentId === account.id);
      interimContext.updateInterim({ ...interim, parentDocumentId: account.id, monthlyInterimItems: [editedInterimExpense] });
    }
    setEditedInterimExpense({ ...interimContext.getInterimExpense(account) });
    setEditing(!editing);
  };

  return (
    <Grid container wrap="nowrap" spacing={1} alignItems='flex-end'>
      {editing && (
        <>
          <Grid item>
            <IconButton size={"small"} onClick={() => handleSaveOrEdit()}>
              <SaveIcon fontSize={"small"} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton size={"small"} onClick={() => setEditing(false)}>
              <CloseIcon fontSize={"small"} />
            </IconButton>
          </Grid>
        </>
      )}
      {!editing && (
        <Grid item>
          <IconButton size={"small"} onClick={() => handleSaveOrEdit()}>
            <EditIcon fontSize={"small"} />
          </IconButton>
        </Grid>
      )}
      <Grid item>
        {editing && (
          <CurrencyInput
            label={""}
            value={editedInterimExpense?.producedNotBilled ?? 0.0}
            onChange={(newProducedNotBilled) => {
              if (editedInterimExpense) {
                editedInterimExpense.producedNotBilled = newProducedNotBilled;
              }
              setEditedInterimExpense({ ...editedInterimExpense });
            }}
            fullWidth
          />
        )}
        {!editing && <CurrencyFormat amount={interimContext.getInterimExpense(account).producedNotBilled} />}
      </Grid>
    </Grid>
  );
};

export default withTheme(EditInterimProducedNotBilled);
