import { DialogActions, DialogContent, Grid, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import withTheme from '@material-ui/styles/withTheme';
import React, { useEffect, useState } from 'react';
import { useAccountContext } from '../../contexts/account/accountContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Account } from '../../contracts/contracts';

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.error.main,
    marginRight: '0.25em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

export interface Props {
  accountGroup: string | undefined;
  open: boolean;
  onClose: (accountsToMutate: Account[]) => void;
}

const EditAccountGroupDialog: React.FC<Props> = ({
  accountGroup,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const accountContext = useAccountContext();

  const [editedAccountGroup, setEditedAccountGroup] = useState<string | undefined>(undefined);

  useEffect(() => {
    setEditedAccountGroup(editedAccountGroup);
  }, [accountGroup])

  const handleOnClickSave = () => {
    const groupedAccounts = accountContext.getAccounts().filter(account => account.accountGroup === accountGroup);
    const accountsToMutate = groupedAccounts.map(groupedAccount => {
      const accountToMutate: Account = { ...groupedAccount, accountGroup: editedAccountGroup };
      return accountToMutate;
    });
    onClose(accountsToMutate);
  };

  const handleOnClickClose = () => {
    onClose([]);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleOnClickClose}
        open={open}
        disableBackdropClick={true}>
        <DialogTitle>
          {languageContext.getMessage('editAccountGroup')}
          <IconButton className={classes.closeButton} onClick={handleOnClickClose} title={languageContext.getMessage('cancel')}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage('accountGroup')}
                defaultValue={accountGroup}
                onChange={(event) => setEditedAccountGroup(event.target.value as string)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClickSave} color="primary" variant="contained">
            {languageContext.getMessage('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withTheme(EditAccountGroupDialog);