import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, CrewList } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";

export const QUERY_CREW_LIST: DocumentNode = gql`
    query crewLists(
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $parentDocumentId: UUID
        $parentDocumentType: DocumentType
        $fromDate: Date
        $toDate: Date
    ) {
        crewLists(
            id: $id
            documentType: $documentType
            state: $state
            created: $created
          	parentDocumentId: $parentDocumentId
          	parentDocumentType: $parentDocumentType
            fromDate: $fromDate
            toDate: $toDate
        ) {
            id
            documentType
            state
            parentDocumentId
            parentDocumentType
            fromDate
            toDate
            firstDate
            lastDate
            constructionAdministrationBudget
            salaryAdjustmentPercentageDate
            monthlyCrewDetails {
                date
                sumAssignedPercentages
                numberOfAvailableCrew
                expectedExpense
                expectedAccumulatedExpense
                actuallyPostedExpense
                actuallyAccumulatedPostedExpense
                revisedForecast
                revisedForecastIsAboveConstructionAdministrationBudget
                disabled
            }
        }
    }
`;

export const getCrewListGraphqlQueryOptions = (crewList: Partial<CrewList>): QueryHookOptions => {

    crewList.state = crewList.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (crewList.id !== undefined) options.variables['id'] = crewList.id;
    if (crewList.state !== undefined) options.variables['state'] = crewList.state;
    if (crewList.parentDocumentId !== undefined) options.variables['parentDocumentId'] = crewList.parentDocumentId;
    if (crewList.parentDocumentType !== undefined) options.variables['parentDocumentType'] = crewList.parentDocumentType;
    if (crewList.fromDate !== undefined) options.variables['fromDate'] = toISODate(crewList.fromDate);
    if (crewList.toDate !== undefined) options.variables['toDate'] = toISODate(crewList.toDate);
    return options;
}