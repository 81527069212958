import { Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import ContractViewAll from "./ContractViewAll";
import ContractViewDetails from "./ContractViewDetails";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { DocumentType, Account, Contract } from "../../contracts/contracts";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { useChangeOrderLandlordContext } from "../../contexts/changeOrderLandlord/changeOrderLandlordContext";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useChangeOrderCounterClaimContext } from '../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext';
import { tryDecodeAndSplitFromUrl, SplitAndEncodeToUrl } from "../../utils/urlTools";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

type props = {};

const ContractView: React.FC<props> = ({}) => {
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const urlContext = useUrlContext();
  const projectContext = useProjectContext();
  const ticketContext = useTicketContext();
  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext()

  const splitString = ";";
  const urlState = urlContext.getUrlState();
  const tabIndex = urlState.tab ? (urlState.tab as string) : "all";
  const detailTabIndex = urlState.detailTab ? (urlState.detailTab as string) : "general";
  const selectedAccountId = urlState.accountId ? (urlState.accountId as string) : undefined;
  const selectedContractId = urlState.contractId ? (urlState.contractId as string) : undefined;
  const selectedSupplierFilter = tryDecodeAndSplitFromUrl(urlState.selectedSupplierFilter, splitString, true);
  const selectedContract = selectedContractId ? contractContext.getContract(selectedContractId) ?? undefined : undefined;

  const accountSearch = accountContext.getAccountSearch();
  const contractSearch = contractContext.getContractSearch();

  const changeOrderSubContractorSearch = changeOrderSubContractorContext.getChangeOrderSubContractorSearch();
  const changeOrderExpectedSearch = changeOrderExpectedContext.getChangeOrderExpectedSearch();
  const changeOrderCounterClaimSearch = changeOrderCounterClaimContext.getChangeOrderCounterClaimSearch();


  useEffect(() => {
    ticketContext.setDocumentTypesToWatch([
      DocumentType.ACCOUNT,
      DocumentType.CONTRACT,
      DocumentType.CHANGE_ORDER_SUB_CONTRACTOR,
      DocumentType.CHANGE_ORDER_EXPECTED,
      DocumentType.CHANGE_ORDER_COUNTER_CLAIM,
    ]);
    accountContext.searchAccounts(accountSearch);
    contractContext.searchContracts(contractSearch);
    // if(selectedContractId) searchChangeOrders(selectedContractId);
    if (changeOrderSubContractorSearch.contractId) {
      changeOrderSubContractorContext.searchChangeOrderSubContractors(changeOrderSubContractorSearch);
    }
    if (changeOrderCounterClaimSearch.contractId) {
      changeOrderCounterClaimContext.searchChangeOrderCounterClaims(changeOrderCounterClaimSearch);
    }
    if (changeOrderExpectedSearch.contractId) {
      changeOrderExpectedContext.searchChangeOrderExpecteds(changeOrderExpectedSearch);
    }
  }, [urlContext.currentLocation, projectContext.getSelectedProject()]);

  function searchChangeOrders(contractId: string) {
      changeOrderSubContractorContext.searchChangeOrderSubContractors({ contractId: contractId });
      changeOrderExpectedContext.searchChangeOrderExpecteds({ contractId: contractId, accountId: undefined });
      changeOrderCounterClaimContext.searchChangeOrderCounterClaims({ contractId: contractId, accountId: undefined });
  }

  const selectedAccount = accountContext.getAccount(selectedAccountId);

  const updateUrlState = (
    newSelectedAccountId: string | undefined,
    newSelectedSupplierFilter: string[] | undefined,
    newSelectedContract: Contract | undefined,
    newTabIndex: string,
    newDetailTabIndex: string
  ): void => {
    const newUrlState = {
      ...contractSearch,
      ...{ accountId: newSelectedAccountId },
      ...{ contractId: newSelectedContract?.id },
      ...{ selectedSupplierFilter: newSelectedSupplierFilter ? SplitAndEncodeToUrl(newSelectedSupplierFilter, splitString, true) : undefined },
      ...{ tab: newTabIndex },
      ...{ detailTab: newDetailTabIndex },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined | any>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const handleTabIndexChange = (event: React.ChangeEvent<{}> | undefined, newTabIndex: string) => {
    updateUrlState(selectedAccountId, selectedSupplierFilter, selectedContract, newTabIndex, detailTabIndex);
  };

  const handleDetailTabIndexChange = (event: React.ChangeEvent<{}>, newTabIndex: string) => {
    updateUrlState(selectedAccountId, selectedSupplierFilter, selectedContract, tabIndex, newTabIndex);
  };

  const onSelectedAccountChange = (newSelectedAccount: Account | undefined): void => {
    updateUrlState(newSelectedAccount?.id, selectedSupplierFilter, selectedContract, tabIndex, detailTabIndex);
  };

  const onSelectedSuppliersChange = (newSelectedSuppliers: string[] | undefined): void => {
    updateUrlState(selectedAccountId, newSelectedSuppliers, selectedContract, tabIndex, detailTabIndex);
  };

  const onSelectedContractChange = (newSelectedContract: Contract | undefined): void => {
    // if (newSelectedContract?.id) {
    //   searchChangeOrders(newSelectedContract?.id);
    // }
    updateUrlState(selectedAccountId, selectedSupplierFilter, newSelectedContract, "details", detailTabIndex);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
          <Tabs value={tabIndex} onChange={handleTabIndexChange} aria-label="simple tabs example">
            <Tab label={languageContext.getMessage("all")} value="all" />
            {<Tab label={languageContext.getMessage("details")} value="details" />}
          </Tabs>
        </Toolbar>
      </Grid>

      {tabIndex === "all" && (
        <Grid item xs={12}>
          <ContractViewAll
            selectedAccount={selectedAccount}
            selectedSuppliers={selectedSupplierFilter}
            onSelectedAccountChange={onSelectedAccountChange}
            onSelectedContractChange={onSelectedContractChange}
            onSelectedSuppliersChange={onSelectedSuppliersChange}
          />
        </Grid>
      )}

      {tabIndex === "details" && (
        <Grid item xs={12}>
          <ContractViewDetails
            selectedContract={selectedContract}
            selectedAccount={selectedAccount}
            onSelectedContractChange={onSelectedContractChange}
            detailTabIndex={detailTabIndex}
            handleDetailTabIndexChange={handleDetailTabIndexChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withTheme(ContractView);
