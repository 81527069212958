import React, { createContext, useContext, useEffect, useState } from "react";
import { AccrualAndOperations } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_ACCRUAL_AND_OPERATIONS, getAccrualAndOperationsGraphqlQueryOptions } from './accrualAndOperationsQueries'
import { useAuthContext } from "../../auth/authContext";

export interface AccrualAndOperationsQueriesContext {
    fetchedAccrualAndOperations: Array<AccrualAndOperations>,
    queryAccrualAndOperations: (accrualAndOperations: AccrualAndOperations) => void,
}

const AccrualAndOperationsQueriesContext = createContext<AccrualAndOperationsQueriesContext>(null as unknown as AccrualAndOperationsQueriesContext);

export const AccrualAndOperationsQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedAccrualAndOperations, setFetchedAccrualAndOperations] = useState<Array<AccrualAndOperations>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_ACCRUAL_AND_OPERATIONS);

    const queryAccrualAndOperations = (accrualAndOperations: AccrualAndOperations): void => {
        const queryOptions = getAccrualAndOperationsGraphqlQueryOptions(accrualAndOperations);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.accrualAndOperations) {
            const newFetchedAccrualAndOperations: Array<AccrualAndOperations> = queryResponse.data.accrualAndOperations;
            setFetchedAccrualAndOperations(newFetchedAccrualAndOperations);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedAccrualAndOperations([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const accrualAndOperationsQueriesContext: AccrualAndOperationsQueriesContext = {
        fetchedAccrualAndOperations,
        queryAccrualAndOperations,
    };

    return (
        <AccrualAndOperationsQueriesContext.Provider value={accrualAndOperationsQueriesContext}>
            {children}
        </AccrualAndOperationsQueriesContext.Provider>
    );
}

export const useAccrualAndOperationsQueriesContext = (): AccrualAndOperationsQueriesContext => {
    return useContext(AccrualAndOperationsQueriesContext);
}
