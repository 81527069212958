import React from 'react';
import { createStyles, Theme, makeStyles, withTheme } from '@material-ui/core/styles';
import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { Risk } from '../../contracts/contracts';
import TicketLoading from '../generalComponents/TicketLoading';
import CurrencyFormat from '../currencyComponents/CurrencyFormat';
import DateFormat from '../dateComponents/DateFormat';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootChange: {
      width: '100%',
    },
    card: {
      padding: '1em',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'initial',
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.dark,
    },
    analysing: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    section: {
      marginTop: theme.spacing(1),
    },
    section1: {
      margin: theme.spacing(2, 2),
    },
    section2: {
      margin: theme.spacing(2),
    },
    section3: {
      margin: theme.spacing(1, 1, 1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    fieldContainer: {
      marginBottom: theme.spacing(1),
    },
    icon: {
      alignItems: 'center',
      margin: 'auto',
      color: '#ffffff',
    },
  }),
);



type props = {
  changes: Risk[];
}

const RiskCommentList: React.FC<props> = ({
  changes}) => {

  const classes = useStyles();

  function ChangesList(props: { riskChanges: Risk[] }) {
    const { riskChanges } = props;
    const mergedRiskChanges: Risk[] = [];
    for (let i = riskChanges.length - 1; i >= 0; i--) {
      let mergedRiskChange: Risk = {};
      mergedRiskChange.id = riskChanges[i].id;
      mergedRiskChange.changedByUsername = riskChanges[i].changedByUsername;
      mergedRiskChange.changed = riskChanges[i].changed;
      mergedRiskChange.changedBy = riskChanges[i].changedBy;
      mergedRiskChange.changeComment = riskChanges[i].changeComment;
      mergedRiskChange.riskCost = riskChanges[i].riskCost;
      if (mergedRiskChanges.length > 0 && 
        mergedRiskChanges[mergedRiskChanges.length - 1].changed === mergedRiskChange.changed && 
        mergedRiskChanges[mergedRiskChanges.length - 1].changedBy === mergedRiskChange.changedBy) {
        continue;
      }
      mergedRiskChanges.push(mergedRiskChange);
    }

    return (
      <React.Fragment>
         {Object.values(mergedRiskChanges).map(risk => (
        <TableRow>
          <TableCell>
            <DateFormat date={risk.changed ? new Date(risk.changed) : undefined} showDatetime={false} />
          </TableCell>
          <TableCell>
            {risk.changedByUsername}
          </TableCell>
          <TableCell>
            {risk.changeComment}
          </TableCell>
          <TableCell>
            <CurrencyFormat amount={risk.riskCost} /> 
          </TableCell>
       </TableRow>
      ))}  
      </React.Fragment>
    );
  
  }
  
  return (<>
    <Card className={classes.card}>
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" component="h5">
          <TicketLoading 
            documentId={changes.length > 0 ? changes[0].id : undefined} 
            component={'Endringer'}/>
          </Typography>
      </Grid>
      <Grid item xs={12}>
      <TableContainer>
      <Table aria-label="collapsible table">
      <TableHead>
          <TableRow>
            <TableCell>Endret</TableCell>
            <TableCell>Endret av</TableCell>
            <TableCell>Kommentar</TableCell>
            <TableCell>Konsekvens</TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
      <ChangesList riskChanges={changes} />
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>
        </Grid>
      </Card>
    </>);
}

export default withTheme(RiskCommentList);