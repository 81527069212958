import React, { createContext, useContext } from "react";
import { StorageFile } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getStorageFileGraphqlMutationOptions, MUTATE_STORAGE_FILE } from "./storageFileMutations";
import { ExecutionResult } from "graphql";

export interface StorageFileMutationsContext {
    mutateStorageFile: (storageFile: StorageFile) => Promise<StorageFile[]>;
}

const StorageFileMutationsContext = createContext<StorageFileMutationsContext>(null as unknown as StorageFileMutationsContext);

export const StorageFileMutationsContextProvider: React.FC<{}> = ({ children }) => {
    const [storageFileMutation] = useMutation(MUTATE_STORAGE_FILE);

    const handleStorageFileMutationResult = (result: ExecutionResult): StorageFile[] => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return [];
        }
        const updatedStorageFiles: StorageFile[] = result.data.mutateStorageFile.files;
        return updatedStorageFiles;
    }

    const mutateStorageFile = (storageFile: StorageFile): Promise<StorageFile[]> => {
        return storageFileMutation(getStorageFileGraphqlMutationOptions(storageFile)).then(handleStorageFileMutationResult);
    }

    const storageFileMutationsContext: StorageFileMutationsContext = {
        mutateStorageFile,
    };

    return (
        <StorageFileMutationsContext.Provider value={storageFileMutationsContext}>
            {children}
        </StorageFileMutationsContext.Provider>
    );
}

export const useStorageFileMutationsContext = (): StorageFileMutationsContext => {
    return useContext(StorageFileMutationsContext);
}
