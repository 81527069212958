import { DialogActions, DialogContent, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Select, TextField, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import withTheme from "@material-ui/styles/withTheme";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";
import { useCrewResourceContext } from "../../contexts/crewResource/crewResourceContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";
import { CrewList, CrewResource, CrewResourceRole } from "../../contracts/contracts";
import { fillEmptyMonths } from "../../utils/dateTools";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import PercentageInput from "../percentageComponents/PercentageInput";
import { notUndefined } from "../../utils/randomTools";
import { GetNewCrewResourceDetails } from "./CrewResourceTools";

const useStyles = makeStyles((theme: Theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function getStyles(subject: string, subjectName: string[], theme: Theme) {
  return {
    fontWeight: subjectName.indexOf(subject) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface CrewResourceDialogProps {
  crewList: CrewList | undefined;
  crewResource: CrewResource | undefined;
  open: boolean;
  usedDates: Date[];
  onClose: (saved: boolean) => void;
}

const NewCrewResourceDialog: React.FC<CrewResourceDialogProps> = (props: CrewResourceDialogProps) => {
  const languageContext = useLanguageContext();
  const projectContext = useProjectContext();
  const crewResourceContext = useCrewResourceContext();
  const userRoleContext = useUserRoleContext();

  const classes = useStyles();
  const { crewList, crewResource, onClose, usedDates, open } = props;
  const theme = useTheme();
  const availableUsers = userRoleContext.getUsers();
  const availableUserNames = availableUsers.map((user) => user.name);

  const [inputAutocompleteValue, setInputAutocompleteValue] = React.useState('');
  const [editedCrewPlan, setEditedCrewPlan] = React.useState<CrewResource | undefined>({});

  useEffect(() => {
    const projectId = crewResource?.projectId ?? projectContext.getSelectedProject()?.id;
    if (crewResource) {
      crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
      crewResource.defaultCrewResourceDetails.assignmentPercentage = crewResource.defaultCrewResourceDetails.assignmentPercentage ?? 100.0;
    }
    setEditedCrewPlan({ ...crewResource, projectId: projectId });
  }, [crewResource]);

  const handleClose = async (save: boolean) => {
    if (save && editedCrewPlan) {
      usedDates.forEach((usedDate) => {
        editedCrewPlan.monthlyCrewResourceDetails = editedCrewPlan.monthlyCrewResourceDetails ?? [];
        editedCrewPlan.monthlyCrewResourceDetails.push(GetNewCrewResourceDetails(crewList, editedCrewPlan, usedDate));
      });
      await crewResourceContext.mutateCrewResource(editedCrewPlan);
    }
    onClose(save);
  };

  const title = `${languageContext.getMessage("new")} ${languageContext.getMessage("crew")}`;

  const freeSolo = true;
  return (
    <Dialog onClose={async () => await handleClose(false)} open={open} maxWidth='xs'>
      <DialogTitle>
        {title}
        <IconButton className={classes.closeButton} onClick={async () => await handleClose(false)} title={languageContext.getMessage("cancel")}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
              <Autocomplete
                options={availableUserNames.filter(notUndefined).filter((name) => crewResource?.name === undefined || name.toLowerCase().includes(crewResource.name.toLowerCase()))}
                getOptionLabel={(name: string) => name}
                freeSolo={freeSolo}
                fullWidth
                value={crewResource?.name ?? ""}
                inputValue={freeSolo ? inputAutocompleteValue : undefined}
                onInputChange={freeSolo ? (event, newInputValue) => {
                  setInputAutocompleteValue(newInputValue);
                  if (crewResource) {
                    crewResource.name = newInputValue;
                    setEditedCrewPlan({ ...crewResource });
                  }
                } : undefined}
                renderInput={(params) => <TextField {...params} label={languageContext.getMessage("name")} />}
              />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>{languageContext.getMessage("role")}</InputLabel>
              <Select
                value={crewResource?.defaultCrewResourceDetails?.role}
                onChange={(event) => {
                  if (crewResource) {
                    if (!crewResource.defaultCrewResourceDetails) {
                      crewResource.defaultCrewResourceDetails = {};
                    }
                    crewResource.defaultCrewResourceDetails.role = event.target.value as CrewResourceRole;
                    setEditedCrewPlan({ ...crewResource });
                  }
                }}
                input={<Input />}
                renderValue={(role) => <div>{crewResourceContext.convertCrewResourceRoleToString(role as CrewResourceRole)}</div>}
                fullWidth
              >
                {Object.values(CrewResourceRole).map((crewResourceRole) => (
                  <MenuItem key={crewResourceRole} value={crewResourceRole} style={getStyles(crewResourceRole, [crewResource?.name ?? ""], theme)}>
                    {crewResourceContext.convertCrewResourceRoleToString(crewResourceRole)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl  fullWidth>
              <CurrencyInput
                fullWidth
                label={languageContext.getMessage("monthlySalary")}
                value={crewResource?.defaultCrewResourceDetails?.salary ?? 0.0}
                onChange={(newSalary) => {
                  if (crewResource) {
                    crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                    crewResource.defaultCrewResourceDetails.salary = newSalary;
                    setEditedCrewPlan({ ...crewResource });
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <PercentageInput
                label={languageContext.getMessage("monthlyPercentage")}
                percentage={crewResource?.defaultCrewResourceDetails?.assignmentPercentage ?? 0.0}
                onChange={(newAssignmentPercentage) => {
                  if (crewResource) {
                    crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                    crewResource.defaultCrewResourceDetails.assignmentPercentage = newAssignmentPercentage;
                    setEditedCrewPlan({ ...crewResource });
                  }
                }}
                fullWidth={true}
                updateOnDefaultPercentageChange={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <PercentageInput
                label={languageContext.getMessage("yearlyWageGrowth")}
                percentage={crewResource?.defaultCrewResourceDetails?.salaryAdjustmentPercentage ?? 0.0}
                onChange={(newAssignmentPercentage) => {
                  if (crewResource) {
                    crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                    crewResource.defaultCrewResourceDetails.salaryAdjustmentPercentage = newAssignmentPercentage;
                    setEditedCrewPlan({ ...crewResource });
                  }
                }}
                fullWidth={true}
                updateOnDefaultPercentageChange={false}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={async () => await handleClose(true)} color="primary" variant="contained">
          {languageContext.getMessage("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTheme(NewCrewResourceDialog);
