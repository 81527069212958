import { DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";
import withTheme from "@material-ui/styles/withTheme";
import ChipInput from "material-ui-chip-input";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { Account, AccountType, ActiveState } from "../../contracts/contracts";
import { joinExternalIds, removeInvalidExternalIdChars, splitExternalId } from "../../utils/externalIdTools";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import ConfirmDeleteDialog from "../deleteComponent/ConfirmDeleteDialog";
import SelectAccountGroup from "./SelectAccountGroup";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.error.main,
    marginRight: "0.25em",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export interface Props {
  account: Account | undefined;
  open: boolean;
  onClose: (mutatedAccount?: Account) => void;
  accountTypeFocus?: AccountType;
  handlingNewAccount?: boolean;
}

const EditAccountDialog: React.FC<Props> = ({ account, open, onClose, accountTypeFocus, handlingNewAccount }) => {
  accountTypeFocus = accountTypeFocus ?? AccountType.NORMAL;
  handlingNewAccount = handlingNewAccount ?? false;
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const accountContext = useAccountContext();
  const projectContext = useProjectContext();
  const userRoleContext = useUserRoleContext();
  const users = userRoleContext.getUsers();

  const [editedAccount, setEditedAccount] = useState<Account>();
  const [accountType, setAccountType] = useState<AccountType | undefined>(undefined);
  const [externalIds, setExternalIds] = useState<string[]>([]);

  const handleOnClickSave = () => {
    const mutatedAccount: Account = { ...editedAccount };
    mutatedAccount.projectId = mutatedAccount.projectId ?? projectContext.getSelectedProject()?.id;
    mutatedAccount.created = mutatedAccount.created ?? new Date();
    mutatedAccount.state = mutatedAccount.state ?? ActiveState.ACTIVE;
    // mutatedAccount.externalId = joinExternalIds(externalIds);
    mutatedAccount.externalId = editedAccount?.accountNumber;

    onClose(mutatedAccount);
  };

  const handleOnClickClose = () => {
    onClose(undefined);
  };

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      const mutatedAccount = { ...account };
      mutatedAccount.state = ActiveState.INACTIVE;
      onClose(mutatedAccount);
    }
    setOpenConfirmDeleteDialog(false);
  };

  const handleAddExternalId = (externalId: string): void => {
    externalId = removeInvalidExternalIdChars(externalId);
    if (externalIds.findIndex((existingExternalId) => existingExternalId === externalId) >= 0) {
      return;
    }
    externalIds.push(externalId);
    setExternalIds(externalIds.slice());
  };

  const handleDeleteExternalId = (externalId: string): void => {
    const index = externalIds.findIndex((existingExternalId) => existingExternalId === externalId);
    if (index >= 0) {
      externalIds.splice(index, 1);
      setExternalIds(externalIds.slice());
    }
  };

  useEffect(() => {
    if (account) {
      setEditedAccount({ ...account });
      setExternalIds(splitExternalId(account?.externalId));
    }
  }, [account]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth={"xs"} onClose={handleOnClickClose} open={open} disableBackdropClick={true}>
        <DialogTitle>
          {handlingNewAccount ? languageContext.getMessage("newAccount") : languageContext.getMessage("editAccount")}
          <IconButton className={classes.closeButton} onClick={handleOnClickClose} title={languageContext.getMessage("cancel")}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage("name")}
                defaultValue={editedAccount?.name}
                onChange={(event) => setEditedAccount({ ...editedAccount, name: event.target.value as string })}
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <SelectAccountGroup
                selectedAccountGroup={editedAccount?.accountGroup}
                accountTypeFocus={accountType}
                onSelectedAccountGroupChange={(newSelectedAccountGroup) => setEditedAccount({ ...editedAccount, accountGroup: newSelectedAccountGroup })}
                freeSolo={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel shrink>{languageContext.getMessage("accountType")}</InputLabel>
                <Select
                  value={editedAccount?.accountType ?? AccountType.NORMAL}
                  onChange={(event) => {
                    setEditedAccount({ ...editedAccount, accountType: event.target.value as AccountType });
                    setAccountType(event.target.value as AccountType);
                  }}
                  displayEmpty
                  fullWidth={true}
                  readOnly={false}
                >
                  {Object.keys(AccountType).map((accountType) => (
                    <MenuItem key={accountType} value={accountType}>
                      {accountContext.accountTypeToMessage(accountType)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage("accountNumber")}
                defaultValue={editedAccount?.accountNumber}
                onChange={(event) => setEditedAccount({ ...editedAccount, accountNumber: event.target.value as string })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyInput
                fullWidth
                label={languageContext.getMessage("accountBudget")}
                value={editedAccount?.budget ?? 0.0}
                onChange={(amount) => setEditedAccount({ ...editedAccount, budget: amount })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage("comment")}
                value={editedAccount?.comment}
                onChange={(event) => setEditedAccount({ ...editedAccount, comment: event.target.value as string })}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            {!handlingNewAccount && (
              <Grid item xs={12}>
                <Typography variant="body2">{`${languageContext.getMessage("lastChangedBy")} ${users.find((user) => user.id === editedAccount?.changedBy)?.name}`}</Typography>
              </Grid>
            )}
          </Grid>

          {/* <Grid item xs={12}>
              <ChipInput
                label={languageContext.getMessage('accountCodes')}
                fullWidth
                value={externalIds}
                onAdd={(externalId: string) => handleAddExternalId(externalId)}
                onDelete={(externalId: string, index: number) => handleDeleteExternalId(externalId)}
              />
          </Grid> */}
        </DialogContent>
        <DialogActions>
          {!handlingNewAccount && (
            <Button onClick={handleOnClickDelete} color="primary" variant="contained">
              {languageContext.getMessage("delete")}
            </Button>
          )}
          <Button onClick={handleOnClickSave} color="primary" variant="contained">
            {languageContext.getMessage("save")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog title={languageContext.getMessage("doYouWantToDeleteAccount")} open={openConfirmDeleteDialog} onClose={async (deleteIsConfirmed) => handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)} />
    </>
  );
};

export default withTheme(EditAccountDialog);
