import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Invoice, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";

export const QUERY_INVOICE: DocumentNode = gql`
    query invoices(
            $searchIndexStart: Int
            $searchIndexStop: Int
            $id: UUID
            $documentType: DocumentType
            $state: ActiveState
            $created: DateTime
            $projectId: UUID
            $accountId: UUID
            $contractId: UUID
            $changeOrderId: UUID
            $externalProvider: String
            $externalId: String
            $externalIdMissingWithProvider: Boolean
            $externalProjectId: String
            $externalAccountId: String
            $paid: Boolean
            $postedStatus: InvoicePostedStatus
            $approvedStatus: InvoiceApprovedStatus
            $invoiceNumber: String
            $accountNumber: String
            $scannedDate: Date
            $voucherDate: Date
            $dueDate: Date
            $interimAccounted: Boolean
            $interimAccountedDate: Date
            $interimAccountedAmount: Float
            $interimAccountedWithheldAmount: Float
            $amount: Float
            $withheldAmount: Float
            $vatCode: String
            $comments: [String]
            $supplier: String
            $invoiceIsSplit: Boolean
            $unknownVat: Boolean
            $unknownCurrency: Boolean
            $parentId: UUID
        ) {
        invoices(
            searchIndexStart: $searchIndexStart
            searchIndexStop: $searchIndexStop
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            projectId: $projectId
            accountId: $accountId
            contractId: $contractId
            changeOrderId: $changeOrderId
            externalProvider: $externalProvider
            externalId: $externalId
            externalIdMissingWithProvider: $externalIdMissingWithProvider
            externalProjectId: $externalProjectId
            externalAccountId: $externalAccountId
            paid: $paid
            postedStatus: $postedStatus
            approvedStatus: $approvedStatus
            invoiceNumber: $invoiceNumber
            accountNumber: $accountNumber
            scannedDate: $scannedDate
          	voucherDate: $voucherDate
            dueDate: $dueDate
            interimAccounted: $interimAccounted
            interimAccountedDate: $interimAccountedDate
            interimAccountedAmount: $interimAccountedAmount
            interimAccountedWithheldAmount: $interimAccountedWithheldAmount
            amount: $amount
            withheldAmount: $withheldAmount
            vatCode: $vatCode
          	comments: $comments
            supplier: $supplier
            invoiceIsSplit: $invoiceIsSplit
            unknownVat: $unknownVat
            unknownCurrency: $unknownCurrency
            parentId: $parentId
        ) {
            id
            documentType
            stateTime
            state
            created
            projectId
            accountId
            contractId
            changeOrderId
            externalProvider
            externalId
            externalReferenceId
            externalIdMissingWithProvider
            externalAttachmentId
            externalProjectId
            externalAccountId
            paid
            postedStatus
            approvedStatus
            invoiceNumber
            accountNumber
            scannedDate
            voucherDate
          	dueDate
            interimAccounted
            interimAccountedDate
            interimAccountedAmount
            interimAccountedWithheldAmount
          	amount
            withheldAmount
          	vatCode
          	comments
          	supplier
            invoiceIsSplit
            unknownVat
            unknownCurrency
            parentId
        }
    }
`;

export const QUERY_EXTERNAL_INVOICE_IMAGES: DocumentNode = gql`
    query externalInvoiceImages($externalId: String!, $externalProvider: ExternalProvider!) {
      externalInvoiceImages(externalId: $externalId, externalProvider: $externalProvider) {
        externalId
        externalProvider
        image
        imagePage
        imagesInTotal
      }
    }
`;

export const getInvoicesGraphqlQueryOptions = (invoice: Partial<Invoice>): QueryHookOptions => {
    invoice.state = invoice.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (invoice.searchIndexStart !== undefined) options.variables['searchIndexStart'] = invoice.searchIndexStart;
    if (invoice.searchIndexStop !== undefined) options.variables['searchIndexStop'] = invoice.searchIndexStop;
    if (invoice.id !== undefined) options.variables['id'] = invoice.id;
    if (invoice.state !== undefined) options.variables['state'] = invoice.state;
    if (invoice.projectId !== undefined) options.variables['projectId'] = invoice.projectId;
    // if (invoice.accountId !== undefined) options.variables['accountId'] = invoice.accountId;
    // if (invoice.contractId !== undefined) options.variables['contractId'] = invoice.contractId;
    // if (invoice.changeOrderId !== undefined) options.variables['changeOrderId'] = invoice.changeOrderId;
    // if (invoice.externalId !== undefined) options.variables['externalId'] = invoice.externalId;
    // if (invoice.externalIdMissingWithProvider !== undefined) options.variables['externalIdMissingWithProvider'] = invoice.externalIdMissingWithProvider;
    // if (invoice.externalProjectId !== undefined) options.variables['externalProjectId'] = invoice.externalProjectId;
    // if (invoice.externalAccountId !== undefined) options.variables['externalAccountId'] = invoice.externalAccountId;
    // if (invoice.paid !== undefined) options.variables['paid'] = invoice.paid;
    // if (invoice.accountNumber !== undefined) options.variables['accountNumber'] = invoice.accountNumber;
    // if (invoice.scannedDate !== undefined) options.variables['scannedDate'] = toISODate(invoice.scannedDate);
    // if (invoice.voucherDate !== undefined) options.variables['voucherDate'] = toISODate(invoice.voucherDate);
    // if (invoice.dueDate !== undefined) options.variables['dueDate'] = toISODate(invoice.dueDate);
    // if (invoice.interimAccounted !== undefined) options.variables['interimAccounted'] = invoice.interimAccounted;
    // if (invoice.interimAccountedDate !== undefined) options.variables['interimAccountedDate'] = toISODate(invoice.interimAccountedDate);
    // if (invoice.amount !== undefined) options.variables['amount'] = invoice.amount;
    // if (invoice.withheldAmount !== undefined) options.variables['withheldAmount'] = invoice.withheldAmount;
    // if (invoice.supplier !== undefined) options.variables['supplier'] = invoice.supplier;
    // if (invoice.invoiceIsSplit !== undefined) options.variables['invoiceIsSplit'] = invoice.invoiceIsSplit;
    // if (invoice.unknownVat !== undefined) options.variables['unknownVat'] = invoice.unknownVat;
    // if (invoice.unknownCurrency !== undefined) options.variables['unknownCurrency'] = invoice.unknownCurrency;
    // if (invoice.parentId !== undefined) options.variables['parentId'] = invoice.parentId;
    return options;
}
