export function splitExternalId(externalId: string | undefined): string[] {
    if (!externalId) {
        return [];
    }
    return externalId.split(',');
}

export function joinExternalIds(externalIds: string[]): string {
    return externalIds.join(',');
}

export function removeInvalidExternalIdChars(externalId: string): string {
    return externalId.replace(',', '');
}