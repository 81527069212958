import { withTheme } from "@material-ui/core";
import { Scatter, ChartComponentProps } from 'react-chartjs-2';
import React from "react";

type props = {
    chartComponentProps: ChartComponentProps;
    chartOptions: Chart.ChartOptions;
    chartTitle: React.ReactNode;
}

const ScatterGraph: React.FC<props> = (props) => {
    return (
        <Scatter
            {...props.chartComponentProps}
            options={props.chartOptions} />
    );
}

export default withTheme(ScatterGraph);