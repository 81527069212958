import { Button, Grid, IconButton, makeStyles, Theme, Toolbar, Tooltip, Typography, withTheme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import MaterialTable, { Column, Components, MTableToolbar } from "material-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { sortContractByName, useContractContext } from "../../contexts/contract/contractContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { ChangeOrderSubContractor, ChangeOrderStatus, Contract, DocumentType, ElementStatusEnum, RoleType, StorageFile, Account } from "../../contracts/contracts";
import { tableIcons } from "../../ui/table-icons";
import { getPageSizeOptions, getRandomGuid, resolveDefaultPageSize } from "../../utils/randomTools";
import { getRenderedElementStatus } from "../../utils/statusToPalettes";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import TicketLoading from "../generalComponents/TicketLoading";
import EditChangeOrderSubContractorDialog from "./EditChangeOrderSubContractorDialog";
import { useStorageFileMutationsContext } from "../../contexts/storageFile/mutations/storageFileMutationsContext";
import { QUERY_STORAGE_FILES, getStorageFilesGraphqlQueryOptions } from "../../contexts/storageFile/queries/storageFileQueries";
import { getStorageFileKey, onUploadFile } from "../../utils/fileTools";
import EditChangeOrderSubContractorGroupDialog from "./EditChangeOrderSubContractorGroupDialog";
import FileUploadDialog from "../generalComponents/FileUploadDialog";
import StorageFileDialog from "../generalComponents/StorageFileDialog";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { CustomTableStyle } from "../../utils/common-types";
import ContentTooltip from "../generalComponents/ContentTooltip";
import { useRememberTableScroll } from "../../hooks/useRememberTableScroll";
import { sortAccountByAccountName } from "../../utils/accountTools";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: "1em",
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const noneParentGroup = "None";
export type GroupedChangeOrder = ChangeOrderSubContractor & {
  parentGroup?: string;
  numberInGroup?: number;
};

type props = {
  selectedAccount?: Account | undefined;
  selectedContract?: Contract | undefined;
  selectedStatus?: ChangeOrderStatus | undefined;
  onSelectedChangeOrderSubContractorChange?: (newSelectedChangeOrderSubContractor: ChangeOrderSubContractor | undefined) => void;
  showTitle?: boolean;
  hideToolbar?: boolean;
};

const ChangeOrderSubContractorList: React.FC<props> = ({ selectedAccount, selectedContract, selectedStatus, onSelectedChangeOrderSubContractorChange, showTitle, hideToolbar }) => {
  const classes = useStyles();

  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const languageContext = useLanguageContext();
  const { getLocalization } = useMaterialTableLanguageContext();
  const themeContext = useThemeContext();
  const projectContext = useProjectContext();

  const storageMutationContext = useStorageFileMutationsContext();
  const theme = themeContext.getTheme();

  const urlContext = useUrlContext();
  const urlState = urlContext.getUrlState();
  const expandedGroupsSeparator = ";";
  const expandedGroups = urlState.expandedGroups ? (urlState.expandedGroups as string).split(expandedGroupsSeparator) : [];

  const getSecureGroupName = (group: string): string => (group = group.trim().length > 0 ? group : noneParentGroup);

  const groupIsExpanded = (group: string): boolean => {
    group = getSecureGroupName(group);
    return expandedGroups.findIndex((expandedGroup) => expandedGroup === group) >= 0;
  };

  const updateUrlStateWithExpandedGroups = (group: string, expanded: boolean): void => {
    group = getSecureGroupName(group);
    const index = expandedGroups.findIndex((expandedGroup) => expandedGroup === group);
    if (index >= 0 && !expanded) {
      expandedGroups.splice(index, 1);
    } else if (index < 0 && expanded) {
      expandedGroups.push(group);
    }
    const newUrlState = {
      ...urlState,
      ...{ expandedGroups: expandedGroups.length > 0 ? expandedGroups.join(expandedGroupsSeparator) : undefined },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const renderToolbar = (props: any) => (
    <div>
      <MTableToolbar {...props} />
      <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
        <Button color="inherit" size="small" className={classes.button} startIcon={<Add />} onClick={() => openEditChangeOrderSubContractorDialog()}>
          {`${languageContext.getMessage("new")} ${languageContext.getMessage("changeOrder")} ${languageContext.getMessage("subContractorShort")}`}
        </Button>
      </Toolbar>
    </div>
  );

  const smallWindow = useWidthContext().isMobileScreen();

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [changeOrderSubContractorToEdit, setChangeOrderSubContractorToEdit] = useState<ChangeOrderSubContractor>();
  const [showEditChangeOrderGroupDialog, setShowEditChangeOrderGroupDialog] = useState<boolean>(false);
  const [selectedChangeOrderGroup, setSelectedChangeOrderGroup] = useState<string | undefined>(undefined);
  // const changeOrderSubContractors = changeOrderSubContractorContext.getChangeOrderSubContractors(selectedContract?.id, selectedStatus, selectedAccount?.id);

  const changeOrderSubContractors = useMemo(() => {
    var resolvedChangeOrders: GroupedChangeOrder[] = [];

    var resolvedGroups: GroupedChangeOrder[] = [];
    var foundChangeOrder: GroupedChangeOrder = {};
    var groupId: string = "";

    resolvedChangeOrders = changeOrderSubContractorContext.getChangeOrderSubContractors(selectedContract?.id, selectedStatus, selectedAccount?.id) as GroupedChangeOrder[];
    resolvedChangeOrders.forEach((changeOrder) => {
      var foundIndex = resolvedGroups.findIndex((existingGroup) => existingGroup.group === changeOrder.group);
      if (foundIndex < 0) {
        groupId = getRandomGuid();
        foundChangeOrder = {
          parentGroup: noneParentGroup,
          numberInGroup: 0,
          id: groupId,
          group: changeOrder.group,
          name: "Total",
          cost: 0,
          costRequirements: 0,
          sumInvoicedAmount: 0,
        };
        foundIndex = resolvedGroups.push(foundChangeOrder);
      } else {
        foundChangeOrder = resolvedGroups[foundIndex];
      }
      if (foundChangeOrder && foundChangeOrder.id) {
        foundChangeOrder.numberInGroup = (foundChangeOrder.numberInGroup ?? 0) + 1;
        foundChangeOrder.cost = (foundChangeOrder?.cost ?? 0) + (changeOrder.cost ?? 0);
        foundChangeOrder.costRequirements = (foundChangeOrder?.costRequirements ?? 0) + (changeOrder.costRequirements ?? 0);
        foundChangeOrder.sumInvoicedAmount = (foundChangeOrder?.sumInvoicedAmount ?? 0) + (changeOrder.sumInvoicedAmount ?? 0);
        groupId = foundChangeOrder.id;
      }
      changeOrder.parentGroup = groupId;
    });
    resolvedChangeOrders = [...resolvedChangeOrders, ...resolvedGroups];

    return resolvedChangeOrders;
  }, [changeOrderSubContractorContext]);

  const [currentChangeOrderId, setCurrentChangeOrderId] = useState<string>("");
  const [changeOrderFiles, setChangeOrderFiles] = useState<StorageFile[]>([]);
  const [changeOrderImages, setChangeOrderImages] = useState<string[]>([]);
  const [changeOrderImageFilenames, setChangeOrderImageFilenames] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [openFileList, setOpenFileList] = useState<boolean>(false);

  const ChangeOrderImagesStorageFileKey = getStorageFileKey(DocumentType.CHANGE_ORDER_SUB_CONTRACTOR, currentChangeOrderId ?? "");

  function loadChangeOrderFiles(changeOrderId: string) {
    var storageFileKey = getStorageFileKey(DocumentType.CHANGE_ORDER_SUB_CONTRACTOR, changeOrderId);
    loadChangeOrderImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: storageFileKey }));
  }

  const refreshChangeOrderFiles = () => {
    if (!currentChangeOrderId) {
      return;
    }
    loadChangeOrderImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: ChangeOrderImagesStorageFileKey }));
  };

  const [loadChangeOrderImagesQuery, queryChangeOrderImagesResponse] = useLazyQuery(QUERY_STORAGE_FILES);

  const onDeletedChangeOrderFile = (deletedFile: StorageFile): void => {
    setChangeOrderFiles(changeOrderFiles.filter((file) => file.id !== deletedFile.id));
  };

  useEffect(() => {
    var storageFiles: StorageFile[] = queryChangeOrderImagesResponse.data?.storageFiles ?? [];
    setChangeOrderFiles(storageFiles);
  }, [queryChangeOrderImagesResponse.data]);

  const openEditChangeOrderSubContractorDialog = (changeOrderSubContractorToEdit?: ChangeOrderSubContractor) => {
    setChangeOrderSubContractorToEdit(changeOrderSubContractorToEdit ?? { accountId: selectedAccount?.id });
    setShowDialog(true);
  };

  const onCloseChangeOrderSubContractorDialog = () => {
    setShowDialog(false);
    setShowEditChangeOrderGroupDialog(false);
  };

  const onEditChangeOrderGroup = (changeOrderGroup: string | undefined) => {
    setSelectedChangeOrderGroup(changeOrderGroup);
    setShowEditChangeOrderGroupDialog(true);
  };

  const [pageSize, setPageSize] = useState<number>(1);
  const tableRef = useRef(null) as any;
  useRememberTableScroll(tableRef, "changeOrderSubContractorList");
  const pageSizeOptions = getPageSizeOptions(changeOrderSubContractors.length);

  useEffect(() => {
    if (pageSizeOptions.length > 1 && pageSize < pageSizeOptions[pageSizeOptions.length - 1]) {
      const newPageSize = resolveDefaultPageSize(pageSizeOptions, changeOrderSubContractors.length);
      tableRef.current.dataManager.changePageSize(newPageSize);
      setPageSize(newPageSize);
    }
  }, [changeOrderSubContractorContext.getChangeOrderSubContractors()]);

  const renderGroupbar = (props: any) => <div></div>;

  const components: Components = {
    Groupbar: renderGroupbar,
  };
  if (projectContext.hasProjectAccess(RoleType.WRITER)) {
    components.Toolbar = renderToolbar;
  }

  const getColumns = (): Column<GroupedChangeOrder>[] => {
    return getColumnsSubContractor();
  };

  const getColumnsSubContractor = (): Column<GroupedChangeOrder>[] => {
    return [
      {
        title: <b>{languageContext.getMessage("group")}</b>,
        field: "parentGroup",
        cellStyle: CustomTableStyle,
        render: (changeOrder) => (
          <>
            <Grid container wrap="nowrap" spacing={1} alignItems="center">
              {changeOrder.parentGroup === noneParentGroup ? (
                <>
                  <Grid item>
                    <Tooltip title={languageContext.getMessage("edit")} key={changeOrder?.group as string | undefined}>
                      <IconButton onClick={() => onEditChangeOrderGroup(changeOrder?.group as string | undefined)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography>{changeOrder?.group}</Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Tooltip title={languageContext.getMessage("details")} key={changeOrder?.group as string | undefined}>
                      <IconButton
                        onClick={() => {
                          if (onSelectedChangeOrderSubContractorChange) {
                            onSelectedChangeOrderSubContractorChange(changeOrder as ChangeOrderSubContractor);
                          }
                        }}
                        size="small"
                        disabled={!projectContext.hasProjectAccess(RoleType.WRITER)}
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title={languageContext.getMessage("edit")} key={changeOrder?.group as string | undefined}>
                      <IconButton
                        onClick={() => {
                          setChangeOrderSubContractorToEdit({ ...(changeOrder as ChangeOrderSubContractor) });
                          setShowDialog(true);
                        }}
                        disabled={!projectContext.hasProjectAccess(RoleType.WRITER)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography>{`${changeOrder.name}`}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        ),
      },
      {
        title: languageContext.getMessage("document"),
        field: "name",
        align: 'center',
        cellStyle: CustomTableStyle,
        render: (changeOrderSubContractor) => {
          if (changeOrderSubContractor.parentGroup !== noneParentGroup) {
            return (
              <div style={{ alignContent: "right" }}>
                <IconButton
                  color="primary"
                  disabled={!projectContext.checkIfProjectFileExists(changeOrderSubContractor.id)}
                  size="small"
                  onClick={() => {
                    if (changeOrderSubContractor.id) {
                      loadChangeOrderFiles(changeOrderSubContractor.id);
                      setOpenFileList(true);
                    }
                  }}
                >
                  <GetAppIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    setIsDialogOpen(true);
                    setCurrentChangeOrderId(changeOrderSubContractor.id ?? "");
                  }}
                >
                  <PublishIcon />
                </IconButton>
              </div>
            );
          }
        },
      },
      {
        title: languageContext.getMessage("costRequirements"),
        field: "costRequirements",
        type: "numeric",
        cellStyle: CustomTableStyle,
        render: (changeOrderSubContractor) => <CurrencyFormat amount={changeOrderSubContractor.costRequirements} />,
      },
      {
        title: languageContext.getMessage("cost"),
        field: "cost",
        type: "numeric",
        cellStyle: CustomTableStyle,
        render: (changeOrderSubContractor) => <CurrencyFormat amount={changeOrderSubContractor.cost} />,
      },
      {
        title: languageContext.getMessage("invoicedAmount"),
        field: "sumInvoicedAmount",
        type: "numeric",
        cellStyle: CustomTableStyle,
        render: (changeOrderSubContractor) => <CurrencyFormat amount={changeOrderSubContractor.sumInvoicedAmount} />,
      },
      {
        title: languageContext.getMessage("remainingInvoicedAmount"),
        field: "remainingInvoicedAmount",
        type: "numeric",
        cellStyle: CustomTableStyle,
        render: (changeOrderSubContractor) => <CurrencyFormat amount={(changeOrderSubContractor.cost ?? 0.0) - (changeOrderSubContractor.sumInvoicedAmount ?? 0.0)} />,
      },
      {
        title: languageContext.getMessage("columnStatus"),
        field: "elementStatus",
        cellStyle: CustomTableStyle,
        render: (changeOrderSubContractor) => {
          if (changeOrderSubContractor.parentGroup === noneParentGroup) {
            return "";
          } else {
            return (
              <div style={{ display: "inline-flex", verticalAlign: "middle" }}>
                {getRenderedElementStatus(changeOrderSubContractor.elementStatus ?? ElementStatusEnum.ALERT, theme)} &nbsp;{" "}
                {changeOrderSubContractorContext.convertChangeOrderStatusToString(changeOrderSubContractor.status)}
              </div>
            );
          }
        },
      },
      {
        title: languageContext.getMessage("description"),
        cellStyle: CustomTableStyle,
        field: "changeOrderDescription",
        render: (changeOrder) => <ContentTooltip>{changeOrder.changeOrderDescription}</ContentTooltip>
      },
      {
        title: languageContext.getMessage("comments"),
        cellStyle: CustomTableStyle,
        field: "changeOrderComment",
        render: (changeOrder) => <ContentTooltip>{changeOrder.changeOrderComment}</ContentTooltip>
      },
      {
        title: languageContext.getMessage("account"),
        field: "accountId",
        cellStyle: CustomTableStyle,
        customSort: (a, b) => sortAccountByAccountName(accountContext.getAccount(a.accountId) ?? {}, accountContext.getAccount(b.accountId) ?? {}),
        render: (changeOrderSubContractor) => accountContext.getAccount(changeOrderSubContractor.accountId)?.name,
      },
      {
        title: languageContext.getMessage("contract"),
        field: "contractId",
        cellStyle: CustomTableStyle,
        customSort: (a, b) => sortContractByName(contractContext.getContract(a.contractId) ?? {}, contractContext.getContract(b.contractId) ?? {}),
        render: (changeOrderSubContractor) => contractContext.getContract(changeOrderSubContractor.contractId)?.name,
      },
      {
        title: languageContext.getMessage("created"),
        field: "created",
        cellStyle: CustomTableStyle,
        type: "date",
      },
      {
        title: languageContext.getMessage("receivedDate"),
        field: "received",
        cellStyle: CustomTableStyle,
        type: "date",
      },
      {
        title: languageContext.getMessage("answeredDate"),
        field: "answered",
        cellStyle: CustomTableStyle,
        type: "date",
      },
    ];
  };

  return (
    <>
      {openFileList && <StorageFileDialog open={openFileList} onClose={() => setOpenFileList(false)} files={changeOrderFiles} onDeletedFile={onDeletedChangeOrderFile} />}
      {isDialogOpen && (
        <FileUploadDialog
          title={languageContext.getMessage("uploadFiles")}
          description={""}
          dropAreaText={languageContext.getMessage("dragAndDropFiles")}
          imagesStorageFileKey={getStorageFileKey(DocumentType.CHANGE_ORDER_SUB_CONTRACTOR, currentChangeOrderId)}
          onClose={() => setIsDialogOpen(false)}
          groups={[
            {
              key: languageContext.getMessage("files"),
              name: languageContext.getMessage("file"),
              pluralName: languageContext.getMessage("files"),
              extensions: [".png", ".jpg", ".jpeg", ".md", ".txt", ".pdf", ".xls", ".xlxs", ".csv"],
            },
          ]}
          defaultGroup={{
            key: "default",
            name: "document",
            pluralName: "documents",
            extensions: [".jpeg", ".png", ".jpeg", ".pdf"],
          }}
          onUploadFile={onUploadFile}
          setImages={setChangeOrderImages}
          setImageFilenames={setChangeOrderImageFilenames}
          existingFileNames={changeOrderImageFilenames}
          onUploadCompleted={() => refreshChangeOrderFiles()}
        />
      )}
      <EditChangeOrderSubContractorGroupDialog open={showEditChangeOrderGroupDialog} onClose={onCloseChangeOrderSubContractorDialog} changeOrderSubContractorGroup={selectedChangeOrderGroup} />
      <EditChangeOrderSubContractorDialog changeOrderSubContractor={changeOrderSubContractorToEdit} open={showDialog} onClose={onCloseChangeOrderSubContractorDialog} />
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        localization={getLocalization()}
        columns={getColumns()}
        data={changeOrderSubContractors}
        onTreeExpandChange={(data: GroupedChangeOrder, isExpanded: boolean) => {
          updateUrlStateWithExpandedGroups(data.group ?? "", isExpanded);
        }}
        parentChildData={(row, rows) => {
          const rowData = row as any;
          rowData.tableData.isTreeExpanded = groupIsExpanded(rowData.group ?? "");
          return rows.find((a) => a.id === row.parentGroup);
        }}
        title={showTitle ? `${languageContext.getMessage("changeOrder")} ${languageContext.getMessage("subContractorShort")}` : ""}
        onChangeRowsPerPage={(newPageSize: number) => {
          setPageSize(newPageSize);
        }}
        options={{
          padding: "dense",
          paging: true,
          search: true,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptions,
          exportButton: { csv: true, pdf: false },
          exportCsv: (columns, renderData) => {
            changeOrderSubContractorContext.downloadChangeOrderSubContractors(changeOrderSubContractors);
          },
          columnsButton: true,
          emptyRowsWhenPaging: false,
          toolbarButtonAlignment: "right",
          searchFieldAlignment: "right",
          detailPanelColumnAlignment: "left",
          actionsColumnIndex: smallWindow ? 0 : 0,
          // grouping: hideToolbar === true ? false : true,
          // groupTitle: (groupData) => {
          //   return groupData.value && groupData.value.length > 0 ? groupData.value : languageContext.getMessage("general");
          // },
          // groupRowSeparator: " ",
          headerStyle: { position: "sticky", top: 0, fontWeight: "bold" },
          maxBodyHeight: 800,
        }}
        components={hideToolbar === true ? undefined : components}
        // actions={[
        //   {
        //     icon: () => <NavigateNextIcon />,
        //     tooltip: languageContext.getMessage("details"),
        //     position: "row",
        //     hidden: !onSelectedChangeOrderSubContractorChange,
        //     onClick: (event, changeOrderSubContractor) => {
        //       changeOrderSubContractor = changeOrderSubContractor as ChangeOrderSubContractor;
        //       if (changeOrderSubContractor && onSelectedChangeOrderSubContractorChange) {
        //         onSelectedChangeOrderSubContractorChange(changeOrderSubContractor);
        //       }
        //     },
        //   },
        //   {
        //     icon: () => <EditIcon />,
        //     tooltip: languageContext.getMessage("edit"),
        //     position: "row",
        //     hidden: !projectContext.hasProjectAccess(RoleType.WRITER),
        //     onClick: (event, changeOrderSubContractor) => {
        //       changeOrderSubContractor = changeOrderSubContractor as ChangeOrderSubContractor;
        //       if (changeOrderSubContractor) {
        //         openEditChangeOrderSubContractorDialog(changeOrderSubContractor);
        //       }
        //     },
        //   },
        // ]}
      />
    </>
  );
};

export default withTheme(ChangeOrderSubContractorList);
