import React, { createContext, useContext, useState } from "react";
import { LanguageCollection, LanguageContext, LanguageKey, LanguageState, LanguageType } from "./interfaces";
import { enMap } from "./locale/en";
import { noMap } from "./locale/nb";


function getSupportedLanguage(language: string): LanguageType {
    switch (language) {
        case 'nb':
        case 'nn':
            return 'nb';
    }
    return 'en';
}

const initialState: LanguageState = {
    language: getSupportedLanguage(localStorage.getItem('language') || 'nb')
};

const languageCollection: LanguageCollection = {
    en: enMap,
    nb: noMap
}

const LanguageContextInstance = createContext<LanguageContext>(null as unknown as LanguageContext);

export const LanguageProvider: React.FC<{}> = ({ children }) => {
    const [languageState, setLanguageState] = useState<LanguageState>(initialState);

    const setLanguage = (language: LanguageType) => {
        const newLanguageState: LanguageState = { language: getSupportedLanguage(language) };
        localStorage.setItem('language', newLanguageState.language);
        setLanguageState({ ...languageState, ...newLanguageState });
    }

    const getLanguage = (): LanguageType => {
        return languageState.language;
    }

    const resetLanguage = () => {
        setLanguage(getSupportedLanguage(navigator.language));
    }

    const getMessage = (key: LanguageKey): string => {
        return languageCollection[languageState.language][key];
    }

    const value = { 
        setLanguage,
        getLanguage, 
        resetLanguage, 
        getMessage 
    };

    return (
        <LanguageContextInstance.Provider value={value}>
            {children}
        </LanguageContextInstance.Provider>
    );
}

export const useLanguageContext = (): LanguageContext => {
    return useContext(LanguageContextInstance);
}

export * from './interfaces';

