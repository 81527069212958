import { makeStyles, Theme, withTheme } from "@material-ui/core";
import { Cancel, CheckCircle, Error, NotInterested } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MaterialTable from "material-table";
import React, { ReactElement, useState } from "react";
import CurrencyFormat from "../../component/currencyComponents/CurrencyFormat";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useRiskContext } from "../../contexts/risk/riskContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { ElementStatusEnum, Risk, RiskType, RoleType } from "../../contracts/contracts";
import { tableIcons } from "../../ui/table-icons";
import { getRenderedRiskStatus } from "../../utils/statusToPalettes";
import TicketLoading from "../generalComponents/TicketLoading";
import RiskDialog from "./RiskDialog";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    button: {
        margin: theme.spacing(1),
    }
}));

function getRenderedStatus(status: ElementStatusEnum, theme: Theme): ReactElement {
    switch (status) {
        case ElementStatusEnum.INFORMATION: return <Error style={{ color: theme.palette.info.main }} />;
        case ElementStatusEnum.ALERT: return <Cancel style={{ color: theme.palette.error.main }} />;
        case ElementStatusEnum.SUCCESS: return <CheckCircle style={{ color: theme.palette.success.main }} />;
        default: return <NotInterested />;
    }
}

type props = {
    selectedRiskType: RiskType | undefined;
    onSelectedRiskChange?: (newSelectedRisk: Risk | undefined) => void;
}

const RiskList: React.FC<props> = ({
    selectedRiskType,
    onSelectedRiskChange }) => {

    const classes = useStyles();

    const riskContext = useRiskContext();
    const projectContext = useProjectContext();
    const languageContext = useLanguageContext();
    const accountContext = useAccountContext();
    const { getLocalization } = useMaterialTableLanguageContext();
    const smallWindow = useWidthContext().isMobileScreen();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [riskToEdit, setRiskToEdit] = useState<Risk>({});

    const openRiskDialog = (riskToEdit: Risk) => {
        setRiskToEdit({...riskToEdit});
        setShowDialog(true);
    };

    const onCloseRiskDialog = () => {
        setShowDialog(false);
        let accountName = '';
    };

    function GetAccountName(risk: Risk) {
        // const { risk } = props;
        const account = accountContext.getAccount(risk.accountId)

        let accountString = '';
  
        if (account != undefined) {
            accountString = account?.accountNumber + ' - ' + account?.name;
        }

        return accountString
        
    }


    return (<>
        <RiskDialog 
            risk={riskToEdit} 
            open={showDialog} 
            onClose={onCloseRiskDialog} />
        <MaterialTable
            icons={tableIcons}
            localization={getLocalization()}
            columns={[
                {
                    title: languageContext.getMessage('type'),
                    field: 'riskType',
                    editable: 'never',
                    hidden: false,
                    render: risk => <TicketLoading 
                                    documentId={risk.id} 
                                    component={languageContext.getMessage(risk?.riskType ?? 'unkown')} />,
                },
                {
                    title: languageContext.getMessage('RISK'),
                    field: 'riskCost',
                    editable: 'never',
                    hidden: false,
                    render: risk => getRenderedRiskStatus(risk?.probabilityMatrix ?? 1, risk?.consequenceMatrix ?? 1)
                },
                {
                    title: languageContext.getMessage('account'),
                    field: 'accountId',
                    editable: 'never',
                    hidden: false,
                    render: risk => GetAccountName(risk)
                },
                {
                    title: languageContext.getMessage('title'),
                    field: 'name',
                    editable: 'never',
                    hidden: false,
                },
                {
                    title: languageContext.getMessage('description'),
                    field: 'comment',
                    editable: 'never',
                    hidden: false,
                },
                {
                    title: languageContext.getMessage('consequence'),
                    field: 'riskCost',
                    editable: 'never',
                    type: 'numeric',
                    hidden: false,
                    render: risk => <CurrencyFormat amount={risk.riskCost} />
                },
            ]}
            data={riskContext.getRisks()}
            title=""
            options={{
                padding: 'dense',
                paging: true,
                search: true,
                exportButton: { csv: true, pdf: false },
                exportCsv: (columns, renderData) => {
                    riskContext.downloadRisks(riskContext.getRisks());
                },
                columnsButton: true,
                emptyRowsWhenPaging: false,
                toolbarButtonAlignment: smallWindow ? 'left' : 'left',
                searchFieldAlignment: 'left',
                detailPanelColumnAlignment: 'right',
                actionsColumnIndex: smallWindow ? 0 : 0,
                headerStyle: {position: "sticky", top: 0, fontWeight: 'bold'},
                maxBodyHeight: 500,
            }}
            actions={[
                {
                    icon: () => <NavigateNextIcon />,
                    tooltip: languageContext.getMessage('details'),
                    position: 'row',
                    hidden: !onSelectedRiskChange,
                    onClick: (event, risk) => {
                        risk = risk as Risk;
                        if (risk && onSelectedRiskChange) {
                            onSelectedRiskChange(risk);
                        }
                    }
                },
                {
                    icon: () => <EditIcon />,
                    tooltip: languageContext.getMessage('edit'),
                    position: 'row',
                    hidden: !projectContext.hasProjectAccess(RoleType.WRITER),
                    onClick: (event, risk) => {
                        risk = risk as Risk;
                        if (risk && onSelectedRiskChange) {
                            openRiskDialog(risk)
                        }
                    }
                }]}
        />
    </>
    );
}

export default withTheme(RiskList);