import React, { useEffect, useState } from "react";
import EditIcon from '@material-ui/icons/Edit';
import { withTheme, makeStyles, Theme, Grid, TextField, Button, Paper, Chip } from '@material-ui/core';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { DatePicker } from '../../component/dateComponents/DatePicker';
import { useProjectContext } from '../../contexts/project/projectContext';
import { Project, RoleType } from '../../contracts/contracts';
import PercentageInput from '../percentageComponents/PercentageInput';
import TicketLoading from '../generalComponents/TicketLoading';
import EditProjectDialog from '../projectComponents/EditProjectDialog';
import ChipInput from "material-ui-chip-input";
import { splitExternalId } from "../../utils/externalIdTools";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: '1em'
    },
    divider: {
        marginBottom: '1em'
    },
    button: {
        margin: theme.spacing(1),
      },
}));

export interface GeneralSettingsProps {
    project: Project | undefined,
  }

const GeneralSettings: React.FC<GeneralSettingsProps> = (props: GeneralSettingsProps) => {

    const classes = useStyles();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();

    const { project } = props;

    const [editedProject, setEditedProject] = React.useState<Project | undefined>(undefined);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

    const onCloseEditDialog = (editedProjectToMutate?: Project) => {
        if (editedProjectToMutate) {
            projectContext.updateProject(editedProjectToMutate);
        }
        setShowEditDialog(false);
    };

    useEffect(() => {
        setEditedProject({...project});
    }, [project])


    const onEditProject = () => {
        setEditedProject({...editedProject});
        setShowEditDialog(true);
    }

    return (<>
        <EditProjectDialog open={showEditDialog} onClose={onCloseEditDialog} project={editedProject} handlingNewProject={false}></EditProjectDialog>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={2}>
                <TicketLoading 
                    documentId={editedProject?.id} 
                    component={<TextField
                                fullWidth
                                label={languageContext.getMessage('projectName')}
                                value={editedProject?.name ?? ''} />} />
            </Grid>
            <Grid item xs={12} sm={6} md={'auto'}>
                <DatePicker
                    label={languageContext.getMessage('projectStart')}
                    date={editedProject?.projectStart}
                    readOnly={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={'auto'}>
                <DatePicker
                    label={languageContext.getMessage('projectEnd')}
                    date={editedProject?.projectEnd}
                    readOnly={true} />
                </Grid>
            <Grid item xs={12} sm={6} md={'auto'}>
                <PercentageInput
                    label={`${languageContext.getMessage('surcharge')}`}
                    percentage={editedProject?.surchargePercent ?? 0.0}
                    fullWidth={true}
                    updateOnDefaultPercentageChange={true}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={'auto'}>
                <TextField
                    fullWidth
                    label={languageContext.getMessage('projectCodes')}
                    value={editedProject?.externalId ?? ""} />
            </Grid>
            {projectContext.hasProjectAccess(RoleType.WRITER) && 
            <Grid item xs={12} sm={6} md={'auto'}>
                <Button className={classes.button} color="primary" variant="contained" onClick={() => onEditProject()}>
                    {<EditIcon />}
                </Button>
            </Grid>}
        </Grid> 
        </>);
}

export default withTheme(GeneralSettings)