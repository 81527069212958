import { DocumentType, AccrualAndOperations, AccrualAndOperationPostedExpense } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";

export const receiveAccrualAndOperations = (
    currentAccrualAndOperationSearch: AccrualAndOperations | undefined,
    newAccrualAndOperations: AccrualAndOperations | undefined): AccrualAndOperations => {
    if (!newAccrualAndOperations) {
        console.error(`Received undefined set of accrualAndOperations: ${newAccrualAndOperations}`);
        return {};
    }
    newAccrualAndOperations.fromDate = new Date(newAccrualAndOperations.fromDate ?? 0);
    newAccrualAndOperations.toDate = new Date(newAccrualAndOperations.toDate ?? 0);
    newAccrualAndOperations.firstDate = new Date(newAccrualAndOperations.firstDate ?? 0);
    newAccrualAndOperations.lastDate = new Date(newAccrualAndOperations.lastDate ?? 0);
    newAccrualAndOperations.monthlyPostedExpenseItems = newAccrualAndOperations.monthlyPostedExpenseItems ?? [];
    newAccrualAndOperations.projectId = newAccrualAndOperations.parentDocumentType === DocumentType.PROJECT ? newAccrualAndOperations.parentDocumentId : undefined;
    newAccrualAndOperations.accountId = newAccrualAndOperations.parentDocumentType === DocumentType.ACCOUNT ? newAccrualAndOperations.parentDocumentId : undefined;
    newAccrualAndOperations.monthlyPostedExpenseItems.forEach(accrualAndOperationPostedExpense => {
        receiveAccrualAndOperationPostedExpense(accrualAndOperationPostedExpense, newAccrualAndOperations.projectId, newAccrualAndOperations.accountId);
    })
    return newAccrualAndOperations;
}

export const receiveAccrualAndOperationPostedExpense = (accrualAndOperationPostedExpense: AccrualAndOperationPostedExpense | undefined, projectId: Guid | undefined, accountId: Guid | undefined) => {
    if (!accrualAndOperationPostedExpense) {
        return;
    }
    accrualAndOperationPostedExpense.date = new Date(accrualAndOperationPostedExpense.date ?? 0);
    accrualAndOperationPostedExpense.projectId = projectId;
    accrualAndOperationPostedExpense.accountId = accountId;
}
