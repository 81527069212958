import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Risk, ActiveState } from "../../../contracts/contracts";

export const MUTATE_RISK: DocumentNode = gql`
    mutation risk(
        $id: UUID
        $state: ActiveState
        $projectId: UUID!
        $accountId: UUID
        $name: String
        $comment: String
        $changeComment: String
        $riskType: RiskType
        $consequence: Float
        $consequenceMin: Float
        $consequenceMax: Float
        $consequenceType: ConsequenceType
        $probabilityType: ProbabilityType
        $probability: Float
        $probabilityMin: Float
        $probabilityMax: Float
        $riskCostToForecast:Boolean
        $fromDate: Date
        $toDate: Date
    ) {
    mutateRisk(
        id: $id
        state: $state
        projectId: $projectId
        accountId: $accountId
        name: $name
        comment: $comment
        changeComment: $changeComment
        riskType: $riskType
        consequence: $consequence
        consequenceMin: $consequenceMin
        consequenceMax: $consequenceMax
        consequenceType: $consequenceType
        probabilityType: $probabilityType
        probability: $probability
        probabilityMin: $probabilityMin
        probabilityMax: $probabilityMax
        riskCostToForecast: $riskCostToForecast
        fromDate: $fromDate
        toDate: $toDate
    ) {
        tickets {
            id
            parentDocumentId
            parentDocumentType
            processTag
        }
    }
}
`;

export const getRisksGraphqlMutationOptions = (risk: Partial<Risk>): QueryHookOptions => {

    risk.state = risk.state ?? ActiveState.ACTIVE;
    if (risk.riskCostToForecast === undefined) {
        risk.riskCostToForecast = false;
    }
    const options: QueryHookOptions = {variables: {}}
    if (risk.id && options.variables) options.variables['id'] = risk.id
    if (risk.state && options.variables) options.variables['state'] = risk.state
    if (risk.projectId && options.variables) options.variables['projectId'] = risk.projectId
    if (risk.accountId && options.variables) options.variables['accountId'] = risk.accountId
    if (risk.name && options.variables) options.variables['name'] = risk.name
    if (risk.comment && options.variables) options.variables['comment'] = risk.comment
    if (risk.changeComment && options.variables) options.variables['changeComment'] = risk.changeComment
    if (risk.riskType && options.variables) options.variables['riskType'] = risk.riskType
    if (risk.consequence && options.variables) options.variables['consequence'] = risk.consequence
    if (risk.consequenceMin && options.variables) options.variables['consequenceMin'] = risk.consequenceMin
    if (risk.consequenceMax && options.variables) options.variables['consequenceMax'] = risk.consequenceMax
    if (risk.consequenceType && options.variables) options.variables['consequenceType'] = risk.consequenceType
    if (risk.probabilityType && options.variables) options.variables['probabilityType'] = risk.probabilityType
    if (risk.probability && options.variables) options.variables['probability'] = risk.probability
    if (risk.probabilityMin && options.variables) options.variables['probabilityMin'] = risk.probabilityMin
    if (risk.probabilityMax && options.variables) options.variables['probabilityMax'] = risk.probabilityMax
    if (options.variables) {options.variables['riskCostToForecast'] = risk.riskCostToForecast}
    if (risk.fromDate && options.variables) options.variables['fromDate'] = risk.fromDate
    if (risk.toDate && options.variables) options.variables['toDate'] = risk.toDate

    return options;
}
