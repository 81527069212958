import { CrewResourceContext } from "../../contexts/crewResource/crewResourceContext";
import { CrewDetails, CrewResource, CrewList, CrewResourceDetails } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { datesAreOnSameMonth, diffDatesInYears, getStartOfMonth } from "../../utils/dateTools";
import { CalculateFutureValue } from "../../utils/numberTools";

export const extractUsedMonths = (
    crewList: CrewList | undefined,
    crewResources: CrewResource[],
    monthlyCrewResourceDetails: Dictionary<Dictionary<CrewResourceDetails>>, 
    monthlyCrewDetails: Dictionary<CrewDetails>, 
    crewListSearch?: CrewList | undefined): Date[] => {
    let usedDates: Date[] = [];
    crewResources.forEach(crewResource => {
        crewResource?.monthlyCrewResourceDetails?.forEach(monthlyCrewResource => {
            if (!monthlyCrewResource.date || !crewResource.id) {
                return;
            }
            const dateStr = monthlyCrewResource.date?.toISOString();
            if (!(crewResource.id in monthlyCrewResourceDetails)) {
                monthlyCrewResourceDetails[crewResource.id] = {}
            }
            monthlyCrewResourceDetails[crewResource.id][dateStr] = monthlyCrewResource;
            if (usedDates.findIndex(usedDate => usedDate?.toISOString() === dateStr) < 0) {
                usedDates.push(new Date(monthlyCrewResource.date));
                const monthlyCrewDetail = crewList?.monthlyCrewDetails?.find(crewDetails => crewDetails.date?.toISOString() === dateStr) ?? {};
                monthlyCrewDetails[dateStr] = monthlyCrewDetail;
            }
        })
    })
    if (crewResources.length === 0 && crewListSearch?.fromDate && crewListSearch.toDate && !crewListSearch?.fromDateIsEmpty && !crewList?.toDateIsEmpty) {
        let month = getStartOfMonth(crewListSearch.fromDate);
        while (month < crewListSearch.toDate || datesAreOnSameMonth(month, getStartOfMonth(crewListSearch.toDate))) {
            usedDates.push(new Date(month));
            month.setMonth(month.getMonth() + 1);
        }
    }
    return usedDates;
}

export const MutateDefaultCrewResourceMonths = (
    crewResourceContext: CrewResourceContext,
    crewList: CrewList | undefined,
    crewResources: CrewResource[], 
    usedDates: Date[],
    emptyMonths: Date[],
    monthlyCrewResourceDetails: Dictionary<Dictionary<CrewResourceDetails>>,
    defaultMutatedMonths?: Dictionary<Dictionary<CrewResourceDetails>>, 
    setDefaultMutatedMonths?: React.Dispatch<React.SetStateAction<Dictionary<Dictionary<CrewResourceDetails>>>>, 
    crewResourcesToUpdate?: Dictionary<CrewResource>, 
    setCrewResourcesToUpdate?: (crewResources: Dictionary<CrewResource>) => void): void => {
    const mutatedMonths: Dictionary<Dictionary<CrewResourceDetails>> = defaultMutatedMonths ?? {};
    const crewResourcesToMutate: Dictionary<CrewResource> = {};
    crewResources.forEach(crewResource => {
        emptyMonths.forEach(emptyMonth => {
            const dateStr = emptyMonth.toISOString();
            if (crewResource.id 
                && mutatedMonths[crewResource.id] && 
                dateStr in mutatedMonths[crewResource.id]) {
                return;
            }
            if (crewResource.id 
                && monthlyCrewResourceDetails[crewResource.id] && 
                !(dateStr in monthlyCrewResourceDetails[crewResource.id])) {

                const crewResourceDetails = monthlyCrewResourceDetails[crewResource.id][dateStr];
                if (!(crewResource.id in mutatedMonths)) {
                    mutatedMonths[crewResource.id] = {}
                }
                mutatedMonths[crewResource.id][dateStr] = crewResourceDetails;

                if (!(crewResource.id in crewResourcesToMutate)) {
                    crewResource.defaultCrewResourceDetails = crewResource.defaultCrewResourceDetails ?? {};
                    crewResource.monthlyCrewResourceDetails = crewResource.monthlyCrewResourceDetails ?? [];
                    crewResourcesToMutate[crewResource.id] = crewResource;
                }

                const crewResourceCopy = crewResourcesToMutate[crewResource.id]
                crewResourceCopy.monthlyCrewResourceDetails = crewResourceCopy.monthlyCrewResourceDetails ?? [];
                const newCrewResourceDetails = GetNewCrewResourceDetails(crewList, crewResourceCopy, emptyMonth);
                crewResourceCopy.monthlyCrewResourceDetails.push(newCrewResourceDetails);
            }
        });
        crewResource.monthlyCrewResourceDetails?.forEach(crewResourceDetails => {
            const dateIsDisabled = usedDates.findIndex(usedDate => datesAreOnSameMonth(crewResourceDetails.date ?? new Date(0), usedDate)) < 0;
            const addToCrewResourcesToMutate = crewResourceDetails.disabled !== dateIsDisabled;
            if (addToCrewResourcesToMutate && crewResource.id && !(crewResource.id in crewResourcesToMutate)) {
                crewResourceDetails.disabled = dateIsDisabled;
                crewResourcesToMutate[crewResource.id] = crewResource;
            }
        })
    });
    if (setDefaultMutatedMonths) {
        setDefaultMutatedMonths({...mutatedMonths});
    }
    let updateCrewResources = false;
    for (var id in crewResourcesToMutate) {
        updateCrewResources = true;
        const crewResourceCopy = crewResourcesToMutate[id];
        crewResourceContext.mutateCrewResource(crewResourceCopy, true);
        if (crewResourcesToUpdate && crewResourceCopy.id) {
            crewResourcesToUpdate[crewResourceCopy.id] = crewResourceCopy;
        }
    }
    if (updateCrewResources && setCrewResourcesToUpdate && crewResourcesToUpdate) {
        setCrewResourcesToUpdate({ ...crewResourcesToUpdate });
    }
}

export const GetNewCrewResourceDetails = (crewList: CrewList | undefined, crewResource: CrewResource, date: Date): CrewResourceDetails => {
    const newCrewResourceDetails: CrewResourceDetails = {...crewResource.defaultCrewResourceDetails, date: date};
    if (crewList?.salaryAdjustmentPercentageDate && newCrewResourceDetails.date) {
        const yearsDifference = Math.floor(diffDatesInYears(crewList.salaryAdjustmentPercentageDate, newCrewResourceDetails.date));
        if (yearsDifference >= 0) {
            newCrewResourceDetails.salary = CalculateFutureValue(newCrewResourceDetails.salary ?? 0.0, (newCrewResourceDetails.salaryAdjustmentPercentage ?? 0.0) / 100.0, yearsDifference + 1);
        }
    }
    return newCrewResourceDetails;
}