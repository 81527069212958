import { Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, IconButton, Input, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Close } from "@material-ui/icons";
import withTheme from "@material-ui/styles/withTheme";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { ChangeOrderSubContractor, ChangeOrderStatus, ActiveState } from "../../contracts/contracts";
import { getEmptyChangeOrderSubContractor } from "../../utils/changeOrderSubContractorTools";
import { Guid } from "../../utils/common-types";
import { getEmptyGuid, guidIsNullOrEmpty } from "../../utils/guidTools";
import SelectAccount from "../accountComponents/SelectAccount";
import SelectChangeOrderExpected from "../changeOrderExpectedComponents/SelectChangeOrderExpected";
import SelectContract from "../contractComponents/SelectContract";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import { DatePicker } from "../dateComponents/DatePicker";
import ConfirmDeleteDialog from "../deleteComponent/ConfirmDeleteDialog";
import SelectChangeOrderSubContractorGroup from "./SelectChangeOrderSubContractorGroup";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  listButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export interface EditChangeOrderSubContractorDialogProps {
  changeOrderSubContractor: ChangeOrderSubContractor | undefined;
  open: boolean;
  onClose: () => void;
}

const EditChangeOrderSubContractorDialog: React.FC<EditChangeOrderSubContractorDialogProps> = ({ changeOrderSubContractor, onClose, open }) => {
  const handlingNewChangeOrder = changeOrderSubContractor?.id === undefined;

  const languageContext = useLanguageContext();
  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const projectContext = useProjectContext();
  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const userRoleContext = useUserRoleContext();
  const users = userRoleContext.getUsers();

  const classes = useStyles();

  const [editedChangeOrderSubContractor, setEditedChangeOrderSubContractor] = useState<ChangeOrderSubContractor>();
  const editedChangeOrderSubContractorIsValid =
    editedChangeOrderSubContractor !== undefined && editedChangeOrderSubContractor.contractId !== undefined && editedChangeOrderSubContractor.contractId.length > 0;

  useEffect(() => {
    if (!open) return;

    if (!changeOrderSubContractor?.id) {
      const projectId = projectContext.getSelectedProject()?.id || "";
      setEditedChangeOrderSubContractor(getEmptyChangeOrderSubContractor(projectId, changeOrderSubContractor?.accountId, changeOrderSubContractor?.contractId));
    } else {
      setEditedChangeOrderSubContractor(changeOrderSubContractor);
    }
  }, [open]);

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      const mutatedChangeOrderSubContractor = { ...changeOrderSubContractor };
      mutatedChangeOrderSubContractor.state = ActiveState.INACTIVE;
      changeOrderSubContractorContext.updateChangeOrderSubContractor(mutatedChangeOrderSubContractor);
      onClose();
    }
    setOpenConfirmDeleteDialog(false);
  };

  const handleClose = (save: boolean) => {
    if (save && editedChangeOrderSubContractor) {
      changeOrderSubContractorContext.updateChangeOrderSubContractor(editedChangeOrderSubContractor);
    }
    onClose();
  };

  let title = changeOrderSubContractor?.id ? languageContext.getMessage("edit") : languageContext.getMessage("new");
  title = `${title} ${languageContext.getMessage("changeOrder")}`;

  var screenIsMobile = useWidthContext().isMobileScreen();

  return (
    <>
      <Dialog maxWidth={screenIsMobile ? "xs" : "sm"} fullWidth={true} onClose={() => handleClose(false)} open={open} disableBackdropClick={true}>
        <DialogTitle>
          {title}
          <IconButton className={classes.closeButton} onClick={() => handleClose(false)} title={languageContext.getMessage("cancel")}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid container item spacing={1} xs={!screenIsMobile ? 6 : 12} alignItems="flex-start">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={languageContext.getMessage("name")}
                  value={editedChangeOrderSubContractor?.name ?? ""}
                  onChange={(event) => {
                    if (editedChangeOrderSubContractor) {
                      editedChangeOrderSubContractor.name = event.target.value;
                      setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectChangeOrderSubContractorGroup
                  selectedChangeOrderSubContractorGroup={editedChangeOrderSubContractor?.group}
                  onSelectedChangeOrderSubContractorGroupChange={(newSelectedChangeOrderSubContractorGroup) =>
                    setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor, group: newSelectedChangeOrderSubContractorGroup })
                  }
                  freeSolo={true}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label={languageContext.getMessage("description")}
                  value={editedChangeOrderSubContractor?.changeOrderDescription ?? ""}
                  onChange={(event) => {
                    if (editedChangeOrderSubContractor) {
                      editedChangeOrderSubContractor.changeOrderDescription = event.target.value;
                      setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectAccount
                  selectedAccount={accountContext.getAccount(editedChangeOrderSubContractor?.accountId)}
                  onSelectedAccountChange={(newSelectedAccount) => {
                    if (editedChangeOrderSubContractor) {
                      editedChangeOrderSubContractor.accountId = newSelectedAccount?.id;
                      setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                    }
                  }}
                  label={`${languageContext.getMessage("account")} *`}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectContract
                  selectedContract={contractContext.getContract(editedChangeOrderSubContractor?.contractId ?? "")}
                  selectedAccount={accountContext.getAccount(editedChangeOrderSubContractor?.accountId ?? "")}
                  onSelectedContractChange={(contract) => {
                    if (editedChangeOrderSubContractor) {
                      setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor, contractId: contract?.id });
                    }
                  }}
                  label={`${languageContext.getMessage("contract")} *`}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectChangeOrderExpected
                  selectedChangeOrderExpected={changeOrderExpectedContext.getChangeOrderExpected(editedChangeOrderSubContractor?.changeOrderExpectedId ?? "")}
                  changeOrderExpectedFilter={(changeOrderExpecteds) =>
                    changeOrderExpecteds.filter(
                      (changeOrderExpected) =>
                        !guidIsNullOrEmpty(editedChangeOrderSubContractor?.accountId) &&
                        !guidIsNullOrEmpty(changeOrderExpected.accountId) &&
                        changeOrderExpected.accountId === editedChangeOrderSubContractor?.accountId
                    )
                  }
                  onSelectedChangeOrderExpectedChange={(changeOrderExpected) => {
                    if (editedChangeOrderSubContractor) {
                      setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor, changeOrderExpectedId: changeOrderExpected?.id ?? getEmptyGuid() });
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={1} xs={!screenIsMobile ? 6 : 12} alignItems="flex-start">
              <Grid item xs={12}>
                <Autocomplete
                  style={{ width: 240 }}
                  options={Object.values(ChangeOrderStatus)}
                  getOptionLabel={(status) => changeOrderSubContractorContext.convertChangeOrderStatusToString(status)}
                  value={editedChangeOrderSubContractor?.status}
                  onChange={(event, newChangeOrderStatus) => {
                    if (editedChangeOrderSubContractor) {
                      editedChangeOrderSubContractor.status = newChangeOrderStatus ?? undefined;
                      setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label={<>{languageContext.getMessage("columnStatus")}</>} />}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label={languageContext.getMessage("receivedDate")}
                  date={editedChangeOrderSubContractor?.received}
                  onChange={(event) => {
                    if (editedChangeOrderSubContractor) {
                      editedChangeOrderSubContractor.received = event;
                      setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label={languageContext.getMessage("answeredDate")}
                  date={editedChangeOrderSubContractor?.answered}
                  onChange={(event) => {
                    if (editedChangeOrderSubContractor) {
                      editedChangeOrderSubContractor.answered = event;
                      setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CurrencyInput
                  fullWidth
                  label={languageContext.getMessage("costRequirements")}
                  value={editedChangeOrderSubContractor?.costRequirements ?? 0}
                  onChange={(amount) => {
                    if (editedChangeOrderSubContractor) {
                      editedChangeOrderSubContractor.costRequirements = amount;
                    }
                    setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CurrencyInput
                  fullWidth
                  label={languageContext.getMessage("cost")}
                  value={editedChangeOrderSubContractor?.cost ?? 0}
                  onChange={(amount) => {
                    if (editedChangeOrderSubContractor) {
                      editedChangeOrderSubContractor.cost = amount;
                    }
                    setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label={languageContext.getMessage("comments")}
                value={editedChangeOrderSubContractor?.changeOrderComment ?? ""}
                onChange={(event) => {
                  if (editedChangeOrderSubContractor) {
                    editedChangeOrderSubContractor.changeOrderComment = event.target.value;
                    setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={`${languageContext.getMessage("enableProgressConsequence")}`}
                control={
                  <Checkbox
                    name="progressConsequenceEnabled"
                    checked={editedChangeOrderSubContractor?.progressConsequenceEnabled ?? false}
                    onChange={() => {
                      if (editedChangeOrderSubContractor) {
                        editedChangeOrderSubContractor.progressConsequenceEnabled = !(editedChangeOrderSubContractor.progressConsequenceEnabled ?? false);
                        setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                      }
                    }}
                  />
                }
              />
            </Grid>

            {editedChangeOrderSubContractor && editedChangeOrderSubContractor?.progressConsequenceEnabled && (
              <Grid item xs={12}>
                <TextField
                  label={`${languageContext.getMessage("progressConsequence")} ${languageContext.getMessage("description")}`}
                  multiline
                  value={editedChangeOrderSubContractor?.progressConsequenceDescription ?? ""}
                  onChange={(event) => {
                    if (editedChangeOrderSubContractor) {
                      editedChangeOrderSubContractor.progressConsequenceDescription = event.target.value;
                      setEditedChangeOrderSubContractor({ ...editedChangeOrderSubContractor });
                    }
                  }}
                  fullWidth
                />
              </Grid>
            )}
            {changeOrderSubContractor?.id && (
              <Grid item xs={12}>
                <Typography variant="body2">{`${languageContext.getMessage("lastChangedBy")} ${users.find((user) => user.id === editedChangeOrderSubContractor?.changedBy)?.name}`}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!handlingNewChangeOrder && (
            <Button onClick={handleOnClickDelete} color="primary" variant="contained">
              {languageContext.getMessage("delete")}
            </Button>
          )}
          <Button
            onClick={() => handleClose(true)}
            color="primary"
            variant="contained"
            disabled={editedChangeOrderSubContractor?.accountId === undefined || editedChangeOrderSubContractor?.contractId === undefined}
          >
            {languageContext.getMessage("save")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog title={languageContext.getMessage("doYouWantToDeleteChangeOrder")} open={openConfirmDeleteDialog} onClose={async (deleteIsConfirmed) => handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)} />
    </>
  );
};

export default withTheme(EditChangeOrderSubContractorDialog);
