import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, Forecasts, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ForecastsSubscriptionsContext {
    subscribedForecasts: Array<Forecasts>,
}

const ForecastsSubscriptionsContext = createContext<ForecastsSubscriptionsContext>(null as unknown as ForecastsSubscriptionsContext);

export const ForecastsSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedForecasts, setSubscribedForecasts] = useState<Array<Forecasts>>([]);

    const updateSubscribedForecasts = (stateDocuments: StateDocument[]) => {
        const subscribedForecasts = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.FORECASTS && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Forecasts);
        setSubscribedForecasts(subscribedForecasts);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedForecasts([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedForecasts(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const forecastsContext: ForecastsSubscriptionsContext = {
        subscribedForecasts,
    };

    return (
        <ForecastsSubscriptionsContext.Provider value={forecastsContext}>
            {children}
        </ForecastsSubscriptionsContext.Provider>
    );
}

export const useForecastsSubscriptionsContext = (): ForecastsSubscriptionsContext => {
    return useContext(ForecastsSubscriptionsContext);
}
