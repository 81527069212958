import { withTheme } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useForecastsContext } from "../../contexts/forecast/forecastsContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { Account, Forecast, HealthStatus } from "../../contracts/contracts";
import { tableIcons } from '../../ui/table-icons';
import { getPageSizeOptions, notUndefined, resolveDefaultPageSize } from "../../utils/randomTools";
import { getRenderedStatus } from "../../utils/statusToPalettes";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import DateFormat from "../dateComponents/DateFormat";
import { CustomTableStyle } from "../../utils/common-types";
import { useRememberTableScroll } from "../../hooks/useRememberTableScroll";
import { useInterimContext } from "../../contexts/interim/InterimContext";
import PercentageFormat from "../percentageComponents/PercentageFormat";

type props = {
    forecasts: (Forecast | undefined)[]
    showLastForecast?: boolean;
    isProjectForecasts?: boolean | undefined;
    isGroupForecasts?: boolean | undefined;
    onSelectedAccountChange?: (newSelectedAccount: Account | undefined) => void;
    onSelectedAccountGroupChange?: (newSelectedAccountGroup: string | undefined) => void;
}

const ForecastList: React.FC<props> = ({
    forecasts,
    showLastForecast,
    isProjectForecasts,
    isGroupForecasts,
    onSelectedAccountChange,
    onSelectedAccountGroupChange }) => {

    isProjectForecasts = isProjectForecasts ?? false;
    isGroupForecasts = isGroupForecasts ?? false;
    if (showLastForecast) {
        const lastForecast = forecasts && forecasts.length > 0 ?
            forecasts[forecasts.length - 1] : {}
        forecasts = [lastForecast];
    }

    const languageContext = useLanguageContext();
    const { getLocalization } = useMaterialTableLanguageContext();
    const themeContext = useThemeContext();
    const theme = themeContext.getTheme();
    const accountContext = useAccountContext();
    const projectContext = useProjectContext();
    const interimContext = useInterimContext();
    const forecastsContext = useForecastsContext();

    const onlyFewRowsToShow = forecasts.length <= 1;

    const widthContext = useWidthContext();
    const width = widthContext.getWidth();
    const smallWindow = widthContext.isMobileScreen();

    const [pageSize, setPageSize] = useState<number>(1);
    const tableRef = useRef(null) as any;
    useRememberTableScroll(tableRef, "forecastList");
    const pageSizeOptions = getPageSizeOptions(forecasts.length)
    useEffect(() => {
        if (pageSizeOptions.length > 1 && pageSize < pageSizeOptions[pageSizeOptions.length - 1]) {
            const newPageSize = resolveDefaultPageSize(pageSizeOptions, forecasts.length);
            tableRef.current.dataManager.changePageSize(newPageSize);
            setPageSize(newPageSize);
        }
    }, [forecasts])

    return (<>
        <MaterialTable
            tableRef={tableRef}
            icons={tableIcons}
            localization={getLocalization()}
            columns={[
                {
                    title: isGroupForecasts ? languageContext.getMessage('group') : (isProjectForecasts ? languageContext.getMessage('project') : languageContext.getMessage('account')),
                    field: isProjectForecasts ? 'projectId' : 'accountId',
                    editable: 'never',
                    hidden: false,
                    cellStyle: CustomTableStyle,
                    render: forecast => (isGroupForecasts ? forecast.accountGroup : (isProjectForecasts ?
                        projectContext.getProject(forecast.projectId)?.name :
                        accountContext.getAccount(forecast.accountId)?.name)) ?? "",
                },
                {
                    title: languageContext.getMessage('date'),
                    field: 'date',
                    editable: 'never',
                    type: 'date',
                    hidden: true,
                    cellStyle: CustomTableStyle,
                    render: forecast => <DateFormat date={forecast.date} />
                },
                {
                    title: languageContext.getMessage('finalForecast'),
                    field: 'forecast',
                    editable: 'never',
                    type: 'numeric',
                    hidden: false,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.forecast} />
                },
                {
                    title: languageContext.getMessage("accumulatedProduced"),
                    editable: "never",
                    type: "numeric",
                    hidden: !isProjectForecasts,
                    cellStyle: CustomTableStyle,
                    render: (forecast) => {
                        const projectInterimExpense = interimContext.getProjectInterimExpense(projectContext.getProject(forecast.projectId));
                        return <CurrencyFormat amount={projectInterimExpense?.accumulatedConstructionCosts} />
                    },
                },
                {
                    title: languageContext.getMessage("producedPercentageOfForecast"),
                    editable: "never",
                    type: "numeric",
                    hidden: !isProjectForecasts,
                    cellStyle: CustomTableStyle,
                    render: (forecast) => {
                        const projectInterimExpense = interimContext.getProjectInterimExpense(projectContext.getProject(forecast.projectId));
                        return <div style={{ display: "inline-flex", alignItems: "middle" }}>
                            <PercentageFormat percentage={projectInterimExpense?.producedPercentageOfForecast} />
                            {(projectInterimExpense?.producedPercentageOfForecast ?? 0.0) > 1.0 && <>
                                &nbsp;
                                {getRenderedStatus(HealthStatus.UNHEALTHY, theme)}
                            </>}
                        </div>
                    },
                },
                {
                    title: languageContext.getMessage('accountBudget'),
                    field: 'accountBudget',
                    editable: 'never',
                    type: 'numeric',
                    hidden: false,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.accountBudget} />
                },
                {
                    title: languageContext.getMessage('netAccountBudget'),
                    field: 'netAccountBudget',
                    editable: 'never',
                    type: 'numeric',
                    hidden: true,
                    cellStyle: CustomTableStyle,
                    render: forecast =>
                        <div style={{ display: 'inline-flex', alignItems: 'middle' }}>
                            <CurrencyFormat amount={forecast?.netAccountBudget} />
                            &nbsp;
                            {getRenderedStatus(forecast.status ?? HealthStatus.UNHEALTHY, theme)}
                        </div>
                },
                {
                    title: `${languageContext.getMessage('approved_plural')} ${languageContext.getMessage('changes')} ${languageContext.getMessage('landlordShort')}`,
                    field: 'changeOrders',
                    editable: 'never',
                    type: 'numeric',
                    hidden: false,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.changeOrderLandlordApprovedCosts} />
                },
                {
                    title: `${languageContext.getMessage('not_approved_plural')} ${languageContext.getMessage('changes')} ${languageContext.getMessage('landlordShort')}`,
                    field: 'changeOrdersSubContractor',
                    editable: 'never',
                    type: 'numeric',
                    hidden: true,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.changeOrderLandlordNotApprovedCosts} />
                },
                {
                    title: `${languageContext.getMessage('revised')} ${languageContext.getMessage('accountBudget')}`,
                    field: 'revisedAccountBudget',
                    editable: 'never',
                    type: 'numeric',
                    hidden: false,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.revisedAccountBudget} />
                },
                {
                    title: languageContext.getMessage('contractProcurement'),
                    field: 'contractProcurement',
                    editable: 'never',
                    type: 'numeric',
                    hidden: false,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.contractProcurement} />
                },
                {
                    title: `${languageContext.getMessage('approved_plural')} ${languageContext.getMessage('changes')} ${languageContext.getMessage('subContractorShort')}`,
                    field: 'changeOrdersSubContractor',
                    editable: 'never',
                    type: 'numeric',
                    hidden: false,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.changeOrderSubContractorApprovedCosts} />
                },
                {
                    title: `${languageContext.getMessage('not_approved_plural')} ${languageContext.getMessage('changes')} ${languageContext.getMessage('subContractorShort')}`,
                    field: 'changeOrdersSubContractor',
                    editable: 'never',
                    type: 'numeric',
                    hidden: true,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.changeOrderSubContractorNotApprovedCosts} />
                },
                {
                    title: languageContext.getMessage('contractCostScope'),
                    field: 'contractCostScope',
                    editable: 'never',
                    type: 'numeric',
                    hidden: true,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.contractCostScope} />
                },
                {
                    title: languageContext.getMessage('expectedChange'),
                    editable: 'never',
                    type: 'numeric',
                    hidden: false,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.changeOrderExpectedCosts} />
                },
                {
                    title: languageContext.getMessage('riskAdjustments'),
                    editable: 'never',
                    type: 'numeric',
                    hidden: true,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.riskAdjustedCosts} />
                },
                {
                    title: languageContext.getMessage('forecastWithSurcharge'),
                    field: 'forecastWithSurcharge',
                    editable: 'never',
                    type: 'numeric',
                    hidden: !isProjectForecasts,
                    cellStyle: CustomTableStyle,
                    render: forecast => <CurrencyFormat amount={forecast.forecastWithSurcharge} />
                },
                {
                    title: languageContext.getMessage('totalSurcharge'),
                    field: 'projectSurcharge',
                    editable: 'never',
                    type: 'numeric',
                    cellStyle: CustomTableStyle,
                    hidden: !isProjectForecasts,
                    render: forecast => <CurrencyFormat amount={forecast.projectSurcharge} />
                },
            ]}
            data={forecasts.filter(notUndefined)}
            title={''}
            onChangeRowsPerPage={(newPageSize: number) => {
                setPageSize(newPageSize);
            }}
            options={{
                padding: 'dense',
                paging: onlyFewRowsToShow ? false : true,
                search: onlyFewRowsToShow ? false : true,
                showFirstLastPageButtons: onlyFewRowsToShow ? false : true,
                pageSize: pageSize,
                pageSizeOptions: pageSizeOptions,
                exportButton: { csv: true, pdf: false },
                exportCsv: (columns, renderData) => {
                    forecastsContext.downloadForecasts(forecasts.filter(notUndefined), isProjectForecasts, isGroupForecasts);
                },
                columnsButton: true,
                emptyRowsWhenPaging: false,
                toolbarButtonAlignment: smallWindow ? 'left' : 'left',
                searchFieldAlignment: 'left',
                detailPanelColumnAlignment: 'left',
                headerStyle: { position: "sticky", top: 0, fontWeight: 'bold' },
                maxBodyHeight: 500,
            }}
            style={{
                width: width,
            }}
            actions={[
                {
                    icon: () => <NavigateNextIcon />,
                    tooltip: languageContext.getMessage('details'),
                    position: 'row',
                    hidden: !onSelectedAccountChange && !onSelectedAccountGroupChange,
                    onClick: (event, forecast) => {
                        forecast = forecast as Forecast;
                        if (forecast && onSelectedAccountChange) {
                            onSelectedAccountChange(accountContext.getAccount(forecast.accountId ?? ""));
                        }
                        if (forecast && onSelectedAccountGroupChange) {
                            onSelectedAccountGroupChange(forecast.accountGroup);
                        }
                    }
                }]}
        />
    </>
    );
}

export default withTheme(ForecastList);