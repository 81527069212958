import { Divider, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Theme, Typography, withTheme } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { ReactElement, useState } from "react";
import { useChangeOrderLandlordContext } from "../../contexts/changeOrderLandlord/changeOrderLandlordContext";
import { ChangeOrderLandlord, ChangeOrderStatus } from "../../contracts/contracts";
import ChangeOrderLandlordStatus from "./ChangeOrderLandlordStatus";
import EditChangeOrderLandlordDialog from "./EditChangeOrderLandlordDialog";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
}));

type props = {
    selectedStatus: ChangeOrderStatus | undefined;
}

const ChangeOrderLandlordMobileList: React.FC<props> = ({
    selectedStatus,
}) => {

    const classes = useStyles();

    const changeOrderLandlordContext = useChangeOrderLandlordContext();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [changeOrderLandlordToEdit, setChangeOrderLandlordToEdit] = useState<ChangeOrderLandlord>({});
    const changeOrderLandlords = changeOrderLandlordContext.getChangeOrderLandlords(selectedStatus);

    const openEditChangeOrderLandlordDialog = (changeOrderLandlordToEdit: ChangeOrderLandlord) => {
        setChangeOrderLandlordToEdit({ ...changeOrderLandlordToEdit });
        setShowDialog(true);
    };

    const onCloseChangeOrderLandlordDialog = () => {
        setShowDialog(false);
    };

    const fabs =
    {
        color: 'primary' as 'primary',
        className: classes.fab,
        icon: <AddIcon />,
        label: 'Add',
    }

    const renderChangeOrderLandlord = (changeOrderLandlord: ChangeOrderLandlord): ReactElement => {
        return (
            <ListItem key={changeOrderLandlord.id}>
                <ListItemAvatar>
                    <ChangeOrderLandlordStatus changeOrderLandlord={changeOrderLandlord} small />
                </ListItemAvatar>
                <ListItemText primary={changeOrderLandlord.name} secondary={
                    <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                    >
                        {changeOrderLandlord.changeOrderDescription?.substring(0, 50)} ...
                        <br />
                        Kost: {changeOrderLandlord.cost} NOK
                        <br />
                        Fakturert: {changeOrderLandlord.billed} NOK
                    </Typography>
                } />
            </ListItem>);
    }

    const renderDivider = (index: number) => {
        return (<Divider key={index} variant="inset" component="li" />);
    }


    return (<>
        <EditChangeOrderLandlordDialog
            changeOrderLandlord={changeOrderLandlordToEdit}
            open={showDialog}
            onClose={onCloseChangeOrderLandlordDialog} />
        <List className={classes.root}>
            {
                changeOrderLandlords.flatMap((changeOrder, index) =>
                    [renderChangeOrderLandlord(changeOrder), renderDivider(index)]
                )
            }
        </List>
        <Fab aria-label={'Add'} className={classes.fab} color={'primary'} onClick={() => openEditChangeOrderLandlordDialog({
            status: selectedStatus,
        })}>
            {<AddIcon />}
        </Fab>
    </>
    );
}

export default withTheme(ChangeOrderLandlordMobileList);