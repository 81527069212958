import { Grid, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Account, AccountType } from "../../contracts/contracts";
import AccrualAndOperationList from "../../component/accrualAndOperationComponents/AccrualAndOperationList";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useAccrualAndOperationsContext } from "../../contexts/accrualAndOperations/accrualAndOperationsContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import AccountList from "../../component/accountComponents/AccountList";

type props = {
    selectedAccount: Account | undefined;
    onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
}

const AccrualAndOperationAllView: React.FC<props> = ({
    selectedAccount,
    onSelectedAccountChange}) => {

    const urlContext = useUrlContext();
    const accountContext = useAccountContext();
    const accrualAndOperationsContext = useAccrualAndOperationsContext();
    
    const accounts = accountContext.getAccounts(AccountType.ACCRUAL);
    const accountSearch = accountContext.getAccountSearch();
    const accrualAndOperationSearch = accrualAndOperationsContext.getAccrualAndOperationsSearch();

    useEffect(() => {
        accountContext.searchAccounts(accountSearch);
        accrualAndOperationsContext.searchAccrualAndOperations(accrualAndOperationSearch);
    }, [urlContext.currentLocation, selectedAccount])

    return (<>
        <Grid container item direction="row" spacing={1}>
            <Grid item xs={12}>
                <AccountList
                    onClickAccount={(accountId) => onSelectedAccountChange(accountContext.getAccount(accountId))} 
                    accountTypeFocus={AccountType.ACCRUAL}/>
                {/* <AccrualAndOperationList 
                    accounts={accounts}
                    onSelectedAccountChange={onSelectedAccountChange} /> */}
            </Grid>
        </Grid>
    </>
    );
}

export default withTheme(AccrualAndOperationAllView);