import { Forecast } from "../../contracts/contracts";
import { capitalizeString } from "../../utils/stringTools";
import { AccountContext } from "../account/accountContext";
import { InterimContext } from "../interim/InterimContext";
import { LanguageContext } from "../language/interfaces";
import { ProjectContext } from "../project/projectContext";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

const FORECAST_LIST_TEMPLATE_FILENAME = 'aimz/template_forecast_list.xlsx'
const FORECAST_PROJECT_LIST_TEMPLATE_FILENAME = 'aimz/template_forecast_project_list.xlsx'
const FORECAST_LIST_TEMPLATE_SHEET_NAME = 'forecast_list'

export const queryTemplateEngineToProduceForecastExcelList = (
    forecasts: Forecast[],
    templateEngineQueriesContext: TemplateEngineQueriesContext,
    languageContext: LanguageContext,
    projectContext: ProjectContext,
    accountContext: AccountContext,
    interimContext: InterimContext,
    isProjectForecasts?: boolean | undefined,
    isGroupForecasts?: boolean | undefined): void => {
    const templateFilename: string = isProjectForecasts ? FORECAST_PROJECT_LIST_TEMPLATE_FILENAME : FORECAST_LIST_TEMPLATE_FILENAME;
    const templateVariables: object[] = getTemplateEngineForecastListReportVariables(forecasts, languageContext, projectContext, accountContext, interimContext, isProjectForecasts, isGroupForecasts) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, `${languageContext.getMessage('finalForecasts')}`);
}

const getTemplateEngineForecastListReportVariables = (
    forecasts: Forecast[],
    languageContext: LanguageContext,
    projectContext: ProjectContext,
    accountContext: AccountContext,
    interimContext: InterimContext,
    isProjectForecasts?: boolean | undefined,
    isGroupForecasts?: boolean | undefined): object[] => {
    const date: Date = (forecasts.length > 0 ? forecasts[0].date : undefined) ?? new Date();
    const title: string = `${languageContext.getMessage('finalForecasts')}`;
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return [{
        'tpl_name': FORECAST_LIST_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': getForecastListVariables(title, monthString, forecasts, languageContext, projectContext, accountContext, interimContext, isProjectForecasts, isGroupForecasts),
    }]
}

const getForecastListVariables = (
    title: string,
    monthString: string,
    forecasts: Forecast[],
    languageContext: LanguageContext,
    projectContext: ProjectContext,
    accountContext: AccountContext,
    interimContext: InterimContext,
    isProjectForecasts?: boolean | undefined,
    isGroupForecasts?: boolean | undefined): object => {
    let forecastType = '';
    let forecastsInput: any[] = [];
    if (isProjectForecasts) {
        forecastType = languageContext.getMessage('project');
        forecastsInput = forecasts.map(forecast => {
            return {
                ...forecast,
                name: projectContext.getProject(forecast.projectId)?.name ?? '',
                interimExpense: interimContext.getProjectInterimExpense(projectContext.getProject(forecast.projectId))
            }
        })
    }
    else if (isGroupForecasts) {
        forecastType = languageContext.getMessage('group');
        forecastsInput = forecasts.map(forecast => {
            return { ...forecast, name: forecast.accountGroup }
        })
    }
    else {
        forecastType = languageContext.getMessage('account');
        forecastsInput = forecasts.map(forecast => {
            return { ...forecast, name: accountContext.getAccount(forecast.accountId)?.name ?? '' }
        })
    }
    const listVariables = {
        'language': { forecastType: forecastType },
        'title': title,
        'date': monthString,
        'forecasts': forecastsInput,
    }
    return listVariables;
} 
