import { ChangeOrderCounterClaim } from "../../contracts/contracts";
import { capitalizeString } from "../../utils/stringTools";
import { AccountContext } from "../account/accountContext";
import { ContractContext } from "../contract/contractContext";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

const CHANGE_ORDER_COUNTER_CLAIM_LIST_TEMPLATE_FILENAME = 'aimz/template_change_order_counter_claim_list.xlsx'
const CHANGE_ORDER_COUNTER_CLAIM_LIST_TEMPLATE_SHEET_NAME = 'change_order_counter_claim_list'

export const sortChangeOrderCounterClaimByGroup = (a: ChangeOrderCounterClaim, b: ChangeOrderCounterClaim) => {
    if ((a.group ?? '') < (b.group ?? '')) { return -1; }
    if ((a.group ?? '') > (b.group ?? '')) { return 1; }
    return 0;
}

export const queryTemplateEngineToProduceChangeOrderCounterClaimExcelList = (
    changeOrderCounterClaims: ChangeOrderCounterClaim[], 
    templateEngineQueriesContext: TemplateEngineQueriesContext, 
    languageContext: LanguageContext, 
    accountContext: AccountContext, 
    contractContext: ContractContext): void => {
    const templateFilename: string = CHANGE_ORDER_COUNTER_CLAIM_LIST_TEMPLATE_FILENAME;
    const templateVariables: object[] = getTemplateEngineChangeOrderCounterClaimListReportVariables(changeOrderCounterClaims, languageContext, accountContext, contractContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, `${languageContext.getMessage('counterClaim')}`);
}

const getTemplateEngineChangeOrderCounterClaimListReportVariables = (
    changeOrderCounterClaims: ChangeOrderCounterClaim[], 
    languageContext: LanguageContext, 
    accountContext: AccountContext, 
    contractContext: ContractContext): object[] => {
    const date: Date = new Date();
    const title: string = `${languageContext.getMessage('counterClaim')}`;
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return [{
        'tpl_name': CHANGE_ORDER_COUNTER_CLAIM_LIST_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': getChangeOrderCounterClaimListVariables(title, monthString, changeOrderCounterClaims, accountContext, contractContext),
    }]
}

const getChangeOrderCounterClaimListVariables = (
    title: string, 
    monthString: string, 
    changeOrderCounterClaims: ChangeOrderCounterClaim[], 
    accountContext: AccountContext, 
    contractContext: ContractContext): object => {
    const listVariables = {
        'language': {},
        'title': title,
        'date': monthString,
        'changeOrderCounterClaims': changeOrderCounterClaims.sort(sortChangeOrderCounterClaimByGroup).map(changeOrderCounterClaim => {
            return {
                ...changeOrderCounterClaim, 
                account: accountContext.getAccount(changeOrderCounterClaim.accountId)?.name ?? '',
                contract: contractContext.getContract(changeOrderCounterClaim.contractId)?.name ?? '', 
            }
        }),
    }
    return listVariables;
} 
