const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const isDevelopment = (): boolean => {
    return development;
}

export const cloneObject = (obj: any):any => {
    return JSON.parse(JSON.stringify(obj));
}

export const sortByName = (a: string | undefined, b: string | undefined) => {
    if ((a ?? "") < (b ?? "")) { return -1; }
    if ((a ?? "") > (b ?? "")) { return 1; }
    return 0;
}