import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Interim, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";

export const MUTATE_INTERIM: DocumentNode = gql`
    mutation mutateInterim(
        $parentDocumentId: UUID!
        $monthlyInterimItems: [InterimExpenseInput]) {
    mutateInterim(
        parentDocumentId: $parentDocumentId
        monthlyInterimItems: $monthlyInterimItems) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getInterimGraphqlMutationOptions = (interim: Partial<Interim>): QueryHookOptions => {

    interim.state = interim.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {}
    options.variables = {};
    if (interim.parentDocumentId !== undefined) options.variables['parentDocumentId'] = interim.parentDocumentId
    if (interim.monthlyInterimItems !== undefined) options.variables['monthlyInterimItems'] = interim.monthlyInterimItems.map(expense => {
            return {
                date: toISODate(expense.date ?? new Date()), 
                producedNotBilled: expense.producedNotBilled,
                administrationCosts: expense.administrationCosts,
            }
        })
    return options;
}
