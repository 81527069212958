import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, ChangeOrderLandlord, ChangeOrderStatus, RelationType } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { guidIsNullOrEmpty } from "../../utils/guidTools";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useProjectContext } from "../project/projectContext";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { useUrlContext } from "../url/urlContext";
import { queryTemplateEngineToProduceChangeOrderLandlordExcelList } from "./ChangeOrderLandlordExportTools";
import { ChangeOrderLandlordMutationsContextProvider, useChangeOrderLandlordMutationsContext } from "./mutations/changeOrderLandlordMutationsContext";
import { ChangeOrderLandlordQueriesContextContext, useChangeOrderLandlordQueriesContext } from "./queries/changeOrderLandlordQueriesContext";
import { ChangeOrderLandlordSubscriptionsContextProvider, useChangeOrderLandlordSubscriptionsContext } from "./subscriptions/changeOrderLandlordSubscriptionsContext";

export interface ChangeOrderLandlordContext {
    getChangeOrderLandlordSearch: () => ChangeOrderLandlord,
    searchChangeOrderLandlords: (changeOrderLandlordSearch: Partial<ChangeOrderLandlord>) => void,
    getChangeOrderLandlordsByAccountId: (accountId?: Guid) => Array<ChangeOrderLandlord>, //Remove this if needed. Only used to get data for changeOrderLandlordMiniList
    getChangeOrderLandlordsByParentId: (parentId?: Guid) => Array<ChangeOrderLandlord>,
    getChangeOrderLandlords: (status?: ChangeOrderStatus, accountId?: Guid) => Array<ChangeOrderLandlord>,
    getChangeOrderLandlord: (id?: string) => ChangeOrderLandlord | undefined,
    updateChangeOrderLandlord:  (changeOrderLandlord: ChangeOrderLandlord, onMutated?: (mutatedChangeOrder: ChangeOrderLandlord) => void) => void,
    convertChangeOrderStatusToString: (status?: ChangeOrderStatus) => string,
    downloadChangeOrderLandlords: (changeOrderLandlords: ChangeOrderLandlord[]) => void,
}

const ChangeOrderLandlordContext = createContext<ChangeOrderLandlordContext>(null as unknown as ChangeOrderLandlordContext);

export const sortChangeOrderLandlordByName = (a: ChangeOrderLandlord, b: ChangeOrderLandlord) => {
    if ((a?.name ?? '') < (b?.name ?? '')) { return -1; }
    if ((a?.name ?? '') > (b?.name ?? '')) { return 1; }
    return 0;
}

export const sortChangeOrderLandlordByGroup = (a: ChangeOrderLandlord, b: ChangeOrderLandlord) => {
    if ((a?.group ?? '') < (b?.group ?? '')) { return -1; }
    if ((a?.group ?? '') > (b?.group ?? '')) { return 1; }
    return 0;
}

export const sortChangeOrderLandlordByDate = (a: ChangeOrderLandlord, b: ChangeOrderLandlord) => {
    if ((a?.sent ?? '') < (b?.sent ?? '')) { return -1; }
    if ((a?.sent ?? '') > (b?.sent ?? '')) { return 1; }
    return 0;
}

export const ChangeOrderLandlordContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ChangeOrderLandlordMutationsContextProvider>
            <ChangeOrderLandlordQueriesContextContext>
                <ChangeOrderLandlordSubscriptionsContextProvider>
                    <ChangeOrderLandlordSubContextProvider>
                        {children}
                    </ChangeOrderLandlordSubContextProvider>
                </ChangeOrderLandlordSubscriptionsContextProvider>
            </ChangeOrderLandlordQueriesContextContext>
        </ChangeOrderLandlordMutationsContextProvider>
    );
}

export const ChangeOrderLandlordSubContextProvider : React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const changeOrderLandlordMutationsContext = useChangeOrderLandlordMutationsContext();
    const changeOrderLandlordQueriesContext = useChangeOrderLandlordQueriesContext();
    const changeOrderLandlordSubscriptionsContext = useChangeOrderLandlordSubscriptionsContext();
    const [currentChangeOrderLandlordSearch, setCurrentChangeOrderLandlordSearch] = useState<ChangeOrderLandlord | undefined>(undefined);
    const [changeOrderLandlords, setChangeOrderLandlords] = useState<Array<ChangeOrderLandlord>>([]);

    const mergeChangeOrderLandlords = (oldChangeOrderLandlords: Array<ChangeOrderLandlord>, newChangeOrderLandlords: Array<ChangeOrderLandlord>): Array<ChangeOrderLandlord> => {
        let updatedChangeOrderLandlords = oldChangeOrderLandlords.slice();
        if (!newChangeOrderLandlords) {
            console.error(`Received undefined set of changeOrderLandlords: ${newChangeOrderLandlords}`);
            return [];
        }
        newChangeOrderLandlords.forEach(newChangeOrderLandlord => {
            if (newChangeOrderLandlord.projectId !== currentChangeOrderLandlordSearch?.projectId) {
                return;
            }
            newChangeOrderLandlord.created = new Date(newChangeOrderLandlord.created ?? 0);
            newChangeOrderLandlord.sent = new Date(newChangeOrderLandlord.sent ?? 0);
            newChangeOrderLandlord.answered = new Date(newChangeOrderLandlord.answered ?? 0);
            const index = updatedChangeOrderLandlords.findIndex(changeOrderLandlord => changeOrderLandlord.id === newChangeOrderLandlord.id);
            if (index >= 0) {
                if (newChangeOrderLandlord.state === ActiveState.ACTIVE) {
                    updatedChangeOrderLandlords[index] = newChangeOrderLandlord;
                }
                else {
                    updatedChangeOrderLandlords.splice(index, 1);
                }
            } 
            else {
                if (newChangeOrderLandlord.state === ActiveState.ACTIVE) {
                    updatedChangeOrderLandlords.push(newChangeOrderLandlord); 
                }
            }
        });
        return updatedChangeOrderLandlords.sort(sortChangeOrderLandlordByName).sort(sortChangeOrderLandlordByGroup);
    }

    const getChangeOrderLandlordSearch = (): ChangeOrderLandlord => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
        const accountId = urlState.accountId ? urlState.accountId as string : undefined;

        return {
            projectId: projectId,
            accountId: accountId,
        }
    }

    const searchChangeOrderLandlords = (changeOrderLandlordSearch: Partial<ChangeOrderLandlord>): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(changeOrderLandlordSearch, currentChangeOrderLandlordSearch);
        matched = matched && changeOrderLandlordSearch?.projectId === currentChangeOrderLandlordSearch?.projectId;
        matched = matched && changeOrderLandlordSearch?.accountId === currentChangeOrderLandlordSearch?.accountId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && !matched) {
            setCurrentChangeOrderLandlordSearch(changeOrderLandlordSearch);
            setChangeOrderLandlords([]);
        }
    }

    const getChangeOrderLandlords = (status?: ChangeOrderStatus, accountId?: Guid): Array<ChangeOrderLandlord> => {
        let resolvedChangeOrderLandlords = changeOrderLandlords;

        if (guidIsNullOrEmpty(accountId)) {
            resolvedChangeOrderLandlords = resolvedChangeOrderLandlords.filter(changeOrderLandlord => changeOrderLandlord.relationType === RelationType.PARENT);
        }
        else {
            resolvedChangeOrderLandlords = changeOrderLandlords.filter(changeOrderLandlord => changeOrderLandlord.accountId === accountId);
        }

        if (status) {
            resolvedChangeOrderLandlords = resolvedChangeOrderLandlords.filter(changeOrderLandlord => changeOrderLandlord.status === status);
        }

        return resolvedChangeOrderLandlords
    }

    const getChangeOrderLandlordsByParentId = (parentId?: Guid): Array<ChangeOrderLandlord> => {
        return changeOrderLandlords.filter(changeOrderLandlord => changeOrderLandlord.relationType === RelationType.CHILD && changeOrderLandlord.parentId === parentId);
    }

    const getChangeOrderLandlordsByAccountId = (accountId?: Guid): ChangeOrderLandlord[] => {
        if(!accountId) return []; 
        return changeOrderLandlords.filter(changeOrderLandlord => changeOrderLandlord.accountId === accountId);
    }

    const getChangeOrderLandlord = (id?: string) => {
        return changeOrderLandlords.find(changeOrderLandlord => changeOrderLandlord.id === id);
    }

    const updateChangeOrderLandlord = (changeOrderLandlord: ChangeOrderLandlord, onMutated?: (mutatedChangeOrder: ChangeOrderLandlord) => void) => {
        changeOrderLandlordMutationsContext.mutateChangeOrderLandlord(changeOrderLandlord, (documentId, variables) => {
            changeOrderLandlord = {...changeOrderLandlord, ...variables};
            changeOrderLandlord.id = documentId;
            setChangeOrderLandlords(mergeChangeOrderLandlords(changeOrderLandlords, [changeOrderLandlord]));
            if (onMutated) {
                onMutated(changeOrderLandlord);
            }
        });
    }

    const convertChangeOrderStatusToString = (status?: ChangeOrderStatus): string => {
        switch (status) {
            case ChangeOrderStatus.APPROVED: return languageContext.getMessage('approved');
            case ChangeOrderStatus.NOT_APPROVED: return languageContext.getMessage('notApproved');
            case ChangeOrderStatus.NOT_PROCESSED: return languageContext.getMessage('notProcessed');
        }
        return languageContext.getMessage('notProcessed')
    }

    const downloadChangeOrderLandlords = (changeOrderLandlords: ChangeOrderLandlord[]): void => {
        queryTemplateEngineToProduceChangeOrderLandlordExcelList(
            changeOrderLandlords, 
            templateEngineQueriesContext, 
            languageContext);
    }

    useEffect(() => {
        if (currentChangeOrderLandlordSearch) {
            changeOrderLandlordQueriesContext.queryChangeOrderLandlords(currentChangeOrderLandlordSearch);
        }
    }, [currentChangeOrderLandlordSearch]);
    
    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setChangeOrderLandlords([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setChangeOrderLandlords(mergeChangeOrderLandlords([], changeOrderLandlordQueriesContext.fetchedChangeOrderLandlords));
    }, [changeOrderLandlordQueriesContext.fetchedChangeOrderLandlords]);

    useEffect(() => {
        setChangeOrderLandlords(mergeChangeOrderLandlords(changeOrderLandlords, changeOrderLandlordSubscriptionsContext.subscribedChangeOrderLandlords));
    }, [changeOrderLandlordSubscriptionsContext.subscribedChangeOrderLandlords]);

    const changeOrderLandlordContext: ChangeOrderLandlordContext = {
        getChangeOrderLandlordSearch,
        searchChangeOrderLandlords,
        getChangeOrderLandlords,
        getChangeOrderLandlordsByAccountId,
        getChangeOrderLandlordsByParentId,
        getChangeOrderLandlord,
        updateChangeOrderLandlord,
        convertChangeOrderStatusToString,
        downloadChangeOrderLandlords,
    };

    return (
        <ChangeOrderLandlordContext.Provider value={ changeOrderLandlordContext }>
            { children }
        </ChangeOrderLandlordContext.Provider>
    );
}

export const useChangeOrderLandlordContext = (): ChangeOrderLandlordContext => {
    return useContext(ChangeOrderLandlordContext);
}
