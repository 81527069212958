import { withTheme, makeStyles, Theme, Grid, Card, CardHeader } from "@material-ui/core";
import React from "react";
import { ChangeOrderCounterClaim } from "../../contracts/contracts";
import SelectChangeOrderCounterClaim from "../../component/changeOrderCounterClaimComponents/SelectChangeOrderCounterClaim";
import ViewHeading from "../../component/viewComponents/ViewHeading";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import InvoiceList from "../../component/invoiceComponents/InvoiceList";
import { InvoiceTabs, useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { Guid } from "../../utils/common-types";
import { Dictionary } from "../../global-types";
import { useUrlContext } from "../../contexts/url/urlContext";
import ChangeOrderCounterClaimDetailCard from "../../component/changeOrderCounterClaimComponents/ChangeOrderCounterClaimDetailCard";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    button: {
        margin: theme.spacing(1),
    },
    demoGraph: {
        maxHeight: '399px',
        padding: '1em',
        marginTop: '30px',
    },
    wrapper: {
        height: '310px',
        margin: 'auto', 
    }
}));

type props = {
    selectedChangeOrderCounterClaim: ChangeOrderCounterClaim | undefined;
    onSelectedChangeOrderCounterClaimChange: (newSelectedChangeOrderCounterClaim: ChangeOrderCounterClaim | undefined) => void;
}

const ChangeOrderCounterClaimViewAll: React.FC<props> = ({
    selectedChangeOrderCounterClaim,
    onSelectedChangeOrderCounterClaimChange}) => {

    const classes = useStyles();
    const languageContext = useLanguageContext();
    const invoiceContext = useInvoiceContext();
    const menuContext = useMenuContext();
    const urlContext = useUrlContext();

    const invoices = selectedChangeOrderCounterClaim?.id ? invoiceContext.getFilteredInvoices({changeOrderId: selectedChangeOrderCounterClaim.id}) : [];

    const onClickInvoice = (invoiceId: Guid): void => {
        const urlState = urlContext.getUrlState();
        const newUrlState = {
            ...urlState,
            ...{ 'invoiceId': invoiceId },
            ...{ 'tab': InvoiceTabs.details },
        }
        menuContext.setSelectedApplicationRoute(ApplicationRouteId.Invoice, newUrlState as Dictionary<string | number | Date>)
    }

    return (<>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ViewHeading title={`${languageContext.getMessage('counterClaim')}`}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                            <SelectChangeOrderCounterClaim
                                selectedChangeOrderCounterClaim={selectedChangeOrderCounterClaim}
                                onSelectedChangeOrderCounterClaimChange={onSelectedChangeOrderCounterClaimChange} />
                        </Grid>
                    </Grid>
                </ViewHeading>
            </Grid>
        </Grid>
        {selectedChangeOrderCounterClaim &&
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ChangeOrderCounterClaimDetailCard
                        invoices={invoices}
                        selectedChangeOrderCounterClaim={selectedChangeOrderCounterClaim} />
                </Grid>
                <Grid item xs={12}>
                    <InvoiceList 
                        invoices={invoices} 
                        onClickInvoice={onClickInvoice} 
                    />
                </Grid>
            </Grid>
        }
    </>
    );
}

export default withTheme(ChangeOrderCounterClaimViewAll);