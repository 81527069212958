import { withTheme } from "@material-ui/core";
import React from "react";
import ProjectDashboard from "../component/projectComponents/ProjectDashboard";

const HomeView: React.FC<{}> = () => {
  return (
    <ProjectDashboard />
  );
}

export default withTheme(HomeView);