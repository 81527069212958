import { Theme } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import ApplicationAppBar from './component/applicationComponents/ApplicationAppBar';
import ApplicationAppFooter from './component/applicationComponents/ApplicationAppFooter';
import ApplicationDrawer from './component/applicationComponents/ApplicationDrawer';
import { useMenuContext, ApplicationRoute, ApplicationRouteId } from './contexts/menu/menuContext';
import Background from './views/Background';
import AboutView from './views/about/AboutView';
import ProjectForecastViewer from './component/projectComponents/ProjectForecastViewer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexShrink: 1,
    flexGrow: 1,
    overflowY: "auto",
  }
}));

const App: React.FC = () => {
  const classes = useStyles();
  const menuContext = useMenuContext();
  
  const homeRoute = menuContext.getApplicationRouteById(ApplicationRouteId.Home);
  const PrivateRoute = ({Component: Component, applicationRoute, ...rest}: {
    [x: string]: any;
    Component: any;
    applicationRoute: ApplicationRoute}) => {
    return (
      <Route
        {...rest}
        render={(props) => !applicationRoute.hidden
          ? <Component {...props} />
          : <AboutView />}
      />
    )
  }

  return (
    <Background className={classes.root}>
      <ApplicationAppBar/>
      <ApplicationDrawer className={classes.content}>
        <Switch>
          {menuContext.applicationRoutes.map((applicationRoute) => {
            return <PrivateRoute
              key={applicationRoute.id}
              exact
              path={applicationRoute.route}
              Component={applicationRoute.component}
              applicationRoute={applicationRoute} />
          })}
          <Redirect path={'*'} to={homeRoute.route} />
        </Switch>
      </ApplicationDrawer>
      <ApplicationAppFooter/>
      <ProjectForecastViewer />
    </Background>
  );
}

export default withTheme(App);