import { SubscriptionOptions } from "apollo-client";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { Ticket } from "../../../contracts/contracts";

export const SUBSCRIBE_TICKET: DocumentNode = gql`
    subscription tickets(
            $authorization: String
            $tenantId: String
            $id: UUID
            $parentDocumentId: UUID
            $parentDocumentType: DocumentType
        ) {
        ticketSubscription(
            authorization: $authorization
          	tenantId: $tenantId
            id: $id
            parentDocumentId: $parentDocumentId
            parentDocumentType: $parentDocumentType
        ) {
            id
            parentDocumentId
            parentDocumentType
            processTag
        }
    }
`;

export const getTicketsGraphqlSubscriptionOptions = (authorization: string, ticket: Ticket, tenantId: string): SubscriptionOptions => {

    const options: SubscriptionOptions = {
        query: SUBSCRIBE_TICKET,
        variables: {},
        fetchPolicy: 'network-only',
    };
    options.variables = {};
    if (authorization !== undefined) options.variables['authorization'] = authorization;
    if (tenantId !== undefined) options.variables['tenantId'] = tenantId;
    if (ticket.id !== undefined) options.variables['id'] = ticket.id;
    if (ticket.parentDocumentId !== undefined) options.variables['parentDocumentId'] = ticket.parentDocumentId;
    if (ticket.parentDocumentType !== undefined) options.variables['parentDocumentType'] = ticket.parentDocumentType;

    return options;
}