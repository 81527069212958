import { format as dateFnsFormat } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import React from 'react';

type props = {
    date: Date | undefined;
    format?: string;
    showMonth?: boolean;
    showYear?: boolean;
    showDatetime?: boolean;
}

export const DateFormatString = (dateFormatProps: props): string => {
    let date = dateFormatProps.date;
    let format = dateFormatProps.format;
    let showMonth = dateFormatProps.showMonth ?? false;
    let showYear = dateFormatProps.showYear ?? false;
    let showDatetime = dateFormatProps.showDatetime ?? false;

    if (showMonth && showYear && !format) {
        format = "MM/yyyy";
    }
    else if (showMonth && !format) {
        format = "MM";
    }
    else if (showYear && !format) {
        format = "yyyy";
    }
    else if (showDatetime && !format) {
        format = "dd/MM/yyyy - HH:mm:ss";
        date = date !== undefined ? utcToZonedTime(date, 'Europe/Berlin') : date;
    }
    format = format ?? "dd/MM/yyyy";
    return date !== undefined ? dateFnsFormat(date, format) : '';
}

const DateFormat: React.FC<props> = (dateFormatProps) => {
    return (<>{DateFormatString(dateFormatProps)}</>)
}

export default DateFormat;