import React, { createContext, useContext, useEffect, useState } from "react";
import { Invoice } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_INVOICE, getInvoicesGraphqlQueryOptions } from './invoiceQueries'
import { useAuthContext } from "../../auth/authContext";

export interface InvoiceQueriesContext {
    fetchedInvoices: Array<Invoice>,
    queryInvoices: (invoice: Invoice) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const InvoiceQueriesContext = createContext<InvoiceQueriesContext>(null as unknown as InvoiceQueriesContext);

export const InvoiceQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedInvoices, setFetchedInvoices] = useState<Array<Invoice>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_INVOICE);

    const queryInvoices = (invoice: Invoice): void => {
        const queryOptions = getInvoicesGraphqlQueryOptions(invoice);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.invoices) {
            const newFetchedInvoices: Array<Invoice> = queryResponse.data.invoices;
            setFetchedInvoices(newFetchedInvoices);
        }
    }, [queryResponse.data]);
    
    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedInvoices([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const invoiceQueriesContext: InvoiceQueriesContext = {
        fetchedInvoices,
        queryInvoices,
        queryResponse,
    };

    return (
        <InvoiceQueriesContext.Provider value={invoiceQueriesContext}>
            {children}
        </InvoiceQueriesContext.Provider>
    );
}

export const useInvoiceQueriesContext = (): InvoiceQueriesContext => {
    return useContext(InvoiceQueriesContext);
}
