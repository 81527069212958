import React, { createContext, useContext, useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_CONTRACTS, getContractsGraphqlQueryOptions } from './contractQueries'
import { useAuthContext } from "../../auth/authContext";
import { Contract } from "../../../contracts/contracts";

export interface ContractQueriesContext {
    fetchedContracts: Contract[],
    queryContracts: (contract: Partial<Contract>) => void,
}

const ContractQueriesContext = createContext<ContractQueriesContext>(null as unknown as ContractQueriesContext);

export const ContractQueriesContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedContracts, setFetchedContracts] = useState<Contract[]>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_CONTRACTS);

    const queryContracts = (contract: Partial<Contract>): void => {
        const queryOptions = getContractsGraphqlQueryOptions(contract);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse?.data?.contracts) {
            const newFetchedContracts: Contract[] = queryResponse.data.contracts;
            setFetchedContracts(newFetchedContracts);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedContracts([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const contractQueriesContext: ContractQueriesContext = {
        fetchedContracts,
        queryContracts,
    };

    return (
        <ContractQueriesContext.Provider value={contractQueriesContext}>
            {children}
        </ContractQueriesContext.Provider>
    );
}

export const useContractQueriesContext = (): ContractQueriesContext => {
    return useContext(ContractQueriesContext);
}
