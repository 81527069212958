import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, StateDocument, CrewResource } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface CrewResourceSubscriptionsContext {
    subscribedCrewResource: Array<CrewResource>,
}

const CrewResourceSubscriptionsContext = createContext<CrewResourceSubscriptionsContext>(null as unknown as CrewResourceSubscriptionsContext);

export const CrewResourceSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedCrewResource, setSubscribedCrewResource] = useState<Array<CrewResource>>([]);

    const updateSubscribedCrewResource = (stateDocuments: StateDocument[]) => {
        const subscribedCrewResource = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.CREW_RESOURCE && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as CrewResource);
        setSubscribedCrewResource(subscribedCrewResource);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedCrewResource([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedCrewResource(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: CrewResourceSubscriptionsContext = {
        subscribedCrewResource,
    };

    return (
        <CrewResourceSubscriptionsContext.Provider value={projectContext}>
            {children}
        </CrewResourceSubscriptionsContext.Provider>
    );
}

export const useCrewResourceSubscriptionsContext = (): CrewResourceSubscriptionsContext => {
    return useContext(CrewResourceSubscriptionsContext);
}
