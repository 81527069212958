import { CircularProgress, DialogActions, DialogContent, Grid, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import withTheme from '@material-ui/styles/withTheme';
import React, { useEffect, useState } from 'react';
import { useAccountContext } from '../../contexts/account/accountContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Account, AccountType, ActiveState, CurrencyType } from '../../contracts/contracts';
import { csvToJson } from '../../utils/csvTools';
import { downloadBase64File } from '../../utils/fileTools';

const useStyles = makeStyles((theme: Theme) => ({
  selectedFile: {
    marginLeft: '0.5em'
  },
  warning: {
    color: theme.palette.error.main,
    marginRight: '0.25em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

export interface Props {
  projectId: string;
  open: boolean;
  onClose: (mutatedAccounts: Account[] | null) => void;
  multipleAccountsMutationIndex: number;
  accountType?: AccountType;
}

const ImportMultipleAccountsDialog: React.FC<Props> = ({
  projectId,
  open,
  onClose,
  multipleAccountsMutationIndex,
  accountType,
}) => {
  accountType = accountType ?? AccountType.NORMAL;
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const accountContext = useAccountContext();

  const [selectedFileName, setSelectedFileName] = useState<string>();
  const [parsedObjectSuccess, setParsedObjectSuccess] = useState<boolean>(false);
  const [parsedObjectCount, setParsedObjectCount] = useState<number>(0);
  const [parsedObjects, setParsedObjects] = useState<any[]>();

  const savingAccounts = multipleAccountsMutationIndex >= 0;
  const handleOnClickSave = () => {
    if (savingAccounts) {
      return;
    }

    let mutatedAccounts: Account[] | null = null;

    if (parsedObjects) {
      mutatedAccounts = parsedObjects.map(obj => {
        return createAccount(obj);
      }).filter(mutatedAccount => mutatedAccount?.accountNumber) as Account[];
    }

    onClose(mutatedAccounts);
  };

  const handleOnClickClose = () => {
    onClose(null);
  };

  const createAccount = (obj: any): Account | undefined => {
    const account: Account = {};

    account.state = ActiveState.ACTIVE;
    account.created = new Date();
    account.projectId = projectId;

    account.accountType = accountType;
    account.name = obj.name;
    account.accountNumber = obj.accountNumber;
    account.externalId = obj.accountNumber;
    account.accountGroup = obj.accountGroup;
    try {
      account.budget = parseFloat(((obj.budget ?? '0.0') as string).replaceAll(',', '.'));
      if (isNaN(account.budget ?? 0)) {
        account.budget = undefined;
      }
    } catch (error) {
      console.log(error);
    }

    if ((account.accountNumber ?? '').trim().length === 0) {
      return undefined;
    }

    const existingAccount = accountContext.getAllAccounts().find(existingAccount => (existingAccount.accountNumber ?? '').trim() === (account.accountNumber ?? '').trim());
    if (existingAccount?.id) {
      account.id = existingAccount.id;
      account.accountType = existingAccount.accountType ?? accountType;
    }

    return account;
  };

  const onChangeFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target as HTMLInputElement).files?.item(0);
    if (file) {
      setSelectedFileName(file.name);

      var oFReader = new FileReader();
      oFReader.onload = (event: any) => {
        const parsedObjects = csvToJson(event.target.result);

        if (parsedObjects) {
          setParsedObjects(parsedObjects);
          setParsedObjectSuccess(true);
          setParsedObjectCount(parsedObjects.length);
        }

      };
      oFReader.readAsBinaryString(file);
    }
  };

  const downloadAccountsExampleFile = () => {
    let csvAccounts = 'name;accountGroup;accountNumber;budget\n';
    const accounts = accountContext.getAllAccounts().slice();
    if (accounts.length === 0) {
      for (let i = 0; i < 3; i++) {
        const demoAccount: Account = {};
        demoAccount.name = `Demo account ${i}`;
        demoAccount.accountGroup = `Demo group ${i}`;
        demoAccount.accountNumber = `${i}234`;
        demoAccount.budget = i + 1000.10;
        accounts.push(demoAccount);
      }
    }
    accounts.forEach(account => {
      csvAccounts += `${account.name ?? ''};${account.accountGroup ?? ''};${account.accountNumber ?? ''};${account.budget ?? ''}\n`;
    })
    const csvContent = 'data:text/csv;charset=utf-8,' + csvAccounts;
    downloadBase64File(undefined, csvContent, 'account-definition-demo.csv');
  }

  useEffect(() => {
    if (open && !savingAccounts) {
      setParsedObjects(undefined);
      setSelectedFileName(undefined);
      setParsedObjectSuccess(false);
      setParsedObjectCount(0);
    }
  }, [open])

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleOnClickClose}
        open={open}
        disableBackdropClick={true}>
        <DialogTitle>
          {languageContext.getMessage('importAccounts')}
          <IconButton className={classes.closeButton} onClick={handleOnClickClose} title={languageContext.getMessage('cancel')}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid>
              <p>{languageContext.getMessage('uploadCsvFileWithAccountDefinitions')}</p>
              <p>{languageContext.getMessage('exampleFileMayBeDownloadedFromHere')}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={downloadAccountsExampleFile}
                  style={{ marginLeft: 10 }}
                >
                  <GetAppIcon />
                </Button>
              </p>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={onChangeFileInput} />
                <Button
                  disabled={savingAccounts}
                  color='primary'
                  variant='contained'
                  component="span" >
                  {languageContext.getMessage('selectFile')}
                </Button>
                {selectedFileName && <span className={classes.selectedFile}>{selectedFileName}</span>}
                {!selectedFileName && <span className={classes.selectedFile}>{languageContext.getMessage('noFileSelected')}</span>}
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {savingAccounts && <p>{`${languageContext.getMessage('saving')} ${languageContext.getMessage('account').toLowerCase()} ${multipleAccountsMutationIndex + 1}/${parsedObjects?.length ?? 0}`}</p>}
          {savingAccounts && <CircularProgress size={15} />}
          <Button onClick={handleOnClickSave} color='primary' variant='contained' disabled={!parsedObjectSuccess || savingAccounts}>
            {parsedObjectSuccess && `${languageContext.getMessage('save')} ${parsedObjectCount} ${languageContext.getMessage('accounts')}`}
            {!parsedObjectSuccess && `${languageContext.getMessage('save')}`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withTheme(ImportMultipleAccountsDialog);