import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

const LightTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#1F2833',
        color: 'white',
        WebkitTextFillColor: 'white'
      }
    }
  },
  palette: {
    primary: {
      main: '#007370',
      light: '#79d4cf',
      dark: '#45A29E',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#79d4cf',
      light: '#63a4ff',
      dark: '#004ba0',
      contrastText: '#ffffff'
    },
    background: {
      paper: '#F2F2F2',
      default: '#E5E6E7'
    },
    error: {
      main: '#ff4e50',
    },
    success: {
      main: '#31d655',
    },
    warning: {
      main: '#f9d62e',
    },
    info: {
      main: '#0086ad',
    },
  },
});

export default LightTheme;