export const noMap = {
  ok: "OK",
  about: "Om",
  account: "Konto",
  accountType: "Kontotype",
  accountBudget: "Kontobudsjett",
  accountDetails: "Kontodetaljer",
  accountGroup: "Kontogruppe",
  changeOrderGroup: "Gruppe",
  accountInvoicedAmount: "Fakturert: Konto",
  accountList: "Kontoliste",
  accountNumber: "Kontonummer",
  accountProcurement: "Kontoinnkjøp",
  accounts: "Kontoer",
  accountTransactionList: "Transaksjoner",
  action: "Handling",
  accessControl: "Tilgangsstyring",
  ACTIVE: "Aktiv",
  addAccount: "Legg til konto",
  addInvoice: "Legg til faktura",
  addContract: "Legg til kontrakt",
  adjustment: "Justering",
  adjustments: "Justeringer",
  accumulated: "Akkumulerte",
  all: "Alle",
  amount: "Beløp",
  withheldAmount: "Innestående beløp",
  quantity: "Antall",
  analytics: "Oversikt",
  analysis: "Analyse",
  analyticsView: "Dette er Oversikt",
  and: "og",
  expectedDate: "Forventet Dato",
  answeredDate: "Svar Dato",
  approved_plural: "Godkjente",
  not_approved_plural: "Ikke Godkjente",
  approved: "Godkjent",
  rejected: "Avvist",
  approvedCost: "Godkjent kostnad",
  ASSISTANT_PROJECT_MANAGER: "Prosjektlederassistent",
  accrual: "Periodisering",
  accumulatedAccruedPosted: "Akkumulert Påløpt bokført",
  receivedRegistered: "Mottaksregistrert",
  revisedForecastIsAboveAccountForecast: "Revidert prognose er over konto prognosen",
  revisedForecastIsAboveConstructionAdministrationCosts: "Revidert prognose er over byggeadministrasjonskostnader",
  producedNotBilled: "Produsert - ikke fakturert",
  accumulatedProduced: "Akkumulert produsert verdi",
  constructionCosts: "Rigg, byggekostnader og prosjektering",
  constructionStart: "Byggestart",
  administrationCosts: "Administrasjonskostnader",
  producedPercentageOfForecast: "Produsert % av prognose",
  producedPercentageOfFinalForecast: "Produsert % av sluttprognose",
  sumProducedValue: "Sum produsert verdi",
  surcharge: "Påslag",
  balance: "Disponibelt",
  billed: "Fakturert",
  budget: "Budsjett",
  burndown: "Nedjusteringer",
  cancel: "Avbryt",
  change: "Endre",
  changeOrder: "Endringsordre",
  changeOrderBilled: "Kostnad",
  changeOrderBudget: "Endringsordrebudsjett",
  changeOrderProcurement: "Endringsordreinnkjøp",
  changeOrderShort: "EM",
  changeOrders: "Endringsordre",
  quantityChangeOrders: "Antall Endringsordre",
  accrualAndOperations: "Periodisering",
  changes: "Endringer",
  clear: "Tøm",
  close: "Lukk",
  columnActorCount: "Aktører",
  columnName: "Navn",
  columnOwnerCount: "Eiere",
  columnStatus: "Status",
  comment: "Kommentar",
  comments: "Kommentarer",
  completion: "Ferdigstillelse",
  company: "Neate AS",
  companySite: "https://neate.no/",
  consequence: "Konsekvens",
  contract: "Kontrakt",
  contractBudget: "Kontraksbudsjett",
  contractor: "Entreprenør",
  contractOrChange: "Kontrakt/Endring",
  contractProcurement: "Kontraktsinnkjøp",
  contracts: "Kontrakter",
  contractCosts: "Kontraktskostnader",
  contractDetails: "Kontraktsdetaljer",
  constructionAdministrationBudget: "Budsjett for byggeadministrasjon",
  cost: "Kostnad",
  costs: "Kostnader",
  costWithSurcharge: "Kostnad inkl. påslag",
  costRequirements: "Kostnadskrav",
  created: "Opprettet",
  crew: "Bemanning",
  crewList: "Bemanningsplan",
  currentLanguage: "Norsk",
  dark: "Mørk",
  date: "dato",
  days: "Dager",
  delete: "Slett",
  description: "Beskrivelse",
  detailed: "Detaljert",
  details: "Detaljer",
  scannedDate: "Skannet Dato",
  voucherDate: "Bilagsdato",
  doYouWantToDeleteFile: "Vil du slette filen?",
  doYouWantToDeleteProject: "Vil du slette prosjektet?",
  doYouWantToDeleteAccount: "Vil du slette kontoen?",
  doYouWantToDeleteInvoice: "Vil du slette fakturaen?",
  doYouWantToDeleteContract: "Vil du slette kontrakten?",
  doYouWantToDeleteChangeOrder: "Vil du slette endringsordren?",
  doYouWantToDeleteExpectedChange: "Vil du slette det forventede tillegget?",
  doYouWantToDeleteRisk: "Vil du slette risikoen?",
  doYouWantToDeleteElement: "Vil du slette elementet?",
  downloadForecastReport: "Last ned prognose rapport",
  dueDate: "Forfall",
  interimAccountedDate: "Kontert dato",
  interimAccountedAmount: "Kontert beløp",
  email: "Email",
  edit: "Rediger",
  editProject: "Rediger prosjekt",
  editAccount: "Rediger konto",
  editAccountGroup: "Rediger kontogruppe",
  editContract: "Rediger kontrakt",
  editChangeOrder: "Rediger endringsordre",
  editChangeOrderGroup: "Rediger gruppe",
  newInvoice: "Ny faktura",
  editInvoice: "Rediger faktura",
  childInvoice: "Underfaktura",
  parentInvoice: "Topfaktura",
  enableProgressConsequence: "Aktiver fremdriftskonsekvens",
  externalName: "Leverandør",
  expectation: "Forventning",
  enabled: "Aktivert",
  finished: "Fullført",
  final: "Endelig",
  finalForecast: "Sluttprognose",
  finalForecasts: "Sluttprognoser",
  forecastReport: "Prognose Rapport",
  forecast: "Prognose",
  forecasts: "Prognoser",
  changesInForecastLastMonth: "Endringer i sluttprognosen sist måned",
  from: "Fra",
  fromDate: "Fra dato",
  group: "Gruppe",
  groupDetails: "Gruppedetaljer",
  general: "Generelt",
  global: "Global",
  hello: "Neate demo",
  home: "Hjem",
  homeView: "Dette er Hjem",
  HSE_MANAGER: "HMS ansvarlig",
  id: "Id",
  INACTIVE: "Inaktiv",
  interim: "Interim",
  incoming: "Innkommende",
  includeContractProcurementInForecast: "Bruk kontraktsinnkjøp i prognose?",
  warningContractProcurementIsLargerThenBudget: "Kontraktsinnkjøpet er større enn kontraktsbudsjettet, og vil derfor bli brukt i prognosen.",
  invoice: "Faktura",
  invoiceComplete: "Behandlet",
  invoiceRejected: "Avvist",
  invoicedAmount: "Fakturert",
  remainingInvoicedAmount: "Resterende beløp",
  invoicedAmountIsGreaterThanProcurment: "Fakturert beløp er større en innkjøp",
  invoiceIsPartOfAnotherInvoice: "Fakturaen er en del av en annen faktura",
  invoiceIsSplitIntoMultipleInvoicesAndCanNotBeEdited: "Fakturaen er delt opp i flere faktura og kan ikke bli redigert",
  invoiceMissingComment: "Ubehandlet",
  invoiceNumber: "Fakturanummer",
  invoices: "Fakturaer",
  importAccounts: "Importer kontoer",
  inTotal: "Totalt",
  info: "Info",
  ITB_MANAGER: "ITB-ansvarlig",
  landlord: "Byggherre",
  landlordShort: "BH",
  language: "Språk",
  last: "Siste",
  light: "Lys",
  list: "Liste",
  listHeading: "Eiendomsliste",
  listView: "Dette er Liste",
  login: "Logg inn",
  logout: "Logg ut",
  max: "Max",
  missingComment: "Mangler kommentar",
  month: "Måned",
  monthly: "Månedlig",
  monthlySalary: "Månedlige Lønnskostnader",
  monthlyPercentage: "Månedlig Prosentandel",
  aMonth: "Måned",
  name: "Navn",
  netAccountBudget: "Rest. Kontobudsjett",
  networkError: "Nettverksfeil: Vi har problemer med å koble til server",
  new: "Ny",
  newProject: "Nytt prosjekt",
  newAccount: "Ny konto",
  newContract: "Ny Kontrakt",
  none: "Ingen",
  showNonInterimAccountedInvoices: "Det finnes fakturaer som ikke er kontert!",
  showInterimAccountedInvoices: "Vis fakturaer som er kontert",
  showPostedInvoices: "Vis bokførte fakturaer",
  showInvoices: "Vis fakturaer",
  notApproved: "Avvist",
  notProcessed: "Uavklart",
  notSelected: "Ikke valgt",
  notSpecified: "Ikke spesifisert",
  selectProject: "Velg prosjekt",
  selectTenant: "Tast inn din organisasjon",
  wrongTenant: "Organisasjon eksisterer ikke!",
  reader: "Leser",
  writer: "Skriver",
  admin: "Admin",
  owner: "Eier",
  administration: "Administrasjon",
  normal: "Normal",
  OPPORTUNITY: "Mulighet",
  opportunityAdjustments: "Mulighetsjusteringer",
  outgoing: "Utgående",
  overview: "Oversikt",
  payments: "Betalinger",
  probability: "Sannsynlighet",
  PROBABILITY: "Sannsynlighet",
  procurement: "Innkjøp",
  productName: "Aimz",
  profile: "Min profil",
  project: "Prosjekt",
  projectInformation: "Prosjektinformasjon",
  projectList: "Prosjektliste",
  projectStart: "Prosjektstart",
  projectEnd: "Prosjektslutt",
  projectName: "Prosjektnavn",
  projectNumber: "Prosjektnummer",
  projectCode: "Prosjektkode",
  projectCodes: "Prosjektkoder",
  accountCode: "Kontokode",
  accountCodes: "Kontokoder",
  property: "Eiendom",
  progressConsequence: "Fremdriftskonsekvens",
  per: "per",
  percentage: "Prosent",
  percentShare: "Prosentandel",
  receivedDate: "Mottatt Dato",
  sentDate: "Sendt Dato",
  remainingAccountBudget: "Rest. kontobudsjett",
  remainingAmount: "Gjenstående beløp",
  remainingWithheldAmount: "Gjenstående innestående beløp",
  remainingCost: "Ufakturert",
  remainingExpectedCost: "Gjenstående Kostnad",
  remainingContractBudget: "Rest. Kontraksbudsjett",
  remainingContractProcurement: "Rest. kontraktsinnkjøp",
  remainingProcurement: "Rest. innkjøp",
  reset: "Reset",
  revised: "Revidert",
  revisedForecast: "Revidert prognose",
  revisedAccountBudget: "Revidert kontobudsjett",
  RISK: "Risiko",
  riskAdjustments: "Risikojusteringer",
  role: "Rolle",
  salary: "Lønn",
  save: "Lagre",
  saving: "Lagrer",
  saveInvoice: "Lagre faktura",
  settings: "Innstillinger",
  signedInAs: "Pålogget som:",
  signIn: "Logg inn",
  signOut: "Logg ut",
  SITE_MANAGER: "Anleggsleder",
  splitInvoice: "Del faktura",
  starting: "fra",
  state: "Status",
  subContractor: "Underentreprenør",
  subContractorShort: "UE",
  subContractorCosts: "Underentreprenørkostnader",
  subProjects: "Underprosjekter",
  counterClaim: "Motkrav",
  counterClaimShort: "MK",
  newCounterClaim: "Nytt Motkrav",
  sum: "Sum",
  sumChangeOrderCost: "Kostnad: endringsordre",
  sumChangeOrderInvoicedAmount: "Fakturert: endringsordre",
  sumContractBudget: "Kontraktsbudsjett",
  sumContractProcurement: "Kontraktsinnkjøp",
  sumContractInvoicedAmount: "Fakturert: kontrakt",
  sumChangeOrderRemainingCost: "Ufakturert: endringsordre",
  sumRemainingContractBudget: "Rest. kontraktsbudsjett",
  contractCostScope: "Kontraktsomfang",
  sumExpenseForecasts: "Sum utgiftsposter",
  sumContractCostScope: "Sum Kontraktsomfang",
  IncurredCosts: "Påløpte kostnader",
  supplier: "Leverandør",
  simulating: "Simulerer",
  forecastWithSurcharge: "Sluttprognose inkl. påslag",
  totalSurcharge: "Totalt Påslag",
  theme: "Tema",
  this: "Denne",
  time: "Tid",
  title: "Tittel",
  to: "Til",
  toDate: "Til dato",
  today: "I dag",
  toggle: "Toggle",
  totalCosts: "Totalkostnader",
  transactions: "Transaksjoner",
  type: "Type",
  uploadImage: "Last opp bilde",
  downloadImage: "Last ned bilde",
  uncertainty: "Usikkerhet",
  unkown: "Ukjent",
  unprocessed: "Ubehandlet",
  unforeseen: "Uforutsett",
  adjustmentsRisksAndUnforeseen: "Justeringer",
  up: "Opp",
  expenseItem: "Utgiftspost",
  remainsToDistribute: "Gjenstår å fordele",
  newExpectedChange: "Nytt forventet tillegg",
  expectedChange: "Forventet tillegg",
  expectedPostedExpense: "Forventet kostnad",
  expectedAccumulatedPostedExpense: "Forventet akkumulert kostnad",
  actuallyPostedExpense: "Faktisk påløpt",
  actuallyAccumulatedPostedExpense: "Faktisk akkumulert påløpt",
  variance: "Varians",
  usedWithForecast: "Med i prognose",
  useRemainingAmount: "Bruk gjenstående beløp",
  username: "Brukernavn",
  value: "Verdi",
  week: "Uken",
  with: "med",
  yes: "Ja",
  yesterday: "I går",
  next: "Neste",
  back: "Tilbake",
  document: "Dokument",
  QUANTITIES: "Mengder",
  PROJECT_CHIEF: "Prosjektsjef",
  PROJECT_MANAGER: "Prosjektleder",
  PROJECT_MANAGER_ASSISTANT: "Prosjektlederassistent",
  CONSTRUCTION_MANAGER: "Anleggsleder",
  OPERATIONS_MANAGER: "Driftsleder",
  PROJECT_SECRETARY: "Prosjektsekretær",
  ENVIRONMENTAL_MANAGER: "Miljøleder",
  HEALTH_SAFETY_AND_ENVIRONMENT_LEADER: "HMS-leder",
  ENGINEERING_MANAGER: "Prosjekteringsleder",
  TECHNICAL_ENGINEERING_MANAGER: "Teknisk Prosjekteringsleder",
  INTEGRATED_TECHNICAL_BUILDING_INSTALLATIONS_RESPONSIBLE: "ITB-ansvarlig",
  CONTROLLER: "Controller",
  PURCHASER: "Innkjøper",
  OTHER: "Annet",
  disabled: "Deaktivert",
  showAllMonths: "Vis alle måneder",
  selectFile: "Velg fil",
  noFileSelected: "Ingen fil valgt",
  uploadCsvFileWithAccountDefinitions: "Last opp csv fil med konto definisjoner.",
  exampleFileMayBeDownloadedFromHere: "Eksempelfil kan lastes ned her:",
  yearlyWageGrowth: "Årlig lønnsvekst",
  monthForWageGrowth: "Måned for lønnsvekst",
  yearForWageGrowth: "Startår for lønnsvekst",
  flags: "Flagg",
  UNKNOWN_VAT: "Ukjent MVA",
  UNKNOWN_CURRENCY: "Ukjent valuta",
  EXTERNAL_ID_MISSING_WITH_PROVIDER: "Faktura slettet i fakturasystem",
  youHaveAdded: "Du har lagt til",
  someFilesCouldNotBeImported: "Noen filer kunne ikke importeres.",
  allFilesWhereSuccessfullyImported: "Alle filer er importert!",
  filesAreStillUploading: "Filer er i opplasting. Er du sikker på at du vil avslutte?",
  couldNotImportFile: "Kunne ikke importere fil",
  dueTo: "fordi",
  dragAndDropFiles: "Dra og slipp dokumenter eller bilder her eller klikk for å legge til",
  uploadFiles: "Last opp filer",
  dropFiles: "Dropp filer",
  importingFiles: "Importerer filer",
  importFinished: "Importering fullført",
  routeInformation: "Rute informasjon",
  chosen: "Valgt",
  choices: "Valg",
  selected: "Valgt",
  files: "Filer",
  file: "Fil",
  confirm: "Bekreft",
  invoicedTotal: 'Totalt fakturert',
  payedOut: 'Utbetalt',
  whereAsWithheld: 'Hvorav innestående',
  notes: 'Notater',
  notesSaved: 'Notater lagret',
  updated: 'Oppdatert',
  forecastLong: 'Sluttprognose',
  summary: 'Sammendrag',
  showForecastOnly: 'Vis kun prognose',
  changedBy: 'Endret av',
  report: 'Rapport',
  APPROVED: 'Godkjent',
  NOT_APPROVED: 'Ikke godkjent',
  NOT_PROCESSED: 'Uavklart',
  changeLog: 'Endringslogg',
  changeType: 'Type',
  timeStamp: 'Tidspunkt',
  documentType: 'Dokument type',
  CHANGE: 'Endring',
  CREATE: 'Opprettet',
  PROJECT: 'Prosjekt',
  ACCOUNT: 'Konto',
  CONTRACT: 'Kontrakt',
  INVOICE: 'Faktura',
  CHANGE_ORDER_SUB_CONTRACTOR: 'Endringsordre underentreprenør',
  CHANGE_ORDER_LANDLORD: 'Endringsordre byggherre',
  CHANGE_ORDER_EXPECTED: 'Forventet tillegg',
  CHANGE_ORDER_COUNTER_CLAIM: 'Motkrav',
  INTERIM: 'Interim',
  CREW_LIST: 'Bemanningsplan',
  CREW_RESOURCE: 'Bemanning',
  ACCRUAL_AND_OPERATIONS: 'Periodisering',
  ACCRUAL_AND_OPERATIONS_EXPENSES: 'Periodisering kostnad',
  FORECASTS: 'Sluttprognose',
  GENERAL: 'GENERAL',
  contractDescription: 'Kontraktsbeskrivelse',
  NOT_POSTED: 'Ikke postert',
  POSTED: 'Postert',
  PARTLY_POSTED: 'Delvis postert',
  accountId: 'Konto',
  contractId: 'Kontrakt',
  postedStatus: 'Postering status',
  NOT_DISTRIBUTED: 'Ikke distribuert',
  DISTRIBUTED: 'Distribuert',
  RETURNED: 'Returnert',
  REJECTED: 'Avvist',
  approvedStatus: 'Godkjenning status',
  changeOrderComment: 'Kommentar',
  status: 'Status',
  changeOrderDescription: 'Beksrivelse',
  answered: 'Besvart',
  expected:'Forventet',
  useContractProcurementWithForecast: 'Bruk i prognose',
  sent: 'Sendt',
  received: 'Mottatt',
  progressConsequenceEnabled: 'Fremdriftskonsekvens',
  progressConsequenceDescription: 'Fremdrifts konsekvens beskrivelse',
  defaultCrewResourceDetails: 'Detaljer',
  assignmentPercentage:'Tildelt prosent',
  salaryAdjustmentPercentage: 'Lønnsjustering',
  changeOrderExpectedId: 'Forventet tillegg',
  usedInForecast: 'Medberegnes i sluttprognose',
  monthlySalaryShort: 'Lønn per/mnd.',
  monthlyPercentageShort:'Prosent per/mnd.',
  yearlyWageGrowthShort: 'Lønnsvekst per/år',
  lastChangedBy: 'Sist endret av',
  approvedChanges: 'Godkjente endringer',
  totalInvoicedChangeOrder: 'Totalt fakturert endringsordre',
  revisedFinalForecast: 'Revidert sluttprognose',
  revisedForecastIsAboveForecast: 'Revidert sluttprognose er større enn prognose',
  variable: 'Variabel',
  newValue: 'Endret til',
  oldValue: 'Endret fra',
  system: 'System',
  forecastCalculationInProgress: 'Utregning pågår',
};
