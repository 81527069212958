
import React, { createContext, useContext } from "react";
import { StorageFileMutationsContextProvider } from "./mutations/storageFileMutationsContext";

export interface StorageFileContext {
}

const StorageFileContext = createContext<StorageFileContext>(null as unknown as StorageFileContext);

export const StorageFileContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <StorageFileMutationsContextProvider>
            <StorageFileSubContextProvider>
                {children}
            </StorageFileSubContextProvider>
        </StorageFileMutationsContextProvider>
    );
}

export const StorageFileSubContextProvider: React.FC<{}> = ({ children }) => {

    const storageFileContext: StorageFileContext = {
    };

    return (
        <StorageFileContext.Provider value={storageFileContext}>
            {children}
        </StorageFileContext.Provider>
    );
}

export const useStorageFileContext = (): StorageFileContext => {
    return useContext(StorageFileContext);
}