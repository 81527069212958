import { Grid, withTheme } from '@material-ui/core';
import React, {  } from 'react';
import InvoiceList from '../../component/invoiceComponents/InvoiceList';
import { Invoice } from '../../contracts/contracts';
import { Guid } from '../../utils/common-types';
import { datesAreOnSameMonth } from '../../utils/dateTools';

type props = {
    invoices: Invoice[],
    showInterimAccountedInvoices?: boolean;
    onClickInvoice?: (invoiceId: Guid) => void;
};

const InterimInvoicesView: React.FC<props> = ({
    invoices,
    showInterimAccountedInvoices,
    onClickInvoice,
}) => {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <InvoiceList 
                    invoices={invoices}
                    onClickInvoice={onClickInvoice} 
                    enableEditAccount={(invoice) => {
                        let valid = true;
                        if (showInterimAccountedInvoices && invoice.interimAccounted && invoice.interimAccountedDate !== undefined) {
                            const nowDate = new Date();
                            valid = datesAreOnSameMonth(invoice.interimAccountedDate, nowDate);
                        }
                        return valid;
                    }}
                    showAsInterim={showInterimAccountedInvoices}
                    hideChangeOrderAndContract={true}
                />
            </Grid>
        </Grid>
    );
}

export default withTheme(InterimInvoicesView);