import { Card, CardContent, GridList, GridListTile, GridListTileBar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { LanguageContext } from '../../contexts/language/interfaces';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { useStorageFileMutationsContext } from '../../contexts/storageFile/mutations/storageFileMutationsContext';
import { Project, RoleType, StorageFile, DocumentType } from '../../contracts/contracts';
import dashboard_photo_1 from '../../data/pictures/dashboard_photo_1.jpg';
import dashboard_photo_2 from '../../data/pictures/dashboard_photo_2.jpg';
import { getMinDate, getMaxDate } from '../../utils/dateTools';
import { getStorageFileKey, toBase64 } from '../../utils/fileTools';
import ContentStepper from '../generalComponents/ContentStepper';
import FileSelect from '../generalComponents/FileSelect';
import { getStorageFilesGraphqlQueryOptions, QUERY_STORAGE_FILES } from '../../contexts/storageFile/queries/storageFileQueries';
import { QueryResult, useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    //   maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    card: {
      padding: '1em'
    },
    gridList: {
      },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
  }),
);

export type ImageTile = {
  image: string;
  base64Image: string | undefined;
  title: string;
  cols: number;
  showUploadImageButton: boolean;
}
 
const resolveProjectSteps = (project: Project | undefined, languageContext: LanguageContext): [string[], number] => {
  const steps: string[] = [];
  const projectStart = project?.projectStart ?? getMinDate();
  const projectEnd = project?.projectEnd ?? getMaxDate();
  const nowDate = new Date();
  let percentageFinished = 0;
  let activeStep = 0;
  if (nowDate.getTime() > projectStart.getTime() && nowDate.getTime() < projectEnd.getTime()) {
    const totalProjectTime = projectEnd.getTime() - projectStart.getTime();
    const finishedProjectTime = nowDate.getTime() - projectStart.getTime();
    percentageFinished = (finishedProjectTime / totalProjectTime)*100;
    activeStep = 1;
  }
  else if (nowDate.getTime() >= projectEnd.getTime()) {
    percentageFinished = 100;
    activeStep = 3;
  }
  steps.push(`${languageContext.getMessage('constructionStart')} ${projectStart.toLocaleString(languageContext.getLanguage(), { day: '2-digit', month: 'long', year: 'numeric' })}`)
  steps.push(`${nowDate.toLocaleString(languageContext.getLanguage(), { day: '2-digit', month: 'long', year: 'numeric' })} - ${percentageFinished.toFixed(0)}% ${languageContext.getMessage('finished')}`)
  steps.push(`${languageContext.getMessage('completion')} ${projectEnd.toLocaleString(languageContext.getLanguage(), { day: '2-digit', month: 'long', year: 'numeric' })}`)
  return [steps, activeStep];
} 

type props = {
  project?: Project | undefined;
}

const ProjectDashboardSteps: React.FC<props> = ({project}) => {
  
  const classes = useStyles();
  const storageMutationContext = useStorageFileMutationsContext();
  const projectContext = useProjectContext();
  const languageContext = useLanguageContext();
  project = project ?? projectContext.getSelectedProject();

  const [imageTiles, setImageTiles] = useState<ImageTile[]>(
  [
    {
      image: dashboard_photo_1,
      base64Image: undefined,
      title: 'dashboard_photo_1',
      cols: 1,
      showUploadImageButton: false,
    },
    {
      image: dashboard_photo_2,
      base64Image: undefined,
      title: 'dashboard_photo_2',
      cols: 2,
      showUploadImageButton: false,
    },
  ])

  const [steps, activeStep] = resolveProjectSteps(project, languageContext);

  function handleFetchedImage(tileIndex: number, fileKey: string | undefined, queryResult: QueryResult) {
    if (queryResult.error) {
      console.log(queryResult.error);
      return;
    }
    if (queryResult.loading || !queryResult.data) {
      return;
    }
    const storageFiles: StorageFile[] = queryResult.data.storageFiles;
    const matchingStorageFile = storageFiles.find(storageFile => storageFile.key === fileKey);
    if (matchingStorageFile) {
      imageTiles[tileIndex].base64Image = matchingStorageFile.content;
      setImageTiles(imageTiles.slice());
    }
  }

  const dashboardTileIndex1 = 0;
  const dashboardPhoto1StorageFile: StorageFile = {key: getStorageFileKey(DocumentType.PROJECT, project?.id ?? '', imageTiles[dashboardTileIndex1].title)}
  const queryResultDashboardPhoto1 = useQuery(QUERY_STORAGE_FILES, getStorageFilesGraphqlQueryOptions(dashboardPhoto1StorageFile));
  useEffect(() => {
    handleFetchedImage(dashboardTileIndex1, dashboardPhoto1StorageFile.key, queryResultDashboardPhoto1);
  }, [queryResultDashboardPhoto1.loading, queryResultDashboardPhoto1.error, queryResultDashboardPhoto1.data])
  
  const dashboardTileIndex2 = 1;
  const dashboardPhoto2StorageFile: StorageFile = {key: getStorageFileKey(DocumentType.PROJECT, project?.id ?? '', imageTiles[dashboardTileIndex2].title)}
  const queryResultDashboardPhoto2 = useQuery(QUERY_STORAGE_FILES, getStorageFilesGraphqlQueryOptions(dashboardPhoto2StorageFile));
  useEffect(() => {
    handleFetchedImage(dashboardTileIndex2, dashboardPhoto2StorageFile.key, queryResultDashboardPhoto2);
  }, [queryResultDashboardPhoto2.loading, queryResultDashboardPhoto2.error, queryResultDashboardPhoto2.data])

  useEffect(() => {
    queryResultDashboardPhoto1.fetchMore(getStorageFilesGraphqlQueryOptions(dashboardPhoto1StorageFile));
    queryResultDashboardPhoto2.fetchMore(getStorageFilesGraphqlQueryOptions(dashboardPhoto2StorageFile));
  }, [projectContext.getSelectedProject()])

  async function onUploadImage(newImageFiles: File[], imageTile: ImageTile, tileIndex: number) {
    if (project?.id === undefined) {
      return;
    }
    for (let i = 0; i < newImageFiles.length; i++) {
      const base64ImageFile = await toBase64(newImageFiles[i]);
      const storageFile: StorageFile = {
        key: getStorageFileKey(DocumentType.PROJECT, project.id, imageTile.title),
        content: base64ImageFile
      }
      storageMutationContext.mutateStorageFile(storageFile).then(storageFiles => {
        const fileKey = getStorageFileKey(DocumentType.PROJECT, project?.id ?? '', imageTile.title);
        const matchingStorageFile = storageFiles.find(storageFile => storageFile.key === fileKey);
        if (matchingStorageFile) {
          imageTiles[tileIndex].base64Image = matchingStorageFile.content;
          setImageTiles(imageTiles.slice());
        }
      });
    }
  }

  return (<>
    <Card className={classes.card}>
      <div className={classes.root}>
        <GridList cellHeight={160} className={classes.gridList} cols={3}>
          {imageTiles.map((imageTile, tileIndex) => (
            <GridListTile key={imageTile.title} cols={imageTile.cols || 1}
            onMouseEnter={() => {
              imageTiles[tileIndex].showUploadImageButton = true;
              setImageTiles(imageTiles.slice());
            }}
            onMouseLeave={() => {
              imageTiles[tileIndex].showUploadImageButton = false;
              setImageTiles(imageTiles.slice());
            }}
            >
              <img src={imageTile.base64Image ?? imageTile.image} alt={imageTile.title} />
              {projectContext.hasProjectAccess(RoleType.WRITER) && imageTile.showUploadImageButton && <GridListTileBar
                actionIcon={
                  <FileSelect 
                    inputId={`fileSelect_${imageTile.title}`}
                    onSelectedFiles={newImageFiles => onUploadImage(newImageFiles, imageTile, tileIndex)} 
                    buttonTitle={languageContext.getMessage('uploadImage')}
                    fileAcceptance={'image/jpeg,image/png'} />
                }
              />}
            </GridListTile>
          ))}
        </GridList>
      </div>
      <CardContent>
        <div className={classes.alignItemsAndJustifyContent}>
          <Typography gutterBottom variant="h4">
            {project?.name}
          </Typography>
        </div>
        <div>
          <ContentStepper steps={steps} activeStep={activeStep} />
        </div>
      </CardContent>
    </Card>
  </>);
}

export default ProjectDashboardSteps