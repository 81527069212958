import { DialogActions, FormControl, TextField, MenuItem, InputLabel, useTheme, Select, Input, InputAdornment, DialogContent, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import withTheme from '@material-ui/styles/withTheme';
import React, { useEffect } from 'react';
import { useAccrualAndOperationsExpensesContext } from '../../contexts/accrualAndOperationsExpenses/accrualAndOperationsExpensesContext';
import { useContractContext } from '../../contexts/contract/contractContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { AccrualAndOperationsExpenses, CurrencyType } from '../../contracts/contracts';
import CurrencyInput from '../currencyComponents/CurrencyInput';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
}));


function getStyles(subject: string, subjectName: string[], theme: Theme) {
    return {
      fontWeight:
        subjectName.indexOf(subject) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

export interface NewAccrualAndOperationsExpensesDialogProps {
    accrualAndOperationsExpenses: AccrualAndOperationsExpenses | undefined,
    open: boolean;
    onClose: (saved: boolean) => void;
}

const NewAccrualAndOperationsExpensesDialog: React.FC<NewAccrualAndOperationsExpensesDialogProps> = (props: NewAccrualAndOperationsExpensesDialogProps) => {
    const languageContext = useLanguageContext();
    const accrualAndOperationsExpensesContext = useAccrualAndOperationsExpensesContext();
    const contractContext = useContractContext();
    const projectContext = useProjectContext();

    const classes = useStyles();
    const { accrualAndOperationsExpenses, onClose, open } = props;
    const theme = useTheme();

    const [editedAccrualAndOperationsExpenses, setEditedAccrualAndOperationsExpenses] = React.useState<AccrualAndOperationsExpenses | undefined>({});

    useEffect(() => {
      const projectId = accrualAndOperationsExpenses?.projectId ?? projectContext.getSelectedProject()?.id;
      setEditedAccrualAndOperationsExpenses({...accrualAndOperationsExpenses, projectId: projectId});
    }, [accrualAndOperationsExpenses])

    const handleClose = async (save: boolean) => {
      if (save && editedAccrualAndOperationsExpenses) {
        await accrualAndOperationsExpensesContext.updateAccrualAndOperationsExpenses(editedAccrualAndOperationsExpenses);
      }
      onClose(save);
    };

    const title = `${languageContext.getMessage('new')} ${languageContext.getMessage('expenseItem')}`;

    return (
        <Dialog onClose={async () => await handleClose(false)} open={open}>
            <DialogTitle>
              {title}
              <IconButton className={classes.closeButton} onClick={async () => await handleClose(false)} title={languageContext.getMessage('cancel')}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent></DialogContent>
            <FormControl className={classes.formControl}>
                <TextField
                    label={languageContext.getMessage('name')}
                    value={editedAccrualAndOperationsExpenses?.name ?? ''}
                    onChange={(event) => {
                      if (editedAccrualAndOperationsExpenses) {
                        editedAccrualAndOperationsExpenses.name = event.target.value;
                        setEditedAccrualAndOperationsExpenses({...editedAccrualAndOperationsExpenses});
                      }
                    }}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
              <CurrencyInput
                label={languageContext.getMessage('finalForecast')}
                value={editedAccrualAndOperationsExpenses?.forecast ?? 0}
                onChange={amount => {
                  if (editedAccrualAndOperationsExpenses) {
                    editedAccrualAndOperationsExpenses.forecast = amount;
                  }
                  setEditedAccrualAndOperationsExpenses({...editedAccrualAndOperationsExpenses});
                }
              }
              />
            </FormControl>
            <DialogActions>
              <Button onClick={async () => await handleClose(true)} color="primary" variant="contained">
                {languageContext.getMessage('save')}
              </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme(NewAccrualAndOperationsExpensesDialog);