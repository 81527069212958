import { useLanguageContext } from "../../contexts/language/LanguageContext";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { StorageFile } from "../../contracts/contracts";
import StorageFileList from "./StorageFileList";

type props = {
  files: StorageFile[];
  open: boolean;
  onClose: () => void;
  onDeletedFile: (deletedFile: StorageFile) => void;
};

const StorageFileDialog: React.FC<props> = ({ files, open, onClose, onDeletedFile }) => {
  const languageContext = useLanguageContext();

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Filer</DialogTitle>
      <DialogContent>
        <StorageFileList files={files} onDeletedFile={onDeletedFile} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{languageContext.getMessage("cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StorageFileDialog;
