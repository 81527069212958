import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, makeStyles, Switch, Tab, Tabs, Theme, Toolbar, Typography, withTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { Account, Interim, DocumentType, Invoice, HealthStatus, InvoiceApprovedStatus } from "../../contracts/contracts";
import ViewHeading from "../../component/viewComponents/ViewHeading";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useInterimContext } from "../../contexts/interim/InterimContext";
import { DatePickerWithPushAndPop } from "../../component/dateComponents/DatePickerWithPushAndPop";
import InterimAllView from "./InterimAllView";
import InvoiceDetails from "../../component/invoiceComponents/InvoiceDetails";
import InterimInvoicesView from "./InterimInvoicesView";
import { InvoiceTabs, useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import InvoicesFilterTab from "../../component/invoiceComponents/InvoicesFilterTab";
import { datesAreOnSameMonth, getEndOfMonth } from "../../utils/dateTools";
import { getRenderedStatus } from "../../utils/statusToPalettes";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { downloadInterimReport } from "../../utils/interimTools";
import { useTemplateEngineQueriesContext } from "../../contexts/templateEngine/queries/templateEngineQueriesContext";
import { GetApp } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: "1em",
  },
  titleSection: {
    margin: theme.spacing(3, 2),
  },
  dateRangePickerSection: {
    margin: theme.spacing(3, 2, 0),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const InterimView: React.FC<{}> = () => {
  const urlContext = useUrlContext();
  const languageContext = useLanguageContext();
  const classes = useStyles();
  const invoiceContext = useInvoiceContext();
  const accountContext = useAccountContext();
  const interimContext = useInterimContext();
  const contractContext = useContractContext();
  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
  const templateEngineQueriesContext = useTemplateEngineQueriesContext();
  const projectContext = useProjectContext();
  const ticketContext = useTicketContext();
  const themeContext = useThemeContext();
  const menuContext = useMenuContext();

  const urlState = urlContext.getUrlState();
  const tabIndex = urlState.tab ? (urlState.tab as string) : "all";
  const theme = themeContext.getTheme();

  const accountSearch = accountContext.getAccountSearch();
  const interimSearch = interimContext.getInterimSearch();
  const invoiceSearch = invoiceContext.getInvoiceSearch();
  const selectedInvoiceId = urlState.invoiceId ? (urlState.invoiceId as string) : null;
  const selectedParentDocumentId = urlState.parentDocumentId ? (urlState.parentDocumentId as string) : undefined;
  const urlStateShowProcessedInvoices = (urlState.showInterimAccountedInvoices ? (urlState.showInterimAccountedInvoices as string) : "false") === "true";

  const [showInterimAccountedInvoices, setShowProcessedInvoices] = useState(urlStateShowProcessedInvoices);
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  interimSearch.toDate = interimSearch.fromDate;

  const changeOrderSubContractorSearch = changeOrderSubContractorContext.getChangeOrderSubContractorSearch();

  const setDefaultSearchDates = (): void => {
    let defaultSearchIsUpdated = false;
    if (!interimSearch.fromDate && (!interimSearch.fromDateIsEmpty || tabIndex === "all")) {
      interimSearch.fromDate = new Date();
      interimSearch.fromDate = new Date(interimSearch.fromDate.setDate(1));
      defaultSearchIsUpdated = true;
    }
    if (defaultSearchIsUpdated) {
      updateInterimSearch(interimSearch);
    }
  };

  useEffect(() => {
    setDefaultSearchDates();
  });

  useEffect(() => {
    ticketContext.setDocumentTypesToWatch([
      DocumentType.ACCOUNT,
      DocumentType.INTERIM,
      DocumentType.INVOICE,
      DocumentType.CONTRACT,
      DocumentType.CHANGE_ORDER_SUB_CONTRACTOR,
      DocumentType.CHANGE_ORDER_LANDLORD,
    ]);
    accountContext.searchAccounts(accountSearch);
    interimContext.searchInterim(interimSearch, false);
    invoiceContext.searchInvoices(invoiceSearch);
    contractContext.searchContracts(contractContext.getContractSearch());
    changeOrderSubContractorContext.searchChangeOrderSubContractors(changeOrderSubContractorSearch);
  }, [urlContext.currentLocation, projectContext.getSelectedProject()]);

  const updateUrlState = (newInterimSearch: Interim, newTabIndex: string, additionalStates?: object): void => {
    newInterimSearch = { ...newInterimSearch };
    const newUrlState = {
      ...urlState,
      ...newInterimSearch,
      ...additionalStates,
      ...{ tab: newTabIndex },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const handleTabIndexChange = (event: React.ChangeEvent<{}> | null, newTabIndex: string) => {
    updateUrlState(interimSearch, newTabIndex);
  };

  const filterInvoicesOnInterimAccountedDates = (filteredInvoices: Invoice[]): void => {
    setInvoices(
      filteredInvoices.filter((filteredInvoice) => {
        let valid = filteredInvoice.approvedStatus !== InvoiceApprovedStatus.REJECTED && filteredInvoice.approvedStatus !== InvoiceApprovedStatus.RETURNED;
        if (valid && filteredInvoice.voucherDate && interimSearch.toDate) {
          const toDate = getEndOfMonth(interimSearch.toDate);
          valid = filteredInvoice.voucherDate.getTime() <= toDate.getTime();
        }
        return valid;
      })
    );
  };

  const updateInterimSearch = (newInterimSearch: Interim): void => {
    newInterimSearch.fromDateIsEmpty = newInterimSearch.fromDate ? false : true;
    newInterimSearch.toDateIsEmpty = newInterimSearch.toDate ? false : true;
    newInterimSearch.toDate = newInterimSearch.fromDate;
    updateUrlState(newInterimSearch, tabIndex);
  };

  const onSelectedAccountChange = (newSelectedAccount: Account | undefined, newTab: string): void => {
    interimSearch.parentDocumentId = newSelectedAccount?.id;
    const additionalStates = {
      selectedAccountIdFilter: newSelectedAccount?.id,
    };
    updateUrlState(interimSearch, newTab, additionalStates);
  };

  const onSelectedInvoiceChange = (newSelectedInvoiceId: string | undefined, newTab: string): void => {
    updateUrlState(interimSearch, newTab, { invoiceId: newSelectedInvoiceId });
  };

  const tabHeadingTitle = `${languageContext.getMessage("interim")}`;
  const nonInterimAccountedInvoicesExists = interimContext.countNonInterimAccountedInvoices() > 0 ? HealthStatus.UNHEALTHY : HealthStatus.HEALTHY;

  const tabHeadingElement = (
    <div style={{ display: "inline-flex", alignItems: "middle", justifyContent: "flex-end" }}>
      {tabHeadingTitle}
      &nbsp;
      {getRenderedStatus(nonInterimAccountedInvoicesExists, theme)}
      &nbsp;
      {nonInterimAccountedInvoicesExists === HealthStatus.UNHEALTHY && (
        <Typography gutterBottom variant="caption">
          {languageContext.getMessage("showNonInterimAccountedInvoices")}
        </Typography>
      )}
    </div>
  );

  const popDateMonth = () => {
    const defaultDate = interimSearch.fromDate;
    const oldDate = new Date(defaultDate ?? new Date());
    const newDate = new Date(oldDate.setMonth(oldDate.getMonth() - 1));
    interimSearch.fromDate = newDate;
    updateInterimSearch(interimSearch);
  };

  const pushDateMonth = () => {
    const defaultDate = interimSearch.fromDate;
    const oldDate = new Date(defaultDate ?? new Date());
    const newDate = new Date(oldDate.setMonth(oldDate.getMonth() + 1));
    interimSearch.fromDate = newDate;
    updateInterimSearch(interimSearch);
  };

  const handleSetShowInterimAccountedInvoices = (showInterimAccountedInvoices: boolean) => {
    const urlState = urlContext.getUrlState();
    const newUrlState = {
      ...urlState,
      ...{ showInterimAccountedInvoices: showInterimAccountedInvoices.toString() },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery);
    setShowProcessedInvoices(showInterimAccountedInvoices);
  };

  const handleShowPostedInvoices = () => {
    const urlState = urlContext.getUrlState();
    if (urlState.fromDate) {
      const oldDate = new Date(urlState.fromDate ?? new Date());
      const newDate = new Date(oldDate.setMonth(oldDate.getMonth() + 1));
      urlState.toDate = newDate;
      urlState.fromDate = undefined;
    }
    const newUrlState = {
      ...urlState,
      ...{ tab: InvoiceTabs.processed },
    };
    menuContext.setSelectedApplicationRoute(ApplicationRouteId.Invoice, newUrlState as Dictionary<string | number | Date>);
  };

  let datePickerTitle = `${languageContext.getMessage("interim")} ${languageContext.getMessage("month")}`;
  if (tabIndex === "invoices") {
    datePickerTitle = languageContext.getMessage("voucherDate");
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
          <Tabs value={tabIndex} onChange={handleTabIndexChange}>
            <Tab label={languageContext.getMessage("all")} value="all" />
            <Tab label={languageContext.getMessage("invoices")} value="invoices" />
            <Tab label={languageContext.getMessage("details")} value="details" />
          </Tabs>
        </Toolbar>
      </Grid>
      <Grid item xs={12}>
        <ViewHeading title={tabHeadingElement}>
          <Grid container wrap="nowrap" spacing={2} alignItems="flex-end">
            {tabIndex !== "details" && (
              <Grid item>
                <DatePickerWithPushAndPop
                  label={datePickerTitle}
                  date={interimSearch.fromDate}
                  monthPicker={true}
                  yearPicker={true}
                  onChange={(newDate) => {
                    if (newDate) {
                      newDate = new Date(newDate.setDate(1));
                    }
                    interimSearch.fromDate = newDate;
                    updateInterimSearch(interimSearch);
                  }}
                  onPopDate={() => popDateMonth()}
                  onPushDate={() => pushDateMonth()}
                />
              </Grid>
            )}
            {tabIndex === "all" && (
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    downloadInterimReport(templateEngineQueriesContext, languageContext, interimContext, accountContext, interimSearch.fromDate);
                  }}
                  startIcon={<GetApp />}
                >
                  {languageContext.getMessage("accountList")}
                </Button>
              </Grid>
            )}
            {tabIndex === "invoices" && (
              <>
                <Grid item>
                  <FormControl size="small">
                    <FormControlLabel
                      control={
                        <Checkbox
                          // size="small"
                          checked={showInterimAccountedInvoices}
                          onChange={(event) => {
                            const newShowInterimAccountedInvoices: boolean = event.target.checked;
                            handleSetShowInterimAccountedInvoices(newShowInterimAccountedInvoices);
                          }}
                        />
                      }
                      label={languageContext.getMessage("showInterimAccountedInvoices")}
                      labelPlacement="end"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={() => handleShowPostedInvoices()}>
                    {languageContext.getMessage("showPostedInvoices")}
                  </Button>
                </Grid>
                <Grid item>
                  <InvoicesFilterTab
                    onInvoicesUpdate={(filteredInvoices) => filterInvoicesOnInterimAccountedDates(filteredInvoices)}
                    showApprovedInvoices={false}
                    showInterimAccountedInvoices={showInterimAccountedInvoices}
                    skipFromDateFilter={true}
                    skipToDateFilter={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </ViewHeading>
      </Grid>
      <Grid item xs={12}>
        {tabIndex === "all" && <InterimAllView onSelectedAccountChange={(newSelectedAccount) => onSelectedAccountChange(newSelectedAccount, "invoices")} />}
        {tabIndex === "invoices" && (
          <InterimInvoicesView
            invoices={invoices}
            showInterimAccountedInvoices={showInterimAccountedInvoices}
            onClickInvoice={(newSelectedInvoiceId) => onSelectedInvoiceChange(newSelectedInvoiceId, "details")}
          />
        )}
        {tabIndex === "details" && (
          <InvoiceDetails selectedInvoiceId={selectedInvoiceId} onChangeSelectedInvoice={(newSelectedInvoiceId) => onSelectedInvoiceChange(newSelectedInvoiceId, "details")} showAsInterim={true} />
        )}
      </Grid>
    </Grid>
  );
};

export default withTheme(InterimView);
