import { Grid, Theme, withTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChartOptions } from 'chart.js';
import React from 'react';
import BarGraph from "../../component/graphComponents/BarGraph";
import DoughnutGraph from "../../component/graphComponents/DoughnutGraph";
import LineGraph from "../../component/graphComponents/LineGraph";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import StackGraphView from './graphs/StackGraphView';
import BarGraphNivoView from './graphs/BarGraphNivoView';
import LineGraphNivoView from './graphs/LineGraphNivoView';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 800,
  },
}));

const GraphTestView: React.FC<{}> = () => {
  const classes = useStyles();
  const { getMessage } = useLanguageContext();

  return (<>
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} sm={6}>
        <LineGraph chartComponentProps={{data: data1, height: 400}} chartOptions={options1} chartTitle={'test'} chartSubHeader={'sub header test'} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BarGraph chartComponentProps={{data: data1, height: 400}} chartOptions={options1} chartTitle={'test'} chartSubHeader={'sub header test'} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DoughnutGraph chartComponentProps={{data: data1, height: 400}} chartOptions={options1} chartTitle={'test'} chartSubHeader={'sub header test'} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <StackGraphView></StackGraphView>
      </Grid>
      <Grid item xs={12} sm={12} style={{height: '25%'}}>
        <BarGraphNivoView></BarGraphNivoView>
      </Grid>
      <Grid item xs={12} sm={12} style={{height: '25%'}}>
        <LineGraphNivoView></LineGraphNivoView>
      </Grid>
    </Grid>
  </>
  );
}

export default withTheme(GraphTestView);

const data1 = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [{
    label: 'Underentreprenør',
    backgroundColor: '#FF6384',
    borderColor: '#FF6384',
    fill: false,
    data: [
      0,
      13,
      16,
      30,
      42,
      62,
      96
    ],
  }, {
    label: 'Byggherre',
    backgroundColor: '#36A2EB',
    borderColor: '#36A2EB',
    fill: false,
    data: [
      2,
      6,
      12,
      19,
      26,
      29,
      45
    ],

  }, {
    label: 'Total',
    backgroundColor: '#FFCE56',
    borderColor: '#FFCE56',
    fill: false,
    data: [
      2,
      19,
      28,
      49,
      68,
      105,
      140
    ],

  }]

};

// Test data

let options1: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
  }
};