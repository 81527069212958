import React, { createContext, useContext, useEffect, useState } from "react";
import { ChangeOrderExpected } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_CHANGE_ORDER_EXPECTED, getChangeOrderExpectedsGraphqlQueryOptions } from './changeOrderExpectedQueries'
import { useAuthContext } from "../../auth/authContext";

export interface ChangeOrderExpectedQueriesContext {
    fetchedChangeOrderExpecteds: Array<ChangeOrderExpected>,
    queryChangeOrderExpecteds: (changeOrderExpected: ChangeOrderExpected) => void,
}

const ChangeOrderExpectedQueriesContext = createContext<ChangeOrderExpectedQueriesContext>(null as unknown as ChangeOrderExpectedQueriesContext);

export const ChangeOrderExpectedQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedChangeOrderExpecteds, setFetchedChangeOrderExpecteds] = useState<Array<ChangeOrderExpected>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_CHANGE_ORDER_EXPECTED);

    const queryChangeOrderExpecteds = (changeOrderExpected: ChangeOrderExpected): void => {
        const queryOptions = getChangeOrderExpectedsGraphqlQueryOptions(changeOrderExpected);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.changeOrderExpecteds) {
            const newFetchedChangeOrderExpecteds: Array<ChangeOrderExpected> = queryResponse.data.changeOrderExpecteds;
            setFetchedChangeOrderExpecteds(newFetchedChangeOrderExpecteds);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedChangeOrderExpecteds([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const changeOrderExpectedQueriesContext: ChangeOrderExpectedQueriesContext = {
        fetchedChangeOrderExpecteds,
        queryChangeOrderExpecteds,
    };

    return (
        <ChangeOrderExpectedQueriesContext.Provider value={changeOrderExpectedQueriesContext}>
            {children}
        </ChangeOrderExpectedQueriesContext.Provider>
    );
}

export const useChangeOrderExpectedQueriesContext = (): ChangeOrderExpectedQueriesContext => {
    return useContext(ChangeOrderExpectedQueriesContext);
}
