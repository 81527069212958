import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, CrewResource, CrewResourceRole } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useProjectContext } from "../project/projectContext";
import { useUrlContext } from "../url/urlContext";
import { receiveCrewResource } from "./crewResourceTools";
import { CrewResourceMutationsContextProvider, useCrewResourceMutationsContext } from "./mutations/crewResourceMutationsContext";
import { CrewResourceQueriesContextProvider, useCrewResourceQueriesContext } from "./queries/crewResourceQueriesContext";
import { CrewResourceSubscriptionsContextProvider, useCrewResourceSubscriptionsContext } from "./subscriptions/crewResourceSubscriptionsContext";

export interface CrewResourceContext {
    getCrewResourceSearch: () => CrewResource,
    searchCrewResource: (crewpPlanSearch: CrewResource, force: boolean) => void,
    crewResource: CrewResource[],
    getCrewResource: (crewResourceId: Guid | undefined) => CrewResource | undefined,
    getCrewResources: () => CrewResource[],
    mutateCrewResource: (crewResource: CrewResource, updateCacheOnly?: boolean) => Promise<void>,
    convertCrewResourceRoleToString: (crewResourceRole?: CrewResourceRole) => string,
}

const CrewResourceContext = createContext<CrewResourceContext>(null as unknown as CrewResourceContext);

const sortCrewplanByName = (a: CrewResource, b: CrewResource) => {
    if ((a.name ?? '') < (b.name ?? '')) { return -1; }
    if ((a.name ?? '') > (b.name ?? '')) { return 1; }
    return 0;
}

export const CrewResourceContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <CrewResourceMutationsContextProvider>
            <CrewResourceQueriesContextProvider>
                <CrewResourceSubscriptionsContextProvider>
                    <CrewResourceSubContextProvider>
                            {children}
                    </CrewResourceSubContextProvider>
                </CrewResourceSubscriptionsContextProvider>
            </CrewResourceQueriesContextProvider>
        </CrewResourceMutationsContextProvider>
    );
}

export const CrewResourceSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const crewResourceMutationsContext = useCrewResourceMutationsContext();
    const crewResourceQueriesContext = useCrewResourceQueriesContext();
    const crewResourceSubscriptionsContext = useCrewResourceSubscriptionsContext();
    const [currentCrewResourceSearch, setCurrentCrewResourceSearch] = useState<CrewResource | undefined>(undefined);
    const [crewResources, setCrewResources] = useState<CrewResource[]>([]);

    const mergeCrewResource = (oldCrewResource: CrewResource[], newCrewResource: CrewResource[]): CrewResource[] => {
        let updatedCrewResource = oldCrewResource.slice();
        newCrewResource.forEach(newCrewResource => {
            if (newCrewResource.projectId !== currentCrewResourceSearch?.projectId) {
                return;
            }
            const index = updatedCrewResource.findIndex(contract => contract.id === newCrewResource.id);
            newCrewResource = receiveCrewResource(currentCrewResourceSearch, newCrewResource);
            if (index >= 0) {
                if (newCrewResource.state === ActiveState.ACTIVE) {
                    updatedCrewResource[index] = newCrewResource;
                }
                else {
                    updatedCrewResource.splice(index, 1);
                }
            } 
            else {
                if (newCrewResource.state === ActiveState.ACTIVE) {
                    updatedCrewResource.push(newCrewResource); 
                }
            }
        });
        
        return updatedCrewResource.sort(sortCrewplanByName);
    }

    const getCrewResourceSearch = (): CrewResource => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
        const fromDate = urlState.fromDate ? new Date(urlState.fromDate as string) : undefined;
        const toDate = urlState.toDate ? new Date(urlState.toDate as string) : undefined;
    
        return {
            projectId: projectId,
            fromDate: fromDate,
            toDate: toDate,
        }
    }

    const searchCrewResource = (crewResourceSearch: CrewResource, force: boolean): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(crewResourceSearch, currentCrewResourceSearch);
        matched = matched && crewResourceSearch?.projectId === currentCrewResourceSearch?.projectId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && (!matched || force)) {
            setCurrentCrewResourceSearch(crewResourceSearch);
            setCrewResources([]);
        }
    }

    const getCrewResource = (crewResourceId: Guid | undefined): CrewResource | undefined => {
        return crewResources.find(crewResource => crewResource.id === crewResourceId);
    }

    const getCrewResources = (): CrewResource[] => {
        const projectId = projectContext.getSelectedProject()?.id
        if (!projectId) {
            return crewResources;
        }
        return crewResources.filter(crewResource => crewResource.projectId === projectId);
    }

    const convertCrewResourceRoleToString = (crewResourceRole?: CrewResourceRole): string => {
        return crewResourceRole ? languageContext.getMessage(crewResourceRole) : '';
    }

    const mutateCrewResource = (crewResource: CrewResource, updateCacheOnly?: boolean): Promise<void> => {
        if (updateCacheOnly && crewResource?.id) {
            setCrewResources(mergeCrewResource(crewResources, [crewResource]));
            return Promise.resolve();
        }
        return crewResourceMutationsContext.mutateCrewResource(crewResource, (documentId, variables) => {
            crewResource = {...crewResource, ...variables};
            crewResource.id = documentId;
            setCrewResources(mergeCrewResource(crewResources, [crewResource]));
        });
    }

    useEffect(() => {
        if (currentCrewResourceSearch) {
            crewResourceQueriesContext.queryCrewResource(currentCrewResourceSearch);
        }
    }, [currentCrewResourceSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setCrewResources([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        if(!crewResourceQueriesContext.fetchedCrewResource) return;
        setCrewResources(mergeCrewResource(crewResources, crewResourceQueriesContext.fetchedCrewResource));
    }, [crewResourceQueriesContext.fetchedCrewResource]);

    
    useEffect(() => {
        setCrewResources(mergeCrewResource(crewResources, crewResourceSubscriptionsContext.subscribedCrewResource));
    }, [crewResourceSubscriptionsContext.subscribedCrewResource]);

    const crewResourceContext: CrewResourceContext = {
        getCrewResourceSearch,
        searchCrewResource,
        crewResource: crewResources,
        getCrewResource,
        getCrewResources,
        mutateCrewResource,
        convertCrewResourceRoleToString,
    };

    return (
        <CrewResourceContext.Provider value={crewResourceContext}>
            {children}
        </CrewResourceContext.Provider>
    );
}

export const useCrewResourceContext = (): CrewResourceContext => {
    return useContext(CrewResourceContext);
}
