import { Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, IconButton, Input, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import withTheme from "@material-ui/styles/withTheme";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { ChangeOrderExpected, ChangeOrderStatus, ActiveState } from "../../contracts/contracts";
import { getEmptyChangeOrderExpected } from "../../utils/changeOrderExpectedTools";
import { getEmptyGuid } from "../../utils/guidTools";
import SelectAccount from "../accountComponents/SelectAccount";
import SelectContract from "../contractComponents/SelectContract";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import { DatePicker } from "../dateComponents/DatePicker";
import ConfirmDeleteDialog from "../deleteComponent/ConfirmDeleteDialog";
import SelectChangeOrderExpectedGroup from "./SelectChangeOrderExpectedGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  listButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export interface EditChangeOrderExpectedDialogProps {
  changeOrderExpected: ChangeOrderExpected | undefined;
  open: boolean;
  onClose: () => void;
}

const EditChangeOrderExpectedDialog: React.FC<EditChangeOrderExpectedDialogProps> = ({ changeOrderExpected, onClose, open }) => {
  const handlingNewChangeOrder = changeOrderExpected?.id === undefined;

  const languageContext = useLanguageContext();
  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const projectContext = useProjectContext();
  const userRoleContext = useUserRoleContext();
  const users = userRoleContext.getUsers();

  const classes = useStyles();

  const [editedChangeOrderExpected, setEditedChangeOrderExpected] = useState<ChangeOrderExpected>();
  const editedChangeOrderExpectedIsValid = editedChangeOrderExpected !== undefined && editedChangeOrderExpected.accountId !== undefined && editedChangeOrderExpected.accountId.length > 0;

  useEffect(() => {
    if (!open) return;

    if (!changeOrderExpected?.id) {
      const projectId = projectContext.getSelectedProject()?.id || "";
      setEditedChangeOrderExpected(getEmptyChangeOrderExpected(projectId, changeOrderExpected?.accountId, changeOrderExpected?.contractId));
    } else {
      setEditedChangeOrderExpected({ ...changeOrderExpected });
    }
  }, [open]);

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      const mutatedChangeOrderExpected = { ...changeOrderExpected };
      mutatedChangeOrderExpected.state = ActiveState.INACTIVE;
      changeOrderExpectedContext.updateChangeOrderExpected(mutatedChangeOrderExpected);
      onClose();
    }
    setOpenConfirmDeleteDialog(false);
  };

  const handleClose = (save: boolean) => {
    if (save && editedChangeOrderExpected) {
      changeOrderExpectedContext.updateChangeOrderExpected(editedChangeOrderExpected);
    }
    onClose();
  };

  let title = changeOrderExpected?.id ? `${languageContext.getMessage("edit")} ${languageContext.getMessage("expectedChange").toLowerCase()}` : languageContext.getMessage("newExpectedChange");

  var screenIsMobile = useWidthContext().isMobileScreen();

  return (
    <>
      <Dialog maxWidth={screenIsMobile ? "xs" : "sm"} fullWidth={true} onClose={() => handleClose(false)} open={open} disableBackdropClick={true}>
        <DialogTitle>
          {title}
          <IconButton className={classes.closeButton} onClick={() => handleClose(false)} title={languageContext.getMessage("cancel")}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={languageContext.getMessage("name")}
                value={editedChangeOrderExpected?.name ?? ""}
                onChange={(event) => {
                  if (editedChangeOrderExpected) {
                    editedChangeOrderExpected.name = event.target.value;
                    setEditedChangeOrderExpected({ ...editedChangeOrderExpected });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectChangeOrderExpectedGroup
                selectedChangeOrderExpectedGroup={editedChangeOrderExpected?.group}
                onSelectedChangeOrderExpectedGroupChange={(newSelectedChangeOrderExpectedGroup) =>
                  setEditedChangeOrderExpected({ ...editedChangeOrderExpected, group: newSelectedChangeOrderExpectedGroup })
                }
                freeSolo={true}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label={languageContext.getMessage("description")}
                value={editedChangeOrderExpected?.changeOrderDescription ?? ""}
                onChange={(event) => {
                  if (editedChangeOrderExpected) {
                    editedChangeOrderExpected.changeOrderDescription = event.target.value;
                    setEditedChangeOrderExpected({ ...editedChangeOrderExpected });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectAccount
                selectedAccount={accountContext.getAccount(editedChangeOrderExpected?.accountId)}
                onSelectedAccountChange={(newSelectedAccount) => {
                  if (editedChangeOrderExpected) {
                    editedChangeOrderExpected.accountId = newSelectedAccount?.id;
                    setEditedChangeOrderExpected({ ...editedChangeOrderExpected });
                  }
                }}
                label={`${languageContext.getMessage("account")} *`}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectContract
                selectedContract={contractContext.getContract(editedChangeOrderExpected?.contractId ?? "")}
                selectedAccount={accountContext.getAccount(editedChangeOrderExpected?.accountId ?? "")}
                onSelectedContractChange={(contract) => {
                  if (editedChangeOrderExpected) {
                    setEditedChangeOrderExpected({ ...editedChangeOrderExpected, contractId: contract?.id  });
                  }
                }}
              />
            </Grid>

            {/* <Grid item xs={12}>
                            <Autocomplete style={{ width: 240 }}
                                options={Object.values(ChangeOrderStatus)}
                                getOptionLabel={status => changeOrderExpectedContext.convertChangeOrderStatusToString(status)}
                                value={editedChangeOrderExpected?.status}
                                onChange={(event, newChangeOrderStatus) => {
                                    if (editedChangeOrderExpected) {
                                        editedChangeOrderExpected.status = newChangeOrderStatus ?? undefined;
                                        setEditedChangeOrderExpected({ ...editedChangeOrderExpected });
                                    }
                                }}
                                renderInput={(params) => <TextField {...params}
                                    label={<>
                                        {languageContext.getMessage('columnStatus')}
                                    </>} />
                                }
                            />
                        </Grid> */}

            <Grid item xs={12}>
              <DatePicker
                fullWidth
                label={languageContext.getMessage("expectedDate")}
                date={editedChangeOrderExpected?.expected}
                onChange={(event) => {
                  if (editedChangeOrderExpected) {
                    editedChangeOrderExpected.expected = event;
                    setEditedChangeOrderExpected({ ...editedChangeOrderExpected });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <CurrencyInput
                fullWidth
                label={languageContext.getMessage("cost")}
                value={editedChangeOrderExpected?.cost ?? 0}
                onChange={(amount) => {
                  if (editedChangeOrderExpected) {
                    editedChangeOrderExpected.cost = amount;
                  }
                  setEditedChangeOrderExpected({ ...editedChangeOrderExpected });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label={languageContext.getMessage("comments")}
                value={editedChangeOrderExpected?.changeOrderComment ?? ""}
                onChange={(event) => {
                  if (editedChangeOrderExpected) {
                    editedChangeOrderExpected.changeOrderComment = event.target.value;
                    setEditedChangeOrderExpected({ ...editedChangeOrderExpected });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={`${languageContext.getMessage("enableProgressConsequence")}`}
                control={
                  <Checkbox
                    name="progressConsequenceEnabled"
                    checked={editedChangeOrderExpected?.progressConsequenceEnabled ?? false}
                    onChange={() => {
                      if (editedChangeOrderExpected) {
                        editedChangeOrderExpected.progressConsequenceEnabled = !(editedChangeOrderExpected.progressConsequenceEnabled ?? false);
                        setEditedChangeOrderExpected({ ...editedChangeOrderExpected });
                      }
                    }}
                  />
                }
              />
            </Grid>

            {editedChangeOrderExpected && editedChangeOrderExpected?.progressConsequenceEnabled && (
              <Grid item xs={12}>
                <TextField
                  label={`${languageContext.getMessage("progressConsequence")} ${languageContext.getMessage("description")}`}
                  multiline
                  value={editedChangeOrderExpected?.progressConsequenceDescription ?? ""}
                  onChange={(event) => {
                    if (editedChangeOrderExpected) {
                      editedChangeOrderExpected.progressConsequenceDescription = event.target.value;
                      setEditedChangeOrderExpected({ ...editedChangeOrderExpected });
                    }
                  }}
                  fullWidth
                />
              </Grid>
            )}
            {changeOrderExpected?.id && (
              <Grid item xs={12}>
                <Typography variant="body2">{`${languageContext.getMessage("lastChangedBy")} ${users.find((user) => user.id === editedChangeOrderExpected?.changedBy)?.name}`}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!handlingNewChangeOrder && (
            <Button onClick={handleOnClickDelete} color="primary" variant="contained">
              {languageContext.getMessage("delete")}
            </Button>
          )}
          <Button
            onClick={() => handleClose(true)}
            color="primary"
            variant="contained"
            disabled={editedChangeOrderExpected?.accountId === undefined}
          >
            {languageContext.getMessage("save")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog title={languageContext.getMessage("doYouWantToDeleteExpectedChange")} open={openConfirmDeleteDialog} onClose={async (deleteIsConfirmed) => handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)} />
    </>
  );
};

export default withTheme(EditChangeOrderExpectedDialog);
