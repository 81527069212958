import { withTheme } from '@material-ui/core';
import React from 'react';
import { ResponsiveLine } from '@nivo/line'

// https://nivo.rocks/line/

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const LineGraphNivoView: React.FC<{}> = () => {
  return <ResponsiveLine
  data={data}
  margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
  xScale={{ type: 'point' }}
  yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
  axisTop={null}
  axisRight={null}
  axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'transportation',
      legendOffset: 36,
      legendPosition: 'middle'
  }}
  axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'count',
      legendOffset: -40,
      legendPosition: 'middle'
  }}
  colors={{ scheme: 'nivo' }}
  pointSize={10}
  pointColor={{ theme: 'background' }}
  pointBorderWidth={2}
  pointBorderColor={{ from: 'serieColor' }}
  pointLabel="y"
  pointLabelYOffset={-12}
  useMesh={true}
  legends={[
      {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
              {
                  on: 'hover',
                  style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                  }
              }
          ]
      }
  ]}
/>
}

export default withTheme(LineGraphNivoView);

const data = [
  {
    "id": "japan",
    "color": "hsl(289, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 261
      },
      {
        "x": "helicopter",
        "y": 39
      },
      {
        "x": "boat",
        "y": 267
      },
      {
        "x": "train",
        "y": 127
      },
      {
        "x": "subway",
        "y": 131
      },
      {
        "x": "bus",
        "y": 182
      },
      {
        "x": "car",
        "y": 232
      },
      {
        "x": "moto",
        "y": 187
      },
      {
        "x": "bicycle",
        "y": 208
      },
      {
        "x": "horse",
        "y": 161
      },
      {
        "x": "skateboard",
        "y": 179
      },
      {
        "x": "others",
        "y": 299
      }
    ]
  },
  {
    "id": "france",
    "color": "hsl(181, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 156
      },
      {
        "x": "helicopter",
        "y": 65
      },
      {
        "x": "boat",
        "y": 167
      },
      {
        "x": "train",
        "y": 288
      },
      {
        "x": "subway",
        "y": 73
      },
      {
        "x": "bus",
        "y": 30
      },
      {
        "x": "car",
        "y": 279
      },
      {
        "x": "moto",
        "y": 146
      },
      {
        "x": "bicycle",
        "y": 16
      },
      {
        "x": "horse",
        "y": 103
      },
      {
        "x": "skateboard",
        "y": 291
      },
      {
        "x": "others",
        "y": 254
      }
    ]
  },
  {
    "id": "us",
    "color": "hsl(42, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 293
      },
      {
        "x": "helicopter",
        "y": 103
      },
      {
        "x": "boat",
        "y": 212
      },
      {
        "x": "train",
        "y": 79
      },
      {
        "x": "subway",
        "y": 39
      },
      {
        "x": "bus",
        "y": 271
      },
      {
        "x": "car",
        "y": 210
      },
      {
        "x": "moto",
        "y": 11
      },
      {
        "x": "bicycle",
        "y": 17
      },
      {
        "x": "horse",
        "y": 140
      },
      {
        "x": "skateboard",
        "y": 234
      },
      {
        "x": "others",
        "y": 62
      }
    ]
  },
  {
    "id": "germany",
    "color": "hsl(94, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 158
      },
      {
        "x": "helicopter",
        "y": 12
      },
      {
        "x": "boat",
        "y": 32
      },
      {
        "x": "train",
        "y": 137
      },
      {
        "x": "subway",
        "y": 45
      },
      {
        "x": "bus",
        "y": 169
      },
      {
        "x": "car",
        "y": 77
      },
      {
        "x": "moto",
        "y": 169
      },
      {
        "x": "bicycle",
        "y": 291
      },
      {
        "x": "horse",
        "y": 102
      },
      {
        "x": "skateboard",
        "y": 200
      },
      {
        "x": "others",
        "y": 153
      }
    ]
  },
  {
    "id": "norway",
    "color": "hsl(122, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 92
      },
      {
        "x": "helicopter",
        "y": 185
      },
      {
        "x": "boat",
        "y": 225
      },
      {
        "x": "train",
        "y": 252
      },
      {
        "x": "subway",
        "y": 34
      },
      {
        "x": "bus",
        "y": 33
      },
      {
        "x": "car",
        "y": 39
      },
      {
        "x": "moto",
        "y": 233
      },
      {
        "x": "bicycle",
        "y": 171
      },
      {
        "x": "horse",
        "y": 229
      },
      {
        "x": "skateboard",
        "y": 125
      },
      {
        "x": "others",
        "y": 49
      }
    ]
  }
]