import { Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { DocumentType, Forecasts, Risk, GanttItem, RiskType } from "../../contracts/contracts";
import RiskAllView from "./RiskAllView";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useRiskContext } from "../../contexts/risk/riskContext";
import RiskDetailsView from "./RiskDetailsView";
import RiskOverview from "./RiskOverview";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import GanttBoard from "../../component/ganttComponents/GanttBoard";
import { useStateDocumentEventsQueriesContext } from "../../contexts/StateDocumentEvents/queries/stateDocumentEventsQueriesContext";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    titleSection: {
        margin: theme.spacing(3, 2),
    },
    dateRangePickerSection: {
        margin: theme.spacing(3, 2, 0),
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
}));

const RiskView: React.FC<{}> = () => {

    const urlContext = useUrlContext();
    const languageContext = useLanguageContext();
    const classes = useStyles();
    const riskContext = useRiskContext();
    const projectContext = useProjectContext();
    const accountContext = useAccountContext();
    const ticketContext = useTicketContext();
    const userRoles = useUserRoleContext();
    const stateDocumentEventsContext = useStateDocumentEventsQueriesContext();

    const urlState = urlContext.getUrlState();
    const tabIndex = urlState.tab ? urlState.tab as string : 'all';
    const selectedType = urlState.type ? urlState.type as RiskType : undefined;

    const accountSearch = accountContext.getAccountSearch();
    const riskSearch = riskContext.getRiskSearch();
    const selectedRiskId = urlState.riskId ? urlState.riskId as string : undefined;
    const selectedRisk = selectedRiskId ? (riskContext.getRisk(selectedRiskId) ?? undefined) : undefined

    useEffect(() => {
        userRoles.queryUsers();
        ticketContext.setDocumentTypesToWatch([
            DocumentType.ACCOUNT, 
            DocumentType.RISK]);
        riskContext.searchRisks(riskSearch);
        accountContext.searchAccounts(accountSearch);
        if (selectedRiskId) {
            stateDocumentEventsContext.getEvents(selectedRiskId, DocumentType.RISK);
        }
    }, [urlContext.currentLocation, projectContext.getSelectedProject()])

    const updateUrlState = (newSelectedRisk: Risk | undefined, newSelectedType: RiskType | undefined, newTabIndex: string): void => {
        const newUrlState = {
            ...riskSearch,
            ...{'type': newSelectedType},
            ...{'riskId': newSelectedRisk?.id}, 
            ...{'tab': newTabIndex}}
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | number[] | boolean | Date | undefined>);
        urlContext.pushUrlQuery(urlQuery);
    }

    const handleTabIndexChange = (event: React.ChangeEvent<{}> | null, newTabIndex: string) => {
        updateUrlState(riskSearch, selectedType, newTabIndex);
    };

    const updateRiskSearch = (newRiskSearch: Risk): void => {
        updateUrlState(newRiskSearch, selectedType,  tabIndex);
    }

    const onSelectedTypeChange = (newSelectedStatus: RiskType | undefined): void => {
        updateUrlState(riskSearch, selectedType, tabIndex);
      } 

    const onSelectedRiskChange = (newSelectedRisk: Risk | undefined): void => {
        updateUrlState(newSelectedRisk,
            selectedType,
            'details');
      }
      

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
                    <Tabs value={tabIndex} onChange={handleTabIndexChange}>
                        <Tab label={languageContext.getMessage('all')} value='all' />
                        <Tab label={languageContext.getMessage('details')} value='details' />
                    </Tabs>
                </Toolbar>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
                {tabIndex === 'all' && <RiskAllView onSelectedRiskChange={onSelectedRiskChange} onSelectedTypeChange={onSelectedTypeChange} />}
                {tabIndex === 'details' && <RiskDetailsView selectedRisk={selectedRisk} onSelectedRiskChange={onSelectedRiskChange}/>}
            </Grid>
        </Grid>
    );
}

export default withTheme(RiskView);