import { Divider, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Theme, Typography, withTheme } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { ReactElement, useState } from "react";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { ChangeOrderExpected, ChangeOrderStatus, Account, ElementStatusEnum } from "../../contracts/contracts";
import { getRenderedElementStatus } from "../../utils/statusToPalettes";
import EditChangeOrderExpectedDialog from "./EditChangeOrderExpectedDialog";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    button: {
        margin: theme.spacing(1),
    },
    root: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
      },
}));

type props = {
    selectedAccount: Account | undefined;
    selectedStatus: ChangeOrderStatus | undefined;
    onSelectedChangeOrderExpectedChange?: (newSelectedChangeOrderExpected: ChangeOrderExpected | undefined) => void;
}

const ChangeOrderExpectedMobileList: React.FC<props> = ({
    selectedAccount,
    selectedStatus,
    onSelectedChangeOrderExpectedChange }) => {

    const classes = useStyles();

    const changeOrderExpectedContext = useChangeOrderExpectedContext();
    const languageContext = useLanguageContext();
    const { getLocalization } = useMaterialTableLanguageContext();
    const themeContext = useThemeContext();
    const theme = themeContext.getTheme();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [changeOrderExpectedToEdit, setChangeOrderExpectedToEdit] = useState<ChangeOrderExpected>({});
    const changeOrderExpecteds = changeOrderExpectedContext.getChangeOrderExpecteds(selectedAccount?.id, selectedStatus);

    const openEditChangeOrderExpectedDialog = (changeOrderExpectedToEdit: ChangeOrderExpected) => {
        setChangeOrderExpectedToEdit({...changeOrderExpectedToEdit});
        setShowDialog(true);
    };

    const onCloseChangeOrderExpectedDialog = () => {
        setShowDialog(false);
    };

    const fabs = 
        {
          color: 'primary' as 'primary',
          className: classes.fab,
          icon: <AddIcon />,
          label: 'Add',
        }

    function data(changeOrderExpecteds: ChangeOrderExpected[]): ReactElement {
        let buffer = [];
        for (var i=0; i<changeOrderExpecteds.length; i++) {
                buffer.push(<>
                <ListItem>
                    <ListItemAvatar>
                        {getRenderedElementStatus(changeOrderExpecteds[i]?.elementStatus ?? ElementStatusEnum.ALERT, theme)}
                    </ListItemAvatar>
                    <ListItemText primary={changeOrderExpecteds[i].name} secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            >
                        
                        </Typography>
                    {changeOrderExpecteds[i].changeOrderDescription?.substring(0,50)} ...
                    <Typography>
                    Kost: {changeOrderExpecteds[i].adjustedCost} NOK
                    </Typography>
                    </React.Fragment> 
                        } />
                 </ListItem>
                 <Divider variant="inset" component="li" />
                 </>)
        }

        return <>{buffer}</>
    }  


    return (<>
        <EditChangeOrderExpectedDialog 
            changeOrderExpected={changeOrderExpectedToEdit} 
            open={showDialog} 
            onClose={onCloseChangeOrderExpectedDialog} />
        <List className={classes.root}>
            {data(changeOrderExpecteds)}
        </List>
        <Fab aria-label={'Add'} className={classes.fab} color={'primary'} onClick={() => openEditChangeOrderExpectedDialog({
                        accountId: selectedAccount?.id,
                        status: selectedStatus,
                    })}>
            {<AddIcon />}
        </Fab>
    </>
    );
}

export default withTheme(ChangeOrderExpectedMobileList);