import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, CrewList } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { datesAreOnSameDay } from "../../utils/dateTools";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useProjectContext } from "../project/projectContext";
import { useUrlContext } from "../url/urlContext";
import { receiveCrewList } from "./crewListTools";
import { CrewListMutationsContextProvider, useCrewListMutationsContext } from "./mutations/CrewListMutationsContext";
import { CrewListQueriesContextProvider, useCrewListQueriesContext } from "./queries/crewListQueriesContext";
import { CrewListSubscriptionsContextProvider, useCrewListSubscriptionsContext } from "./subscriptions/crewListSubscriptionsContext";

export interface CrewListContext {
    getCrewListSearch: () => CrewList,
    searchCrewList: (crewpPlanSearch: CrewList, force: boolean) => void,
    updateCrewList: (crewListToUpdate: CrewList) => Promise<void>,
    crewLists: CrewList[],
}

const CrewListContext = createContext<CrewListContext>(null as unknown as CrewListContext);

export const CrewListContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <CrewListMutationsContextProvider>
            <CrewListQueriesContextProvider>
                <CrewListSubscriptionsContextProvider>
                    <CrewListSubContextProvider>
                            {children}
                    </CrewListSubContextProvider>
                </CrewListSubscriptionsContextProvider>
            </CrewListQueriesContextProvider>
        </CrewListMutationsContextProvider>
    );
}

export const CrewListSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const projectContext = useProjectContext();
    const crewListMutationsContext = useCrewListMutationsContext();
    const crewListQueriesContext = useCrewListQueriesContext();
    const crewListSubscriptionsContext = useCrewListSubscriptionsContext();
    const [currentCrewListSearch, setCurrentCrewListSearch] = useState<CrewList | undefined>(undefined);
    const [crewLists, setCrewLists] = useState<CrewList[]>([]);

    const mergeCrewList = (oldCrewList: CrewList[], newCrewList: CrewList[]): CrewList[] => {
        let updatedCrewList = oldCrewList.slice();
        newCrewList.forEach(newCrewList => {
            const index = updatedCrewList.findIndex(contract => contract.id === newCrewList.id);
            newCrewList = receiveCrewList(currentCrewListSearch, newCrewList);
            if (index >= 0) {
                if (newCrewList.state === ActiveState.ACTIVE) {
                    updatedCrewList[index] = newCrewList;
                }
                else {
                    updatedCrewList.splice(index, 1);
                }
            } 
            else {
                if (newCrewList.state === ActiveState.ACTIVE) {
                    updatedCrewList.push(newCrewList); 
                }
            }
        });
        
        return updatedCrewList;
    }

    const getCrewListSearch = (): CrewList => {
        const urlState = urlContext.getUrlState();

        const parentDocumentId = urlState.parentDocumentId ? urlState.parentDocumentId as Guid : projectContext.getSelectedProject()?.id;
        const fromDate = urlState.fromDate ? new Date(urlState.fromDate as string) : undefined;
        const toDate = urlState.toDate ? new Date(urlState.toDate as string) : undefined;
        const fromDateIsEmpty = urlState.fromDateIsEmpty === 'true';
        const toDateIsEmpty = urlState.toDateIsEmpty === 'true';
    
        return {
            parentDocumentId,
            fromDate: fromDateIsEmpty === true ? undefined : fromDate,
            toDate: toDateIsEmpty === true ? undefined : toDate,
            fromDateIsEmpty: fromDateIsEmpty,
            toDateIsEmpty: toDateIsEmpty,
        }
    }

    const searchCrewList = (crewListSearch: CrewList, force: boolean): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(crewListSearch, currentCrewListSearch);
        matched = matched && crewListSearch?.parentDocumentId === currentCrewListSearch?.parentDocumentId;
        matched = matched && crewListSearch?.parentDocumentType === currentCrewListSearch?.parentDocumentType;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && (!matched || force)) {
            setCurrentCrewListSearch(crewListSearch);
            setCrewLists([]);
        }
    }

    const updateCrewList = (crewListToUpdate: CrewList): Promise<void> => {
        return crewListMutationsContext.mutateCrewList(crewListToUpdate, (documentId, variables) => {
            crewListToUpdate = {...crewListToUpdate, ...variables};
            crewListToUpdate.id = documentId;
            setCrewLists(mergeCrewList(crewLists, [crewListToUpdate]));
        });
    }

    useEffect(() => {
        if (currentCrewListSearch) {
            crewListQueriesContext.queryCrewList(currentCrewListSearch);
        }
    }, [currentCrewListSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setCrewLists([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        if(!crewListQueriesContext.fetchedCrewList) return;
        setCrewLists(mergeCrewList(crewLists, crewListQueriesContext.fetchedCrewList));
    }, [crewListQueriesContext.fetchedCrewList]);

    
    useEffect(() => {
        setCrewLists(mergeCrewList(crewLists, crewListSubscriptionsContext.subscribedCrewList));
    }, [crewListSubscriptionsContext.subscribedCrewList]);

    const crewListContext: CrewListContext = {
        getCrewListSearch,
        searchCrewList,
        updateCrewList,
        crewLists,
    };

    return (
        <CrewListContext.Provider value={crewListContext}>
            {children}
        </CrewListContext.Provider>
    );
}

export const useCrewListContext = (): CrewListContext => {
    return useContext(CrewListContext);
}
