import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Risk, ActiveState } from "../../../contracts/contracts";

export const QUERY_RISK: DocumentNode = gql`
    query risks(
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $projectId: UUID
        $accountId: UUID
        $name: String
        $comment: String
        $changeComment: String
        $riskType: RiskType
        $consequence: Float
        $consequenceMin: Float
        $consequenceMax: Float
        $consequenceMatrix: Int
        $consequenceType: ConsequenceType
        $probabilityType: ProbabilityType
        $probability: Float
        $probabilityMin: Float
        $probabilityMax: Float
        $probabilityMatrix: Int
        $riskCost: Float
        $riskCostDistribution: [Float]
        $riskCostDistributionP: [Float]
        $riskCostVar: Float
        $riskCostToForecast:Boolean
        $fromDate: Date
        $toDate: Date
    ) {
    risks(
        id: $id
        documentType: $documentType
        state: $state
        created: $created
        projectId: $projectId
        accountId: $accountId
        name: $name
        comment: $comment
        changeComment: $changeComment
        riskType: $riskType
        consequence: $consequence
        consequenceMin: $consequenceMin
        consequenceMax: $consequenceMax
        consequenceMatrix: $consequenceMatrix
        consequenceType: $consequenceType
        probabilityType: $probabilityType
        probability: $probability
        probabilityMin: $probabilityMin
        probabilityMax: $probabilityMax
        probabilityMatrix: $probabilityMatrix
        riskCost: $riskCost
        riskCostDistribution: $riskCostDistribution
        riskCostDistributionP: $riskCostDistributionP
        riskCostVar: $riskCostVar
        riskCostToForecast: $riskCostToForecast
        fromDate: $fromDate
        toDate: $toDate
    ) {
        id
        documentType
        stateTime
        state
        created
        changed
        changedBy
        projectId
        accountId
        name
        comment
        changeComment
        riskType
        consequence
        consequenceMin
        consequenceMax
        consequenceMatrix
        consequenceType
        probabilityType
        probability
        probabilityMin
        probabilityMax
        probabilityMatrix
        riskCost
        riskCostDistribution
        riskCostDistributionP
        riskCostVar
        riskCostToForecast
        fromDate
        toDate
    }
}
`;

export const QUERY_RISK_HISTORY: DocumentNode = gql`
    query risk(
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $projectId: UUID
    ) {
    risks(
        id: $id
        documentType: $documentType
        state: $state
        created: $created
        projectId: $projectId
    ) {
        id
        documentType
        stateTime
        state
        created
        projectId
    }
}
`;

export const getRisksGraphqlQueryOptions = (risk: Partial<Risk>): QueryHookOptions => {
    risk.state = risk.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {variables: {}}
    if (risk.id && options.variables) options.variables['id'] = risk.id
    if (risk.state && options.variables) options.variables['state'] = risk.state
    if (risk.projectId && options.variables) options.variables['projectId'] = risk.projectId
    if (risk.accountId && options.variables) options.variables['accountId'] = risk.accountId
    if (risk.name && options.variables) options.variables['name'] = risk.name
    if (risk.comment && options.variables) options.variables['comment'] = risk.comment
    if (risk.changeComment && options.variables) options.variables['changeComment'] = risk.changeComment
    if (risk.riskType && options.variables) options.variables['riskType'] = risk.riskType
    if (risk.consequence && options.variables) options.variables['consequence'] = risk.consequence
    if (risk.consequenceMatrix && options.variables) options.variables['consequenceMatrix'] = risk.consequenceMatrix
    if (risk.consequenceType && options.variables) options.variables['consequenceType'] = risk.consequenceType
    if (risk.probabilityType && options.variables) options.variables['probabilityType'] = risk.probabilityType
    if (risk.probability && options.variables) options.variables['probability'] = risk.probability
    if (risk.probabilityMin && options.variables) options.variables['probabilityMin'] = risk.probabilityMin
    if (risk.probabilityMax && options.variables) options.variables['probabilityMax'] = risk.probabilityMax
    if (risk.probabilityMatrix && options.variables) options.variables['probabilityMatrix'] = risk.probabilityMatrix
    if (risk.riskCost && options.variables) options.variables['riskCost'] = risk.riskCost
    if (risk.riskCostVar && options.variables) options.variables['riskCostVar'] = risk.riskCostVar
    if (risk.fromDate && options.variables) options.variables['fromDate'] = risk.fromDate
    if (risk.toDate && options.variables) options.variables['toDate'] = risk.toDate

    return options;
}

export const getRiskHistoryGraphqlQueryOptions = (risk: Partial<Risk>): QueryHookOptions => {
    risk.state = risk.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {variables: {}}
    if (risk.id && options.variables) options.variables['id'] = risk.id
    if (risk.state && options.variables) options.variables['state'] = risk.state
    if (risk.projectId && options.variables) options.variables['projectId'] = risk.projectId

    return options;
}