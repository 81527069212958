import { Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import withTheme from '@material-ui/styles/withTheme';
import React, { useEffect, useState } from 'react';
import { useAccountContext } from '../../contexts/account/accountContext';
import { useInvoiceContext } from '../../contexts/invoice/invoiceContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { ActiveState, Invoice, InvoiceApprovedStatus, InvoiceFlag } from '../../contracts/contracts';
import { Guid } from '../../utils/common-types';
import SelectAccount from '../accountComponents/SelectAccount';
import CurrencyInput from '../currencyComponents/CurrencyInput';
import { DatePicker } from '../dateComponents/DatePicker';
import ConfirmDeleteDialog from '../deleteComponent/ConfirmDeleteDialog';
import InvoiceChangeOrderSelect from './InvoiceChangeOrderSelect';
import InvoiceExternalProjectIdSelect from './InvoiceExternalProjectIdSelect';
import SelectInvoiceFlags from './SelectInvoiceFlags';
import { getInvoiceFlags, updateInvoiceFlags } from '../../utils/InvoiceFlagsTools';

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.error.main,
    marginRight: '0.25em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  card: {
    padding: theme.spacing(1)
  },
}));

export interface Props {
  invoice: Invoice | undefined;
  open: boolean;
  onClose: (mutatedInvoice?: Invoice) => void;
  editMode?: boolean;
  isDeletable?: boolean;
  hideChangeOrderAndContract?: boolean;
}

const NewInvoiceDialog: React.FC<Props> = ({
  invoice,
  open,
  onClose,
  editMode,
  isDeletable,
  hideChangeOrderAndContract,
}) => {
  editMode = editMode ?? false;
  hideChangeOrderAndContract = hideChangeOrderAndContract ?? false;
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const invoiceContext = useInvoiceContext();
  const accountContext = useAccountContext();
  const projectContext = useProjectContext();

  const [contractId, setContractId] = useState<Guid>();
  const [changeOrderId, setChangeOrderId] = useState<Guid>();
  const [externalProjectId, setExternalProjectId] = useState<string>();
  const [supplier, setSupplier] = useState<string>();
  const [accountId, setAccountId] = useState<Guid>();
  const [accountNumber, setAccountNumber] = useState<string>();
  const [invoiceNumber, setInvoiceNumber] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const [withheldAmount, setWithheldAmount] = useState<number>();
  const [scannedDate, setScannedDate] = useState<Date>();
  const [voucherDate, setVoucherDate] = useState<Date>();
  const [dueDate, setDueDate] = useState<Date>();
  const [approvedStatus, setApprovedStatus] = useState<InvoiceApprovedStatus>();
  const [comments, setComments] = useState<string[]>();
  const [invoiceFlags, setInvoiceFlags] = useState<InvoiceFlag[]>([]);

  const inputProps = { readOnly: false };

  const handleOnClickSave = () => {
    const mutatedInvoice: Invoice = { ...invoice };
    mutatedInvoice.projectId = projectContext.getSelectedProject()?.id;
    mutatedInvoice.accountId = accountId;
    mutatedInvoice.contractId = contractId;
    mutatedInvoice.changeOrderId = changeOrderId;
    mutatedInvoice.externalProjectId = externalProjectId;
    mutatedInvoice.supplier = supplier;
    mutatedInvoice.accountNumber = accountNumber;
    mutatedInvoice.invoiceNumber = invoiceNumber;
    mutatedInvoice.amount = amount;
    mutatedInvoice.withheldAmount = withheldAmount;
    mutatedInvoice.scannedDate = scannedDate;
    mutatedInvoice.voucherDate = voucherDate;
    mutatedInvoice.dueDate = dueDate;
    mutatedInvoice.approvedStatus = approvedStatus;
    mutatedInvoice.comments = comments;
    updateInvoiceFlags(mutatedInvoice, invoiceFlags);
    invoiceContext.mutateInvoice(mutatedInvoice);
    onClose(mutatedInvoice);
  };

  const handleOnClickClose = () => {
    onClose(undefined);
  };

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      const mutatedInvoice = { ...invoice };
      mutatedInvoice.state = ActiveState.INACTIVE;
      invoiceContext.mutateInvoice(mutatedInvoice);
      onClose(mutatedInvoice);
    }
    setOpenConfirmDeleteDialog(false);
  }

  useEffect(() => {
    if (invoice) {
      setAccountId(invoice?.accountId);
      setContractId(invoice?.contractId);
      setChangeOrderId(invoice?.changeOrderId);
      setExternalProjectId(invoice?.externalProjectId);
      setSupplier(invoice?.supplier);
      setAccountNumber(invoice?.accountNumber);
      setInvoiceNumber(invoice?.invoiceNumber);
      setAmount(invoice?.amount);
      setWithheldAmount(invoice?.withheldAmount);
      setScannedDate(invoice?.scannedDate);
      setVoucherDate(invoice?.voucherDate);
      setDueDate(invoice?.dueDate);
      setApprovedStatus(invoice?.approvedStatus ?? (editMode ? invoice?.approvedStatus : (hideChangeOrderAndContract ? InvoiceApprovedStatus.DISTRIBUTED : InvoiceApprovedStatus.APPROVED)));
      setComments(invoice.comments);
      setInvoiceFlags(getInvoiceFlags(invoice));
    }
  }, [invoice])

  const invoiceIsApproved = approvedStatus === InvoiceApprovedStatus.APPROVED;
  const invoiceIsRejected = approvedStatus === InvoiceApprovedStatus.REJECTED;

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleOnClickClose}
        open={open}
        disableBackdropClick={true}>
        <DialogTitle>
          {editMode ? languageContext.getMessage('editInvoice') : languageContext.getMessage('newInvoice')}
          <IconButton className={classes.closeButton} onClick={handleOnClickClose} title={languageContext.getMessage('cancel')}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <FormControlLabel
                label={`${languageContext.getMessage('approved')}`}
                control={
                  <Checkbox
                    name="invoiceApproved"
                    checked={invoiceIsApproved}
                    onChange={(event, checked) => {
                      if (checked) {
                        setApprovedStatus(InvoiceApprovedStatus.APPROVED);
                      }
                      else {
                        if (invoiceIsApproved) {
                          setApprovedStatus(InvoiceApprovedStatus.DISTRIBUTED);
                        }
                      }
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label={`${languageContext.getMessage('rejected')}`}
                control={
                  <Checkbox
                    name="invoiceRejected"
                    checked={invoiceIsRejected}
                    onChange={(event, checked) => {
                      if (checked) {
                        setApprovedStatus(InvoiceApprovedStatus.REJECTED);
                      }
                      else {
                        if (invoiceIsRejected) {
                          setApprovedStatus(InvoiceApprovedStatus.DISTRIBUTED);
                        }
                      }
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <InvoiceExternalProjectIdSelect
              externalProjectId={externalProjectId}
              onChange={externalProjectId => setExternalProjectId(externalProjectId)}
              readonly={inputProps.readOnly}
              fullWidth />
          </Grid>
          {invoiceIsApproved && <Grid item xs={12}>
            <SelectAccount
              selectedAccount={accountContext.getAccount(accountId)}
              onSelectedAccountChange={newSelectedAccount => { setContractId(""); setChangeOrderId(""); setAccountId(newSelectedAccount?.id); }}
              readonly={inputProps.readOnly} />
          </Grid>}
          {invoiceIsApproved && <Grid item xs={12}>
            <InvoiceChangeOrderSelect
              accountId={accountId}
              contractId={contractId}
              changeOrderId={changeOrderId}
              onChange={(nextContractId, nextChangeOrderId) => { setChangeOrderId(nextChangeOrderId); setContractId(nextContractId); }}
              readonly={inputProps.readOnly}
              fullWidth />
          </Grid>}
          <Grid item xs={12} >
            <TextField
              label={languageContext.getMessage('supplier')}
              value={supplier}
              InputProps={inputProps}
              onChange={event => setSupplier(event.target.value as string)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              label={languageContext.getMessage('invoiceNumber')}
              value={invoiceNumber}
              InputProps={inputProps}
              onChange={event => setInvoiceNumber(event.target.value as string)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} >
            <CurrencyInput
              fullWidth
              label={languageContext.getMessage('amount')}
              readOnly={inputProps.readOnly}
              value={amount ?? 0.0}
              onChange={amount => setAmount(amount)}
            />
          </Grid>
          <Grid item xs={12} >
            <CurrencyInput
              fullWidth
              label={languageContext.getMessage('withheldAmount')}
              readOnly={inputProps.readOnly}
              value={withheldAmount ?? 0.0}
              onChange={withheldAmount => setWithheldAmount(withheldAmount)}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              fullWidth
              readOnly={inputProps.readOnly}
              label={languageContext.getMessage('scannedDate')}
              date={scannedDate}
              onChange={date => setScannedDate(date)} />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              fullWidth
              readOnly={inputProps.readOnly}
              label={languageContext.getMessage('voucherDate')}
              date={voucherDate}
              onChange={date => setVoucherDate(date)} />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              fullWidth
              readOnly={inputProps.readOnly}
              label={languageContext.getMessage('dueDate')}
              date={dueDate}
              onChange={date => setDueDate(date)} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={languageContext.getMessage('comments')}
              value={comments?.join('\n')}
              multiline
              InputProps={inputProps}
              onChange={event => setComments((event.target.value as string).split('\n'))}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInvoiceFlags
              invoiceFlags={invoiceFlags}
              onChange={setInvoiceFlags}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {editMode && isDeletable && !invoice?.invoiceIsSplit && <Button onClick={handleOnClickDelete} color="primary" variant="contained">
            {languageContext.getMessage('delete')}
          </Button>}
          <Button onClick={handleOnClickSave} color="primary" variant="contained">
            {languageContext.getMessage('save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog
        title={languageContext.getMessage('doYouWantToDeleteInvoice')}
        open={openConfirmDeleteDialog}
        onClose={async (deleteIsConfirmed) => handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)}
      />
    </>
  );
}

export default withTheme(NewInvoiceDialog);