import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Interim, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";
import { INTERIM_EXPENSE_QUERY_ELEMENT } from "../InterimTools";

export const QUERY_INTERIMS: DocumentNode = gql(`
    query interims(
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $parentDocumentId: UUID
        $parentDocumentType: DocumentType
        $projectId: UUID
        $fromDate: Date
        $toDate: Date
    ) {
        interims(
        id: $id
        documentType: $documentType
        state: $state
        created: $created
        parentDocumentId: $parentDocumentId
        parentDocumentType: $parentDocumentType
        projectId: $projectId
        fromDate: $fromDate
        toDate: $toDate
        ) {
            id
            documentType
            stateTime
            state
            created
            parentDocumentId
            parentDocumentType
            projectId
            fromDate
            toDate
            firstDate
            lastDate
            monthlyInterimItems {
                ` + INTERIM_EXPENSE_QUERY_ELEMENT + `
            }
        }
    }  
`);

export const getInterimGraphqlQueryOptions = (interim: Interim): QueryHookOptions => {
    interim.state = interim.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {variables: {}}
    options.variables = {};
    if (interim.id !== undefined) options.variables['id'] = interim.id
    if (interim.state !== undefined) options.variables['state'] = interim.state
    if (interim.parentDocumentId !== undefined) options.variables['parentDocumentId'] = interim.parentDocumentId
    if (interim.parentDocumentType !== undefined) options.variables['parentDocumentType'] = interim.parentDocumentType
    if (interim.projectId !== undefined) options.variables['projectId'] = interim.projectId
    if (interim.fromDate !== undefined) options.variables['fromDate'] = toISODate(interim.fromDate);
    if (interim.toDate !== undefined) options.variables['toDate'] = toISODate(interim.toDate);
    return options;
}