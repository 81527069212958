import { AppBar, IconButton, makeStyles, Theme, Toolbar, withTheme, Button } from "@material-ui/core";
import { Menu } from "@material-ui/icons/";
import React from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import UserMenu from "../menuComponents/UserMenu";
import { useMenuContext, ApplicationRouteId } from "../../contexts/menu/menuContext";
import ProjectSelector from "../projectComponents/ProjectSelector";
import companyLogo from '../../data/companyLogo.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  buttonList: {
    '& >*+*': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const ApplicationAppBar: React.FC<{}> = () => {
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const menuContext = useMenuContext();

  const handleOnClickMenu = () => {
    menuContext.setFullMenuVisible(!menuContext.fullMenuVisible);
  }

  const handleOnClickTitleButton = () => {
    menuContext.setSelectedApplicationRoute(ApplicationRouteId.Home);
  }

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <IconButton className={classes.button} edge="start" color="inherit" aria-label="menu" onClick={handleOnClickMenu}>
          <Menu />
        </IconButton>

        <div className={classes.title}>
          <Button onClick={handleOnClickTitleButton}>
            <img src={companyLogo} style={{
              height: '20px',
              width: '20px',
              marginRight: '10px'
              }} 
              alt={languageContext.getMessage('company')}/>
            {languageContext.getMessage('productName')}
          </Button>
          /
          <ProjectSelector></ProjectSelector>
        </div>

        <div className={classes.buttonList}>
          <UserMenu></UserMenu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default withTheme(ApplicationAppBar);