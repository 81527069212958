import { makeStyles, Theme, withTheme, Tooltip, IconButton } from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';
import React, { ReactElement } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { Risk } from "../../contracts/contracts";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { useRiskContext } from "../../contexts/risk/riskContext";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    button: {
        margin: theme.spacing(1),
    },
    square: {
        width: '16.266667%',
        paddingBottom: '16.266667%',
        position: 'relative',
        background: 'linear-gradient(147deg, #31d655 0%, #30a645 74%)',
        textAlign: 'center',
        margin: '0.2%',
        borderRadius: '5px',
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        overflow: 'hidden'
    },
    squarePlain: {
        width: '16.266667%',
        paddingBottom: '16.266667%',
        position: 'relative',
        textAlign: 'center',
        margin: '0.2%',
        borderRadius: '5px',
        overflow: 'hidden'
    },
    squareLow: {
        width: '16.266667%',
        paddingBottom: '16.266667%',
        position: 'relative',
        background: 'linear-gradient(147deg, #31d655 0%, #30a645 74%)',
        textAlign: 'center',
        margin: '0.2%',
        borderRadius: '5px',
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        overflow: 'hidden'
    },
    squareMediumLow: {
        width: '16.266667%',
        paddingBottom: '16.266667%',
        position: 'relative',
        background: 'linear-gradient(147deg, #FFCE56 0%, #FFDE67 74%)',
        textAlign: 'center',
        margin: '0.2%',
        borderRadius: '5px',
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        overflow: 'hidden'
    },
    squareMedium: {
        width: '16.266667%',
        paddingBottom: '16.266667%',
        position: 'relative',
        background: 'linear-gradient(147deg, #FFA500 0%, #FFA522 74%)',
        textAlign: 'center',
        margin: '0.2%',
        borderRadius: '5px',
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        overflow: 'hidden'
    },
    squareHigh: {
        width: '16.266667%',
        paddingBottom: '16.266667%',
        position: 'relative',
        background: 'linear-gradient(147deg, #FF4646 0%, #FF4756 74%)',
        textAlign: 'center',
        margin: '0.2%',
        borderRadius: '5px',
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        overflow: 'hidden'
    },
    squareSevere: {
        width: '16.266667%',
        paddingBottom: '16.266667%',
        position: 'relative',
        background: 'linear-gradient(147deg, #FF0900 0%, #FF0922 74%)',
        textAlign: 'center',
        margin: '0.2%',
        borderRadius: '5px',
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        overflow: 'hidden'
    },
    content: {
        position: 'absolute',
        height: '95%',
        width: '95%',
        padding: '2.5%'
    },
    table: {
        display: 'table',
        width: '100%',
        height: '100%'
    },
    tableCell: {
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    riskCell: {
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row'
    }
}));


type props = {
    selectedRisk: Risk | undefined;
    riskRow: number;
    riskCol: number;
}

const RiskMatrix: React.FC<props> = ({
    selectedRisk: Risk,
    riskRow,
    riskCol}) => {

    const classes = useStyles();

    const themeContext = useThemeContext();
    
    function renderRisk(cnsq: number, prob: number) {
        let risk = cnsq * prob;
        if (risk > 0 && risk < 6) {
            return <div>Lav</div>
        }
        else if (risk > 6 && risk < 16) {
            return <div>Medium</div>
        }
        else if (risk > 16) {
            return <div>Stor</div>
        }
        else {
            return <div>-</div>
        }
    
    }

    function getRenderedStatus(col:number, row:number, theme:any, isRisk: boolean): ReactElement {
        let total = row * col;
        let buffer = [];
        if (isRisk) {
        buffer.push(<div className={classes.content}>
                        <div className={classes.table}>
                            <div className={classes.riskCell}>
                                    <Tooltip title="Dette er en risiko!">
                                        <IconButton aria-label="delete" color='primary'>
                                            <ErrorIcon />
                                        </IconButton>
                                    </Tooltip>
                            </div>
                        </div>
                    </div>)
        }
        else if (row==0 && col == 0) {
            buffer.push(<div className={classes.content}>
                <div className={classes.table}>
                    <div className={classes.tableCell}>
                    K/S
                    </div>
                </div>
            </div>)
        }
        else if (row==1 && col == 0) {
            buffer.push(<div className={classes.content}>
                <div className={classes.table}>
                    <div className={classes.tableCell}>
                    Lav
                    </div>
                </div>
            </div>)
        }
        else if (row==5 && col == 0) {
            buffer.push(<div className={classes.content}>
                <div className={classes.table}>
                    <div className={classes.tableCell}>
                    Høy
                    </div>
                </div>
            </div>)
        }
        else if (row==0 && col == 1) {
            buffer.push(<div className={classes.content}>
                <div className={classes.table}>
                    <div className={classes.tableCell}>
                    Lav
                    </div>
                </div>
            </div>)
        }
        else if (row==0 && col == 5) {
            buffer.push(<div className={classes.content}>
                <div className={classes.table}>
                    <div className={classes.tableCell}>
                    Høy
                    </div>
                </div>
            </div>)
        }
        else {
            buffer.push(<div className={classes.content}>
                <div className={classes.table}>
                    <div className={classes.tableCell}>
                        
                    </div>
                </div>
            </div>)
        }

        if (col == 2 && row == 2) {
            return (
                <div className={classes.squareLow}>
                    {buffer}
                </div>
            )
        }
        else {

        switch (total) {
            case 1:
            case 2:
            case 3: return (
                <div className={classes.squareLow}>
                    {buffer}
                </div>
            )
            case 4:
            case 5:
            case 6:
            case 8: return (
                <div className={classes.squareMediumLow}>
                    {buffer}
                </div>
            )
            case 9:
            case 10:
            case 12: return (
                <div className={classes.squareMedium}>
                    {buffer}
                </div>
            )
            case 15:
            case 16: return (
                <div className={classes.squareHigh}>
                    {buffer}
                </div>
            )
            case 20:
            case 25: return (
                <div className={classes.squareSevere}>
                    {buffer}
                </div>
            )
        }

    }
    
        return <div className={classes.squarePlain}>{buffer}</div>;
    }
    
    function createMatrixRow(col:number, theme:any, isRiskRow?:number): ReactElement {
        let buffer = [];
        // isRiskRow ?? 100;
    
            for (let j=0;j<6;j++) {
                if (j == isRiskRow) {
                buffer.push(getRenderedStatus(col, j, classes, true));
                }
                else {
                    buffer.push(getRenderedStatus(col, j, classes, false));
                }
            }
    
        return (
            <div className={classes.flexContainer}>
                {buffer}
            </div>
        ) 
    }
    
    function createMatrix(theme:any, riskRow: number, riskCol: number): ReactElement {
        let buffer = [];
    
        for (let i=5;i>=0;i--) {
            if (i == riskCol) {
                buffer.push(createMatrixRow(i, classes, riskRow));
            }
            else {
                buffer.push(createMatrixRow(i, classes, 50));
            }
        }
    
        return (
            <div> 
            { buffer }
            </div>
        )
    }

    let buffer = [];

    buffer.push(createMatrix(classes, riskRow, riskCol));

    return (<>

        { buffer }

    </>
    );
}

export default withTheme(RiskMatrix);