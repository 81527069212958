import { Grid, Paper, withTheme } from "@material-ui/core";
import React from "react";
import ChangeOrderLandlordList from "../../component/changeOrderLandlordComponents/ChangeOrderLandlordList";
import ChangeOrderLandlordMobileList from "../../component/changeOrderLandlordComponents/ChangeOrderLandlordMobileList";
import SelectChangeOrderLandlordFilter from "../../component/changeOrderLandlordComponents/SelectChangeOrderLandlordFilter";
import SelectStatus from "../../component/changeOrderLandlordComponents/SelectStatus";
import { ChangeOrderStatus } from "../../contracts/contracts";

type props = {
    selectedStatus: ChangeOrderStatus | undefined;
    onSelectedStatusChange: (newSelectedStatus: ChangeOrderStatus | undefined) => void;
}

const ChangeOrderLandlordDetailsView: React.FC<props> = ({
    selectedStatus,
    onSelectedStatusChange,
}) => {

    return (<>
            {(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 400 ?
            <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SelectChangeOrderLandlordFilter
                        selectedStatus={selectedStatus}
                        onSelectedStatusChange={onSelectedStatusChange}/>
                </Grid>
                <Grid item xs={12}>
                    <ChangeOrderLandlordList
                        selectedStatus={selectedStatus} />
                </Grid>
            </Grid>
            </>
            :
            <>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={3}>
                    <Paper elevation={2}>
                        <SelectStatus
                            selectedStatus={selectedStatus}
                            onSelectedStatusChange={onSelectedStatusChange} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <ChangeOrderLandlordMobileList
                            selectedStatus={selectedStatus} />
                    </Paper>
                </Grid>
            </Grid>
            </>
            }
        </>
    );
}

export default withTheme(ChangeOrderLandlordDetailsView);