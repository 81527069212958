import { Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { isWidthUp } from "@material-ui/core/withWidth";
import { createContext, useContext } from "react";

export interface WidthContext {
    isMobileScreen: () => boolean;
    getWidth: () => Breakpoint;
};

const WidthContext = createContext<WidthContext>(null as unknown as WidthContext);

export const WidthContextProvider: React.FC<{}> = ({ children }) => {
    
    const theme: Theme = useTheme();

    const getWidth = ():Breakpoint => {
        const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
        return (
            keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const matches = useMediaQuery(theme.breakpoints.up(key));
                return !output && matches ? key : output;
            }, null) || 'xs'
        );
    }

    const isMobileScreen = (): boolean => {
        return !isWidthUp('md', getWidth());
    }

    const value = {
        isMobileScreen,
        getWidth
    };

    return (
        <WidthContext.Provider value={value}>
            {children}
        </WidthContext.Provider>
    );
};

export const useWidthContext = (): WidthContext => {
    return useContext(WidthContext);
}