import React, { useState } from 'react';
import { createStyles, Theme, makeStyles, withTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Card, Button, Grid } from '@material-ui/core';
import { ChangeOrderExpected, ChangeOrderStatus, ElementStatusEnum, RoleType } from '../../contracts/contracts';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import { useChangeOrderExpectedContext } from '../../contexts/changeOrderExpected/changeOrderExpectedContext';
import EditChangeOrderExpectedDialog from './EditChangeOrderExpectedDialog';
import { getRenderedElementStatus } from '../../utils/statusToPalettes';
import { useThemeContext } from '../../contexts/theme/ThemeContext';
import { useAccountContext } from '../../contexts/account/accountContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import DateFormat from '../dateComponents/DateFormat';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootChange: {
      width: '100%',
    },
    card: {
      padding: '1em',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'initial',
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.dark,
    },
    section1: {
      margin: theme.spacing(3, 2),
    },
    section2: {
      margin: theme.spacing(2),
    },
    section3: {
      margin: theme.spacing(3, 1, 1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    contentInfo: {
      background: 'linear-gradient(147deg, #0086ad 0%, #1276bd 74%)', //'linear-gradient(147deg, #31d655 0%, #31d655 74%)'
      maxWidth: '80px',
      maxHeight: '80px',
      borderRadius: '5px',
      boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
      color: '#ffffff',
    },
    contentSucc: {
      background: 'linear-gradient(147deg, #31d655 0%, #30a645 74%)', //'linear-gradient(147deg, #31d655 0%, #31d655 74%)'
      maxWidth: '80px',
      maxHeight: '80px',
      borderRadius: '5px',
      boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
      color: '#ffffff',
    },
    contentCancel: {
      background: 'linear-gradient(147deg, #ff4e50 0%, #fe4e62 74%)', //'linear-gradient(147deg, #31d655 0%, #31d655 74%)'
      maxWidth: '80px',
      maxHeight: '80px',
      borderRadius: '5px',
      boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
      color: '#ffffff',
    },
    icon: {
      alignItems: 'center',
      margin: 'auto',
      color: '#ffffff',
    },
  }),
);

function getClassStatus(status: ElementStatusEnum, theme: any): string {
  switch (status) {
    case ElementStatusEnum.INFORMATION: return theme.contentInfo;
    case ElementStatusEnum.CANCEL: return theme.contentCancel;
    case ElementStatusEnum.SUCCESS: return theme.contentSucc;
  }

  return theme.contentSucc;
}

type props = {
  selectedChangeOrderExpected: ChangeOrderExpected;
}

const ChangeOrderExpectedDetailCard: React.FC<props> = ({
  selectedChangeOrderExpected }) => {

  const classes = useStyles();
  const themeContext = useThemeContext();
  const languageContext = useLanguageContext();
  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const accountContext = useAccountContext();
  const projectContext = useProjectContext();

  const theme = themeContext.getTheme();

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [changeOrderExpectedToEdit, setChangeOrderExpectedToEdit] = useState<ChangeOrderExpected>({});

  const openEditChangeOrderExpectedDialog = () => {
    setChangeOrderExpectedToEdit({ ...selectedChangeOrderExpected });
    setShowDialog(true);
  };

  const onCloseChangeOrderExpectedDialog = () => {
    setShowDialog(false);
  };

  return (<>
    <EditChangeOrderExpectedDialog
      changeOrderExpected={changeOrderExpectedToEdit}
      open={showDialog}
      onClose={onCloseChangeOrderExpectedDialog} />
    <Card className={classes.card}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className={getClassStatus(selectedChangeOrderExpected.elementStatus ?? ElementStatusEnum.ALERT, classes)}>
            <div className={classes.icon}>
              {getRenderedElementStatus(selectedChangeOrderExpected.elementStatus ?? ElementStatusEnum.ALERT, theme, true, { fontSize: 80, color: '#ffffff' })}
            </div>
          </div>
          <div className={classes.rootChange}>
            <div className={classes.section1}>
              <Typography gutterBottom variant="h5">
                {languageContext.getMessage('expectedChange')} {selectedChangeOrderExpected.name}
              </Typography>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('columnStatus')}:</b> {changeOrderExpectedContext.convertChangeOrderStatusToString(selectedChangeOrderExpected.status ?? ChangeOrderStatus.NOT_PROCESSED)}
              </Typography>
            </div>
            <div className={classes.section2}>
              <Typography color="textSecondary" variant="body1">
                {selectedChangeOrderExpected?.changeOrderDescription ?? ""}
              </Typography>
            </div>
            <Divider variant="middle" color="textSecondary" />
            <div className={classes.section1}>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('cost')}:</b> <CurrencyFormat amount={selectedChangeOrderExpected.adjustedCost} />
              </Typography>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('account')}:</b> {accountContext.getAccount(selectedChangeOrderExpected.accountId)?.name}
              </Typography>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('expectedDate')}:</b> <DateFormat date={selectedChangeOrderExpected.expected} />
              </Typography>
            </div>
            <Divider variant="middle" color="textSecondary" />
            <div className={classes.section1}>
              <Typography color="textSecondary" variant="body1">
                {selectedChangeOrderExpected?.changeOrderComment ?? ""}
              </Typography>
            </div>
            {projectContext.hasProjectAccess(RoleType.WRITER) && <div className={classes.section2}>
              <Button color="primary" variant="contained" onClick={openEditChangeOrderExpectedDialog} >
                <EditIcon /> {languageContext.getMessage('edit')}
              </Button>
            </div>}
          </div>
        </Grid>
      </Grid>
    </Card>
  </>);
}

export default withTheme(ChangeOrderExpectedDetailCard);