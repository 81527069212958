import { AccountTree, Assessment, Assignment, AssignmentLate, Event, Description, DeveloperMode, Home, Info, ListAlt, Schedule, History } from "@material-ui/icons";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { ComponentType, createContext, ReactElement, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RoleType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { isDevelopment } from "../../utils/devTools";
import AboutView from "../../views/about/AboutView";
import AccountView from "../../views/account/AccountView";
import AccrualAndOperationsView from "../../views/accrualAndOperations/AccrualAndOperationsView";
import ChangeLogView from "../../views/ChangeLogView";
import ChangeOrderCounterClaimView from "../../views/changeOrderCounterClaim/ChangeOrderCounterClaimView";
import ChangeOrderExpectedView from "../../views/changeOrderExpected/ChangeOrderExpectedView";
import ChangeOrderLandlordView from "../../views/changeOrderLandlord/ChangeOrderLandlordView";
import ChangeOrderSubContractorView from "../../views/changeOrderSubContractor/ChangeOrderSubContractorView";
import ContractView from "../../views/contract/ContractView";
import CrewPlanView from "../../views/crewList/CrewListView";
import GraphTestView from "../../views/devViews/GraphTestView";
import GridTestView from "../../views/devViews/GridTestView";
import ForecastView from "../../views/forecast/ForecastView";
import HomeView from "../../views/HomeView";
import InterimView from "../../views/interim/InterimView";
import InvoiceView from "../../views/invoices/InvoiceView";
import ProjectSettingsView from "../../views/projectSettings/ProjectSettingsView";
import RiskView from "../../views/risk/RiskView";
import { BrowserContext, useBrowserContext } from "../browserContext/browserContext";
import { LanguageContext, useLanguageContext } from "../language/LanguageContext";
import { useUrlContext } from "../url/urlContext";

export enum ApplicationRouteId {
    Home = "home",
    About = "about",
    Accounts = "accounts",
    Contracts = "contracts",
    Invoice = "invoice",
    changeOrderSubContractor = "changeOrderSubContractor",
    changeOrderLandlord = "changeOrderLandlord",
    changeOrderExpected = "changeOrderExpected",
    changeOrderCounterClaim = "changeOrderCounterClaim",
    AccrualAndOperations = "AccrualAndOperations",
    Forecast = "forecast",
    Interim = "interim",
    Risk = "risk",
    ProjectSettings = "projectSettings",
    ChangeLog = 'changeLog',
    CrewPlan = 'crewPlan',
    GraphTest = "graph-test",
    GridTest = "grid-test",
}

export type ApplicationRoute = {
    id: ApplicationRouteId;
    route: string;
    menuTitle: string;
    params?: Dictionary<string | number | Date>;
    component: ComponentType; // TODO(ljo): Should be FunctionComponent<{}>
    icon?: ReactElement;
    accessRole?: RoleType;
    hidden?: boolean;
};

const getApplicationRoutes = (languageContext: LanguageContext, browserContext: BrowserContext): ApplicationRoute[] => {
    return [
        {
            id: ApplicationRouteId.Home,
            route: '/',
            menuTitle: languageContext.getMessage('home'),
            component: HomeView,
            icon: <Home />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.Accounts,
            route: '/accounts',
            menuTitle: languageContext.getMessage('accounts'),
            component: AccountView,
            icon: <ListAlt />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.Contracts,
            route: '/contracts',
            menuTitle: languageContext.getMessage('contracts'),
            component: ContractView,
            icon: <ListAlt />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.Invoice,
            route: '/invoice',
            menuTitle: languageContext.getMessage('invoices'),
            component: InvoiceView,
            icon: <Description />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.Risk,
            route: '/risk',
            component: RiskView,
            menuTitle: languageContext.getMessage('RISK'),
            icon: <AssignmentLate />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.changeOrderExpected,
            route: '/changeorder_expected',
            component: ChangeOrderExpectedView,
            menuTitle: `${languageContext.getMessage('expectedChange')}`,
            icon: <Assignment />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.changeOrderCounterClaim,
            route: '/changeorder_counter_claim',
            component: ChangeOrderCounterClaimView,
            menuTitle: `${languageContext.getMessage('counterClaim')}`,
            icon: <Assignment />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.changeOrderSubContractor,
            route: '/changeorder_sub_contractor',
            component: ChangeOrderSubContractorView,
            menuTitle: `${languageContext.getMessage('changeOrder')} ${languageContext.getMessage('subContractorShort')}`,
            icon: <Assignment />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.changeOrderLandlord,
            route: '/changeorder_landlord',
            component: ChangeOrderLandlordView,
            menuTitle: `${languageContext.getMessage('changeOrder')} ${languageContext.getMessage('landlordShort')}`,
            icon: <Assignment />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.AccrualAndOperations,
            route: '/accrual_and_operations',
            component: AccrualAndOperationsView,
            menuTitle: languageContext.getMessage('accrualAndOperations'),
            icon: <Event />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.Forecast,
            route: '/forecasts',
            menuTitle: languageContext.getMessage('finalForecasts'),
            component: ForecastView,
            icon: <Assessment />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.Interim,
            route: '/interim',
            menuTitle: languageContext.getMessage('interim'),
            component: InterimView,
            icon: <Schedule />,
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.CrewPlan,
            route: '/crew_plan',
            menuTitle: languageContext.getMessage('crewList'),
            component: CrewPlanView,
            icon: <GroupAddIcon />,
            accessRole: RoleType.WRITER,
        },
        {
            id: ApplicationRouteId.ChangeLog,
            route: '/changelog',
            component: ChangeLogView,
            menuTitle: languageContext.getMessage('changeLog'),
            icon: <History />,
        },
        {
            id: ApplicationRouteId.ProjectSettings,
            route: '/projectSettings',
            component: ProjectSettingsView,
            menuTitle: languageContext.getMessage('settings'),
            icon: <SettingsIcon />,
        },
        {
            id: ApplicationRouteId.About,
            route: '/about',
            menuTitle: `${languageContext.getMessage('info')}`,
            component: AboutView,
            icon: <Info />,
        },
        {
            id: ApplicationRouteId.GraphTest,
            route: '/graph-test',
            component: GraphTestView,
            menuTitle: 'graph-test',
            icon: <DeveloperMode />,
            hidden: !isDevelopment(),
            accessRole: RoleType.READER,
        },
        {
            id: ApplicationRouteId.GridTest,
            route: '/grid-test',
            component: GridTestView,
            menuTitle: 'grid-test',
            icon: <DeveloperMode />,
            hidden: !isDevelopment(),
            accessRole: RoleType.READER,
        },
    ].filter(route => route.hidden !== true);
}

interface MenuContext {
    applicationRoutes: ApplicationRoute[];
    selectedApplicationRoute: ApplicationRoute,
    fullMenuVisible: boolean,
    getApplicationRouteById: (id: ApplicationRouteId) => ApplicationRoute,
    getApplicationRouteByRoute: (route: string) => ApplicationRoute,
    setFullMenuVisible: (newFullMenuVisible: boolean) => void,
    setSelectedApplicationRoute: (id: ApplicationRouteId, params?: Dictionary<string | number | Date> | null) => void,
    replaceSelectedApplicationRoute: (id: ApplicationRouteId, params: Dictionary<string | number | Date>) => void
}

const MenuContext = createContext<MenuContext>(null as unknown as MenuContext);

export const MenuProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const location = useLocation();
    const history = useHistory();
    const languageContext = useLanguageContext();
    const browserContext = useBrowserContext();

    const localStorageFullMenuVisibleSettingId = 'settings.fullMenuVisible';
    let defaultFullMenuVisible = (localStorage.getItem(localStorageFullMenuVisibleSettingId) ?? 'false') === 'true';
    const [fullMenuVisible, _setFullMenuVisible] = useState<boolean>(defaultFullMenuVisible);
    const applicationRoutes = getApplicationRoutes(languageContext, browserContext);

    const setFullMenuVisible = (newFullMenuVisible: boolean): void => {
        _setFullMenuVisible(newFullMenuVisible);
        localStorage.setItem(localStorageFullMenuVisibleSettingId, newFullMenuVisible.toString());
    }

    const getApplicationRouteById = (id: ApplicationRouteId): ApplicationRoute => {
        let applicationRoute = applicationRoutes.find(applicationRoute => applicationRoute.id === id);
        if (applicationRoute) return applicationRoute;
        return applicationRoutes[0];
    }
    
    const getApplicationRouteByRoute = (route: string): ApplicationRoute => {
        let applicationRoute = applicationRoutes.find(applicationRoute => applicationRoute.route === route);
        if (applicationRoute) return applicationRoute;
        return applicationRoutes[0];
    }

    const initialApplicationRoute = getApplicationRouteByRoute(location.pathname);
    const [selectedApplicationRoute, _setSelectedApplicationRoute] = useState<ApplicationRoute>(initialApplicationRoute);

    const setSelectedApplicationRoute = (id: ApplicationRouteId, params?: Dictionary<string | number | Date> | null): void => {
        const next = getApplicationRouteById(id);

        _setSelectedApplicationRoute(next);

        if (!params) {
            history.push(next.route);
        } else {
            history.push({ pathname: next.route, search: urlContext.buildUrlQuery(params) });
        }
    }

    const replaceSelectedApplicationRoute = (id: ApplicationRouteId, params: Dictionary<string | number | Date>): void => {
        if (selectedApplicationRoute.id !== getApplicationRouteById(id).id) return;

        const changes = { search: urlContext.buildUrlQuery(params) };
        history.replace({ ...history.location, ...changes });
    }

    const value = { 
        applicationRoutes, 
        selectedApplicationRoute, 
        fullMenuVisible, 
        getApplicationRouteById, 
        getApplicationRouteByRoute, 
        setFullMenuVisible, 
        setSelectedApplicationRoute, 
        replaceSelectedApplicationRoute 
    };

    return (
        <MenuContext.Provider value={value}>
            {children}
        </MenuContext.Provider>
    );
}

export const useMenuContext = (): MenuContext => {
    return useContext(MenuContext);
}