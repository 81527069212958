import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { ChangeOrderExpected } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useChangeOrderExpectedContext } from '../../contexts/changeOrderExpected/changeOrderExpectedContext';

type props = {
    selectedChangeOrderExpected: ChangeOrderExpected | undefined;
    onSelectedChangeOrderExpectedChange: (newSelectedChangeOrderExpected: ChangeOrderExpected | undefined) => void;
    changeOrderExpectedFilter?: (changeOrderExpecteds: ChangeOrderExpected[]) => ChangeOrderExpected[];
}

const SelectChangeOrderExpected: React.FC<props> = ({
    selectedChangeOrderExpected,
    onSelectedChangeOrderExpectedChange, 
    changeOrderExpectedFilter}) => {
        
    const languageContext = useLanguageContext();
    const changeOrderExpectedContext = useChangeOrderExpectedContext();

    let changeOrderExpecteds = changeOrderExpectedContext.getChangeOrderExpecteds();
    const defaultChangeOrderExpected = selectedChangeOrderExpected ?? null;

    if (changeOrderExpectedFilter) {
        changeOrderExpecteds = changeOrderExpectedFilter(changeOrderExpecteds);
    }

    const handleChange = (event: any, newSelectedChangeOrderExpected: ChangeOrderExpected | null) => {
        onSelectedChangeOrderExpectedChange(newSelectedChangeOrderExpected ?? undefined);
    }

    return  <Autocomplete
                options={changeOrderExpecteds}
                getOptionLabel={(changeOrderExpected) => changeOrderExpected.name ?? ''}
                value={defaultChangeOrderExpected}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} 
                    label={languageContext.getMessage('expectedChange')} 
                    variant="standard" />}
            />
}

export default SelectChangeOrderExpected;