import { Grid, makeStyles, Paper, Theme, withTheme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    green: {
        backgroundColor: '#85e685'
    },
    purple: {
        backgroundColor: '#e49ce4'
    },
    yellow: {
        backgroundColor: '#ffff8f'
    }
}));

const GridTestView: React.FC<{}> = () => {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={`${classes.paper} ${classes.green}`} elevation={4}>Full width</Paper>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <Paper className={`${classes.paper} ${classes.purple}`} elevation={4}>1/4 -- 1/2 -- 1</Paper>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <Paper className={`${classes.paper} ${classes.purple}`} elevation={4}>1/4 -- 1/2 -- 1</Paper>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <Paper className={`${classes.paper} ${classes.purple}`} elevation={4}>1/4 -- 1/2 -- 1</Paper>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <Paper className={`${classes.paper} ${classes.purple}`} elevation={4}>1/4 -- 1/2 -- 1</Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Paper className={`${classes.paper} ${classes.yellow}`} elevation={4}>1/4 -- 1</Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Paper className={`${classes.paper} ${classes.yellow}`} elevation={4}>1/4 -- 1</Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Paper className={`${classes.paper} ${classes.yellow}`} elevation={4}>1/4 -- 1</Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Paper className={`${classes.paper} ${classes.yellow}`} elevation={4}>1/4 -- 1</Paper>
            </Grid>
        </Grid>
    );
}

export default withTheme(GridTestView);