import { Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, IconButton, Input, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import withTheme from "@material-ui/styles/withTheme";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useChangeOrderCounterClaimContext } from "../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { ChangeOrderCounterClaim, ChangeOrderStatus, ActiveState } from "../../contracts/contracts";
import { getEmptyChangeOrderCounterClaim } from "../../utils/changeOrderCounterClaimTools";
import { getEmptyGuid, guidIsNullOrEmpty } from "../../utils/guidTools";
import SelectAccount from "../accountComponents/SelectAccount";
import SelectContract from "../contractComponents/SelectContract";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import { DatePicker } from "../dateComponents/DatePicker";
import ConfirmDeleteDialog from "../deleteComponent/ConfirmDeleteDialog";
import SelectChangeOrderCounterClaimGroup from "./SelectChangeOrderCounterClaimGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  listButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export interface EditChangeOrderCounterClaimDialogProps {
  changeOrderCounterClaim: ChangeOrderCounterClaim | undefined;
  open: boolean;
  onClose: () => void;
}

const EditChangeOrderCounterClaimDialog: React.FC<EditChangeOrderCounterClaimDialogProps> = ({ changeOrderCounterClaim, onClose, open }) => {
  const handlingNewChangeOrder = changeOrderCounterClaim?.id === undefined;

  const languageContext = useLanguageContext();
  const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const projectContext = useProjectContext();
  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const userRoleContext = useUserRoleContext();
  const users = userRoleContext.getUsers();

  const classes = useStyles();

  const [editedChangeOrderCounterClaim, setEditedChangeOrderCounterClaim] = useState<ChangeOrderCounterClaim>();
  const editedChangeOrderCounterClaimIsValid =
    editedChangeOrderCounterClaim !== undefined && editedChangeOrderCounterClaim.contractId !== undefined && editedChangeOrderCounterClaim.contractId.length > 0;

  useEffect(() => {
    if (!open) return;

    if (!changeOrderCounterClaim?.id) {
      const projectId = projectContext.getSelectedProject()?.id || "";
      setEditedChangeOrderCounterClaim(getEmptyChangeOrderCounterClaim(projectId, changeOrderCounterClaim?.accountId, changeOrderCounterClaim?.contractId));
    } else {
      setEditedChangeOrderCounterClaim({ ...changeOrderCounterClaim });
    }
  }, [open]);

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      const mutatedChangeOrderCounterClaim = { ...changeOrderCounterClaim };
      mutatedChangeOrderCounterClaim.state = ActiveState.INACTIVE;
      changeOrderCounterClaimContext.updateChangeOrderCounterClaim(mutatedChangeOrderCounterClaim);
      onClose();
    }
    setOpenConfirmDeleteDialog(false);
  };

  const handleClose = (save: boolean) => {
    if (save && editedChangeOrderCounterClaim) {
      changeOrderCounterClaimContext.updateChangeOrderCounterClaim(editedChangeOrderCounterClaim);
    }
    onClose();
  };

  const title = changeOrderCounterClaim?.id ? `${languageContext.getMessage("edit")} ${languageContext.getMessage("counterClaim")}` : languageContext.getMessage("newCounterClaim");

  var screenIsMobile = useWidthContext().isMobileScreen();

  return (
    <>
      <Dialog maxWidth={screenIsMobile ? "xs" : "sm"} fullWidth={true} onClose={() => handleClose(false)} open={open} disableBackdropClick={true}>
        <DialogTitle>
          {title}
          <IconButton className={classes.closeButton} onClick={() => handleClose(false)} title={languageContext.getMessage("cancel")}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid container item spacing={1} xs={!screenIsMobile ? 6 : 12} alignItems="flex-start">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={languageContext.getMessage("name")}
                  value={editedChangeOrderCounterClaim?.name ?? ""}
                  onChange={(event) => {
                    if (editedChangeOrderCounterClaim) {
                      editedChangeOrderCounterClaim.name = event.target.value;
                      setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectChangeOrderCounterClaimGroup
                  selectedChangeOrderCounterClaimGroup={editedChangeOrderCounterClaim?.group}
                  onSelectedChangeOrderCounterClaimGroupChange={(newSelectedChangeOrderCounterClaimGroup) =>
                    setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim, group: newSelectedChangeOrderCounterClaimGroup })
                  }
                  freeSolo={true}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label={languageContext.getMessage("description")}
                  value={editedChangeOrderCounterClaim?.changeOrderDescription ?? ""}
                  onChange={(event) => {
                    if (editedChangeOrderCounterClaim) {
                      editedChangeOrderCounterClaim.changeOrderDescription = event.target.value;
                      setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectAccount
                  selectedAccount={accountContext.getAccount(editedChangeOrderCounterClaim?.accountId)}
                  onSelectedAccountChange={(newSelectedAccount) => {
                    if (editedChangeOrderCounterClaim) {
                      editedChangeOrderCounterClaim.accountId = newSelectedAccount?.id;
                      setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                    }
                  }}
                  label={`${languageContext.getMessage("account")} *`}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectContract
                  selectedContract={contractContext.getContract(editedChangeOrderCounterClaim?.contractId ?? "")}
                  selectedAccount={accountContext.getAccount(editedChangeOrderCounterClaim?.accountId ?? "")}
                  onSelectedContractChange={(contract) => {
                    if (editedChangeOrderCounterClaim) {
                      setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim, contractId: contract?.id ?? getEmptyGuid() });
                    }
                  }}
                  label={`${languageContext.getMessage("contract")} *`}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={1} xs={!screenIsMobile ? 6 : 12} alignItems="flex-start">
              <Grid item xs={12}>
                <Autocomplete
                  style={{ width: 240 }}
                  options={Object.values(ChangeOrderStatus)}
                  getOptionLabel={(status) => changeOrderCounterClaimContext.convertChangeOrderStatusToString(status)}
                  value={editedChangeOrderCounterClaim?.status}
                  onChange={(event, newChangeOrderStatus) => {
                    if (editedChangeOrderCounterClaim) {
                      editedChangeOrderCounterClaim.status = newChangeOrderStatus ?? undefined;
                      setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label={<>{languageContext.getMessage("columnStatus")}</>} />}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label={languageContext.getMessage("sentDate")}
                  date={editedChangeOrderCounterClaim?.sent}
                  onChange={(event) => {
                    if (editedChangeOrderCounterClaim) {
                      editedChangeOrderCounterClaim.sent = event;
                      setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label={languageContext.getMessage("answeredDate")}
                  date={editedChangeOrderCounterClaim?.answered}
                  onChange={(event) => {
                    if (editedChangeOrderCounterClaim) {
                      editedChangeOrderCounterClaim.answered = event;
                      setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CurrencyInput
                  fullWidth
                  label={languageContext.getMessage("costRequirements")}
                  value={editedChangeOrderCounterClaim?.costRequirements ?? 0}
                  onChange={(amount) => {
                    if (editedChangeOrderCounterClaim) {
                      editedChangeOrderCounterClaim.costRequirements = amount;
                    }
                    setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CurrencyInput
                  fullWidth
                  label={languageContext.getMessage("cost")}
                  value={editedChangeOrderCounterClaim?.cost ?? 0}
                  onChange={(amount) => {
                    if (editedChangeOrderCounterClaim) {
                      editedChangeOrderCounterClaim.cost = amount;
                    }
                    setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label={languageContext.getMessage("comments")}
                value={editedChangeOrderCounterClaim?.changeOrderComment ?? ""}
                onChange={(event) => {
                  if (editedChangeOrderCounterClaim) {
                    editedChangeOrderCounterClaim.changeOrderComment = event.target.value;
                    setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={`${languageContext.getMessage("enableProgressConsequence")}`}
                control={
                  <Checkbox
                    name="progressConsequenceEnabled"
                    checked={editedChangeOrderCounterClaim?.progressConsequenceEnabled ?? false}
                    onChange={() => {
                      if (editedChangeOrderCounterClaim) {
                        editedChangeOrderCounterClaim.progressConsequenceEnabled = !(editedChangeOrderCounterClaim.progressConsequenceEnabled ?? false);
                        setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                      }
                    }}
                  />
                }
              />
            </Grid>

            {editedChangeOrderCounterClaim && editedChangeOrderCounterClaim?.progressConsequenceEnabled && (
              <Grid item xs={12}>
                <TextField
                  label={`${languageContext.getMessage("progressConsequence")} ${languageContext.getMessage("description")}`}
                  multiline
                  value={editedChangeOrderCounterClaim?.progressConsequenceDescription ?? ""}
                  onChange={(event) => {
                    if (editedChangeOrderCounterClaim) {
                      editedChangeOrderCounterClaim.progressConsequenceDescription = event.target.value;
                      setEditedChangeOrderCounterClaim({ ...editedChangeOrderCounterClaim });
                    }
                  }}
                  fullWidth
                />
              </Grid>
            )}
            {changeOrderCounterClaim?.id && (
              <Grid item xs={12}>
                <Typography variant="body2">{`${languageContext.getMessage("lastChangedBy")} ${users.find((user) => user.id === editedChangeOrderCounterClaim?.changedBy)?.name}`}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!handlingNewChangeOrder && (
            <Button onClick={handleOnClickDelete} color="primary" variant="contained">
              {languageContext.getMessage("delete")}
            </Button>
          )}
          <Button onClick={() => handleClose(true)} color="primary" variant="contained" disabled={editedChangeOrderCounterClaim?.contractId === undefined ||  editedChangeOrderCounterClaim?.accountId === undefined}>
            {languageContext.getMessage("save")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog title={languageContext.getMessage("doYouWantToDeleteChangeOrder")} open={openConfirmDeleteDialog} onClose={async (deleteIsConfirmed) => handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)} />
    </>
  );
};

export default withTheme(EditChangeOrderCounterClaimDialog);
