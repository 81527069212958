import { makeStyles, Theme, withTheme, IconButton, CardHeader, Card, CardContent, Button, CardActions } from "@material-ui/core";
import { Doughnut, ChartComponentProps } from 'react-chartjs-2';
import React from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '0.5em',
    }
}));
  
  type props = {
    chartComponentProps: ChartComponentProps;
    chartOptions: any;
    chartTitle: React.ReactNode;
    chartSubHeader: React.ReactNode;
}


const DoughnutGraph: React.FC<props> = (props) => {
    const classes = useStyles();
    return (<>
        <Card className={classes.card}>
            <CardHeader
                title={props.chartTitle}
                subheader={props.chartSubHeader}
            />
            <CardContent>
                <Doughnut 
                    {...props.chartComponentProps} 
                    options={props.chartOptions} />
            </CardContent>
        </Card>
    </>
    );
}

export default withTheme(DoughnutGraph);