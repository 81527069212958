import { RoleType, User, UserRole, DocumentType } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { LanguageContext } from "../language/interfaces";
import { ProjectContext } from "../project/projectContext";
import { UserRoleContext } from "./userRoleContext";

export const notSpecified = 'notSpecified'

export function checkProjectAccess(roleType: RoleType, accountRoles: string[], projectId?: Guid | undefined): boolean {
  return accountRoles.findIndex(role => {
      const roleProjectId = getProjectIdFromProjectRole({name: role});
      const accountRoleType = getRoleType({name: role});
      if (projectId && roleProjectId === projectId && accountRoleType && accountRoleType === roleType) {
          return true;
      } 
      else if (!roleProjectId && accountRoleType && accountRoleType === roleType) {
          return true;
      }
      return false;
  }) >= 0;
}

export function getProjectIdFromProjectRole(role: UserRole): string | undefined {
    if (!role.name || role.name.indexOf(`${DocumentType.PROJECT}-`) < 0) {
      return undefined;
    }
    let roleProjectId = role.name.slice(role.name.indexOf('-')+1);
    roleProjectId = roleProjectId.slice(0, roleProjectId.lastIndexOf('-'));
    return roleProjectId;
  }

export function getRoleType(role: UserRole | undefined): RoleType | undefined {
    if (!role?.name) {
      return undefined;
    }
    const roleType = role.name.slice(role.name.lastIndexOf('-')+1);
    return Object.values(RoleType).find(roleTypeEnum => roleTypeEnum === roleType);
}

export function getRoleTypeIndex(roleType: RoleType | undefined): number {
    return Object.values(RoleType).findIndex(roleTypeEnum => roleTypeEnum === roleType);
}

export function getRoleDescription(role: UserRole | undefined, 
                                   languageContext: LanguageContext,
                                   projectContext?: ProjectContext): string {
    if (!role) {
      return languageContext.getMessage(notSpecified);
    }
    const roleType = getRoleType(role);
    const roleTypeMessage = languageContext.getMessage(roleType as RoleType);
    const roleProjectId = getProjectIdFromProjectRole(role);
    const matchingProject = projectContext ? projectContext.getProject(roleProjectId) : undefined;
    if (matchingProject) {
      return `${roleTypeMessage}: ${matchingProject.name}`
    }
    return roleTypeMessage ?? '';
}

export function getRolesFromUser(user: User, projectContext?: ProjectContext): UserRole[] {
    const selectedProject = projectContext ? projectContext.getSelectedProject() : undefined;
    const projectRoles = user.roles?.filter(role => {
      const roleProjectId = getProjectIdFromProjectRole(role);
      if (projectContext === undefined && roleProjectId === undefined) {
          return true;
      }
      return projectContext !== undefined && roleProjectId !== undefined && roleProjectId === selectedProject?.id;
    })
    return projectRoles ?? [];
}

export function getDominantRoleFromUser(user: User, projectContext?: ProjectContext): UserRole | undefined {
    const userRoles = getRolesFromUser(user, projectContext).filter(role => role.delete !== true);
    let dominantRole: UserRole | undefined = undefined;
    const roleTypes = Object.values(RoleType);
    for (let i = 0; i < roleTypes.length; i++) {
      const newDominantRole = userRoles?.find(userRole => {
        const roleType = getRoleType(userRole);
        return roleType !== undefined && roleType === roleTypes[i];
      })
      if (newDominantRole !== undefined) {
        dominantRole = newDominantRole;
      }
    }
    return dominantRole;
}

export function updateUserRoles(user: User, roleId: string, 
                                userRoleContext: UserRoleContext, 
                                projectContext?: ProjectContext): void {
    user.roles = user.roles ?? [];
    let userRoles = getRolesFromUser(user, projectContext);
    if (roleId === notSpecified) {
      userRoles.forEach(role => {
        role.delete = true;
      })
      return;
    }
    const roles = userRoleContext.getUserRoles(projectContext ? (projectContext.getSelectedProject() ?? {}) : undefined);
    let newRole = roles.find(role => role.id === roleId);
    if (!newRole) {
      return;
    }
    let roleExistsOnUser = false;
    for (let i = 0; i < userRoles.length; i++) {
      if (userRoles[i].id === newRole) {
        userRoles[i].delete = false;
        roleExistsOnUser = true;
      }
    }
    if (!roleExistsOnUser) {
      user.roles.push({...newRole});        
      userRoles = getRolesFromUser(user, projectContext);
    }
    const roleTypes = Object.values(RoleType);
    const roleTypesToDelete: string[] = [];
    const roleType = getRoleType(newRole);
    for (let i = 0; i < roleTypes.length; i++) {
      if (roleType !== undefined && roleTypes[i] === roleType) {
        for (let j = i+1; j < roleTypes.length; j++) {
          roleTypesToDelete.push(roleTypes[j]);
        }
        break;
      } 
    }
    userRoles.forEach(role => {
      const userRoleType = getRoleType(role);
      if (userRoleType !== undefined && roleTypesToDelete.findIndex(roleTypeToDelete => roleTypeToDelete === userRoleType) > -1) {
        role.delete = true;
      }
    })
}