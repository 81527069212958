import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import enGBLocale from "date-fns/locale/en-GB";
import nbLocale from "date-fns/locale/nb";
import React from "react";
import { useLanguageContext } from "../language/LanguageContext";

const DateFnslocaleMap = {
    en: enGBLocale,
    nb: nbLocale,
};

export const DateFnsProvider: React.FC<{}> = ({ children }) => {
    const languageContext = useLanguageContext()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={DateFnslocaleMap[languageContext.getLanguage()]}>
            {children}
        </MuiPickersUtilsProvider>
    );
}
