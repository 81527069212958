import React, { createContext, useContext, useEffect, useState } from "react";
import { ChangeOrderSubContractor } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_CHANGE_ORDER_SUB_CONTRACTOR, getChangeOrderSubContractorsGraphqlQueryOptions } from './changeOrderSubContractorQueries'
import { useAuthContext } from "../../auth/authContext";

export interface ChangeOrderSubContractorQueriesContext {
    fetchedChangeOrderSubContractors: Array<ChangeOrderSubContractor>,
    queryChangeOrderSubContractors: (changeOrderSubContractor: ChangeOrderSubContractor) => void,
}

const ChangeOrderSubContractorQueriesContext = createContext<ChangeOrderSubContractorQueriesContext>(null as unknown as ChangeOrderSubContractorQueriesContext);

export const ChangeOrderSubContractorQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedChangeOrderSubContractors, setFetchedChangeOrderSubContractors] = useState<Array<ChangeOrderSubContractor>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_CHANGE_ORDER_SUB_CONTRACTOR);

    const queryChangeOrderSubContractors = (changeOrderSubContractor: ChangeOrderSubContractor): void => {
        const queryOptions = getChangeOrderSubContractorsGraphqlQueryOptions(changeOrderSubContractor);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.changeOrderSubContractors) {
            const newFetchedChangeOrderSubContractors: Array<ChangeOrderSubContractor> = queryResponse.data.changeOrderSubContractors;
            setFetchedChangeOrderSubContractors(newFetchedChangeOrderSubContractors);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedChangeOrderSubContractors([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const changeOrderSubContractorQueriesContext: ChangeOrderSubContractorQueriesContext = {
        fetchedChangeOrderSubContractors,
        queryChangeOrderSubContractors,
    };

    return (
        <ChangeOrderSubContractorQueriesContext.Provider value={changeOrderSubContractorQueriesContext}>
            {children}
        </ChangeOrderSubContractorQueriesContext.Provider>
    );
}

export const useChangeOrderSubContractorQueriesContext = (): ChangeOrderSubContractorQueriesContext => {
    return useContext(ChangeOrderSubContractorQueriesContext);
}
