import { Divider, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Theme, Typography, withTheme } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import React, { ReactElement, useState } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useRiskContext } from "../../contexts/risk/riskContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { Risk, RiskType } from "../../contracts/contracts";
import { getMobileRenderedRiskStatus } from "../../utils/statusToPalettes";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    button: {
        margin: theme.spacing(1),
    },
    root: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
      },
}));

type props = {
    selectedRiskType: RiskType | undefined;
    onSelectedRiskChange?: (newSelectedRisk: Risk | undefined) => void;
}

const RiskMobileList: React.FC<props> = ({
    selectedRiskType,
    onSelectedRiskChange }) => {

    const classes = useStyles();

    const riskContext = useRiskContext();

    const languageContext = useLanguageContext();
    const { getLocalization } = useMaterialTableLanguageContext();
    const themeContext = useThemeContext();
    const theme = themeContext.getTheme();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [riskToEdit, setRiskToEdit] = useState<Risk>({});

    const openRiskDialog = (riskToEdit: Risk) => {
        setRiskToEdit({...riskToEdit});
        setShowDialog(true);
    };

    const onCloseRiskDialog = () => {
        setShowDialog(false);
    };

    const risks = riskContext.getRisks();

    const fabs = 
        {
          color: 'primary' as 'primary',
          className: classes.fab,
          icon: <AddIcon />,
          label: 'Add',
        }

    function data(risks: Risk[]): ReactElement {
        let buffer = [];
        for (var i=0; i<risks.length; i++) {
                buffer.push(<>
                <ListItem>
                    <ListItemAvatar>
                        {getMobileRenderedRiskStatus(risks[i].riskCost ?? 0)}
                    </ListItemAvatar>
                    <ListItemText primary={risks[i].name} secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            >
                        </Typography>
                    {risks[i].comment?.substring(0,50)} ...
                    <Typography>
                    Kost: {risks[i].riskCost?.toLocaleString(undefined, {maximumFractionDigits:2})} NOK
                    </Typography>
                    </React.Fragment> 
                        } />
                 </ListItem>
                 <Divider variant="inset" component="li" />
                 </>)
        }

        return <>{buffer}</>
    }  


    return (<>
        <List className={classes.root}>
            {data(risks)}
        </List>
    </>
    );
}

export default withTheme(RiskMobileList);