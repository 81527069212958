import React, { createContext, useContext, useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/react-hooks';
import { useAuthContext } from "../auth/authContext";
import { AuthSettings } from "../auth/interfaces";
import gql from "graphql-tag";
import { useBrowserContext } from "../browserContext/browserContext";

export interface GraphqlAuthSettingsContext {
    tenantRealmExists: boolean | undefined;
}

const GraphqlAuthSettingsContext = createContext<GraphqlAuthSettingsContext>(null as unknown as GraphqlAuthSettingsContext);

export const GET_AUTHORIZATION_SETTINGS = gql`
    query authSettings {
        authorizationSettings {
            url
            realm
            accountSettingsUrl
            authOff
        }
    }
`;

export const AIMZ_CLIENT_ID = 'neate-aimz-client'

export const GraphqlAuthSettingsContextProvider: React.FC<{}> = ({ children }) => {

    const browserContext = useBrowserContext();
    const authContext = useAuthContext();

    const [tenantRealmExists, setTenantRealmExists] = useState<boolean | undefined>(undefined);
    const [loadQuery, queryResponse] = useLazyQuery(GET_AUTHORIZATION_SETTINGS);

    useEffect(() => {
        if (browserContext.isTenantNameAvailable()) {
            loadQuery();
        }
    }, [])

    useEffect(() => {
        if (!authContext.authInitialized && queryResponse.data) {
            if (!queryResponse.error) {
                const authSettings: AuthSettings = {
                    url: queryResponse.data?.authorizationSettings?.url ?? "",
                    realm: queryResponse.data?.authorizationSettings?.realm ?? "",
                    clientId: AIMZ_CLIENT_ID,
                    accountSettingsUrl: queryResponse.data?.authorizationSettings?.accountSettingsUrl ?? "",
                    authOff: queryResponse.data?.authorizationSettings?.authOff ?? false,
                };
                authContext.setAuthSettings(authSettings);
                setTenantRealmExists(true);
            } else {
                console.log(queryResponse.error);
                if (queryResponse.error.message.includes('Realm does not exist')) {
                    setTenantRealmExists(false);
                }
                else {
                    setTenantRealmExists(undefined);
                }
            }
        }
    }, [queryResponse.data])

    const value = {
        tenantRealmExists,
    };

    return (
        <GraphqlAuthSettingsContext.Provider value={value}>
            {children}
        </GraphqlAuthSettingsContext.Provider>
    );
}

export const useGraphqlAuthSettingsContext = (): GraphqlAuthSettingsContext => {
    return useContext(GraphqlAuthSettingsContext);
}