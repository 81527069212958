import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, ChangeOrderLandlord, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ChangeOrderLandlordSubscriptionsContext {
    subscribedChangeOrderLandlords: Array<ChangeOrderLandlord>,
}

const ChangeOrderLandlordSubscriptionsContext = createContext<ChangeOrderLandlordSubscriptionsContext>(null as unknown as ChangeOrderLandlordSubscriptionsContext);

export const ChangeOrderLandlordSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedChangeOrderLandlords, setSubscribedChangeOrderLandlords] = useState<Array<ChangeOrderLandlord>>([]);

    const updateSubscribedChangeOrderLandlords = (stateDocuments: StateDocument[]) => {
        const subscribedChangeOrderLandlords = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.CHANGE_ORDER_LANDLORD && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as ChangeOrderLandlord);
        setSubscribedChangeOrderLandlords(subscribedChangeOrderLandlords);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedChangeOrderLandlords([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedChangeOrderLandlords(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: ChangeOrderLandlordSubscriptionsContext = {
        subscribedChangeOrderLandlords,
    };

    return (
        <ChangeOrderLandlordSubscriptionsContext.Provider value={projectContext}>
            {children}
        </ChangeOrderLandlordSubscriptionsContext.Provider>
    );
}

export const useChangeOrderLandlordSubscriptionsContext = (): ChangeOrderLandlordSubscriptionsContext => {
    return useContext(ChangeOrderLandlordSubscriptionsContext);
}
