import { ListItem, IconButton, ListItemAvatar, Avatar, Divider, ListItemSecondaryAction } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";

import { StorageFile } from "../../contracts/contracts";
import { GetApp } from "@material-ui/icons";

type props = {
  avatar?: string;
  avatarColor?: string;
  content: any;
  file: StorageFile;
  lastLine: boolean;
  onFileDownloadClick: (file: StorageFile) => void;
  onFileDeleteClick?: (file: StorageFile) => void;
};

const StorageFileListItem: React.FC<props> = ({ avatar, avatarColor, content, file, lastLine, onFileDownloadClick, onFileDeleteClick }) => {
  return (
    <>
      <ListItem alignItems="flex-start" key={file.id}>
        {avatar && avatarColor && (
          <ListItemAvatar>
            <Avatar>{avatar}</Avatar>
          </ListItemAvatar>
        )}
        <React.Fragment></React.Fragment>
        {content}
        <ListItemSecondaryAction>
          <IconButton
            edge="start"
            // size="small"
            aria-label="delete"
            onClick={(e) => {
              onFileDownloadClick(file);
            }}
          >
            <GetApp  />
          </IconButton>
          {onFileDeleteClick && (
            <IconButton
              edge="start"
            //   size="small"
              aria-label="delete"
              onClick={(e) => {
                onFileDeleteClick(file);
              }}
            >
              <Delete  />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="middle" component="li" />
    </>
  );
};

export default StorageFileListItem;
