import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, ChangeOrderSubContractor, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ChangeOrderSubContractorSubscriptionsContext {
    subscribedChangeOrderSubContractors: Array<ChangeOrderSubContractor>,
}

const ChangeOrderSubContractorSubscriptionsContext = createContext<ChangeOrderSubContractorSubscriptionsContext>(null as unknown as ChangeOrderSubContractorSubscriptionsContext);

export const ChangeOrderSubContractorSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedChangeOrderSubContractors, setSubscribedChangeOrderSubContractors] = useState<Array<ChangeOrderSubContractor>>([]);

    const updateSubscribedChangeOrderSubContractors = (stateDocuments: StateDocument[]) => {
        const subscribedChangeOrderSubContractors = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.CHANGE_ORDER_SUB_CONTRACTOR && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as ChangeOrderSubContractor);
        setSubscribedChangeOrderSubContractors(subscribedChangeOrderSubContractors);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedChangeOrderSubContractors([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedChangeOrderSubContractors(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: ChangeOrderSubContractorSubscriptionsContext = {
        subscribedChangeOrderSubContractors,
    };

    return (
        <ChangeOrderSubContractorSubscriptionsContext.Provider value={projectContext}>
            {children}
        </ChangeOrderSubContractorSubscriptionsContext.Provider>
    );
}

export const useChangeOrderSubContractorSubscriptionsContext = (): ChangeOrderSubContractorSubscriptionsContext => {
    return useContext(ChangeOrderSubContractorSubscriptionsContext);
}
