import { DialogActions, FormControl, InputLabel, useTheme, DialogContent, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import withTheme from '@material-ui/styles/withTheme';
import React, { useEffect } from 'react';
import { useInterimContext } from '../../contexts/interim/InterimContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { InterimExpense, Account } from '../../contracts/contracts';
import CurrencyInput from '../currencyComponents/CurrencyInput';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
}));

export interface EditInterimProps {
    account: Account | undefined;
    open: boolean;
    onClose: () => void;
}

const EditInterimDialog: React.FC<EditInterimProps> = (props: EditInterimProps) => {
    const languageContext = useLanguageContext();
    const interimContext = useInterimContext();

    const classes = useStyles();
    const { account, onClose, open } = props;
    const theme = useTheme();

    const [editedInterimExpense, setEditedInterimExpense] = React.useState<InterimExpense | undefined>({});

    useEffect(() => {
      if (!account) {
        setEditedInterimExpense(undefined);
      }
      else {
        setEditedInterimExpense({...interimContext.getInterimExpense(account)});
      }
    }, [account])

    const handleClose = (save: boolean) => {
      if (save && editedInterimExpense && account?.id) {
        const interim = interimContext.getInterims().find(interim => interim.parentDocumentId === account.id);
        interimContext.updateInterim({...interim, parentDocumentId: account.id, monthlyInterimItems: [editedInterimExpense]});
      }
      onClose();
    };

    const title = `${languageContext.getMessage('edit')} ${languageContext.getMessage('account')} ${languageContext.getMessage('interim')}`;

    return (
        <Dialog onClose={() => handleClose(false)} open={open}>
            <DialogTitle>
              {title}
              <IconButton className={classes.closeButton} onClick={() => handleClose(false)} title={languageContext.getMessage('cancel')}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent></DialogContent>
            <FormControl className={classes.formControl}>
                <CurrencyInput
                    label={languageContext.getMessage('producedNotBilled')}
                    value={editedInterimExpense?.producedNotBilled ?? 0.0}
                    onChange={newProducedNotBilled => {
                        if (editedInterimExpense) {
                          editedInterimExpense.producedNotBilled = newProducedNotBilled;
                        }
                        setEditedInterimExpense({...editedInterimExpense});
                      }
                    }
                    fullWidth
                  />
            </FormControl>
            <DialogActions>
              <Button onClick={() => handleClose(true)} color="primary" variant="contained">
                {languageContext.getMessage('save')}
              </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme(EditInterimDialog);