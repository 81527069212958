import { Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useChangeOrderLandlordContext } from "../../contexts/changeOrderLandlord/changeOrderLandlordContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { ChangeOrderStatus, DocumentType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import ChangeOrderLandlordViewAll from "./ChangeOrderLandlordViewAll";
import { useUserContext } from "../../contexts/user/UserContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

type props = {};

const ChangeOrderLandlordView: React.FC<props> = ({}) => {
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const projectContext = useProjectContext();
  const accountContext = useAccountContext();
  const changeOrderLandlordContext = useChangeOrderLandlordContext();
  const urlContext = useUrlContext();
  const userRoleContext = useUserRoleContext();
  const ticketContext = useTicketContext();

  const urlState = urlContext.getUrlState();
  const tabIndex = urlState.tab ? (urlState.tab as string) : "all";
  const selectedStatus = urlState.status ? (urlState.status as ChangeOrderStatus) : undefined;

  const accountSearch = accountContext.getAccountSearch();
  const changeOrderLandlordSearch = changeOrderLandlordContext.getChangeOrderLandlordSearch();

  useEffect(() => {
    if (userRoleContext.getUsers().length === 0) {
      userRoleContext.queryUsers();
    }
    ticketContext.setDocumentTypesToWatch([DocumentType.ACCOUNT, DocumentType.CHANGE_ORDER_LANDLORD]);
    changeOrderLandlordContext.searchChangeOrderLandlords(changeOrderLandlordSearch);
    accountContext.searchAccounts(accountSearch);
  }, [urlContext.currentLocation, projectContext.getSelectedProject()]);

  const updateUrlState = (newSelectedStatus: ChangeOrderStatus | undefined, newTabIndex: string): void => {
    const newUrlState = {
      ...changeOrderLandlordSearch,
      ...{ status: newSelectedStatus },
      ...{ tab: newTabIndex },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined | any>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const handleTabIndexChange = (event: React.ChangeEvent<{}> | undefined, newTabIndex: string) => {
    updateUrlState(selectedStatus, newTabIndex);
  };

  const onSelectedStatusChange = (newSelectedStatus: ChangeOrderStatus | undefined): void => {
    updateUrlState(newSelectedStatus, tabIndex);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
          <Tabs value={tabIndex} onChange={handleTabIndexChange} aria-label="simple tabs example">
            <Tab label={languageContext.getMessage("all")} value="all" />
          </Tabs>
        </Toolbar>
      </Grid>

      {tabIndex === "all" && (
        <Grid item xs={12}>
          <ChangeOrderLandlordViewAll selectedStatus={selectedStatus} onSelectedStatusChange={onSelectedStatusChange} />
        </Grid>
      )}
    </Grid>
  );
};

export default withTheme(ChangeOrderLandlordView);
