import { DialogActions, DialogContent, Grid, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import withTheme from '@material-ui/styles/withTheme';
import React, { useEffect, useState } from 'react';
import { useChangeOrderCounterClaimContext } from '../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { ChangeOrderCounterClaim } from '../../contracts/contracts';

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.error.main,
    marginRight: '0.25em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

export interface Props {
  changeOrderCounterClaimGroup: string | undefined;
  open: boolean;
  onClose: () => void;
}

const EditChangeOrderCounterClaimGroupDialog: React.FC<Props> = ({
  changeOrderCounterClaimGroup,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();

  const [editedChangeOrderCounterClaimGroup, setEditedChangeOrderCounterClaimGroup] = useState<string | undefined>(undefined);

  useEffect(() => {
    setEditedChangeOrderCounterClaimGroup(editedChangeOrderCounterClaimGroup);
  }, [changeOrderCounterClaimGroup])

  const handleOnClickSave = () => {
    const groupedChangeOrderCounterClaims = changeOrderCounterClaimContext.getChangeOrderCounterClaims().filter(changeOrderCounterClaim => changeOrderCounterClaim.group === changeOrderCounterClaimGroup);
    groupedChangeOrderCounterClaims.forEach(groupedChangeOrderCounterClaim => {
      const changeOrderCounterClaimToMutate: ChangeOrderCounterClaim = { ...groupedChangeOrderCounterClaim, group: editedChangeOrderCounterClaimGroup };
      changeOrderCounterClaimContext.updateChangeOrderCounterClaim(changeOrderCounterClaimToMutate);
    });
    onClose();
  };

  const handleOnClickClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleOnClickClose}
        open={open}
        disableBackdropClick={true}>
        <DialogTitle>
          {languageContext.getMessage('editChangeOrderGroup')}
          <IconButton className={classes.closeButton} onClick={handleOnClickClose} title={languageContext.getMessage('cancel')}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage('changeOrderGroup')}
                defaultValue={changeOrderCounterClaimGroup}
                onChange={(event) => setEditedChangeOrderCounterClaimGroup(event.target.value as string)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClickSave} color="primary" variant="contained">
            {languageContext.getMessage('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withTheme(EditChangeOrderCounterClaimGroupDialog);