const trimStringsInArray = (input: string[]):string[] => {
    return input.map(text => text.trim());
} 

export const csvToJson = (text: string): any[] | null => {
    const lines = text.split("\n");
    if(lines.length < 2) return null;

    const properties = trimStringsInArray(lines[0].split(";"));


    const result:any[] = [];
    for(let i = 1; i < lines.length; i++){
        const values = trimStringsInArray(lines[i].split(";"));

        var obj:any = {};

        for (let index = 0; index < values.length; index++) {
            const p = properties[index];
            const v = values[index];
            
            obj[p] = v;
        }

        result.push(obj);
    }

    return result;
}