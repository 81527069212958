
import React, { useEffect, useState } from "react";
import { withTheme, makeStyles, Theme, Grid, InputLabel, Select, MenuItem, FormControl} from '@material-ui/core';
import { LanguageType, useLanguageContext } from '../../contexts/language/LanguageContext';
import { ThemeEnum } from '../../contexts/theme/ThemeContext';
import { useThemeContext } from '../../contexts/theme/ThemeContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { Project } from '../../contracts/contracts';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: '1em'
    },
    divider: {
        marginBottom: '1em'
    },
}));

export interface ThemeSettingsProps {
    project: Project | undefined,
  }

const ThemeSettings: React.FC<ThemeSettingsProps> = (props: ThemeSettingsProps) => {

    const classes = useStyles();
    const languageContext = useLanguageContext();
    const themeContext = useThemeContext();
    const projectContext = useProjectContext();

    const { project } = props;

    const [editingProject, setEditingProject] = useState<boolean>(false);

    const [editedProject, setEditedProject] = React.useState<Project | undefined>(projectContext.getSelectedProject());


    useEffect(() => {
        setEditedProject({...project});
      }, [project])


    const onEditProject = (save: boolean) => {
        if (save && editedProject && editingProject) {
            projectContext.updateProject({...editedProject});
        }
        setEditingProject(!editingProject);
    }


    return (<>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth={true}>
            <InputLabel>{languageContext.getMessage('theme')}</InputLabel>
                      <Select
                        value={themeContext.getThemeId()}
                        onChange={(event) => {if (event.target.value) 
                            {
                                let themeChoice = event.target.value as ThemeEnum;
                                themeContext.setThemeId(themeChoice)
                            }
                        }}
                      >
                        <MenuItem value={ThemeEnum.LIGHT}>{languageContext.getMessage('light')}</MenuItem>
                        <MenuItem value={ThemeEnum.DARK}>{languageContext.getMessage('dark')}</MenuItem>
                      </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth={true}>
            <InputLabel>{languageContext.getMessage('language')}</InputLabel>
                      <Select
                        value={languageContext.getLanguage()}
                        onChange={(event) => {if (event.target.value) 
                            {
                                let languageChoice = event.target.value as LanguageType;
                                languageContext.setLanguage(languageChoice)
                            }
                        }}
                      >
                        <MenuItem value={'nb'}>Norsk</MenuItem>
                        <MenuItem value={'en'}>English</MenuItem>
                      </Select>
                </FormControl>
            </Grid>
        </Grid>

        </>);
}

export default withTheme(ThemeSettings)