import React, { createContext, useContext } from "react";
import { Project, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getProjectsGraphqlMutationOptions, MUTATE_PROJECT } from "./projectMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface ProjectMutationsContext {
    mutateProject: (project: Project, onTicketSubscribed: (documentId: Guid, variables: any) => void) => void;
}

const ProjectMutationsContext = createContext<ProjectMutationsContext>(null as unknown as ProjectMutationsContext);

export const ProjectMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [projectMutation] = useMutation(MUTATE_PROJECT);

    const handleProjectMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateProject.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
    }

    const mutateProject = (project: Project, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        const options = getProjectsGraphqlMutationOptions(project);
        projectMutation(options).then((result) => handleProjectMutationResult(result, options.variables, onTicketSubscribed));
    }

    const projectMutationsContext: ProjectMutationsContext = {
        mutateProject
    };

    return (
        <ProjectMutationsContext.Provider value={projectMutationsContext}>
            {children}
        </ProjectMutationsContext.Provider>
    );
}

export const useProjectMutationsContext = (): ProjectMutationsContext => {
    return useContext(ProjectMutationsContext);
}
