import { Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import ChangeOrderExpectedViewAll from "./ChangeOrderExpectedViewAll";
import ChangeOrderExpectedDetailsView from "./ChangeOrderExpectedViewDetails";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { DocumentType, Account, ChangeOrderExpected, ChangeOrderStatus } from "../../contracts/contracts";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

type props = {};

const ChangeOrderExpectedView: React.FC<props> = ({}) => {
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const urlContext = useUrlContext();
  const userRoleContext = useUserRoleContext();
  const projectContext = useProjectContext();
  const ticketContext = useTicketContext();

  const urlState = urlContext.getUrlState();
  const tabIndex = urlState.tab ? (urlState.tab as string) : "all";
  const selectedAccountId = urlState.accountId ? (urlState.accountId as string) : undefined;
  const selectedStatus = urlState.status ? (urlState.status as ChangeOrderStatus) : undefined;
  const selectedChangeOrderExpectedId = urlState.changeOrderExpectedId ? (urlState.changeOrderExpectedId as string) : undefined;
  const selectedChangeOrderExpected = selectedChangeOrderExpectedId ? changeOrderExpectedContext.getChangeOrderExpected(selectedChangeOrderExpectedId) ?? undefined : undefined;

  const changeOrderExpectedSearch = changeOrderExpectedContext.getChangeOrderExpectedSearch();
  const accountSearch = accountContext.getAccountSearch();
  const contractSearch = contractContext.getContractSearch();

  useEffect(() => {
    if (userRoleContext.getUsers().length === 0) {
      userRoleContext.queryUsers();
    }
    ticketContext.setDocumentTypesToWatch([DocumentType.ACCOUNT, DocumentType.CONTRACT, DocumentType.CHANGE_ORDER_EXPECTED]);
    changeOrderExpectedContext.searchChangeOrderExpecteds(changeOrderExpectedSearch);
    accountContext.searchAccounts(accountSearch);
    contractContext.searchContracts(contractSearch);
  }, [urlContext.currentLocation, projectContext.getSelectedProject()]);

  const selectedAccount = accountContext.getAccount(selectedAccountId);

  const updateUrlState = (
    newSelectedAccountId: string | undefined,
    newSelectedStatus: ChangeOrderStatus | undefined,
    newSelectedChangeOrderExpected: ChangeOrderExpected | undefined,
    newTabIndex: string
  ): void => {
    const newUrlState = {
      ...changeOrderExpectedSearch,
      ...{ accountId: newSelectedAccountId },
      ...{ status: newSelectedStatus },
      ...{ changeOrderExpectedId: newSelectedChangeOrderExpected?.id },
      ...{ tab: newTabIndex },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined | any>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const handleTabIndexChange = (event: React.ChangeEvent<{}> | undefined, newTabIndex: string) => {
    updateUrlState(selectedAccountId, selectedStatus, selectedChangeOrderExpected, newTabIndex);
  };

  const onSelectedAccountChange = (newSelectedAccount: Account | undefined): void => {
    updateUrlState(newSelectedAccount?.id, selectedStatus, selectedChangeOrderExpected, tabIndex);
  };

  const onSelectedStatusChange = (newSelectedStatus: ChangeOrderStatus | undefined): void => {
    updateUrlState(selectedAccountId, newSelectedStatus, selectedChangeOrderExpected, tabIndex);
  };

  const onSelectedChangeOrderExpectedChange = (newSelectedChangeOrderExpected: ChangeOrderExpected | undefined): void => {
    updateUrlState(selectedAccountId, selectedStatus, newSelectedChangeOrderExpected, "details");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
          <Tabs value={tabIndex} onChange={handleTabIndexChange} aria-label="simple tabs example">
            <Tab label={languageContext.getMessage("all")} value="all" />
            {/* <Tab label={languageContext.getMessage("details")} value='details' /> */}
          </Tabs>
        </Toolbar>
      </Grid>

      {tabIndex === "all" && (
        <Grid item xs={12}>
          <ChangeOrderExpectedViewAll
            selectedAccount={selectedAccount}
            selectedStatus={selectedStatus}
            onSelectedAccountChange={onSelectedAccountChange}
            onSelectedStatusChange={onSelectedStatusChange}
            onSelectedChangeOrderExpectedChange={onSelectedChangeOrderExpectedChange}
          />
        </Grid>
      )}

      {tabIndex === "details" && (
        <Grid item xs={12}>
          <ChangeOrderExpectedDetailsView selectedChangeOrderExpected={selectedChangeOrderExpected} onSelectedChangeOrderExpectedChange={onSelectedChangeOrderExpectedChange} />
        </Grid>
      )}
    </Grid>
  );
};

export default withTheme(ChangeOrderExpectedView);
