import { withTheme } from "@material-ui/core";
import { Theme } from '@material-ui/core/styles';
import React from "react";
import { CurrencyType, AccrualAndOperations, AccrualAndOperationsExpenses, AccrualAndOperationPostedExpense, AccrualAndOperationExpense } from "../../contracts/contracts";
import BarGraph from "../graphComponents/BarGraph";
import { ChartOptions, ChartData, ChartColor } from "chart.js";
import { LanguageContext } from "../../contexts/language/interfaces";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { extractUsedMonths } from "./AccrualAndOperationExpensesTools";
import { Dictionary } from "../../global-types";
import { getRandomColorFromString } from "../../utils/randomTools";
import { fillEmptyMonths, sortDatesAndExtractYears } from "../../utils/dateTools";


const addDefaultDataSet = (datasets: Dictionary<Chart.ChartDataSets>, dataKey: string, label: string, stacksKey: string) => {
    const color = getRandomColorFromString(dataKey);
    datasets[dataKey] = {
        label: label,
        data: [],
        backgroundColor: color,
        borderColor: color,
        // stack: stacksKey,
        fill: false,
    }
}

  
const accrualAndOperationsToBarData = (
    accrualAndOperations: AccrualAndOperations, 
    accrualAndOperationsExpenses: AccrualAndOperationsExpenses[], 
    from: Date | undefined, to: Date | undefined, 
    languageContext: LanguageContext, theme: Theme): ChartData => {

    const labels = new Array<string>();
    const finalAccrualAndOperations = new Array<number>();
    const barColors = new Array<ChartColor>();
    const monthlyExpenseItems: Dictionary<Dictionary<AccrualAndOperationExpense>> = {}
    const monthlyPostedExpenseItems: Dictionary<AccrualAndOperationPostedExpense> = {}
    
    let usedDates = extractUsedMonths(
        accrualAndOperations, 
        accrualAndOperationsExpenses, 
        monthlyExpenseItems, 
        monthlyPostedExpenseItems);
    usedDates = fillEmptyMonths(from, to, usedDates);
    const [sortedUsedDates, usedYears] = sortDatesAndExtractYears(usedDates);
    const datasets: Dictionary<Chart.ChartDataSets> = {};
    const stacksKey = 'monthlyPostedExpenseItems';
    addDefaultDataSet(datasets, 'actuallyPostedExpense', languageContext.getMessage('actuallyPostedExpense'), stacksKey);
    addDefaultDataSet(datasets, 'expectedPostedExpense', languageContext.getMessage('expectedPostedExpense'), stacksKey);

    sortedUsedDates.forEach(usedDate => {
        const dateStr = usedDate.toISOString()
        const monthlyPostedExpenseItem = dateStr in monthlyPostedExpenseItems ? monthlyPostedExpenseItems[dateStr] : undefined;
        labels.push(usedDate.toLocaleString(languageContext.getLanguage(), {month: 'long', year: '2-digit'}))
        let value = monthlyPostedExpenseItem?.actuallyPostedExpense ?? 0.0;
        datasets['actuallyPostedExpense'].data?.push(value);
        value = monthlyPostedExpenseItem?.expectedPostedExpense ?? 0.0;
        datasets['expectedPostedExpense'].data?.push(value);
    })

    const datasetsList: Chart.ChartDataSets[] = [];
    for (const key in datasets) {
        datasetsList.push(datasets[key]);
    }

    return {
        labels: labels,
        datasets: datasetsList
    }
}

let options = (languageContext: LanguageContext, currency: String): ChartOptions => {
    return {
        legend: {
            display: true,
            position: 'bottom',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                type: 'category',
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: languageContext.getMessage('accounts'),
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: languageContext.getMessage('amount') + ` (${currency})`,
                }
            }]
        }
    }
};

type props = {
    accrualAndOperations: AccrualAndOperations;
    accrualAndOperationsExpenses: AccrualAndOperationsExpenses[];
    from: Date | undefined,
    to: Date | undefined,
}

const AccrualAndOperationOverviewBarGraph: React.FC<props> = ({
    accrualAndOperations, 
    accrualAndOperationsExpenses,
    from, 
    to}) => {

    const languageContext = useLanguageContext();
    const themeContext = useThemeContext();
    const theme = themeContext.getTheme();

    const title = languageContext.getMessage('accrualAndOperations');
    let currency = CurrencyType.NOK;
    return (
        <BarGraph 
            chartComponentProps={{
                data: accrualAndOperationsToBarData(
                    accrualAndOperations, 
                    accrualAndOperationsExpenses,
                    from, to, 
                    languageContext, theme),
                height: 400,
            }}
            chartOptions={options(languageContext, currency)} 
            chartTitle={title} 
            chartSubHeader={''} />
    );
}

export default withTheme(AccrualAndOperationOverviewBarGraph);