import { QueryHookOptions } from "@apollo/react-hooks";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { StorageFile } from "../../../contracts/contracts";

export const QUERY_STORAGE_FILES: DocumentNode = gql`
  query storageFiles($searchIndexStart: Int, $searchIndexStop: Int, $includeContent: Boolean, $searchKey: String, $id: UUID, $key: String, $changed: DateTime, $changedBy: String) {
    storageFiles(searchIndexStart: $searchIndexStart, searchIndexStop: $searchIndexStop, includeContent: $includeContent, searchKey: $searchKey, id: $id, key: $key, changed: $changed, changedBy: $changedBy) {
      id
      key
      changed
      changedBy
      metaData
      content
    }
  }
`;

export const getStorageFilesGraphqlQueryOptions = (storageFile: StorageFile): QueryHookOptions => {
  const options: QueryHookOptions = {};
  options.variables = {};
  if (storageFile.searchIndexStart !== undefined) options.variables["searchIndexStart"] = storageFile.searchIndexStart;
  if (storageFile.searchIndexStop !== undefined) options.variables["searchIndexStop"] = storageFile.searchIndexStop;
  if (storageFile.searchKey !== undefined) options.variables["searchKey"] = storageFile.searchKey;
  if (storageFile.includeContent !== undefined) options.variables["includeContent"] = storageFile.includeContent;
  if (storageFile.id !== undefined) options.variables["id"] = storageFile.id;
  if (storageFile.key !== undefined) options.variables["key"] = storageFile.key;
  if (storageFile.changed !== undefined) options.variables["changed"] = storageFile.changed;
  if (storageFile.changedBy !== undefined) options.variables["changedBy"] = storageFile.changedBy;

  return options;
};
