import { Grid, withTheme } from "@material-ui/core";
import React from "react";
import { Account, Contract } from "../../contracts/contracts";
import ContractList from "../../component/contractComponents/ContractList";
import SelectContractFilter from "../../component/contractComponents/SelectContractFilter";

type props = {
  selectedAccount: Account | undefined;
  selectedSuppliers: string[] | undefined;
  onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
  onSelectedContractChange?: (newSelectedContract: Contract | undefined) => void;
  onSelectedSuppliersChange?: (newSelectedSuppliers: string[] | undefined) => void;
};

const ContractViewAll: React.FC<props> = ({ selectedAccount, selectedSuppliers, onSelectedAccountChange, onSelectedContractChange, onSelectedSuppliersChange }) => {
  return (
    <>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectContractFilter
              selectedAccount={selectedAccount}
              selectedSuppliers={selectedSuppliers}
              onSelectedAccountChange={onSelectedAccountChange}
              onSelectedSuppliersChange={onSelectedSuppliersChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ContractList accountId={selectedAccount?.id} selectedSuppliers={selectedSuppliers} onSelectedContractChange={onSelectedContractChange} />
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default withTheme(ContractViewAll);
