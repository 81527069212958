import {  Grid, Paper, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Account, ChangeOrderExpected, ChangeOrderStatus } from "../../contracts/contracts";
import ChangeOrderExpectedList from "../../component/changeOrderExpectedComponents/ChangeOrderExpectedList";
import SelectChangeOrderExpectedFilter from "../../component/changeOrderExpectedComponents/SelectChangeOrderExpectedFilter";
import SelectStatus from "../../component/changeOrderExpectedComponents/SelectStatus";
import ChangeOrderExpectedMobileList from "../../component/changeOrderExpectedComponents/ChangeOrderExpectedMobileList";

type props = {
    selectedAccount: Account | undefined;
    selectedStatus: ChangeOrderStatus | undefined;
    onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
    onSelectedStatusChange: (newSelectedStatus: ChangeOrderStatus | undefined) => void;
    onSelectedChangeOrderExpectedChange?: (newSelectedChangeOrderExpected: ChangeOrderExpected | undefined) => void;
}

const ChangeOrderExpectedDetailsView: React.FC<props> = ({
    selectedAccount,
    selectedStatus,
    onSelectedAccountChange,
    onSelectedStatusChange,
    onSelectedChangeOrderExpectedChange}) => {

    return (<>
            {(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 400 ?
            <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SelectChangeOrderExpectedFilter 
                        selectedAccount={selectedAccount}
                        selectedStatus={selectedStatus}
                        onSelectedAccountChange={onSelectedAccountChange}
                        onSelectedStatusChange={onSelectedStatusChange}/>
                </Grid>
                <Grid item xs={12}>
                    <ChangeOrderExpectedList 
                        selectedAccount={selectedAccount}
                        selectedStatus={selectedStatus}
                        onSelectedChangeOrderExpectedChange={onSelectedChangeOrderExpectedChange} />
                </Grid>
            </Grid>
            </>
            :
            <>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={3}>
                    <Paper elevation={2}>
                        <SelectStatus
                            selectedStatus={selectedStatus}
                            onSelectedStatusChange={onSelectedStatusChange} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <ChangeOrderExpectedMobileList 
                            selectedAccount={selectedAccount}
                            selectedStatus={selectedStatus}
                            onSelectedChangeOrderExpectedChange={onSelectedChangeOrderExpectedChange} />
                    </Paper>
                </Grid>
            </Grid>
            </>
            }
        </>
    );
}

export default withTheme(ChangeOrderExpectedDetailsView);