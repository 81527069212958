import React, { createContext, useContext, useEffect, useState } from "react";
import { useUrlContext } from "../url/urlContext";
import { ActiveState, Account, Interim, InterimExpense, DocumentType, Project, InvoiceFilter } from "../../contracts/contracts";
import { receiveInterims } from "./InterimTools";
import { InterimMutationsContextProvider, useInterimMutationsContext } from "./mutations/InterimMutationsContext";
import { InterimQueriesContextProvider, useInterimQueriesContext } from "./queries/InterimQueriesContext";
import { InterimSubscriptionsContextProvider, useInterimSubscriptionsContext } from "./subscriptions/InterimSubscriptionsContext";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { datesAreOnSameDay, datesAreOnSameMonth } from "../../utils/dateTools";
import { useAuthContext } from "../auth/authContext";
import { useProjectContext } from "../project/projectContext";
import { Guid } from "../../utils/common-types";
import { useInvoiceContext } from "../invoice/invoiceContext";
import { url } from "inspector";
import { InterimExpenseWithAccumulatedTag, queryTemplateEngineToProduceInterimProjectExcelList } from "./InterimProjectExportTools";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { useLanguageContext } from "../language/LanguageContext";

export interface InterimContext {
    getInterimSearch: () => Interim,
    searchInterim: (interimSearch: Interim | undefined, force: boolean) => void,
    getInterims: () => Interim[],
    getInterim: (accountId?: Guid) => Interim | undefined,
    getProjectInterim: (projectId?: Guid) => Interim | undefined,
    updateInterim: (interimToUpdate: Interim) => void,
    getInterimExpense: (account: Account) => InterimExpense,
    getProjectInterimExpense: (project?: Project) => InterimExpense,
    countNonInterimAccountedInvoices: (fromDate?: Date, toDate?: Date) => number,
    downloadInterimProject: (interims: InterimExpenseWithAccumulatedTag[]) => void,
}

const InterimContext = createContext<InterimContext>(null as unknown as InterimContext);

export const InterimContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <InterimMutationsContextProvider>
            <InterimQueriesContextProvider>
                <InterimSubscriptionsContextProvider>
                    <InterimSubContextProvider>
                        {children}
                    </InterimSubContextProvider>
                </InterimSubscriptionsContextProvider>
            </InterimQueriesContextProvider>
        </InterimMutationsContextProvider>
    );
}

export const InterimSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const invoiceContext = useInvoiceContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const interimsMutationsContext = useInterimMutationsContext();
    const interimsQueriesContext = useInterimQueriesContext();
    const interimsSubscriptionsContext = useInterimSubscriptionsContext();
    const [currentInterimsSearch, setCurrentInterimsSearch] = useState<Interim | undefined>(undefined);
    const [interims, setInterims] = useState<Array<Interim>>([]);

    const mergeInterims = (oldInterims: Array<Interim>, newInterims: Array<Interim>): Array<Interim> => {
        let updatedInterims = oldInterims.slice();
        if (!newInterims) {
            console.error(`Received undefined set of interims: ${newInterims}`);
            return [];
        }
        newInterims.forEach(newInterim => {
            if (newInterim.projectId !== currentInterimsSearch?.projectId) {
                return;
            }
            newInterim.created = new Date(newInterim.created ?? 0);
            newInterim = receiveInterims(currentInterimsSearch, newInterim);
            const index = updatedInterims.findIndex(updatedInterim => updatedInterim.id === newInterim.id);
            if (index >= 0) {
                if (newInterim.state === ActiveState.ACTIVE) {
                    updatedInterims[index] = newInterim;
                }
                else {
                    updatedInterims.splice(index, 1);
                }
            } 
            else {
                if (newInterim.state === ActiveState.ACTIVE) {
                    updatedInterims.push(newInterim); 
                }
            }
        });
        return updatedInterims;
    }

    const getInterimSearch = (): Interim => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
        const fromDate = urlState.fromDate ? new Date(urlState.fromDate as string) : undefined;
        const toDate = urlState.toDate ? new Date(urlState.toDate as string) : undefined;
        const fromDateIsEmpty = urlState.fromDateIsEmpty === 'true';
        const toDateIsEmpty = urlState.toDateIsEmpty === 'true';
    
        return {
            projectId: projectId,
            fromDate: fromDateIsEmpty === true ? undefined : fromDate,
            toDate: toDateIsEmpty === true ? undefined : toDate,
            fromDateIsEmpty: fromDateIsEmpty, 
            toDateIsEmpty: toDateIsEmpty,
        }
    }

    const searchInterim = (interimSearch: Interim | undefined, force: boolean): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(interimSearch, currentInterimsSearch);
        matched = matched && interimSearch?.projectId === currentInterimsSearch?.projectId;
        matched = matched && interimSearch?.parentDocumentId === currentInterimsSearch?.parentDocumentId;
        matched = matched && interimSearch?.parentDocumentType === currentInterimsSearch?.parentDocumentType;
        matched = matched && checkIfBothPropertiesAreUndefined(interimSearch?.fromDate, currentInterimsSearch?.fromDate) && datesAreOnSameDay(interimSearch?.fromDate ?? new Date(), currentInterimsSearch?.fromDate ?? new Date());
        matched = matched && checkIfBothPropertiesAreUndefined(interimSearch?.toDate, currentInterimsSearch?.toDate) && datesAreOnSameDay(interimSearch?.toDate ?? new Date(), currentInterimsSearch?.toDate ?? new Date());
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && (!matched || force)) {
            setCurrentInterimsSearch(interimSearch);
            setInterims([]);
        }
    }
    
    const getInterims = (): Interim[] => {
        return interims;
    }

    const getInterim = (accountId?: Guid): Interim | undefined => {
        if (!accountId) {
            return undefined;
        }
        return interims.find(interim => interim.parentDocumentId === accountId && interim.parentDocumentType === DocumentType.ACCOUNT);
    }

    const getProjectInterim = (projectId?: Guid): Interim | undefined => {
        projectId = projectId ?? projectContext.getSelectedProject()?.id;
        return interims.find(interim => interim.parentDocumentId === projectId && interim.parentDocumentType === DocumentType.PROJECT);
    }

    const updateInterim = (interimToUpdate: Interim) => {
        interimsMutationsContext.mutateInterim(interimToUpdate, (documentId, variables) => {
            interimToUpdate = {...interimToUpdate, ...variables};
            interimToUpdate.id = documentId;
            setInterims(mergeInterims(interims, [interimToUpdate]));
        });
    }

    const resolveCurrentInterimExpense = (interim: Interim | undefined): InterimExpense => {
        const currentMonth = getInterimSearch().fromDate ?? new Date()
        let interimExpense: InterimExpense = {
            date: currentMonth,
            parentDocumentId: interim?.parentDocumentId,
            parentDocumentType: interim?.parentDocumentType,
        };
        if (interim?.monthlyInterimItems && interim.monthlyInterimItems.length > 0) {
            let matchedInterimExpense = interim.monthlyInterimItems.find(monthlyInterimExpense => (monthlyInterimExpense.date && datesAreOnSameMonth(monthlyInterimExpense.date, currentMonth)));
            if (!matchedInterimExpense) {
                matchedInterimExpense = interim.monthlyInterimItems.slice().reverse().find(monthlyInterimExpense => (monthlyInterimExpense.date && monthlyInterimExpense.date <= currentMonth));
            }
            if (matchedInterimExpense !== undefined) {
                interimExpense = matchedInterimExpense;
            }
        }
        return interimExpense;
    }

    const getInterimExpense = (account: Account): InterimExpense => {
        const interim = getInterim(account?.id);
        return resolveCurrentInterimExpense(interim);
    } 

    const getProjectInterimExpense = (project?: Project): InterimExpense => {
        const interim = getProjectInterim(project?.id);
        return resolveCurrentInterimExpense(interim);
    } 

    const countNonInterimAccountedInvoices = (fromDate?: Date, toDate?: Date): number => {
        const invoiceFilter: InvoiceFilter = {
            approved: false,
            showInterimAccountedInvoices: false,
            fromDate: fromDate,
            toDate: toDate,
        }
        return invoiceContext.getFilteredInvoices(invoiceFilter).length;
    }

    const downloadInterimProject = (interims: InterimExpenseWithAccumulatedTag[]): void => {
        queryTemplateEngineToProduceInterimProjectExcelList(
            interims, 
            templateEngineQueriesContext, 
            languageContext);
    }

    useEffect(() => {
        if (currentInterimsSearch) {
            interimsQueriesContext.queryInterims(currentInterimsSearch)
        }
    }, [currentInterimsSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setInterims([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setInterims(mergeInterims([], interimsQueriesContext.fetchedInterims));
    }, [interimsQueriesContext.fetchedInterims]);

    useEffect(() => {
        setInterims(mergeInterims(interims, interimsSubscriptionsContext.subscribedInterims));
    }, [interimsSubscriptionsContext.subscribedInterims]);

    const value = {
        getInterimSearch,
        searchInterim,
        getInterims,
        getInterim,
        getProjectInterim,
        updateInterim,
        getInterimExpense,
        getProjectInterimExpense,
        countNonInterimAccountedInvoices,
        downloadInterimProject,
    };

    return (
        <InterimContext.Provider value={value}>
            {children}
        </InterimContext.Provider>
    );
}

export const useInterimContext = (): InterimContext => {
    return useContext(InterimContext);
}
