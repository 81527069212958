import React from "react";
import { ChangeOrderStatus, Contract, Account, AccountType } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Grid } from '@material-ui/core';
import ViewHeading from '../viewComponents/ViewHeading';
import SelectContract from '../contractComponents/SelectContract';
import SelectStatus from './SelectStatus';
import SelectAccount from "../accountComponents/SelectAccount";

type props = {
    selectedAccount?: Account | undefined;
    selectedContract?: Contract | undefined;
    selectedStatus?: ChangeOrderStatus | undefined;
    
    onSelectedAccountChange?: (newSelectedAccount: Account | undefined) => void;
    onSelectedContractChange?: (newSelectedContract: Contract | undefined) => void;
    onSelectedStatusChange?: (newSelectedStatus: ChangeOrderStatus | undefined) => void;
}

const SelectChangeOrderSubContractorFilter: React.FC<props> = ({
    selectedAccount,
    selectedContract,
    selectedStatus,
    onSelectedAccountChange,
    onSelectedContractChange,
    onSelectedStatusChange}) => {
        
    const languageContext = useLanguageContext();

    return  <ViewHeading title={`${languageContext.getMessage('changeOrder')} ${languageContext.getMessage('subContractorShort')}`}>
                <Grid container spacing={2}>
                    {onSelectedAccountChange &&
                    <Grid item xs={12} lg={3}>
                        <SelectAccount
                            selectedAccount={selectedAccount}
                            onSelectedAccountChange={onSelectedAccountChange} />
                    </Grid>}
                    {onSelectedContractChange &&
                    <Grid item xs={12} lg={3}>
                        <SelectContract
                            selectedContract={selectedContract}
                            selectedAccount={selectedAccount}
                            onSelectedContractChange={onSelectedContractChange} />
                    </Grid>}
                    {onSelectedStatusChange &&
                    <Grid item xs={12} lg={3}>
                        <SelectStatus
                            selectedStatus={selectedStatus}
                            onSelectedStatusChange={onSelectedStatusChange} />
                    </Grid>}
                </Grid>
            </ViewHeading>
}

export default SelectChangeOrderSubContractorFilter;