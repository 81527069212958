import NumberFormat from 'react-number-format';
import React from "react";
import { TextField } from '@material-ui/core';

type props = {
    value?: number;
    label: string;
    readOnly?: boolean;
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}


export interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }

export function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  export interface State {
    numberformat: string;
  }

const NumberFormatField: React.FC<props> = ({
    value, label, readOnly, onInputChange }) => {
    
    let usedValue = value?.toString();

    if (value === 0) {
        usedValue = "";
    }
    

    return (<>
        <TextField
            label={label}
            value={usedValue}
            onChange={onInputChange}
            name="numberformat"
            InputProps={{
                inputComponent: NumberFormatCustom as any,
                readOnly: readOnly,
              }}
        />
        </>
    );
}

export default NumberFormatField
