import React, { createContext, useContext, useEffect, useState } from "react";
import { ChangeOrderCounterClaim } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_CHANGE_ORDER_COUNTER_CLAIM, getChangeOrderCounterClaimsGraphqlQueryOptions } from './changeOrderCounterClaimQueries'
import { useAuthContext } from "../../auth/authContext";

export interface ChangeOrderCounterClaimQueriesContext {
    fetchedChangeOrderCounterClaims: Array<ChangeOrderCounterClaim>,
    queryChangeOrderCounterClaims: (changeOrderCounterClaim: ChangeOrderCounterClaim) => void,
}

const ChangeOrderCounterClaimQueriesContext = createContext<ChangeOrderCounterClaimQueriesContext>(null as unknown as ChangeOrderCounterClaimQueriesContext);

export const ChangeOrderCounterClaimQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedChangeOrderCounterClaims, setFetchedChangeOrderCounterClaims] = useState<Array<ChangeOrderCounterClaim>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_CHANGE_ORDER_COUNTER_CLAIM);

    const queryChangeOrderCounterClaims = (changeOrderCounterClaim: ChangeOrderCounterClaim): void => {
        const queryOptions = getChangeOrderCounterClaimsGraphqlQueryOptions(changeOrderCounterClaim);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.changeOrderCounterClaims) {
            const newFetchedChangeOrderCounterClaims: Array<ChangeOrderCounterClaim> = queryResponse.data.changeOrderCounterClaims;
            setFetchedChangeOrderCounterClaims(newFetchedChangeOrderCounterClaims);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedChangeOrderCounterClaims([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const changeOrderCounterClaimQueriesContext: ChangeOrderCounterClaimQueriesContext = {
        fetchedChangeOrderCounterClaims,
        queryChangeOrderCounterClaims,
    };

    return (
        <ChangeOrderCounterClaimQueriesContext.Provider value={changeOrderCounterClaimQueriesContext}>
            {children}
        </ChangeOrderCounterClaimQueriesContext.Provider>
    );
}

export const useChangeOrderCounterClaimQueriesContext = (): ChangeOrderCounterClaimQueriesContext => {
    return useContext(ChangeOrderCounterClaimQueriesContext);
}
