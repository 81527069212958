import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, CrewResource } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";

export const QUERY_CREW_RESOURCE: DocumentNode = gql`
    query crewResources(
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $projectId: UUID
        $name: String
        $fromDate: Date
        $toDate: Date
    ) {
        crewResources(
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            projectId: $projectId
            name: $name
            fromDate: $fromDate
            toDate: $toDate
        ) {
            id
            documentType
            state
            projectId
            name
            fromDate
            toDate
        		defaultCrewResourceDetails  {
                date
              	role
                salaryAdjustmentPercentage
                assignmentPercentage
              	salary
                disabled
            }
            monthlyCrewResourceDetails {
                date
              	role
                salaryAdjustmentPercentage
                assignmentPercentage
              	salary
                disabled
            }
        }
    }
`;

export const getCrewResourceGraphqlQueryOptions = (crewResource: Partial<CrewResource>): QueryHookOptions => {

    crewResource.state = crewResource.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (crewResource.id !== undefined) options.variables['id'] = crewResource.id;
    if (crewResource.state !== undefined) options.variables['state'] = crewResource.state;
    if (crewResource.projectId !== undefined) options.variables['projectId'] = crewResource.projectId;
    if (crewResource.name !== undefined) options.variables['name'] = crewResource.name;
    if (crewResource.fromDate !== undefined) options.variables['fromDate'] = toISODate(crewResource.fromDate);
    if (crewResource.toDate !== undefined) options.variables['toDate'] = toISODate(crewResource.toDate);
    return options;
}