import { DialogActions, DialogContent, Grid, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import { DatePicker } from '../../component/dateComponents/DatePicker';
import withTheme from '@material-ui/styles/withTheme';
import React, { useEffect, useState } from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Project, ActiveState } from '../../contracts/contracts';
import ConfirmDeleteDialog from '../deleteComponent/ConfirmDeleteDialog';
import TicketLoading from '../generalComponents/TicketLoading';
import PercentageInput from '../percentageComponents/PercentageInput';
import ChipInput from 'material-ui-chip-input';
import { joinExternalIds, removeInvalidExternalIdChars, splitExternalId } from '../../utils/externalIdTools';

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.error.main,
    marginRight: '0.25em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

export interface Props {
  project: Project | undefined;
  open: boolean;
  onClose: (editedProject?: Project) => void;
  handlingNewProject?: boolean;
}

const EditProjectDialog: React.FC<Props> = ({
  project,
  open,
  onClose,
  handlingNewProject
}) => {
  handlingNewProject = handlingNewProject ?? false;
  const classes = useStyles();
  const languageContext = useLanguageContext();

  const [editedProject, setEditedProject] = React.useState<Project | undefined>(undefined);

  const handleOnClickSave = () => {
    if (editedProject) {
      editedProject.created = editedProject.created ?? new Date();
      editedProject.state = editedProject.state ?? ActiveState.ACTIVE;
    }
    onClose(editedProject);
  };

  const handleOnClickClose = () => {
    setEditedProject(undefined);
    onClose(undefined);
  };

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = (deleteIsConfirmed: boolean): void => {
    if (deleteIsConfirmed) {
      if (editedProject) {
        editedProject.state = ActiveState.INACTIVE;
      }
      onClose(editedProject);
    }
    setOpenConfirmDeleteDialog(false);
  }

  const externalIds = splitExternalId(editedProject?.externalId);
  const handleAddExternalId = (externalId: string): void => {
    if (!editedProject) {
      return;
    }
    externalId = removeInvalidExternalIdChars(externalId);
    if (externalIds.findIndex(existingExternalId => existingExternalId === externalId) >= 0) {
      return;
    }
    externalIds.push(externalId);
    editedProject.externalId = joinExternalIds(externalIds);
    setEditedProject({...editedProject});
  }

  const handleDeleteExternalId = (externalId: string): void => {
    if (!editedProject) {
      return;
    }
    const index = externalIds.findIndex(existingExternalId => existingExternalId === externalId);
    if (index >= 0) {
      externalIds.splice(index, 1);
      editedProject.externalId = joinExternalIds(externalIds);
      setEditedProject({...editedProject});
    }
  }

  useEffect(() => {
    setEditedProject({...project});
  }, [project])

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleOnClickClose}
        open={open}
        disableBackdropClick={true}>
        <DialogTitle>
          {handlingNewProject ? languageContext.getMessage('newProject') : languageContext.getMessage('editProject')}
          <IconButton className={classes.closeButton} onClick={handleOnClickClose} title={languageContext.getMessage('cancel')}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TicketLoading 
                documentId={editedProject?.id} 
                component={<TextField
                            fullWidth
                            label={languageContext.getMessage('projectName')}
                            value={editedProject?.name ?? ''}
                            onChange={(event) => {
                                if (editedProject) {
                                    editedProject.name = event.target.value;
                                    setEditedProject({...editedProject});
                                    }
                              }} />} />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                fullWidth
                label={languageContext.getMessage('projectStart')}
                date={editedProject?.projectStart}
                onChange={(date) => {
                  if (editedProject) {
                      editedProject.projectStart = date;
                      setEditedProject({...editedProject});
                      }
                  }} />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                fullWidth
                label={languageContext.getMessage('projectEnd')}
                date={editedProject?.projectEnd}
                onChange={(date) => {
                    if (editedProject) {
                        editedProject.projectEnd = date;
                        setEditedProject({...editedProject});
                        }
                    }} />
              </Grid>
            <Grid item xs={12}>
              <PercentageInput
                label={`${languageContext.getMessage('surcharge')}`}
                percentage={editedProject?.surchargePercent ?? 0.0}
                onChange={(newSurchargePercentage) => { 
                    if (editedProject) {
                        editedProject.surchargePercent = newSurchargePercentage;
                        setEditedProject({...editedProject});
                        }
                    }
                }
                fullWidth={true}
                updateOnDefaultPercentageChange={true}
              />
            </Grid>
            <Grid item xs={12}>
              <ChipInput
                label={languageContext.getMessage('projectCodes')}
                fullWidth
                value={externalIds}
                onAdd={(externalId: string) => handleAddExternalId(externalId)}
                onDelete={(externalId: string, index: number) => handleDeleteExternalId(externalId)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!handlingNewProject && <Button onClick={handleOnClickDelete} color="primary" variant="contained">
            {languageContext.getMessage('delete')}
          </Button>}
          <Button onClick={handleOnClickSave} color="primary" variant="contained">
            {languageContext.getMessage('save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog
        title={languageContext.getMessage('doYouWantToDeleteProject')}
        open={openConfirmDeleteDialog}
        onClose={async (deleteIsConfirmed) => handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)}
      />
    </>
  );
}

export default withTheme(EditProjectDialog);