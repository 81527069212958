import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { Account, AccountType } from "../../contracts/contracts";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { Box } from "@material-ui/core";

type props = {
  selectedAccount: Account | undefined;
  accountTypeFocus?: AccountType;
  onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
  label?: string;
  readonly?: boolean;
  minWidth?: number;
  hideLabel?: boolean;
};

const SelectAccount: React.FC<props> = ({ selectedAccount, accountTypeFocus, onSelectedAccountChange, label, readonly, minWidth, hideLabel }) => {
  const languageContext = useLanguageContext();
  const accountContext = useAccountContext();
  const labelToUse = label ?? languageContext.getMessage("account");
  const accounts = accountContext.getAccounts(accountTypeFocus);
  const defaultAccount = selectedAccount ?? null;

  const handleChange = (event: any, newSelectedAccount: Account | null) => {
    onSelectedAccountChange(newSelectedAccount ?? undefined);
  };

  const getAccountRenderText = (account: Account | null): string => {
    return account ? `${account.accountNumber} - ${account.name}` : "";
  };

  if (readonly) {
    return <TextField label={hideLabel === true ? undefined : labelToUse} value={getAccountRenderText(defaultAccount)} InputProps={{ readOnly: true }} fullWidth />;
  }

  return (
    <Autocomplete
      options={accounts}
      getOptionLabel={(account) => getAccountRenderText(account)}
      renderOption={(account, props) => (
        <Box component="li" {...props}>
          {getAccountRenderText(account)}
        </Box>
      )}
      value={defaultAccount}
      style={{ minWidth: minWidth }}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={hideLabel === true ? undefined : labelToUse} variant="standard" />}
    />
  );
};

export default SelectAccount;
