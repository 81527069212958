import { Grid, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import ForecastsGraph from "../../component/forecastComponents/ForecastsGraph";
import ForecastList from "../../component/forecastComponents/ForecastList";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useForecastsContext } from "../../contexts/forecast/forecastsContext";
import { useUrlContext } from "../../contexts/url/urlContext";

type props = {
}

const ForecastOverview: React.FC<props> = ({}) => {

    const urlContext = useUrlContext();
    const projectContext = useProjectContext();
    const forecastsContext = useForecastsContext();

    const project = projectContext.getSelectedProject();
    const forecastSearch = forecastsContext.getForecastsSearch();
    forecastSearch.parentDocumentId = project?.id;

    useEffect(() => {
        forecastsContext.searchForecasts(forecastSearch);
    }, [urlContext.currentLocation, projectContext.getSelectedProject()])

    const forecasts = forecastsContext.getForecasts(project?.id) ?? {};

    return (<>
        <Grid container item direction="row" spacing={1}>
            {forecasts && project?.forecast &&
                <Grid item xs={12}>
                    <ForecastList 
                        forecasts={forecasts?.forecasts ?? []}
                        showLastForecast={true}
                        isProjectForecasts={true}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <ForecastsGraph 
                    forecasts={forecasts}
                    isProjectForecasts={true} />
            </Grid>
        </Grid>
    </>
    );
}

export default withTheme(ForecastOverview);