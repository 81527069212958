import React, { createContext, useContext, useEffect, useState } from "react";
import { Account } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_ACCOUNT, getAccountsGraphqlQueryOptions } from './accountQueries'
import { useAuthContext } from "../../auth/authContext";

export interface AccountQueriesContext {
    fetchedAccounts: Array<Account>,
    queryAccounts: (account: Account) => void,
    loadingAccounts: boolean,
}

const AccountQueriesContext = createContext<AccountQueriesContext>(null as unknown as AccountQueriesContext);

export const AccountQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedAccounts, setFetchedAccounts] = useState<Array<Account>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_ACCOUNT);

    const queryAccounts = (account: Account): void => {
        const queryOptions = getAccountsGraphqlQueryOptions(account);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.accounts) {
            const newFetchedAccounts: Array<Account> = queryResponse.data.accounts;
            setFetchedAccounts(newFetchedAccounts);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedAccounts([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const accountQueriesContext: AccountQueriesContext = {
        fetchedAccounts,
        queryAccounts,
        loadingAccounts: queryResponse.loading,
    };

    return (
        <AccountQueriesContext.Provider value={accountQueriesContext}>
            {children}
        </AccountQueriesContext.Provider>
    );
}

export const useAccountQueriesContext = (): AccountQueriesContext => {
    return useContext(AccountQueriesContext);
}
