import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Account, ActiveState } from "../../../contracts/contracts";
import { FORECAST_QUERY_ELEMENT, FORECAST_REPORT_QUERY_ELEMENT } from "../../forecast/forecastsTools";
import { INTERIM_EXPENSE_QUERY_ELEMENT } from "../../interim/InterimTools";

export const QUERY_ACCOUNT: DocumentNode = gql(`
    query accounts(
            $id: UUID
            $documentType: DocumentType
            $state: ActiveState
            $changedBy: String
            $created: DateTime
            $projectId: UUID
            $name: String
            $externalProvider: String
            $externalId: String
            $accountNumber: String
            $budget: Float
            $remainingBudget: Float
            $riskConsequenceLimit: Float
            $riskCostAdjustment: Float
        ) {
        accounts(
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            changedBy: $changedBy
            projectId: $projectId
            name: $name
            externalProvider: $externalProvider
            externalId: $externalId
            accountNumber: $accountNumber
            budget: $budget
            remainingBudget: $remainingBudget
            riskConsequenceLimit: $riskConsequenceLimit
            riskCostAdjustment: $riskCostAdjustment
        ) {
            id
            documentType
            stateTime
            state
            created
            changedBy
            projectId
            accountType
            name
            externalProvider
            externalId
            accountGroup
            accountNumber
            budget
            remainingBudget
            invoicedAmount
            invoicedWithheldAmount
            invoicedAmountTotal
            sumContractBudget
            sumContractProcurement
            sumContractCostScope
            sumContractRemainingBudget
            sumContractInvoicedAmount
            sumContractInvoicedWithheldAmount
            sumContractInvoicedAmountTotal
            sumChangeOrderSubContractorCost
            sumChangeOrderSubContractorRemainingCost
            sumChangeOrderSubContractorInvoicedAmount
            sumChangeOrderSubContractorInvoicedWithheldAmount
            adjustmentCosts
            unforeseenBudget
            forecast {
                ` + FORECAST_QUERY_ELEMENT + `
            }
            forecastReport {
                ` + FORECAST_REPORT_QUERY_ELEMENT + `
            }
            interimExpense {
                ` + INTERIM_EXPENSE_QUERY_ELEMENT + `
            }
            sumExpenseForecastsOnAccrualAndOperations
            remainingForecastsToDistributeOnAccrualAndOperations
            riskConsequenceLimit
            riskCostAdjustment
            comment
        }
    }
`);

export const getAccountsGraphqlQueryOptions = (account: Partial<Account>): QueryHookOptions => {
    account.state = account.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (account.id !== undefined) options.variables['id'] = account.id
    if (account.state !== undefined) options.variables['state'] = account.state
    if (account.projectId !== undefined) options.variables['projectId'] = account.projectId
    if (account.name !== undefined) options.variables['name'] = account.name
    if (account.externalProvider !== undefined) options.variables['externalProvider'] = account.externalProvider
    if (account.externalId !== undefined) options.variables['externalId'] = account.externalId
    if (account.accountNumber !== undefined) options.variables['accountNumber'] = account.accountNumber
    if (account.comment !== undefined) options.variables['comment'] = account.comment
    return options;
}