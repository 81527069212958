import React, { useState } from "react";
import { withTheme, makeStyles, Theme, Grid, TextField, InputLabel, Select, MenuItem, FormControl, Input, useTheme, Button, Typography, Divider } from '@material-ui/core';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Account } from '../../contracts/contracts';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useThemeContext } from '../../contexts/theme/ThemeContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { useAccountContext } from '../../contexts/account/accountContext';
import PercentageInput from '../percentageComponents/PercentageInput';
import { displayCurrency } from '../currencyComponents/CurrencyFormat';
import SelectAccount from "../accountComponents/SelectAccount";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: '1em'
    },
    divider: {
        marginBottom: '1em'
    },
    button: {
        margin: theme.spacing(1),
    },
}));

function getStyles(subject: string, subjectName: string[], theme: Theme) {
    return {
        fontWeight:
            subjectName.indexOf(subject) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const RiskAccountSettings: React.FC<{}> = () => {

    const classes = useStyles();
    const languageContext = useLanguageContext();
    const themeContext = useThemeContext();
    const projectContext = useProjectContext();
    const accountContext = useAccountContext();
    const currentProject = projectContext.getSelectedProject();
    const theme = useTheme();

    const accountSearch = accountContext.getAccountSearch();

    const accounts = accountContext.getAccounts();

    const [editedAccount, setEditedAccount] = React.useState<Account | undefined>();

    const [editingAccountRisk, setEditingAccountRisk] = useState<boolean>(false);

    const onEditAccount = (save: boolean) => {
        if (editedAccount && editingAccountRisk) {
            if (save) {
                accountContext.mutateAccount({ ...editedAccount });
            }
            else {
                let selectedAccount = accountContext.getAccount(editedAccount.id);
                setEditedAccount({ ...selectedAccount });
            }
        }
        setEditingAccountRisk(!editingAccountRisk);
    }

    const readonly = { readOnly: true };

    return (<>
        <Grid container spacing={1}>
            <Grid item xs={6} lg={3}>
                <FormControl fullWidth={true}>
                    <SelectAccount
                        selectedAccount={accountContext.getAccount(editedAccount?.id)}
                        onSelectedAccountChange={newEditedAccount => {
                            if (newEditedAccount) {
                                setEditedAccount({ ...newEditedAccount });
                            }
                        }} />
                </FormControl>
            </Grid>
            <Grid item xs={3} sm={2} lg={1}>
                <PercentageInput
                    label={`${languageContext.getMessage('max')} ${languageContext.getMessage('percentage')}`}
                    percentage={editedAccount?.riskConsequenceLimit ?? 0}
                    onChange={(newAssignmentPercentage) => {
                        if (editedAccount) {
                            editedAccount.riskConsequenceLimit = newAssignmentPercentage;
                            setEditedAccount({ ...editedAccount });
                        }
                    }}
                    fullWidth={true}
                    readOnly={editingAccountRisk ? false : true}
                    updateOnDefaultPercentageChange={true}
                />
            </Grid>
            <Grid item xs={3} sm={2} lg={2}>
                <TextField
                    fullWidth
                    label={languageContext.getMessage('accountBudget')}
                    value={displayCurrency(editedAccount?.budget)}
                    InputProps={readonly} />
            </Grid>
            <Grid item xs={3} sm={2} lg={2}>
                <TextField
                    fullWidth
                    label={`${languageContext.getMessage('max')} ${languageContext.getMessage('consequence')}`}
                    value={displayCurrency((editedAccount?.budget ?? 0) * (editedAccount?.riskConsequenceLimit ?? 0) * 1 / 100)}
                    InputProps={readonly} />
            </Grid>
            {editedAccount &&
                <>
                    <Button className={classes.button} color="primary" variant="contained" onClick={() => onEditAccount(true)}>
                        {editingAccountRisk ? <SaveIcon /> : <EditIcon />}
                    </Button>
                    {editingAccountRisk &&
                        <Button className={classes.button} color="default" variant="contained" onClick={() => onEditAccount(false)}>
                            <CancelIcon />
                        </Button>
                    }
                </>}
        </Grid>
    </>);
}

export default withTheme(RiskAccountSettings)