export const sumOfDigitsFromString = (str: string): number => {
    let sum = 0;
    for (var i = 0; i < str.length; i++) {
        if (/[0-9]/.test(str[i])) {
            sum += parseInt(str[i])
        }
    }
    return sum;
}

export const capitalizeString = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export const alphaNumericString = (value: string): string => {
    return value.replace(/[^a-z0-9]/gi,'');
}

export const safeToLowerCase = (value: string | undefined): string => {
    return value ? value.toLowerCase() : "";
}