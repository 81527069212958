import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ChangeOrderLandlord, ActiveState } from "../../../contracts/contracts";

export const QUERY_CHANGE_ORDER_LANDLORD: DocumentNode = gql`
    query changeOrderLandlords(
            $id: UUID
            $state: ActiveState
            $projectId: UUID
            $accountId: UUID
            $parentId: UUID
            $relationType: RelationType
            $name: String
            $group: String
            $sent: DateTime
            $answered: DateTime
            $changeOrderDescription: String
            $changeOrderComment: String
            $costRequirements: Float
            $cost: Float
            $costSurcharge: Float
            $costWithSurcharge: Float
            $billed: Float
            $status: ChangeOrderStatus
            $progressConsequenceEnabled: Boolean
            $progressConsequenceDescription: String
        ) {
        changeOrderLandlords(
            id: $id
            state: $state
            projectId: $projectId
            accountId: $accountId
            parentId: $parentId
            relationType: $relationType
            name: $name
            group: $group
            sent: $sent
            answered: $answered
            changeOrderDescription: $changeOrderDescription
            changeOrderComment: $changeOrderComment
            costRequirements: $costRequirements
            cost: $cost
            costSurcharge: $costSurcharge
            costWithSurcharge: $costWithSurcharge
            billed: $billed
            status: $status
            progressConsequenceEnabled: $progressConsequenceEnabled
            progressConsequenceDescription: $progressConsequenceDescription
        ) {
            id
            documentType
            stateTime
            state
            created
            changedBy
            projectId
            accountId
            parentId
            relationType
            name
            group
            sent
            answered
            changeOrderDescription
            changeOrderComment
            costRequirements
            cost
            costSurcharge
            costWithSurcharge
            billed
            remainingBilled
            status
            progressConsequenceEnabled
            progressConsequenceDescription
        }
    }
`;

export const getChangeOrderLandlordsGraphqlQueryOptions = (changeOrderLandlord: Partial<ChangeOrderLandlord>): QueryHookOptions => {
    changeOrderLandlord.state = changeOrderLandlord.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (changeOrderLandlord.id !== undefined) options.variables['id'] = changeOrderLandlord.id;
    if (changeOrderLandlord.state !== undefined) options.variables['state'] = changeOrderLandlord.state;
    if (changeOrderLandlord.projectId !== undefined) options.variables['projectId'] = changeOrderLandlord.projectId;
    if (changeOrderLandlord.accountId !== undefined) options.variables['accountId'] = changeOrderLandlord.accountId;
    if (changeOrderLandlord.parentId !== undefined) options.variables['parentId'] = changeOrderLandlord.parentId;
    if (changeOrderLandlord.relationType !== undefined) options.variables['relationType'] = changeOrderLandlord.relationType;
    if (changeOrderLandlord.name !== undefined) options.variables['name'] = changeOrderLandlord.name;
    if (changeOrderLandlord.sent !== undefined) options.variables['sent'] = changeOrderLandlord.sent.toISOString();
    if (changeOrderLandlord.changeOrderDescription !== undefined) options.variables['changeOrderDescription'] = changeOrderLandlord.changeOrderDescription;
    if (changeOrderLandlord.changeOrderComment !== undefined) options.variables['changeOrderComment'] = changeOrderLandlord.changeOrderComment;
    if (changeOrderLandlord.costRequirements !== undefined) options.variables['costRequirements'] = changeOrderLandlord.costRequirements;
    if (changeOrderLandlord.cost !== undefined) options.variables['cost'] = changeOrderLandlord.cost;
    if (changeOrderLandlord.costSurcharge !== undefined) options.variables['costSurcharge'] = changeOrderLandlord.costSurcharge;
    if (changeOrderLandlord.costWithSurcharge !== undefined) options.variables['costWithSurcharge'] = changeOrderLandlord.costWithSurcharge;
    if (changeOrderLandlord.billed !== undefined) options.variables['billed'] = changeOrderLandlord.billed;
    if (changeOrderLandlord.status !== undefined) options.variables['status'] = changeOrderLandlord.status;
    if (changeOrderLandlord.progressConsequenceEnabled !== undefined) options.variables['progressConsequenceEnabled'] = changeOrderLandlord.progressConsequenceEnabled;
    if (changeOrderLandlord.progressConsequenceDescription !== undefined) options.variables['progressConsequenceDescription'] = changeOrderLandlord.progressConsequenceDescription;

    return options;
}