import { Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, IconButton, Input, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Close, Delete } from "@material-ui/icons";
import withTheme from "@material-ui/styles/withTheme";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useChangeOrderLandlordContext } from "../../contexts/changeOrderLandlord/changeOrderLandlordContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { ActiveState, ChangeOrderLandlord, ChangeOrderStatus } from "../../contracts/contracts";
import { getEmptyChangeOrderLandlord, getEmptySubChangeOrderLandlord, mergeSubChangeOrderLandlordWithParent } from "../../utils/changeOrderLandlordTools";
import { Guid } from "../../utils/common-types";
import { getEmptyGuid, guidIsNullOrEmpty } from "../../utils/guidTools";
import { focusElement } from "../../utils/htmlTools";
import SelectAccount from "../accountComponents/SelectAccount";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import { DatePicker } from "../dateComponents/DatePicker";
import ConfirmDeleteDialog from "../deleteComponent/ConfirmDeleteDialog";
import SelectChangeOrderLandlordGroup from "./SelectChangeOrderLandlordGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  listButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export interface EditChangeOrderLandlordDialogProps {
  changeOrderLandlord: ChangeOrderLandlord | undefined;
  open: boolean;
  onClose: () => void;
}

const EditChangeOrderLandlordDialog: React.FC<EditChangeOrderLandlordDialogProps> = ({ changeOrderLandlord, open, onClose }) => {
  const handlingNewChangeOrder = changeOrderLandlord?.id === undefined;

  const languageContext = useLanguageContext();
  const changeOrderLandlordContext = useChangeOrderLandlordContext();
  const accountContext = useAccountContext();
  const projectContext = useProjectContext();
  const userRoleContext = useUserRoleContext();
  const users = userRoleContext.getUsers();

  const classes = useStyles();

  const [editedChangeOrderLandlord, setEditedChangeOrderLandlord] = useState<ChangeOrderLandlord>();
  const [subChangeOrderLandlords, setSubChangeOrderLandlords] = useState<ChangeOrderLandlord[]>([]);

  useEffect(() => {
    if (!open) return;

    if (handlingNewChangeOrder) {
      const projectId = projectContext.getSelectedProject()?.id || "";
      setEditedChangeOrderLandlord(getEmptyChangeOrderLandlord(projectId));
      setSubChangeOrderLandlords([getEmptySubChangeOrderLandlord(changeOrderLandlord?.id ?? getEmptyGuid(), changeOrderLandlord?.accountId ?? getEmptyGuid())]);
    } else {
      setEditedChangeOrderLandlord(changeOrderLandlord);
      setSubChangeOrderLandlords(changeOrderLandlordContext.getChangeOrderLandlordsByParentId(changeOrderLandlord?.id ?? ""));
    }
  }, [open]);

  useEffect(() => {
    if (!subChangeOrderLandlords || subChangeOrderLandlords.length === 0) return;
    updateChangeOrderLandlord();
  }, [subChangeOrderLandlords]);

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      const mutatedChangeOrderLandlord = { ...changeOrderLandlord };
      mutatedChangeOrderLandlord.state = ActiveState.INACTIVE;
      changeOrderLandlordContext.updateChangeOrderLandlord(mutatedChangeOrderLandlord, (mutatedChangeOrder) => {
        for (let index = 0; index < subChangeOrderLandlords.length; index++) {
          subChangeOrderLandlords[index].state = ActiveState.INACTIVE;
          subChangeOrderLandlords[index] = mergeSubChangeOrderLandlordWithParent(mutatedChangeOrder, subChangeOrderLandlords[index]);
          changeOrderLandlordContext.updateChangeOrderLandlord(subChangeOrderLandlords[index]);
        }
      });
      onClose();
    }
    setOpenConfirmDeleteDialog(false);
  };

  const handleClose = (save: boolean) => {
    if (save && editedChangeOrderLandlord) {
      changeOrderLandlordContext.updateChangeOrderLandlord(editedChangeOrderLandlord, (mutatedChangeOrder) => {
        for (let index = 0; index < subChangeOrderLandlords.length; index++) {
          subChangeOrderLandlords[index] = mergeSubChangeOrderLandlordWithParent(mutatedChangeOrder, subChangeOrderLandlords[index]);
          changeOrderLandlordContext.updateChangeOrderLandlord(subChangeOrderLandlords[index]);
        }
      });
    }
    onClose();
  };

  const addSubChangeOrderLandlord = () => {
    if (!editedChangeOrderLandlord) return;

    const nextChangeOrderLandlord = getEmptySubChangeOrderLandlord(editedChangeOrderLandlord?.id ?? "", editedChangeOrderLandlord.accountId ?? "");
    setSubChangeOrderLandlords([...subChangeOrderLandlords, nextChangeOrderLandlord]);
    updateChangeOrderLandlord();

    focusElement(`account-select-${nextChangeOrderLandlord.id}`);
  };

  const removeSubChangeOrderLandlord = (id?: Guid) => {
    if (!id) return;

    const copy = [...subChangeOrderLandlords];
    const index = copy.findIndex((co) => co.id === id);
    if (index === -1) return;

    const changeOrderLandlord = copy[index];
    if (changeOrderLandlord.state === ActiveState.ACTIVE) {
      copy[index] = { ...copy[index], state: ActiveState.INACTIVE };
    } else {
      copy.splice(index, 1);
    }

    setSubChangeOrderLandlords(copy);

    focusElement("add-sub-change-order-button");

    if (copy.length === 0) {
      const test = { ...editedChangeOrderLandlord };
      test.costRequirements = 0;
      test.cost = 0;
      setEditedChangeOrderLandlord(test);
    }
  };

  const updateSubChangeOrderLandlord = (id: Guid, partialChangeOrderLandlord: Partial<ChangeOrderLandlord>) => {
    if (!id) return;

    const copy = [...subChangeOrderLandlords];
    const index = copy.findIndex((co) => co.id == id);
    if (index === -1) return;
    copy[index] = { ...copy[index], ...partialChangeOrderLandlord };
    setSubChangeOrderLandlords(copy);
  };

  const updateChangeOrderLandlord = () => {
    const copy = { ...editedChangeOrderLandlord };
    let sumCostRequirements = 0;
    let sumCost = 0;
    let sumBilled = 0;
    subChangeOrderLandlords.forEach((changeOrderLandlord) => {
      if (changeOrderLandlord.state === ActiveState.INACTIVE) return;
      sumCostRequirements += changeOrderLandlord.costRequirements || 0;
      sumCost += changeOrderLandlord.cost || 0;
      sumBilled += changeOrderLandlord.billed || 0;
    });
    copy.costRequirements = sumCostRequirements;
    copy.cost = sumCost;
    copy.billed = sumBilled;
    setEditedChangeOrderLandlord(copy);
  };

  const setEditedChangeOrderProperty = (properties: Partial<ChangeOrderLandlord>) => {
    if (editedChangeOrderLandlord) {
      setEditedChangeOrderLandlord({ ...editedChangeOrderLandlord, ...properties });
    }
  };

  let title = changeOrderLandlord?.id ? languageContext.getMessage("edit") : languageContext.getMessage("new");
  title = `${title} ${languageContext.getMessage("changeOrder")}`;

  var screenIsMobile = useWidthContext().isMobileScreen();

  const renderSubChangeOrderLandlord = (changeOrderLandlord: ChangeOrderLandlord, index: number, array: ChangeOrderLandlord[]) => {
    if (changeOrderLandlord.state === ActiveState.INACTIVE) return;

    return (
      <Grid container item xs={12} spacing={1} wrap={"nowrap"} key={changeOrderLandlord.id}>
        <Grid item>
          <IconButton className={classes.listButton} onClick={() => removeSubChangeOrderLandlord(changeOrderLandlord.id)}>
            <Delete fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <SelectAccount
            selectedAccount={accountContext.getAccount(changeOrderLandlord?.accountId)}
            onSelectedAccountChange={(newSelectedAccount) => {
              updateSubChangeOrderLandlord(changeOrderLandlord.id || "", { accountId: newSelectedAccount?.id });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CurrencyInput
            label={languageContext.getMessage("costRequirements")}
            fullWidth
            value={changeOrderLandlord.costRequirements || 0}
            onChange={(amount) => updateSubChangeOrderLandlord(changeOrderLandlord.id || "", { costRequirements: amount })}
          />
        </Grid>
        <Grid item xs={4}>
          <CurrencyInput
            label={languageContext.getMessage("cost")}
            fullWidth
            value={changeOrderLandlord.cost || 0}
            onChange={(amount) => updateSubChangeOrderLandlord(changeOrderLandlord.id || "", { cost: amount })}
          />
        </Grid>
        <Grid item xs={4}>
          <CurrencyInput
            label={languageContext.getMessage("billed")}
            fullWidth
            value={changeOrderLandlord.billed || 0}
            onChange={(amount) => updateSubChangeOrderLandlord(changeOrderLandlord.id || "", { billed: amount })}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Dialog maxWidth={screenIsMobile ? "xs" : "sm"} fullWidth={true} onClose={() => handleClose(false)} open={open} disableBackdropClick={true}>
        <DialogTitle>
          {title}
          <IconButton className={classes.closeButton} onClick={() => handleClose(false)} title={languageContext.getMessage("cancel")}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid container item spacing={1} xs={!screenIsMobile ? 6 : 12} alignItems="flex-start">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={languageContext.getMessage("name")}
                  value={editedChangeOrderLandlord?.name ?? ""}
                  onChange={(event) => setEditedChangeOrderProperty({ name: event.target.value })}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectChangeOrderLandlordGroup
                  selectedChangeOrderLandlordGroup={editedChangeOrderLandlord?.group}
                  onSelectedChangeOrderLandlordGroupChange={(newSelectedChangeOrderLandlordGroup) => setEditedChangeOrderProperty({ group: newSelectedChangeOrderLandlordGroup })}
                  freeSolo={true}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label={languageContext.getMessage("description")}
                  value={editedChangeOrderLandlord?.changeOrderDescription ?? ""}
                  onChange={(event) => setEditedChangeOrderProperty({ changeOrderDescription: event.target.value })}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  style={{ width: 240 }}
                  options={Object.values(ChangeOrderStatus)}
                  getOptionLabel={(status) => changeOrderLandlordContext.convertChangeOrderStatusToString(status)}
                  value={editedChangeOrderLandlord?.status}
                  onChange={(event, newChangeOrderStatus) => {
                    setEditedChangeOrderProperty({ status: newChangeOrderStatus ?? undefined });
                  }}
                  renderInput={(params) => <TextField {...params} label={<>{languageContext.getMessage("columnStatus")}</>} />}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={1} xs={!screenIsMobile ? 6 : 12} alignItems="flex-start">
              <Grid item xs={12}>
                <DatePicker fullWidth label={languageContext.getMessage("sentDate")} date={editedChangeOrderLandlord?.sent} onChange={(event) => setEditedChangeOrderProperty({ sent: event })} />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label={languageContext.getMessage("answeredDate")}
                  date={editedChangeOrderLandlord?.answered}
                  onChange={(event) => setEditedChangeOrderProperty({ answered: event })}
                />
              </Grid>

              <Grid item xs={12}>
                <CurrencyInput fullWidth readOnly disabled label={languageContext.getMessage("costRequirements")} value={editedChangeOrderLandlord?.costRequirements ?? 0} />
              </Grid>

              <Grid item xs={12}>
                <CurrencyInput fullWidth readOnly disabled label={languageContext.getMessage("cost")} value={editedChangeOrderLandlord?.cost ?? 0} />
              </Grid>

              <Grid item xs={12}>
                <CurrencyInput fullWidth readOnly disabled label={languageContext.getMessage("billed")} value={editedChangeOrderLandlord?.billed ?? 0} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label={languageContext.getMessage("comments")}
                value={editedChangeOrderLandlord?.changeOrderComment ?? ""}
                onChange={(event) => {
                  if (editedChangeOrderLandlord) {
                    editedChangeOrderLandlord.changeOrderComment = event.target.value;
                    setEditedChangeOrderLandlord({ ...editedChangeOrderLandlord });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={`${languageContext.getMessage("enableProgressConsequence")}`}
                control={
                  <Checkbox
                    name="progressConsequenceEnabled"
                    checked={editedChangeOrderLandlord?.progressConsequenceEnabled ?? false}
                    onChange={(event) => setEditedChangeOrderProperty({ progressConsequenceEnabled: event.target.checked })}
                  />
                }
              />
            </Grid>

            {editedChangeOrderLandlord && editedChangeOrderLandlord?.progressConsequenceEnabled && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`${languageContext.getMessage("progressConsequence")} ${languageContext.getMessage("description")}`}
                  multiline
                  value={editedChangeOrderLandlord?.progressConsequenceDescription ?? ""}
                  onChange={(event) => setEditedChangeOrderProperty({ progressConsequenceDescription: event.target.value })}
                />
              </Grid>
            )}

            <Grid container item xs={12} spacing={1}>
              {subChangeOrderLandlords.map(renderSubChangeOrderLandlord)}
            </Grid>
            {changeOrderLandlord?.id && (
              <Grid item xs={12}>
                <Typography variant="body2">{`${languageContext.getMessage("lastChangedBy")} ${users.find((user) => user.id === editedChangeOrderLandlord?.changedBy)?.name}`}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="add-sub-change-order-button" onClick={addSubChangeOrderLandlord} color="primary" variant="contained">
            {languageContext.getMessage("addAccount")}
          </Button>
          {!handlingNewChangeOrder && (
            <Button onClick={handleOnClickDelete} color="primary" variant="contained">
              {languageContext.getMessage("delete")}
            </Button>
          )}
          <Button onClick={() => handleClose(true)} color="primary" variant="contained">
            {languageContext.getMessage("save")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog title={languageContext.getMessage("doYouWantToDeleteChangeOrder")} open={openConfirmDeleteDialog} onClose={async (deleteIsConfirmed) => handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)} />
    </>
  );
};

export default withTheme(EditChangeOrderLandlordDialog);
