import { Button, Grid, IconButton, makeStyles, Theme, Toolbar, Tooltip, withTheme, Typography } from "@material-ui/core";
import { Add, Info } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import MaterialTable, { Column, Components, MTableToolbar } from "material-table";
import React, { useEffect, useRef, useState } from "react";
import EditAccountDialog from "./EditAccountDialog";
import ImportMultipleAccountsDialog from "./ImportMultipleAccountsDialog";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import { useAccountContext } from "../../contexts/account/accountContext";
import { LanguageContext, useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useProjectContext, ProjectContext } from "../../contexts/project/projectContext";
import { Account, AccountType, AccrualAndOperations, GroupedAccount, HealthStatus, RoleType } from "../../contracts/contracts";
import { tableIcons } from "../../ui/table-icons";
import { CustomTableStyle, Guid } from "../../utils/common-types";
import { getPageSizeOptions, resolveDefaultPageSize, notUndefined, getRandomGuid } from "../../utils/randomTools";
import { getRenderedStatus } from "../../utils/statusToPalettes";
import { getRemainsToDistributeHealthStatus } from "../accrualAndOperationComponents/AccrualAndOperationDetailsList";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import DateFormat from "../dateComponents/DateFormat";
import { InterimContext, useInterimContext } from "../../contexts/interim/InterimContext";
import EditInterimDialog from "../interimComponents/EditInterimDialog";
import EditAccountGroupDialog from "./EditAccountGroupDialog";
import TicketLoading from "../generalComponents/TicketLoading";
import PercentageFormat from "../percentageComponents/PercentageFormat";
import { accountListTemplateAlternatives } from "../../contexts/account/AccountListExportTools";
import EditInterimProducedNotBilled from "../interimComponents/EditInterimProducedNotBilled";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { useAccrualAndOperationsContext } from "../../contexts/accrualAndOperations/accrualAndOperationsContext";
import { useRememberTableScroll } from "../../hooks/useRememberTableScroll";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
  forecastIcon: {
    marginLeft: "0.25em",
    verticalAlign: "bottom",
    color: theme.palette.warning.main,
  },
}));

const noneParentGroup = "None";

type props = {
  onClickAccount?: (accountId: Guid) => void;
  accountTypeFocus?: AccountType;
  showForecasts?: boolean;
  showInterims?: boolean;
  accountsToShow?: Account[];
  showGroups?: boolean;
  selectedGroup?: string;
};

const AccountList: React.FC<props> = ({ onClickAccount, accountTypeFocus, showForecasts, showInterims, accountsToShow, showGroups, selectedGroup }) => {
  showForecasts = showForecasts ?? false;
  showGroups = showGroups ?? true;
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const materialTableLanguageContext = useMaterialTableLanguageContext();
  const projectContext = useProjectContext();
  const accountContext = useAccountContext();
  const interimContext = useInterimContext();
  const themeContext = useThemeContext();
  const theme = themeContext.getTheme();
  const urlContext = useUrlContext();
  const accrualAndOperationsContext = useAccrualAndOperationsContext();

  const urlState = urlContext.getUrlState();
  const expandedGroupsSeparator = ";";
  const expandedGroups = urlState.expandedGroups ? (urlState.expandedGroups as string).split(expandedGroupsSeparator) : [];

  const getSecureGroupName = (group: string): string => (group = group.trim().length > 0 ? group : noneParentGroup);

  const groupIsExpanded = (group: string): boolean => {
    group = getSecureGroupName(group);
    return expandedGroups.findIndex((expandedGroup) => expandedGroup === group) >= 0;
  };

  const updateUrlStateWithExpandedGroups = (group: string, expanded: boolean): void => {
    group = getSecureGroupName(group);
    const index = expandedGroups.findIndex((expandedGroup) => expandedGroup === group);
    if (index >= 0 && !expanded) {
      expandedGroups.splice(index, 1);
    } else if (index < 0 && expanded) {
      expandedGroups.push(group);
    }
    const newUrlState = {
      ...urlState,
      ...{ expandedGroups: expandedGroups.length > 0 ? expandedGroups.join(expandedGroupsSeparator) : undefined },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(undefined);
  const [selectedAccountGroup, setSelectedAccountGroup] = useState<string | undefined>(undefined);
  const [handlingNewAccount, setHandlingNewAccount] = useState<boolean>(false);

  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showEditAccountGroupDialog, setShowEditAccountGroupDialog] = useState<boolean>(false);
  const [showImportDialog, setShowImportDialog] = useState<boolean>(false);

  // const accounts = accountsToShow ? accountsToShow : accountContext.getAccounts(accountTypeFocus);
  const getAccounts = () => {
    var resolvedAccounts: GroupedAccount[] = [];
    if (accountsToShow) {
      resolvedAccounts = accountsToShow as GroupedAccount[];
    } else {
      var resolvedAccountGroups: GroupedAccount[] = [];
      var foundAccount: GroupedAccount = {};
      var groupId: string = "";
      var accrualAndOperation: AccrualAndOperations | undefined;
      resolvedAccounts = accountContext.getAccounts(accountTypeFocus) as GroupedAccount[];
      resolvedAccounts.forEach((account) => {
        account.interimExpense = interimContext.getInterimExpense(account);
        accrualAndOperation = accrualAndOperationsContext.getAccrualAndOperation(account.id);
        if (accrualAndOperation) {
          let lastMonthlyPostedExpenseItemDate: Date = new Date(0);
          const lastMonthlyPostedExpenseItems = (accrualAndOperation?.monthlyPostedExpenseItems ?? []).filter(monthlyPostedExpenseItem => {
            if (!monthlyPostedExpenseItem.disabled && (monthlyPostedExpenseItem.date ?? new Date(0)) > lastMonthlyPostedExpenseItemDate) {
              lastMonthlyPostedExpenseItemDate = monthlyPostedExpenseItem.date ?? new Date(0);
              return true;
            }
            return false;
          }).slice();
          account.accrualLastExpense = lastMonthlyPostedExpenseItems.pop();
        }
        var foundAccountIndex = resolvedAccountGroups.findIndex((existingGroupAccount) => existingGroupAccount.accountGroup === account.accountGroup);
        if (foundAccountIndex < 0) {
          groupId = getRandomGuid();
          foundAccount = {
            parentGroup: noneParentGroup,
            numberOfAccountsInGroup: 0,
            id: groupId,
            accountGroup: account.accountGroup,
            accountType: account.accountType,
            name: "Total",
            adjustmentCosts: 0,
            invoicedAmount: 0,
            remainingBudget: 0,
            budget: 0,
            forecast: {
              netAccountBudget: 0,
            },
          };
          foundAccountIndex = resolvedAccountGroups.push(foundAccount);
        } else {
          foundAccount = resolvedAccountGroups[foundAccountIndex];
        }
        if (foundAccount && foundAccount.id) {
          foundAccount.numberOfAccountsInGroup = (foundAccount.numberOfAccountsInGroup ?? 0) + 1;
          foundAccount.budget = (foundAccount?.budget ?? 0) + (account.budget ?? 0);
          foundAccount.forecast = foundAccount.forecast ?? {};
          foundAccount.forecast.accountBudget = (foundAccount.forecast.accountBudget ?? 0) + (account.forecast?.accountBudget ?? 0);
          foundAccount.forecast.contractBudget = (foundAccount.forecast.contractBudget ?? 0) + (account.forecast?.contractBudget ?? 0);
          foundAccount.forecast.contractProcurement = (foundAccount.forecast.contractProcurement ?? 0) + (account.forecast?.contractProcurement ?? 0);
          foundAccount.forecast.contractBudgetUsedInCostScope = (foundAccount.forecast.contractBudgetUsedInCostScope ?? 0) + (account.forecast?.contractBudgetUsedInCostScope ?? 0);
          foundAccount.forecast.contractProcurementUsedInCostScope = (foundAccount.forecast.contractProcurementUsedInCostScope ?? 0) + (account.forecast?.contractProcurementUsedInCostScope ?? 0);
          foundAccount.forecast.contractCostScope = (foundAccount.forecast.contractCostScope ?? 0) + (account.forecast?.contractCostScope ?? 0);
          foundAccount.forecast.changeOrderSubContractorCosts = (foundAccount.forecast.changeOrderSubContractorCosts ?? 0) + (account.forecast?.changeOrderSubContractorCosts ?? 0);
          foundAccount.forecast.changeOrderSubContractorApprovedCosts = (foundAccount.forecast.changeOrderSubContractorApprovedCosts ?? 0) + (account.forecast?.changeOrderSubContractorApprovedCosts ?? 0);
          foundAccount.forecast.changeOrderSubContractorNotApprovedCosts = (foundAccount.forecast.changeOrderSubContractorNotApprovedCosts ?? 0) + (account.forecast?.changeOrderSubContractorNotApprovedCosts ?? 0);
          foundAccount.forecast.changeOrderLandlordCosts = (foundAccount.forecast.changeOrderLandlordCosts ?? 0) + (account.forecast?.changeOrderLandlordCosts ?? 0);
          foundAccount.forecast.changeOrderLandlordApprovedCosts = (foundAccount.forecast.changeOrderLandlordApprovedCosts ?? 0) + (account.forecast?.changeOrderLandlordApprovedCosts ?? 0);
          foundAccount.forecast.changeOrderLandlordNotApprovedCosts = (foundAccount.forecast.changeOrderLandlordNotApprovedCosts ?? 0) + (account.forecast?.changeOrderLandlordNotApprovedCosts ?? 0);
          foundAccount.forecast.changeOrderExpectedCosts = (foundAccount.forecast.changeOrderExpectedCosts ?? 0) + (account.forecast?.changeOrderExpectedCosts ?? 0);
          foundAccount.forecast.changeOrderCounterClaimCosts = (foundAccount.forecast.changeOrderCounterClaimCosts ?? 0) + (account.forecast?.changeOrderCounterClaimCosts ?? 0);
          foundAccount.forecast.netAccountBudget = (foundAccount.forecast.netAccountBudget ?? 0) + (account.forecast?.netAccountBudget ?? 0);
          foundAccount.forecast.revisedAccountBudget = (foundAccount.forecast.revisedAccountBudget ?? 0) + (account.forecast?.revisedAccountBudget ?? 0);
          foundAccount.forecast.riskAdjustedCosts = (foundAccount.forecast.riskAdjustedCosts ?? 0) + (account.forecast?.riskAdjustedCosts ?? 0);
          foundAccount.forecast.adjustmentCosts = (foundAccount.forecast.adjustmentCosts ?? 0) + (account.forecast?.adjustmentCosts ?? 0);
          foundAccount.forecast.forecast = (foundAccount.forecast.forecast ?? 0) + (account.forecast?.forecast ?? 0);
          foundAccount.forecast.riskAdjustedForecast = (foundAccount.forecast.riskAdjustedForecast ?? 0) + (account.forecast?.riskAdjustedForecast ?? 0);
          foundAccount.forecast.forecastWithSurcharge = (foundAccount.forecast.forecastWithSurcharge ?? 0) + (account.forecast?.forecastWithSurcharge ?? 0);
          foundAccount.forecast.projectSurcharge = (foundAccount.forecast.projectSurcharge ?? 0) + (account.forecast?.projectSurcharge ?? 0);
          foundAccount.forecast.surchargePercent = (foundAccount.forecast.surchargePercent ?? 0) + (account.forecast?.surchargePercent ?? 0);
          foundAccount.remainingForecastsToDistributeOnAccrualAndOperations = (foundAccount.remainingForecastsToDistributeOnAccrualAndOperations ?? 0) + (account.remainingForecastsToDistributeOnAccrualAndOperations ?? 0);
          foundAccount.interimExpense = foundAccount.interimExpense ?? {};
          foundAccount.interimExpense.accumulatedAccruedPosted = (foundAccount.interimExpense?.accumulatedAccruedPosted ?? 0) + (account.interimExpense?.accumulatedAccruedPosted ?? 0);
          foundAccount.interimExpense.receivedRegistered = (foundAccount.interimExpense?.receivedRegistered ?? 0) + (account.interimExpense?.receivedRegistered ?? 0);
          foundAccount.interimExpense.producedNotBilled = (foundAccount.interimExpense?.producedNotBilled ?? 0) + (account.interimExpense?.producedNotBilled ?? 0);
          foundAccount.interimExpense.accumulatedProduced = (foundAccount.interimExpense?.accumulatedProduced ?? 0) + (account.interimExpense?.accumulatedProduced ?? 0);
          foundAccount.interimExpense.constructionCosts = (foundAccount.interimExpense?.constructionCosts ?? 0) + (account.interimExpense?.constructionCosts ?? 0);
          foundAccount.interimExpense.administrationCosts = (foundAccount.interimExpense?.administrationCosts ?? 0) + (account.interimExpense?.administrationCosts ?? 0);
          foundAccount.interimExpense.surcharge = (foundAccount.interimExpense?.surcharge ?? 0) + (account.interimExpense?.surcharge ?? 0);
          foundAccount.interimExpense.accumulatedConstructionCosts = (foundAccount.interimExpense?.accumulatedConstructionCosts ?? 0) + (account.interimExpense?.accumulatedConstructionCosts ?? 0);
          foundAccount.interimExpense.accumulatedAdministrationCosts =
            (foundAccount.interimExpense?.accumulatedAdministrationCosts ?? 0) + (account.interimExpense?.accumulatedAdministrationCosts ?? 0);
          foundAccount.interimExpense.accumulatedSurcharge = (foundAccount.interimExpense?.accumulatedSurcharge ?? 0) + (account.interimExpense?.accumulatedSurcharge ?? 0);
          foundAccount.interimExpense.forecast = (foundAccount.interimExpense?.forecast ?? 0) + (account.interimExpense?.forecast ?? 0);
          foundAccount.interimExpense.producedPercentageOfForecast =
            (foundAccount.interimExpense.forecast ?? 0) !== 0 ? foundAccount.interimExpense.accumulatedProduced / (foundAccount.interimExpense.forecast ?? 0) : 0;
          foundAccount.accrualLastExpense = foundAccount.accrualLastExpense ?? {};
          foundAccount.accrualLastExpense.revisedForecast = (foundAccount.accrualLastExpense?.revisedForecast ?? 0) + (account.accrualLastExpense?.revisedForecast ?? 0);
          foundAccount.accrualLastExpense.actuallyAccumulatedPostedExpense =
            (foundAccount.accrualLastExpense?.actuallyAccumulatedPostedExpense ?? 0) + (account.accrualLastExpense?.actuallyAccumulatedPostedExpense ?? 0);
          groupId = foundAccount.id;
        }
        account.parentGroup = groupId;
      });
      resolvedAccounts = [...resolvedAccounts, ...resolvedAccountGroups];
    }
    return resolvedAccounts;
  };

  let accounts = getAccounts();
  if (selectedGroup) {
    accounts = accounts.filter(account => (account.accountGroup ?? "") === selectedGroup);
  }

  const openEditDialog = (account?: Account | undefined, handlingNewAccount?: boolean) => {
    setSelectedAccount(account ?? {});
    setHandlingNewAccount(handlingNewAccount ?? false);
    setShowEditDialog(true);
  };

  const onCloseEditorDialog = (mutatedAccounts: (Account | undefined)[]) => {
    mutatedAccounts.forEach((mutatedAccount) => {
      if (mutatedAccount) {
        accountContext.mutateAccount(mutatedAccount);
      }
    });
    setShowEditDialog(false);
    setShowEditAccountGroupDialog(false);
  };

  const [multipleAccountsMutationIndex, setMultipleAccountsMutationIndex] = useState<number>(-1);
  const [multipleAccountsToMutate, setMultipleAccountsToMutate] = useState<Account[]>([]);
  const onCloseImportDialog = (mutatedAccounts: Account[] | null) => {
    if (mutatedAccounts) {
      setMultipleAccountsToMutate(mutatedAccounts);
      setMultipleAccountsMutationIndex(0);
    } else {
      setShowImportDialog(false);
    }
  };

  useEffect(() => {
    if (multipleAccountsMutationIndex >= multipleAccountsToMutate.length || multipleAccountsMutationIndex < 0) {
      setShowImportDialog(false);
      if (multipleAccountsMutationIndex >= 0) {
        setMultipleAccountsMutationIndex(-1);
      }
      return;
    }
    const accountToMutate = multipleAccountsToMutate[multipleAccountsMutationIndex];
    accountContext.mutateAccount(
      accountToMutate,
      (mutatedAccount) => { },
      async () => {
        await new Promise((r) => setTimeout(r, 100));
        setMultipleAccountsMutationIndex(multipleAccountsMutationIndex + 1);
      }
    );
  }, [multipleAccountsMutationIndex]);

  const onClickRow = (selectedRow: Account | undefined) => {
    if (onClickAccount && selectedRow && selectedRow.id) {
      onClickAccount(selectedRow.id);
    }
  };

  const onEditAccountGroup = (accountGroup: string | undefined) => {
    setSelectedAccountGroup(accountGroup);
    setShowEditAccountGroupDialog(true);
  };

  const [pageSize, setPageSize] = useState<number>(1);
  const tableRef = useRef(null) as any;
  useRememberTableScroll(tableRef, "accountList");
  const pageSizeOptions = getPageSizeOptions(accounts.length);
  useEffect(() => {
    if (pageSizeOptions.length > 1 && pageSize < pageSizeOptions[pageSizeOptions.length - 1]) {
      const newPageSize = resolveDefaultPageSize(pageSizeOptions, accounts.length);
      tableRef.current.dataManager.changePageSize(newPageSize);
      setPageSize(newPageSize);
    }
  }, [accounts]);

  const showToolbar = !(showInterims || showForecasts || accountTypeFocus === AccountType.ACCRUAL);
  const renderToolbar = (props: any) => (
    <div>
      <MTableToolbar {...props} />
      <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
        <Button
          color="inherit"
          size="small"
          className={classes.button}
          startIcon={<Add />}
          disabled={accountContext.loadingAccounts || projectContext.loadingProjects}
          onClick={() => openEditDialog(undefined, true)}
        >
          {languageContext.getMessage("addAccount")}
        </Button>
        <Button
          color="inherit"
          size="small"
          className={classes.button}
          startIcon={<Add />}
          disabled={accountContext.loadingAccounts || projectContext.loadingProjects}
          onClick={() => setShowImportDialog(true)}
        >
          {languageContext.getMessage("importAccounts")}
        </Button>
      </Toolbar>
    </div>
  );

  const renderGroupbar = (props: any) => <div></div>;

  const tableComponents: Components = {
    Groupbar: renderGroupbar,
  };

  if (showToolbar && projectContext.hasProjectAccess(RoleType.WRITER)) {
    tableComponents.Toolbar = renderToolbar;
  }

  let columns: Column<GroupedAccount>[] = [];
  let exportType: accountListTemplateAlternatives = "account";
  if (showForecasts) {
    columns = getForecastColumns(languageContext, projectContext, showGroups, onEditAccountGroup, theme, openEditDialog, onClickRow);
    exportType = "forecast";
  } else if (showInterims) {
    columns = getInterimColumns(languageContext, projectContext, showGroups, onEditAccountGroup, interimContext, theme, openEditDialog, onClickRow);
    exportType = "interim";
  } else if (!accountTypeFocus || accountTypeFocus === AccountType.NORMAL || accountTypeFocus === AccountType.ADMINISTRATION) {
    columns = getAccountColumns(languageContext, projectContext, showGroups, onEditAccountGroup, openEditDialog, onClickRow);
    exportType = "account";
  } else if (accountTypeFocus === AccountType.ACCRUAL) {
    columns = getAccrualColumns(languageContext, projectContext, showGroups, onEditAccountGroup, theme, openEditDialog, onClickRow);
    exportType = "accrual";
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <EditAccountGroupDialog open={showEditAccountGroupDialog} onClose={onCloseEditorDialog} accountGroup={selectedAccountGroup} />
        {!showInterims && (
          <EditAccountDialog
            open={showEditDialog}
            onClose={(mutatedAccount) => onCloseEditorDialog([mutatedAccount])}
            account={selectedAccount}
            accountTypeFocus={accountTypeFocus}
            handlingNewAccount={handlingNewAccount}
          ></EditAccountDialog>
        )}
        {showInterims && <EditInterimDialog open={showEditDialog} onClose={() => onCloseEditorDialog([])} account={selectedAccount}></EditInterimDialog>}
        <ImportMultipleAccountsDialog
          open={showImportDialog}
          onClose={onCloseImportDialog}
          multipleAccountsMutationIndex={multipleAccountsMutationIndex}
          accountType={accountTypeFocus}
          projectId={projectContext.getSelectedProject()?.id || ""}
        />
        <MaterialTable
          tableRef={tableRef}
          icons={tableIcons}
          localization={materialTableLanguageContext.getLocalization()}
          columns={columns}
          //   data={accounts.filter(notUndefined).sort((a, b) => sortByName(a.accountNumber, b.accountNumber))}
          data={accounts}
          onTreeExpandChange={(data: GroupedAccount, isExpanded: boolean) => {
            updateUrlStateWithExpandedGroups(data.accountGroup ?? "", isExpanded);
          }}
          parentChildData={(row, rows) => {
            const rowData = row as any;
            rowData.tableData.isTreeExpanded = groupIsExpanded(rowData.accountGroup ?? "");
            return rows.find((a) => a.id === row.parentGroup);
          }}
          title={languageContext.getMessage("accountList")}
          onChangeRowsPerPage={(newPageSize: number) => {
            setPageSize(newPageSize);
          }}
          // actions={[
          //   {
          //     icon: () => <NavigateNextIcon />,
          //     tooltip: languageContext.getMessage("details"),
          //     position: "row",
          //     onClick: (event, selectedRow) => onClickRow(selectedRow as Account),
          //   },
          //   {
          //     icon: () => <EditIcon />,
          //     tooltip: languageContext.getMessage("edit"),
          //     position: "row",
          //     hidden: !projectContext.hasProjectAccess(RoleType.WRITER),
          //     onClick: (event, selectedRow) => {
          //       openEditDialog(selectedRow as Account);
          //     },
          //   },
          // ]}
          options={{
            padding: "dense",
            exportButton: { csv: true, pdf: false },
            exportCsv: (columns, renderData) => {
              accountContext.downloadAccounts(accounts.filter(notUndefined), exportType, interimContext);
            },
            columnsButton: true,
            paging: false,
            //   grouping: showGroups,
            //   groupTitle: (groupData) => {
            //     return groupData.value && groupData.value.length > 0 ? groupData.value : languageContext.getMessage("general");
            //   },
            //   groupRowSeparator: " ",
            headerStyle: { position: "sticky", top: 0, fontWeight: "bold" },
            maxBodyHeight: "70vh",
          }}
          components={tableComponents}
        />
      </Grid>
    </Grid>
  );
};

export default withTheme(AccountList);

const getDefaultAccountColumns = (
  languageContext: LanguageContext,
  showGroups: boolean,
  onEditAccountGroup: (accountGroup: string | undefined) => void,
  projectContext: ProjectContext,
  openEditDialog?: (account?: Account | undefined, handlingNewAccount?: boolean) => void,
  onClickRow?: (selectedRow: Account | undefined) => void,
  interimContext?: InterimContext
): Column<GroupedAccount>[] => {
  const groupColumns: Column<GroupedAccount>[] = [
    {
      title: <b>{showGroups ? languageContext.getMessage("group") : languageContext.getMessage("accountNumber")}</b>,
      field: showGroups ? "parentGroup" : "accountNumber",
      cellStyle: CustomTableStyle,
      render: (account) => (
        <>
          <Grid container wrap="nowrap" spacing={1} alignItems="center">
            {account.parentGroup === noneParentGroup ? (
              <>
                <Grid item>
                  <Tooltip title={languageContext.getMessage("edit")} key={account?.accountGroup as string | undefined}>
                    <IconButton onClick={() => onEditAccountGroup(account?.accountGroup as string | undefined)} size="small">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography>{account?.accountGroup}</Typography>
                </Grid>
              </>
            ) : (
              <>
                {onClickRow && (
                  <Grid item>
                    <Tooltip title={languageContext.getMessage("details")} key={account?.accountGroup as string | undefined}>
                      <IconButton onClick={() => onClickRow(account as Account)} size="small" disabled={!projectContext.hasProjectAccess(RoleType.WRITER)}>
                        <NavigateNextIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                {openEditDialog && (
                  <Grid item>
                    <Tooltip title={languageContext.getMessage("edit")} key={account?.accountGroup as string | undefined}>
                      <IconButton onClick={() => openEditDialog(account as Account)} disabled={!projectContext.hasProjectAccess(RoleType.WRITER)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item>
                  {/* <Typography>{""}</Typography> */}
                  <Typography>{`${account.accountNumber}`}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </>
      ),
    },
  ];
  const columns: Column<GroupedAccount>[] = groupColumns.concat([
    {
      title: languageContext.getMessage("name"),
      field: "name",
      align: "left",
      cellStyle: CustomTableStyle,
      render: (account) => <TicketLoading documentId={interimContext ? interimContext.getInterim(account.id)?.id : account.id} component={account.name} />,
    },
  ]);
  return columns;
};

const getAccountColumns = (
  languageContext: LanguageContext,
  projectContext: ProjectContext,
  showGroups: boolean,
  onEditAccountGroup: (accountGroup: string | undefined) => void,
  openEditDialog?: (account?: Account | undefined, handlingNewAccount?: boolean) => void,
  onClickRow?: (selectedRow: Account | undefined) => void
): Column<GroupedAccount>[] => {
  const columns: Column<GroupedAccount>[] = [
    {
      title: languageContext.getMessage("accountCodes"),
      field: "externalId",
      cellStyle: CustomTableStyle,
      hidden: true,
    },
    {
      title: languageContext.getMessage("accountBudget"),
      render: (account) => <CurrencyFormat amount={account.budget} />,
      align: "right",
      hidden: false,
      cellStyle: CustomTableStyle,
      editable: "never",
    },
    {
      title: languageContext.getMessage("revisedAccountBudget"),
      render: (account) => <CurrencyFormat amount={account.forecast?.revisedAccountBudget} />,
      align: "right",
      hidden: false,
      cellStyle: CustomTableStyle,
      editable: "never",
    },
    {
      title: languageContext.getMessage("netAccountBudget"),
      render: (account) => <CurrencyFormat amount={account?.forecast?.netAccountBudget} />,
      align: "right",
      hidden: false,
      cellStyle: CustomTableStyle,
      editable: "never",
    },
    {
      title: languageContext.getMessage("finalForecast"),
      render: (account) => <CurrencyFormat amount={account?.forecast?.forecast} />,
      align: "right",
      hidden: false,
      cellStyle: CustomTableStyle,
      editable: "never",
    },
    {
      title: languageContext.getMessage("revisedFinalForecast"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => {
        if (account.accountType === AccountType.ACCRUAL) {
          return <CurrencyFormat amount={account?.accrualLastExpense?.revisedForecast} />;
        } else {
          return null;
        }
      },
    },
    // {
    //   title: languageContext.getMessage("netAccountBudget"),
    //   render: (account) => <CurrencyFormat amount={account?.forecast?.riskAdjustedForecast} />,
    //   align: "right",
    //   hidden: false,
    //   editable: "never",
    // },
  ];
  return getDefaultAccountColumns(languageContext, showGroups, onEditAccountGroup, projectContext, openEditDialog, onClickRow).concat(columns);
};

const getAccrualColumns = (
  languageContext: LanguageContext,
  projectContext: ProjectContext,
  showGroups: boolean,
  onEditAccountGroup: (accountGroup: string | undefined) => void,
  theme: Theme,
  openEditDialog?: (account?: Account | undefined, handlingNewAccount?: boolean) => void,
  onClickRow?: (selectedRow: Account | undefined) => void
): Column<GroupedAccount>[] => {
  const columns: Column<GroupedAccount>[] = [
    {
      title: languageContext.getMessage("finalForecast"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.forecast} />,
    },
    {
      title: languageContext.getMessage("revisedFinalForecast"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => {
        return (<Grid container wrap="nowrap" alignItems="center" direction='row' spacing={1} justify="flex-end">
          {(account?.accrualLastExpense?.revisedForecast ?? 0) > (account.forecast?.forecast ?? 0) && (
            <Grid item>
              <Tooltip title={languageContext.getMessage("revisedForecastIsAboveForecast")}>
                <Info style={{ color: "#FF4646" }} />
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <CurrencyFormat amount={account?.accrualLastExpense?.revisedForecast} />
          </Grid>
        </Grid>)
      },
    },
    {
      title: languageContext.getMessage("actuallyAccumulatedPostedExpense"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => {
        return <CurrencyFormat amount={account?.accrualLastExpense?.actuallyAccumulatedPostedExpense} />;
      },
    },
    {
      title: languageContext.getMessage("remainsToDistribute"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => (
        <div style={{ display: "inline-flex", alignItems: "middle" }}>
          <CurrencyFormat amount={account.remainingForecastsToDistributeOnAccrualAndOperations} />
          &nbsp;
          {getRenderedStatus(getRemainsToDistributeHealthStatus(account.remainingForecastsToDistributeOnAccrualAndOperations ?? 0), theme)}
        </div>
      ),
    },
  ];
  return getDefaultAccountColumns(languageContext, showGroups, onEditAccountGroup, projectContext, openEditDialog, onClickRow).concat(columns);
};

const getForecastColumns = (
  languageContext: LanguageContext,
  projectContext: ProjectContext,
  showGroups: boolean,
  onEditAccountGroup: (accountGroup: string | undefined) => void,
  theme: Theme,
  openEditDialog?: (account?: Account | undefined, handlingNewAccount?: boolean) => void,
  onClickRow?: (selectedRow: Account | undefined) => void
): Column<GroupedAccount>[] => {
  const columns: Column<GroupedAccount>[] = [
    {
      title: languageContext.getMessage("date"),
      field: "forecast.date",
      editable: "never",
      type: "date",
      hidden: true,
      cellStyle: CustomTableStyle,
      render: (account) => <DateFormat date={account.forecast?.date} />,
    },
    {
      title: languageContext.getMessage("finalForecast"),
      field: "forecast.forecast",
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.forecast} />,
    },
    {
      title: languageContext.getMessage("accumulatedProduced"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.interimExpense?.accumulatedProduced} />,
    },
    {
      title: languageContext.getMessage("producedPercentageOfForecast"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => (
        <div style={{ display: "inline-flex", alignItems: "middle" }}>
          <PercentageFormat percentage={account.interimExpense?.producedPercentageOfForecast} />
          {(account.interimExpense?.producedPercentageOfForecast ?? 0.0) > 1.0 && <>
            &nbsp;
            {getRenderedStatus(HealthStatus.UNHEALTHY, theme)}
          </>}
        </div>
      ),
    },
    {
      title: languageContext.getMessage("accountBudget"),
      field: "accountBudget",
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.accountBudget} />,
    },
    {
      title: languageContext.getMessage("netAccountBudget"),
      field: "forecast.netAccountBudget",
      editable: "never",
      type: "numeric",
      hidden: true,
      cellStyle: CustomTableStyle,
      render: (account) => (
        <div style={{ display: "inline-flex", alignItems: "middle" }}>
          <CurrencyFormat amount={account.forecast?.netAccountBudget} />
          &nbsp;
          {getRenderedStatus(account.forecast?.status ?? HealthStatus.UNHEALTHY, theme)}
        </div>
      ),
    },
    {
      title: `${languageContext.getMessage("approved_plural")} ${languageContext.getMessage("changes")} ${languageContext.getMessage("landlordShort")}`,
      field: "forecast.changeOrders",
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.changeOrderLandlordApprovedCosts} />,
    },
    {
      title: `${languageContext.getMessage("not_approved_plural")} ${languageContext.getMessage("changes")} ${languageContext.getMessage("landlordShort")}`,
      field: "forecast.changeOrders",
      editable: "never",
      type: "numeric",
      hidden: true,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.changeOrderLandlordNotApprovedCosts} />,
    },
    {
      title: `${languageContext.getMessage("revised")} ${languageContext.getMessage("accountBudget")}`,
      field: "forecast.revisedAccountBudget",
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.revisedAccountBudget} />,
    },
    {
      title: languageContext.getMessage("contractProcurement"),
      field: "contractProcurement",
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.contractProcurement} />,
    },
    {
      title: `${languageContext.getMessage("approved_plural")} ${languageContext.getMessage("changes")} ${languageContext.getMessage("subContractorShort")}`,
      field: "changeOrdersSubContractor",
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.changeOrderSubContractorApprovedCosts} />,
    },
    {
      title: `${languageContext.getMessage("not_approved_plural")} ${languageContext.getMessage("changes")} ${languageContext.getMessage("subContractorShort")}`,
      field: "changeOrdersSubContractor",
      editable: "never",
      type: "numeric",
      hidden: true,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.changeOrderSubContractorNotApprovedCosts} />,
    },
    {
      title: languageContext.getMessage("expectedChange"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.changeOrderExpectedCosts} />,
    },
    {
      title: languageContext.getMessage("contractCostScope"),
      field: "contractCostScope",
      editable: "never",
      type: "numeric",
      hidden: true,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.contractCostScope} />,
    },
    {
      title: languageContext.getMessage("riskAdjustments"),
      editable: "never",
      type: "numeric",
      hidden: true,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.riskAdjustedCosts} />,
    },
    {
      title: `${languageContext.getMessage("forecastWithSurcharge")}`,
      field: "forecastWithSurcharge",
      editable: "never",
      type: "numeric",
      hidden: true,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.forecast?.forecastWithSurcharge} />,
    },
  ];
  return getDefaultAccountColumns(languageContext, showGroups, onEditAccountGroup, projectContext, openEditDialog, onClickRow).concat(columns);
};

const getInterimColumns = (
  languageContext: LanguageContext,
  projectContext: ProjectContext,
  showGroups: boolean,
  onEditAccountGroup: (accountGroup: string | undefined) => void,
  interimContext: InterimContext,
  theme: Theme,
  openEditDialog?: (account?: Account | undefined, handlingNewAccount?: boolean) => void,
  onClickRow?: (selectedRow: Account | undefined) => void
): Column<GroupedAccount>[] => {
  const columns: Column<GroupedAccount>[] = [
    {
      title: languageContext.getMessage("date"),
      editable: "never",
      type: "date",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <DateFormat date={account.interimExpense?.date} showMonth={true} showYear={true} />,
    },
    {
      title: languageContext.getMessage("finalForecast"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.interimExpense?.forecast} />,
    },
    {
      title: languageContext.getMessage("accumulatedAccruedPosted"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.interimExpense?.accumulatedAccruedPosted} />,
    },
    {
      title: languageContext.getMessage("receivedRegistered"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.interimExpense?.receivedRegistered} />,
    },
    {
      title: languageContext.getMessage("producedNotBilled"),
      editable: "never",
      align: "right",
      // type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <>{account.parentGroup !== noneParentGroup ? <EditInterimProducedNotBilled account={account} /> : <CurrencyFormat amount={account.interimExpense?.producedNotBilled} />}</>,
    },
    {
      title: languageContext.getMessage("accumulatedProduced"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => <CurrencyFormat amount={account.interimExpense?.accumulatedProduced} />,
    },
    {
      title: languageContext.getMessage("producedPercentageOfForecast"),
      editable: "never",
      type: "numeric",
      hidden: false,
      cellStyle: CustomTableStyle,
      render: (account) => (
        <div style={{ display: "inline-flex", alignItems: "middle" }}>
          <PercentageFormat percentage={account.interimExpense?.producedPercentageOfForecast} />
          {(account.interimExpense?.producedPercentageOfForecast ?? 0.0) > 1.0 && <>
            &nbsp;
            {getRenderedStatus(HealthStatus.UNHEALTHY, theme)}
          </>}
        </div>
      ),
    },
  ];
  return getDefaultAccountColumns(languageContext, showGroups, onEditAccountGroup, projectContext, openEditDialog, onClickRow, interimContext).concat(columns);
};
