import { Grid, withTheme } from "@material-ui/core";
import React from "react";
import { Account, ChangeOrderStatus, Contract, ElementStatusEnum } from "../../contracts/contracts";
import StatusCard from "../../component/generalComponents/StatusCard";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import SelectChangeOrderSubContractorFilter from "../../component/changeOrderSubContractorComponents/SelectChangeOrderSubContractorFilter";
import ChangeOrderSubContractorOverviewGraphsCard from "../../component/changeOrderSubContractorComponents/ChangeOrderSubContractorOverviewGraphsCard";

type props = {
  selectedAccount: Account | undefined;
  selectedContract: Contract | undefined;
  onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
  onSelectedContractChange: (newSelectedContract: Contract | undefined) => void;
}

const ChangeOrderSubContractorOverview: React.FC<props> = ({
  selectedAccount,
  selectedContract,
  onSelectedAccountChange,
  onSelectedContractChange}) => {

  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();

  const numberOfNotProcessedChangeOrderSubContractors = changeOrderSubContractorContext.getChangeOrderSubContractors().filter(changeOrderSubContractor => changeOrderSubContractor.status === ChangeOrderStatus.NOT_PROCESSED).length;
  const numberOfApprovovedChangeOrderSubContractors = changeOrderSubContractorContext.getChangeOrderSubContractors().filter(changeOrderSubContractor => changeOrderSubContractor.status === ChangeOrderStatus.APPROVED).length;
  const numberOfNotApprovedChangeOrderSubContractors = changeOrderSubContractorContext.getChangeOrderSubContractors().filter(changeOrderSubContractor => changeOrderSubContractor.status === ChangeOrderStatus.NOT_APPROVED).length;

  return (<Grid container spacing={2}> 
            <Grid item xs={12}>
              <SelectChangeOrderSubContractorFilter 
                  selectedAccount={selectedAccount}
                  selectedContract={selectedContract}
                  onSelectedAccountChange={onSelectedAccountChange}
                  onSelectedContractChange={onSelectedContractChange}/>
            </Grid>
          
            <Grid item container spacing={2}> 
              <Grid item xs={12} lg={4}>
                <StatusCard
                head={'notProcessed'}
                content={'subContractor'}
                status={ElementStatusEnum.INFORMATION}
                data={numberOfNotProcessedChangeOrderSubContractors}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StatusCard
                head={'approved'}
                content={'subContractor'}
                status={ElementStatusEnum.SUCCESS}
                data={numberOfApprovovedChangeOrderSubContractors}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StatusCard
                head={'notApproved'}
                content={'subContractor'}
                status={ElementStatusEnum.ALERT}
                data={numberOfNotApprovedChangeOrderSubContractors}
                />
              </Grid>
            </Grid>

          <Grid item xs={12}> 
            <ChangeOrderSubContractorOverviewGraphsCard selectedContract={selectedContract} selectedAccount={selectedAccount}/>
          </Grid>
        </Grid>
  );
}

export default withTheme(ChangeOrderSubContractorOverview);



