import { makeStyles, Theme, withTheme } from "@material-ui/core";
import React from "react";
import clsx from "clsx";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.default,

    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: "auto",
    position: "absolute",
  },
}));

const Background: React.FC<{ className: string }> = (props) => {
  const classes = useStyles();

  const className = clsx(classes.root, props.className);

  return (
    <>
      <div className={className}>{props.children}</div>
    </>
  );
};

export default withTheme(Background);
