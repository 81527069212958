import React, { createContext, useContext, useEffect, useState } from "react";
import { Risk } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_RISK, getRisksGraphqlQueryOptions } from './riskQueries'
import { useAuthContext } from "../../auth/authContext";

export interface RiskQueriesContext {
    fetchedRisks: Array<Risk>,
    queryRisks: (risk: Risk) => void,
}

const RiskQueriesContext = createContext<RiskQueriesContext>(null as unknown as RiskQueriesContext);

export const RiskQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedRisks, setFetchedRisks] = useState<Array<Risk>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_RISK);

    const queryRisks = (risk: Risk): void => {
        const queryOptions = getRisksGraphqlQueryOptions(risk);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.risks) {
            const newFetchedRisks: Array<Risk> = queryResponse.data.risks;
            setFetchedRisks(newFetchedRisks);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedRisks([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const riskQueriesContext: RiskQueriesContext = {
        fetchedRisks,
        queryRisks,
    };

    return (
        <RiskQueriesContext.Provider value={riskQueriesContext}>
            {children}
        </RiskQueriesContext.Provider>
    );
}

export const useRiskQueriesContext = (): RiskQueriesContext => {
    return useContext(RiskQueriesContext);
}
