import { withTheme, makeStyles, Theme, Grid, Button, Divider, IconButton, Paper, Snackbar, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Contract, Account, ChangeOrderExpected, ChangeOrderSubContractor, ChangeOrderCounterClaim } from "../../contracts/contracts";
import SelectContract from "../../component/contractComponents/SelectContract";
import ContractDetailCard from "../../component/contractComponents/ContractDetailCard";
import ViewHeading from "../../component/viewComponents/ViewHeading";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import ChangeOrderExpectedList from "../../component/changeOrderExpectedComponents/ChangeOrderExpectedList";
import ChangeOrderSubContractorList from "../../component/changeOrderSubContractorComponents/ChangeOrderSubContractorList";
import InvoiceGraph from "../../component/invoiceComponents/InvoiceGraph";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useForecastsContext } from "../../contexts/forecast/forecastsContext";
import { InvoiceTabs, useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { useMenuContext, ApplicationRouteId } from "../../contexts/menu/menuContext";
import { Dictionary } from "../../global-types";
import { useUrlContext } from "../../contexts/url/urlContext";
import ChangeOrderCounterClaimList from "../../component/changeOrderCounterClaimComponents/ChangeOrderCounterClaimList";
import { AccountDialogStates } from "../account/AccountDetailsView";
import CurrencyTable, { CurrencyTableElement, CurrencyTableGroup } from "../../component/accountComponents/CurrencyTable";
import { Alert } from "@material-ui/lab";
import SelectAccount from "../../component/accountComponents/SelectAccount";
import ContractList from "../../component/contractComponents/ContractList";
import { Edit, Save, Add } from "@material-ui/icons";
import EditChangeOrderCounterClaimDialog from "../../component/changeOrderCounterClaimComponents/EditChangeOrderCounterClaimDialog";
import EditChangeOrderExpectedDialog from "../../component/changeOrderExpectedComponents/EditChangeOrderExpectedDialog";
import EditChangeOrderSubContractorDialog from "../../component/changeOrderSubContractorComponents/EditChangeOrderSubContractorDialog";
import EditContractDialog from "../../component/contractComponents/EditContractDialog";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: "1em",
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
  demoGraph: {
    maxHeight: "399px",
    padding: "1em",
    marginTop: "30px",
  },
  wrapper: {
    height: "310px",
    margin: "auto",
  },
}));

type props = {
  selectedContract: Contract | undefined;
  selectedAccount: Account | undefined;
  onSelectedContractChange: (newSelectedContract: Contract | undefined) => void;
  detailTabIndex: string;
  handleDetailTabIndexChange: (event: React.ChangeEvent<{}>, newTabIndex: string) => void;
};

const ContractViewDetails: React.FC<props> = ({ selectedContract, selectedAccount, onSelectedContractChange, detailTabIndex, handleDetailTabIndexChange }) => {
  const classes = useStyles();
  const urlContext = useUrlContext();
  const languageContext = useLanguageContext();
  const invoiceContext = useInvoiceContext();
  const accountContext = useAccountContext();
  const forecastContext = useForecastsContext();
  const contractContext = useContractContext();
  const userRoleContext = useUserRoleContext();
  const menuContext = useMenuContext();

  const users = userRoleContext.getUsers();

  const [showOnlyForecast, setShowOnlyForecast] = useState<boolean>(false);

  const [dialogStates, setDialogStates] = useState<AccountDialogStates>({ contract: false, changeOrderExpected: false, changeOrderSubContractor: false, changeOrderCounterClaim: false });

  const [contractNotes, setContractNotes] = useState<string | undefined>(selectedContract?.comment ?? "");
  const [contractNotesChanged, setContractNotesChanged] = useState<boolean>(false);
  const [contractNotesSaved, setContractNotesSaved] = useState<boolean>(false);

  function saveNotes() {
    if (selectedContract) {
      contractContext.mutateContract({ ...selectedContract, comment: contractNotes });
      setContractNotesSaved(true);
      setContractNotesChanged(false);
    }
  }

  const tableData: CurrencyTableElement[] = [
    {
      group: languageContext.getMessage("contracts"),
      label: languageContext.getMessage("sumContractBudget"),
      value: selectedContract?.adjustedBudget,
      markedValue: selectedContract?.adjustedBudget,
      isMarked: selectedContract?.contractProcurementIsUsedWithForecast === true ? false : true,
    },
    {
      group: languageContext.getMessage("contracts"),
      label: languageContext.getMessage("sumContractProcurement"),
      value: selectedContract?.procurement,
      markedValue: selectedContract?.procurement,
      isMarked: selectedContract?.contractProcurementIsUsedWithForecast === true ? true : false,
    },
    {
      group: languageContext.getMessage("contracts"),
      label: languageContext.getMessage("invoicedTotal"),
      value: (selectedContract?.sumInvoicedAmount ?? 0) + (selectedContract?.sumInvoicedWithheldAmount ?? 0),
    },
    { group: languageContext.getMessage("contracts"), label: languageContext.getMessage("payedOut"), value: selectedContract?.sumInvoicedAmount },
    { group: languageContext.getMessage("contracts"), label: languageContext.getMessage("whereAsWithheld"), value: selectedContract?.sumInvoicedWithheldAmount },
    {
      group: languageContext.getMessage("changeOrder"),
      label: languageContext.getMessage("cost"),
      value: selectedContract?.sumChangeOrderSubContractorCost,
      isMarked: true,
      markedValue: selectedContract?.sumChangeOrderSubContractorCost,
    },
    { group: languageContext.getMessage("changeOrder"), label: languageContext.getMessage("invoicedAmount"), value: selectedContract?.sumChangeOrderSubContractorInvoicedAmount },
    {
      group: languageContext.getMessage("adjustmentsRisksAndUnforeseen"),
      label: languageContext.getMessage("expectedChange"),
      value: selectedContract?.sumChangeOrderExpectedCost,
      isMarked: true,
      markedValue: selectedContract?.sumChangeOrderExpectedCost,
    },
    {
      group: languageContext.getMessage("adjustmentsRisksAndUnforeseen"),
      label: languageContext.getMessage("counterClaim"),
      value: selectedContract?.sumChangeOrderCounterClaimCost,
      isMarked: true,
      markedValue: selectedContract?.sumChangeOrderCounterClaimCost,
    },
  ];

  const tableDataGroups: CurrencyTableGroup[] = [
    { value: languageContext.getMessage("contracts"), isMarked: true },
    { value: languageContext.getMessage("changeOrder"), isMarked: true },
    { value: languageContext.getMessage("adjustmentsRisksAndUnforeseen"), isMarked: true },
  ];

  const sumTotal =
    (selectedContract?.contractProcurementIsUsedWithForecast === true ? selectedContract?.procurement ?? 0 : selectedContract?.adjustedBudget ?? 0) +
    (selectedContract?.sumChangeOrderSubContractorCost ?? 0) +
    (selectedContract?.sumChangeOrderExpectedCost ?? 0) -
    (selectedContract?.sumChangeOrderCounterClaimCost ?? 0);

  const changeOrderExpectedRoute = menuContext.getApplicationRouteById(ApplicationRouteId.changeOrderExpected);
  const updateUrlStateToChangeOrderExpectedDetails = (newSelectedChangeOrder: ChangeOrderExpected | undefined): void => {
    const newUrlState = {
      ...{ changeOrderExpectedId: newSelectedChangeOrder?.id },
      ...{ tab: "details" },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery, changeOrderExpectedRoute.route);
  };

  const changeOrderSubContractorRoute = menuContext.getApplicationRouteById(ApplicationRouteId.changeOrderSubContractor);
  const updateUrlStateToChangeOrderSubContractorDetails = (newSelectedChangeOrder: ChangeOrderSubContractor | undefined): void => {
    const newUrlState = {
      ...{ changeOrderSubContractorId: newSelectedChangeOrder?.id },
      ...{ tab: "details" },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery, changeOrderSubContractorRoute.route);
  };

  const changeOrderCounterClaimRoute = menuContext.getApplicationRouteById(ApplicationRouteId.changeOrderCounterClaim);
  const updateUrlStateToChangeOrderCounterClaimDetails = (newSelectedChangeOrder: ChangeOrderCounterClaim | undefined): void => {
    const newUrlState = {
      ...{ changeOrderCounterClaimId: newSelectedChangeOrder?.id },
      ...{ tab: "details" },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery, changeOrderCounterClaimRoute.route);
  };

  const invoices = selectedContract?.id ? invoiceContext.getFilteredInvoices({ contractId: selectedContract.id }) : [];

  return (
    <>
      {dialogStates.contract && (
        <EditContractDialog
          open={dialogStates.contract}
          onClose={(mutatedContract) => {
            if (mutatedContract) {
              contractContext.mutateContract(mutatedContract);
            }
            setDialogStates({ ...dialogStates, contract: false });
          }}
          contract={selectedContract}
          handleNewContract={true}
        />
      )}
      {dialogStates.changeOrderCounterClaim && (
        <EditChangeOrderCounterClaimDialog
          open={dialogStates.changeOrderCounterClaim}
          onClose={() => setDialogStates({ ...dialogStates, changeOrderCounterClaim: false })}
          changeOrderCounterClaim={{ accountId: selectedContract?.accountId, contractId: selectedContract?.id, projectId: selectedAccount?.projectId }}
        />
      )}
      {dialogStates.changeOrderExpected && (
        <EditChangeOrderExpectedDialog
          open={dialogStates.changeOrderExpected}
          onClose={() => setDialogStates({ ...dialogStates, changeOrderExpected: false })}
          changeOrderExpected={{ accountId: selectedContract?.accountId, contractId: selectedContract?.id, projectId: selectedAccount?.projectId }}
        />
      )}
      {dialogStates.changeOrderSubContractor && (
        <EditChangeOrderSubContractorDialog
          open={dialogStates.changeOrderSubContractor}
          onClose={() => setDialogStates({ ...dialogStates, changeOrderSubContractor: false })}
          changeOrderSubContractor={{ accountId: selectedContract?.accountId, contractId: selectedContract?.id, projectId: selectedAccount?.projectId }}
        />
      )}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper style={{ padding: "1em" }} elevation={2}>
            <Grid container wrap="nowrap" spacing={1} alignItems="flex-end">
              <Grid item>
                <SelectContract selectedContract={selectedContract} onSelectedContractChange={onSelectedContractChange} minWidth={200} selectedAccount={selectedAccount} />
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setDialogStates({ ...dialogStates, contract: true })} startIcon={<Edit />}>
                  {languageContext.getMessage("edit")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setDialogStates({ ...dialogStates, changeOrderExpected: true })} startIcon={<Add />}>
                  {languageContext.getMessage("expectedChange")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setDialogStates({ ...dialogStates, changeOrderSubContractor: true })} startIcon={<Add />}>
                  {`${languageContext.getMessage("changeOrder")} ${languageContext.getMessage("subContractorShort")}`}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setDialogStates({ ...dialogStates, changeOrderCounterClaim: true })} startIcon={<Add />}>
                  {languageContext.getMessage("counterClaim")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 0 }} elevation={2}>
            <Tabs
              value={detailTabIndex}
              onChange={(event, value) => {
                handleDetailTabIndexChange(event, value);
              }}
            >
              <Tab label={languageContext.getMessage("general")} value="general" />
              <Tab label={languageContext.getMessage("expectedChange")} value="changeOrderExpected" />
              <Tab label={`${languageContext.getMessage("changeOrder")} ${languageContext.getMessage("subContractorShort")}`} value="changeOrderSubContractor" />
              <Tab label={languageContext.getMessage("counterClaim")} value="changeOrderCounterClaim" />
              <Tab label={languageContext.getMessage("invoices")} value="Invoices" />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {detailTabIndex === "general" && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CurrencyTable
                  tableData={tableData}
                  groups={tableDataGroups}
                  title={languageContext.getMessage("summary")}
                  showMarked={showOnlyForecast}
                  setShowMarked={setShowOnlyForecast}
                  markedTitle={languageContext.getMessage("showForecastOnly")}
                  totalTitle={languageContext.getMessage("finalForecast")}
                  totalValue={sumTotal}
                />
              </Grid>
              <Grid item xs={6}>
                <Paper style={{ padding: "1em" }} elevation={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container wrap="nowrap" spacing={1} alignItems="center">
                        <Grid item>
                          <Typography variant="h5">{languageContext.getMessage("notes")}</Typography>
                        </Grid>
                        <Grid item>
                          <IconButton size="small"  disabled={!contractNotesChanged} onClick={saveNotes}>
                            <Save />
                          </IconButton>
                        </Grid>
                        <Grid item>{languageContext.getMessage("changedBy") + ": " + users.find((user) => user.id === selectedContract?.changedBy)?.name ?? ""}</Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={contractNotes}
                        onChange={(event) => {
                          setContractNotes(event.target.value);
                          if (contractNotesChanged === false) {
                            setContractNotesChanged(true);
                          }
                        }}
                        fullWidth
                        multiline
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
          {detailTabIndex === "changeOrderExpected" && (
            <ChangeOrderExpectedList selectedContract={selectedContract} showTitle={true} onSelectedChangeOrderExpectedChange={updateUrlStateToChangeOrderExpectedDetails} hideToolbar={true} />
          )}
          {detailTabIndex === "changeOrderSubContractor" && (
            <ChangeOrderSubContractorList
              selectedAccount={selectedAccount}
              selectedContract={selectedContract}
              showTitle={true}
              onSelectedChangeOrderSubContractorChange={updateUrlStateToChangeOrderSubContractorDetails}
              hideToolbar={true}
            />
          )}
          {detailTabIndex === "changeOrderCounterClaim" && (
            <ChangeOrderCounterClaimList
              selectedAccount={selectedAccount}
              selectedContract={selectedContract}
              showTitle={true}
              onSelectedChangeOrderCounterClaimChange={updateUrlStateToChangeOrderCounterClaimDetails}
              hideToolbar={true}
            />
          )}
          {detailTabIndex === "Invoices" && (
            <InvoiceGraph
              invoices={invoices}
              onShowInvoicesFilter={{
                selectedContractIdFilter: selectedContract?.id,
                tab: InvoiceTabs.processed,
              }}
              fullWidth={true}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar open={contractNotesSaved} autoHideDuration={1800} onClose={() => setContractNotesSaved(false)}>
        <Alert severity="success">{languageContext.getMessage("notesSaved")}</Alert>
      </Snackbar>
    </>
  );
};

export default withTheme(ContractViewDetails);
