import { Interim, InterimExpense } from "../../contracts/contracts";
import { datesAreOnSameMonth } from "../../utils/dateTools";

export const sortInterimExpenseByDate = (a: InterimExpense, b: InterimExpense) => {
    if ((a.date ?? "") < (b.date ?? "")) { return -1; }
    if ((a.date ?? "") > (b.date ?? "")) { return 1; }
    return 0;
}

export const receiveInterims = (
    currentInterimSearch: Interim | undefined,
    newInterim: Interim | undefined): Interim => {
    if (!newInterim) {
        console.error(`Received undefined set of interims: ${newInterim}`);
        return {};
    }
    newInterim.fromDate = new Date(newInterim.fromDate ?? 0);
    newInterim.toDate = new Date(newInterim.toDate ?? 0);
    newInterim.monthlyInterimItems = newInterim.monthlyInterimItems ?? [];
    let i = 0;
    while (i < newInterim.monthlyInterimItems.length) {
        const interimExpense = newInterim.monthlyInterimItems[i];
        receiveInterimExpense(newInterim, interimExpense);
        i++;
    }
    newInterim.monthlyInterimItems = newInterim.monthlyInterimItems.sort(sortInterimExpenseByDate);
    return newInterim;
}

export const receiveInterimExpense = (interim: Interim, interimExpense: InterimExpense | undefined) => {
    if (!interimExpense) {
        return;
    }
    interimExpense.date = new Date(interimExpense.date ?? 0);
    interimExpense.parentDocumentId = interim.parentDocumentId;
    interimExpense.parentDocumentType = interim.parentDocumentType;
}

export const INTERIM_EXPENSE_QUERY_ELEMENT: string = `
    date
    accumulatedAccruedPosted
    receivedRegistered
    producedNotBilled
    accumulatedProduced
    constructionCosts
    administrationCosts
    surcharge
    accumulatedConstructionCosts
    accumulatedAdministrationCosts
    accumulatedSurcharge
    forecast
    producedPercentageOfForecast`