import { Grid, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Risk, StateDocument, StateDocumentEvents, DocumentType, RoleType } from "../../contracts/contracts";
import SelectRisk from "../../component/riskComponents/SelectRisk";
import { useRiskContext } from "../../contexts/risk/riskContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import ViewHeading from "../../component/viewComponents/ViewHeading";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import RiskDetailCard from "../../component/riskComponents/RiskDetailCard";
import RiskDialog from "../../component/riskComponents/RiskDialog";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit'
import RiskCommentList from "../../component/riskComponents/RiskCommentList";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useStateDocumentEventsQueriesContext } from "../../contexts/StateDocumentEvents/queries/stateDocumentEventsQueriesContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

type props = {
    selectedRisk: Risk | undefined;
    onSelectedRiskChange: (newSelectedRisk: Risk | undefined) => void;
}

const RiskDetailsView: React.FC<props> = ({
    selectedRisk,
    onSelectedRiskChange}) => {
        
    const urlContext = useUrlContext();
    const riskContext = useRiskContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const userRoleContext = useUserRoleContext();

    const users = userRoleContext.getUsers();

    const riskSearch = riskContext.getRiskSearch();
    const stateDocumentEventsContext = useStateDocumentEventsQueriesContext();

    const [showDialog, setShowDialog] = React.useState<boolean>(false);

    const [riskChanges, setRiskChanges] = React.useState<Array<Risk>>([]);

    function getUserNameFromId(userId: string): string | undefined {
        let resolvedUsername: string | undefined = undefined;
        users.forEach(user => {
            if (user.id === userId) {
                resolvedUsername = user.name ?? '';
            }
        });
        return resolvedUsername
    }

    useEffect(() => {
        const documentChanges: StateDocumentEvents[] = stateDocumentEventsContext.fetchedStateDocumentEvents
        if(documentChanges.length > 0 && documentChanges[0].events) {
            const newRiskChanges: Risk[] = [];
            documentChanges[0].events.forEach((stateDocument: StateDocument) => {
                if(stateDocument.content) {
                    const contentFromString: Risk = JSON.parse(stateDocument.content)
                    if (contentFromString.documentType === DocumentType.RISK) {
                        const riskChange: Risk = contentFromString
                        riskChange.changedByUsername = getUserNameFromId(stateDocument.changedBy ?? '');
                        if (riskChange.changedByUsername !== undefined) {
                            newRiskChanges.push(riskChange);
                        }
                    }
                }
            });
            setRiskChanges(newRiskChanges);
        }
    }, [stateDocumentEventsContext.fetchedStateDocumentEvents, users]);

    const openRiskDialog = (riskToEdit: Risk) => {
        setShowDialog(true);
    };

    const onCloseRiskDialog = () => {
        setShowDialog(false);
    };

    useEffect(() => {
        riskContext.searchRisks(riskSearch);
    }, [urlContext.currentLocation])
    
    return (<>
    <RiskDialog 
            risk={selectedRisk} 
            open={showDialog} 
            onClose={onCloseRiskDialog} />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ViewHeading title={languageContext.getMessage('RISK')}>
                    <Grid container spacing={1}>
                    {projectContext.hasProjectAccess(RoleType.WRITER) && <Grid item xs={12} sm={6} md={'auto'}>
                        {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { if (selectedRisk) {openRiskDialog(
                                selectedRisk)}}}
                            startIcon={<Add /> }
                            >Rediger</Button> */}
                            <IconButton
                            onClick={() => { if (selectedRisk) {openRiskDialog(
                                selectedRisk)}}}>
                            <EditIcon />
                            </IconButton>
                        </Grid>}
                        <Grid item xs={12} sm={6} md={3}>
                            <SelectRisk
                                selectedRisk={selectedRisk}
                                onSelectedRiskChange={onSelectedRiskChange} />
                        </Grid>
                    </Grid>
                </ViewHeading>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                        {selectedRisk &&
                            <RiskDetailCard
                            selectedRisk={selectedRisk} />
                        }
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        {selectedRisk &&
                            <RiskCommentList changes={riskChanges}></RiskCommentList>
                        }
                    </Grid>
                </Grid>
            </Grid>
         </Grid>
    </>
    );
}

export default withTheme(RiskDetailsView);