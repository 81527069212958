import { FormControl, InputLabel, MenuItem, Select, TextField, withTheme } from "@material-ui/core";
import React from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { RoleType } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { splitExternalId } from "../../utils/externalIdTools";

type props = {
    id?: string,
    externalProjectId?: string,
    onChange?: (nextExternalProjectId: string) => void,
    dense?: boolean
    readonly?: boolean,
    fullWidth?: boolean
};

const InvoiceExternalProjectIdSelect: React.FC<props> = ({
    id,
    externalProjectId,
    onChange,
    dense,
    readonly,
    fullWidth
}) => {
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();

    const externalProjectIds = splitExternalId(projectContext.getSelectedProject()?.externalId);
    
    const handleChange = (event: React.ChangeEvent<{
        value: unknown;
    }>) => {
        const externalProjectId = event.target.value as Guid;
        if (onChange) {
            onChange(externalProjectId);
        }
    };

    const selectId = id || "";

    const renderSelect = () => {
        return (<Select
            labelId="invoice-externalProjectId-select-label"
            id={selectId}
            value={externalProjectId}
            onChange={handleChange}
            disabled={!projectContext.hasProjectAccess(RoleType.WRITER)}
            displayEmpty
            fullWidth={fullWidth}
            readOnly={readonly}
        >
            <MenuItem value={""}>
                <em>{languageContext.getMessage('notSelected')}</em>
            </MenuItem>
            {externalProjectIds.map(externalProjectIdOption => <MenuItem key={externalProjectIdOption} value={externalProjectIdOption}>{externalProjectIdOption}</MenuItem>)}
        </Select>
        );
    };

    const renderText = () => {
        return (<TextField
            label={languageContext.getMessage('projectCode')}
            value={externalProjectId}
            fullWidth={fullWidth}
        />);
    };

    return (<>
        {(!readonly && !dense) && <FormControl fullWidth>
            <InputLabel shrink id="invoice-externalProjectId-select-label">{languageContext.getMessage('projectCode')}</InputLabel>
            {renderSelect()}
        </FormControl>}
        {(readonly && !dense) && renderText()}
        {(!readonly && dense) && renderSelect()}
        {(readonly && dense) && externalProjectId}
    </>);
}

export default withTheme(InvoiceExternalProjectIdSelect);