import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useProjectContext } from '../../contexts/project/projectContext';
import { Project } from '../../contracts/contracts';
import ProjectDashboardSteps from './ProjectDashboardSteps';
import ProjectDashboardContent from "./ProjectDashboardContent";
import { useForecastsContext } from '../../contexts/forecast/forecastsContext';
import { useUrlContext } from '../../contexts/url/urlContext';
import ForecastsGraph from '../forecastComponents/ForecastsGraph';
import { useAuthContext } from '../../contexts/auth/authContext';
import { useBrowserContext } from '../../contexts/browserContext/browserContext';
import ProjectDashboardSelectTenant from './ProjectDashboardSelectTenant';

type props = {
  project?: Project | undefined;
}

const ProjectDashboard: React.FC<props> = ({project}) => {
  
  const browserContext = useBrowserContext();
  const authContext = useAuthContext();
  const urlContext = useUrlContext();
  const projectContext = useProjectContext();
  const forecastsContext = useForecastsContext();
  project = project ?? projectContext.getSelectedProject();

  const forecastSearch = forecastsContext.getForecastsSearch();
  forecastSearch.parentDocumentId = project?.id;

  useEffect(() => {
      forecastsContext.searchForecasts(forecastSearch);
  }, [urlContext.currentLocation, projectContext.getSelectedProject()])

  const forecasts = forecastsContext.getForecasts(project?.id) ?? {};

  return (<>
    {!authContext.authenticated &&
      <ProjectDashboardSelectTenant />
    }
    {authContext.authenticated && browserContext.isTenantNameAvailable() &&
    <>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} lg={12}>
          <ProjectDashboardSteps project={project} />
        </Grid>
        <Grid item lg={12}>
          <ProjectDashboardContent project={project} />
        </Grid>
        <Grid item xs={12}>
          <ForecastsGraph 
            forecasts={forecasts}
            isProjectForecasts={true}
            showMonthUnits={true} />
        </Grid>
      </Grid>
    </>
    }
  </>);
}

export default ProjectDashboard