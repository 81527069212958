import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Invoice, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";
import { getEmptyGuid } from "../../../utils/guidTools";

export const MUTATE_INVOICE: DocumentNode = gql`
    mutation mutateInvoice(
        $id: UUID
        $state: ActiveState
        $projectId: UUID
        $accountId: UUID
        $contractId: UUID
        $changeOrderId: UUID
        $externalProvider: String
        $externalId: String
        $externalReferenceId: String
        $externalIdMissingWithProvider: Boolean
        $externalProjectId: String
        $externalAccountId: String
        $paid: Boolean
        $postedStatus: InvoicePostedStatus
        $approvedStatus: InvoiceApprovedStatus
        $invoiceNumber: String
        $accountNumber: String
        $scannedDate: Date
        $voucherDate: Date
        $dueDate: Date
        $amount: Float
        $withheldAmount: Float
        $vatCode: String
        $comments: [String]
        $supplier: String
        $invoiceIsSplit: Boolean
        $unknownVat: Boolean
        $unknownCurrency: Boolean
        $parentId: UUID
    ) {
        mutateInvoice(
            id: $id
            state: $state
            projectId: $projectId
            accountId: $accountId
            contractId: $contractId
            changeOrderId: $changeOrderId
            externalProvider: $externalProvider
            externalId: $externalId
            externalReferenceId: $externalReferenceId
            externalIdMissingWithProvider: $externalIdMissingWithProvider
            externalProjectId: $externalProjectId
            externalAccountId: $externalAccountId
            paid: $paid
            postedStatus: $postedStatus
            approvedStatus: $approvedStatus
            invoiceNumber: $invoiceNumber
            accountNumber: $accountNumber
            scannedDate: $scannedDate
            voucherDate: $voucherDate
            dueDate: $dueDate
            amount: $amount
            withheldAmount: $withheldAmount
            vatCode: $vatCode
            comments: $comments
            supplier: $supplier
            invoiceIsSplit: $invoiceIsSplit
            unknownVat: $unknownVat
            unknownCurrency: $unknownCurrency
            parentId: $parentId
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getInvoicesGraphqlMutationOptions = (invoice: Partial<Invoice>): QueryHookOptions => {
    invoice.state = invoice.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (invoice.id !== undefined) options.variables['id'] = invoice.id;
    if (invoice.state !== undefined) options.variables['state'] = invoice.state;
    if (invoice.projectId !== undefined) options.variables['projectId'] = invoice.projectId;
    if (invoice.contractId !== undefined) options.variables['contractId'] = invoice.contractId;
    if (invoice.contractId === "") options.variables['contractId'] = getEmptyGuid(); //used to clear property
    if (invoice.changeOrderId !== undefined) options.variables['changeOrderId'] = invoice.changeOrderId;
    if (invoice.changeOrderId === "") options.variables['changeOrderId'] = getEmptyGuid(); //used to clear property
    if (invoice.accountId !== undefined) options.variables['accountId'] = invoice.accountId;
    if (invoice.accountId === "") options.variables['accountId'] = getEmptyGuid(); //used to clear property
    if (invoice.invoiceNumber !== undefined) options.variables['invoiceNumber'] = invoice.invoiceNumber;
    if (invoice.accountNumber !== undefined) options.variables['accountNumber'] = invoice.accountNumber;
    if (invoice.scannedDate !== undefined) options.variables['scannedDate'] = toISODate(invoice.scannedDate);
    if (invoice.voucherDate !== undefined) options.variables['voucherDate'] = toISODate(invoice.voucherDate);
    if (invoice.dueDate !== undefined) options.variables['dueDate'] = toISODate(invoice.dueDate);
    if (invoice.amount !== undefined) options.variables['amount'] = invoice.amount;
    if (invoice.withheldAmount !== undefined) options.variables['withheldAmount'] = invoice.withheldAmount;
    if (invoice.vatCode !== undefined) options.variables['vatCode'] = invoice.vatCode;
    if (invoice.comments !== undefined) options.variables['comments'] = invoice.comments;
    if (invoice.supplier !== undefined) options.variables['supplier'] = invoice.supplier;
    if (invoice.invoiceIsSplit !== undefined) options.variables['invoiceIsSplit'] = invoice.invoiceIsSplit;
    if (invoice.unknownVat !== undefined) options.variables['unknownVat'] = invoice.unknownVat;
    if (invoice.unknownCurrency !== undefined) options.variables['unknownCurrency'] = invoice.unknownCurrency;
    if (invoice.parentId !== undefined) options.variables['parentId'] = invoice.parentId;
    if (invoice.parentId === "") options.variables['parentId'] = getEmptyGuid(); //used to clear property
    if (invoice.paid !== undefined) options.variables['paid'] = invoice.paid;
    if (invoice.postedStatus !== undefined) options.variables['postedStatus'] = invoice.postedStatus;
    if (invoice.approvedStatus !== undefined) options.variables['approvedStatus'] = invoice.approvedStatus;
    if (invoice.externalProvider !== undefined) options.variables['externalProvider'] = invoice.externalProvider;
    if (invoice.externalId !== undefined) options.variables['externalId'] = invoice.externalId;
    if (invoice.externalReferenceId !== undefined) options.variables['externalReferenceId'] = invoice.externalReferenceId;
    if (invoice.externalIdMissingWithProvider !== undefined) options.variables['externalIdMissingWithProvider'] = invoice.externalIdMissingWithProvider;
    if (invoice.externalProjectId !== undefined) options.variables['externalProjectId'] = invoice.externalProjectId;
    if (invoice.externalAccountId !== undefined) options.variables['externalAccountId'] = invoice.externalAccountId;

    return options;
}