import React, { useEffect, useState } from 'react';
import { createStyles, Theme, makeStyles, withTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { Card, Button, Grid, IconButton } from '@material-ui/core';
import { ChangeOrderCounterClaim, ChangeOrderStatus, ElementStatusEnum, Invoice, RoleType, StorageFile, DocumentType } from '../../contracts/contracts';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import { useChangeOrderCounterClaimContext } from '../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext';
import EditChangeOrderCounterClaimDialog from './EditChangeOrderCounterClaimDialog';
import { getRenderedElementStatus } from '../../utils/statusToPalettes';
import { useThemeContext } from '../../contexts/theme/ThemeContext';
import InvoiceGraph from "../invoiceComponents/InvoiceGraph";
import { useContractContext } from '../../contexts/contract/contractContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_STORAGE_FILES, getStorageFilesGraphqlQueryOptions } from '../../contexts/storageFile/queries/storageFileQueries';
import { getStorageFileKey, onUploadFile } from '../../utils/fileTools';
import { useStorageFileMutationsContext } from '../../contexts/storageFile/mutations/storageFileMutationsContext';
import { InvoiceTabs } from '../../contexts/invoice/invoiceContext';
import FileUploadDialog from '../generalComponents/FileUploadDialog';
import StorageFileDialog from '../generalComponents/StorageFileDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootChange: {
      width: '100%',
    },
    card: {
      padding: '1em',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'initial',
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.dark,
    },
    section1: {
      margin: theme.spacing(3, 2),
    },
    section2: {
      margin: theme.spacing(2),
    },
    section3: {
      margin: theme.spacing(3, 1, 1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    contentInfo: {
      background: 'linear-gradient(147deg, #0086ad 0%, #1276bd 74%)', //'linear-gradient(147deg, #31d655 0%, #31d655 74%)'
      maxWidth: '80px',
      maxHeight: '80px',
      borderRadius: '5px',
      boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
      color: '#ffffff',
    },
    contentSucc: {
      background: 'linear-gradient(147deg, #31d655 0%, #30a645 74%)', //'linear-gradient(147deg, #31d655 0%, #31d655 74%)'
      maxWidth: '80px',
      maxHeight: '80px',
      borderRadius: '5px',
      boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
      color: '#ffffff',
    },
    contentCancel: {
      background: 'linear-gradient(147deg, #ff4e50 0%, #fe4e62 74%)', //'linear-gradient(147deg, #31d655 0%, #31d655 74%)'
      maxWidth: '80px',
      maxHeight: '80px',
      borderRadius: '5px',
      boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
      color: '#ffffff',
    },
    icon: {
      alignItems: 'center',
      margin: 'auto',
      color: '#ffffff',
    },
  }),
);

function getClassStatus(status: ElementStatusEnum, theme: any): string {
  switch (status) {
    case ElementStatusEnum.INFORMATION: return theme.contentInfo;
    case ElementStatusEnum.CANCEL: return theme.contentCancel;
    case ElementStatusEnum.SUCCESS: return theme.contentSucc;
  }

  return theme.contentSucc;
}

type props = {
  invoices: Invoice[],
  selectedChangeOrderCounterClaim: ChangeOrderCounterClaim;
}

const ChangeOrderCounterClaimDetailCard: React.FC<props> = ({
  invoices,
  selectedChangeOrderCounterClaim }) => {

  const classes = useStyles();
  const themeContext = useThemeContext();
  const languageContext = useLanguageContext();
  const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();
  const contractContext = useContractContext();
  const projectContext = useProjectContext();
  const storageMutationContext = useStorageFileMutationsContext();

  const theme = themeContext.getTheme();

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [changeOrderCounterClaimToEdit, setChangeOrderCounterClaimToEdit] = useState<ChangeOrderCounterClaim>({});

  const [currentChangeOrderId, setCurrentChangeOrderId] = useState<string>("");
  const [changeOrderFiles, setChangeOrderFiles] = useState<StorageFile[]>([]);
  const [changeOrderImages, setChangeOrderImages] = useState<string[]>([]);
  const [changeOrderImageFilenames, setChangeOrderImageFilenames] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [openFileList, setOpenFileList] = useState<boolean>(false);

  const ChangeOrderImagesStorageFileKey = getStorageFileKey(DocumentType.CHANGE_ORDER_SUB_CONTRACTOR, currentChangeOrderId ?? "");

  function loadChangeOrderFiles(changeOrderId: string) {
    var storageFileKey = getStorageFileKey(DocumentType.CHANGE_ORDER_SUB_CONTRACTOR, changeOrderId);
    loadChangeOrderImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: storageFileKey }));
  }

  const refreshChangeOrderFiles = () => {
    if (!currentChangeOrderId) {
      return;
    }
    loadChangeOrderImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: ChangeOrderImagesStorageFileKey }));
  };

  const [loadChangeOrderImagesQuery, queryChangeOrderImagesResponse] = useLazyQuery(QUERY_STORAGE_FILES);

  const onDeletedChangeOrderFile = (deletedFile: StorageFile): void => {
    setChangeOrderFiles(changeOrderFiles.filter((file) => file.id !== deletedFile.id));
  };

  useEffect(() => {
    var storageFiles: StorageFile[] = queryChangeOrderImagesResponse.data?.storageFiles ?? [];
    setChangeOrderFiles(storageFiles)
  }, [queryChangeOrderImagesResponse.data]);

  const openEditChangeOrderCounterClaimDialog = () => {
    setChangeOrderCounterClaimToEdit({ ...selectedChangeOrderCounterClaim });
    setShowDialog(true);
  };

  const onCloseChangeOrderCounterClaimDialog = () => {
    setShowDialog(false);
  };

  return (<>
    {openFileList && <StorageFileDialog open={openFileList} onClose={() => setOpenFileList(false)} files={changeOrderFiles} onDeletedFile={onDeletedChangeOrderFile} />}
    {isDialogOpen && (
      <FileUploadDialog
        title={languageContext.getMessage("uploadFiles")}
        description={""}
        dropAreaText={languageContext.getMessage("dragAndDropFiles")}
        imagesStorageFileKey={getStorageFileKey(DocumentType.CHANGE_ORDER_SUB_CONTRACTOR, currentChangeOrderId)}
        onClose={() => setIsDialogOpen(false)}
        groups={[
          {
            key: languageContext.getMessage("files"),
            name: languageContext.getMessage("file"),
            pluralName: languageContext.getMessage("files"),
            extensions: [".png", ".jpg", ".jpeg", ".md", ".txt", ".pdf", ".xls", ".xlxs", ".csv"],
          },
        ]}
        defaultGroup={{
          key: "default",
          name: "document",
          pluralName: "documents",
          extensions: [".jpeg", ".png", ".jpeg", ".pdf"],
        }}
        onUploadFile={onUploadFile}
        setImages={setChangeOrderImages}
        setImageFilenames={setChangeOrderImageFilenames}
        existingFileNames={changeOrderImageFilenames}
        onUploadCompleted={() => refreshChangeOrderFiles()}
      />
    )}
    <EditChangeOrderCounterClaimDialog
      changeOrderCounterClaim={changeOrderCounterClaimToEdit}
      open={showDialog}
      onClose={onCloseChangeOrderCounterClaimDialog} />
    <Card className={classes.card}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className={getClassStatus(selectedChangeOrderCounterClaim.elementStatus ?? ElementStatusEnum.ALERT, classes)}>
            <div className={classes.icon}>
              {getRenderedElementStatus(selectedChangeOrderCounterClaim.elementStatus ?? ElementStatusEnum.ALERT, theme, true, { fontSize: 80, color: '#ffffff' })}
            </div>
          </div>
          <div className={classes.rootChange}>
            <div className={classes.section1}>
              <Typography gutterBottom variant="h5">
                {languageContext.getMessage('counterClaim')} {selectedChangeOrderCounterClaim.name}
              </Typography>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('columnStatus')}:</b> {changeOrderCounterClaimContext.convertChangeOrderStatusToString(selectedChangeOrderCounterClaim.status ?? ChangeOrderStatus.NOT_PROCESSED)}
              </Typography>
            </div>
            <div className={classes.section2}>
              <Typography color="textSecondary" variant="body1">
                {selectedChangeOrderCounterClaim?.changeOrderDescription ?? ""}
              </Typography>
            </div>
            <Divider variant="middle" color="textSecondary" />
            <div className={classes.section1}>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('costRequirements')}:</b> <CurrencyFormat amount={selectedChangeOrderCounterClaim.costRequirements} />
              </Typography>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('cost')}:</b> <CurrencyFormat amount={selectedChangeOrderCounterClaim.cost} />
              </Typography>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('contract')}:</b> {contractContext.getContract(selectedChangeOrderCounterClaim.contractId)?.name}
              </Typography>
              <Typography variant="body1" component="p">
                <b>{languageContext.getMessage('counterClaim')}:</b> {selectedChangeOrderCounterClaim.contractor}
              </Typography>
            </div>
            <Divider variant="middle" color="textSecondary" />
            <div className={classes.section1}>
              <Typography color="textSecondary" variant="body1">
                {selectedChangeOrderCounterClaim?.changeOrderComment ?? ""}
              </Typography>
            </div>
            {projectContext.hasProjectAccess(RoleType.WRITER) && <div className={classes.section2}>
              <Button color="primary" variant="contained" onClick={openEditChangeOrderCounterClaimDialog} >
                <EditIcon /> {languageContext.getMessage('edit')}
              </Button>
              <IconButton
                color="primary"
                disabled={!projectContext.checkIfProjectFileExists(selectedChangeOrderCounterClaim.id)}
                onClick={() => {
                  if (selectedChangeOrderCounterClaim.id) {
                    loadChangeOrderFiles(selectedChangeOrderCounterClaim.id);
                    setOpenFileList(true)
                  }

                }}
              >
                <GetAppIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  setIsDialogOpen(true);
                  setCurrentChangeOrderId(selectedChangeOrderCounterClaim.id ?? "")
                }}
              >
                <PublishIcon />
              </IconButton>
            </div>}
          </div>
        </Grid>
        <Grid item xs={6}>
          <InvoiceGraph invoices={invoices} onShowInvoicesFilter={{
            selectedAccountIdFilter: selectedChangeOrderCounterClaim.accountId,
            selectedContractIdFilter: selectedChangeOrderCounterClaim.contractId,
            tab: InvoiceTabs.processed
          }} height={300}></InvoiceGraph>
        </Grid>
      </Grid>
    </Card>
  </>);
}

export default withTheme(ChangeOrderCounterClaimDetailCard);