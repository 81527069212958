import { Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import ChangeOrderSubContractorViewAll from "./ChangeOrderSubContractorViewAll";
import ChangeOrderSubContractorDetailsView from "./ChangeOrderSubContractorViewDetails";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { DocumentType, Account, Contract, ChangeOrderSubContractor, ChangeOrderStatus } from "../../contracts/contracts";
import { useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import ChangeOrderSubContractorOverview from "./ChangeOrderSubContractorOverview";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

type props = {};

const ChangeOrderSubContractorView: React.FC<props> = ({}) => {
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
  const accountContext = useAccountContext();
  const invoiceContext = useInvoiceContext();
  const urlContext = useUrlContext();
  const contractContext = useContractContext();
  const projectContext = useProjectContext();
  const ticketContext = useTicketContext();
  const userRoleContext = useUserRoleContext();

  const urlState = urlContext.getUrlState();
  const tabIndex = urlState.tab ? (urlState.tab as string) : "all";
  const selectedAccountId = urlState.accountId ? (urlState.accountId as string) : undefined;
  const selectedContractId = urlState.contractId ? (urlState.contractId as string) : undefined;
  const selectedStatus = urlState.status ? (urlState.status as ChangeOrderStatus) : undefined;
  const selectedChangeOrderSubContractorId = urlState.changeOrderSubContractorId ? (urlState.changeOrderSubContractorId as string) : undefined;
  const selectedChangeOrderSubContractor = selectedChangeOrderSubContractorId
    ? changeOrderSubContractorContext.getChangeOrderSubContractor(selectedChangeOrderSubContractorId) ?? undefined
    : undefined;

  const changeOrderSubContractorSearch = changeOrderSubContractorContext.getChangeOrderSubContractorSearch();
  const accountSearch = accountContext.getAccountSearch();
  const invoiceSearch = invoiceContext.getInvoiceSearch();
  const contractSearch = contractContext.getContractSearch();

  invoiceSearch.accountId = undefined;
  contractSearch.accountId = undefined;

  useEffect(() => {
    if (userRoleContext.getUsers().length === 0) {
      userRoleContext.queryUsers();
    }
    ticketContext.setDocumentTypesToWatch([DocumentType.ACCOUNT, DocumentType.CHANGE_ORDER_SUB_CONTRACTOR]);
    changeOrderSubContractorContext.searchChangeOrderSubContractors(changeOrderSubContractorSearch);
    accountContext.searchAccounts(accountSearch);
    invoiceContext.searchInvoices(invoiceSearch);
    contractContext.searchContracts(contractSearch);
  }, [urlContext.currentLocation, projectContext.getSelectedProject()]);

  const selectedAccount = accountContext.getAccount(selectedAccountId);
  const selectedContract = contractContext.getContract(selectedContractId);

  const updateUrlState = (
    newSelectedAccountId: string | undefined,
    newSelectedContractId: string | undefined,
    newSelectedStatus: ChangeOrderStatus | undefined,
    newSelectedChangeOrderSubContractor: ChangeOrderSubContractor | undefined,
    newTabIndex: string
  ): void => {
    const newUrlState = {
      ...changeOrderSubContractorSearch,
      ...{ accountId: newSelectedAccountId },
      ...{ contractId: newSelectedContractId },
      ...{ status: newSelectedStatus },
      ...{ changeOrderSubContractorId: newSelectedChangeOrderSubContractor?.id },
      ...{ tab: newTabIndex },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined | any>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const handleTabIndexChange = (event: React.ChangeEvent<{}> | undefined, newTabIndex: string) => {
    updateUrlState(selectedAccountId, selectedContractId, selectedStatus, selectedChangeOrderSubContractor, newTabIndex);
  };

  const onSelectedAccountChange = (newSelectedAccount: Account | undefined): void => {
    updateUrlState(newSelectedAccount?.id, selectedContractId, selectedStatus, selectedChangeOrderSubContractor, tabIndex);
  };

  const onSelectedContractChange = (newSelectedContract: Contract | undefined): void => {
    updateUrlState(selectedAccountId, newSelectedContract?.id, selectedStatus, selectedChangeOrderSubContractor, tabIndex);
  };

  const onSelectedStatusChange = (newSelectedStatus: ChangeOrderStatus | undefined): void => {
    updateUrlState(selectedAccountId, selectedContractId, newSelectedStatus, selectedChangeOrderSubContractor, tabIndex);
  };

  const onSelectedChangeOrderSubContractorChange = (newSelectedChangeOrderSubContractor: ChangeOrderSubContractor | undefined): void => {
    updateUrlState(selectedAccountId, selectedContractId, selectedStatus, newSelectedChangeOrderSubContractor, "details");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
          <Tabs value={tabIndex} onChange={handleTabIndexChange} aria-label="simple tabs example">
            <Tab label={languageContext.getMessage("all")} value="all" />
            {<Tab label={languageContext.getMessage("details")} value="details" />}
            {<Tab label={languageContext.getMessage("overview")} value="overview" />}
          </Tabs>
        </Toolbar>
      </Grid>

      {tabIndex === "all" && (
        <Grid item xs={12}>
          <ChangeOrderSubContractorViewAll
            selectedAccount={selectedAccount}
            selectedContract={selectedContract}
            selectedStatus={selectedStatus}
            onSelectedAccountChange={onSelectedAccountChange}
            onSelectedContractChange={onSelectedContractChange}
            onSelectedStatusChange={onSelectedStatusChange}
            onSelectedChangeOrderSubContractorChange={onSelectedChangeOrderSubContractorChange}
          />
        </Grid>
      )}

      {tabIndex === "details" && (
        <Grid item xs={12}>
          <ChangeOrderSubContractorDetailsView
            selectedChangeOrderSubContractor={selectedChangeOrderSubContractor}
            onSelectedChangeOrderSubContractorChange={onSelectedChangeOrderSubContractorChange}
          />
        </Grid>
      )}

      {tabIndex === "overview" && (
        <Grid item xs={12}>
          <ChangeOrderSubContractorOverview
            selectedAccount={selectedAccount}
            selectedContract={selectedContract}
            onSelectedAccountChange={onSelectedAccountChange}
            onSelectedContractChange={onSelectedContractChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withTheme(ChangeOrderSubContractorView);
