import { Tooltip, Typography } from "@material-ui/core";

import * as React from "react";

const ContentTooltip: React.FC<{}> = ({ children }) => {
  function GenerateList() {
    let comment: string = children as string;
    return (
        <Typography>{children}</Typography>
    );
  }
  const [textElement, setTextElement] = React.useState(React.createRef<any>());
  let placeHolder: string = children as string;

  return (
    <div>
      <Tooltip
        style={{
          backgroundColor: "#E5E6E7",
          color: "rgba(0, 0, 0, 0.87)",
          maxWidth: 350,
          padding: '0.2em'
        }}
        title={<GenerateList />}
      >
        <div
          ref={textElement}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {placeHolder && placeHolder.length > 0 && placeHolder ? (placeHolder.slice(0, 30) + (placeHolder.length > 30 ? "..." : "")) : ''}
        </div>
      </Tooltip>
    </div>
  );
};

export default ContentTooltip;
