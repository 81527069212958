import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useInvoiceContext } from '../../contexts/invoice/invoiceContext';
import { Invoice } from '../../contracts/contracts';
import { CircularProgress } from '@material-ui/core';

type props = {
    selectedInvoiceId?: string | null;
    onChange: (invoiceId?: string) => void;
}

const SelectInvoice: React.FC<props> = ({
    selectedInvoiceId,
    onChange
}) => {

    const languageContext = useLanguageContext();
    const invoiceContext = useInvoiceContext();

    const defaultInvoice = !selectedInvoiceId ? null : invoiceContext.getInvoice(selectedInvoiceId);

    const handleChange = (event: any, invoice: Invoice | null) => {
        onChange(invoice?.id || "");
    }

    return <Autocomplete style={{width:240}}
        options={invoiceContext.invoices}
        getOptionLabel={invoice => invoice.invoiceNumber ?? ''}
        value={defaultInvoice || {}}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params}
            label={<>
                {languageContext.getMessage('invoiceNumber')}
                {invoiceContext.loadingInvoices &&
                    <CircularProgress size={12} style={{marginLeft: 5}} />
                }
            </>} />
        }
    />
}

export default SelectInvoice;