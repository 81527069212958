import { Risk } from "../../contracts/contracts";
import { getRenderedRiskStatus } from "../../utils/statusToPalettes";
import { capitalizeString } from "../../utils/stringTools";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

const RISK_LIST_TEMPLATE_FILENAME = 'aimz/template_risk_list.xlsx'
const RISK_LIST_TEMPLATE_SHEET_NAME = 'risk_list'

const sortRiskByName = (a: Risk, b: Risk) => {
    if ((a.name ?? '') < (b.name ?? '')) { return -1; }
    if ((a.name ?? '') > (b.name ?? '')) { return 1; }
    return 0;
}

export const queryTemplateEngineToProduceRiskExcelList = (
    risks: Risk[], 
    templateEngineQueriesContext: TemplateEngineQueriesContext, 
    languageContext: LanguageContext): void => {
    const templateFilename: string = RISK_LIST_TEMPLATE_FILENAME;
    const templateVariables: object[] = getTemplateEngineRiskListReportVariables(risks, languageContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, `${languageContext.getMessage('RISK')}`);
}

const getTemplateEngineRiskListReportVariables = (
    risks: Risk[], 
    languageContext: LanguageContext): object[] => {
    const date: Date = new Date();
    const title: string = `${languageContext.getMessage('RISK')}`;
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return [{
        'tpl_name': RISK_LIST_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': getRiskListVariables(title, monthString, risks),
    }]
}

const getRiskListVariables = (
    title: string, 
    monthString: string, 
    risks: Risk[]): object => {
    const listVariables = {
        'language': {},
        'title': title,
        'date': monthString,
        'risks': risks.sort(sortRiskByName),
    }
    return listVariables;
} 
