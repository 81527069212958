import React, { createContext, useContext, useEffect, useState } from "react";
import { QueryHookOptions, useLazyQuery } from "@apollo/react-hooks";
import { useAuthContext } from "../auth/authContext";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { ChangeLog } from "../../contracts/contracts";

export const QUERY_CHANGE_LOG: DocumentNode = gql`
  query changeLog($searchIndexStart: Int, $searchIndexStop: Int, $projectId: [String]) {
    changeLog(searchIndexStart: $searchIndexStart, searchIndexStop: $searchIndexStop, projectId: $projectId) {
      count
      documents {
        id
        documentType
        documentId
        changeType
        changedBy
        timeStamp
        content
      }
    }
  }
`;

export interface IChangeLogContext {
  searchChangeLog: (projectId: string, startIndex?: number, stopIndex?: number) => void;
  changeLog: ChangeLog[];
  changeLogCount: number;
}

const ChangeLogContext = createContext<IChangeLogContext>(null as unknown as IChangeLogContext);

export const ChangeLogContextProvider: React.FC<{}> = ({ children }) => {
  const authContext = useAuthContext();

  const [changeLog, setChangeLog] = useState<ChangeLog[]>([]);
  const [changeLogCount, setChangeLogCount] = useState<number>(0);

  const [queryChangeLog, queryChangeLogResponse] = useLazyQuery(QUERY_CHANGE_LOG);

  function searchChangeLog(projectId: string, startIndex?: number, stopIndex?: number) {
    const options: QueryHookOptions = {};
    options.variables = {};
    options.variables.projectId = [projectId];
    if (startIndex !== null || startIndex !== undefined) options.variables.searchIndexStart = startIndex;
    if (stopIndex !== null || stopIndex !== undefined) options.variables.searchIndexStop = stopIndex;
    queryChangeLog(options);
  }

  useEffect(() => {
    if (queryChangeLogResponse.data && queryChangeLogResponse.data.changeLog) {
      if (queryChangeLogResponse.data.changeLog.documents) {
        const returnedDocuments: Array<ChangeLog> = queryChangeLogResponse.data.changeLog.documents;
        returnedDocuments.forEach((document) => {
          if (document.timeStamp) document.timeStamp = new Date(document.timeStamp ?? 0);
        });
        setChangeLog([...returnedDocuments]);
      }
      if (queryChangeLogResponse.data.changeLog.count) {
        const newCount: number = queryChangeLogResponse.data.changeLog.count;
        setChangeLogCount(newCount);
      }
    }
  }, [queryChangeLogResponse.data]);

  useEffect(() => {
    if (authContext.authenticated || authContext.insecure) {
      setChangeLog([]);
    }
  }, [authContext.authenticated, authContext.insecure]);

  const changeLogContext: IChangeLogContext = {
    searchChangeLog,
    changeLog,
    changeLogCount,
  };

  return <ChangeLogContext.Provider value={changeLogContext}>{children}</ChangeLogContext.Provider>;
};

export const useChangeLogContext = (): IChangeLogContext => {
  return useContext(ChangeLogContext);
};
