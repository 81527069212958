import { Invoice } from "../contracts/contracts"
import { Guid } from "./common-types"
import { getRandomGuid } from "./guidTools"

export const getEmptyChildInvoice = (parentId: Guid): Invoice => {
    return {
        parentId: parentId,
        id: getRandomGuid(),
        accountId: "",
        contractId: "",
        changeOrderId: "",
        amount: 0,
    }
}

export const mergeChildInvoiceWithParent = (parent: Invoice, child: Invoice): Invoice => {
    const filteredProperties: Partial<Invoice> = {
        id: child.id,
        state: child.state,
        parentId: child.parentId,
        invoiceIsSplit: undefined,
        accountId: child.accountId,
        contractId: child.contractId,
        changeOrderId: child.changeOrderId,
        amount: child.amount,
    }
    return {...parent, ...filteredProperties};
}