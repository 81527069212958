import { CircularProgress, makeStyles, Theme, Typography, withTheme } from "@material-ui/core";
import React from "react";
import ImageCarousel from "../generalComponents/ImageCarousel";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: theme.spacing(1)
    },
    analysing: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    section: {
        marginTop: theme.spacing(1),
    },
}));

type props = {
    loading?: boolean,
    error?: string;
    base64Images?: string[]; 
};

const ShowInvoiceImages: React.FC<props> = ({
    loading, 
    error, 
    base64Images,
}) => {
    const classes = useStyles();

    return <div className={classes.card} style={{ height: '100%', minHeight: '600px', maxHeight: '900px', maxWidth: '900px' }}>
        {loading && <>
            <div className={classes.analysing}>
              <Typography variant="body1" component="p">
                <b>Henter faktura</b>
              </Typography>
              <div className={classes.section}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  size={40}
                  thickness={4}
                />
              </div>
            </div></>}
        {error && <>{`Error! ${error}`}</>}
        {!loading && !error && base64Images && <><ImageCarousel base64Images={base64Images}></ImageCarousel></>}
    </div>
}

export default withTheme(ShowInvoiceImages);