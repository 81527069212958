import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";

export const QUERY_TEMPLATE_ENGINE: DocumentNode = gql(`
    query templateEngine(
            $templateFilename: String, 
            $templateVariables: String,
            $outputFilename: String) {
        templateEngine(
                templateFilename: $templateFilename, 
                templateVariables: $templateVariables, 
                outputFilename: $outputFilename) {
            templatedResult
            templatedFileType
            outputFilename
        }
    }
`);

export const getTemplateEngineGraphqlQueryOptions = (templateFilename: string, templateVariables: object[], outputFilename: string): QueryHookOptions => {
    const options: QueryHookOptions = {};
    options.variables = {};
    options.variables['templateFilename'] = templateFilename;
    options.variables['templateVariables'] = JSON.stringify(templateVariables);
    options.variables['outputFilename'] = outputFilename
    return options;
}
