import React, { createContext, useContext } from "react";
import { ChangeOrderLandlord, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getChangeOrderLandlordsGraphqlMutationOptions, MUTATE_CHANGE_ORDER } from "./changeOrderLandlordMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface ChangeOrderLandlordMutationsContext {
    mutateChangeOrderLandlord: (accout: ChangeOrderLandlord, onTicketSubscribed: (documentId: Guid, variables: any) => void) => void;
}

const ChangeOrderLandlordMutationsContext = createContext<ChangeOrderLandlordMutationsContext>(null as unknown as ChangeOrderLandlordMutationsContext);

export const ChangeOrderLandlordMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [changeOrderLandlordMutation] = useMutation(MUTATE_CHANGE_ORDER);

    const handleChangeOrderLandlordMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateChangeOrderLandlord.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
    }

    const mutateChangeOrderLandlord = (changeOrderLandlord: ChangeOrderLandlord, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        const options = getChangeOrderLandlordsGraphqlMutationOptions(changeOrderLandlord)
        changeOrderLandlordMutation(options).then((result) => handleChangeOrderLandlordMutationResult(result, options.variables, onTicketSubscribed));
    }

    const changeOrderLandlordMutationsContext: ChangeOrderLandlordMutationsContext = {
        mutateChangeOrderLandlord
    };

    return (
        <ChangeOrderLandlordMutationsContext.Provider value={changeOrderLandlordMutationsContext}>
            {children}
        </ChangeOrderLandlordMutationsContext.Provider>
    );
}

export const useChangeOrderLandlordMutationsContext = (): ChangeOrderLandlordMutationsContext => {
    return useContext(ChangeOrderLandlordMutationsContext);
}
