import React, { createContext, useContext, useEffect, useState } from "react";
import { useUrlContext } from "../url/urlContext";
import { ActiveState, AccrualAndOperationsExpenses } from "../../contracts/contracts";
import { receiveAccrualAndOperationsExpenses } from "./accrualAndOperationsExpensesTools";
import { AccrualAndOperationsExpensesMutationsContextProvider, useAccrualAndOperationsExpensesMutationsContext } from "./mutations/accrualAndOperationsExpensesMutationsContext";
import { AccrualAndOperationsExpensesQueriesContextProvider, useAccrualAndOperationsExpensesQueriesContext } from "./queries/accrualAndOperationsExpensesQueriesContext";
import { AccrualAndOperationsExpensesSubscriptionsContextProvider, useAccrualAndOperationsExpensesSubscriptionsContext } from "./subscriptions/accrualAndOperationsExpensesSubscriptionsContext";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { datesAreOnSameDay } from "../../utils/dateTools";
import { Guid } from "../../utils/common-types";
import { useAuthContext } from "../auth/authContext";
import { useProjectContext } from "../project/projectContext";

export interface AccrualAndOperationsExpensesContext {
    getAccrualAndOperationsExpensesSearch: () => AccrualAndOperationsExpenses,
    searchAccrualAndOperationsExpenses: (accrualAndOperationsExpensesSearch: AccrualAndOperationsExpenses | undefined, force: boolean) => void,
    getAccrualAndOperationsExpenses: (accountId: Guid | undefined) => AccrualAndOperationsExpenses[],
    updateAccrualAndOperationsExpenses: (accrualAndOperationsExpensesToUpdate: AccrualAndOperationsExpenses, updateCacheOnly?: boolean) => Promise<void>,
}

const AccrualAndOperationsExpensesContext = createContext<AccrualAndOperationsExpensesContext>(null as unknown as AccrualAndOperationsExpensesContext);

export const AccrualAndOperationsExpensesContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <AccrualAndOperationsExpensesMutationsContextProvider>
            <AccrualAndOperationsExpensesQueriesContextProvider>
                <AccrualAndOperationsExpensesSubscriptionsContextProvider>
                    <AccrualAndOperationsExpensesSubContextProvider>
                        {children}
                    </AccrualAndOperationsExpensesSubContextProvider>
                </AccrualAndOperationsExpensesSubscriptionsContextProvider>
            </AccrualAndOperationsExpensesQueriesContextProvider>
        </AccrualAndOperationsExpensesMutationsContextProvider>
    );
}

export const AccrualAndOperationsExpensesSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const projectContext = useProjectContext();
    const accrualAndOperationsExpensesMutationsContext = useAccrualAndOperationsExpensesMutationsContext();
    const accrualAndOperationsExpensesQueriesContext = useAccrualAndOperationsExpensesQueriesContext();
    const accrualAndOperationsExpensesSubscriptionsContext = useAccrualAndOperationsExpensesSubscriptionsContext();
    const [currentAccrualAndOperationsExpensesSearch, setCurrentAccrualAndOperationsExpensesSearch] = useState<AccrualAndOperationsExpenses | undefined>(undefined);
    const [accrualAndOperationsExpenses, setAccrualAndOperationsExpenses] = useState<Array<AccrualAndOperationsExpenses>>([]);

    const mergeAccrualAndOperationsExpenses = (oldAccrualAndOperationsExpenses: Array<AccrualAndOperationsExpenses>, newAccrualAndOperationsExpenses: Array<AccrualAndOperationsExpenses>): Array<AccrualAndOperationsExpenses> => {
        let updatedAccrualAndOperationsExpenses = oldAccrualAndOperationsExpenses.slice();
        if (!newAccrualAndOperationsExpenses) {
            console.error(`Received undefined set of accrualAndOperationsExpenses: ${newAccrualAndOperationsExpenses}`);
            return [];
        }
        newAccrualAndOperationsExpenses.forEach(newAccrualAndOperation => {
            if (newAccrualAndOperation.projectId !== currentAccrualAndOperationsExpensesSearch?.projectId) {
                return;
            }
            newAccrualAndOperation.created = new Date(newAccrualAndOperation.created ?? 0);
            newAccrualAndOperation = receiveAccrualAndOperationsExpenses(currentAccrualAndOperationsExpensesSearch, newAccrualAndOperation);
            const index = updatedAccrualAndOperationsExpenses.findIndex(updatedAccrualAndOperationsExpense => updatedAccrualAndOperationsExpense.id === newAccrualAndOperation.id);
            if (index >= 0) {
                if (newAccrualAndOperation.state === ActiveState.ACTIVE) {
                    updatedAccrualAndOperationsExpenses[index] = newAccrualAndOperation;
                }
                else {
                    updatedAccrualAndOperationsExpenses.splice(index, 1);
                }
            } 
            else {
                if (newAccrualAndOperation.state === ActiveState.ACTIVE) {
                    updatedAccrualAndOperationsExpenses.push(newAccrualAndOperation); 
                }
            }
        });
        return updatedAccrualAndOperationsExpenses;
    }

    const getAccrualAndOperationsExpensesSearch = (): AccrualAndOperationsExpenses => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as Guid : projectContext.getSelectedProject()?.id;
        const accountId = urlState.accountId ? urlState.accountId as Guid : undefined;
        const fromDate = urlState.fromDate ? new Date(urlState.fromDate as string) : undefined;
        const toDate = urlState.toDate ? new Date(urlState.toDate as string) : undefined;
    
        return {
            projectId,
            accountId,
            fromDate: fromDate,
            toDate: toDate,
        }
    }

    const searchAccrualAndOperationsExpenses = (accrualAndOperationsExpensesSearch: AccrualAndOperationsExpenses | undefined, force: boolean): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(accrualAndOperationsExpensesSearch, currentAccrualAndOperationsExpensesSearch);
        matched = matched && accrualAndOperationsExpensesSearch?.projectId === currentAccrualAndOperationsExpensesSearch?.projectId;
        matched = matched && accrualAndOperationsExpensesSearch?.accountId === currentAccrualAndOperationsExpensesSearch?.accountId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && (!matched || force)) {
            setCurrentAccrualAndOperationsExpensesSearch(accrualAndOperationsExpensesSearch);
            setAccrualAndOperationsExpenses([]);
        }
    }
    
    const getAccrualAndOperationsExpenses = (accountId: Guid | undefined): AccrualAndOperationsExpenses[] => {
        return accrualAndOperationsExpenses.filter(accrualAndOperationExpense => accrualAndOperationExpense.accountId === accountId);
    }

    const updateAccrualAndOperationsExpenses = (accrualAndOperationsExpensesToUpdate: AccrualAndOperationsExpenses, updateCacheOnly?: boolean): Promise<void> => {
        if (updateCacheOnly && accrualAndOperationsExpensesToUpdate.id) {
            setAccrualAndOperationsExpenses(mergeAccrualAndOperationsExpenses(accrualAndOperationsExpenses, [accrualAndOperationsExpensesToUpdate]));
            return Promise.resolve();
        }
        return accrualAndOperationsExpensesMutationsContext.mutateAccrualAndOperationsExpenses(accrualAndOperationsExpensesToUpdate, (documentId, variables) => {
            accrualAndOperationsExpensesToUpdate = {...accrualAndOperationsExpensesToUpdate, ...variables};
            accrualAndOperationsExpensesToUpdate.id = documentId;
            setAccrualAndOperationsExpenses(mergeAccrualAndOperationsExpenses(accrualAndOperationsExpenses, [accrualAndOperationsExpensesToUpdate]));
        });
    }

    useEffect(() => {
        if (currentAccrualAndOperationsExpensesSearch) {
            accrualAndOperationsExpensesQueriesContext.queryAccrualAndOperationsExpenses(currentAccrualAndOperationsExpensesSearch)
        }
    }, [currentAccrualAndOperationsExpensesSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setAccrualAndOperationsExpenses([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setAccrualAndOperationsExpenses(mergeAccrualAndOperationsExpenses([], accrualAndOperationsExpensesQueriesContext.fetchedAccrualAndOperationsExpenses));
    }, [accrualAndOperationsExpensesQueriesContext.fetchedAccrualAndOperationsExpenses]);

    useEffect(() => {
        setAccrualAndOperationsExpenses(mergeAccrualAndOperationsExpenses(accrualAndOperationsExpenses, accrualAndOperationsExpensesSubscriptionsContext.subscribedAccrualAndOperationsExpenses));
    }, [accrualAndOperationsExpensesSubscriptionsContext.subscribedAccrualAndOperationsExpenses]);

    const value = {
        getAccrualAndOperationsExpensesSearch,
        searchAccrualAndOperationsExpenses,
        getAccrualAndOperationsExpenses,
        updateAccrualAndOperationsExpenses,
    };

    return (
        <AccrualAndOperationsExpensesContext.Provider value={value}>
            {children}
        </AccrualAndOperationsExpensesContext.Provider>
    );
}

export const useAccrualAndOperationsExpensesContext = (): AccrualAndOperationsExpensesContext => {
    return useContext(AccrualAndOperationsExpensesContext);
}
