import { ChartData, ChartDataSets, ChartOptions, ChartPoint } from "chart.js";
import React from "react";
import LineGraph from "../graphComponents/LineGraph";
import { LanguageContext, useLanguageContext } from "../../contexts/language/LanguageContext";
import { CurrencyType, Forecast, Forecasts } from "../../contracts/contracts";
import DateFormatFromTo from "../dateComponents/DateFormatFromTo";
import { AccountContext, useAccountContext } from "../../contexts/account/accountContext";
import { ProjectContext, useProjectContext } from "../../contexts/project/projectContext";
import { datesAreOnSameMonth } from "../../utils/dateTools";

const accountForecastToDataSet = (accountContext: AccountContext, projectContext: ProjectContext, forecasts: Forecast[], isProjectForecasts: boolean, isGroupForecasts: boolean): ChartDataSets => {
    let label = '';
    if (forecasts.length > 0) {
        if (isProjectForecasts) {
            label = projectContext.getProject(forecasts[0].projectId)?.name ?? '';
        }
        else if (isGroupForecasts) {
            label = forecasts[0].accountGroup ?? '';
        }
        else {
            label = accountContext.getAccount(forecasts[0].accountId)?.name ?? '';
        }
    }
    
    let lastRegisteredDate: undefined | Date = undefined;
    const dataPoints: ChartPoint[] = [];
    forecasts.forEach(forecast => {
        if (!forecast.date) {
            return;
        }
        const value = forecast.forecast ?? 0;
        const chartPoint: ChartPoint = {
            x: forecast.date,
            y: value.toFixed(2)
        }
        if (lastRegisteredDate && datesAreOnSameMonth(lastRegisteredDate, forecast.date) && dataPoints.length > 0) {
            dataPoints[dataPoints.length - 1] = chartPoint;
        }
        else {
            dataPoints.push(chartPoint);
        }
        lastRegisteredDate = forecast.date;
    });

    const dataSet: ChartDataSets = {
        label: label,
        fill: true,
        lineTension: 0,
        backgroundColor: '#1976d280',
        borderColor: '#1976d2',
        pointBorderColor: '#666',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        pointRadius: 5,
        pointHitRadius: 10,
        data: dataPoints
    }

    return dataSet;
};

const accountForecastsToDataSets = (accountContext: AccountContext, projectContext: ProjectContext, forecasts: Forecasts, isProjectForecasts: boolean, isGroupForecasts: boolean): ChartData => {
    const datasets = accountForecastToDataSet(accountContext, projectContext, forecasts.forecasts ?? [], isProjectForecasts, isGroupForecasts);

    return {
        datasets: [datasets]
    }
};

let options = (languageContext: LanguageContext, currency: String, timeUnit: Chart.TimeUnit): ChartOptions => {
    const amountLabel = languageContext.getMessage('amount') + ` (${currency})`;
    return {
        legend: {
            position: 'bottom',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                type: 'time',
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: "Date",
                },
                time: {
                    isoWeekday: false,
                    unit: timeUnit
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: amountLabel,
                }
            }]
        }
    }
};

type props = {
    forecasts: Forecasts;
    isProjectForecasts?: boolean | undefined;
    isGroupForecasts?: boolean | undefined;
    showMonthUnits?: boolean;
}

const ForecastsGraph: React.FC<props> = ({ 
    forecasts,
    isProjectForecasts, 
    isGroupForecasts, 
    showMonthUnits }) => {

    isProjectForecasts = isProjectForecasts ?? false;
    isGroupForecasts = isGroupForecasts ?? false;
    showMonthUnits = showMonthUnits ?? false;

    const languageContext = useLanguageContext();
    const accountContext = useAccountContext();
    const projectContext = useProjectContext();

    let title = `${languageContext.getMessage('account')} ${languageContext.getMessage('finalForecast')}`
    if (isProjectForecasts) {
        title = `${languageContext.getMessage('project')} ${languageContext.getMessage('finalForecast')}`
    }
    if (isGroupForecasts) {
        title = `${languageContext.getMessage('group')} ${languageContext.getMessage('finalForecast')}`
    }
    
    const currency = CurrencyType.NOK;
    return (
        <LineGraph
            chartComponentProps={{
                data: accountForecastsToDataSets(accountContext, projectContext, forecasts, isProjectForecasts, isGroupForecasts),
                height: 400,
            }}
            chartOptions={options(languageContext, currency, showMonthUnits ? 'month' : 'day')}
            chartTitle={title} 
            chartSubHeader={<DateFormatFromTo from={forecasts.fromDate} to={forecasts.toDate} />}/>
    );
}

export default ForecastsGraph;