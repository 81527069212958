import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ChangeOrderLandlord, ActiveState } from "../../../contracts/contracts";
import { guidIsNullOrEmpty } from "../../../utils/guidTools";

export const MUTATE_CHANGE_ORDER: DocumentNode = gql`
    mutation changeOrderLandlord(
            $id: UUID
            $state: ActiveState
            $projectId: UUID
            $accountId: UUID
            $parentId: UUID
            $relationType: RelationType
            $name: String
            $group: String
            $sent: DateTime
            $answered: DateTime
            $changeOrderDescription: String
            $changeOrderComment: String
            $costRequirements: Float
            $cost: Float
            $billed: Float
            $status: ChangeOrderStatus
            $progressConsequenceEnabled: Boolean
            $progressConsequenceDescription: String
        ) {
        mutateChangeOrderLandlord(
            id: $id
            state: $state
            projectId: $projectId
            accountId: $accountId
            parentId: $parentId
            relationType: $relationType
            name: $name
            group: $group
            sent: $sent
            answered: $answered
            changeOrderDescription: $changeOrderDescription
            changeOrderComment: $changeOrderComment
            costRequirements: $costRequirements
            cost: $cost
            billed: $billed
            status: $status
            progressConsequenceEnabled: $progressConsequenceEnabled
            progressConsequenceDescription: $progressConsequenceDescription
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getChangeOrderLandlordsGraphqlMutationOptions = (changeOrderLandlord: ChangeOrderLandlord): QueryHookOptions => {

    changeOrderLandlord.state = changeOrderLandlord.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (changeOrderLandlord.id !== undefined) options.variables['id'] = changeOrderLandlord.id;
    if (changeOrderLandlord.state !== undefined) options.variables['state'] = changeOrderLandlord.state;
    if (!guidIsNullOrEmpty(changeOrderLandlord.projectId)) options.variables['projectId'] = changeOrderLandlord.projectId;
    if (changeOrderLandlord.parentId !== undefined) options.variables['parentId'] = changeOrderLandlord.parentId;
    if (changeOrderLandlord.relationType !== undefined) options.variables['relationType'] = changeOrderLandlord.relationType;
    if (!guidIsNullOrEmpty(changeOrderLandlord.accountId)) options.variables['accountId'] = changeOrderLandlord.accountId;
    if (changeOrderLandlord.name !== undefined) options.variables['name'] = changeOrderLandlord.name;
    if (changeOrderLandlord.group !== undefined) options.variables['group'] = changeOrderLandlord.group;
    if (changeOrderLandlord.sent !== undefined) options.variables['sent'] = changeOrderLandlord.sent.toISOString();
    if (changeOrderLandlord.answered !== undefined) options.variables['answered'] = changeOrderLandlord.answered.toISOString();
    if (changeOrderLandlord.changeOrderDescription !== undefined) options.variables['changeOrderDescription'] = changeOrderLandlord.changeOrderDescription;
    if (changeOrderLandlord.changeOrderComment !== undefined) options.variables['changeOrderComment'] = changeOrderLandlord.changeOrderComment;
    if (changeOrderLandlord.costRequirements !== undefined) options.variables['costRequirements'] = changeOrderLandlord.costRequirements;
    if (changeOrderLandlord.cost !== undefined) options.variables['cost'] = changeOrderLandlord.cost;
    if (changeOrderLandlord.billed !== undefined) options.variables['billed'] = changeOrderLandlord.billed;
    if (changeOrderLandlord.status !== undefined) options.variables['status'] = changeOrderLandlord.status;
    if (changeOrderLandlord.progressConsequenceEnabled !== undefined) options.variables['progressConsequenceEnabled'] = changeOrderLandlord.progressConsequenceEnabled;
    if (changeOrderLandlord.progressConsequenceDescription !== undefined) options.variables['progressConsequenceDescription'] = changeOrderLandlord.progressConsequenceDescription;
    return options;
}
