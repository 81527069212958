import { withTheme } from "@material-ui/core";
import React from "react";
import { useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { Invoice } from "../../contracts/contracts";
import InvoiceChangeOrderSelect from "./InvoiceChangeOrderSelect";

type props = {
  invoiceId?: string;
};

const InvoiceChangeOrderSelectWrapper: React.FC<props> = ({ invoiceId }) => {
  invoiceId = invoiceId ?? "";

  const invoiceContext = useInvoiceContext();

  const invoice = invoiceId ? invoiceContext.getInvoice(invoiceId) : null;

  const setInvoiceProperties = (partialInvoice: Partial<Invoice>) => {
    if (!invoice) return;
    const copy = { ...invoice, ...partialInvoice };
    invoiceContext.updateInvoice(copy.id, copy);
  };

  return (
    <>
      {invoice && (
        <InvoiceChangeOrderSelect
          accountId={invoice.accountId}
          contractId={invoice.contractId}
          changeOrderId={invoice.changeOrderId}
          onChange={(contractId, changeOrderId) => setInvoiceProperties({ contractId: contractId, changeOrderId: changeOrderId })}
          dense
          fullWidth
        />
      )}
    </>
  );
};

export default withTheme(InvoiceChangeOrderSelectWrapper);
