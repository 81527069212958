import React, { useEffect, useState } from "react";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { withTheme, makeStyles, Theme, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Select, Input, MenuItem, IconButton, FormControl, CircularProgress } from '@material-ui/core';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useUserRoleContext } from '../../contexts/userRole/userRoleContext';
import { Dictionary } from '../../global-types';
import { RoleType, User } from '../../contracts/contracts';
import { useProjectContext } from "../../contexts/project/projectContext";
import { getDominantRoleFromUser, getRoleDescription, getRoleType, notSpecified, updateUserRoles } from "../../contexts/userRole/userRoleTools";
import { useAuthContext } from "../../contexts/auth/authContext";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: '1em'
  },
  divider: {
    marginBottom: '1em'
  },
  tableCellHeader: {
    minWidth: theme.spacing(20),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export interface AuthSettingsProps {
  users: User[] | undefined,
}

const AuthSettings: React.FC<AuthSettingsProps> = (props: AuthSettingsProps) => {

  const languageContext = useLanguageContext();
  const projectContext = useProjectContext();
  const authContext = useAuthContext();
  const userRoleContext = useUserRoleContext();

  let projectRoles = userRoleContext.getUserRoles(projectContext.getSelectedProject() ?? {});
  let globalRoles = userRoleContext.getUserRoles();
  const users = userRoleContext.getUsers();
  const [updatingUserRoles, setUpdatingUserRoles] = useState<boolean>(false);

  const userHasOwnerAccess = projectContext.hasProjectAccess(RoleType.OWNER);
  const checkIfRoleIsPrivileged = (role: RoleType | undefined): boolean => {
    return role === RoleType.OWNER || role === RoleType.ADMIN;
  }

  if (!userHasOwnerAccess) {
    projectRoles = projectRoles.filter(role => !checkIfRoleIsPrivileged(getRoleType(role)));
    globalRoles = globalRoles.filter(role => !checkIfRoleIsPrivileged(getRoleType(role)));
  }

  const [editingAll, setEditingAll] = React.useState<boolean>(false);
  const [editingUsers, setEditingUsers] = React.useState<Dictionary<boolean>>({});

  const handleUpdateUser = async (user: User | undefined, handlingMultipleUsers?: boolean): Promise<void> => {
    handlingMultipleUsers = handlingMultipleUsers ?? false;
    if (user) {
      const userCopy = { ...user };
      if (!handlingMultipleUsers) {
        setUpdatingUserRoles(true);
      }
      userCopy.roles = userCopy.roles ?? [];
      if (!userHasOwnerAccess) {
        userCopy.roles = userCopy.roles.filter(role => !checkIfRoleIsPrivileged(getRoleType(role)));
      }
      await userRoleContext.updateUserRole(userCopy);
      if (!handlingMultipleUsers) {
        setUpdatingUserRoles(false);
      }
    }
  }

  const handleEditingAllButton = async () => {
    const userIds = Object.keys(editingUsers);
    setUpdatingUserRoles(true);
    for (let i = 0; i < userIds.length; i++) {
      if (editingAll && editingUsers[userIds[i]]) {
        const user = users.find(user => user.id === userIds[i]);
        await handleUpdateUser(user, true);
      }
      editingUsers[userIds[i]] = !editingAll;
    }
    setUpdatingUserRoles(false);
    setEditingUsers({ ...editingUsers });
    setEditingAll(!editingAll);
  }

  useEffect(() => {
    users.forEach(user => {
      if (!user.id) {
        return;
      }
      if (!(user.id in editingUsers)) {
        editingUsers[user.id] = false;
      }
    })
    setEditingUsers({ ...editingUsers });
  }, [users]);

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  function Row(props: { rowUser: User }) {
    const { rowUser } = props;
    const classes = useRowStyles();

    if (!rowUser.id || !(rowUser.id in editingUsers)) {
      return <></>;
    }
    const editing = editingUsers[rowUser.id];

    const handleEditingButton = async () => {
      if (!rowUser.id) {
        return;
      }

      if (editing) {
        await handleUpdateUser(rowUser);
      }
      editingUsers[rowUser.id] = !editing;

      const defaultEditingState = editingUsers[rowUser.id];
      let allAreInSameEditState = true;
      Object.keys(editingUsers).forEach(userId => {
        if (editingUsers[userId] !== defaultEditingState) {
          allAreInSameEditState = false;
        }
      })
      if (allAreInSameEditState) {
        setEditingAll(defaultEditingState);
      }
      setEditingUsers({ ...editingUsers });
    };

    const projectRoleMenuItems = Object.values(projectRoles).map(role => (
      <MenuItem key={role.id} value={role.id}>
        {getRoleDescription(role, languageContext, projectContext)}
      </MenuItem>
    ))
    projectRoleMenuItems.push((
      <MenuItem key={notSpecified} value={notSpecified}>
        {languageContext.getMessage(notSpecified)}
      </MenuItem>
    ))

    const globalRoleMenuItems = Object.values(globalRoles).map(role => (
      <MenuItem key={role.id} value={role.id}>
        {getRoleDescription(role, languageContext)}
      </MenuItem>
    ))
    globalRoleMenuItems.push((
      <MenuItem key={notSpecified} value={notSpecified}>
        {languageContext.getMessage(notSpecified)}
      </MenuItem>
    ))

    const projectRoleIsEditable = authContext.accountInfo?.id !== rowUser.id && (userHasOwnerAccess || (!userHasOwnerAccess && !checkIfRoleIsPrivileged(getRoleType(getDominantRoleFromUser(rowUser, projectContext)))));
    const globalRoleIsEditable = authContext.accountInfo?.id !== rowUser.id && (userHasOwnerAccess || (!userHasOwnerAccess && !checkIfRoleIsPrivileged(getRoleType(getDominantRoleFromUser(rowUser)))));
    const userRowIsEditable = projectRoleIsEditable || globalRoleIsEditable

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell align='left' width='10%'>
            <IconButton disabled={!userRowIsEditable || updatingUserRoles} aria-label="expand row" size="small" onClick={async () => await handleEditingButton()}>
              {(editing && userRowIsEditable) ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" width='30%'>
            {rowUser.name}
          </TableCell>
          <TableCell width='30%'>{rowUser.email}</TableCell>
          {(editing && projectRoleIsEditable) ?
            <TableCell width='30%'>
              <FormControl fullWidth>
                <Select
                  value={getDominantRoleFromUser(rowUser, projectContext)?.id ?? notSpecified}
                  input={<Input />}
                  onChange={(event) => {
                    if (event.target.value) {
                      updateUserRoles(rowUser, event.target.value as string, userRoleContext, projectContext);
                      setEditingUsers({ ...editingUsers });
                    }
                  }}
                >
                  {projectRoleMenuItems}
                </Select>
              </FormControl>
            </TableCell>
            :
            <TableCell width='30%'>{getRoleDescription(getDominantRoleFromUser(rowUser, projectContext), languageContext, projectContext)}</TableCell>
          }
          {(editing && globalRoleIsEditable) ?
            <TableCell width='30%'>
              <FormControl fullWidth>
                <Select
                  value={getDominantRoleFromUser(rowUser)?.id ?? notSpecified}
                  input={<Input />}
                  onChange={(event) => {
                    if (event.target.value) {
                      updateUserRoles(rowUser, event.target.value as string, userRoleContext);
                      setEditingUsers({ ...editingUsers });
                    }
                  }}
                >
                  {globalRoleMenuItems}
                </Select>
              </FormControl>
            </TableCell>
            :
            <TableCell width='30%'>{getRoleDescription(getDominantRoleFromUser(rowUser), languageContext)}</TableCell>
          }
        </TableRow>
      </React.Fragment>
    );
  }

  return (<>
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              <IconButton aria-label="expand row" size="small" disabled={updatingUserRoles} onClick={async () => await handleEditingAllButton()}>
                {updatingUserRoles ? <CircularProgress size={25} /> : (editingAll ? <SaveIcon /> : <EditIcon />)}
              </IconButton>
            </TableCell>
            <TableCell>{languageContext.getMessage('name')}</TableCell>
            <TableCell >{languageContext.getMessage('email')}</TableCell>
            <TableCell >{`${languageContext.getMessage('project')} ${languageContext.getMessage('role')}`}</TableCell>
            <TableCell >{`${languageContext.getMessage('global')} ${languageContext.getMessage('role')}`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <Row key={user.id} rowUser={user} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

export default withTheme(AuthSettings)