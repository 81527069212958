import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { AccrualAndOperationExpense, AccrualAndOperationsExpenses, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";

export const MUTATE_ACCRUAL_AND_OPERATIONS_EXPENSES: DocumentNode = gql`
    mutation mutateAccrualAndOperationsExpenses(
        $id: UUID
        $state: ActiveState
        $accountId: UUID!
        $name: String
        $forecast: Float
        $monthlyExpenseItems: [AccrualAndOperationExpenseInput]) {
    mutateAccrualAndOperationsExpenses(
        id: $id
        state: $state
        accountId: $accountId
        name: $name
        forecast: $forecast
        monthlyExpenseItems: $monthlyExpenseItems) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getAccrualAndOperationsExpensesGraphqlMutationOptions = (accrualAndOperationsExpenses: Partial<AccrualAndOperationsExpenses>): QueryHookOptions => {

    accrualAndOperationsExpenses.state = accrualAndOperationsExpenses.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {}
    options.variables = {};
    if (accrualAndOperationsExpenses.id !== undefined) options.variables['id'] = accrualAndOperationsExpenses.id
    if (accrualAndOperationsExpenses.state !== undefined) options.variables['state'] = accrualAndOperationsExpenses.state
    if (accrualAndOperationsExpenses.accountId !== undefined) options.variables['accountId'] = accrualAndOperationsExpenses.accountId
    if (accrualAndOperationsExpenses.name !== undefined) options.variables['name'] = accrualAndOperationsExpenses.name
    if (accrualAndOperationsExpenses.forecast !== undefined) options.variables['forecast'] = accrualAndOperationsExpenses.forecast
    if (accrualAndOperationsExpenses.monthlyExpenseItems !== undefined) options.variables['monthlyExpenseItems'] = accrualAndOperationsExpenses.monthlyExpenseItems.map(expense => GetAccrualAndOperationExpenseInput(expense))
    return options;
}

export const GetAccrualAndOperationExpenseInput = (accrualAndOperationExpense: AccrualAndOperationExpense): Record<string, any> => {
    const accrualAndOperationExpenseInput: Record<string, any> = {}
    accrualAndOperationExpenseInput.date = toISODate(accrualAndOperationExpense.date ?? new Date());
    if (accrualAndOperationExpense.expense !== undefined) accrualAndOperationExpenseInput.expense = accrualAndOperationExpense.expense;
    if (accrualAndOperationExpense.disabled !== undefined) accrualAndOperationExpenseInput.disabled = accrualAndOperationExpense.disabled;
    return accrualAndOperationExpenseInput;
}
