import { Button, CircularProgress, Grid, makeStyles, Paper, Tab, Tabs, Theme, Toolbar, Typography, withTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DateRangePicker from "../../component/dateComponents/DateRangePicker";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { CrewDetails, CrewList, CrewResource, CrewResourceDetails, DocumentType, RoleType } from "../../contracts/contracts";
import ViewHeading from "../../component/viewComponents/ViewHeading";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useCrewResourceContext } from "../../contexts/crewResource/crewResourceContext";
import { useCrewListContext } from "../../contexts/crewList/crewListContext";
import CrewListOverview from "./CrewListOverview";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";
import { datesAreOnSameMonth, getStartOfMonth } from "../../utils/dateTools";
import CrewResourceDetailsList from "../../component/crewListComponents/CrewResourceDetailsList";
import NewCrewResourceDialog from "../../component/crewListComponents/NewCrewResourceDialog";
import { Add, Cancel, Edit, Save, SwapHoriz } from "@material-ui/icons";
import { displayCurrency } from "../../component/currencyComponents/CurrencyFormat";
import DateFormat from "../../component/dateComponents/DateFormat";
import CurrencyInput from "../../component/currencyComponents/CurrencyInput";
import { DatePicker } from "../../component/dateComponents/DatePicker";
import { extractUsedMonths, GetNewCrewResourceDetails } from "../../component/crewListComponents/CrewResourceTools";
import { ToggleButton } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: "1em",
  },
  titleSection: {
    margin: theme.spacing(3, 2),
  },
  dateRangePickerSection: {
    margin: theme.spacing(3, 2, 0),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CrewListView: React.FC<{}> = () => {
  const urlContext = useUrlContext();
  const languageContext = useLanguageContext();
  const classes = useStyles();
  const crewListContext = useCrewListContext();
  const crewResourceContext = useCrewResourceContext();
  const projectContext = useProjectContext();
  const ticketContext = useTicketContext();
  const userRoles = useUserRoleContext();

  const urlState = urlContext.getUrlState();
  const tabIndex = urlState.tab ? (urlState.tab as string) : "all";
  const showSalaryState = (urlState.showSalary ? (urlState.showSalary as string) : "false") === "true";
  const editingCrewResources = (urlState.isEditing ? (urlState.isEditing as string) : "false") === "true";

  const crewListSearch = crewListContext.getCrewListSearch();
  const crewResourceSearch = crewResourceContext.getCrewResourceSearch();

  const [isSavingCrewList, setIsSavingCrewList] = useState<boolean>(false);

  const [openCrewDialog, setOpenCrewDialog] = useState<boolean>(false);
  const [crewResourceToEdit, setCrewResourceToEdit] = useState<CrewResource>({});

  const [crewListToUpdate, setCrewListToUpdate] = useState<CrewList | undefined>(undefined);
  const [crewResourcesToUpdate, setCrewResourcesToUpdate] = useState<Dictionary<CrewResource>>({});

  const canEditSalary = projectContext.hasProjectAccess(RoleType.OWNER);
  const canSeeSalary = projectContext.hasProjectAccess(RoleType.ADMIN);

  const crewList = crewListContext.crewLists.find((crewList) => crewList.parentDocumentId === projectContext.getSelectedProject()?.id);
  const crewResources = crewResourceContext.getCrewResources();

  const monthlyCrewResourceDetails: Dictionary<Dictionary<CrewResourceDetails>> = {};
  const monthlyCrewDetails: Dictionary<CrewDetails> = {};

  const usedDates = extractUsedMonths(crewList, crewResources, monthlyCrewResourceDetails, monthlyCrewDetails, crewListSearch);

  useEffect(() => {
    ticketContext.setDocumentTypesToWatch([DocumentType.CREW_LIST, DocumentType.CREW_RESOURCE]);
    crewListContext.searchCrewList(crewListSearch, false);
    crewResourceContext.searchCrewResource(crewResourceSearch, false);
    userRoles.queryUsers();
  }, [urlContext.currentLocation, projectContext.getSelectedProject()]);

  const updateUrlState = (newCrewListSearch: CrewList,
    newTabIndex: string,
    isEditing: boolean,
    showSalary: boolean): void => {
    newCrewListSearch = { ...newCrewListSearch };
    let newUrlState = {
      ...urlState,
      ...newCrewListSearch,
      ...{ tab: newTabIndex },
      ...{ isEditing: isEditing },
      ...{ showSalary: showSalary },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const handleTabIndexChange = (event: React.ChangeEvent<{}> | null, newTabIndex: string) => {
    updateUrlState(crewListSearch, newTabIndex, editingCrewResources, showSalaryState);
  };

  const handleToAndFromDateLimit = (newCrewListSearch: CrewList) => {
    if (!newCrewListSearch.fromDate || !newCrewListSearch.toDate) {
      return;
    }
    if (getStartOfMonth(newCrewListSearch.fromDate) > getStartOfMonth(newCrewListSearch.toDate)) {
      newCrewListSearch.fromDate = new Date(newCrewListSearch.toDate);
    }
  };

  const updateCrewResourceSearch = (newCrewListSearch: CrewList): void => {
    handleToAndFromDateLimit(newCrewListSearch);
    newCrewListSearch.fromDateIsEmpty = newCrewListSearch.fromDate ? false : true;
    newCrewListSearch.toDateIsEmpty = newCrewListSearch.toDate ? false : true;
    updateUrlState(newCrewListSearch, tabIndex, editingCrewResources, showSalaryState);
  };

  const tabHeadingTitle = `${languageContext.getMessage("crewList")}`;

  const popDateMonth = (popToDate: boolean) => {
    const defaultDate = popToDate ? crewListSearch.toDate ?? crewListSearch.fromDate : crewListSearch.fromDate ?? crewListSearch.toDate;
    const oldDate = new Date(defaultDate ?? new Date());
    const newDate = new Date(oldDate.setMonth(oldDate.getMonth() - 1));
    if (popToDate) {
      crewListSearch.toDate = newDate;
    } else {
      crewListSearch.fromDate = newDate;
    }
    updateCrewResourceSearch(crewListSearch);
  };

  const pushDateMonth = (pushToDate: boolean) => {
    const defaultDate = pushToDate ? crewListSearch.toDate ?? crewListSearch.fromDate : crewListSearch.fromDate ?? crewListSearch.toDate;
    const oldDate = new Date(defaultDate ?? new Date());
    const newDate = new Date(oldDate.setMonth(oldDate.getMonth() + 1));
    if (pushToDate) {
      crewListSearch.toDate = newDate;
    } else {
      crewListSearch.fromDate = newDate;
    }
    updateCrewResourceSearch(crewListSearch);
  };

  const updateStartAndEndDateInCrewListSearch = (newStartDate: Date | undefined, newEndDate: Date | undefined) => {
    if (newStartDate) {
      newStartDate = new Date(newStartDate.setDate(1));
    }
    if (newEndDate) {
      newEndDate = new Date(newEndDate.setDate(1));
    }
    crewListSearch.fromDate = newStartDate;
    crewListSearch.toDate = newEndDate;
  };

  const updateStartAndEndDate = (newStartDate: Date | undefined, newEndDate: Date | undefined) => {
    updateStartAndEndDateInCrewListSearch(newStartDate, newEndDate);
    updateCrewResourceSearch(crewListSearch);
  };

  const onEditCrewResource = async (cancel: boolean) => {
    const editingCrewResourcesCopy = editingCrewResources;
    updateUrlState(crewListSearch, tabIndex, !editingCrewResourcesCopy, showSalaryState);
    if (editingCrewResourcesCopy && !cancel) {
      setIsSavingCrewList(true);
      if (crewList) {
        crewList.fromDate = crewListSearch.fromDate ? new Date(crewListSearch.fromDate) : undefined;
        crewList.toDate = crewListSearch.toDate ? new Date(crewListSearch.toDate) : undefined;
        crewList.firstDate = crewListSearch.fromDate ? new Date(crewListSearch.fromDate) : undefined;
        crewList.lastDate = crewListSearch.toDate ? new Date(crewListSearch.toDate) : undefined;
        crewList.fromDateIsEmpty = crewListSearch.fromDate ? false : true;
        crewList.toDateIsEmpty = crewListSearch.toDate ? false : true;
      }
      for (let id in crewResourcesToUpdate) {
        const crewResource = crewResourcesToUpdate[id];
        await crewResourceContext.mutateCrewResource(crewResource);
      }
      if (crewListToUpdate) {
        await crewListContext.updateCrewList(crewListToUpdate);
      }
      setIsSavingCrewList(false);
    }
    else if (!editingCrewResourcesCopy && !cancel) {
      updateStartAndEndDateInCrewListSearch(crewList?.firstDate, crewList?.lastDate);
    }

    if (cancel) {
      const crewListEntrySearch = crewResourceContext.getCrewResourceSearch();
      crewResourceContext.searchCrewResource(crewListEntrySearch, true);
    }
    setCrewResourcesToUpdate({});
    setCrewListToUpdate(undefined);
  };

  const onShowSalary = async (showSalary: boolean) => {
    updateUrlState(crewListSearch, tabIndex, editingCrewResources, showSalary);
  };

  const onUpdatesalaryAdjustmentPercentageDate = (newDate: Date | undefined) => {
    if (!crewList) {
      return;
    }
    if (newDate && crewListSearch.fromDate && newDate < crewListSearch.fromDate) {
      newDate = crewListSearch.fromDate;
    }
    crewList.salaryAdjustmentPercentageDate = newDate;
  }

  const registerCrewResourceToUpdate = (crewResource: CrewResource) => {
    if (!crewResource.id) {
      return;
    }
    crewResourcesToUpdate[crewResource.id] = crewResource;
    setCrewResourcesToUpdate({ ...crewResourcesToUpdate });
  };

  const resetCrewResource = (crewResource: CrewResource): void => {
    crewResource.monthlyCrewResourceDetails = crewResource.monthlyCrewResourceDetails ?? [];

    usedDates.forEach((usedDate) => {
      if (crewResource.monthlyCrewResourceDetails) {
        if (crewResource.monthlyCrewResourceDetails.findIndex((crewResourceDetails) => crewResourceDetails.date && datesAreOnSameMonth(crewResourceDetails.date, usedDate)) < 0) {
          crewResource.monthlyCrewResourceDetails.push(GetNewCrewResourceDetails(crewList, crewResource, usedDate));
        }
      }
    });
    crewResource.monthlyCrewResourceDetails.forEach((crewResourceDetails) => {
      const newCrewResourceDetails = GetNewCrewResourceDetails(crewList, crewResource, crewResourceDetails.date ?? new Date());
      if (showSalaryState) {
        crewResourceDetails.salaryAdjustmentPercentage = newCrewResourceDetails.salaryAdjustmentPercentage;
        crewResourceDetails.salary = newCrewResourceDetails.salary;
      } else {
        crewResourceDetails.assignmentPercentage = newCrewResourceDetails.assignmentPercentage;
      }
    });
    registerCrewResourceToUpdate(crewResource);
  };

  const disablePushAndPopDateButtons = getStartOfMonth(crewListSearch.fromDate ?? new Date(0)) >= getStartOfMonth(crewListSearch.toDate ?? new Date(0));
  const language = languageContext.getLanguage();

  const disablePopStartDateButton = !editingCrewResources && getStartOfMonth(crewListSearch.fromDate ?? new Date(0)) <= getStartOfMonth(crewList?.firstDate ?? new Date(0));
  const disablePushEndDateButton = !editingCrewResources && getStartOfMonth(crewListSearch.toDate ?? new Date(0)) >= getStartOfMonth(crewList?.lastDate ?? new Date(0));

  if (disablePopStartDateButton) {
    crewListSearch.fromDate = datesAreOnSameMonth(crewList?.firstDate ?? new Date(0), new Date(0)) ? undefined : crewList?.firstDate;
  }
  if (disablePushEndDateButton) {
    crewListSearch.toDate = datesAreOnSameMonth(crewList?.lastDate ?? new Date(0), new Date(0)) ? undefined : crewList?.lastDate;
  }

  useEffect(() => {
    let defaultSearchIsUpdated = false;
    if (!crewListSearch.fromDate && !crewListSearch.fromDateIsEmpty && crewList?.id !== undefined) {
      crewListSearch.fromDate = new Date(crewList?.firstDate ?? new Date(0));
      crewListSearch.fromDate = datesAreOnSameMonth(crewListSearch.fromDate, new Date(0)) ? undefined : crewListSearch.fromDate;
      crewListSearch.fromDate = crewListSearch.fromDate ? new Date(crewListSearch.fromDate.setDate(1)) : undefined;
      defaultSearchIsUpdated = true;
    }
    if (crewListSearch.fromDate && !crewListSearch.toDate && !crewListSearch.toDateIsEmpty && crewList?.id !== undefined) {
      crewListSearch.toDate = new Date(crewList?.lastDate ?? new Date(0));
      crewListSearch.toDate = datesAreOnSameMonth(crewListSearch.toDate, new Date(0)) ? undefined : crewListSearch.toDate;
      crewListSearch.toDate = crewListSearch.toDate ? new Date(crewListSearch.toDate.setDate(1)) : undefined;
      defaultSearchIsUpdated = true;
    }
    if (defaultSearchIsUpdated) {
      updateCrewResourceSearch(crewListSearch);
    }
  }, [crewList]);

  var matchingCrewDetail = crewList?.monthlyCrewDetails?.find((crewDetail: any) => {
    if (crewDetail.date && crewListSearch.toDate) {
      if (crewDetail.date.getMonth() === crewListSearch.toDate.getMonth() && crewDetail.date.getFullYear() === crewListSearch.toDate.getFullYear()) {
        return crewDetail;
      }
    }
    return undefined;
  });

  const onAddNewCrewResource = () => {
    setOpenCrewDialog(true);
    setCrewResourceToEdit({ projectId: projectContext.getSelectedProject()?.id });
  }

  if (editingCrewResources && !openCrewDialog && crewResources.length === 0) {
    onAddNewCrewResource();
  }

  return (
    <>
      {openCrewDialog && (
        <NewCrewResourceDialog
          crewList={crewList}
          crewResource={crewResourceToEdit}
          open={openCrewDialog}
          usedDates={usedDates}
          onClose={(saved) => {
            setOpenCrewDialog(false);
            if (!saved && crewResources.length === 0) {
              updateUrlState(crewListSearch, tabIndex, false, showSalaryState);
            }
          }}
        />
      )}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
            <Tabs value={tabIndex} onChange={handleTabIndexChange}>
              <Tab label={languageContext.getMessage("all")} value="all" />
              <Tab label={languageContext.getMessage("overview")} value="overview" />
            </Tabs>
          </Toolbar>
        </Grid>
        <Grid item xs={12}>
          <ViewHeading title={tabHeadingTitle}>
            <Grid container wrap="nowrap" spacing={1} alignItems="flex-end">
              <Grid item>
                {editingCrewResources && (
                  <Button
                    variant="outlined"
                    startIcon={<Add />}
                    disabled={!canSeeSalary || isSavingCrewList}
                    onClick={() => onAddNewCrewResource()}
                  >
                    {languageContext.getMessage("crew")}
                  </Button>
                )}
              </Grid>
              {!editingCrewResources && (
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    disabled={!canSeeSalary || isSavingCrewList}
                    onClick={() => {
                      onEditCrewResource(false);
                    }}
                  >
                    {languageContext.getMessage("edit")}
                  </Button>
                </Grid>
              )}
              {editingCrewResources && (
                <>
                  <Grid item>
                    <Button variant="outlined" startIcon={<Cancel />} disabled={isSavingCrewList} onClick={async () => await onEditCrewResource(true)}>
                      {languageContext.getMessage("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" startIcon={<Save />} disabled={isSavingCrewList} onClick={async () => await onEditCrewResource(false)}>
                      {isSavingCrewList ? <CircularProgress size={25} /> : languageContext.getMessage("save")}
                    </Button>
                  </Grid>
                </>
              )}
              {canEditSalary && (
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<SwapHoriz />}
                    disabled={!canSeeSalary || isSavingCrewList}
                    onClick={() => {
                      onShowSalary(!showSalaryState);
                    }}
                  >
                    {showSalaryState ? languageContext.getMessage("percentage") : languageContext.getMessage("salary")}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={isSavingCrewList || datesAreOnSameMonth(crewList?.firstDate ?? new Date(0), new Date(0)) || datesAreOnSameMonth(crewList?.lastDate ?? new Date(0), new Date(0))}
                  onClick={() => {
                    updateStartAndEndDate(crewList?.firstDate, crewList?.lastDate);
                  }}
                >
                  {languageContext.getMessage("showAllMonths")}
                </Button>
              </Grid>
              <Grid item>
                <DateRangePicker
                  fromLabel={`${languageContext.getMessage("from")} - ${crewListSearch?.fromDate?.toLocaleString(language, { month: "long" })}`}
                  toLabel={`${languageContext.getMessage("to")} - ${crewListSearch?.toDate?.toLocaleString(language, { month: "long" })}`}
                  startDate={crewListSearch.fromDate}
                  endDate={crewListSearch.toDate}
                  monthPicker={true}
                  yearPicker={true}
                  onChange={(newStartDate, newEndDate) => {
                    updateStartAndEndDate(newStartDate, newEndDate);
                  }}
                  onPopStartDate={() => popDateMonth(false)}
                  onPushStartDate={() => pushDateMonth(false)}
                  onPopEndDate={() => popDateMonth(true)}
                  onPushEndDate={() => pushDateMonth(true)}
                  popStartDateDisabled={disablePopStartDateButton}
                  pushStartDateDisabled={disablePushAndPopDateButtons}
                  popEndDateDisabled={disablePushAndPopDateButtons}
                  pushEndDateDisabled={disablePushEndDateButton}
                  disabled={isSavingCrewList}
                />
              </Grid>
            </Grid>
          </ViewHeading>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2} style={{ padding: "1em" }}>
            <Grid container wrap="nowrap" spacing={1} alignItems="center">
              {!editingCrewResources && (
                <>
                  <Grid item>
                    <Typography variant="body2">{languageContext.getMessage("revisedForecast") + ": "}</Typography>{" "}
                  </Grid>
                  <Grid item>{displayCurrency(matchingCrewDetail?.revisedForecast)}</Grid>
                  <Grid item style={{ marginLeft: "3em" }}>
                    <Typography variant="body2">{languageContext.getMessage("actuallyAccumulatedPostedExpense") + ": "}</Typography>{" "}
                  </Grid>
                  <Grid item>{displayCurrency(matchingCrewDetail?.actuallyAccumulatedPostedExpense)}</Grid>
                </>
              )}
              <Grid item style={{ marginLeft: "3em" }}>
                <Typography variant="body2">{languageContext.getMessage("constructionAdministrationBudget") + ": "}</Typography>{" "}
              </Grid>
              <Grid item>
                {!editingCrewResources && displayCurrency(crewList?.constructionAdministrationBudget)}
                {editingCrewResources && (
                  <CurrencyInput
                    value={crewList?.constructionAdministrationBudget ?? 0.0}
                    onChange={(newConstructionAdministrationBudget) => {
                      if (crewList) {
                        crewList.constructionAdministrationBudget = newConstructionAdministrationBudget;
                        setCrewListToUpdate({ ...crewList });
                      }
                    }}
                    fullWidth
                    disabled={isSavingCrewList}
                  />
                )}
              </Grid>
              <Grid item style={{ marginLeft: "3em" }}>
                <Typography variant="body2">{languageContext.getMessage("monthForWageGrowth") + ": "}</Typography>{" "}
              </Grid>
              <Grid item>
                {!editingCrewResources &&
                  `${crewList?.salaryAdjustmentPercentageDate ? crewList?.salaryAdjustmentPercentageDate?.toLocaleString(language, { month: "long" }) : languageContext.getMessage("notSpecified")}`}
                {editingCrewResources && (
                  <DatePicker
                    date={crewList?.salaryAdjustmentPercentageDate}
                    monthPicker={true}
                    yearPicker={false}
                    onChange={(newDate) => {
                      newDate = newDate ?? (crewList?.salaryAdjustmentPercentageDate ? new Date(crewList.salaryAdjustmentPercentageDate) : undefined);
                      if (newDate) {
                        newDate = new Date(newDate.setDate(1));
                        if (crewList?.salaryAdjustmentPercentageDate) {
                          newDate = new Date(newDate.setFullYear(crewList.salaryAdjustmentPercentageDate.getFullYear()));
                        }
                      }
                      if (crewList) {
                        onUpdatesalaryAdjustmentPercentageDate(newDate);
                        setCrewListToUpdate({ ...crewList });
                      }
                    }}
                    style={{ width: 150 }}
                    disabled={isSavingCrewList}
                  />
                )}
              </Grid>
              <Grid item style={{ marginLeft: "3em" }}>
                <Typography variant="body2">{languageContext.getMessage("yearForWageGrowth") + ": "}</Typography>{" "}
              </Grid>
              <Grid item>
                {!editingCrewResources && <DateFormat date={crewList?.salaryAdjustmentPercentageDate} showMonth={false} showYear={true} />}
                {editingCrewResources && (
                  <DatePicker
                    date={crewList?.salaryAdjustmentPercentageDate}
                    monthPicker={false}
                    yearPicker={true}
                    onChange={(newDate) => {
                      newDate = newDate ?? (crewList?.salaryAdjustmentPercentageDate ? new Date(crewList.salaryAdjustmentPercentageDate) : undefined);
                      if (newDate) {
                        newDate = new Date(newDate.setDate(1));
                        if (crewList?.salaryAdjustmentPercentageDate) {
                          newDate = new Date(newDate.setMonth(crewList.salaryAdjustmentPercentageDate.getMonth()));
                        }
                      }
                      if (crewList) {
                        onUpdatesalaryAdjustmentPercentageDate(newDate);
                        setCrewListToUpdate({ ...crewList });
                      }
                    }}
                    style={{ width: 150 }}
                    disabled={isSavingCrewList}
                  />
                )}
              </Grid>
              {editingCrewResources &&
                <Grid item>
                  <ToggleButton
                    color="inherit"
                    size="small"
                    className={classes.button}
                    value="check"
                    disabled={isSavingCrewList}
                    onChange={() => {
                      crewResources.forEach(crewResource => {
                        resetCrewResource(crewResource);
                      })
                    }}
                  >
                    <RotateLeftIcon fontSize="small" />
                  </ToggleButton>
                </Grid>}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {tabIndex === "all" && (
            <CrewResourceDetailsList
              from={crewListSearch.fromDate}
              to={crewListSearch.toDate}
              crewList={crewList}
              crewResources={crewResources}
              crewResourcesToUpdate={crewResourcesToUpdate}
              setCrewListToUpdate={setCrewListToUpdate}
              setCrewResourcesToUpdate={setCrewResourcesToUpdate}
              editingCrewResources={editingCrewResources}
              showSalaryState={showSalaryState}
              isSavingCrewList={isSavingCrewList}
              setIsSavingCrewList={setIsSavingCrewList}
              registerCrewResourceToUpdate={registerCrewResourceToUpdate}
              resetCrewResource={resetCrewResource}
            />
            // <CrewListDetailsView from={crewListSearch.fromDate} to={crewListSearch.toDate} />
          )}
          {tabIndex === "overview" && <CrewListOverview from={crewListSearch.fromDate} to={crewListSearch.toDate} />}
        </Grid>
      </Grid>
    </>
  );
};

export default withTheme(CrewListView);
