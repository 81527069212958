import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, Invoice, InvoiceApprovedStatus, InvoiceFilter, InvoiceFlag } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { getStartOfDay, getEndOfDay } from "../../utils/dateTools";
import { getEmptyGuid, guidIsNullOrEmpty } from "../../utils/guidTools";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useProjectContext } from "../project/projectContext";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { useUrlContext } from "../url/urlContext";
import { queryTemplateEngineToProduceInvoiceExcelList } from "./InvoiceExportTools";
import { InvoiceMutationsContextProvider, useInvoiceMutationsContext } from "./mutations/invoiceMutationsContext";
import { InvoiceQueriesContextContext, useInvoiceQueriesContext } from "./queries/invoiceQueriesContext";
import { InvoiceSubscriptionsContextProvider, useInvoiceSubscriptionsContext } from "./subscriptions/invoiceSubscriptionsContext";
import { useAccountContext } from "../account/accountContext";
import { safeToLowerCase } from "../../utils/stringTools";

export const defaultAimzExternalProviderId = 'aimz';

export interface InvoiceContext {
    getInvoiceSearch: () => Invoice,
    searchInvoices: (invoiceSearch: Invoice) => void,
    invoices: Invoice[],
    invoiceIsChild: (invoice: Invoice) => boolean,
    getTopParentInvoice: (invoice: Invoice) => Invoice | undefined,
    getInvoicesByParentId: (parentId?: Guid) => Invoice[],
    getFilteredInvoices: (invoiceFilter: InvoiceFilter) => Invoice[],
    getInvoice: (id: Guid) => (Invoice | undefined),
    updateInvoice: (id?: Guid, changes?: Partial<Invoice>) => void,
    mutateInvoice: (invoice: Invoice) => void,
    downloadInvoices: (invoices: Invoice[]) => void,
    loadingInvoices: boolean,
}

const InvoiceContext = createContext<InvoiceContext>(null as unknown as InvoiceContext);

export const sortInvoiceByDate = (a: Invoice, b: Invoice) => {
    if ((a.voucherDate ?? "") < (b.voucherDate ?? "")) { return -1; }
    if ((a.voucherDate ?? "") > (b.voucherDate ?? "")) { return 1; }
    return 0;
}

export enum InvoiceTabs {
    unprocessed = "unprocessed",
    processed = "processed",
    rejected = "rejected",
    details = "details",
}

export const InvoiceContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <InvoiceMutationsContextProvider>
            <InvoiceQueriesContextContext>
                <InvoiceSubscriptionsContextProvider>
                    <InvoiceSubContextProvider>
                        {children}
                    </InvoiceSubContextProvider>
                </InvoiceSubscriptionsContextProvider>
            </InvoiceQueriesContextContext>
        </InvoiceMutationsContextProvider>
    );
}

export const InvoiceSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const projectContext = useProjectContext();
    const authContext = useAuthContext();
    const accountContext = useAccountContext();
    const languageContext = useLanguageContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const invoiceMutationsContext = useInvoiceMutationsContext();
    const invoiceQueriesContext = useInvoiceQueriesContext();
    const invoiceSubscriptionsContext = useInvoiceSubscriptionsContext();
    const [currentInvoiceSearch, setCurrentInvoiceSearch] = useState<Invoice | undefined>(undefined);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const maxInvoicesToFetchAtOnce = 100;

    const mergeInvoices = (oldInvoices: Array<Invoice>, newInvoices: Array<Invoice>): Array<Invoice> => {
        const updatedInvoices = oldInvoices.slice();
        if (!newInvoices) {
            console.error(`Received undefined set of invoices: ${newInvoices}`);
            return [];
        }
        newInvoices.forEach(newInvoice => {
            if (newInvoice.projectId !== currentInvoiceSearch?.projectId) {
                return;
            }
            if (currentInvoiceSearch?.accountId && newInvoice.accountId !== currentInvoiceSearch?.accountId) {
                return;
            }
            if (currentInvoiceSearch?.changeOrderId && newInvoice.changeOrderId !== currentInvoiceSearch?.changeOrderId) {
                return;
            }
            newInvoice.created = new Date(newInvoice.created ?? 0);
            newInvoice.scannedDate = new Date(newInvoice.scannedDate ?? 0);
            newInvoice.voucherDate = new Date(newInvoice.voucherDate ?? 0);
            newInvoice.dueDate = new Date(newInvoice.dueDate ?? 0);
            newInvoice.interimAccountedDate = newInvoice.interimAccountedDate !== undefined ? new Date(newInvoice.interimAccountedDate) : undefined;
            const index = updatedInvoices.findIndex(invoice => invoice.id === newInvoice.id);
            if (index >= 0) {
                if (newInvoice.state === ActiveState.ACTIVE) {
                    updatedInvoices[index] = newInvoice;
                }
                else {
                    updatedInvoices.splice(index, 1);
                }
            } else {
                if (newInvoice.state === ActiveState.ACTIVE) {
                    updatedInvoices.push(newInvoice);
                }
            }
        });
        return updatedInvoices.sort(sortInvoiceByDate);
    }

    const getInvoiceSearch = (): Invoice => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
        const accountId = urlState.accountId ? urlState.accountId as string : undefined;
        const contractId = urlState.contractId ? urlState.contractId as string: undefined;
        const changeOrderId = urlState.changeOrderId ? urlState.changeOrderId as string : undefined;
    
        return {
            projectId: projectId,
            accountId: accountId,
            contractId: contractId,
            changeOrderId: changeOrderId,
        }
    }

    const searchInvoices = (invoiceSearch: Invoice): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(invoiceSearch, currentInvoiceSearch);
        matched = matched && invoiceSearch?.projectId === currentInvoiceSearch?.projectId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && !matched) {
            invoiceSearch.searchIndexStart = 0;
            invoiceSearch.searchIndexStop = maxInvoicesToFetchAtOnce;
            setCurrentInvoiceSearch(invoiceSearch);
            setInvoices([]);
        }
    }

    const invoiceIsChild = (invoice?: Invoice): boolean => {
        if (!invoice) return false;
        return !guidIsNullOrEmpty(invoice.parentId);
    };

    const getTopParentInvoice = (invoice?: Invoice): Invoice | undefined => {
        let parentInvoice = invoiceContext.getInvoice(invoice?.parentId ?? '');
        while (invoiceIsChild(parentInvoice)) {
            parentInvoice = invoiceContext.getInvoice(parentInvoice?.parentId ?? '');
        }
        return parentInvoice ?? invoice;
    };

    const getInvoicesByParentId = (parentId?: Guid): Invoice[] => {
        return invoices.filter(invoice => invoiceIsChild(invoice) && invoice.parentId === parentId);
    }

    const invoiceIsProcessed = (invoice: Invoice): boolean => {
        if (!guidIsNullOrEmpty(invoice.changeOrderId)) return true;
        if (!guidIsNullOrEmpty(invoice.contractId)) return true;
        return false;
    };

    const invoiceIsInterimAccounted = (invoice: Invoice): boolean => {
        if (!invoice.interimAccountedDate) return false;
        if (!invoice.interimAccounted) return false;
        if (guidIsNullOrEmpty(invoice.accountId)) return false;
        return true;
    } 

    const getFilteredInvoices = (invoiceFilter: InvoiceFilter) => {
        const result = invoices;

        const useAccountId = invoiceFilter.accountId !== undefined;
        const useContractId = invoiceFilter.contractId !== undefined;
        const useChangeOrderId = invoiceFilter.changeOrderId !== undefined && invoiceFilter.changeOrderId !== getEmptyGuid();
        const useShowInterimAccountedInvoices = invoiceFilter.showInterimAccountedInvoices !== undefined;
        const useShowProcessedInvoices = invoiceFilter.showProcessedInvoices !== undefined;
        const fromDate = invoiceFilter.fromDate ? getStartOfDay(invoiceFilter.fromDate) : invoiceFilter.fromDate;
        const toDate = invoiceFilter.toDate ? getEndOfDay(invoiceFilter.toDate) : invoiceFilter.toDate;
        const useSupplierFilter = invoiceFilter.supplierFilter && invoiceFilter.supplierFilter.length > 0;
        const useAccountIdFilter = invoiceFilter.accountIdFilter && invoiceFilter.accountIdFilter.length > 0;
        const useContractIdFilter = invoiceFilter.contractIdFilter && invoiceFilter.contractIdFilter.length > 0;
        const useChangeOrderSubContractorIdFilter = invoiceFilter.changeOrderSubContractorIdFilter && invoiceFilter.changeOrderSubContractorIdFilter.length > 0;
        const useExternalProjectIdFilter = invoiceFilter.externalProjectIdFilter && invoiceFilter.externalProjectIdFilter.length > 0;
        const useExternalProviderFilter = invoiceFilter.externalProviderFilter && invoiceFilter.externalProviderFilter.length > 0;
        const useInternalProviderFilter = invoiceFilter.externalProviderFilter && invoiceFilter.externalProviderFilter.includes(defaultAimzExternalProviderId);
        const useUnknownVatFilter = invoiceFilter.invoiceFlags && invoiceFilter.invoiceFlags.length > 0 && invoiceFilter.invoiceFlags.includes(InvoiceFlag.UNKNOWN_VAT);
        const useUnknownCurrencyFilter = invoiceFilter.invoiceFlags && invoiceFilter.invoiceFlags.length > 0 && invoiceFilter.invoiceFlags.includes(InvoiceFlag.UNKNOWN_CURRENCY);
        const useExternalIdMissingWithProviderFilter = invoiceFilter.invoiceFlags && invoiceFilter.invoiceFlags.length > 0 && invoiceFilter.invoiceFlags.includes(InvoiceFlag.EXTERNAL_ID_MISSING_WITH_PROVIDER);

        const modifiedExternalProviderFilter = (invoiceFilter.externalProviderFilter ?? []).slice() as (string | undefined | null)[];
        if (useInternalProviderFilter) {
            modifiedExternalProviderFilter.push('');
            modifiedExternalProviderFilter.push(undefined);
            modifiedExternalProviderFilter.push(null);
        }

        const lowerCasedSupplierFilter = invoiceFilter.supplierFilter?.map(supplier => supplier.toLowerCase());

        return result.filter(invoice => {
            if(invoice.invoiceIsSplit) return false;
            const dateToTest = invoice.voucherDate as Date;
            if(useAccountId && invoice.accountId !== invoiceFilter.accountId) return false;
            if(useContractId && invoice.contractId !== invoiceFilter.contractId) return false;
            if(useChangeOrderId && invoice.changeOrderId !== invoiceFilter.changeOrderId) return false;
            if(useShowProcessedInvoices){
                if(invoiceFilter.showProcessedInvoices === true && !invoiceIsProcessed(invoice)) return false;
                if(invoiceFilter.showProcessedInvoices === false && invoiceIsProcessed(invoice)) return false;
            }
            if(invoice.approvedStatus === InvoiceApprovedStatus.RETURNED) return false;
            if(invoiceFilter.showRejectedInvoices === true && invoice.approvedStatus !== InvoiceApprovedStatus.REJECTED) return false;
            if(invoiceFilter.approved === true && invoice.approvedStatus !== InvoiceApprovedStatus.APPROVED) return false;
            if(invoiceFilter.approved === false && invoice.approvedStatus === InvoiceApprovedStatus.APPROVED) return false;
            if(fromDate && dateToTest < fromDate) return false;
            if(toDate && dateToTest > toDate) return false;
            if(useSupplierFilter && !lowerCasedSupplierFilter?.includes(safeToLowerCase(invoice.supplier as string))) return false;
            if(useAccountIdFilter && !invoiceFilter.accountIdFilter?.includes(invoice.accountId as string)) return false;
            if(useContractIdFilter && !invoiceFilter.contractIdFilter?.includes(invoice.contractId as string)) return false;
            if(useChangeOrderSubContractorIdFilter && !invoiceFilter.changeOrderSubContractorIdFilter?.includes(invoice.changeOrderId as string)) return false;
            if(useExternalProjectIdFilter && !invoiceFilter.externalProjectIdFilter?.includes(invoice.externalProjectId as string)) return false;
            if(useExternalProviderFilter && !modifiedExternalProviderFilter?.includes(invoice.externalProvider)) return false;
            if(useUnknownVatFilter && !invoice.unknownVat) return false;
            if(useUnknownCurrencyFilter && !invoice.unknownCurrency) return false;
            if(useExternalIdMissingWithProviderFilter && !invoice.externalIdMissingWithProvider) return false;
            if(useShowInterimAccountedInvoices && invoiceFilter.showInterimAccountedInvoices && !invoiceIsInterimAccounted(invoice)) return false;
            if(useShowInterimAccountedInvoices && !invoiceFilter.showInterimAccountedInvoices && invoiceIsInterimAccounted(invoice)) return false;
            return true;
        });
    }

    const getInvoice = (id: Guid) => {
        return invoices.find(invoice => invoice.id === id);
    }

    const updateInvoice = (id?: Guid, changes?: Partial<Invoice>) => {
        if (!id || !changes) return;
        const index = invoices.findIndex(invoice => invoice.id === id);
        if (index > -1) {
            const invoice: Invoice = { ...invoices[index], ...changes};
            mutateInvoice(invoice);
        }
    }

    const mutateInvoice = (invoice: Invoice) => {
        invoiceMutationsContext.mutateInvoice(invoice, (documentId, variables) => {
            invoice = {...invoice, ...variables};
            invoice.id = documentId;
            setInvoices(mergeInvoices(invoices, [invoice]));
        });
    }

    const downloadInvoices = (invoices: Invoice[]): void => {
        queryTemplateEngineToProduceInvoiceExcelList(
            invoices, 
            accountContext,
            templateEngineQueriesContext, 
            languageContext);
    }

    useEffect(() => {
        if (currentInvoiceSearch) {
            invoiceQueriesContext.queryInvoices(currentInvoiceSearch);
        }
    }, [currentInvoiceSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setInvoices([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setInvoices(mergeInvoices(invoices, invoiceQueriesContext.fetchedInvoices));
        if (invoiceQueriesContext.fetchedInvoices.length > 0 && currentInvoiceSearch) {
            currentInvoiceSearch.searchIndexStart = (currentInvoiceSearch.searchIndexStart ?? 0) + invoiceQueriesContext.fetchedInvoices.length;
            currentInvoiceSearch.searchIndexStop = currentInvoiceSearch.searchIndexStart + maxInvoicesToFetchAtOnce;
            setCurrentInvoiceSearch({...currentInvoiceSearch});
        }
    }, [invoiceQueriesContext.fetchedInvoices]);

    useEffect(() => {
        setInvoices(mergeInvoices(invoices, invoiceSubscriptionsContext.subscribedInvoices));
    }, [invoiceSubscriptionsContext.subscribedInvoices]);

    const invoiceContext = {
        getInvoiceSearch,
        searchInvoices,
        invoices,
        invoiceIsChild,
        getTopParentInvoice,
        getInvoicesByParentId,
        getFilteredInvoices,
        getInvoice,
        updateInvoice,
        mutateInvoice,
        downloadInvoices,
        loadingInvoices: invoiceQueriesContext.queryResponse.loading,
    };

    return (
        <InvoiceContext.Provider value={invoiceContext}>
            {children}
        </InvoiceContext.Provider>
    );
}

export const useInvoiceContext = (): InvoiceContext => {
    return useContext(InvoiceContext);
}
