import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { ChangeOrderCounterClaim } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useChangeOrderCounterClaimContext } from '../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext';

type props = {
    selectedChangeOrderCounterClaim: ChangeOrderCounterClaim | undefined;
    onSelectedChangeOrderCounterClaimChange: (newSelectedChangeOrderCounterClaim: ChangeOrderCounterClaim | undefined) => void;
}

const SelectChangeOrderCounterClaim: React.FC<props> = ({
    selectedChangeOrderCounterClaim,
    onSelectedChangeOrderCounterClaimChange}) => {
        
    const languageContext = useLanguageContext();
    const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();

    const changeOrderCounterClaims = changeOrderCounterClaimContext.getChangeOrderCounterClaims();
    const defaultChangeOrderCounterClaim = selectedChangeOrderCounterClaim ?? null;

    const handleChange = (event: any, newSelectedChangeOrderCounterClaim: ChangeOrderCounterClaim | null) => {
        onSelectedChangeOrderCounterClaimChange(newSelectedChangeOrderCounterClaim ?? undefined);
    }

    return  <Autocomplete
                options={changeOrderCounterClaims}
                getOptionLabel={(changeOrderCounterClaim) => changeOrderCounterClaim.name ?? ''}
                value={defaultChangeOrderCounterClaim}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} 
                    label={languageContext.getMessage('changeOrder')} 
                    variant="standard" />}
            />
}

export default SelectChangeOrderCounterClaim;