import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Project, ActiveState } from "../../../contracts/contracts";
import { FORECAST_QUERY_ELEMENT, FORECAST_REPORT_QUERY_ELEMENT } from "../../forecast/forecastsTools";
import { INTERIM_EXPENSE_QUERY_ELEMENT } from "../../interim/InterimTools";

export const QUERY_PROJECT: DocumentNode = gql(`
    query projects(
            $id: UUID
            $documentType: DocumentType
            $state: ActiveState
            $created: DateTime
            $name: String
            $externalProvider: String
            $externalId: String
            $projectStart: Date
            $projectEnd: Date
            $surchargePercent: Float
            $riskConsequenceLimit: Float
        ) {
        projects(
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            name: $name
            externalProvider: $externalProvider
            externalId: $externalId
            projectStart: $projectStart
            projectEnd: $projectEnd
            surchargePercent: $surchargePercent
            riskConsequenceLimit: $riskConsequenceLimit
        ) {
            id
            documentType
            stateTime
            state
            created
            changed
            name
            externalProvider
            externalId
            projectStart
            projectEnd
            surchargePercent
            validateForecast
            forecast {
                ` + FORECAST_QUERY_ELEMENT + `
            }
            activeForecastGroups
            forecastGroups {
                ` + FORECAST_QUERY_ELEMENT + `
            }
            forecastReport {
                ` + FORECAST_REPORT_QUERY_ELEMENT + `
            }
            forecastGroupsReport {
                ` + FORECAST_REPORT_QUERY_ELEMENT + `
            }
            interimExpense {
                ` + INTERIM_EXPENSE_QUERY_ELEMENT + `
            }
            riskConsequenceLimit
        }
    }
`);

export const getProjectsGraphqlQueryOptions = (project: Partial<Project>): QueryHookOptions => {
    project.state = project.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (project.id !== undefined) options.variables['id'] = project.id
    if (project.state !== undefined) options.variables['state'] = project.state
    if (project.name !== undefined) options.variables['name'] = project.name
    if (project.externalProvider !== undefined) options.variables['externalProvider'] = project.externalProvider
    if (project.externalId !== undefined) options.variables['externalId'] = project.externalId
    if (project.projectStart !== undefined) options.variables['projectStart'] = project.projectStart
    if (project.projectEnd !== undefined) options.variables['projectEnd'] = project.projectEnd
    return options;
}