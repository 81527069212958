import { DialogActions, DialogContent, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close, Delete, Input } from '@material-ui/icons';
import withTheme from '@material-ui/styles/withTheme';
import React, { useEffect, useState } from 'react';
import { useAccountContext } from '../../contexts/account/accountContext';
import { useInvoiceContext } from '../../contexts/invoice/invoiceContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useWidthContext } from '../../contexts/WidthContext';
import { ActiveState, Invoice } from '../../contracts/contracts';
import { Guid } from '../../utils/common-types';
import { focusElement } from '../../utils/htmlTools';
import { getEmptyChildInvoice, mergeChildInvoiceWithParent } from '../../utils/invoiceTools';
import SelectAccount from '../accountComponents/SelectAccount';
import CurrencyInput from '../currencyComponents/CurrencyInput';
import InvoiceChangeOrderSelect from './InvoiceChangeOrderSelect';

const useStyles = makeStyles((theme: Theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    listButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export interface Props {
    invoice?: Invoice;
    open: boolean;
    onClose: () => void;
}

const InvoiceSplitDialog: React.FC<Props> = ({
    invoice,
    open,
    onClose,
}) => {
    const classes = useStyles();
    const languageContext = useLanguageContext();
    const invoiceContext = useInvoiceContext();
    const accountContext = useAccountContext();
    const screenIsMobile = useWidthContext().isMobileScreen();

    const [mutatedInvoice, setMutatedInvoice] = useState<Invoice>();
    const [mutatedChildInvoices, setMutatedChildInvoices] = useState<Invoice[]>([]);

    const handleOnClickSave = () => {
        if (mutatedInvoice && mutatedChildInvoices) {
            const activeChildInvoices = getActiveChildInvoices();
            mutatedInvoice.invoiceIsSplit = activeChildInvoices.length > 0;

            invoiceContext.mutateInvoice(mutatedInvoice);

            for (let index = 0; index < mutatedChildInvoices.length; index++) {
                mutatedChildInvoices[index] = mergeChildInvoiceWithParent(mutatedInvoice, mutatedChildInvoices[index]);
                invoiceContext.mutateInvoice(mutatedChildInvoices[index]);
            }
        }

        setMutatedInvoice(undefined);
        setMutatedChildInvoices([]);
        onClose();
    };

    const handleOnClickClose = () => {
        setMutatedInvoice(undefined);
        setMutatedChildInvoices([]);
        onClose();
    };


    useEffect(() => {
        if (!open) return;

        if (invoice) {
            setMutatedInvoice({ ...invoice });

            if (invoice.invoiceIsSplit) {
                setMutatedChildInvoices(invoiceContext.getInvoicesByParentId(invoice.id || ""));
            } else {
                const amount = invoice.amount || 0;
                const splitAmount = Math.floor(amount / 2);

                const withheldAmount = invoice.withheldAmount || 0;
                const splitWithheldAmount = Math.floor(withheldAmount / 2);

                const childInvoiceA: Invoice = {
                    ...getEmptyChildInvoice(invoice?.id || ''),
                    accountId: invoice?.accountId,
                    contractId: invoice?.contractId,
                    changeOrderId: invoice?.changeOrderId,
                    amount: splitAmount,
                    withheldAmount: splitWithheldAmount,
                };
                const childInvoiceB = getEmptyChildInvoice(invoice?.id || '');
                childInvoiceB.amount = amount - splitAmount;
                childInvoiceB.withheldAmount = withheldAmount - splitWithheldAmount;
                setMutatedChildInvoices([childInvoiceA, childInvoiceB]);
            }
        } else {
            setMutatedInvoice({});
            setMutatedChildInvoices([]);
        }
    }, [open]);

    const getActiveChildInvoices = ()=>{
        return mutatedChildInvoices.filter(invoice => invoice.state !== ActiveState.INACTIVE);
    }

    const handleOnClickAddInvoice = () => {
        const nextInvoice = getEmptyChildInvoice(mutatedInvoice?.id || '');
        setMutatedChildInvoices([...mutatedChildInvoices, nextInvoice]);
        focusElement(`account-select-${nextInvoice.id}`);
    }

    const setChildInvoiceProperties = (id: Guid, partialInvoice: Partial<Invoice>) => {
        if (!id) return;

        const copy = [...mutatedChildInvoices];
        const index = copy.findIndex(invoice => invoice.id == id);
        if (index === -1) return;

        copy[index] = { ...copy[index], ...partialInvoice };

        setMutatedChildInvoices(copy);
    }

    const removeChildInvoice = (id?: Guid) => {
        if (!id) return;

        const copy = [...mutatedChildInvoices];
        const index = copy.findIndex(invoice => invoice.id === id);
        if (index === -1) return;

        const invoice = copy[index];
        if (invoice.state === ActiveState.ACTIVE) {
            copy[index] = { ...copy[index], state: ActiveState.INACTIVE };
        } else {
            copy.splice(index, 1);
        }

        setMutatedChildInvoices(copy);

        focusElement('add-invoice-button');
    }

    const getRestAmount = (skipInvoiceId?: Guid) => {
        const activeChildInvoices = getActiveChildInvoices();
        
        let sumAmount = 0;
        activeChildInvoices.forEach(invoice => {
            if(invoice.id === skipInvoiceId) return;
            sumAmount += (invoice.amount || 0)
        });

        const amount = mutatedInvoice?.amount || 0;
        return amount - sumAmount;
    }

    const getRestWithheldAmount = (skipInvoiceId?: Guid) => {
        const activeChildInvoices = getActiveChildInvoices();
        
        let sumWithheldAmount = 0;
        activeChildInvoices.forEach(invoice => {
            if(invoice.id === skipInvoiceId) return;
            sumWithheldAmount += (invoice.withheldAmount || 0)
        });

        const withheldAmount = mutatedInvoice?.withheldAmount || 0;
        return withheldAmount - sumWithheldAmount;
    }

    const saveIsDisabled = () => {
        const activeChildInvoices = getActiveChildInvoices();
        if (activeChildInvoices.length === 0){
            return false;
        }

        if(activeChildInvoices.length >= 2){
            return getRestAmount() !== 0 || getRestWithheldAmount() !== 0;
        }

        return true;
    }

    const renderChildInvoice = (invoice: Invoice) => {

        return (
            <Grid container item xs={12} spacing={1} wrap={'nowrap'} key={invoice.id}>
                <Grid item >
                    <IconButton
                        title={languageContext.getMessage('delete')}
                        className={classes.listButton}
                        onClick={() => removeChildInvoice(invoice.id)}>
                        <Delete fontSize='small' />
                    </IconButton>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectAccount
                        selectedAccount={accountContext.getAccount(invoice.accountId)}
                        onSelectedAccountChange={account => { setChildInvoiceProperties(invoice.id || '', { accountId: account?.id, contractId: '', changeOrderId: '' }) }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InvoiceChangeOrderSelect
                        fullWidth
                        accountId={invoice.accountId}
                        contractId={invoice.contractId}
                        changeOrderId={invoice.changeOrderId}
                        onChange={(contractId, changeOrderId) => setChildInvoiceProperties(invoice.id || '', { contractId: contractId, changeOrderId: changeOrderId })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CurrencyInput
                        fullWidth
                        label={languageContext.getMessage('amount')}
                        value={invoice.amount || 0}
                        onChange={amount => setChildInvoiceProperties(invoice.id || '', { amount: amount })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CurrencyInput
                        fullWidth
                        label={languageContext.getMessage('withheldAmount')}
                        value={invoice.withheldAmount || 0}
                        onChange={withheldAmount => setChildInvoiceProperties(invoice.id || '', { withheldAmount: withheldAmount })}
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        className={classes.listButton}
                        title={languageContext.getMessage('useRemainingAmount')}
                        onClick={() => setChildInvoiceProperties(invoice.id || '', { amount: getRestAmount(invoice.id), withheldAmount: getRestWithheldAmount(invoice.id) })}>
                        <Input fontSize='small' />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    return (
        <Dialog
            fullWidth
            maxWidth={screenIsMobile ? 'xs' : 'md'}
            disableBackdropClick
            onClose={handleOnClickClose}
            open={open}>
            <DialogTitle>
                {languageContext.getMessage('splitInvoice')}
                <IconButton className={classes.closeButton} onClick={handleOnClickClose} title={languageContext.getMessage('cancel')}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <CurrencyInput
                            fullWidth
                            readOnly
                            label={languageContext.getMessage('amount')}
                            value={mutatedInvoice?.amount || 0}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CurrencyInput
                            fullWidth
                            readOnly
                            label={languageContext.getMessage('remainingAmount')}
                            value={getRestAmount()}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CurrencyInput
                            fullWidth
                            readOnly
                            label={languageContext.getMessage('withheldAmount')}
                            value={mutatedInvoice?.withheldAmount || 0}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CurrencyInput
                            fullWidth
                            readOnly
                            label={languageContext.getMessage('remainingWithheldAmount')}
                            value={getRestWithheldAmount()}
                        />
                    </Grid>
                    {getActiveChildInvoices().map(invoice => renderChildInvoice(invoice))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    id='add-invoice-button'
                    color='primary'
                    variant='contained'
                    onClick={handleOnClickAddInvoice}>
                    {languageContext.getMessage('addInvoice')}
                </Button>
                <Button onClick={handleOnClickSave}
                    color='primary'
                    variant='contained'
                    disabled={saveIsDisabled()}>
                    {languageContext.getMessage('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme(InvoiceSplitDialog);