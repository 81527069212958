import { withTheme } from "@material-ui/core";
import { Theme } from '@material-ui/core/styles';
import React from "react";
import { CrewResource, CrewList, CrewResourceDetails, CrewDetails } from "../../contracts/contracts";
import BarGraph from "../graphComponents/BarGraph";
import { ChartOptions, ChartData, ChartColor } from "chart.js";
import { LanguageContext } from "../../contexts/language/interfaces";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { extractUsedMonths } from "./CrewResourceTools";
import { Dictionary } from "../../global-types";
import { getRandomColorFromString } from "../../utils/randomTools";
import { fillEmptyMonths, sortDatesAndExtractYears } from "../../utils/dateTools";
import { useCrewListContext } from "../../contexts/crewList/crewListContext";
import { useCrewResourceContext } from "../../contexts/crewResource/crewResourceContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import LineGraph from "../graphComponents/LineGraph";


const addDefaultDataSet = (datasets: Dictionary<Chart.ChartDataSets>, dataKey: string, label: string, stacksKey: string) => {
    const color = getRandomColorFromString(dataKey);
    datasets[dataKey] = {
        label: label,
        data: [],
        backgroundColor: color,
        borderColor: color,
        // stack: stacksKey,
        fill: false,
    }
}

  
const crewListToLineData = (
    crewList: CrewList | undefined, 
    crewResources: CrewResource[], 
    from: Date | undefined, to: Date | undefined, 
    languageContext: LanguageContext, theme: Theme): ChartData => {

    const labels = new Array<string>();
    const finalAccrualAndOperations = new Array<number>();
    const barColors = new Array<ChartColor>();
    const monthlyCrewResourceDetails: Dictionary<Dictionary<CrewResourceDetails>> = {}
    const monthlyCrewDetails: Dictionary<CrewDetails> = {}
    
    let usedDates = extractUsedMonths(
        crewList, 
        crewResources, 
        monthlyCrewResourceDetails, 
        monthlyCrewDetails);
    usedDates = fillEmptyMonths(from, to, usedDates);
    const [sortedUsedDates, usedYears] = sortDatesAndExtractYears(usedDates);
    const datasets: Dictionary<Chart.ChartDataSets> = {};
    const stacksKey = 'monthlyCrewDetails';
    addDefaultDataSet(datasets, 'expectedAccumulatedExpense', languageContext.getMessage('sum'), stacksKey);

    sortedUsedDates.forEach(usedDate => {
        const dateStr = usedDate.toISOString()
        const monthlyCrewItem = dateStr in monthlyCrewDetails ? monthlyCrewDetails[dateStr] : undefined;
        labels.push(usedDate.toLocaleString(languageContext.getLanguage(), {month: 'long', year: '2-digit'}))
        let value = monthlyCrewItem?.expectedAccumulatedExpense ?? 0.0;
        datasets['expectedAccumulatedExpense'].data?.push(value);
    })

    const datasetsList: Chart.ChartDataSets[] = [];
    for (const key in datasets) {
        datasetsList.push(datasets[key]);
    }

    return {
        labels: labels,
        datasets: datasetsList
    }
}

let options = (languageContext: LanguageContext): ChartOptions => {
    return {
        legend: {
            display: false,
            position: 'bottom',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                type: 'category',
                display: true,
                scaleLabel: {
                    display: true,
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'NOK',
                }
            }]
        }
    }
};

type props = {
    from: Date | undefined,
    to: Date | undefined,
}

const CrewListExpectedAccumulatedExpenseGraph: React.FC<props> = ({
    from, 
    to}) => {

    const languageContext = useLanguageContext();
    const themeContext = useThemeContext();
    const theme = themeContext.getTheme();    
    const projectContext = useProjectContext();
    const crewListContext = useCrewListContext();
    const crewResourceContext = useCrewResourceContext();

    const crewList = crewListContext.crewLists.find(crewList => crewList.parentDocumentId === projectContext.getSelectedProject()?.id);
    const crewResources = crewResourceContext.getCrewResources();

    const title = `${languageContext.getMessage('expectedAccumulatedPostedExpense')}`;
    return (
        <LineGraph 
            chartComponentProps={{
                data: crewListToLineData(
                    crewList, 
                    crewResources,
                    from, to, 
                    languageContext, theme),
                height: 400,
            }}
            chartOptions={options(languageContext)} 
            chartTitle={title} 
            chartSubHeader={''} />
    );
}

export default withTheme(CrewListExpectedAccumulatedExpenseGraph);