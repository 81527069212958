import { ChangeOrderSubContractor, ChangeOrderStatus, RelationType } from "../contracts/contracts";
import { Guid } from "./common-types";
import { getEmptyGuid, getRandomGuid } from "./guidTools";

export const getEmptyChangeOrderSubContractor = (projectId: Guid, accountId?: Guid, contractId?: Guid): ChangeOrderSubContractor => {
    return {
        id: getRandomGuid(),
        projectId: projectId,
        name: "",
        cost: 0,
        accountId: accountId ? accountId : undefined,
        contractId: contractId ? contractId : undefined,
        status: ChangeOrderStatus.NOT_PROCESSED, 
        received: new Date(),
        answered: new Date(),
    }
}
