import React, { createContext, useContext, useEffect, useState } from "react";
import { useUrlContext } from "../url/urlContext";
import { ActiveState, Forecast, Forecasts } from "../../contracts/contracts";
import { receiveForecasts } from "./forecastsTools";
import { ForecastsQueriesContextContext, useForecastsQueriesContext } from "./queries/forecastsQueriesContext";
import { ForecastsSubscriptionsContextProvider, useForecastsSubscriptionsContext } from "./subscriptions/forecastsSubscriptionsContext";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { datesAreOnSameDay } from "../../utils/dateTools";
import { Guid } from "../../utils/common-types";
import { useAuthContext } from "../auth/authContext";
import { useProjectContext } from "../project/projectContext";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { queryTemplateEngineToProduceForecastExcelReport } from "./forecastReportTools";
import { useAccountContext } from "../account/accountContext";
import { useLanguageContext } from "../language/LanguageContext";
import { queryTemplateEngineToProduceForecastExcelList } from "./ForecastExportTools";
import { guidIsNullOrEmpty } from "../../utils/guidTools";
import { useInterimContext } from "../interim/InterimContext";

export interface ForecastsContext {
    getForecastsSearch: () => Forecasts,
    searchForecasts: (forecastsSearch: Forecasts | undefined) => void,
    getForecasts: (parentDocumentId: Guid | undefined) => Forecasts | undefined,
    downloadForecastsReport: (forecastsToReport: Forecasts) => void,
    downloadForecasts: (forecasts: Forecast[], isProjectForecasts?: boolean | undefined, isGroupForecasts?: boolean | undefined) => void,
}

const ForecastsContext = createContext<ForecastsContext>(null as unknown as ForecastsContext);

export const ForecastsContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ForecastsQueriesContextContext>
            <ForecastsSubscriptionsContextProvider>
                <ForecastsSubContextProvider>
                    {children}
                </ForecastsSubContextProvider>
            </ForecastsSubscriptionsContextProvider>
        </ForecastsQueriesContextContext>
    );
}

export const ForecastsSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const accountContext = useAccountContext();
    const interimContext = useInterimContext();
    const forecastsQueriesContext = useForecastsQueriesContext();
    const forecastsSubscriptionsContext = useForecastsSubscriptionsContext();
    const [currentForecastsSearch, setCurrentForecastsSearch] = useState<Forecasts | undefined>(undefined);
    const [forecasts, setForecasts] = useState<Array<Forecasts>>([]);

    const mergeForecasts = (oldForecasts: Array<Forecasts>, newForecasts: Array<Forecasts>): Array<Forecasts> => {
        let updatedForecasts = oldForecasts.slice();
        if (!newForecasts) {
            console.error(`Received undefined set of forecasts: ${newForecasts}`);
            return [];
        }
        newForecasts.forEach(newForecast => {
            if (newForecast.parentDocumentId !== currentForecastsSearch?.parentDocumentId) {
                return;
            }
            newForecast.created = new Date(newForecast.created ?? 0);
            newForecast.changed = new Date(newForecast.changed ?? 0);
            newForecast = receiveForecasts(currentForecastsSearch, newForecast, languageContext);
            const index = updatedForecasts.findIndex(account => account.id === newForecast.id);
            if (index >= 0) {
                if (newForecast.state === ActiveState.ACTIVE) {
                    updatedForecasts[index] = newForecast;
                }
                else {
                    updatedForecasts.splice(index, 1);
                }
            } 
            else {
                if (newForecast.state === ActiveState.ACTIVE) {
                    updatedForecasts.push(newForecast); 
                }
            }
        });
        return updatedForecasts;
    }

    const getForecastsSearch = (): Forecasts => {
        const urlState = urlContext.getUrlState();

        const parentDocumentId = urlState.parentDocumentId ? urlState.parentDocumentId as Guid : undefined;
        const fromDate = urlState.fromDate ? new Date(urlState.fromDate as string) : undefined;
        const toDate = urlState.toDate ? new Date(urlState.toDate as string) : undefined;
    
        return {
            parentDocumentId,
            fromDate: fromDate,
            toDate: toDate,
        }
    }

    const downloadForecastsReport = (forecastsToReport: Forecasts): void => {
        queryTemplateEngineToProduceForecastExcelReport(
            forecastsToReport, 
            templateEngineQueriesContext, 
            languageContext,
            accountContext, 
            projectContext);
    }

    const searchForecasts = (forecastsSearch: Forecasts | undefined): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(forecastsSearch, currentForecastsSearch);
        matched = matched && forecastsSearch?.parentDocumentId === currentForecastsSearch?.parentDocumentId;
        matched = matched && forecastsSearch?.parentDocumentType === currentForecastsSearch?.parentDocumentType;
        matched = matched && checkIfBothPropertiesAreUndefined(forecastsSearch?.fromDate, currentForecastsSearch?.fromDate) && datesAreOnSameDay(forecastsSearch?.fromDate ?? new Date(), currentForecastsSearch?.fromDate ?? new Date());
        matched = matched && checkIfBothPropertiesAreUndefined(forecastsSearch?.toDate, currentForecastsSearch?.toDate) && datesAreOnSameDay(forecastsSearch?.toDate ?? new Date(), currentForecastsSearch?.toDate ?? new Date());
        const mandatoryPropertiesIsSet = !guidIsNullOrEmpty(projectContext.getSelectedProject()?.id) && !guidIsNullOrEmpty(forecastsSearch?.parentDocumentId);
        if (mandatoryPropertiesIsSet && !matched) {
            setCurrentForecastsSearch(forecastsSearch);
            setForecasts([]);
        }
    }
    
    const getForecasts = (parentDocumentId: Guid | undefined): Forecasts | undefined => {
        return forecasts.find(forecast => forecast.parentDocumentId === parentDocumentId);
    }

    const downloadForecasts = (
        forecasts: Forecast[], 
        isProjectForecasts?: boolean | undefined,
        isGroupForecasts?: boolean | undefined): void => {
        queryTemplateEngineToProduceForecastExcelList(
            forecasts, 
            templateEngineQueriesContext, 
            languageContext, 
            projectContext, 
            accountContext, 
            interimContext,
            isProjectForecasts, 
            isGroupForecasts);
    }

    useEffect(() => {
        if (currentForecastsSearch) {
            forecastsQueriesContext.queryForecasts(currentForecastsSearch);
        }
    }, [currentForecastsSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setForecasts([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setForecasts(mergeForecasts([], forecastsQueriesContext.fetchedForecasts));
    }, [forecastsQueriesContext.fetchedForecasts]);

    useEffect(() => {
        setForecasts(mergeForecasts(forecasts, forecastsSubscriptionsContext.subscribedForecasts));
    }, [forecastsSubscriptionsContext.subscribedForecasts]);

    const value = {
        getForecastsSearch,
        searchForecasts,
        getForecasts,
        downloadForecastsReport,
        downloadForecasts,
    };

    return (
        <ForecastsContext.Provider value={value}>
            {children}
        </ForecastsContext.Provider>
    );
}

export const useForecastsContext = (): ForecastsContext => {
    return useContext(ForecastsContext);
}
