
import { CircularProgress, IconButton, Theme, Tooltip } from "@material-ui/core";
import { Delete, CheckCircle, Image, PictureAsPdf, PostAdd, Error } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useMemo } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: "1em",
  },
  paper: {
    padding: theme.spacing(2),
  },
  divider: {
    marginBottom: "1em",
  },
  mainItem: {
    display: "flex",
    columnGap: "5px",
    alignItems: "center",
    borderBottom: "1px",
    padding: "12px 4px",
  },
  icon: {
    flexShrink: 0,
    display: 'block',
  },
  fileName: {
    flexGrow: 1,
    textOverflow: 'ellipsis',
  },
  controls: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    columnGap: '4px',
  },
  image: {
    flexShrink: 0,
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  }
}));

export interface FileQueueItemInterface {
  id: string;
  file: File;
  ext: string;
  progress: number;
  error: string | null;
  isUploading: boolean;
  isUploaded: boolean;
}

interface UploadDialogItemProps {
  item: FileQueueItemInterface;
  editMode: boolean;
  onDelete: (item: FileQueueItemInterface) => void;
}

const FileUploadItem = ({
  item,
  editMode,
  onDelete,
}: UploadDialogItemProps) => {
  const languageContext = useLanguageContext();
  const classes = useStyles();

  const mode = useMemo(() => {
    if (item.error) return "error";
    if (item.isUploading) return "uploading";
    if (item.isUploaded) return "uploaded";
    if (editMode) return "edit";
    return "queued";
  }, [item, editMode]);

  function getIconFromType(item: FileQueueItemInterface) {
    switch(item.ext) {
        case '.PNG':
        case '.jpg':
        case '.png':
        case '.jpeg': return (<Image />)
        case '.pdf': return (<PictureAsPdf />)
        default: return(<PostAdd />)
    }
  }


  return (
    <>
      <div className={classes.mainItem} >
        <span className={classes.icon}>{getIconFromType(item)}</span>
        <div className={classes.fileName}>{item.file.name}</div>
        <div className={classes.controls}>
          {mode === "edit" && (
            <>
              <Tooltip title="Delete file">
                <IconButton onClick={() => onDelete(item)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )}
          {mode === "uploading" && (
            <CircularProgress
              size={24}
              variant="determinate"
              value={item.progress}
            />
          )}
          {mode === "error" && <Error color="error" />}
          {mode === "uploaded" && <CheckCircle color="primary" />}
          {mode === "queued" && "queued"}
        </div>
      </div>
      {mode === "error" && (
        <Alert style={{ marginTop: "16px" }} severity="error">
          {`${languageContext.getMessage('couldNotImportFile')} ${item.file.name} ${languageContext.getMessage('dueTo')}`}
        </Alert>
      )}
    </>
  );
};

export default FileUploadItem;
