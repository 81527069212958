import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Forecasts, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";
import { FORECAST_QUERY_ELEMENT, FORECAST_REPORT_QUERY_ELEMENT } from "../forecastsTools";

export const QUERY_FORECASTS: DocumentNode = gql(`
    query forecasts(
            $id: UUID
            $documentType: DocumentType
            $state: ActiveState
            $created: DateTime
            $parentDocumentId: UUID
            $parentDocumentType: DocumentType
            $fromDate: Date
            $toDate: Date
        ) {
        forecasts(
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            parentDocumentId: $parentDocumentId
            parentDocumentType: $parentDocumentType
            fromDate: $fromDate
            toDate: $toDate
        ) {
            id
            documentType
            stateTime
            state
            created
            changed
            parentDocumentId
            parentDocumentType
            fromDate
            toDate
            forecasts {
                ` + FORECAST_QUERY_ELEMENT + `
            }
            forecastsGroups {
                ` + FORECAST_QUERY_ELEMENT + `
            }
            monthlyForecastReports {
                ` + FORECAST_REPORT_QUERY_ELEMENT + `
            }
            monthlyForecastGroupsReports {
                ` + FORECAST_REPORT_QUERY_ELEMENT + `
            }
        }
    }
`);

export const getForecastsGraphqlQueryOptions = (forecasts: Partial<Forecasts>): QueryHookOptions => {
    forecasts.state = forecasts.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (forecasts.id !== undefined) options.variables['id'] = forecasts.id
    if (forecasts.state !== undefined) options.variables['state'] = forecasts.state
    if (forecasts.parentDocumentId !== undefined) options.variables['parentDocumentId'] = forecasts.parentDocumentId
    if (forecasts.parentDocumentType !== undefined) options.variables['parentDocumentType'] = forecasts.parentDocumentType
    if (forecasts.fromDate !== undefined) options.variables['fromDate'] = toISODate(forecasts.fromDate);
    if (forecasts.toDate !== undefined) options.variables['toDate'] = toISODate(forecasts.toDate);
    return options;
}