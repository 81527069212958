import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { useContractContext } from "../../contexts/contract/contractContext";
import { Account, Contract } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
    selectedContract: Contract | undefined;
    selectedAccount: Account | undefined;
    onSelectedSupplierChange: (newSelectedSupplier: string | undefined) => void;
    label?: React.ReactNode;
}

const SelectContractSupplier: React.FC<props> = ({
    selectedContract,
    selectedAccount,
    onSelectedSupplierChange,
    label}) => {
        
    const [inputValue, setInputValue] = React.useState('');
    const languageContext = useLanguageContext();
    const contractContext = useContractContext();

    const suppliers = contractContext.getContracts(selectedAccount?.id).filter(contract => (contract.supplier ?? '').trim().length > 0).map(contract => contract.supplier ?? '');
    const defaultSupplier = selectedContract?.supplier ?? null;

    const handleChange = (event: any, newSelectedSupplier: string | null) => {
        onSelectedSupplierChange(newSelectedSupplier ?? '');
    }

    const freeSolo = true;
    return  <Autocomplete
                options={freeSolo ? suppliers.filter(group => (group ?? "").toLowerCase().includes(inputValue.toLowerCase())) : suppliers}
                getOptionLabel={(supplier) => supplier}
                freeSolo={freeSolo}
                value={defaultSupplier}
                onChange={handleChange}
                inputValue={freeSolo ? inputValue : undefined}
                onInputChange={freeSolo ? (event, newInputValue) => {
                  setInputValue(newInputValue);
                  handleChange(event, newInputValue);
                } : undefined}
                renderInput={(params) => <TextField {...params} 
                    label={label ?? languageContext.getMessage('supplier')} 
                    variant="standard" />}
            />
}

export default SelectContractSupplier;