import { Button, Fab, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, Theme, withTheme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";
import { Risk, RiskType, RoleType } from "../../contracts/contracts";
import RiskList from "../../component/riskComponents/RiskList";
import ViewHeading from "../../component/viewComponents/ViewHeading";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import RiskDialog from "../../component/riskComponents/RiskDialog";
import MultipleButtonFilter from "../../component/multipleDropdownComponents/MultipleButtonFilter";
import AddIcon from '@material-ui/icons/Add';
import RiskMobileList from "../../component/riskComponents/RiskMobileList";
import MultipleDropDownNoLabel from "../../component/multipleDropdownComponents/MultipleDropDownNoLabel";
import MultipleDropDown from "../../component/multipleDropdownComponents/MultipleDropDown";
import { useProjectContext } from "../../contexts/project/projectContext";


const useStyles = makeStyles((theme: Theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    formControl: {
        width: '100%',
      },
}));

type props = {
    onSelectedRiskChange: (newSelectedRisk: Risk | undefined) => void;
    onSelectedTypeChange: (newSelectedTyoe: RiskType | undefined) => void;
}

const RiskAllView: React.FC<props> = ({
    onSelectedRiskChange, onSelectedTypeChange}) => {
    
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const classes = useStyles();

    const [showDialog, setShowDialog] = React.useState<boolean>(false);
    const [riskToEdit, setRiskToEdit] = React.useState<Risk>({});
    const [statusFilter, setStatusFilter] = React.useState<string[]>(['Risiko', 'Mulighet']);
    const [selectedStatusFilter, setSelectedStatusFilter] = React.useState<string[]>([]);


    const handleChangeStatusFilter = (value: string[]) => {
        setSelectedStatusFilter(value);
    };

    const updateStatusFilter = () => {
        const result: string[] = [];
        result.push('Risiko');
        result.push('Mulighet')
        setStatusFilter(result.sort());
    }

    
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
      });

    function setFindRiskType() {
        if (state.checkedA == true && state.checkedB == false) {
            return RiskType.RISK;
        }
        else if (state.checkedA == false && state.checkedB == true) {
            return RiskType.OPPORTUNITY;
        }
        else return 
    }
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const openRiskDialog = (riskToEdit: Risk) => {
        setRiskToEdit({...riskToEdit});
        setShowDialog(true);
    };

    const onCloseRiskDialog = () => {
        setShowDialog(false);
    };

    const [age, setAge] = React.useState('');

  const handleChangeTest = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAge(event.target.value as string);
  };
    
    return (<>
        <RiskDialog 
            risk={riskToEdit} 
            open={showDialog} 
            onClose={onCloseRiskDialog} />
        {(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 400 ?
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ViewHeading title={`${languageContext.getMessage(RiskType.RISK)} ${languageContext.getMessage('and')} ${languageContext.getMessage(RiskType.OPPORTUNITY)}`}>
                    <Grid container spacing={2}>
                        {projectContext.hasProjectAccess(RoleType.WRITER) && <Grid item xs={12} sm={6} md={'auto'}>
                            <IconButton
                            onClick={() => openRiskDialog(
                                riskToEdit)}>
                            <AddIcon />
                            </IconButton>
                        </Grid>}
                        <Grid item xs={12} sm={6} md={'auto'}>
                            <MultipleDropDown
                                heading='Risiko type'
                                options={statusFilter}
                                values={selectedStatusFilter}
                                onChange={handleChangeStatusFilter}
                            />
                            </Grid>
                    </Grid>
                </ViewHeading>
            </Grid>
                <Grid item xs={12}>                
                    <RiskList
                        selectedRiskType={setFindRiskType()}
                        onSelectedRiskChange={onSelectedRiskChange} />
                </Grid>
            </Grid>
        :
        <>
            <Grid item xs={12}>
                <Paper elevation={2}>                
                    <RiskMobileList
                        selectedRiskType={setFindRiskType()}
                        onSelectedRiskChange={onSelectedRiskChange} />
                    {projectContext.hasProjectAccess(RoleType.WRITER) && <Fab aria-label={'Add'} 
                        className={classes.fab} 
                        color={'primary'} 
                        onClick={() => openRiskDialog(
                            riskToEdit)}>
                        {<AddIcon />}
                    </Fab>}
                </Paper>
            </Grid>
        </>                        
        }
    </>
    );
}

export default withTheme(RiskAllView);