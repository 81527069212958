import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, Contract } from "../../../contracts/contracts";

export const QUERY_CONTRACTS: DocumentNode = gql`
    query contracts(
            $id: UUID
            $documentType: DocumentType
            $state: ActiveState
            $created: DateTime
            $changedBy: String
            $projectId: UUID
            $accountId: UUID
            $name: String
            $comment: String
            $contractDescription: String
            $budget: Float
            $procurement: Float
            $useContractProcurementWithForecast: Boolean
            $remainingBudget: Float
            $supplier: String
        ) {
        contracts(
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            changedBy: $changedBy
            projectId: $projectId
            accountId: $accountId 
            name: $name
            comment: $comment
            contractDescription: $contractDescription
            budget: $budget
            procurement: $procurement
            useContractProcurementWithForecast: $useContractProcurementWithForecast
            remainingBudget: $remainingBudget
            supplier: $supplier
        ) {
            id
            documentType
            stateTime
            state
            created
            changedBy
            accountId
            projectId
            name
            comment
            contractDescription
            budget
            adjustedBudget
            procurement
            contractCostScope
            useContractProcurementWithForecast
            contractProcurementIsUsedWithForecast
            sumInvoicedAmount
            sumInvoicedWithheldAmount
            unforeseenBudget
            remainingBudget
            supplier
            sumChangeOrderSubContractorCost
            sumChangeOrderSubContractorApprovedCost
            sumChangeOrderSubContractorRemainingCost
            sumChangeOrderSubContractorInvoicedAmount
            sumChangeOrderSubContractorInvoicedWithheldAmount
            sumChangeOrderExpectedCost
            sumChangeOrderCounterClaimCost
        }
    }
`;

export const getContractsGraphqlQueryOptions = (contract: Partial<Contract>): QueryHookOptions => {

    contract.state = contract.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (contract.id !== undefined) options.variables['id'] = contract.id;
    if (contract.state !== undefined) options.variables['state'] = contract.state;
    if (contract.accountId !== undefined) options.variables['accountId'] = contract.accountId;
    if (contract.projectId !== undefined) options.variables['projectId'] = contract.projectId;
    if (contract.name !== undefined) options.variables['name'] = contract.name;
    if (contract.comment !== undefined) options.variables['comment'] = contract.comment;
    if (contract.contractDescription !== undefined) options.variables['contractDescription'] = contract.contractDescription;
    if (contract.budget !== undefined) options.variables['budget'] = contract.budget;
    if (contract.procurement !== undefined) options.variables['procurement'] = contract.procurement;
    if (contract.remainingBudget !== undefined) options.variables['remainingBudget'] = contract.remainingBudget;
    if (contract.supplier !== undefined) options.variables['supplier'] = contract.supplier;
    return options;
}