import { Tooltip } from "@material-ui/core";

import * as React from "react";

const CommentToolTip: React.FC<{}> = ({ children }) => {
  function GenerateList() {
    let comments: string[] = children as string[];
    return (
        <ul style={{listStylePosition: 'outside', margin: 0, padding: 0}}>
          {comments?.map((comment) => (
            <li style={{fontSize: 12, padding: '5px'}}>{comment}</li>
          ))}
        </ul>
    );
  }
  const [textElement, setTextElement] = React.useState(React.createRef<any>());
  let placeHolder: string[] = children as string[];

  return (
    <div>
      <Tooltip
        style={{
          backgroundColor: "#E5E6E7",
          color: "rgba(0, 0, 0, 0.87)",
          maxWidth: 350,
          padding: '0.2em'
        }}
        title={<GenerateList />}
      >
        <div
          ref={textElement}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {placeHolder && placeHolder.length > 0 && placeHolder[0] ? (placeHolder[0].slice(0, 30) + (placeHolder[0].length > 30 ? "..." : "")) : ''}
        </div>
      </Tooltip>
    </div>
  );
};

export default CommentToolTip;
