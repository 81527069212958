import { makeStyles, Theme, withTheme, CardHeader, Card, CardContent } from "@material-ui/core";
import { Bar, ChartComponentProps } from 'react-chartjs-2';
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '0.5em',
    }
}));
  
type props = {
    chartComponentProps: ChartComponentProps;
    chartOptions: any;
    chartTitle: React.ReactNode;
    chartSubHeader: React.ReactNode;
}


const BarGraph: React.FC<props> = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardHeader
                title={props.chartTitle}
                subheader={props.chartSubHeader}
            />
            <CardContent>
                <Bar 
                    {...props.chartComponentProps} 
                    options={props.chartOptions} />
            </CardContent>
        </Card>
    );
}

export default withTheme(BarGraph);