import { ChangeOrderExpected } from "../../contracts/contracts";
import { capitalizeString } from "../../utils/stringTools";
import { AccountContext } from "../account/accountContext";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

const CHANGE_ORDER_EXPECTED_LIST_TEMPLATE_FILENAME = 'aimz/template_change_order_expected_list.xlsx'
const CHANGE_ORDER_EXPECTED_LIST_TEMPLATE_SHEET_NAME = 'change_order_expected_list'

const sortChangeOrderExpectedByGroup = (a: ChangeOrderExpected, b: ChangeOrderExpected) => {
    if ((a.group ?? '') < (b.group ?? '')) { return -1; }
    if ((a.group ?? '') > (b.group ?? '')) { return 1; }
    return 0;
}

export const queryTemplateEngineToProduceChangeOrderExpectedExcelList = (
    changeOrderExpecteds: ChangeOrderExpected[], 
    templateEngineQueriesContext: TemplateEngineQueriesContext, 
    languageContext: LanguageContext, 
    accountContext: AccountContext): void => {
    const templateFilename: string = CHANGE_ORDER_EXPECTED_LIST_TEMPLATE_FILENAME;
    const templateVariables: object[] = getTemplateEngineChangeOrderExpectedListReportVariables(changeOrderExpecteds, languageContext, accountContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, `${languageContext.getMessage('expectedChange')}`);
}

const getTemplateEngineChangeOrderExpectedListReportVariables = (
    changeOrderExpecteds: ChangeOrderExpected[], 
    languageContext: LanguageContext, 
    accountContext: AccountContext): object[] => {
    const date: Date = new Date();
    const title: string = `${languageContext.getMessage('expectedChange')}`;
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return [{
        'tpl_name': CHANGE_ORDER_EXPECTED_LIST_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': getChangeOrderExpectedListVariables(title, monthString, changeOrderExpecteds, accountContext),
    }]
}

const getChangeOrderExpectedListVariables = (
    title: string, 
    monthString: string, 
    changeOrderExpecteds: ChangeOrderExpected[], 
    accountContext: AccountContext): object => {
    const listVariables = {
        'language': {},
        'title': title,
        'date': monthString,
        'changeOrderExpecteds': changeOrderExpecteds.sort(sortChangeOrderExpectedByGroup).map(changeOrderExpected => {
            return {
                ...changeOrderExpected, 
                account: accountContext.getAccount(changeOrderExpected.accountId)?.name ?? '',
            }
        }),
    }
    return listVariables;
} 
