import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, Contract, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ContractSubscriptionsContext {
    subscribedContracts: Array<Contract>,
}

const ContractSubscriptionsContext = createContext<ContractSubscriptionsContext>(null as unknown as ContractSubscriptionsContext);

export const ContractSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedContracts, setSubscribedContracts] = useState<Array<Contract>>([]);

    const updateSubscribedContracts = (stateDocuments: StateDocument[]) => {
        const subscribedContracts = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.CONTRACT && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Contract);
        setSubscribedContracts(subscribedContracts);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedContracts([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedContracts(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: ContractSubscriptionsContext = {
        subscribedContracts,
    };

    return (
        <ContractSubscriptionsContext.Provider value={projectContext}>
            {children}
        </ContractSubscriptionsContext.Provider>
    );
}

export const useContractSubscriptionsContext = (): ContractSubscriptionsContext => {
    return useContext(ContractSubscriptionsContext);
}
