import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

const DarkTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#242930'
      }
    }
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#0F1319',
      light: '#57CBCC',
      dark: '#57CBCC', //1D2024
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#57CBCC',
      light: '#A8C7E8',
      dark: '#57CBCC',
      contrastText: '#ffffff'
    },
    background: {
      paper: '#242930',
      default: '#353B43'
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#AFBAC4" 
    }
  },
});

export default DarkTheme;