import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, ChangeOrderExpected, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ChangeOrderExpectedSubscriptionsContext {
    subscribedChangeOrderExpecteds: Array<ChangeOrderExpected>,
}

const ChangeOrderExpectedSubscriptionsContext = createContext<ChangeOrderExpectedSubscriptionsContext>(null as unknown as ChangeOrderExpectedSubscriptionsContext);

export const ChangeOrderExpectedSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedChangeOrderExpecteds, setSubscribedChangeOrderExpecteds] = useState<Array<ChangeOrderExpected>>([]);

    const updateSubscribedChangeOrderExpecteds = (stateDocuments: StateDocument[]) => {
        const subscribedChangeOrderExpecteds = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.CHANGE_ORDER_EXPECTED && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as ChangeOrderExpected);
        setSubscribedChangeOrderExpecteds(subscribedChangeOrderExpecteds);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedChangeOrderExpecteds([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedChangeOrderExpecteds(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: ChangeOrderExpectedSubscriptionsContext = {
        subscribedChangeOrderExpecteds,
    };

    return (
        <ChangeOrderExpectedSubscriptionsContext.Provider value={projectContext}>
            {children}
        </ChangeOrderExpectedSubscriptionsContext.Provider>
    );
}

export const useChangeOrderExpectedSubscriptionsContext = (): ChangeOrderExpectedSubscriptionsContext => {
    return useContext(ChangeOrderExpectedSubscriptionsContext);
}
