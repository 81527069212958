import React, { createContext, useContext, useEffect, useState } from "react";
import { TemplateEngineResult } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_TEMPLATE_ENGINE, getTemplateEngineGraphqlQueryOptions } from './templateEngineQueries'
import { useAuthContext } from "../../auth/authContext";
import { downloadBase64File } from "../../../utils/fileTools";

export interface TemplateEngineQueriesContext {
    fetchedTemplateEngineResult: TemplateEngineResult,
    isWaitingForResponse: boolean,
    queryTemplateEngine: (templateFilename: string, templateVariables: object[], outputFilename: string) => void,
}

const TemplateEngineQueriesContext = createContext<TemplateEngineQueriesContext>(null as unknown as TemplateEngineQueriesContext);

export const TemplateEngineQueriesContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedTemplateEngineResult, setFetchedTemplateEngineResult] = useState<TemplateEngineResult>({});
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_TEMPLATE_ENGINE);

    const queryTemplateEngine = (templateFilename: string, templateVariables: object[], outputFilename: string): void => {
        const queryOptions = getTemplateEngineGraphqlQueryOptions(templateFilename, templateVariables, outputFilename);
        setIsWaitingForResponse(true);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        setIsWaitingForResponse(false);
        if (queryResponse.data && queryResponse.data.templateEngine) {
            const newFetchedTemplateEngineResult: TemplateEngineResult = queryResponse.data.templateEngine;
            setFetchedTemplateEngineResult(newFetchedTemplateEngineResult);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        setIsWaitingForResponse(false);
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedTemplateEngineResult({});
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    useEffect(() => {
        const templatedFileType: string = templateEngineQueriesContext.fetchedTemplateEngineResult.templatedFileType ?? ''
        const outputFilename: string = templateEngineQueriesContext.fetchedTemplateEngineResult.outputFilename ?? ''
        const templatedResult: string = templateEngineQueriesContext.fetchedTemplateEngineResult.templatedResult ?? ''
        if (templatedResult.length > 0) {
            downloadBase64File(templatedFileType, templatedResult, outputFilename);
        }
    }, [fetchedTemplateEngineResult]);

    const templateEngineQueriesContext: TemplateEngineQueriesContext = {
        fetchedTemplateEngineResult,
        isWaitingForResponse,
        queryTemplateEngine,
    };

    return (
        <TemplateEngineQueriesContext.Provider value={templateEngineQueriesContext}>
            {children}
        </TemplateEngineQueriesContext.Provider>
    );
}

export const useTemplateEngineQueriesContext = (): TemplateEngineQueriesContext => {
    return useContext(TemplateEngineQueriesContext);
}
