import { GroupedAccount } from "../../contracts/contracts";
import { sortAccountByGroup } from "../../utils/accountTools";
import { capitalizeString } from "../../utils/stringTools";
import { InterimContext } from "../interim/InterimContext";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

export type accountListTemplateAlternatives = 'account' | 'forecast' | 'accrual' | 'interim';

const accountListTemplates = {
    'account': 'aimz/template_account_list.xlsx',
    'forecast': 'aimz/template_account_forecast_list.xlsx',
    'accrual': 'aimz/template_account_accrual_and_operations_list.xlsx',
    'interim': 'aimz/template_account_interim_list.xlsx',
}

const ACCOUNT_LIST_TEMPLATE_SHEET_NAME = 'account_list'

export const queryTemplateEngineToProduceAccountExcelList = (
    accounts: GroupedAccount[],
    exportType: accountListTemplateAlternatives,
    templateEngineQueriesContext: TemplateEngineQueriesContext,
    languageContext: LanguageContext,
    interimContext: InterimContext): void => {
    const templateFilename: string = accountListTemplates[exportType];
    const templateVariables: object[] = getTemplateEngineAccountListReportVariables(accounts, languageContext, interimContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, languageContext.getMessage('accountList'));
}

const getTemplateEngineAccountListReportVariables = (
    accounts: GroupedAccount[],
    languageContext: LanguageContext,
    interimContext: InterimContext): object[] => {
    const date: Date = new Date();
    const title: string = languageContext.getMessage('accountList');
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return [{
        'tpl_name': ACCOUNT_LIST_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': getAccountListVariables(title, monthString, accounts, interimContext),
    }]
}

const getAccountListVariables = (
    title: string,
    monthString: string,
    accounts: GroupedAccount[],
    interimContext: InterimContext): object => {
    const accountsInput: any[] = accounts.map(account => {
        return {
            ...account,
            forecast: account.forecast ?? {},
            accrualLastExpense: account.accrualLastExpense ?? {},
            interimExpense: interimContext.getInterimExpense(account),
        }
    })
    const listVariables = {
        'language': {},
        'title': title,
        'date': monthString,
        'accounts': accountsInput.sort(sortAccountByGroup),
    }
    return listVariables;
} 
