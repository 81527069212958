import React, { createContext, useContext } from "react";
import { ChangeOrderCounterClaim, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getChangeOrderCounterClaimsGraphqlMutationOptions, MUTATE_CHANGE_ORDER } from "./changeOrderCounterClaimMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface ChangeOrderCounterClaimMutationsContext {
    mutateChangeOrderCounterClaim: (accout: ChangeOrderCounterClaim, onTicketSubscribed: (documentId: Guid, variables: any) => void) => void;
}

const ChangeOrderCounterClaimMutationsContext = createContext<ChangeOrderCounterClaimMutationsContext>(null as unknown as ChangeOrderCounterClaimMutationsContext);

export const ChangeOrderCounterClaimMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [changeOrderCounterClaimMutation] = useMutation(MUTATE_CHANGE_ORDER);

    const handleChangeOrderCounterClaimMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateChangeOrderCounterClaim.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                        onTicketSubscribed(ticket.parentDocumentId, variables);
                    }
                }
            }
        });
    }

    const mutateChangeOrderCounterClaim = (changeOrderCounterClaim: ChangeOrderCounterClaim, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        const options = getChangeOrderCounterClaimsGraphqlMutationOptions(changeOrderCounterClaim);
        changeOrderCounterClaimMutation(options).then((result) => handleChangeOrderCounterClaimMutationResult(result, options.variables, onTicketSubscribed));
    }

    const changeOrderCounterClaimMutationsContext: ChangeOrderCounterClaimMutationsContext = {
        mutateChangeOrderCounterClaim
    };

    return (
        <ChangeOrderCounterClaimMutationsContext.Provider value={changeOrderCounterClaimMutationsContext}>
            {children}
        </ChangeOrderCounterClaimMutationsContext.Provider>
    );
}

export const useChangeOrderCounterClaimMutationsContext = (): ChangeOrderCounterClaimMutationsContext => {
    return useContext(ChangeOrderCounterClaimMutationsContext);
}
