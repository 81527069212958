import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Account, ActiveState } from "../../../contracts/contracts";

export const MUTATE_ACCOUNT: DocumentNode = gql`
    mutation account(
            $id: UUID
            $state: ActiveState
            $projectId: UUID!
            $name: String
            $externalProvider: String
            $externalId: String
            $accountType: AccountType
            $accountGroup: String
            $accountNumber: String
            $budget: Float
            $riskConsequenceLimit: Float
            $comment: String
        ) {
        mutateAccount(
            id: $id
            state: $state
            projectId: $projectId
            name: $name
            externalProvider: $externalProvider
            externalId: $externalId
            accountType: $accountType
            accountGroup: $accountGroup
            accountNumber: $accountNumber
            budget: $budget
            riskConsequenceLimit: $riskConsequenceLimit
            comment: $comment
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getAccountsGraphqlMutationOptions = (account: Partial<Account>): QueryHookOptions => {

    account.state = account.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (account.id !== undefined) options.variables['id'] = account.id;
    if (account.state !== undefined) options.variables['state'] = account.state;
    if (account.projectId !== undefined) options.variables['projectId'] = account.projectId;
    if (account.accountType !== undefined) options.variables['accountType'] = account.accountType;
    if (account.name !== undefined) options.variables['name'] = account.name;
    if (account.externalProvider !== undefined) options.variables['externalProvider'] = account.externalProvider
    if (account.externalId !== undefined) options.variables['externalId'] = account.externalId
    if (account.accountGroup !== undefined) options.variables['accountGroup'] = account.accountGroup;
    if (account.accountNumber !== undefined) options.variables['accountNumber'] = account.accountNumber;
    if (account.budget !== undefined) options.variables['budget'] = account.budget;
    if (account.riskConsequenceLimit !== undefined) options.variables['riskConsequenceLimit'] = account.riskConsequenceLimit;
    if (account.comment !== undefined) options.variables['comment'] = account.comment
    return options;
}
