import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useChangeOrderLandlordContext } from '../../contexts/changeOrderLandlord/changeOrderLandlordContext';
import { ChangeOrderStatus } from '../../contracts/contracts';

type props = {
    selectedStatus: ChangeOrderStatus | undefined;
    onSelectedStatusChange: (newSelectedStatus: ChangeOrderStatus | undefined) => void;
}

const SelectStatus: React.FC<props> = ({
    selectedStatus,
    onSelectedStatusChange}) => {
        
    const languageContext = useLanguageContext();
    const changeOrderLandlordContext = useChangeOrderLandlordContext();

    const defaultStatus = selectedStatus ?? null;

    const handleChange = (event: any, newSelectedStatus: ChangeOrderStatus | null) => {
        onSelectedStatusChange(newSelectedStatus ?? undefined);
    }

    return  <Autocomplete
                options={Object.values(ChangeOrderStatus)}
                getOptionLabel={(status) => changeOrderLandlordContext.convertChangeOrderStatusToString(status)}
                renderOption={(option) => (
                    <React.Fragment>
                      <span>{changeOrderLandlordContext.convertChangeOrderStatusToString(option)}</span>
                    </React.Fragment>
                  )}
                value={defaultStatus}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} 
                    label={languageContext.getMessage('columnStatus')} 
                    variant="standard"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill 
                    }}
                />}
            />
}

export default SelectStatus;