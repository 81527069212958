import { Grid } from '@material-ui/core';
import React from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { DatePicker } from './DatePicker';
import { DatePickerWithPushAndPop } from './DatePickerWithPushAndPop';

type props = {
  startDate?: Date;
  endDate?: Date;
  monthPicker?: boolean;
  yearPicker?: boolean;
  onChange?: (startDate?: Date | undefined, endDate?: Date | undefined) => void;
  onPopStartDate?: () => void;
  onPushStartDate?: () => void;
  onPopEndDate?: () => void;
  onPushEndDate?: () => void;
  popStartDateDisabled?: boolean;
  pushStartDateDisabled?: boolean;
  popEndDateDisabled?: boolean;
  pushEndDateDisabled?: boolean;
  extraComponents?: any;
  fromLabel?: React.ReactNode;
  toLabel?: React.ReactNode;
  disabled?: boolean;
}

const DateRangePicker: React.FC<props> = ({
  startDate,
  endDate,
  monthPicker,
  yearPicker,
  onChange,  
  onPopStartDate, 
  onPushStartDate, 
  onPopEndDate, 
  onPushEndDate, 
  popStartDateDisabled, 
  pushStartDateDisabled, 
  popEndDateDisabled, 
  pushEndDateDisabled,
  extraComponents, 
  fromLabel, 
  toLabel,
  disabled}) => {

  const languageContext = useLanguageContext();
  
  return (
    <Grid container wrap="nowrap" spacing={2} alignItems="flex-end" >
      <Grid item>
          {!(onPopStartDate || onPushStartDate) && 
            <DatePicker
              label={fromLabel ?? languageContext.getMessage('from')}
              date={startDate}
              monthPicker={monthPicker}
              yearPicker={yearPicker}
              disabled={disabled}
              onChange={newStartDate => {
                if (onChange) {
                  onChange(newStartDate, endDate)
                }
              }} />
          }
          {(onPopStartDate || onPushStartDate) && 
            <DatePickerWithPushAndPop
              label={fromLabel ?? languageContext.getMessage('from')}
              date={startDate}
              monthPicker={monthPicker}
              yearPicker={yearPicker}
              disabled={disabled}
              onChange={newStartDate => {
                if (onChange) {
                  onChange(newStartDate, endDate)
                }
              }} 
              onPopDate={onPopStartDate}
              onPushDate={onPushStartDate}
              popDateDisabled={popStartDateDisabled}
              pushDateDisabled={pushStartDateDisabled}/>
          }
      </Grid>
      <Grid item>
          {!(onPopEndDate || onPushEndDate) && 
            <DatePicker
              label={toLabel ?? languageContext.getMessage('to')}
              date={endDate}
              monthPicker={monthPicker}
              yearPicker={yearPicker}
              disabled={disabled}
              onChange={newEndDate => {
                if (onChange) {
                  onChange(startDate, newEndDate)
                }
              }} />
          }
          {(onPopEndDate || onPushEndDate) && 
            <DatePickerWithPushAndPop
              label={toLabel ?? languageContext.getMessage('to')}
              date={endDate}
              monthPicker={monthPicker}
              yearPicker={yearPicker}
              disabled={disabled}
              onChange={newEndDate => {
                if (onChange) {
                  onChange(startDate, newEndDate)
                }
              }} 
              onPopDate={onPopEndDate}
              onPushDate={onPushEndDate}
              popDateDisabled={popEndDateDisabled}
              pushDateDisabled={pushEndDateDisabled}/>
          }
      </Grid>
      {extraComponents !== undefined && <>{extraComponents}</>}
    </Grid>
  );
}

export default DateRangePicker;