import React, { createContext, useContext, useEffect, useState } from "react";
import { Forecasts } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_FORECASTS, getForecastsGraphqlQueryOptions } from './forecastsQueries'
import { useAuthContext } from "../../auth/authContext";

export interface ForecastsQueriesContext {
    fetchedForecasts: Array<Forecasts>,
    queryForecasts: (forecasts: Forecasts) => void,
}

const ForecastsQueriesContext = createContext<ForecastsQueriesContext>(null as unknown as ForecastsQueriesContext);

export const ForecastsQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedForecasts, setFetchedForecasts] = useState<Array<Forecasts>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_FORECASTS);

    const queryForecasts = (forecasts: Forecasts): void => {
        const queryOptions = getForecastsGraphqlQueryOptions(forecasts);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.forecasts) {
            const newFetchedForecasts: Array<Forecasts> = queryResponse.data.forecasts;
            setFetchedForecasts(newFetchedForecasts);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedForecasts([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const forecastsQueriesContext: ForecastsQueriesContext = {
        fetchedForecasts,
        queryForecasts,
    };

    return (
        <ForecastsQueriesContext.Provider value={forecastsQueriesContext}>
            {children}
        </ForecastsQueriesContext.Provider>
    );
}

export const useForecastsQueriesContext = (): ForecastsQueriesContext => {
    return useContext(ForecastsQueriesContext);
}
