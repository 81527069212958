import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel'
import { Box, CircularProgress, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import { Document, Page, pdfjs } from "react-pdf";
import { KeyboardArrowUp, KeyboardArrowDown, ZoomIn, ZoomOut } from '@material-ui/icons';

// Fetch correct pdf worker from here, and put it in the public folder:
// https://github.com/mozilla/pdfjs-dist/blob/master/build/pdf.worker.min.js
pdfjs.GlobalWorkerOptions.workerSrc = `${window.origin}/pdf.worker.min.js`;

const useStyles = makeStyles((theme: Theme) => ({
    img: {
        maxHeight: '90%',
        maxWidth: '100%',
    }
}));


type props = {
    base64Images: string[];
}

const ImageCarousel: React.FC<props> = ({ base64Images }) => {
    const classes = useStyles();
    const pdfDescription = 'data:application/pdf;base64,';
    const pngDescription = 'data:image/png;base64,';
    const base64Pdfs = base64Images.filter(base64Image => base64Image.includes(pdfDescription));
    const otherBase64Images = base64Images.filter(base64Image => !base64Image.includes(pdfDescription));


    const [numPages, setNumPages] = useState<number | undefined | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoomLevel, setZoomLevel] = useState(1);

    function changePage(offset: number) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function changeZoom(offset: number) {
        setZoomLevel((prevZoomNumber) => prevZoomNumber + offset);
    }

    function previousZoom() {
        changeZoom(-0.25);
    }

    function nextZoom() {
        changeZoom(0.25);
    }

    function Item(props: { data: string; }) {
        let data = props.data;
        if (data.indexOf(';base64,') < 0) {
            data = `${pngDescription}${data}`;
        }
        return (
            <div>
                <img className={classes.img} src={data} />
            </div >
        )
    }

    return (<>
        {base64Pdfs.length > 0 && <>
            {base64Pdfs.map(base64Pdf =>
                <div style={{ height: "70vh", display: "flex", justifyContent: "center", overflow: "scroll", minHeight: "min-content" }}>
                    <div style={{ maxWidth: "100%", height: "auto !important" }}>
                        <Document
                            file={base64Pdf}
                            onLoadSuccess={(proxy) => {
                                setNumPages(proxy.numPages);
                            }}
                            loading={<CircularProgress />}
                        >
                            <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} scale={zoomLevel} pageIndex={0} loading={<CircularProgress />} />
                        </Document>
                    </div>
                    <div style={{ position: "fixed", width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "flex-end", marginBottom: "0.5em" }}>
                        <Box style={{ backgroundColor: "grey", opacity: 0.9, borderRadius: 5, alignItems: "center", paddingLeft: "0.4em" }}>
                            <Typography variant="body1" style={{ paddingLeft: "0.4em", color: "#ffffff" }}>{`${pageNumber || (numPages ? 1 : "--")}/${numPages || "--"}`}</Typography>
                            <IconButton disabled={pageNumber <= 1} onClick={previousPage}>
                                <KeyboardArrowUp style={{ color: pageNumber <= 1 ? "#ffffff" + "CC" : "#ffffff" }} />
                            </IconButton>

                            <IconButton disabled={pageNumber >= (numPages ?? 0)} onClick={nextPage}>
                                <KeyboardArrowDown style={{ color: pageNumber >= (numPages ?? 0) ? "#ffffff" + "CC" : "#ffffff" }} />
                            </IconButton>

                            <IconButton onClick={nextZoom}>
                                <ZoomIn style={{ color: "#ffffff" }} />
                            </IconButton>

                            <IconButton onClick={previousZoom}>
                                <ZoomOut style={{ color: "#ffffff" }} />
                            </IconButton>
                        </Box>
                    </div>
                </div>
            )}
        </>}
        {otherBase64Images.length > 0 && <>
            <Carousel autoPlay={false}>
                {otherBase64Images.map((base64Image: any) => <Item data={base64Image} />)}
            </Carousel>
        </>}
    </>);
}

export default ImageCarousel
