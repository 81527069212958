import { ChangeOrderLandlord } from "../../contracts/contracts";
import { capitalizeString } from "../../utils/stringTools";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

const CHANGE_ORDER_LANDLORD_LIST_TEMPLATE_FILENAME = 'aimz/template_change_order_landlord_list.xlsx'
const CHANGE_ORDER_LANDLORD_LIST_TEMPLATE_SHEET_NAME = 'change_order_landlord_list'

const sortChangeOrderLandlordByGroup = (a: ChangeOrderLandlord, b: ChangeOrderLandlord) => {
    if ((a.group ?? '') < (b.group ?? '')) { return -1; }
    if ((a.group ?? '') > (b.group ?? '')) { return 1; }
    return 0;
}

export const queryTemplateEngineToProduceChangeOrderLandlordExcelList = (
    changeOrderLandlords: ChangeOrderLandlord[], 
    templateEngineQueriesContext: TemplateEngineQueriesContext, 
    languageContext: LanguageContext): void => {
    const templateFilename: string = CHANGE_ORDER_LANDLORD_LIST_TEMPLATE_FILENAME;
    const templateVariables: object[] = getTemplateEngineChangeOrderLandlordListReportVariables(changeOrderLandlords, languageContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, `${languageContext.getMessage('changeOrder')} ${languageContext.getMessage('landlordShort')}`);
}

const getTemplateEngineChangeOrderLandlordListReportVariables = (
    changeOrderLandlords: ChangeOrderLandlord[], 
    languageContext: LanguageContext): object[] => {
    const date: Date = new Date();
    const title: string = `${languageContext.getMessage('changeOrder')} ${languageContext.getMessage('landlordShort')}`;
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return [{
        'tpl_name': CHANGE_ORDER_LANDLORD_LIST_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': getChangeOrderLandlordListVariables(title, monthString, changeOrderLandlords),
    }]
}

const getChangeOrderLandlordListVariables = (
    title: string, 
    monthString: string, 
    changeOrderLandlords: ChangeOrderLandlord[]): object => {
    const listVariables = {
        'language': {},
        'title': title,
        'date': monthString,
        'changeOrderLandlords': changeOrderLandlords.sort(sortChangeOrderLandlordByGroup),
    }
    return listVariables;
} 
