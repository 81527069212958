import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { useChangeOrderCounterClaimContext } from "../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext";
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
    selectedChangeOrderCounterClaimGroup: string | undefined;
    onSelectedChangeOrderCounterClaimGroupChange: (newSelectedChangeOrderCounterClaimGroup: string | undefined) => void;
    freeSolo?: boolean;
}

const SelectChangeOrderCounterClaimGroup: React.FC<props> = ({
    selectedChangeOrderCounterClaimGroup,
    onSelectedChangeOrderCounterClaimGroupChange, 
    freeSolo }) => {

    const [inputValue, setInputValue] = React.useState('');
    const languageContext = useLanguageContext();
    const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();

    const changeOrderCounterClaimGroups: (string | undefined)[] = [];
    changeOrderCounterClaimContext.getChangeOrderCounterClaims().forEach(changeOrderCounterClaim => {
        if (changeOrderCounterClaimGroups.indexOf(changeOrderCounterClaim.group) < 0) {
            changeOrderCounterClaimGroups.push(changeOrderCounterClaim.group);
        }
    });
    const defaultChangeOrderCounterClaimGroup = selectedChangeOrderCounterClaimGroup ?? null;

    const handleChange = (event: any, newSelectedChangeOrderCounterClaimGroup: string | undefined | null) => {
        onSelectedChangeOrderCounterClaimGroupChange(newSelectedChangeOrderCounterClaimGroup ?? undefined);
    }

    return <Autocomplete
        options={freeSolo ? changeOrderCounterClaimGroups.filter(group => (group ?? "").toLowerCase().includes(inputValue.toLowerCase())) : changeOrderCounterClaimGroups}
        freeSolo={freeSolo}
        getOptionLabel={(changeOrderCounterClaimGroup) => changeOrderCounterClaimGroup ?? ''}
        value={defaultChangeOrderCounterClaimGroup}
        onChange={handleChange}
        inputValue={freeSolo ? inputValue : undefined}
        onInputChange={freeSolo ? (event, newInputValue) => {
          setInputValue(newInputValue);
          handleChange(event, newInputValue);
        } : undefined}
        renderInput={(params) => <TextField {...params}
            label={languageContext.getMessage('group')}
            variant="standard" />}
    />
}

export default SelectChangeOrderCounterClaimGroup;