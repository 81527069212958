import React, { createContext, useContext, useEffect, useState } from "react";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { ChangeOrderExpected, ActiveState, ElementStatusEnum, ChangeOrderStatus } from "../../contracts/contracts";
import { useProjectContext } from "../project/projectContext";
import { useLanguageContext } from "../language/LanguageContext";
import { ChangeOrderExpectedMutationsContextProvider, useChangeOrderExpectedMutationsContext } from "./mutations/changeOrderExpectedMutationsContext";
import { ChangeOrderExpectedQueriesContextContext, useChangeOrderExpectedQueriesContext } from "./queries/changeOrderExpectedQueriesContext";
import { ChangeOrderExpectedSubscriptionsContextProvider, useChangeOrderExpectedSubscriptionsContext } from "./subscriptions/changeOrderExpectedSubscriptionsContext";
import { useUrlContext } from "../url/urlContext";
import { useAuthContext } from "../auth/authContext";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { queryTemplateEngineToProduceChangeOrderExpectedExcelList } from "./ChangeOrderExpectedExportTools";
import { useAccountContext } from "../account/accountContext";

export interface ChangeOrderExpectedContext {
    getChangeOrderExpectedSearch: () => ChangeOrderExpected,
    searchChangeOrderExpecteds: (changeOrderExpectedSearch: ChangeOrderExpected) => void,
    getChangeOrderExpecteds: (accountId?: string, status?: ChangeOrderStatus) => Array<ChangeOrderExpected>,
    getChangeOrderExpected: (id?: string) => ChangeOrderExpected | undefined,
    updateChangeOrderExpected:  (changeOrderExpected: ChangeOrderExpected) => void,
    convertChangeOrderStatusToString: (status?: ChangeOrderStatus) => string,
    convertChangeOrderStatusToElementStatus: (status?: ChangeOrderStatus) => ElementStatusEnum,
    downloadChangeOrderExpecteds: (changeOrderExpecteds: ChangeOrderExpected[]) => void,
}

const ChangeOrderExpectedContext = createContext<ChangeOrderExpectedContext>(null as unknown as ChangeOrderExpectedContext);

export const sortChangeOrderExpectedByName = (a: ChangeOrderExpected, b: ChangeOrderExpected) => {
    if ((a?.name ?? '') < (b?.name ?? '')) { return -1; }
    if ((a?.name ?? '') > (b?.name ?? '')) { return 1; }
    return 0;
}

export const sortChangeOrderExpectedByDate = (a: ChangeOrderExpected, b: ChangeOrderExpected) => {
    if ((a?.expected ?? '') < (b?.expected ?? '')) { return -1; }
    if ((a?.expected ?? '') > (b?.expected ?? '')) { return 1; }
    return 0;
}

export const sortChangeOrderExpectedByGroup = (a: ChangeOrderExpected, b: ChangeOrderExpected) => {
    if ((a.group ?? '') < (b.group ?? '')) { return -1; }
    if ((a.group ?? '') > (b.group ?? '')) { return 1; }
    return 0;
}

export const ChangeOrderExpectedContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ChangeOrderExpectedMutationsContextProvider>
            <ChangeOrderExpectedQueriesContextContext>
                <ChangeOrderExpectedSubscriptionsContextProvider>
                    <ChangeOrderExpectedSubContextProvider>
                        {children}
                    </ChangeOrderExpectedSubContextProvider>
                </ChangeOrderExpectedSubscriptionsContextProvider>
            </ChangeOrderExpectedQueriesContextContext>
        </ChangeOrderExpectedMutationsContextProvider>
    );
}

export const ChangeOrderExpectedSubContextProvider : React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const accountContext = useAccountContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const changeOrderExpectedMutationsContext = useChangeOrderExpectedMutationsContext();
    const changeOrderExpectedQueriesContext = useChangeOrderExpectedQueriesContext();
    const changeOrderExpectedSubscriptionsContext = useChangeOrderExpectedSubscriptionsContext();
    const [currentChangeOrderExpectedSearch, setCurrentChangeOrderExpectedSearch] = useState<ChangeOrderExpected | undefined>(undefined);
    const [changeOrderExpecteds, setChangeOrderExpecteds] = useState<Array<ChangeOrderExpected>>([]);

    const mergeChangeOrderExpecteds = (oldChangeOrderExpecteds: Array<ChangeOrderExpected>, newChangeOrderExpecteds: Array<ChangeOrderExpected>): Array<ChangeOrderExpected> => {
        let updatedChangeOrderExpecteds = oldChangeOrderExpecteds.slice();
        if (!newChangeOrderExpecteds) {
            console.error(`Received undefined set of changeOrderExpecteds: ${newChangeOrderExpecteds}`);
            return [];
        }
        newChangeOrderExpecteds.forEach(newChangeOrderExpected => {
            if (newChangeOrderExpected.projectId !== currentChangeOrderExpectedSearch?.projectId) {
                return;
            }
            newChangeOrderExpected.created = new Date(newChangeOrderExpected.created ?? 0);
            newChangeOrderExpected.expected = new Date(newChangeOrderExpected.expected ?? 0);
            newChangeOrderExpected.elementStatus = convertChangeOrderStatusToElementStatus(newChangeOrderExpected.status);
            const index = updatedChangeOrderExpecteds.findIndex(changeOrderExpected => changeOrderExpected.id === newChangeOrderExpected.id);
            if (index >= 0) {
                if (newChangeOrderExpected.state === ActiveState.ACTIVE) {
                    updatedChangeOrderExpecteds[index] = newChangeOrderExpected;
                }
                else {
                    updatedChangeOrderExpecteds.splice(index, 1);
                }
            } 
            else {
                if (newChangeOrderExpected.state === ActiveState.ACTIVE) {
                    updatedChangeOrderExpecteds.push(newChangeOrderExpected); 
                }
            }
        });
        return updatedChangeOrderExpecteds.sort(sortChangeOrderExpectedByName).sort(sortChangeOrderExpectedByGroup);
    }

    const getChangeOrderExpectedSearch = (): ChangeOrderExpected => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
        const accountId = urlState.accountId ? urlState.accountId as string : undefined;
        const contractId = urlState.contractId ? urlState.contractId as string : undefined;

        return {
            projectId: projectId,
            accountId: accountId,
            contractId: contractId,
        }
    }

    const searchChangeOrderExpecteds = (changeOrderExpectedSearch: ChangeOrderExpected): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(changeOrderExpectedSearch, currentChangeOrderExpectedSearch);
        matched = matched && changeOrderExpectedSearch?.projectId === currentChangeOrderExpectedSearch?.projectId;
        matched = matched && changeOrderExpectedSearch?.accountId === currentChangeOrderExpectedSearch?.accountId;
        matched = matched && changeOrderExpectedSearch?.contractId === currentChangeOrderExpectedSearch?.contractId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && !matched) {
            setCurrentChangeOrderExpectedSearch(changeOrderExpectedSearch);
            setChangeOrderExpecteds([]);
        }
    }

    const getChangeOrderExpecteds = (accountId?: string, status?: ChangeOrderStatus): Array<ChangeOrderExpected> => {
        let resolvedChangeOrderExpecteds = changeOrderExpecteds;

        if (accountId) {
            resolvedChangeOrderExpecteds = resolvedChangeOrderExpecteds.filter(changeOrderExpected => changeOrderExpected.accountId === accountId);
        }

        if (status) {
            resolvedChangeOrderExpecteds = resolvedChangeOrderExpecteds.filter(changeOrderExpected => changeOrderExpected.status === status);
        }

        return resolvedChangeOrderExpecteds
    }

    const getChangeOrderExpected = (id?: string) => {
        return changeOrderExpecteds.find(changeOrderExpected => changeOrderExpected.id === id);
    }

    const updateChangeOrderExpected = (changeOrderExpected: ChangeOrderExpected) => {
        changeOrderExpectedMutationsContext.mutateChangeOrderExpected(changeOrderExpected, (documentId, variables) => {
            changeOrderExpected = {...changeOrderExpected, ...variables};
            changeOrderExpected.id = documentId;
            setChangeOrderExpecteds(mergeChangeOrderExpecteds(changeOrderExpecteds, [changeOrderExpected]));
        });
    }

    const convertChangeOrderStatusToString = (status?: ChangeOrderStatus): string => {
        switch (status) {
            case ChangeOrderStatus.APPROVED: return languageContext.getMessage('approved');
            case ChangeOrderStatus.NOT_APPROVED: return languageContext.getMessage('notApproved');
            case ChangeOrderStatus.NOT_PROCESSED: return languageContext.getMessage('notProcessed');
        }
        return languageContext.getMessage('notProcessed')
    }

    const convertChangeOrderStatusToElementStatus = (status?: ChangeOrderStatus): ElementStatusEnum => {
        switch (status) {
            case ChangeOrderStatus.APPROVED: return ElementStatusEnum.SUCCESS;
            case ChangeOrderStatus.NOT_APPROVED: return ElementStatusEnum.CANCEL;
            case ChangeOrderStatus.NOT_PROCESSED: return ElementStatusEnum.INFORMATION;
        }

        return ElementStatusEnum.ALERT;
    }

    const downloadChangeOrderExpecteds = (changeOrderExpecteds: ChangeOrderExpected[]): void => {
        queryTemplateEngineToProduceChangeOrderExpectedExcelList(
            changeOrderExpecteds, 
            templateEngineQueriesContext, 
            languageContext, 
            accountContext);
    }

    useEffect(() => {
        if (currentChangeOrderExpectedSearch) {
            changeOrderExpectedQueriesContext.queryChangeOrderExpecteds(currentChangeOrderExpectedSearch);
        }
    }, [currentChangeOrderExpectedSearch]);
    
    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setChangeOrderExpecteds([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setChangeOrderExpecteds(mergeChangeOrderExpecteds([], changeOrderExpectedQueriesContext.fetchedChangeOrderExpecteds));
    }, [changeOrderExpectedQueriesContext.fetchedChangeOrderExpecteds]);

    useEffect(() => {
        setChangeOrderExpecteds(mergeChangeOrderExpecteds(changeOrderExpecteds, changeOrderExpectedSubscriptionsContext.subscribedChangeOrderExpecteds));
    }, [changeOrderExpectedSubscriptionsContext.subscribedChangeOrderExpecteds]);

    const changeOrderExpectedContext: ChangeOrderExpectedContext = {
        getChangeOrderExpectedSearch,
        searchChangeOrderExpecteds,
        getChangeOrderExpecteds,
        getChangeOrderExpected,
        updateChangeOrderExpected,
        convertChangeOrderStatusToString,
        convertChangeOrderStatusToElementStatus,
        downloadChangeOrderExpecteds,
    };

    return (
        <ChangeOrderExpectedContext.Provider value={ changeOrderExpectedContext }>
            { children }
        </ChangeOrderExpectedContext.Provider>
    );
}

export const useChangeOrderExpectedContext = (): ChangeOrderExpectedContext => {
    return useContext(ChangeOrderExpectedContext);
}
