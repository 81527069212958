import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { Risk, RiskType } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useRiskContext } from '../../contexts/risk/riskContext';

type props = {
    selectedRisk: Risk | undefined;
    onSelectedRiskChange: (newSelectedRisk: Risk | undefined) => void;
}

const SelectRisk: React.FC<props> = ({
    selectedRisk,
    onSelectedRiskChange}) => {
        
    const languageContext = useLanguageContext();
    const riskContext = useRiskContext();

    const risks = riskContext.getRisks();
    const defaultRisk = selectedRisk ?? null;

    const handleChange = (event: any, newSelectedRisk: Risk | null) => {
        onSelectedRiskChange(newSelectedRisk ?? undefined);
    }

    return  <Autocomplete
                options={risks}
                getOptionLabel={(risk) => risk.name ?? ''}
                value={defaultRisk}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} 
                label={languageContext.getMessage('RISK')} 
                     />}
            />
}

export default SelectRisk;