import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, Interim, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface InterimSubscriptionsContext {
    subscribedInterims: Array<Interim>,
}

const InterimSubscriptionsContext = createContext<InterimSubscriptionsContext>(null as unknown as InterimSubscriptionsContext);

export const InterimSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedInterims, setSubscribedInterims] = useState<Array<Interim>>([]);

    const updateSubscribedInterims = (stateDocuments: StateDocument[]) => {
        const subscribedInterims = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.INTERIM && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Interim);
        setSubscribedInterims(subscribedInterims);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedInterims([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedInterims(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const interimContext: InterimSubscriptionsContext = {
        subscribedInterims,
    };

    return (
        <InterimSubscriptionsContext.Provider value={interimContext}>
            {children}
        </InterimSubscriptionsContext.Provider>
    );
}

export const useInterimSubscriptionsContext = (): InterimSubscriptionsContext => {
    return useContext(InterimSubscriptionsContext);
}
