import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow, withTheme, Theme } from "@material-ui/core";

import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  headerCell: {
    position: "sticky",
    left: 0,
    zIndex: 999,
    backgroundColor: theme.palette.background.paper,
    borderStyle: "solid",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 1,
    borderBottomColor: "#D1D1D1CC",
    fontWeight: "bold",
  },
  bodyCell: {
    position: "sticky",
    left: 0,
    zIndex: 998,
    backgroundColor: theme.palette.background.paper,
    borderStyle: "solid",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 1,
    borderBottomColor: "#D1D1D1CC",
    fontWeight: "bold",
  },
  footerCell: {
    position: "sticky",
    left: 0,
    zIndex: 999,
    backgroundColor: theme.palette.background.paper,
    borderStyle: "solid",
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    borderTop: 1,
    borderTopColor: "#D1D1D1CC",
    fontWeight: "bold",
  },
  resizeHandle: {
    display: "block",
    position: "absolute",
    cursor: "col-resize",
    width: "7px",
    right: -1,
    top: 0,
    zIndex: 10000,
    borderLeft: 1,
    borderLeftColor: 'transparent',
    borderRight: "1px solid #D1D1D1CC",
    "&:hover": {
      borderRight: "1px solid #555555",
    },
    "&.active": {
      borderColor: "#517ea5",
    },
  },
  resizeHandleActive: {
    display: "block",
    position: "absolute",
    cursor: "col-resize",
    width: "7px",
    right: -1,
    top: 0,
    zIndex: 10000,
    borderLeft: 1,
    borderLeftColor: 'transparent',
    borderRight: "3px solid #517ea5CC",
  },
}));

export interface StickyColumnTableData {
  label?: JSX.Element;
  cellValues?: JSX.Element[];
}

export interface StickyColumnTableDateArray {
  dateArray: Date[];
  monthYearStringArray: {
    year: string;
    month: string;
  }[];
}

type props = {
  theme: Theme;
  dateColumnData: StickyColumnTableDateArray;
  mainColumn: JSX.Element;
  tableSumData: StickyColumnTableData[];
  tableBodyData: StickyColumnTableData[];
  topLeftElement?: JSX.Element;
};

const StickyColumnTable: React.FC<props> = ({ theme, dateColumnData, mainColumn, tableSumData, tableBodyData, topLeftElement }) => {
  const classes = useStyles();
  const borderColors = {
    borderRightColor: "#909090",
    borderBottomColor: "#D1D1D1CC",
  };

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [headerWidth, setHeaderWidth] = useState<number | string>("auto");
  const [tableHeight, setTableHeight] = useState("auto");
  const tableElement = useRef(null);
  const headerRef = useRef();

  useEffect(() => {
    var topRef = headerRef as any;
    setTableHeight(topRef.current.offsetHeight);
  }, []);

  const mouseMove = useCallback(
    (e) => {
      var topRef = headerRef as any;
      if (topRef && topRef.current) {
        var width = topRef.current.minWidth - topRef.current.offsetParent.offsetLeft;
        width = e.clientX - topRef.current.offsetLeft - topRef.current.offsetParent.offsetLeft;
        if (activeIndex === 1) {
          setHeaderWidth(width);
        }
      }
    },
    [activeIndex]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(-1);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== -1) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  return (
    <TableContainer component={Paper} style={{ maxHeight: "60vh" }}>
      <Table size="small" stickyHeader ref={tableElement}>
        <TableBody style={{ position: "sticky", top: 0, zIndex: 999, backgroundColor: theme.palette.background.paper }}>
          <TableRow>
            <TableCell
              ref={headerRef}
              //   className={classes.headerCell}
              style={{
                minWidth: headerWidth,
                position: "sticky",
                left: 0,
                zIndex: 999,
                backgroundColor: theme.palette.background.paper,
                borderTop: 0,
                borderLeft: 1,
                borderRight: 1,
                borderBottom: 1,
                borderStyle: "solid",
                borderBottomColor: "#D1D1D1CC",
                borderRightColor: 'transparent',
                borderLeftColor: 'transparent',
                fontWeight: "bold",
              }}
            >
              <div
                style={{ height: tableHeight }}
                onMouseDown={() => {
                  setActiveIndex(1);
                }}
                className={activeIndex === 1 ? classes.resizeHandleActive : classes.resizeHandle}
              />
              {topLeftElement}
            </TableCell>

            {dateColumnData.monthYearStringArray.map((column) => {
              return (
                <TableCell
                  align="right"
                  style={{
                    fontWeight: "bold",
                    borderRight: 1,
                    borderTop: 0,
                    borderLeft: (column.year === "") ? 0 : 1,
                    borderBottom: 1,
                    borderStyle: "solid",
                    borderLeftColor: "#D1D1D1CC",
                    borderRightColor: 'transparent',
                    borderBottomColor: "#D1D1D1CC",
                  }}
                > 
                  {column.year}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell
              //   className={classes.headerCell}
              style={{
                fontWeight: "bold",
                position: "sticky",
                left: 0,
                zIndex: 999,
                backgroundColor: theme.palette.background.paper,
                borderTop: 0,
                borderLeft: 1,
                borderRight: 1,
                borderBottom: 1,
                borderStyle: "solid",
                borderRightColor: "#909090",
                borderLeftColor: 'transparent',
                borderBottomColor: "#D1D1D1CC",
              }}
            > 
              {mainColumn}
            </TableCell>
            {dateColumnData.monthYearStringArray.map((column) => {
              return (
                <TableCell
                  align="right"
                  style={{ fontWeight: "bold", borderRight: 1, borderTop: 0, borderLeft: 1, borderBottom: 1, borderStyle: "solid", borderLeftColor: "#D1D1D1CC", borderRightColor: 'transparent', borderBottomColor: "#D1D1D1CC" }}
                >
                  {column.month}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
        <TableBody style={{ zIndex: 998, backgroundColor: theme.palette.background.paper }}>
          {tableBodyData?.map((data, index) => {
            return (
              <TableRow>
                <TableCell
                  //   className={classes.bodyCell}
                  style={{
                    position: "sticky",
                    left: 0,
                    zIndex: 998,
                    backgroundColor: theme.palette.background.paper,
                    borderTop: 0,
                    borderLeft: 1,
                    borderRight: 1,
                    borderBottom: 1,
                    borderStyle: "solid",
                    borderRightColor: "#909090",
                    borderLeftColor: 'transparent',
                    borderBottomColor: "#D1D1D1CC",
                    fontWeight: "bold",
                  }}
                >
                  {data.label}
                </TableCell>
                {data?.cellValues?.map((value) => {
                  return (
                    <TableCell
                      align="right"
                      style={{ borderRight: 1, borderTop: 0, borderLeft: 1, borderBottom: 1, borderStyle: "solid", borderLeftColor: "#D1D1D1CC", borderRightColor: 'transparent', borderBottomColor: "#D1D1D1CC" }}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        <TableBody style={{ position: "sticky", bottom: -1, zIndex: 999, backgroundColor: theme.palette.background.paper }}>
          {tableSumData?.map((data, index) => {
            return (
              <TableRow>
                <TableCell
                  //   className={classes.footerCell}
                  style={{
                    position: "sticky",
                    left: 0,
                    zIndex: 999,
                    backgroundColor: theme.palette.background.paper,
                    borderTop: index === 0 ? 1 : 0,
                    borderBottom: 1,
                    borderRight: 1,
                    borderLeft: 1,
                    borderStyle: "solid",
                    borderRightColor: "#909090",
                    borderLeftColor: 'transparent',
                    borderTopColor: "#909090",
                    borderBottomColor: "#D1D1D1CC",
                    fontWeight: "bold",
                  }}
                >
                  {data.label}
                </TableCell>
                {data?.cellValues?.map((cellValue) => {
                  if (index === 0) {
                    return (
                      <TableCell align="right" style={{ borderTop: 1, borderLeft: 1, borderRight: 1, borderStyle: "solid", borderTopColor: "#909090", borderLeftColor: '#D1D1D1CC', borderRightColor: "transparent" }}>
                        {cellValue}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        align="right"
                        style={{ borderRight: 1, borderTop: 0, borderLeft: 1, borderBottom: 1, borderStyle: "solid", borderRightColor: "transparent", borderLeftColor: '#D1D1D1CC', borderBottomColor: "#D1D1D1CC" }}
                      >
                        {cellValue}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withTheme(StickyColumnTable);
