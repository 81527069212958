import { Button, Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import DateRangePicker from "../../component/dateComponents/DateRangePicker";
import ForecastDetailsView from "./ForecastDetailsView";
import ForecastOverview from "./ForecastOverview";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { DocumentType, Account, Forecasts } from "../../contracts/contracts";
import ForecastAllView from "./ForecastAllView";
import ViewHeading from "../../component/viewComponents/ViewHeading";
import { useForecastsContext } from "../../contexts/forecast/forecastsContext";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useTemplateEngineQueriesContext } from "../../contexts/templateEngine/queries/templateEngineQueriesContext";
import { getStartOfMonth, getEndOfMonth } from "../../utils/dateTools";
import ForecastGroupDetailsView from "./ForecastGroupDetailsView";
import { useInterimContext } from "../../contexts/interim/InterimContext";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    titleSection: {
        margin: theme.spacing(3, 2),
    },
    dateRangePickerSection: {
        margin: theme.spacing(3, 2, 0),
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
}));

const ForecastView: React.FC<{}> = () => {

    const urlContext = useUrlContext();
    const languageContext = useLanguageContext();
    const classes = useStyles();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const accountContext = useAccountContext();
    const interimContext = useInterimContext();
    const forecastContext = useForecastsContext();
    const projectContext = useProjectContext();
    const ticketContext = useTicketContext();

    const project = projectContext.getSelectedProject();

    const urlState = urlContext.getUrlState();
    const tabIndex = urlState.tab ? urlState.tab as string : 'all';
    const accountGroup = urlState.accountGroup ? urlState.accountGroup as string : undefined;

    const accountSearch = accountContext.getAccountSearch();
    const interimSearch = interimContext.getInterimSearch();

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.ACCOUNT, 
            DocumentType.FORECASTS]);
        accountContext.searchAccounts(accountSearch);
        interimContext.searchInterim(interimSearch, false);
    }, [urlContext.currentLocation, projectContext.getSelectedProject()])

    const forecastSearch = forecastContext.getForecastsSearch();
    const selectedAccount = accountContext.getAccount(forecastSearch.parentDocumentId);

    const updateUrlState = (newForecastSearch: Forecasts, accountGroup: string | undefined, newTabIndex: string): void => {
        newForecastSearch = {...newForecastSearch}
        const newUrlState = {
            ...urlState, 
            ...newForecastSearch, 
            ...{'tab': newTabIndex}}
        if (accountGroup) {
            newUrlState['accountGroup'] = accountGroup;
        }
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
        urlContext.pushUrlQuery(urlQuery);
    }

    const handleTabIndexChange = (event: React.ChangeEvent<{}> | null, newTabIndex: string) => {
        updateUrlState(forecastSearch, accountGroup, newTabIndex);
    };

    const updateForecastSearch = (newForecastSearch: Forecasts): void => {
        updateUrlState(newForecastSearch, accountGroup, tabIndex);
    }

    const onSelectedAccountChange = (newSelectedAccount: Account | undefined): void => {
        forecastSearch.parentDocumentId = newSelectedAccount?.id;
        updateUrlState(forecastSearch, accountGroup, 'details');
    };

    const onSelectedGroupChange = (newSelectedAccountGroup: string | undefined): void => {
        updateUrlState(forecastSearch, newSelectedAccountGroup, 'groupDetails');
    }

    const onSelectedProjectChange = (): void => {
        updateUrlState(forecastSearch, accountGroup, 'overview');
    }

    let tabHeadingTitle = `${languageContext.getMessage('account')} ${languageContext.getMessage('finalForecast')}`
    if (tabIndex === 'groupDetails') {
        tabHeadingTitle = `${languageContext.getMessage('group')} ${languageContext.getMessage('finalForecast')}`
    }
    if (tabIndex === 'overview') {
        tabHeadingTitle = `${languageContext.getMessage('project')} ${languageContext.getMessage('finalForecast')}`
    }

    const projectForecasts = forecastContext.getForecasts(project?.id) ?? {};
    const downloadForecastReportButtonIsShown: boolean = tabIndex === 'overview';
    const downloadForecastReportButtonDisabled: boolean = projectForecasts.id === undefined || (projectForecasts.monthlyForecastReports ?? []).length === 0 || templateEngineQueriesContext.isWaitingForResponse;
    const downloadForecastReportButton = <Grid item xs={12} sm="auto">
        <Button disabled={downloadForecastReportButtonDisabled} color="primary" variant="contained" onClick={() => forecastContext.downloadForecastsReport(projectForecasts)} startIcon={<GetAppIcon />}>
            {languageContext.getMessage('downloadForecastReport')}
        </Button>
    </Grid>

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
                    <Tabs value={tabIndex} onChange={handleTabIndexChange}>
                        <Tab label={languageContext.getMessage('all')} value='all' />
                        <Tab label={languageContext.getMessage('groupDetails')} value='groupDetails' />
                        <Tab label={languageContext.getMessage('accountDetails')} value='details' />
                        <Tab label={languageContext.getMessage('overview')} value='overview' />
                    </Tabs>
                </Toolbar>
            </Grid>
            <Grid item xs={12}>
                <ViewHeading title={tabHeadingTitle}>
                    {tabIndex !== 'all' &&
                        <DateRangePicker
                            startDate={forecastSearch.fromDate}
                            endDate={forecastSearch.toDate}
                            monthPicker={true}
                            yearPicker={true}
                            onChange={(newStartDate, newEndDate) => {
                                forecastSearch.fromDate = newStartDate !== undefined ? getStartOfMonth(newStartDate) : undefined;
                                forecastSearch.toDate = newEndDate !== undefined ?  getEndOfMonth(newEndDate) : undefined;
                                updateForecastSearch(forecastSearch)
                            }}
                            extraComponents={downloadForecastReportButtonIsShown ? downloadForecastReportButton : undefined}
                            />
                    }
                </ViewHeading>
            </Grid>
            <Grid item xs={12}>
                {tabIndex === 'all' && <ForecastAllView onSelectedAccountChange={onSelectedAccountChange} onSelectedGroupChange={onSelectedGroupChange} onSelectedProjectChange={onSelectedProjectChange} />}
                {tabIndex === 'details' && <ForecastDetailsView selectedAccount={selectedAccount} onSelectedAccountChange={onSelectedAccountChange}/>}
                {tabIndex === 'groupDetails' && <ForecastGroupDetailsView selectedAccountGroup={accountGroup} onSelectedAccountGroupChange={onSelectedGroupChange} onSelectedAccountChange={onSelectedAccountChange}/>}
                {tabIndex === 'overview' && <ForecastOverview />}
            </Grid>
        </Grid>
    );
}

export default withTheme(ForecastView);