import { TableRow, TableCell, Dialog, DialogTitle, IconButton, DialogContent, Table, TableBody, DialogActions, Box, Button, Grid, Typography, TableHead } from "@material-ui/core";
import { Close, Restore, Update } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useChangeLogContext } from "../contexts/changeLog/ChangeLogContext";
import { useLanguageContext } from "../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../contexts/language/MaterialTableLanguageContext";
import { useProjectContext } from "../contexts/project/projectContext";
import { useThemeContext } from "../contexts/theme/ThemeContext";
import { useUserRoleContext } from "../contexts/userRole/userRoleContext";
import { ChangeLog, DocumentType } from "../contracts/contracts";
import { tableIcons } from "../ui/table-icons";
import { useChangeOrderExpectedContext } from "../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useContractContext } from "../contexts/contract/contractContext";
import { useAccountContext } from "../contexts/account/accountContext";
import { useTicketContext } from "../contexts/ticket/ticketContext";
import { useChangeOrderCounterClaimContext } from "../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext";
import { useChangeOrderSubContractorContext } from "../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { displayCurrency } from "../component/currencyComponents/CurrencyFormat";
import { useRememberTableScroll } from "../hooks/useRememberTableScroll";

const ChangeLogView: React.FC<{}> = () => {
  const languageContext = useLanguageContext();
  const changeLogContext = useChangeLogContext();
  const userRoleContext = useUserRoleContext();
  const projectContext = useProjectContext();
  const materialTableLanguageContext = useMaterialTableLanguageContext();
  const ticketContext = useTicketContext();

  const accountContext = useAccountContext();
  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();
  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
  const contractContext = useContractContext();

  const accountSearch = accountContext.getAccountSearch();
  const contractSearch = contractContext.getContractSearch();
  const changeOrderExpectedSearch = changeOrderExpectedContext.getChangeOrderExpectedSearch();
  const changeOrderCounterClaimSearch = changeOrderCounterClaimContext.getChangeOrderCounterClaimSearch();
  const changeOrderSubContractorSearch = changeOrderSubContractorContext.getChangeOrderSubContractorSearch();


  const project = projectContext.getSelectedProject();
  const theme = useThemeContext().getTheme();
  const tableRef = useRef(null) as any;
  useRememberTableScroll(tableRef, "changeLogList");
  const users = userRoleContext.getUsers();

  const [pageStates, setPageStates] = useState<{ pageSize: number; pageNumber: number }>({ pageSize: 100, pageNumber: 0 });
  const [offset, setOffset] = useState<number>(0);

  const [changeLogsToShow, setChangeLogsToShow] = useState<ChangeLog[]>([]);
  const [openContent, setOpenContent] = useState<boolean>(false);
  const [detailToShow, setDetailToShow] = useState<{ newValues?: any; oldValues?: any; deltaForecast?: number; currentForecast?: number; isUpdatedWithCorrectForecast?: boolean }>({});

  const keysToIgnore = [
    "id",
    "documentType",
    "changed",
    "activeState",
    "created",
    "tenantId",
    "changedBy",
    "projectId",
    "externalId",
    "externalAccountId",
    "externalProjectId",
    "relationType",
    "parentId",
  ];

  useEffect(() => {
    if (project && project.id) {
      userRoleContext.queryUsers();
      ticketContext.setDocumentTypesToWatch([
        DocumentType.ACCOUNT,
        DocumentType.CONTRACT,
        DocumentType.CHANGE_ORDER_SUB_CONTRACTOR,
        DocumentType.CHANGE_ORDER_EXPECTED,
        DocumentType.CHANGE_ORDER_COUNTER_CLAIM,
      ]);
      changeLogContext.searchChangeLog(project.id, offset, 100);
      accountContext.searchAccounts(accountSearch);
      contractContext.searchContracts(contractSearch);
      changeOrderExpectedContext.searchChangeOrderExpecteds(changeOrderExpectedSearch);
      changeOrderCounterClaimContext.searchChangeOrderCounterClaims(changeOrderCounterClaimSearch);
      changeOrderSubContractorContext.searchChangeOrderSubContractors(changeOrderSubContractorSearch);
    }
  }, [project, offset]);

  useEffect(() => {
    setChangeLogsToShow([...changeLogContext.changeLog.slice(0, 100)]);
  }, [changeLogContext.changeLog]);

  const onPageChange = (page: number, pageSize: number): void => {
    setChangeLogsToShow([...changeLogContext.changeLog.slice(page * pageSize, pageSize * (page + 1))]);
  };

  function onRowClick(params: any) {
    var parsedContent: any;
    parsedContent = JSON.parse(params.content ?? "");
    if (parsedContent.newValues && parsedContent.oldValues && parsedContent.deltaForecast && parsedContent.currentForecast) {
      setDetailToShow({ ...{ newValues: parsedContent.newValues, oldValues: parsedContent.oldValues, deltaForecast: parsedContent.deltaForecast, currentForecast: parsedContent.currentForecast, isUpdatedWithCorrectForecast: parsedContent.isUpdatedWithCorrectForecast } });
      setOpenContent(true);
    }
  }

  function getChangeName(logItem: ChangeLog) {
    switch (logItem.documentType) {
      case DocumentType.ACCOUNT:
        return accountContext.getAccount(logItem.documentId)?.name;
      case DocumentType.CONTRACT:
        return contractContext.getContract(logItem.documentId)?.name;
      case DocumentType.CHANGE_ORDER_EXPECTED:
        return changeOrderExpectedContext.getChangeOrderExpected(logItem.documentId)?.name;
      case DocumentType.CHANGE_ORDER_SUB_CONTRACTOR:
        return changeOrderSubContractorContext.getChangeOrderSubContractor(logItem.documentId)?.name;
      case DocumentType.CHANGE_ORDER_COUNTER_CLAIM:
        return changeOrderCounterClaimContext.getChangeOrderCounterClaim(logItem.documentId)?.name;
      default:
        return "";
    }
  }

  function getRow(object: { newValues?: any; oldValues?: any }, key: any) {
    var resolvedRows: any[] = [];
    if (object.newValues[key] instanceof Object) {
      Object.keys(object.newValues[key]).forEach((subKey) => {
        resolvedRows.push(getSubRow(object.newValues[key], subKey, key));
      });
    } else if (key === "accountId") {
      resolvedRows.push(
        <TableRow key={object + "_row_"}>
          <TableCell>{languageContext.getMessage(key)}</TableCell>
          <TableCell>{accountContext.getAccount(object.oldValues[key])?.name}</TableCell>
          <TableCell>{accountContext.getAccount(object.newValues[key])?.name}</TableCell>
        </TableRow>
      );
    } else if (key === "contractId") {
      resolvedRows.push(
        <TableRow key={object + "_row_"}>
          <TableCell>{languageContext.getMessage(key)}</TableCell>
          <TableCell>{contractContext.getContract(object.oldValues[key])?.name}</TableCell>
          <TableCell>{contractContext.getContract(object.newValues[key])?.name}</TableCell>
        </TableRow>
      );
    } else if (key === "changeOrderExpectedId") {
      resolvedRows.push(
        <TableRow key={object + "_row_"}>
          <TableCell>{languageContext.getMessage(key)}</TableCell>
          <TableCell>{changeOrderExpectedContext.getChangeOrderExpected(object.oldValues[key])?.name}</TableCell>
          <TableCell>{changeOrderExpectedContext.getChangeOrderExpected(object.newValues[key])?.name}</TableCell>
        </TableRow>
      );
    } else if ((object !== null || object !== undefined) && !keysToIgnore.includes(key)) {
      resolvedRows.push(
        <TableRow key={object + "_row_"}>
          <TableCell>{languageContext.getMessage(key)}</TableCell>
          <TableCell>{object.oldValues[key]?.toString()}</TableCell>
          <TableCell>{object.newValues[key].toString()}</TableCell>
        </TableRow>
      );
    }
    return resolvedRows;
  }

  function getSubRow(object: { newValues?: any; oldValues?: any }, subKey: any, mainKey: any) {
    var resolvedRows: any[] = [];
    if (object.newValues instanceof Object) {
      Object.keys(object).forEach((subKey) => {
        resolvedRows.push(getSubRow(object, subKey, mainKey));
      });
    } else if ((object !== null || object !== undefined) && !keysToIgnore.includes(subKey)) {
      resolvedRows.push(
        <TableRow key={object + "_subrow_" + subKey}>
          <TableCell>{`${languageContext.getMessage(mainKey)} / ${languageContext.getMessage(subKey)}`}</TableCell>
          <TableCell>{object}</TableCell>
          <TableCell>{object}</TableCell>
        </TableRow>
      );
    }
    return resolvedRows;
  }

  return (
    <>
      {openContent && (
        <Dialog
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setOpenContent(false);
            }
          }}
          open={openContent}
          maxWidth={"md"}
          fullWidth={true}
        >
          <DialogTitle>
            {languageContext.getMessage("changes")}
            <IconButton
              onClick={() => setOpenContent(false)}
              title={languageContext.getMessage("cancel")}
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* <Grid container spacing={1}>
              <Grid item xs={12}> */}
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>{languageContext.getMessage("variable")}</b>
                  </TableCell>
                  <TableCell>
                    <b>{languageContext.getMessage("oldValue")}</b>
                  </TableCell>
                  <TableCell>
                    <b>{languageContext.getMessage("newValue")}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(detailToShow.newValues)?.map((key, index) => {
                  return getRow(detailToShow, key);
                })}
                <TableRow>
                  <TableCell>
                    <b>{languageContext.getMessage("forecastLong")}</b>
                  </TableCell>
                  <TableCell>{detailToShow.isUpdatedWithCorrectForecast ? displayCurrency(detailToShow.currentForecast) : languageContext.getMessage("forecastCalculationInProgress")}</TableCell>
                  <TableCell>{detailToShow.isUpdatedWithCorrectForecast ? displayCurrency((detailToShow.deltaForecast ?? 0) + (detailToShow.currentForecast ?? 0)) : languageContext.getMessage("forecastCalculationInProgress")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  <b>Sluttprognose før endring</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {displayCurrency(detailToShow.currentForecast)}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  <b>Endring i sluttprognose</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {displayCurrency(detailToShow.deltaForecast)}
              </Grid>
            </Grid> */}
          </DialogContent>
          <DialogActions>
            <Box style={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <Box style={{ flex: "1 1 auto" }} />
              <Button onClick={() => setOpenContent(false)} variant="outlined">
                {languageContext.getMessage("close")}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            tableRef={tableRef}
            icons={tableIcons}
            onRowClick={(event, rowData) => {
              onRowClick(rowData);
            }}
            localization={materialTableLanguageContext.getLocalization()}
            columns={[
              {
                title: <b>{languageContext.getMessage("documentType")}</b>,
                field: "documentType",
                render: (logItem) => languageContext.getMessage(logItem.documentType as any),
              },
              {
                title: <b>{languageContext.getMessage("name")}</b>,
                field: "name",
                render: (logItem) => getChangeName(logItem),
              },
              {
                title: <b>{languageContext.getMessage("changeType")}</b>,
                field: "changeType",
                render: (logItem) => languageContext.getMessage(logItem.changeType as any),
              },
              {
                title: <b>{languageContext.getMessage("changedBy")}</b>,
                field: "changedBy",
                render: (logItem) => users.find((user) => user.id === logItem.changedBy)?.name ?? "SYSTEM",
              },
              {
                title: <b>{languageContext.getMessage("timeStamp")}</b>,
                field: "timeStamp",
                type: "date",
              },
              {
                title: <b>{languageContext.getMessage("forecastLong")}</b>,
                field: "content",
                render: (changeLog) => {
                  var parsedContent = JSON.parse(changeLog.content ?? "");
                  return parsedContent.isUpdatedWithCorrectForecast ? displayCurrency((parsedContent.currentForecast ?? 0) + (parsedContent.deltaForecast ?? 0)) : languageContext.getMessage("forecastCalculationInProgress");
                },
              },
              {
                title: <b>Delta</b>,
                field: "content",
                render: (changeLog) => {
                  var parsedContent = JSON.parse(changeLog.content ?? "");
                  return displayCurrency(parsedContent.deltaForecast);
                },
              },
            ]}
            data={changeLogsToShow}
            // title={languageContext.getMessage("changeLog")}
            title={
              <Grid container wrap="nowrap" spacing={1} alignItems="center">
                <Grid item>
                  <Typography variant="h5" style={{ marginRight: "2em" }}>
                    {languageContext.getMessage("changeLog")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      var oldOffset = offset;
                      setOffset(oldOffset - 100);
                    }}
                    startIcon={<Update />}
                    disabled={offset < 100}
                    variant="outlined"
                  >
                    Hent nyere
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      var oldOffset = offset;
                      setOffset(oldOffset + 100);
                    }}
                    startIcon={<Restore />}
                    disabled={changeLogContext.changeLogCount < 100 || offset >= changeLogContext.changeLogCount}
                    variant="outlined"
                  >
                    Hent eldre
                  </Button>
                </Grid>
              </Grid>
            }
            onChangeRowsPerPage={(newPageSize: number) => {
              onPageChange(0, newPageSize);
            }}
            options={{
              padding: "dense",
              columnsButton: false,
              paging: false,
              // pageSizeOptions: [10, 50, 100],
              pageSize: 100,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "75vh",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ChangeLogView;
