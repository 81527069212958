import { DocumentType, Forecast, ForecastReport, Forecasts } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { LanguageContext } from "../language/interfaces";

export const receiveForecasts = (
    currentForecastSearch: Forecasts | undefined,
    newForecasts: Forecasts | undefined, 
    languageContext: LanguageContext): Forecasts => {
    if (!newForecasts) {
        console.error(`Received undefined set of forecasts: ${newForecasts}`);
        return {};
    }
    newForecasts.fromDate = new Date(newForecasts.fromDate ?? 0);
    newForecasts.toDate = new Date(newForecasts.toDate ?? 0);
    newForecasts.forecasts = newForecasts.forecasts ?? [];
    newForecasts.monthlyForecastReports = newForecasts.monthlyForecastReports ?? [];
    newForecasts.projectId = newForecasts.parentDocumentType === DocumentType.PROJECT ? newForecasts.parentDocumentId : undefined;
    newForecasts.accountId = newForecasts.parentDocumentType === DocumentType.ACCOUNT ? newForecasts.parentDocumentId : undefined;
    receiveForecastsStates(newForecasts.forecasts, currentForecastSearch, newForecasts.projectId, newForecasts.accountId, languageContext);
    newForecasts.forecastsGroups?.forEach(forecastGroup => receiveForecastsStates(forecastGroup, currentForecastSearch, newForecasts.projectId, newForecasts.accountId, languageContext));
    receiveMonthlyForecastReports(newForecasts.monthlyForecastReports, currentForecastSearch, newForecasts.projectId, newForecasts.accountId, languageContext);
    newForecasts.monthlyForecastGroupsReports?.forEach(monthlyForecastGroupReport => receiveMonthlyForecastReports(monthlyForecastGroupReport, currentForecastSearch, newForecasts.projectId, newForecasts.accountId, languageContext));
    return newForecasts;
}

export const receiveForecastsStates = (forecasts: Forecast[], 
                                       currentForecastSearch: Forecasts | undefined, 
                                       projectId: Guid | undefined, 
                                       accountId: Guid | undefined, 
                                       languageContext: LanguageContext): void => {
    let i = 0;
    while (i < forecasts.length) {
        const forecast = forecasts[i];
        receiveForecast(forecast, projectId, accountId, languageContext);
        if (!forecast.date || (currentForecastSearch?.fromDate && currentForecastSearch.fromDate > forecast.date) || 
            (currentForecastSearch?.toDate && currentForecastSearch.toDate < forecast.date)) {
            forecasts.splice(i, 1);
        }
        else {
            i++;
        }
    }
}

export const receiveMonthlyForecastReports = (monthlyForecastReports: ForecastReport[][], 
                                              currentForecastSearch: Forecasts | undefined, 
                                              projectId: Guid | undefined, 
                                              accountId: Guid | undefined, 
                                              languageContext: LanguageContext): void => {
    let i = 0;
    monthlyForecastReports.forEach(forecastReports => {
        i = 0;
        while (i < forecastReports.length) {
            const forecastReport = forecastReports[i];
            receiveForecastReport(forecastReport, projectId, accountId, languageContext);
            if (!forecastReport.date || (currentForecastSearch?.fromDate && currentForecastSearch.fromDate > forecastReport.date) || 
                (currentForecastSearch?.toDate && currentForecastSearch.toDate < forecastReport.date)) {
                forecastReports.splice(i, 1);
            }
            else {
                i++;
            }
        }
    })
    i = 0;
    while (i < monthlyForecastReports.length) {
        if (monthlyForecastReports[i].length === 0) {
            monthlyForecastReports.splice(i, 1);
        }
        else {
            i++;
        }
    }
}

export const receiveForecast = (forecast: Forecast | undefined, projectId: Guid | undefined, accountId: Guid | undefined, languageContext: LanguageContext) => {
    if (!forecast) {
        return;
    }
    forecast.date = new Date(forecast.date ?? 0);
    forecast.projectId = projectId;
    forecast.accountId = accountId;
    forecast.accountGroup = (forecast.accountGroup && forecast.accountGroup.length > 0) ? forecast.accountGroup : languageContext.getMessage('general');
}

export const receiveForecastReport = (forecastReport: ForecastReport | undefined, projectId: Guid | undefined, accountId: Guid | undefined, languageContext: LanguageContext) => {
    if (!forecastReport) {
        return;
    }
    forecastReport.date = new Date(forecastReport.date ?? 0);
    forecastReport.projectId = projectId;
    forecastReport.accountId = accountId;
    forecastReport.group = (forecastReport.group && forecastReport.group.length > 0) ? forecastReport.group : languageContext.getMessage('general');
}

export const FORECAST_QUERY_ELEMENT: string = `
    status
    date
    accountGroup
    accountBudget
    contractBudget
    contractProcurement
    contractBudgetUsedInCostScope
    contractProcurementUsedInCostScope
    contractCostScope
    contractCostScopeUsedInForecast
    sumContractInvoicedAmount
    sumContractInvoicedWithheldAmount
    changeOrderSubContractorCosts
    changeOrderSubContractorApprovedCosts
    changeOrderSubContractorNotApprovedCosts
    changeOrderLandlordCosts
    changeOrderLandlordApprovedCosts
    changeOrderLandlordNotApprovedCosts
    changeOrderExpectedCosts
    changeOrderCounterClaimCosts
    netAccountBudget
    revisedAccountBudget
    riskAdjustedCosts
    adjustmentCosts
    forecast
    forecastDeviationFromRevisedAccountBudget
    riskAdjustedForecast
    forecastWithSurcharge
    projectSurcharge
    surchargePercent`

export const FORECAST_REPORT_QUERY_ELEMENT: string = `
    parentDocumentId
    parentDocumentType
    date
    group
    budget
    revisedBudget
    forecast
    forecastDeviationFromBudget
    previousForecast
    forecastChange
    forecastWithSurcharge
    projectSurcharge
    surchargePercent
    projectBudgetSurcharge
    projectRevisedBudgetSurcharge
    projectForecastDeviationSurcharge
    projectPreviousForecastSurcharge
    projectForecastChangeSurcharge
    projectBudgetWithSurcharge
    projectRevisedBudgetWithSurcharge
    projectForecastDeviationWithSurcharge
    projectPreviousForecastWithSurcharge
    projectForecastChangeWithSurcharge`