import { Step, StepLabel, Stepper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    step: {
      color: theme.palette.text.secondary,
      "&$active": {
        color: theme.palette.primary.main
      },
      "&$completed": {
        color: theme.palette.primary.dark,
      }
    },
    active: {},
    completed: {}
  }),
);
 
type props = {
  steps: string[];
  activeStep: number; 
}

const ContentStepper: React.FC<props> = ({
  steps, 
  activeStep,
}) => {
  
  const classes = useStyles();

  return (<>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel className={classes.step} StepIconProps={{
                  classes: {
                    root: classes.step,
                    active: classes.active,
                    completed: classes.completed
                  }
                }}>{label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </>);
}

export default ContentStepper