import { Grid, makeStyles, withTheme } from "@material-ui/core";
import React from "react";
import CrewListExpectedAccumulatedExpenseGraph from "../../component/crewListComponents/CrewListExpectedAccumulatedExpenseGraph";
import CrewListTotalMonthlyNumberOfPersonellBarGraph from "../../component/crewListComponents/CrewListTotalMonthlyNumberOfPersonellBarGraph";
import CrewListTotalMonthlyPercentageBarGraph from "../../component/crewListComponents/CrewListTotalMonthlyPercentageBarGraph";

const useStyles = makeStyles(() => ({
    card: {
        padding: '1em'
    }
}));

type props = {
    from: Date | undefined;
    to: Date | undefined;
}

const CrewListOverview: React.FC<props> = ({
    from,
    to}) => {

    return (<>
        <Grid container item direction="row" spacing={1}>
            <Grid item xs={12}>
                <CrewListTotalMonthlyPercentageBarGraph 
                    from={from}
                    to={to}
                />
            </Grid>
            <Grid item xs={12}>
                <CrewListTotalMonthlyNumberOfPersonellBarGraph 
                    from={from}
                    to={to}
                />
            </Grid>
            <Grid item xs={12}>
                <CrewListExpectedAccumulatedExpenseGraph 
                    from={from}
                    to={to}
                />
            </Grid>
        </Grid>
    </>
    );
}

export default withTheme(CrewListOverview);