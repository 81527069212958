import { Grid, Typography, CircularProgress, Button } from "@material-ui/core";
import { ChartData, ChartDataSets, ChartOptions } from "chart.js";
import React from "react";
import { InvoiceTabs, sortInvoiceByDate, useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { LanguageContext, useLanguageContext } from "../../contexts/language/LanguageContext";
import { Invoice, CurrencyType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { getRandomColorFromString } from "../../utils/randomTools";
import BarGraph from "../graphComponents/BarGraph";
import LineGraph from "../graphComponents/LineGraph";
import { useUrlContext } from "../../contexts/url/urlContext";
import { ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { AccountContext, useAccountContext } from "../../contexts/account/accountContext";

const data = (invoices: Invoice[], languageContext: LanguageContext, accountContext: AccountContext, showAccumulatedAmounts: boolean): ChartData => {
  invoices = invoices.sort(sortInvoiceByDate);
  const groupedInvoicesOnAccountNumber: Dictionary<Invoice[]> = {};
  invoices.forEach((invoice) => {
    let key = accountContext.getAccount(invoice.accountId)?.accountNumber ?? (invoice.accountNumber ?? "unkown");
    if (showAccumulatedAmounts) {
      key = `${languageContext.getMessage("accumulated")} ${languageContext.getMessage("invoices")}`;
    }
    if (!(key in groupedInvoicesOnAccountNumber)) {
      groupedInvoicesOnAccountNumber[key] = [];
    }
    groupedInvoicesOnAccountNumber[key].push(invoice);
  });

  const dataSets: ChartDataSets[] = [];
  let accumulatedAmount = 0.0;
  for (const key in groupedInvoicesOnAccountNumber) {
    const color = getRandomColorFromString(key);
    const data = groupedInvoicesOnAccountNumber[key].map((invoice) => {
      const amount = invoice.amount ?? 0;
      accumulatedAmount += amount;
      return {
        x: invoice.voucherDate,
        y: showAccumulatedAmounts ? accumulatedAmount : amount,
      };
    });
    const dataSet: ChartDataSets = {
      label: key,
      fill: !showAccumulatedAmounts,
      lineTension: 0,
      backgroundColor: color,
      borderColor: color,
      pointBorderColor: color,
      pointBackgroundColor: color,
      pointBorderWidth: 1,
      pointHoverRadius: 10,
      pointRadius: 5,
      pointHitRadius: 10,
      data: data,
    };
    dataSets.push(dataSet);
  }

  return {
    datasets: dataSets,
  };
};

let options = (languageContext: LanguageContext, currency?: String): ChartOptions => {
  currency = currency ?? CurrencyType.UNDEFINED;
  const amountLabel = languageContext.getMessage("amount") + ` (${currency})`;
  return {
    legend: {
      position: "bottom",
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          type: "time",
          display: true,
          scaleLabel: {
            display: true,
            labelString: languageContext.getMessage("date"),
          },
          time: {
            isoWeekday: false,
            unit: "day",
            stepSize: 10,
          },
          offset: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: amountLabel,
          },
        },
      ],
    },
  };
};

type props = {
  invoices: Invoice[];
  showAccumulatedAmounts?: boolean;
  height?: number;
  onShowInvoicesFilter?: Dictionary<string | number | Date | undefined>;
  fullWidth?: boolean;
};

const InvoiceGraph: React.FC<props> = ({ invoices, showAccumulatedAmounts, height, onShowInvoicesFilter, fullWidth }) => {
  showAccumulatedAmounts = showAccumulatedAmounts ?? true;
  height = height ?? 400;

  const languageContext = useLanguageContext();
  const invoiceContext = useInvoiceContext();
  const accountContext = useAccountContext();
  const urlContext = useUrlContext();
  const menuContext = useMenuContext();
  const currency = CurrencyType.NOK;

  const chartData = data(invoices, languageContext, accountContext, showAccumulatedAmounts);

  const handleShowInvoices = () => {
    if (!onShowInvoicesFilter) {
      return;
    }
    const urlState = urlContext.getUrlState();
    const newUrlState = {
      ...urlState,
      ...onShowInvoicesFilter,
    };
    menuContext.setSelectedApplicationRoute(ApplicationRouteId.Invoice, newUrlState as Dictionary<string | number | Date>);
  };

  const chartTitle = (
    <>
      <Grid container wrap="nowrap" spacing={2} alignItems="flex-end">
        <Grid item>
          <Typography variant="h6">
            {showAccumulatedAmounts ? `${languageContext.getMessage("accumulated")} ${languageContext.getMessage("invoices")}` : languageContext.getMessage("invoices")}
          </Typography>
        </Grid>
        {onShowInvoicesFilter && (
          <Grid item>
            <Button variant="contained" onClick={() => handleShowInvoices()} size="small">
              {languageContext.getMessage("showInvoices")}
            </Button>
          </Grid>
        )}
        {invoiceContext.loadingInvoices && (
          <Grid item>
            <CircularProgress size={25} />
          </Grid>
        )}
      </Grid>
    </>
  );

  if (showAccumulatedAmounts) {
    return <LineGraph chartComponentProps={{ data: chartData, height: height }} chartOptions={options(languageContext, currency)} chartTitle={chartTitle} chartSubHeader={""} />;
  }

  return <BarGraph chartComponentProps={{ data: chartData, height: height }} chartOptions={options(languageContext, currency)} chartTitle={chartTitle} chartSubHeader={""} />;
};

export default InvoiceGraph;
