import { Grid, withTheme } from "@material-ui/core";
import React from "react";
import { Account } from "../../contracts/contracts";
import ForecastList from "../../component/forecastComponents/ForecastList";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import AccountList from "../../component/accountComponents/AccountList";

type props = {
    onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
    onSelectedGroupChange: (newSelectedAccountGroup: string | undefined) => void;
    onSelectedProjectChange: () => void;
}

const ForecastAllView: React.FC<props> = ({
    onSelectedAccountChange, 
    onSelectedGroupChange,
    onSelectedProjectChange}) => {

    const accountContext = useAccountContext();
    const projectContext = useProjectContext();
    
    const project = projectContext.getSelectedProject();
    const forecasts = accountContext.getAccounts().map(account => account.forecast ?? {
        projectId: account.projectId,
        accountId: account.id
    });

    return (<>
        <Grid container item direction="row" spacing={1}>
            <Grid item xs={12}>
                <AccountList
                    onClickAccount={(accountId) => onSelectedAccountChange(accountContext.getAccount(accountId))} 
                    showForecasts={true}/>
            </Grid>
            {/* {forecasts && project?.forecastGroups &&
                <Grid item xs={12}>
                    <ForecastList 
                        forecasts={project?.forecastGroups}
                        isGroupForecasts={true}
                        onSelectedAccountGroupChange={onSelectedGroupChange}
                    />
                </Grid>
            } */}
            {forecasts && project?.forecast &&
                <Grid item xs={12}>
                    <ForecastList 
                        forecasts={[project.forecast]}
                        isProjectForecasts={true}
                        onSelectedAccountChange={onSelectedProjectChange}
                    />
                </Grid>
            }
        </Grid>
    </>
    );
}

export default withTheme(ForecastAllView);