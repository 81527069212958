import React, { createContext, useContext, useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_CREW_LIST, getCrewListGraphqlQueryOptions } from './crewListQueries'
import { useAuthContext } from "../../auth/authContext";
import { CrewList } from "../../../contracts/contracts";

export interface CrewListQueriesContext {
    fetchedCrewList: CrewList[],
    queryCrewList: (crewList: Partial<CrewList>) => void,
}

const CrewListQueriesContext = createContext<CrewListQueriesContext>(null as unknown as CrewListQueriesContext);

export const CrewListQueriesContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedCrewList, setFetchedCrewList] = useState<CrewList[]>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_CREW_LIST);

    const queryCrewList = (crewList: Partial<CrewList>): void => {
        const queryOptions = getCrewListGraphqlQueryOptions(crewList);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse?.data?.crewLists) {
            const newFetchedCrewList: CrewList[] = queryResponse.data.crewLists;
            setFetchedCrewList(newFetchedCrewList);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedCrewList([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const crewListQueriesContext: CrewListQueriesContext = {
        fetchedCrewList,
        queryCrewList,
    };

    return (
        <CrewListQueriesContext.Provider value={crewListQueriesContext}>
            {children}
        </CrewListQueriesContext.Provider>
    );
}

export const useCrewListQueriesContext = (): CrewListQueriesContext => {
    return useContext(CrewListQueriesContext);
}
