import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getEmptyGuid } from '../../utils/guidTools';



const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  fileSelect: {
    color: theme.palette.primary.light,
  },
  dropAreaFileSelect: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "1px",
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: "1px",
  },
  dropAreaOverlay: {
    position: "absolute",
    top: "3px",
    left: "3px",
    right: "3px",
    bottom: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    backgroundColor: "inherit",
  },
  dropAreaLabel: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "28px",
    outline: "1px dashed",
    outlineOffset: "-1px",
    background: "#DDDDDD",
    borderRadius: "3px",
    padding: "24px",
  },
}));


type props = {
  text: string;
  dragOverText: string | ((fileCount: number) => string);
  multiple?: boolean;
  disabled?: boolean;
  onFiles(acceptedFiles: File[], rejectedFiles: File[]): void;
  allowedExtensions?: string[];
};

const isValidFiletype = (file: File, allowedExtensions?: string[]) => {
  if (!allowedExtensions) {
    return true;
  }
  const fileName = file?.name || "";
  return allowedExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
};

const FileDragAndDrop: React.FC<props> = ({
  disabled = false,
  multiple = false,
  text,
  dragOverText,
  allowedExtensions,
  onFiles,
}) => {
  const classes = useStyles();
  const id = getEmptyGuid()
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const dropAreaRef = useRef<HTMLLabelElement | null>(null);
  const dragEnterTarget = useRef<EventTarget | null>(null);
  const [fileCount, setFileCount] = useState<number>(0);
  const dragOverTextFn = useMemo(() => {
    if (typeof dragOverText === "function") {
      return dragOverText;
    }
    return () => dragOverText;
  }, [dragOverText]);
  const handleFiles = useCallback(
    (files: File[]) => {
      const rejectedFiles: File[] = [];
      const acceptedFiles: File[] = [];
      files.forEach((file) => {
        if (isValidFiletype(file, allowedExtensions)) {
          acceptedFiles.push(file);
        } else {
          rejectedFiles.push(file);
        }
      });
      onFiles(acceptedFiles, rejectedFiles);
    },
    [onFiles, allowedExtensions]
  );
  const dropAreaProps = useMemo(
    () => ({
      ref: dropAreaRef,
      onDragEnter: (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.persist();
        e.stopPropagation();

        if (!dragEnterTarget.current) {
          setFileCount(e?.dataTransfer?.items.length);
          setIsDragOver(true);
        }
        dragEnterTarget.current = e.target;
      },
      onDragLeave: (e: React.DragEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();

        if (dragEnterTarget.current === e.target) {
          dragEnterTarget.current = null;
          setFileCount(0);
          setIsDragOver(false);
        }
      },
      onDragOver: (e: React.DragEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
      },
      onDrop: (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        dragEnterTarget.current = null;
        setFileCount(0);
        setIsDragOver(false);

        const files = e?.dataTransfer?.files;
        if (files && files.length) {
          handleFiles(Array.from(files));
        }
      },
    }),
    [handleFiles]
  );

  const inputProps = useMemo(
    () => ({
      type: "file",
      disabled,
      multiple,
      accept: allowedExtensions?.join(",") || undefined,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        const files = e?.target?.files;
        if (files && files.length) {
          handleFiles(Array.from(files));
        }
        e.target.value = "";
      },
    }),
    [multiple, allowedExtensions, handleFiles, disabled]
  );

  useEffect(() => {
    const onDocumentDragOver = (e: DragEvent) => {
      if (dropAreaRef.current && dropAreaRef.current === e.target) {
        return;
      }
      e.preventDefault();
    };
    const onDocumentDrop = (e: DragEvent) => {
      e.preventDefault();
    };
    document.addEventListener("dragover", onDocumentDragOver, false);
    document.addEventListener("drop", onDocumentDrop, false);

    return () => {
      document.removeEventListener("dragover", onDocumentDragOver);
      document.removeEventListener("drop", onDocumentDrop);
    };
  }, []);
  return (
    <>
      <div>
        <input {...inputProps} id={id} className={classes.dropAreaFileSelect} />
        <label {...dropAreaProps} htmlFor={id} className={classes.dropAreaLabel} >
          {isDragOver && (
            <div className={classes.dropAreaOverlay}>
              <Typography>{dragOverTextFn(fileCount)}</Typography>
            </div>
          )}
          {/* <Import /> */}
          <Typography>{text}</Typography>
        </label>
      </div>
    </>
  );
};

export default FileDragAndDrop;
