import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Project, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";

export const MUTATE_PROJECT: DocumentNode = gql`
    mutation project(
            $id: UUID
            $state: ActiveState
            $name: String
            $externalProvider: String
            $externalId: String
            $projectStart: Date
            $projectEnd: Date
            $surchargePercent: Float
            $riskConsequenceLimit: Float
        ) {
        mutateProject(
            id: $id
            state: $state
            name: $name
            externalProvider: $externalProvider
            externalId: $externalId
            projectStart: $projectStart
            projectEnd: $projectEnd
            surchargePercent: $surchargePercent
            riskConsequenceLimit: $riskConsequenceLimit
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getProjectsGraphqlMutationOptions = (project: Partial<Project>): QueryHookOptions => {
    project.state = project.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (project.id !== undefined) options.variables['id'] = project.id
    if (project.state !== undefined) options.variables['state'] = project.state
    if (project.name !== undefined) options.variables['name'] = project.name
    if (project.externalProvider !== undefined) options.variables['externalProvider'] = project.externalProvider
    if (project.externalId !== undefined) options.variables['externalId'] = project.externalId
    if (project.projectStart !== undefined) options.variables['projectStart'] = toISODate(project.projectStart)
    if (project.projectEnd !== undefined) options.variables['projectEnd'] = toISODate(project.projectEnd)
    if (project.surchargePercent !== undefined) options.variables['surchargePercent'] = project.surchargePercent
    if (project.riskConsequenceLimit !== undefined) options.variables['riskConsequenceLimit'] = project.riskConsequenceLimit
    return options;
}