import React, { createContext, useContext, useEffect, useState } from "react";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { ChangeOrderSubContractor, ActiveState, ElementStatusEnum, ChangeOrderStatus, RelationType } from "../../contracts/contracts";
import { useProjectContext } from "../project/projectContext";
import { useLanguageContext } from "../language/LanguageContext";
import { ChangeOrderSubContractorMutationsContextProvider, useChangeOrderSubContractorMutationsContext } from "./mutations/changeOrderSubContractorMutationsContext";
import { ChangeOrderSubContractorQueriesContextContext, useChangeOrderSubContractorQueriesContext } from "./queries/changeOrderSubContractorQueriesContext";
import { ChangeOrderSubContractorSubscriptionsContextProvider, useChangeOrderSubContractorSubscriptionsContext } from "./subscriptions/changeOrderSubContractorSubscriptionsContext";
import { useUrlContext } from "../url/urlContext";
import { useAuthContext } from "../auth/authContext";
import { Guid } from "../../utils/common-types";
import { filterAbles } from "react-moveable/declaration/utils";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { queryTemplateEngineToProduceChangeOrderSubContractorExcelList } from "./ChangeOrderSubContractorExportTools";
import { useAccountContext } from "../account/accountContext";
import { useContractContext } from "../contract/contractContext";

export interface ChangeOrderSubContractorContext {
    getChangeOrderSubContractorSearch: () => ChangeOrderSubContractor,
    searchChangeOrderSubContractors: (changeOrderSubContractorSearch: ChangeOrderSubContractor) => void,
    getChangeOrderSubContractorsByAccountId: (accountId?: Guid) => Array<ChangeOrderSubContractor>, //Remove this if needed. Only used to get data for changeOrderSubContractorMiniList
    getChangeOrderSubContractors: (contractId?: string, status?: ChangeOrderStatus, accountId?: string) => Array<ChangeOrderSubContractor>,
    getChangeOrderSubContractor: (id?: string) => ChangeOrderSubContractor | undefined,
    updateChangeOrderSubContractor:  (changeOrderSubContractor: ChangeOrderSubContractor) => void,
    convertChangeOrderStatusToString: (status?: ChangeOrderStatus) => string,
    convertChangeOrderStatusToElementStatus: (status?: ChangeOrderStatus) => ElementStatusEnum,
    getContractors: () => Array<string>,
    downloadChangeOrderSubContractors: (changeOrderSubContractors: ChangeOrderSubContractor[]) => void,
}

const ChangeOrderSubContractorContext = createContext<ChangeOrderSubContractorContext>(null as unknown as ChangeOrderSubContractorContext);

export const sortChangeOrderSubContractorByName = (a: ChangeOrderSubContractor, b: ChangeOrderSubContractor, reverse?: boolean) => {
    if ((a?.name ?? '') < (b?.name ?? '')) { return reverse ? 1 : -1; }
    if ((a?.name ?? '') > (b?.name ?? '')) { return reverse ? -1 : 1; }
    return 0;
}

export const sortChangeOrderSubContractorByDate = (a: ChangeOrderSubContractor, b: ChangeOrderSubContractor) => {
    if ((a?.received ?? '') < (b?.received ?? '')) { return -1; }
    if ((a?.received ?? '') > (b?.received ?? '')) { return 1; }
    return 0;
}

export const sortChangeOrderSubContractorByGroup = (a: ChangeOrderSubContractor, b: ChangeOrderSubContractor) => {
    if ((a.group ?? '') < (b.group ?? '')) { return -1; }
    if ((a.group ?? '') > (b.group ?? '')) { return 1; }
    return 0;
}

export const ChangeOrderSubContractorContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ChangeOrderSubContractorMutationsContextProvider>
            <ChangeOrderSubContractorQueriesContextContext>
                <ChangeOrderSubContractorSubscriptionsContextProvider>
                    <ChangeOrderSubContractorSubContextProvider>
                        {children}
                    </ChangeOrderSubContractorSubContextProvider>
                </ChangeOrderSubContractorSubscriptionsContextProvider>
            </ChangeOrderSubContractorQueriesContextContext>
        </ChangeOrderSubContractorMutationsContextProvider>
    );
}

export const ChangeOrderSubContractorSubContextProvider : React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const accountContext = useAccountContext();
    const contractContext = useContractContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const changeOrderSubContractorMutationsContext = useChangeOrderSubContractorMutationsContext();
    const changeOrderSubContractorQueriesContext = useChangeOrderSubContractorQueriesContext();
    const changeOrderSubContractorSubscriptionsContext = useChangeOrderSubContractorSubscriptionsContext();
    const [currentChangeOrderSubContractorSearch, setCurrentChangeOrderSubContractorSearch] = useState<ChangeOrderSubContractor | undefined>(undefined);
    const [changeOrderSubContractors, setChangeOrderSubContractors] = useState<Array<ChangeOrderSubContractor>>([]);

    const mergeChangeOrderSubContractors = (oldChangeOrderSubContractors: Array<ChangeOrderSubContractor>, newChangeOrderSubContractors: Array<ChangeOrderSubContractor>): Array<ChangeOrderSubContractor> => {
        let updatedChangeOrderSubContractors = oldChangeOrderSubContractors.slice();
        if (!newChangeOrderSubContractors) {
            console.error(`Received undefined set of changeOrderSubContractors: ${newChangeOrderSubContractors}`);
            return [];
        }
        newChangeOrderSubContractors.forEach(newChangeOrderSubContractor => {
            if (newChangeOrderSubContractor.projectId !== currentChangeOrderSubContractorSearch?.projectId) {
                return;
            }
            newChangeOrderSubContractor.created = new Date(newChangeOrderSubContractor.created ?? 0);
            newChangeOrderSubContractor.received = new Date(newChangeOrderSubContractor.received ?? 0);
            newChangeOrderSubContractor.answered = new Date(newChangeOrderSubContractor.answered ?? 0);
            newChangeOrderSubContractor.elementStatus = convertChangeOrderStatusToElementStatus(newChangeOrderSubContractor.status);
            const index = updatedChangeOrderSubContractors.findIndex(changeOrderSubContractor => changeOrderSubContractor.id === newChangeOrderSubContractor.id);
            if (index >= 0) {
                if (newChangeOrderSubContractor.state === ActiveState.ACTIVE) {
                    updatedChangeOrderSubContractors[index] = newChangeOrderSubContractor;
                }
                else {
                    updatedChangeOrderSubContractors.splice(index, 1);
                }
            } 
            else {
                if (newChangeOrderSubContractor.state === ActiveState.ACTIVE) {
                    updatedChangeOrderSubContractors.push(newChangeOrderSubContractor); 
                }
            }
        });
        return updatedChangeOrderSubContractors.sort(sortChangeOrderSubContractorByName).sort(sortChangeOrderSubContractorByGroup);
    }

    const getChangeOrderSubContractorSearch = (): ChangeOrderSubContractor => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
        const accountId = urlState.accountId ? urlState.accountId as string : undefined;
        const contractId = urlState.contractId ? urlState.contractId as string : undefined;
        return {
            projectId: projectId,
            accountId: accountId,
            contractId: contractId,
        }
    }

    const searchChangeOrderSubContractors = (changeOrderSubContractorSearch: ChangeOrderSubContractor): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(changeOrderSubContractorSearch, currentChangeOrderSubContractorSearch);
        matched = matched && changeOrderSubContractorSearch?.projectId === currentChangeOrderSubContractorSearch?.projectId;
        matched = matched && changeOrderSubContractorSearch?.accountId === currentChangeOrderSubContractorSearch?.accountId;
        matched = matched && changeOrderSubContractorSearch?.contractId === currentChangeOrderSubContractorSearch?.contractId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && !matched) {
            setCurrentChangeOrderSubContractorSearch(changeOrderSubContractorSearch);
            setChangeOrderSubContractors([]);
        }
    }

    const getChangeOrderSubContractors = (contractId?: string, status?: ChangeOrderStatus, accountId?: string): Array<ChangeOrderSubContractor> => {
        let resolvedChangeOrderSubContractors = changeOrderSubContractors;

        if (contractId) {
            resolvedChangeOrderSubContractors = resolvedChangeOrderSubContractors.filter(changeOrderSubContractor => changeOrderSubContractor.contractId === contractId);
        }

        if (status) {
            resolvedChangeOrderSubContractors = resolvedChangeOrderSubContractors.filter(changeOrderSubContractor => changeOrderSubContractor.status === status);
        }

        if (accountId) {
            resolvedChangeOrderSubContractors = resolvedChangeOrderSubContractors.filter(changeOrderSubContractor => changeOrderSubContractor.accountId === accountId);
        }

        return resolvedChangeOrderSubContractors
    }

    const getChangeOrderSubContractorsByAccountId = (accountId?: Guid): ChangeOrderSubContractor[] => {
        if(!accountId) return []; 
        return changeOrderSubContractors.filter(changeOrderSubContractor => changeOrderSubContractor.accountId === accountId);
    }

    const getChangeOrderSubContractor = (id?: string) => {
        return changeOrderSubContractors.find(changeOrderSubContractor => changeOrderSubContractor.id === id);
    }

    const updateChangeOrderSubContractor = (changeOrderSubContractor: ChangeOrderSubContractor) => {
        changeOrderSubContractorMutationsContext.mutateChangeOrderSubContractor(changeOrderSubContractor, (documentId, variables) => {
            changeOrderSubContractor = {...changeOrderSubContractor, ...variables};
            changeOrderSubContractor.id = documentId;
            setChangeOrderSubContractors(mergeChangeOrderSubContractors(changeOrderSubContractors, [changeOrderSubContractor]));
        });
    }

    const convertChangeOrderStatusToString = (status?: ChangeOrderStatus): string => {
        switch (status) {
            case ChangeOrderStatus.APPROVED: return languageContext.getMessage('approved');
            case ChangeOrderStatus.NOT_APPROVED: return languageContext.getMessage('notApproved');
            case ChangeOrderStatus.NOT_PROCESSED: return languageContext.getMessage('notProcessed');
        }
        return languageContext.getMessage('notProcessed')
    }

    const convertChangeOrderStatusToElementStatus = (status?: ChangeOrderStatus): ElementStatusEnum => {
        switch (status) {
            case ChangeOrderStatus.APPROVED: return ElementStatusEnum.SUCCESS;
            case ChangeOrderStatus.NOT_APPROVED: return ElementStatusEnum.CANCEL;
            case ChangeOrderStatus.NOT_PROCESSED: return ElementStatusEnum.INFORMATION;
        }

        return ElementStatusEnum.ALERT;
    }

    const getContractors = (): Array<string> => {
        const contractors: Array<string> = []
        getChangeOrderSubContractors().forEach(changeOrderSubContractor => {
            if (changeOrderSubContractor.contractor && !contractors.includes(changeOrderSubContractor.contractor)) {
                contractors.push(changeOrderSubContractor.contractor);
            }
        });
        return contractors;
    }

    const downloadChangeOrderSubContractors = (changeOrderSubContractors: ChangeOrderSubContractor[]): void => {
        queryTemplateEngineToProduceChangeOrderSubContractorExcelList(
            changeOrderSubContractors, 
            templateEngineQueriesContext, 
            languageContext, 
            accountContext, 
            contractContext);
    }

    useEffect(() => {
        if (currentChangeOrderSubContractorSearch) {
            changeOrderSubContractorQueriesContext.queryChangeOrderSubContractors(currentChangeOrderSubContractorSearch);
        }
    }, [currentChangeOrderSubContractorSearch]);
    
    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setChangeOrderSubContractors([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setChangeOrderSubContractors(mergeChangeOrderSubContractors([], changeOrderSubContractorQueriesContext.fetchedChangeOrderSubContractors));
    }, [changeOrderSubContractorQueriesContext.fetchedChangeOrderSubContractors]);

    useEffect(() => {
        setChangeOrderSubContractors(mergeChangeOrderSubContractors(changeOrderSubContractors, changeOrderSubContractorSubscriptionsContext.subscribedChangeOrderSubContractors));
    }, [changeOrderSubContractorSubscriptionsContext.subscribedChangeOrderSubContractors]);

    const changeOrderSubContractorContext: ChangeOrderSubContractorContext = {
        getChangeOrderSubContractorSearch,
        searchChangeOrderSubContractors,
        getChangeOrderSubContractors,
        getChangeOrderSubContractorsByAccountId,
        getChangeOrderSubContractor,
        updateChangeOrderSubContractor,
        convertChangeOrderStatusToString,
        convertChangeOrderStatusToElementStatus,
        getContractors,
        downloadChangeOrderSubContractors,
    };

    return (
        <ChangeOrderSubContractorContext.Provider value={ changeOrderSubContractorContext }>
            { children }
        </ChangeOrderSubContractorContext.Provider>
    );
}

export const useChangeOrderSubContractorContext = (): ChangeOrderSubContractorContext => {
    return useContext(ChangeOrderSubContractorContext);
}
