import React, { createContext, useContext, useEffect, useState } from "react";
import { DocumentType, StateDocument, CrewList } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface CrewListSubscriptionsContext {
    subscribedCrewList: Array<CrewList>,
}

const CrewListSubscriptionsContext = createContext<CrewListSubscriptionsContext>(null as unknown as CrewListSubscriptionsContext);

export const CrewListSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedCrewList, setSubscribedCrewList] = useState<Array<CrewList>>([]);

    const updateSubscribedCrewList = (stateDocuments: StateDocument[]) => {
        const subscribedCrewList = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.CREW_LIST && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as CrewList);
        setSubscribedCrewList(subscribedCrewList);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedCrewList([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedCrewList(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: CrewListSubscriptionsContext = {
        subscribedCrewList,
    };

    return (
        <CrewListSubscriptionsContext.Provider value={projectContext}>
            {children}
        </CrewListSubscriptionsContext.Provider>
    );
}

export const useCrewListSubscriptionsContext = (): CrewListSubscriptionsContext => {
    return useContext(CrewListSubscriptionsContext);
}
