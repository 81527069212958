import { Grid, withTheme } from '@material-ui/core';
import React, { useState } from 'react';
import ViewHeading from '../../component/viewComponents/ViewHeading';
import { Invoice } from '../../contracts/contracts';
import InvoiceList from '../../component/invoiceComponents/InvoiceList'
import { Guid } from '../../utils/common-types';
import InvoicesFilterTab from '../../component/invoiceComponents/InvoicesFilterTab';

type props = {
    onClickInvoice?: (invoiceId: Guid) => void,
    showProcessedInvoices?: boolean,
    showRejectedInvoices?: boolean,
};

const AllInvoicesView: React.FC<props> = ({
    onClickInvoice,
    showProcessedInvoices,
    showRejectedInvoices,
}) => {
    const [invoices, setInvoices] = useState<Invoice[]>([]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ViewHeading>
                    <Grid container spacing={1}>
                        <InvoicesFilterTab 
                            onInvoicesUpdate={setInvoices} 
                            showApprovedInvoices={showRejectedInvoices ? false : true}
                            showProcessedInvoices={showProcessedInvoices}
                            showRejectedInvoices={showRejectedInvoices} />
                    </Grid>
                </ViewHeading>
            </Grid>
            <Grid item xs={12}>
                <InvoiceList 
                    invoices={invoices} 
                    onClickInvoice={onClickInvoice} 
                />
            </Grid>
        </Grid>
    );
}

export default withTheme(AllInvoicesView);