import { Button, CircularProgress, Grid } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import { useAuthContext } from '../../contexts/auth/authContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { checkProjectAccess } from '../../contexts/userRole/userRoleTools';
import { Project, RoleType } from '../../contracts/contracts';
import { Guid } from '../../utils/common-types';
import EditProjectDialog from './EditProjectDialog';

const ProjectSelector: React.FC<{}> = () => {

    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const authContext = useAuthContext();

    const [editedProject, setEditedProject] = React.useState<Project | undefined>({});
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

    const hasOwnerAccess = checkProjectAccess(RoleType.OWNER, authContext.accountRoles());

    const onCloseEditDialog = (editedProjectToMutate?: Project) => {
        if (editedProjectToMutate) {
            projectContext.updateProject(editedProjectToMutate);
        }
        setShowEditDialog(false);
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAddProject = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEditedProject({});
        setShowEditDialog(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOnClickProject = (id: Guid) => {
        projectContext.setSelectedProject(id);
        handleCloseMenu();
    };

    const renderMenuItem = (project: Project) => {
        return <MenuItem key={project.id} onClick={() => handleOnClickProject(project.id ?? "")}>{project.name}</MenuItem>
    }

    return (<>
        <EditProjectDialog open={showEditDialog} onClose={onCloseEditDialog} project={editedProject} handlingNewProject={true}></EditProjectDialog>
        <Button onClick={handleClickMenu}>
            {projectContext.getSelectedProject()?.name || languageContext.getMessage('selectProject')}
            {projectContext.loadingProjects &&
                <CircularProgress size={15} style={{marginLeft: "10px"}} />
            }
        </Button>
        <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            {projectContext.getProjects().map(renderMenuItem)}
            {hasOwnerAccess && <MenuItem><Button fullWidth onClick={handleClickAddProject}><Add /></Button></MenuItem>}
        </Menu>
    </>
    );
}

export default ProjectSelector;