import React, { createContext, useContext, useEffect, useState } from "react";
import { AccrualAndOperationsExpenses } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_ACCRUAL_AND_OPERATIONS_EXPENSES, getAccrualAndOperationsExpensesGraphqlQueryOptions } from './accrualAndOperationsExpensesQueries'
import { useAuthContext } from "../../auth/authContext";

export interface AccrualAndOperationsExpensesQueriesContext {
    fetchedAccrualAndOperationsExpenses: Array<AccrualAndOperationsExpenses>,
    queryAccrualAndOperationsExpenses: (accrualAndOperationsExpenses: AccrualAndOperationsExpenses) => void,
}

const AccrualAndOperationsExpensesQueriesContext = createContext<AccrualAndOperationsExpensesQueriesContext>(null as unknown as AccrualAndOperationsExpensesQueriesContext);

export const AccrualAndOperationsExpensesQueriesContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedAccrualAndOperationsExpenses, setFetchedAccrualAndOperationsExpenses] = useState<Array<AccrualAndOperationsExpenses>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_ACCRUAL_AND_OPERATIONS_EXPENSES);

    const queryAccrualAndOperationsExpenses = (accrualAndOperationsExpenses: AccrualAndOperationsExpenses): void => {
        const queryOptions = getAccrualAndOperationsExpensesGraphqlQueryOptions(accrualAndOperationsExpenses);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.accrualAndOperationsExpenses) {
            const newFetchedAccrualAndOperationsExpenses: Array<AccrualAndOperationsExpenses> = queryResponse.data.accrualAndOperationsExpenses;
            setFetchedAccrualAndOperationsExpenses(newFetchedAccrualAndOperationsExpenses);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedAccrualAndOperationsExpenses([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const accrualAndOperationsExpensesQueriesContext: AccrualAndOperationsExpensesQueriesContext = {
        fetchedAccrualAndOperationsExpenses,
        queryAccrualAndOperationsExpenses,
    };

    return (
        <AccrualAndOperationsExpensesQueriesContext.Provider value={accrualAndOperationsExpensesQueriesContext}>
            {children}
        </AccrualAndOperationsExpensesQueriesContext.Provider>
    );
}

export const useAccrualAndOperationsExpensesQueriesContext = (): AccrualAndOperationsExpensesQueriesContext => {
    return useContext(AccrualAndOperationsExpensesQueriesContext);
}
