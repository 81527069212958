import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { ChangeOrderSubContractor } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useChangeOrderSubContractorContext } from '../../contexts/changeOrderSubContractor/changeOrderSubContractorContext';

type props = {
    selectedChangeOrderSubContractor: ChangeOrderSubContractor | undefined;
    onSelectedChangeOrderSubContractorChange: (newSelectedChangeOrderSubContractor: ChangeOrderSubContractor | undefined) => void;
}

const SelectChangeOrderSubContractor: React.FC<props> = ({
    selectedChangeOrderSubContractor,
    onSelectedChangeOrderSubContractorChange}) => {
        
    const languageContext = useLanguageContext();
    const changeOrderSubContractorContext = useChangeOrderSubContractorContext();

    const changeOrderSubContractors = changeOrderSubContractorContext.getChangeOrderSubContractors();
    const defaultChangeOrderSubContractor = selectedChangeOrderSubContractor ?? null;

    const handleChange = (event: any, newSelectedChangeOrderSubContractor: ChangeOrderSubContractor | null) => {
        onSelectedChangeOrderSubContractorChange(newSelectedChangeOrderSubContractor ?? undefined);
    }

    return  <Autocomplete
                options={changeOrderSubContractors}
                getOptionLabel={(changeOrderSubContractor) => changeOrderSubContractor.name ?? ''}
                value={defaultChangeOrderSubContractor}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} 
                    label={languageContext.getMessage('changeOrder')} 
                    variant="standard" />}
            />
}

export default SelectChangeOrderSubContractor;