import { Box, Button, CircularProgress, Divider, makeStyles, TextField, Theme, Tooltip, withTheme } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Add, Info } from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ToggleButton from '@material-ui/lab/ToggleButton';
import React, { ReactElement, useEffect, useState } from "react";
import { useAccrualAndOperationsExpensesContext } from "../../contexts/accrualAndOperationsExpenses/accrualAndOperationsExpensesContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { Account, AccrualAndOperationExpense, AccrualAndOperationPostedExpense, AccrualAndOperations, AccrualAndOperationsExpenses, ActiveState, HealthStatus, RoleType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { fillEmptyMonths, sortDatesAndExtractYears } from "../../utils/dateTools";
import { getRenderedStatus } from "../../utils/statusToPalettes";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import CurrencyInput from "../currencyComponents/CurrencyInput";
import TicketLoading from "../generalComponents/TicketLoading";
import { MutateDefaultAccrualAndOperationsMonths, extractUsedMonths } from "./AccrualAndOperationExpensesTools";
import NewAccrualAndOperationsExpensesDialog from "./NewAccrualAndOperationsExpensesDialog";

export function getRemainsToDistributeHealthStatus(value: number): HealthStatus {
    if (value === 0) {
        return HealthStatus.HEALTHY;
    } else if (value >= 0) {
        return HealthStatus.DEGRADED;
    } else {
        return HealthStatus.UNHEALTHY;
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
      maxHeight: 440,
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    button: {
        margin: theme.spacing(1),
    },
    tableCellHeader: {
        minWidth: theme.spacing(20),
        position: "sticky",
        top: 0,
        background: theme.palette.background.paper,
    },
    tableCellHeaderFixed: {
        minWidth: theme.spacing(20),
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 950,
        background: theme.palette.background.paper,
    },
    tableCellFixed: {
        minWidth: theme.spacing(20),
        position: "sticky",
        left: 0,
        background: theme.palette.background.paper,
    },
}));

type props = {
    from: Date | undefined;
    to: Date | undefined;
    editingExpenses: boolean;
    setEditingExpenses: (updateEditingExpenses: boolean) => void;
    selectedAccount: Account | undefined;
    accrualAndOperations: AccrualAndOperations | undefined;
    accrualAndOperationsExpenses: AccrualAndOperationsExpenses[];
    isProjectAccrualAndOperationsExpenses: boolean | undefined;
}

const AccrualAndOperationDetailsList: React.FC<props> = ({
    from,
    to,
    editingExpenses,
    setEditingExpenses,
    selectedAccount,
    accrualAndOperations,
    accrualAndOperationsExpenses,
    isProjectAccrualAndOperationsExpenses }) => {

    isProjectAccrualAndOperationsExpenses = isProjectAccrualAndOperationsExpenses ?? false;

    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const accrualAndOperationsExpensesContext = useAccrualAndOperationsExpensesContext(); 

    const [accrualExpensesVerifyDelete, setAccrualExpensesVerifyDelete] = useState<Dictionary<boolean>>({});
    const [accrualAndOperationsExpensesToUpdate, setAccrualAndOperationsExpensesToUpdate] = useState<Dictionary<AccrualAndOperationsExpenses>>({});
    const [defaultMutatedMonths, setDefaultMutatedMonths] = useState<Dictionary<Dictionary<AccrualAndOperationsExpenses>>>({});
    const [disabledMonths, setDisabledMonths] = useState<Dictionary<boolean>>({});
    const [savingAccrualAndOperationExpenses, setSavingAccrualAndOperationExpenses] = useState<boolean>(false);
    const classes = useStyles();
    const themeContext = useThemeContext();
    const theme = themeContext.getTheme();
    const language = languageContext.getLanguage();
    const monthlyExpenseItems: Dictionary<Dictionary<AccrualAndOperationExpense>> = {}
    const monthlyPostedExpenseItems: Dictionary<AccrualAndOperationPostedExpense> = {}
    const defaultCurrency: number = 0.0;

    const getAccrualAndOperationsExpenseDuringEditing = (defaultAccrualExpense: AccrualAndOperationsExpenses): AccrualAndOperationsExpenses => {
        if (!defaultAccrualExpense.id || !(defaultAccrualExpense.id in accrualAndOperationsExpensesToUpdate)) {
            return defaultAccrualExpense;
        }
        return accrualAndOperationsExpensesToUpdate[defaultAccrualExpense.id];
    }

    const registerAccrualAndOperationsExpensesToUpdate = (accrualExpense: AccrualAndOperationsExpenses) => {
        if (!accrualExpense.id) {
            return;
        }
        accrualAndOperationsExpensesToUpdate[accrualExpense.id] = accrualExpense;
        setAccrualAndOperationsExpensesToUpdate({ ...accrualAndOperationsExpensesToUpdate });
    }

    for (let i = 0; i < accrualAndOperationsExpenses.length; i++) {
        accrualAndOperationsExpenses[i] = getAccrualAndOperationsExpenseDuringEditing(accrualAndOperationsExpenses[i]);
    }

    let usedDates = extractUsedMonths(
        accrualAndOperations,
        accrualAndOperationsExpenses,
        monthlyExpenseItems,
        monthlyPostedExpenseItems);
    const emptyMonths: Date[] = [];
    usedDates = fillEmptyMonths(from, to, usedDates, emptyMonths);
    const [sortedUsedDates, usedYears] = sortDatesAndExtractYears(usedDates);

    useEffect(() => {
        if (!editingExpenses) {
            return;
        }
        MutateDefaultAccrualAndOperationsMonths(
            accrualAndOperationsExpensesContext,
            accrualAndOperationsExpenses,
            usedDates,
            emptyMonths,
            monthlyExpenseItems,
            defaultMutatedMonths,
            setDefaultMutatedMonths, 
            accrualAndOperationsExpensesToUpdate, 
            setAccrualAndOperationsExpensesToUpdate);
    }, [to, from]);

    useEffect(() => {
        if (!editingExpenses) {
            return;
        }
        sortedUsedDates.forEach(date => {
            disabledMonths[date.toISOString()] = monthlyPostedExpenseItems[date.toISOString()]?.disabled ?? false;
        });
        setDisabledMonths({ ...disabledMonths });
    }, [editingExpenses])

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [accrualAndOperationsExpensesToEdit, setAccrualAndOperationsExpensesToEdit] = useState<AccrualAndOperationsExpenses>({});
    const onCloseAccrualAndOperationsExpensesDialog = (saved: boolean) => {
        setAccrualAndOperationsExpensesToEdit({});
        setShowDialog(false);
        if (!saved && accrualAndOperationsExpenses.length === 0) {
            setEditingExpenses(false);
        }
    };

    const openNewAccrualAndOperationsExpensesDialog = (newAccrualAndOperationsExpensesToEdit: AccrualAndOperationsExpenses) => {
        setAccrualAndOperationsExpensesToEdit({ ...newAccrualAndOperationsExpensesToEdit });
        setShowDialog(true);
    };

    if (editingExpenses && !showDialog && accrualAndOperationsExpenses.length === 0) {
        openNewAccrualAndOperationsExpensesDialog({
            accountId: selectedAccount?.id,
        })
    }

    const onEditExpenses = async (cancel: boolean) => {
        const editingExpensesCopy = editingExpenses;
        setEditingExpenses(!editingExpensesCopy);
        if (editingExpensesCopy && !cancel) {
            setSavingAccrualAndOperationExpenses(true);
            if (accrualAndOperations) {
                accrualAndOperations.fromDate = from ? new Date(from) : undefined;
                accrualAndOperations.toDate = to ? new Date(to) : undefined;
                accrualAndOperations.firstDate = from ? new Date(from) : undefined;
                accrualAndOperations.lastDate = to ? new Date(to) : undefined;
                accrualAndOperations.fromDateIsEmpty = from ? false : true;
                accrualAndOperations.toDateIsEmpty = to ? false : true;
            }
            for (let id in accrualAndOperationsExpensesToUpdate) {
                const accrualExpense = accrualAndOperationsExpensesToUpdate[id];
                await accrualAndOperationsExpensesContext.updateAccrualAndOperationsExpenses(accrualExpense);
            }
            setSavingAccrualAndOperationExpenses(false);
        }

        if (cancel) {
            const accrualAndOperationsExpensesSearch = accrualAndOperationsExpensesContext.getAccrualAndOperationsExpensesSearch();
            accrualAndOperationsExpensesContext.searchAccrualAndOperationsExpenses(accrualAndOperationsExpensesSearch, true);
        }
        setAccrualAndOperationsExpensesToUpdate({});
    }

    const onDeleteAccrualExpense = async (accrualExpense: AccrualAndOperationsExpenses) => {
        if (!accrualExpense.id) {
            return;
        }
        if (accrualExpense.id in accrualExpensesVerifyDelete && accrualExpensesVerifyDelete[accrualExpense.id] === true) {
            accrualExpense.state = ActiveState.INACTIVE;
            setSavingAccrualAndOperationExpenses(true);
            await accrualAndOperationsExpensesContext.updateAccrualAndOperationsExpenses(accrualExpense);
            accrualExpensesVerifyDelete[accrualExpense.id] = false;
            setSavingAccrualAndOperationExpenses(false);
        }
        setAccrualExpensesVerifyDelete({ ...accrualExpensesVerifyDelete });
    }

    const getAboveForecastSignal = (aboveForecast: boolean | undefined): ReactElement => {
        if (aboveForecast === true) {
            return (
                <>
                    &nbsp;
                    <Tooltip title={languageContext.getMessage('revisedForecastIsAboveAccountForecast')}>
                        <Info />
                    </Tooltip>
                </>);
        }
        return <></>;
    }

    return (<>
        <NewAccrualAndOperationsExpensesDialog
            accrualAndOperationsExpenses={accrualAndOperationsExpensesToEdit}
            open={showDialog}
            onClose={onCloseAccrualAndOperationsExpensesDialog} />
        <TableContainer component={Paper} className={classes.container}>
            <Table size={'small'}>
                <TableHead>
                    <TableRow key="mainHeader">
                        <TableCell align="left" colSpan={1}>
                            <Button
                                color="inherit"
                                size="small"
                                className={classes.button}
                                startIcon={<Add />}
                                disabled={!projectContext.hasProjectAccess(RoleType.WRITER) || savingAccrualAndOperationExpenses || !selectedAccount?.id}
                                onClick={() => openNewAccrualAndOperationsExpensesDialog({
                                    accountId: selectedAccount?.id,
                                })}>
                                {languageContext.getMessage('expenseItem')}
                            </Button>
                        </TableCell>
                        <TableCell align="left" colSpan={1}>
                            <Box display="flex" flexDirection="row">
                                <Box>
                                    <ToggleButton
                                        color="inherit"
                                        size="small"
                                        className={classes.button}
                                        value="check"
                                        disabled={!projectContext.hasProjectAccess(RoleType.WRITER) || savingAccrualAndOperationExpenses || !selectedAccount?.id}
                                        onChange={async () => await onEditExpenses(false)}>
                                        {savingAccrualAndOperationExpenses ? <CircularProgress size={25} /> : (editingExpenses ? <SaveIcon /> : <EditIcon />)}
                                    </ToggleButton>
                                </Box>
                                {editingExpenses &&
                                    <Box>
                                        <ToggleButton
                                            color="inherit"
                                            size="small"
                                            className={classes.button}
                                            value="check"
                                            disabled={savingAccrualAndOperationExpenses}
                                            onChange={async () => await onEditExpenses(true)}>
                                            <CancelIcon />
                                        </ToggleButton>
                                    </Box>
                                }
                            </Box>
                        </TableCell>
                        <TableCell align="left" colSpan={editingExpenses ? 2 : 1} />
                        {usedYears.map(usedYear => (<TableCell align="center" colSpan={1}>{usedYear?.getFullYear() ?? ''}</TableCell>))}
                    </TableRow>
                    <TableRow key="subHeader">
                        {editingExpenses && <TableCell align="center" size="small" className={classes.tableCellHeader}>{languageContext.getMessage('delete')}</TableCell>}
                        <TableCell align="left" className={classes.tableCellHeaderFixed}>{languageContext.getMessage('name')}</TableCell>
                        <TableCell align="left" className={classes.tableCellHeader}>{languageContext.getMessage('finalForecast')}</TableCell>
                        <TableCell align="left" className={classes.tableCellHeader}>{languageContext.getMessage('remainsToDistribute')}</TableCell>
                        {sortedUsedDates.map(usedDate => (<TableCell align="center" className={classes.tableCellHeader}>
                            {usedDate.toLocaleString(language, { month: 'long' })}
                            {/* <br/>
                            {!editingExpenses && (monthlyPostedExpenseItems[usedDate.toISOString()]?.disabled ?? false) && `(${languageContext.getMessage('disabled').toLowerCase()})`}
                            {editingExpenses && <Switch
                                checked={!disabledMonths[usedDate.toISOString()]}
                                onChange={(event) => {
                                    const dateStr = usedDate.toISOString();
                                    disabledMonths[dateStr] = !event.target.checked;
                                    accrualAndOperationsExpenses.forEach(accrualExpense => {
                                        accrualExpense.id = accrualExpense.id ?? '';
                                        if (monthlyExpenseItems[accrualExpense.id] && dateStr in monthlyExpenseItems[accrualExpense.id]) {
                                            const crewResourceDetails = monthlyExpenseItems[accrualExpense.id][dateStr];
                                            crewResourceDetails.disabled = disabledMonths[dateStr];
                                            accrualExpense.monthlyExpenseItems = accrualExpense.monthlyExpenseItems ?? []
                                            const index = accrualExpense.monthlyExpenseItems.findIndex(item => item.date?.toISOString() === dateStr)
                                            if (index >= 0) {
                                                accrualExpense.monthlyExpenseItems[index] = crewResourceDetails;
                                            }
                                            else {
                                                accrualExpense.monthlyExpenseItems.push(crewResourceDetails);
                                            }
                                            registerAccrualAndOperationsExpensesToUpdate(accrualExpense);
                                        }
                                    });
                                    setDisabledMonths({ ...disabledMonths });
                                }}
                                disabled={!editingExpenses || savingAccrualAndOperationExpenses}
                            />} */}
                        </TableCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accrualAndOperationsExpenses.map(accrualExpense => (
                        <TableRow key={accrualExpense.id}>
                            {editingExpenses && (accrualExpense.id && (!(accrualExpense.id in accrualExpensesVerifyDelete) || accrualExpensesVerifyDelete[accrualExpense.id] === false)) &&
                                <TableCell align="center" size="small">
                                    <ToggleButton
                                        color="inherit"
                                        size="small"
                                        className={classes.button}
                                        value="check"
                                        disabled={savingAccrualAndOperationExpenses}
                                        onChange={() => {
                                            if (accrualExpense.id) {
                                                accrualExpensesVerifyDelete[accrualExpense.id] = true;
                                                setAccrualExpensesVerifyDelete({ ...accrualExpensesVerifyDelete });
                                            }
                                        }}>
                                        <DeleteIcon fontSize="small" />
                                    </ToggleButton>
                                </TableCell>
                            }
                            {editingExpenses && (accrualExpense.id && (accrualExpense.id in accrualExpensesVerifyDelete && accrualExpensesVerifyDelete[accrualExpense.id] === true)) &&
                                <TableCell align="center" size="small">
                                    <Box display="flex" flexDirection="row">
                                        <Box>
                                            <ToggleButton
                                                color="inherit"
                                                size="small"
                                                className={classes.button}
                                                value="check"
                                                disabled={savingAccrualAndOperationExpenses}
                                                onChange={async () => await onDeleteAccrualExpense(accrualExpense)}>
                                                <DeleteForeverIcon fontSize="small" />
                                            </ToggleButton>
                                        </Box>
                                        <Box>
                                            <ToggleButton
                                                color="inherit"
                                                size="small"
                                                className={classes.button}
                                                value="check"
                                                disabled={savingAccrualAndOperationExpenses}
                                                onChange={() => {
                                                    if (accrualExpense.id) {
                                                        accrualExpensesVerifyDelete[accrualExpense.id] = false;
                                                        setAccrualExpensesVerifyDelete({ ...accrualExpensesVerifyDelete });
                                                    }
                                                }}>
                                                <CancelIcon fontSize="small" />
                                            </ToggleButton>
                                        </Box>
                                    </Box>
                                </TableCell>
                            }
                            <TableCell className={classes.tableCellFixed} align="left">
                                {!editingExpenses &&
                                    <TicketLoading documentId={accrualExpense.id} component={accrualExpense.name} />
                                }
                                {editingExpenses &&
                                    <TextField
                                        label={''}
                                        value={accrualExpense.name ?? ''}
                                        onChange={(event) => {
                                            accrualExpense.name = event.target.value;
                                            registerAccrualAndOperationsExpensesToUpdate(accrualExpense);
                                        }}
                                        disabled={savingAccrualAndOperationExpenses}
                                    />
                                }
                            </TableCell>
                            <TableCell align="left">
                                {!editingExpenses &&
                                    <CurrencyFormat amount={accrualExpense.forecast ?? 0.0} />
                                }
                                {editingExpenses &&
                                    <CurrencyInput
                                        label={''}
                                        value={accrualExpense.forecast ?? 0.0}
                                        onChange={(newForecast) => {
                                            accrualExpense.forecast = newForecast;
                                            registerAccrualAndOperationsExpensesToUpdate(accrualExpense);
                                        }}
                                        fullWidth
                                        disabled={savingAccrualAndOperationExpenses}
                                    />
                                }
                            </TableCell>
                            <TableCell align="left">
                                <div style={{ display: 'inline-flex', alignItems: 'middle' }}>
                                    {getRenderedStatus(getRemainsToDistributeHealthStatus(accrualExpense.remainingForecastsToDistribute ?? 0), theme)}
                                    &nbsp;
                                    <CurrencyFormat amount={accrualExpense.remainingForecastsToDistribute} />
                                </div>
                            </TableCell>
                            {sortedUsedDates.map(usedDate => {
                                const dateStr = usedDate.toISOString();
                                if (!accrualExpense.id || !(accrualExpense.id ?? "" in monthlyExpenseItems)) {
                                    return <></>
                                }
                                let expense = defaultCurrency;
                                if (monthlyExpenseItems[accrualExpense.id] && dateStr in monthlyExpenseItems[accrualExpense.id]) {
                                    expense = monthlyExpenseItems[accrualExpense.id][dateStr].expense ?? 0.0
                                }
                                return <TableCell align="center">
                                    {!editingExpenses &&
                                        <CurrencyFormat amount={expense} />
                                    }
                                    {editingExpenses &&
                                        <CurrencyInput
                                            label={''}
                                            value={expense}
                                            onChange={(newExpense) => {
                                                if (!accrualExpense.monthlyExpenseItems) {
                                                    accrualExpense.monthlyExpenseItems = []
                                                }
                                                const accrualMonthlyExpense: AccrualAndOperationExpense = { date: usedDate, expense: newExpense }
                                                const index = accrualExpense.monthlyExpenseItems.findIndex(item => item.date?.toISOString() === dateStr)
                                                if (index >= 0) {
                                                    accrualExpense.monthlyExpenseItems[index] = accrualMonthlyExpense;
                                                }
                                                else {
                                                    accrualExpense.monthlyExpenseItems.push(accrualMonthlyExpense);
                                                }
                                                registerAccrualAndOperationsExpensesToUpdate(accrualExpense);
                                            }}
                                            fullWidth
                                            disabled={savingAccrualAndOperationExpenses}
                                        />
                                    }
                                </TableCell>
                            })}
                        </TableRow>
                    ))}
                    <TableRow key='summaryDivider'>
                        <TableCell colSpan={sortedUsedDates.length + (editingExpenses ? 4 : 3)}><Divider /></TableCell>
                    </TableRow>
                    <TableRow key='TotalExpenseForecastsAndExpectedPostedExpense'>
                        {editingExpenses && <TableCell colSpan={1} />}
                        <TableCell colSpan={1}>{languageContext.getMessage('sumExpenseForecasts')}</TableCell>
                        <TableCell colSpan={1}><CurrencyFormat amount={selectedAccount?.sumExpenseForecastsOnAccrualAndOperations} /></TableCell>
                        <TableCell className={classes.tableCellFixed} colSpan={1}>{languageContext.getMessage('expectedPostedExpense')}</TableCell>
                        {sortedUsedDates.map(usedDate => {
                            const dateStr = usedDate.toISOString();
                            let expense = defaultCurrency;;
                            if (dateStr in monthlyPostedExpenseItems) {
                                expense = monthlyPostedExpenseItems[dateStr].expectedPostedExpense ?? 0.0;
                            }
                            return <TableCell align="center">
                                {<CurrencyFormat amount={expense} />}
                            </TableCell>
                        })}
                    </TableRow>
                    <TableRow key='TotalForecastAndExpectedAccumulatedPostedExpense'>
                        {editingExpenses && <TableCell colSpan={1} />}
                        <TableCell colSpan={1}>{languageContext.getMessage('finalForecast')}</TableCell>
                        <TableCell colSpan={1}><CurrencyFormat amount={selectedAccount?.forecast?.forecast} /></TableCell>
                        <TableCell className={classes.tableCellFixed} colSpan={1}>{languageContext.getMessage('expectedAccumulatedPostedExpense')}</TableCell>
                        {sortedUsedDates.map(usedDate => {
                            const dateStr = usedDate.toISOString();
                            let expense = defaultCurrency;;
                            if (dateStr in monthlyPostedExpenseItems) {
                                expense = monthlyPostedExpenseItems[dateStr].expectedAccumulatedPostedExpense ?? 0.0;
                            }
                            return <TableCell align="center">
                                {<CurrencyFormat amount={expense} />}
                            </TableCell>
                        })}
                    </TableRow>
                    <TableRow key='remainsToDistributeAndActuallyPostedExpense'>
                        {editingExpenses && <TableCell colSpan={1} />}
                        <TableCell colSpan={1}>{languageContext.getMessage('remainsToDistribute')}</TableCell>
                        <TableCell colSpan={1}>
                            <div style={{ display: 'inline-flex', alignItems: 'middle' }}>
                                {getRenderedStatus(getRemainsToDistributeHealthStatus(selectedAccount?.remainingForecastsToDistributeOnAccrualAndOperations ?? 0), theme)}
                                &nbsp;
                                <CurrencyFormat amount={selectedAccount?.remainingForecastsToDistributeOnAccrualAndOperations} />
                            </div>
                        </TableCell>
                        <TableCell className={classes.tableCellFixed} colSpan={1}>{languageContext.getMessage('actuallyPostedExpense')}</TableCell>
                        {sortedUsedDates.map(usedDate => {
                            const dateStr = usedDate.toISOString();
                            let expense = defaultCurrency;;
                            if (dateStr in monthlyPostedExpenseItems) {
                                expense = monthlyPostedExpenseItems[dateStr].actuallyPostedExpense ?? 0.0;
                            }
                            return <TableCell align="center">
                                {<CurrencyFormat amount={expense} />}
                            </TableCell>
                        })}
                    </TableRow>
                    <TableRow key='actuallyAccumulatedPostedExpense'>
                        {editingExpenses && <TableCell colSpan={1} />}
                        <TableCell colSpan={2} />
                        <TableCell className={classes.tableCellFixed} colSpan={1}>{languageContext.getMessage('actuallyAccumulatedPostedExpense')}</TableCell>
                        {sortedUsedDates.map(usedDate => {
                            const dateStr = usedDate.toISOString();
                            let expense = defaultCurrency;;
                            if (dateStr in monthlyPostedExpenseItems) {
                                expense = monthlyPostedExpenseItems[dateStr].actuallyAccumulatedPostedExpense ?? 0.0;
                            }
                            return <TableCell align="center">
                                {<CurrencyFormat amount={expense} />}
                            </TableCell>
                        })}
                    </TableRow>
                    <TableRow key='revisedForecast'>
                        {editingExpenses && <TableCell colSpan={1} />}
                        <TableCell colSpan={2} />
                        <TableCell className={classes.tableCellFixed} colSpan={1}>{languageContext.getMessage('revisedForecast')}</TableCell>
                        {sortedUsedDates.map(usedDate => {
                            const dateStr = usedDate.toISOString();
                            let expense = defaultCurrency;;
                            let aboveForecast = false;
                            if (dateStr in monthlyPostedExpenseItems) {
                                expense = monthlyPostedExpenseItems[dateStr].revisedForecast ?? 0.0;
                                aboveForecast = monthlyPostedExpenseItems[dateStr].revisedForecastIsAboveAccountForecast ?? false;
                            }
                            return <TableCell align="center">
                                {<CurrencyFormat amount={expense} />}{getAboveForecastSignal(aboveForecast)}
                            </TableCell>
                        })}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>
    );
}

export default withTheme(AccrualAndOperationDetailsList);