import React, { createContext, useContext, useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_CREW_RESOURCE, getCrewResourceGraphqlQueryOptions } from './crewResourceQueries'
import { useAuthContext } from "../../auth/authContext";
import { CrewResource } from "../../../contracts/contracts";

export interface CrewResourceQueriesContext {
    fetchedCrewResource: CrewResource[],
    queryCrewResource: (crewResource: Partial<CrewResource>) => void,
}

const CrewResourceQueriesContext = createContext<CrewResourceQueriesContext>(null as unknown as CrewResourceQueriesContext);

export const CrewResourceQueriesContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedCrewResource, setFetchedCrewResource] = useState<CrewResource[]>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_CREW_RESOURCE);

    const queryCrewResource = (crewResource: Partial<CrewResource>): void => {
        const queryOptions = getCrewResourceGraphqlQueryOptions(crewResource);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse?.data?.crewResources) {
            const newFetchedCrewResource: CrewResource[] = queryResponse.data.crewResources;
            setFetchedCrewResource(newFetchedCrewResource);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedCrewResource([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const crewResourceQueriesContext: CrewResourceQueriesContext = {
        fetchedCrewResource,
        queryCrewResource,
    };

    return (
        <CrewResourceQueriesContext.Provider value={crewResourceQueriesContext}>
            {children}
        </CrewResourceQueriesContext.Provider>
    );
}

export const useCrewResourceQueriesContext = (): CrewResourceQueriesContext => {
    return useContext(CrewResourceQueriesContext);
}
