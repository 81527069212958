import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useInvoiceContext } from '../../contexts/invoice/invoiceContext';
import { InvoiceFlag } from '../../contracts/contracts';
import { CircularProgress } from '@material-ui/core';

type props = {
    invoiceFlags: InvoiceFlag[];
    onChange: (invoiceFlags: InvoiceFlag[]) => void;
}

const SelectInvoiceFlags: React.FC<props> = ({
    invoiceFlags,
    onChange
}) => {

    const languageContext = useLanguageContext();
    const invoiceContext = useInvoiceContext();

    const handleChange = (newSelectedInvoiceFlags: InvoiceFlag[]) => {
        onChange(newSelectedInvoiceFlags);
    }

    return <Autocomplete
        options={Object.values(InvoiceFlag)}
        multiple={true}
        getOptionLabel={invoiceFlag => languageContext.getMessage(invoiceFlag)}
        value={invoiceFlags}
        fullWidth={true}
        onChange={(event, newSelectedInvoiceFlags) => handleChange(newSelectedInvoiceFlags)}
        renderInput={(params) => <TextField {...params}
            label={<>
                {languageContext.getMessage('flags')}
                {invoiceContext.loadingInvoices &&
                    <CircularProgress size={12} style={{ marginLeft: 5 }} />
                }
            </>} />
        }
    />
}

export default SelectInvoiceFlags;