import { Checkbox, createStyles, FormControl, Input, InputLabel, ListItemText, makeStyles, MenuItem, Select, Theme, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 250,
            maxWidth: 300,
        }
    }),
);

type props = {
    heading: string;
    options: string[];
    values: string[];
    onChange: (value: string[]) => void;
    render?: (value: string) => React.ReactNode;
}

const MultipleDropDown: React.FC<props> = (
    {
        heading,
        options,
        values,
        onChange, 
        render,
    }
) => {
    const classes = useStyles();
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value as string[];
        onChange(value);
    };

    const selectedElementsContainsElement = (value: string): boolean => {
        return values.indexOf(value) > -1;
    };

    const renderValues = (): string | null => {
        if (values.length <= 0) return null;
        return (render ? values.map(value => render(value)) : values).join(', ');
    };

    const renderTooltip = (): string => {
        if (values.length <= 0) return '';
        return (render ? values.map(value => render(value)) : values).join(',\n');
    };

    return (<FormControl className={classes.formControl}>
        <InputLabel>{heading}</InputLabel>
        <Tooltip title={renderTooltip()} placement={'bottom'} open={showTooltip}>
            <Select
                multiple
                value={values}
                onChange={handleChange}
                input={<Input />}
                renderValue={renderValues}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onOpen={() => setShowTooltip(false)}
            >
                {options.map((element) => (
                    <MenuItem key={element} value={element}>
                        <Checkbox checked={selectedElementsContainsElement(element)} />
                        <ListItemText primary={render ? render(element) : element} />
                    </MenuItem>
                ))}
            </Select>
        </Tooltip>
    </FormControl>)
}

export default MultipleDropDown;