import React from 'react';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

type props = {
    label?: any;
    value: number;
    onChange?: (amount: number) => void;
    isCost?: boolean;
    readOnly?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
}

const CurrencyInput: React.FC<props> = ({
    label,
    value,
    onChange,
    readOnly,
    fullWidth,
    disabled
}) => {
    readOnly = readOnly ?? false;
    disabled = disabled ?? false;

    return (
    <>
        <CurrencyTextField
            fullWidth={fullWidth}
            label={label}
            readOnly={readOnly}
            disabled={disabled}
            variant="standard"
            value={value}
            currencySymbol={'kr'}
            outputFormat="number"
            decimalPlaces={0}
            decimalCharacter=","
            digitGroupSeparator=" "
            onChange={(event: any, value: any) => {
                if (onChange){
                    onChange(value as number);
                }
            }}
        />
    </>);
}

export default CurrencyInput