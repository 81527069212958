import { IconButton, Grid } from '@material-ui/core';
import withTheme from '@material-ui/styles/withTheme';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Invoice } from '../../contracts/contracts';
import CurrencyInput from '../currencyComponents/CurrencyInput';
import CurrencyFormat from '../currencyComponents/CurrencyFormat';
import { useInvoiceContext } from '../../contexts/invoice/invoiceContext';

export interface EditInvoiceProps {
  invoice: Invoice;
}

const EditInvoiceWithheldAmount: React.FC<EditInvoiceProps> = ({ invoice }) => {
  const languageContext = useLanguageContext();
  const invoiceContext = useInvoiceContext();

  const [editing, setEditing] = React.useState<boolean>(false);
  const [editedInvoice, setEditedInvoice] = React.useState<Invoice | undefined>({});

  const handleSaveOrEdit = () => {
    if (editing && editedInvoice && invoice?.id) {
      invoiceContext.mutateInvoice({ ...editedInvoice });
    }
    setEditedInvoice({ ...invoice });
    setEditing(!editing);
  };

  return (
    <Grid container>
      <Grid item xs={8}>
        {editing &&
          <CurrencyInput
            label={''}
            value={editedInvoice?.withheldAmount ?? 0.0}
            onChange={newProducedNotBilled => {
              if (editedInvoice) {
                editedInvoice.withheldAmount = newProducedNotBilled;
              }
              setEditedInvoice({ ...editedInvoice });
            }
            }
            fullWidth
          />
        }
        {!editing &&
          <CurrencyFormat amount={invoice.withheldAmount} />
        }
      </Grid>
      {editing &&
        <>
          <Grid item xs={4}>
            <IconButton size={'small'} onClick={() => handleSaveOrEdit()}>
              <SaveIcon fontSize={'small'} />
            </IconButton>
            <IconButton size={'small'} onClick={() => setEditing(false)}>
              <CloseIcon fontSize={'small'} />
            </IconButton>
          </Grid>
        </>
      }
      {!editing &&
        <Grid item xs={4}>
          <IconButton size={'small'} onClick={() => handleSaveOrEdit()}>
            <EditIcon fontSize={'small'} />
          </IconButton>
        </Grid>
      }
    </Grid>
  );
}

export default withTheme(EditInvoiceWithheldAmount);