import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { CrewList, ActiveState } from "../../../contracts/contracts";
import { toISODate } from "../../../utils/dateTools";

export const MUTATE_CREW_LIST: DocumentNode = gql`
    mutation mutateCrewList(
        $parentDocumentId: UUID!
        $constructionAdministrationBudget: Float
        $salaryAdjustmentPercentageDate: Date) {
    mutateCrewList(
        parentDocumentId: $parentDocumentId
        constructionAdministrationBudget: $constructionAdministrationBudget
        salaryAdjustmentPercentageDate: $salaryAdjustmentPercentageDate) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getCrewListGraphqlMutationOptions = (crewList: Partial<CrewList>): QueryHookOptions => {

    crewList.state = crewList.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {}
    options.variables = {};
    if (crewList.parentDocumentId !== undefined) options.variables['parentDocumentId'] = crewList.parentDocumentId
    if (crewList.constructionAdministrationBudget !== undefined) options.variables['constructionAdministrationBudget'] = crewList.constructionAdministrationBudget
    if (crewList.salaryAdjustmentPercentageDate !== undefined) options.variables['salaryAdjustmentPercentageDate'] = toISODate(crewList.salaryAdjustmentPercentageDate)
    return options;
}
