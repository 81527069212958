import React, { createContext, useContext } from "react";
import { CrewList, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getCrewListGraphqlMutationOptions, MUTATE_CREW_LIST } from "./CrewListMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface CrewListMutationsContext {
    mutateCrewList: (crewList: CrewList, onTicketSubscribed: (documentId: Guid, variables: any) => void) => Promise<void>;
}

const CrewListMutationsContext = createContext<CrewListMutationsContext>(null as unknown as CrewListMutationsContext);

export const CrewListMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [crewListMutation] = useMutation(MUTATE_CREW_LIST);

    const handleCrewListsMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateCrewList.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
    }

    const mutateCrewList = async (crewList: CrewList, onTicketSubscribed: (documentId: Guid, variables: any) => void): Promise<void> => {
        const options = getCrewListGraphqlMutationOptions(crewList);
        const result = await crewListMutation(options);
        return handleCrewListsMutationResult(result, options.variables, onTicketSubscribed);
    }

    const crewListMutationsContext: CrewListMutationsContext = {
        mutateCrewList,
    };

    return (
        <CrewListMutationsContext.Provider value={crewListMutationsContext}>
            {children}
        </CrewListMutationsContext.Provider>
    );
}

export const useCrewListMutationsContext = (): CrewListMutationsContext => {
    return useContext(CrewListMutationsContext);
}
