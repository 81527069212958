import { ProjectContext } from "../contexts/project/projectContext";
import { StorageFileMutationsContext } from "../contexts/storageFile/mutations/storageFileMutationsContext";
import { StorageFile, DocumentType } from "../contracts/contracts";
import { Guid } from "./common-types";

export function downloadBase64File(contentType: string | undefined, base64Data: string, filename: string) {
  let linkSource = contentType ? `data:${contentType};base64,${base64Data}` : base64Data;
  if (linkSource.indexOf(';base64,') < 0 && !linkSource.startsWith('data:text/csv')) {
    linkSource = `data:image/png;base64,${linkSource}`;
  }
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
}

export const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve((reader.result ?? '') as string);
  reader.onerror = error => reject(error);
});

export const getStorageFileKey = (documentType?: DocumentType, documentId?: Guid, fileKey?: string): string => {
  let storageFileKey = `neate_aimz`;
  if (documentType && documentId) {
    storageFileKey = `${storageFileKey}_${documentType}_${documentId}`;
  }
  if (fileKey && fileKey.trim().length > 0) {
    storageFileKey = `${storageFileKey}_${fileKey}`;
  }
  return storageFileKey;
};

export const fileToString = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = () => resolve((reader.result ?? '') as string);
  reader.onerror = error => reject(error);
});

export const parseStorageFileToFilename = (storageFile: StorageFile[]): string => {
  if (storageFile.length > 0) {
    return storageFile[0].metaData ?? "";
  } else {
    return "";
  }
};

export const parseStorageFileToBase64Files = (storageFiles: StorageFile[], key: string | undefined): string[] => {
  const matchingStorageFile = storageFiles.find(storageFile => storageFile.key === key || key === undefined);
  const base64Images: string[] = matchingStorageFile?.content ? matchingStorageFile.content.split(';;') : [];
  return base64Images
}

export const parseStorageFileToBase64File = (storageFiles: StorageFile[], key: string | undefined): string => {
  const matchingStorageFile = storageFiles.find((storageFile) => storageFile.key === key || key === undefined);
  return matchingStorageFile?.content ?? "";
};

export const parseStorageFileToFilenames = (storageFiles: StorageFile[], key: string | undefined): string[] => {
  const matchingStorageFile = storageFiles.find(storageFile => storageFile.key === key || key === undefined);
  const filenames: string[] = matchingStorageFile?.metaData ? matchingStorageFile.metaData.split(';;') : [];
  return filenames
}

export async function onUploadFiles(
  storageMutationContext: StorageFileMutationsContext,
  projectContext: ProjectContext,
  newFiles: File[],
  key: string | undefined,
  onMutatedStorageFile?: (base64Images: string[], filenames: string[]) => void) {
  if (!key) {
    return;
  }
  const base64ImageFiles: string[] = [];
  const filenames: string[] = [];
  for (let i = 0; i < newFiles.length; i++) {
    base64ImageFiles.push(await toBase64(newFiles[i]));
    filenames.push(newFiles[i].name);
  }
  const storageFile: StorageFile = {
    key: key,
    metaData: filenames.join(';;'),
    content: base64ImageFiles.join(';;')
  }
  const storageFiles = await storageMutationContext.mutateStorageFile(storageFile);
  storageFiles.forEach(storageFile => {
    projectContext.addToExistingProjectFiles(storageFile);
  })
  if (onMutatedStorageFile) {
    onMutatedStorageFile(parseStorageFileToBase64Files(storageFiles, key), parseStorageFileToFilenames(storageFiles, key));
  }
}

export async function onUploadFile(
  storageMutationContext: StorageFileMutationsContext,
  projectContext: ProjectContext,
  newFile: File,
  key: string | undefined,
  onMutatedStorageFile?: (base64Image: string, filename: string) => void
) {
  if (!key) {
    return;
  }
  const base64ImageFile: string = await toBase64(newFile);
  const filename: string = newFile.name;

  const storageFile: StorageFile = {
    key: key,
    metaData: filename,
    content: base64ImageFile,
  };
  const storageFiles = await storageMutationContext.mutateStorageFile(storageFile);
  storageFiles.forEach(storageFile => {
    projectContext.addToExistingProjectFiles(storageFile);
  })
  if (onMutatedStorageFile) {
    onMutatedStorageFile(parseStorageFileToBase64File(storageFiles, key), parseStorageFileToFilename(storageFiles));
  }
}