import { jwtDecode } from "jwt-decode";
import { ApplicationRoute } from "../menu/menuContext";
import { ProjectContext } from "../project/projectContext";
import { localStorageAccessTokenKey, localStorageIdTokenKey } from "./interfaces";

export const generalAuthorizedCheck = (
    authenticated: boolean, 
    insecure: boolean, 
    applicationRoute: ApplicationRoute, 
    projectContext: ProjectContext): boolean => {
    return (authenticated || insecure) 
            && (applicationRoute.accessRole === undefined || projectContext.hasProjectAccess(applicationRoute.accessRole));
}

export const jwtAccessToken = (): string | undefined => {
    return localStorage.getItem(localStorageAccessTokenKey) ?? undefined;
  };
  
  export const jwtIdToken = (): string | undefined => {
    return localStorage.getItem(localStorageIdTokenKey) ?? undefined;
  };
  
  export const checkIfTokenIsExpired = (token: string | undefined): boolean => {
    if (!token || token.trim().length === 0) {
      return true;
    }
    let isExpired = false;
    let decodedToken = jwtDecode(token);
    let dateNow = new Date();
  
    if ((decodedToken?.exp ?? 0) < dateNow.getTime() / 1000) {
      isExpired = true;
    }
    return isExpired;
  };