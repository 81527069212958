import React from "react";
import { DatePickerView, KeyboardDatePicker } from "@material-ui/pickers";
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
    label?: React.ReactNode;
    date?: Date;
    monthPicker?: boolean;
    yearPicker?: boolean;
    onChange?: (date: Date | undefined) => void;
    readOnly?: boolean;
    fullWidth?: boolean;
    style?: React.CSSProperties | undefined;
    disabled?: boolean;
    maxWidth?: number;
}

export const DatePicker: React.FC<props> = ({
    label,
    date,
    monthPicker,
    yearPicker,
    onChange,
    readOnly,
    fullWidth,
    style,
    disabled,
    maxWidth,
}) => {

    monthPicker = monthPicker ?? false;
    yearPicker = yearPicker ?? false;

    let dateValue = date === undefined ? null : date;
    const languageContext = useLanguageContext();
    style = maxWidth ? { ...style, maxWidth: maxWidth } : style;

    let format = "dd/MM/yyyy";
    let views: DatePickerView[] | undefined = undefined;
    let openTo: DatePickerView | undefined = undefined;
    if (monthPicker && yearPicker) {
        format = "MM/yyyy"
        openTo = "month";
        views = ["year", "month"]
    }
    else if (monthPicker) {
        format = "MM"
        openTo = "month";
        views = ["month"]
    }
    else if (yearPicker) {
        format = "yyyy"
        openTo = "year";
        views = ["year"]
    }
    return <KeyboardDatePicker
            label={label}
            clearable={true}
            showTodayButton={true}
            todayLabel={languageContext.getMessage('today')}
            clearLabel={languageContext.getMessage('clear')}
            cancelLabel={languageContext.getMessage('cancel')}
            openTo={openTo}
            views={views}
            value={dateValue}
            readOnly={readOnly}
            onChange={(newDate: any) => {
                if (!onChange) {
                    return;
                }
                if (newDate) {
                    if (newDate.toString() !== 'Invalid Date') {
                        onChange(new Date(newDate.getTime()))
                    }
                }
                else {
                    onChange(undefined)
                }
            }}
            format={format}
            fullWidth={fullWidth}
            style={style}
            disabled={disabled}/>
}