import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useContractContext } from "../../contexts/contract/contractContext";
import { Account, Contract } from "../../contracts/contracts";
import { useLanguageContext } from "../../contexts/language/LanguageContext";

type props = {
  selectedContract: Contract | undefined;
  selectedAccount: Account | undefined;
  onSelectedContractChange: (newSelectedContract: Contract | undefined) => void;
  label?: React.ReactNode;
  minWidth?: number;
};

const SelectContract: React.FC<props> = ({ selectedContract, selectedAccount, onSelectedContractChange, label, minWidth }) => {
  const languageContext = useLanguageContext();
  const contractContext = useContractContext();

  const contracts = contractContext.getContracts(selectedAccount?.id);
  const defaultContract = selectedContract ?? null;

  const handleChange = (event: any, newSelectedContract: Contract | null) => {
    onSelectedContractChange(newSelectedContract ?? undefined);
  };

  return (
    <Autocomplete
      options={contracts}
      getOptionLabel={(contract) => contract.name ?? ""}
      style={{ minWidth: minWidth }}
      value={defaultContract}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={label ?? languageContext.getMessage("contract")} variant="standard" />}
    />
  );
};

export default SelectContract;
