import React, { createContext, useContext, useEffect, useState } from "react";
import { Interim } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_INTERIMS, getInterimGraphqlQueryOptions } from './InterimQueries'
import { useAuthContext } from "../../auth/authContext";

export interface InterimQueriesContext {
    fetchedInterims: Array<Interim>,
    queryInterims: (interim: Interim) => void,
}

const InterimQueriesContext = createContext<InterimQueriesContext>(null as unknown as InterimQueriesContext);

export const InterimQueriesContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedInterims, setFetchedInterims] = useState<Array<Interim>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_INTERIMS);

    const queryInterims = (interims: Interim): void => {
        const queryOptions = getInterimGraphqlQueryOptions(interims);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.interims) {
            const newFetchedInterims: Array<Interim> = queryResponse.data.interims;
            setFetchedInterims(newFetchedInterims);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedInterims([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const interimQueriesContext: InterimQueriesContext = {
        fetchedInterims,
        queryInterims,
    };

    return (
        <InterimQueriesContext.Provider value={interimQueriesContext}>
            {children}
        </InterimQueriesContext.Provider>
    );
}

export const useInterimQueriesContext = (): InterimQueriesContext => {
    return useContext(InterimQueriesContext);
}
