import { Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useChangeOrderCounterClaimContext } from "../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import ChangeOrderCounterClaimViewAll from "./ChangeOrderCounterClaimViewAll";
import ChangeOrderCounterClaimDetailsView from "./ChangeOrderCounterClaimViewDetails";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Dictionary } from "../../global-types";
import { DocumentType, Account, Contract, ChangeOrderCounterClaim, ChangeOrderStatus } from "../../contracts/contracts";
import { useInvoiceContext } from "../../contexts/invoice/invoiceContext";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

type props = {
}

const ChangeOrderCounterClaimView: React.FC<props> = ({}) => {

  const classes = useStyles();
  const languageContext = useLanguageContext();
  const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();
  const accountContext = useAccountContext();
  const invoiceContext = useInvoiceContext();
  const urlContext = useUrlContext();
  const contractContext = useContractContext();
  const projectContext = useProjectContext();
  const ticketContext = useTicketContext();
  const userRoleContext = useUserRoleContext();

  const urlState = urlContext.getUrlState();
  const tabIndex = urlState.tab ? urlState.tab as string : 'all'
  const selectedAccountId = urlState.accountId ? urlState.accountId as string : undefined;
  const selectedContractId = urlState.contractId ? urlState.contractId as string : undefined;
  const selectedStatus = urlState.status ? urlState.status as ChangeOrderStatus : undefined;
  const selectedChangeOrderCounterClaimId = urlState.changeOrderCounterClaimId ? urlState.changeOrderCounterClaimId as string : undefined;
  const selectedChangeOrderCounterClaim = selectedChangeOrderCounterClaimId ? (changeOrderCounterClaimContext.getChangeOrderCounterClaim(selectedChangeOrderCounterClaimId) ?? undefined) : undefined

  const changeOrderCounterClaimSearch = changeOrderCounterClaimContext.getChangeOrderCounterClaimSearch();
  const accountSearch = accountContext.getAccountSearch();
  const invoiceSearch = invoiceContext.getInvoiceSearch();
  const contractSearch = contractContext.getContractSearch();

  invoiceSearch.accountId = undefined;
  contractSearch.accountId = undefined;

  useEffect(() => {
    if (userRoleContext.getUsers().length === 0) {
      userRoleContext.queryUsers();
    }
    ticketContext.setDocumentTypesToWatch([
        DocumentType.ACCOUNT, 
        DocumentType.CHANGE_ORDER_COUNTER_CLAIM]);
    changeOrderCounterClaimContext.searchChangeOrderCounterClaims(changeOrderCounterClaimSearch);
    accountContext.searchAccounts(accountSearch);
    invoiceContext.searchInvoices(invoiceSearch);
    contractContext.searchContracts(contractSearch);
  }, [urlContext.currentLocation, projectContext.getSelectedProject()])

  const selectedAccount = accountContext.getAccount(selectedAccountId);
  const selectedContract = contractContext.getContract(selectedContractId);

  const updateUrlState = (newSelectedAccountId: string | undefined, 
                          newSelectedContractId: string | undefined, 
                          newSelectedStatus: ChangeOrderStatus | undefined,
                          newSelectedChangeOrderCounterClaim: ChangeOrderCounterClaim | undefined,
                          newTabIndex: string): void => {
    const newUrlState = {
      ...changeOrderCounterClaimSearch,
      ...{'accountId': newSelectedAccountId},
      ...{'contractId': newSelectedContractId},
      ...{'status': newSelectedStatus},
      ...{'changeOrderCounterClaimId': newSelectedChangeOrderCounterClaim?.id},
      ...{'tab': newTabIndex}
    }
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined | any>);
    urlContext.pushUrlQuery(urlQuery);
  }

  const handleTabIndexChange = (event: React.ChangeEvent<{}> | undefined, newTabIndex: string) => {
    updateUrlState(selectedAccountId, 
      selectedContractId,
      selectedStatus,
      selectedChangeOrderCounterClaim,
      newTabIndex);
  };

  const onSelectedAccountChange = (newSelectedAccount: Account | undefined): void => {
    updateUrlState(newSelectedAccount?.id, 
      selectedContractId,
      selectedStatus,
      selectedChangeOrderCounterClaim,
      tabIndex);
  }

  const onSelectedContractChange = (newSelectedContract: Contract | undefined): void => {
    updateUrlState(selectedAccountId, 
      newSelectedContract?.id,
      selectedStatus,
      selectedChangeOrderCounterClaim,
      tabIndex);
  }

  const onSelectedStatusChange = (newSelectedStatus: ChangeOrderStatus | undefined): void => {
    updateUrlState(selectedAccountId, 
      selectedContractId,
      newSelectedStatus,
      selectedChangeOrderCounterClaim,
      tabIndex);
  }

  const onSelectedChangeOrderCounterClaimChange = (newSelectedChangeOrderCounterClaim: ChangeOrderCounterClaim | undefined): void => {
    updateUrlState(selectedAccountId, 
      selectedContractId,
      selectedStatus,
      newSelectedChangeOrderCounterClaim,
      'details');
  }

  return (<Grid container spacing={2}>

          <Grid item xs={12}>
            <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
              <Tabs value={tabIndex} onChange={handleTabIndexChange} aria-label="simple tabs example" >
                <Tab label={languageContext.getMessage("all")} value='all' />
                {/* <Tab label={languageContext.getMessage("details")} value='details' /> */}
              </Tabs>
            </Toolbar>
          </Grid>

          {tabIndex === 'all' && 
            <Grid item xs={12}>
              <ChangeOrderCounterClaimViewAll
                selectedAccount={selectedAccount}
                selectedContract={selectedContract}
                selectedStatus={selectedStatus}
                onSelectedAccountChange={onSelectedAccountChange}
                onSelectedContractChange={onSelectedContractChange}
                onSelectedStatusChange={onSelectedStatusChange}
                onSelectedChangeOrderCounterClaimChange={onSelectedChangeOrderCounterClaimChange}/>
            </Grid>
          }

          {tabIndex === 'details' &&
            <Grid item xs={12}>
              <ChangeOrderCounterClaimDetailsView
                selectedChangeOrderCounterClaim={selectedChangeOrderCounterClaim} 
                onSelectedChangeOrderCounterClaimChange={onSelectedChangeOrderCounterClaimChange} />
            </Grid>
          }
        </Grid>
  );
}

export default withTheme(ChangeOrderCounterClaimView);

