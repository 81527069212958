
import React, { createContext, useContext, useEffect, useState } from "react";
import { Guid } from "../../utils/common-types"
import { Risk, ActiveState, CurrencyType, RiskType, ConsequenceType, ProbType } from "../../contracts/contracts";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useProjectContext } from "../project/projectContext";
import { useUrlContext } from "../url/urlContext";
import { RiskMutationsContextProvider, useRiskMutationsContext } from "./mutations/riskMutationsContext";
import { RiskQueriesContextContext, useRiskQueriesContext } from "./queries/riskQueriesContext";
import { RiskSubscriptionsContextProvider, useRiskSubscriptionsContext } from "./subscriptions/riskSubscriptionsContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { queryTemplateEngineToProduceRiskExcelList } from "./RiskExportTools";

export interface RiskContext {
    getRiskSearch: () => Risk,
    searchRisks: (riskSearch: Risk) => void,
    getRisks: () => Array<Risk>,
    addRisk: (name: string, description:string, riskType: RiskType, 
        consequence: number, consequenceType: ConsequenceType, probability:number,
        probabilityType: ProbType, useWithForecast: boolean) => void,
    getRisk: (id: string | undefined) => Risk | undefined,
    updateRisk: (risk: Risk) => void,
    downloadRisks: (risks: Risk[]) => void,
}

const RiskContext = createContext<RiskContext>(null as unknown as RiskContext);

const sortRiskByName = (a: Risk, b: Risk) => {
    if ((a.name ?? '') < (b.name ?? '')) { return -1; }
    if ((a.name ?? '') > (b.name ?? '')) { return 1; }
    return 0;
}

export const RiskContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <RiskMutationsContextProvider>
            <RiskQueriesContextContext>
                <RiskSubscriptionsContextProvider>
                    <RiskSubContextProvider>
                        {children}
                    </RiskSubContextProvider>
                </RiskSubscriptionsContextProvider>
            </RiskQueriesContextContext>
        </RiskMutationsContextProvider>
    );
}

export const RiskSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const projectContext = useProjectContext();
    const languageContext = useLanguageContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const riskMutationsContext = useRiskMutationsContext();
    const riskQueriesContext = useRiskQueriesContext();
    const riskSubscriptionsContext = useRiskSubscriptionsContext();
    const [currentRiskSearch, setCurrentRiskSearch] = useState<Risk | undefined>(undefined);
    const [risks, setRisks] = useState<Array<Risk>>([]);

    const mergeRisks = (oldRisks: Array<Risk>, newRisks: Array<Risk>): Array<Risk> => {
        let updatedRisks = oldRisks.slice();
        if (!newRisks) {
            console.error(`Received undefined set of risks: ${newRisks}`);
            return [];
        }
        newRisks.forEach(newRisk => {
            if (newRisk.projectId !== currentRiskSearch?.projectId) {
                return;
            }
            newRisk.created = new Date(newRisk.created ?? 0);
            const index = updatedRisks.findIndex(risk => risk.id === newRisk.id);
            if (index >= 0) {
                if (newRisk.state === ActiveState.ACTIVE) {
                    updatedRisks[index] = newRisk;
                }
                else {
                    updatedRisks.splice(index, 1);
                }
            } 
            else {
                if (newRisk.state === ActiveState.ACTIVE) {
                    updatedRisks.push(newRisk); 
                }
            }
        });
        return updatedRisks.sort(sortRiskByName);
    }

    const getRiskSearch = (): Risk => {
        const urlState = urlContext.getUrlState();

        const projectId = urlState.projectId ? urlState.projectId as string : projectContext.getSelectedProject()?.id;
    
        return {
            projectId: projectId,
        }
    }

    const searchRisks = (riskSearch: Risk): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(riskSearch, currentRiskSearch);
        matched = matched && riskSearch?.projectId === currentRiskSearch?.projectId;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && !matched) {
            setCurrentRiskSearch(riskSearch);
            setRisks([]);
        }
    }

    const getRisks = (): Array<Risk> => {
        const projectId = projectContext.getSelectedProject()?.id;
        if (!projectId) return [];
        return risks.filter(risk => risk.projectId === projectId);
    }

    const updateRisk = (risk: Risk): void => {
        riskMutationsContext.mutateRisk(risk, (documentId, variables) => {
            risk = {...risk, ...variables};
            risk.id = documentId;
            setRisks(mergeRisks(risks, [risk]));
        });
    }

    const addRisk = (
        name: string,
        comment: string, 
        riskType: RiskType, 
        consequence: number, 
        consequenceType: ConsequenceType, 
        probability: number,
        probabilityType: ProbType, 
        useWithForecasts: boolean): void => {

        let projectId = projectContext.getSelectedProject()?.id;
        if (!projectId) {
            //TODO (lars): Add error handling.
            throw Error('No selected project');
        }

        let risk: Risk = {
            state: ActiveState.ACTIVE,
            created: new Date(),
            projectId: projectId,
            name: name,
            comment: comment,
            riskType: riskType,
            consequence: consequence,
            consequenceType: consequenceType,
            probability: probability,
            probabilityType: probabilityType,
        }; 

        updateRisk(risk);
    }

    const getRisk = (id: string | undefined): Risk | undefined => {
        return risks.find(risk => risk.id === id);
    }

    const downloadRisks = (risks: Risk[]): void => {
        queryTemplateEngineToProduceRiskExcelList(
            risks, 
            templateEngineQueriesContext, 
            languageContext);
    }

    useEffect(() => {
        if (currentRiskSearch) {
            riskQueriesContext.queryRisks(currentRiskSearch);
        }
    }, [currentRiskSearch]);
    
    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setRisks([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setRisks(mergeRisks([], riskQueriesContext.fetchedRisks));
    }, [riskQueriesContext.fetchedRisks]);

    useEffect(() => {
        setRisks(mergeRisks(risks, riskSubscriptionsContext.subscribedRisks));
    }, [riskSubscriptionsContext.subscribedRisks]);
    
    const riskContext: RiskContext = {
        getRiskSearch,
        searchRisks,
        getRisks,
        addRisk,
        getRisk,
        updateRisk,
        downloadRisks,
    };

    return (
        <RiskContext.Provider value={riskContext}>
            {children}
        </RiskContext.Provider>
    );
}

export const useRiskContext = (): RiskContext => {
    return useContext(RiskContext);
}