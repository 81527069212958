import { Avatar, makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useUserContext } from '../../contexts/user/UserContext';
import { useAuthContext } from '../../contexts/auth/authContext';
import { navigateToExternalUrl } from '../../utils/urlTools';

const useStyles = makeStyles(() => ({
    avatar: {
        marginLeft: '10px',
        width: '1.5em',
        height: '1.5em',
    }
}));

const UserMenu: React.FC<{}> = () => {

    const classes = useStyles();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const userContext = useUserContext();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOnClickSignIn = () => {
        authContext.login();
    };

    const handleOnClickSignOut = () => {
        authContext.logout();
        handleCloseMenu();
    };

    const handleOnClickProfile = () => {
        if (authContext.authSettings) {
            navigateToExternalUrl(authContext.authSettings.accountSettingsUrl ?? '');
        }
        handleCloseMenu();
    };

    const getUserName = (): string => {
        return userContext.userState?.accountInfo?.name ?? '';
    };
    const getFirstLetterOfUserName = (): string => {
        const userName = getUserName();
        if (userName.length > 0) {
            return userName[0];
        }
        return "U";
    };

    return (
        <>
            {authContext.authenticated === false ? (
                <Button disabled={!authContext.authReady} variant="contained" color="primary" onClick={handleOnClickSignIn}>{languageContext.getMessage('signIn')}</Button>
            ) : (
                    <>
                        <Button onClick={handleClickMenu}>
                            {getUserName()}
                            <Avatar className={classes.avatar}>{getFirstLetterOfUserName()}</Avatar>
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}>

                            <MenuItem onClick={handleOnClickProfile}>{languageContext.getMessage('profile')}</MenuItem>
                            <MenuItem onClick={handleOnClickSignOut}>{languageContext.getMessage('signOut')}</MenuItem>
                        </Menu>
                    </>
                )}
        </>
    );
}

export default UserMenu;