import { withTheme } from '@material-ui/core';
import React from "react";
import { useChangeOrderLandlordContext } from '../../contexts/changeOrderLandlord/changeOrderLandlordContext';
import { useThemeContext } from '../../contexts/theme/ThemeContext';
import { ChangeOrderLandlord, ChangeOrderStatus, ElementStatusEnum } from "../../contracts/contracts";
import { getRenderedElementStatus } from '../../utils/statusToPalettes';

type props = {
    changeOrderLandlord?: ChangeOrderLandlord;
    small?: boolean
}

const ChangeOrderLandlordStatus: React.FC<props> = ({
    changeOrderLandlord,
    small
}) => {
    const changeOrderLandlordContext = useChangeOrderLandlordContext();
    const theme = useThemeContext().getTheme();


    const convertChangeOrderStatusToElementStatus = (status?: ChangeOrderStatus): ElementStatusEnum => {
        switch (status) {
            case ChangeOrderStatus.APPROVED: return ElementStatusEnum.SUCCESS;
            case ChangeOrderStatus.NOT_APPROVED: return ElementStatusEnum.CANCEL;
            case ChangeOrderStatus.NOT_PROCESSED: return ElementStatusEnum.INFORMATION;
        }

        return ElementStatusEnum.ALERT;
    }

    const elementStatus = convertChangeOrderStatusToElementStatus(changeOrderLandlord?.status);

    if (small) {
        return getRenderedElementStatus(elementStatus, theme);
    } else {
        return (<div style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
            {getRenderedElementStatus(elementStatus, theme)} &nbsp; {changeOrderLandlordContext.convertChangeOrderStatusToString(changeOrderLandlord?.status)}
        </div>)
    }
}

export default withTheme(ChangeOrderLandlordStatus);