import { Accordion, AccordionDetails, AccordionSummary, Typography, withTheme } from "@material-ui/core";
import { ChartOptions } from "chart.js";
import React from "react";
import { Bar, ChartData, Line } from "react-chartjs-2";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { sortChangeOrderSubContractorByDate, useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { LanguageContext, useLanguageContext } from "../../contexts/language/LanguageContext";
import { Account, ChangeOrderSubContractor, ChangeOrderStatus, Contract, CurrencyType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { datesAreOnSameMonth } from "../../utils/dateTools";
import { ColorArray, getRandomColorFromString } from "../../utils/randomTools";
import { ContractContext, useContractContext } from "../../contexts/contract/contractContext";

type props = {
    selectedContract?: Contract | undefined;
    selectedAccount?: Account | undefined;
    selectedStatus?: ChangeOrderStatus | undefined;
    showCosts?: boolean;
    showTotalCosts?: boolean;
    defaultExpanded?: boolean;
}
  
const ChangeOrderSubContractorsPerMonthGraph: React.FC<props> = ({
    selectedContract,
    selectedAccount,
    selectedStatus, 
    showCosts, 
    showTotalCosts, 
    defaultExpanded}) => {
    showCosts = showCosts ?? false;
    showTotalCosts = showTotalCosts ?? false;
    defaultExpanded = defaultExpanded ?? false;
    const languageContext = useLanguageContext();
    const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
    const contractContext = useContractContext();

    let changeOrderSubContractors: ChangeOrderSubContractor[] = [];
    let selectedContracts: Contract[] = [];
    if (selectedContract) {
        selectedContracts.push(selectedContract);
    }
    else {
        selectedContracts = contractContext.getContracts(selectedAccount?.id);
    }

    if (selectedContracts.length === 0) {
        changeOrderSubContractors = changeOrderSubContractorContext.getChangeOrderSubContractors(selectedContract?.id, selectedStatus);
    }
    else {
        selectedContracts.forEach(contract => {
            changeOrderSubContractors = changeOrderSubContractors.concat(changeOrderSubContractorContext.getChangeOrderSubContractors(contract?.id, selectedStatus))
        })
    }

    const sortedChangeOrderSubContractors = sortChangeOrderSubContractorsPerMonth(changeOrderSubContractors);
    const chartData = getChangeOrderSubContractorGraphData(contractContext, changeOrderSubContractors, sortedChangeOrderSubContractors, languageContext, showCosts, showTotalCosts);
    const chartOptions = getChangeOrderSubContractorGraphOptions(languageContext);

    let title = languageContext.getMessage('quantityChangeOrders');
    let showLineGraph = false;
    if (showCosts || showTotalCosts) {
        const labelsLength = (chartData as any).labels.length
        title = showTotalCosts ? 
            `${languageContext.getMessage('accumulated')} ${languageContext.getMessage('approved_plural')} ${languageContext.getMessage('changeOrders')}` : 
            `${languageContext.getMessage('accumulated')} ${languageContext.getMessage('changeOrders')} ${languageContext.getMessage('per')} ${languageContext.getMessage('contract')}`;
        if (labelsLength > 1) {
            showLineGraph = true;
        }
    }

    return <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {showLineGraph &&
                <Line data={chartData} height={400} options={chartOptions} />
            }
            {!showLineGraph &&
                <Bar data={chartData} height={400} options={chartOptions} />
            }
        </AccordionDetails>
    </Accordion>
}

const sortChangeOrderSubContractorsPerMonth = (changeOrderSubContractors: ChangeOrderSubContractor[]): Dictionary<ChangeOrderSubContractor[]> => {
    const sortedChangeOrderSubContractors: Dictionary<ChangeOrderSubContractor[]> = {};
    changeOrderSubContractors.forEach(changeOrderSubContractor => {
        if (!changeOrderSubContractor.received) {
            return;
        }
        let foundMatch = false;
        for (const key in sortedChangeOrderSubContractors) {
            const date = new Date(Number(key));
            if (datesAreOnSameMonth(date, changeOrderSubContractor.received)) {
                foundMatch = true;
                sortedChangeOrderSubContractors[key].push(changeOrderSubContractor);
                return;
            }
        }
        if (!foundMatch) {
            sortedChangeOrderSubContractors[changeOrderSubContractor.received.getTime()] = [changeOrderSubContractor];
        }
    })
    return sortedChangeOrderSubContractors;
} 

const getChangeOrderSubContractorGraphData = (
    contractContext: ContractContext,
    allChangeOrderSubContractors: ChangeOrderSubContractor[], 
    sortedChangeOrderSubContractors: Dictionary<ChangeOrderSubContractor[]>, 
    languageContext: LanguageContext, 
    showCosts: boolean, 
    showTotalCosts: boolean): ChartData<Chart.ChartData> => {
    allChangeOrderSubContractors = allChangeOrderSubContractors.sort(sortChangeOrderSubContractorByDate)

    const labels: string[] = [];
    const data: number[] = [];
    const contractChangeOrderSubContractorsCount: Dictionary<number[]> = {};
    const contractChangeOrderSubContractorsCost: Dictionary<number[]> = {};
    const datasets: Chart.ChartDataSets[] = [];
    let totalCosts: number = 0;

    allChangeOrderSubContractors.forEach(changeOrderSubContractor => {
        if (changeOrderSubContractor.contractId) {
            contractChangeOrderSubContractorsCount[changeOrderSubContractor.contractId] = []
            contractChangeOrderSubContractorsCost[changeOrderSubContractor.contractId] = []
        }
    });

    for (const key in sortedChangeOrderSubContractors) {
        const date = new Date(Number(key));
        labels.push(date.toLocaleString(languageContext.getLanguage(), {month: 'long'}))
        let changeOrderSubContractors: ChangeOrderSubContractor[] = sortedChangeOrderSubContractors[key];
        changeOrderSubContractors = changeOrderSubContractors.sort(sortChangeOrderSubContractorByDate)
        let costs = 0;
        changeOrderSubContractors.forEach(changeOrderSubContractor => {
            costs += changeOrderSubContractor.cost ?? 0;
        })
        totalCosts += costs;
        data.push(totalCosts);
        for (const contractIdKey in contractChangeOrderSubContractorsCount) {
            contractChangeOrderSubContractorsCount[contractIdKey].push(0);
            if (contractChangeOrderSubContractorsCost[contractIdKey].length > 0) {
                const previousCosts = contractChangeOrderSubContractorsCost[contractIdKey][contractChangeOrderSubContractorsCost[contractIdKey].length-1]
                contractChangeOrderSubContractorsCost[contractIdKey].push(previousCosts);
            }
            else {
                contractChangeOrderSubContractorsCost[contractIdKey].push(0);
            }
            changeOrderSubContractors.forEach(changeOrderSubContractor => {
                if (changeOrderSubContractor.contractId && changeOrderSubContractor.contractId === contractIdKey) {
                    contractChangeOrderSubContractorsCount[contractIdKey][contractChangeOrderSubContractorsCount[contractIdKey].length-1] += 1
                    contractChangeOrderSubContractorsCost[contractIdKey][contractChangeOrderSubContractorsCost[contractIdKey].length-1] += changeOrderSubContractor.cost ?? 0;
                }
            })
        }
    }
    if (showTotalCosts) {
        datasets.push({
            label: languageContext.getMessage('totalCosts'),
            data: data,
            backgroundColor: ColorArray[0],
            borderColor: ColorArray[0],
            fill: false,
        })
    }
    else {
        for (const contractIdKey in contractChangeOrderSubContractorsCount) {
            const color = getRandomColorFromString(contractIdKey);
            datasets.push({
                label: contractContext.getContract(contractIdKey)?.name,
                data: showCosts ? contractChangeOrderSubContractorsCost[contractIdKey] : contractChangeOrderSubContractorsCount[contractIdKey],
                backgroundColor: color,
                borderColor: color,
                stack: 'contractIds',
                fill: false,
            })
        }
    }

    return {
        labels: labels,
        datasets: datasets
    }
};
  
let getChangeOrderSubContractorGraphOptions = (languageContext: LanguageContext, currency?: CurrencyType): ChartOptions => {
    currency = currency ?? CurrencyType.NOK;
    const amountLabel = languageContext.getMessage('amount') + ` (${currency})`;
    return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'bottom',
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: languageContext.getMessage('month'),
                },
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: amountLabel,
                }
            }]
        }
    }
};

export default withTheme(ChangeOrderSubContractorsPerMonthGraph);