import { Button, Grid, IconButton, makeStyles, Theme, Toolbar, Tooltip, Typography, withTheme } from "@material-ui/core";
import { Add, Info } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLazyQuery } from "@apollo/react-hooks";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import MaterialTable, { Column, Components, MTableToolbar } from "material-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { sortContractByName, useContractContext } from "../../contexts/contract/contractContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { ChangeOrderExpected, ChangeOrderStatus, Account, ElementStatusEnum, RoleType, Contract, StorageFile, DocumentType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { tableIcons } from "../../ui/table-icons";
import { getPageSizeOptions, getRandomGuid, resolveDefaultPageSize } from "../../utils/randomTools";
import { getElementStausColor, getRenderedElementStatus } from "../../utils/statusToPalettes";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import TicketLoading from "../generalComponents/TicketLoading";
import EditChangeOrderExpectedDialog from "./EditChangeOrderExpectedDialog";
import EditChangeOrderExpectedGroupDialog from "./EditChangeOrderExpectedGroupDialog";
import { CustomTableStyle } from "../../utils/common-types";
import ContentTooltip from "../generalComponents/ContentTooltip";
import { useRememberTableScroll } from "../../hooks/useRememberTableScroll";
import { sortAccountByAccountName } from "../../utils/accountTools";
import { getStorageFilesGraphqlQueryOptions, QUERY_STORAGE_FILES } from "../../contexts/storageFile/queries/storageFileQueries";
import { getStorageFileKey, onUploadFile } from "../../utils/fileTools";
import FileUploadDialog from "../generalComponents/FileUploadDialog";
import StorageFileDialog from "../generalComponents/StorageFileDialog";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: "1em",
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const noneParentGroup = "None";
export type GroupedChangeOrderExpected = ChangeOrderExpected & {
  parentGroup?: string;
  numberInGroup?: number;
};

type props = {
  selectedAccount?: Account | undefined;
  selectedContract?: Contract | undefined;
  selectedStatus?: ChangeOrderStatus | undefined;
  onSelectedChangeOrderExpectedChange?: (newSelectedChangeOrderExpected: ChangeOrderExpected | undefined) => void;
  showTitle?: boolean;
  hideToolbar?: boolean;
};

const ChangeOrderExpectedList: React.FC<props> = ({ selectedAccount, selectedContract, selectedStatus, onSelectedChangeOrderExpectedChange, showTitle, hideToolbar }) => {
  const classes = useStyles();

  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const languageContext = useLanguageContext();
  const { getLocalization } = useMaterialTableLanguageContext();
  const themeContext = useThemeContext();
  const projectContext = useProjectContext();
  const theme = themeContext.getTheme();

  const urlContext = useUrlContext();
  const urlState = urlContext.getUrlState();
  const expandedGroupsSeparator = ";";
  const expandedGroups = urlState.expandedGroups ? (urlState.expandedGroups as string).split(expandedGroupsSeparator) : [];

  const getSecureGroupName = (group: string): string => (group = group.trim().length > 0 ? group : noneParentGroup);

  const groupIsExpanded = (group: string): boolean => {
    group = getSecureGroupName(group);
    return expandedGroups.findIndex((expandedGroup) => expandedGroup === group) >= 0;
  };

  const updateUrlStateWithExpandedGroups = (group: string, expanded: boolean): void => {
    group = getSecureGroupName(group);
    const index = expandedGroups.findIndex((expandedGroup) => expandedGroup === group);
    if (index >= 0 && !expanded) {
      expandedGroups.splice(index, 1);
    } else if (index < 0 && expanded) {
      expandedGroups.push(group);
    }
    const newUrlState = {
      ...urlState,
      ...{ expandedGroups: expandedGroups.length > 0 ? expandedGroups.join(expandedGroupsSeparator) : undefined },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const renderToolbar = (props: any) => (
    <div>
      <MTableToolbar {...props} />
      <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
        <Button color="inherit" size="small" className={classes.button} startIcon={<Add />} onClick={() => openEditChangeOrderExpectedDialog()}>
          {`${languageContext.getMessage("newExpectedChange")}`}
        </Button>
      </Toolbar>
    </div>
  );

  const smallWindow = useWidthContext().isMobileScreen();

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [changeOrderExpectedToEdit, setChangeOrderExpectedToEdit] = useState<ChangeOrderExpected>();
  const [showEditChangeOrderGroupDialog, setShowEditChangeOrderGroupDialog] = useState<boolean>(false);
  const [selectedChangeOrderGroup, setSelectedChangeOrderGroup] = useState<string | undefined>(undefined);
  // let changeOrderExpecteds = changeOrderExpectedContext.getChangeOrderExpecteds(selectedAccount?.id, selectedStatus);
  // if (selectedContract?.id) {
  //     changeOrderExpecteds = changeOrderExpecteds.filter(changeOrderExpected => changeOrderExpected.contractId === selectedContract.id);
  // }

  const changeOrderExpecteds = useMemo(() => {
    var resolvedChangeOrders: GroupedChangeOrderExpected[] = [];

    var resolvedGroups: GroupedChangeOrderExpected[] = [];
    var foundChangeOrder: GroupedChangeOrderExpected = {};
    var groupId: string = "";

    resolvedChangeOrders = changeOrderExpectedContext.getChangeOrderExpecteds(selectedAccount?.id, selectedStatus) as GroupedChangeOrderExpected[];
    if (selectedContract?.id) {
      resolvedChangeOrders = resolvedChangeOrders.filter((changeOrderExpected) => changeOrderExpected.contractId === selectedContract.id);
    }
    resolvedChangeOrders.forEach((changeOrder) => {
      var foundIndex = resolvedGroups.findIndex((existingGroup) => existingGroup.group === changeOrder.group);
      if (foundIndex < 0) {
        groupId = getRandomGuid();
        foundChangeOrder = {
          parentGroup: noneParentGroup,
          numberInGroup: 0,
          id: groupId,
          group: changeOrder.group,
          name: "Total",
          cost: 0,
          adjustedCost: 0,
        };
        foundIndex = resolvedGroups.push(foundChangeOrder);
      } else {
        foundChangeOrder = resolvedGroups[foundIndex];
      }
      if (foundChangeOrder && foundChangeOrder.id) {
        foundChangeOrder.numberInGroup = (foundChangeOrder.numberInGroup ?? 0) + 1;
        foundChangeOrder.cost = (foundChangeOrder?.cost ?? 0) + (changeOrder.cost ?? 0);
        foundChangeOrder.adjustedCost = (foundChangeOrder?.adjustedCost ?? 0) + (changeOrder.adjustedCost ?? 0);
        groupId = foundChangeOrder.id;
      }
      changeOrder.parentGroup = groupId;
    });
    resolvedChangeOrders = [...resolvedChangeOrders, ...resolvedGroups];

    return resolvedChangeOrders;
  }, [changeOrderExpectedContext]);

  const [currentChangeOrderId, setCurrentChangeOrderId] = useState<string>("");
  const [changeOrderFiles, setChangeOrderFiles] = useState<StorageFile[]>([]);
  const [changeOrderImages, setChangeOrderImages] = useState<string[]>([]);
  const [changeOrderImageFilenames, setChangeOrderImageFilenames] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [openFileList, setOpenFileList] = useState<boolean>(false);

  const ChangeOrderImagesStorageFileKey = getStorageFileKey(DocumentType.CHANGE_ORDER_EXPECTED, currentChangeOrderId ?? "");

  function loadChangeOrderFiles(changeOrderId: string) {
    var storageFileKey = getStorageFileKey(DocumentType.CHANGE_ORDER_EXPECTED, changeOrderId);
    loadChangeOrderImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: storageFileKey }));
  }

  const refreshChangeOrderFiles = () => {
    if (!currentChangeOrderId) {
      return;
    }
    loadChangeOrderImagesQuery(getStorageFilesGraphqlQueryOptions({ searchKey: ChangeOrderImagesStorageFileKey }));
  };

  const [loadChangeOrderImagesQuery, queryChangeOrderImagesResponse] = useLazyQuery(QUERY_STORAGE_FILES);

  const onDeletedChangeOrderFile = (deletedFile: StorageFile): void => {
    setChangeOrderFiles(changeOrderFiles.filter((file) => file.id !== deletedFile.id));
  };

  useEffect(() => {
    var storageFiles: StorageFile[] = queryChangeOrderImagesResponse.data?.storageFiles ?? [];
    setChangeOrderFiles(storageFiles);
  }, [queryChangeOrderImagesResponse.data]);

  const openEditChangeOrderExpectedDialog = (changeOrderExpectedToEdit?: ChangeOrderExpected) => {
    setChangeOrderExpectedToEdit(changeOrderExpectedToEdit ?? { accountId: selectedAccount?.id });
    setShowDialog(true);
  };

  const onCloseChangeOrderExpectedDialog = () => {
    setShowDialog(false);
    setShowEditChangeOrderGroupDialog(false);
  };

  const onEditChangeOrderGroup = (changeOrderGroup: string | undefined) => {
    setSelectedChangeOrderGroup(changeOrderGroup);
    setShowEditChangeOrderGroupDialog(true);
  };

  const [pageSize, setPageSize] = useState<number>(1);
  const tableRef = useRef(null) as any;
  useRememberTableScroll(tableRef, "changeOrderExpectedList");
  const pageSizeOptions = getPageSizeOptions(changeOrderExpecteds.length);
  useEffect(() => {
    if (pageSizeOptions.length > 1 && pageSize < pageSizeOptions[pageSizeOptions.length - 1]) {
      const newPageSize = resolveDefaultPageSize(pageSizeOptions, changeOrderExpecteds.length);
      tableRef.current.dataManager.changePageSize(newPageSize);
      setPageSize(newPageSize);
    }
  }, [changeOrderExpectedContext.getChangeOrderExpecteds()]);

  const renderGroupbar = (props: any) => <div></div>;

  const components: Components = {
    Groupbar: renderGroupbar,
  };
  if (projectContext.hasProjectAccess(RoleType.WRITER)) {
    components.Toolbar = renderToolbar;
  }

  const getColumns = (): Column<GroupedChangeOrderExpected>[] => {
    return getColumnsExpected();
  };

  const getColumnsExpected = (): Column<GroupedChangeOrderExpected>[] => {
    return [
      //   {
      //     title: languageContext.getMessage("group"),
      //     field: "group",
      //     defaultGroupOrder: 0,
      //     render: (changeOrderGroup) => (
      //       <>
      //         <Tooltip title={languageContext.getMessage("edit")} key={changeOrderGroup as string | undefined}>
      //           <IconButton onClick={() => onEditChangeOrderGroup(changeOrderGroup as string | undefined)}>
      //             <EditIcon />
      //           </IconButton>
      //         </Tooltip>
      //       </>
      //     ),
      //   },
      //   {
      //     title: languageContext.getMessage("expectedChange"),
      //     field: "name",
      //     render: (changeOrderExpected) => <TicketLoading documentId={changeOrderExpected.id} component={changeOrderExpected.name} />,
      //   },
      {
        title: <b>{languageContext.getMessage("group")}</b>,
        field: "parentGroup",
        cellStyle: CustomTableStyle,
        render: (changeOrder) => (
          <>
            <Grid container wrap="nowrap" spacing={1} alignItems="center">
              {changeOrder.parentGroup === noneParentGroup ? (
                <>
                  <Grid item>
                    <Tooltip title={languageContext.getMessage("edit")} key={changeOrder?.group as string | undefined}>
                      <IconButton onClick={() => onEditChangeOrderGroup(changeOrder?.group as string | undefined)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography>{changeOrder?.group}</Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Tooltip title={languageContext.getMessage("edit")} key={changeOrder?.group as string | undefined}>
                      <IconButton
                        onClick={() => {
                          setChangeOrderExpectedToEdit({ ...(changeOrder as ChangeOrderExpected) });
                          setShowDialog(true);
                        }}
                        disabled={!projectContext.hasProjectAccess(RoleType.WRITER)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography>{`${changeOrder.name}`}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        ),
      },
      {
        title: languageContext.getMessage("document"),
        field: "name",
        align: 'center',
        cellStyle: CustomTableStyle,
        render: (changeOrderExpected) => {
          if (changeOrderExpected.parentGroup !== noneParentGroup) {
            return (
              <div style={{ alignContent: "right" }}>
                <IconButton
                  color="primary"
                  disabled={!projectContext.checkIfProjectFileExists(changeOrderExpected.id)}
                  size="small"
                  onClick={() => {
                    if (changeOrderExpected.id) {
                      loadChangeOrderFiles(changeOrderExpected.id);
                      setOpenFileList(true);
                    }
                  }}
                >
                  <GetAppIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    setIsDialogOpen(true);
                    setCurrentChangeOrderId(changeOrderExpected.id ?? "");
                  }}
                >
                  <PublishIcon />
                </IconButton>
              </div>
            );
          }
        },
      },
      {
        title: languageContext.getMessage("cost"),
        field: "cost",
        type: "numeric",
        cellStyle: CustomTableStyle,
        render: (changeOrderExpected) => <CurrencyFormat amount={changeOrderExpected.cost} />,
      },
      {
        title: languageContext.getMessage("remainingExpectedCost"),
        field: "cost",
        type: "numeric",
        cellStyle: CustomTableStyle,
        align: "right",
        render: (changeOrderExpected) => {
          return (
            <Grid container wrap="nowrap" spacing={1} alignItems="center" justify="flex-end">
              <Grid item>
                <CurrencyFormat amount={changeOrderExpected.adjustedCost} />
              </Grid>
              <Grid item>
                <Tooltip title={languageContext.getMessage("usedInForecast")}>
                  <Info style={{ color: getElementStausColor(ElementStatusEnum.INFORMATION, theme).main }} />
                </Tooltip>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: languageContext.getMessage("description"),
        cellStyle: CustomTableStyle,
        field: "changeOrderDescription",
        render: (changeOrder) => <ContentTooltip>{changeOrder.changeOrderDescription}</ContentTooltip>
      },
      {
        title: languageContext.getMessage("comments"),
        cellStyle: CustomTableStyle,
        field: "changeOrderComment",
        render: (changeOrder) => <ContentTooltip>{changeOrder.changeOrderComment}</ContentTooltip>
      },
      {
        title: languageContext.getMessage("account"),
        field: "accountId",
        cellStyle: CustomTableStyle,
        customSort: (a, b) => sortAccountByAccountName(accountContext.getAccount(a.accountId) ?? {}, accountContext.getAccount(b.accountId) ?? {}),
        render: (changeOrderExpected) => accountContext.getAccount(changeOrderExpected.accountId)?.name,
      },
      {
        title: languageContext.getMessage("contract"),
        field: "contractId",
        cellStyle: CustomTableStyle,
        customSort: (a, b) => sortContractByName(contractContext.getContract(a.contractId) ?? {}, contractContext.getContract(b.contractId) ?? {}),
        render: (changeOrderExpected) => contractContext.getContract(changeOrderExpected.contractId)?.name,
      },
      {
        title: languageContext.getMessage("created"),
        field: "created",
        cellStyle: CustomTableStyle,
        type: "date",
      },
      {
        title: languageContext.getMessage("expectedDate"),
        field: "expected",
        cellStyle: CustomTableStyle,
        type: "date",
      },
      //   {
      //     title: languageContext.getMessage("columnStatus"),
      //     field: "elementStatus",
      //     hidden: true,
      //     render: (rowData) => (
      //       <div style={{ display: "inline-flex", verticalAlign: "middle" }}>
      //         {getRenderedElementStatus(rowData.elementStatus ?? ElementStatusEnum.ALERT, theme)} &nbsp; {changeOrderExpectedContext.convertChangeOrderStatusToString(rowData.status)}
      //       </div>
      //     ),
      //   },
    ];
  };

  return (
    <>
      {openFileList && <StorageFileDialog open={openFileList} onClose={() => setOpenFileList(false)} files={changeOrderFiles} onDeletedFile={onDeletedChangeOrderFile} />}
      {isDialogOpen && (
        <FileUploadDialog
          title={languageContext.getMessage("uploadFiles")}
          description={""}
          dropAreaText={languageContext.getMessage("dragAndDropFiles")}
          imagesStorageFileKey={getStorageFileKey(DocumentType.CHANGE_ORDER_EXPECTED, currentChangeOrderId)}
          onClose={() => setIsDialogOpen(false)}
          groups={[
            {
              key: languageContext.getMessage("files"),
              name: languageContext.getMessage("file"),
              pluralName: languageContext.getMessage("files"),
              extensions: [".png", ".jpg", ".jpeg", ".md", ".txt", ".pdf", ".xls", ".xlxs", ".csv"],
            },
          ]}
          defaultGroup={{
            key: "default",
            name: "document",
            pluralName: "documents",
            extensions: [".jpeg", ".png", ".jpeg", ".pdf"],
          }}
          onUploadFile={onUploadFile}
          setImages={setChangeOrderImages}
          setImageFilenames={setChangeOrderImageFilenames}
          existingFileNames={changeOrderImageFilenames}
          onUploadCompleted={() => refreshChangeOrderFiles()}
        />
      )}
      <EditChangeOrderExpectedGroupDialog open={showEditChangeOrderGroupDialog} onClose={onCloseChangeOrderExpectedDialog} changeOrderExpectedGroup={selectedChangeOrderGroup} />
      <EditChangeOrderExpectedDialog changeOrderExpected={changeOrderExpectedToEdit} open={showDialog} onClose={onCloseChangeOrderExpectedDialog} />
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        localization={getLocalization()}
        columns={getColumns()}
        data={changeOrderExpecteds}
        onTreeExpandChange={(data: GroupedChangeOrderExpected, isExpanded: boolean) => {
          updateUrlStateWithExpandedGroups(data.group ?? "", isExpanded);
        }}
        parentChildData={(row, rows) => {
          const rowData = row as any;
          rowData.tableData.isTreeExpanded = groupIsExpanded(rowData.group ?? "");
          return rows.find((a) => a.id === row.parentGroup);
        }}
        title={showTitle ? `${languageContext.getMessage("expectedChange")}` : ""}
        onChangeRowsPerPage={(newPageSize: number) => {
          setPageSize(newPageSize);
        }}
        options={{
          padding: "dense",
          paging: true,
          search: true,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptions,
          exportButton: { csv: true, pdf: false },
          exportCsv: (columns, renderData) => {
            changeOrderExpectedContext.downloadChangeOrderExpecteds(changeOrderExpecteds);
          },
          columnsButton: true,
          emptyRowsWhenPaging: false,
          toolbarButtonAlignment: "right",
          searchFieldAlignment: "right",
          detailPanelColumnAlignment: "left",
          actionsColumnIndex: smallWindow ? 0 : 0,
          //   grouping: hideToolbar === true ? false : true,

          //   groupTitle: (groupData) => {
          //     return groupData.value && groupData.value.length > 0 ? groupData.value : languageContext.getMessage("general");
          //   },
          //   groupRowSeparator: " ",
          headerStyle: { position: "sticky", top: 0, fontWeight: "bold" },
          maxBodyHeight: 800,
        }}
        components={hideToolbar === true ? undefined : components}
      // actions={[
      //   {
      //     icon: () => <NavigateNextIcon />,
      //     tooltip: languageContext.getMessage("details"),
      //     position: "row",
      //     hidden: !onSelectedChangeOrderExpectedChange,
      //     onClick: (event, changeOrderExpected) => {
      //       changeOrderExpected = changeOrderExpected as ChangeOrderExpected;
      //       if (changeOrderExpected && onSelectedChangeOrderExpectedChange) {
      //         onSelectedChangeOrderExpectedChange(changeOrderExpected);
      //       }
      //     },
      //   },
      //   {
      //     icon: () => <EditIcon />,
      //     tooltip: languageContext.getMessage("edit"),
      //     position: "row",
      //     hidden: !projectContext.hasProjectAccess(RoleType.WRITER),
      //     onClick: (event, changeOrderExpected) => {
      //       changeOrderExpected = changeOrderExpected as ChangeOrderExpected;
      //       if (changeOrderExpected) {
      //         openEditChangeOrderExpectedDialog(changeOrderExpected);
      //       }
      //     },
      //   },
      // ]}
      />
    </>
  );
};

export default withTheme(ChangeOrderExpectedList);
