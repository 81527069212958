import { Button, Card, CardContent, CircularProgress, Grid, Link, Paper, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useBrowserContext } from '../../contexts/browserContext/browserContext';
import { useGraphqlAuthSettingsContext } from '../../contexts/graphql/graphqlAuthSettingsContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import companyLogo from '../../data/companyLogo.png';
import { alphaNumericString } from '../../utils/stringTools';
import { useAuthContext } from '../../contexts/auth/authContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      //   maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    card: {
      padding: '1em'
    },
    button: {
      marginTop: '1em'
    },
    gridList: {
    },
    alignItemsAndJustifyContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

type props = {
}

const ProjectDashboardSelectTenant: React.FC<props> = ({ }) => {

  const classes = useStyles();
  const browserContext = useBrowserContext();
  const languageContext = useLanguageContext();
  const graphqlAuthSettingsContext = useGraphqlAuthSettingsContext();
  const authContext = useAuthContext();

  const [tenant, setTenant] = useState<string | undefined>(browserContext.getTenantName(true));

  const tenantIsSet = tenant && tenant.length > 0;
  const showWarningTenantDoesNotExist = graphqlAuthSettingsContext.tenantRealmExists !== undefined && !graphqlAuthSettingsContext.tenantRealmExists;

  const handleOnClickOk = (): void => {
    if (tenantIsSet) {
      browserContext.setTenantName(tenant ?? '');
    }
  }

  return (<>
    {(!authContext.authReady && browserContext.isTenantNameAvailable() && graphqlAuthSettingsContext.tenantRealmExists !== false) && <>
      <Card className={classes.card} elevation={4}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item>
            <Typography variant="h1" component="h1">AIMZ</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" component="h5">By Neate</Typography>
          </Grid>
          <Grid item>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      </Card>
    </>}

    {((!authContext.authReady && !browserContext.isTenantNameAvailable()) || graphqlAuthSettingsContext.tenantRealmExists === false) && <>
      <Paper className={classes.card} elevation={4}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item>
            <Typography variant="h1" component="h1">AIMZ</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" component="h5">By Neate</Typography>
          </Grid>
          <Grid item>
            <TextField
              error={showWarningTenantDoesNotExist}
              helperText={showWarningTenantDoesNotExist ? `${languageContext.getMessage('wrongTenant')}` : undefined}
              label={`${languageContext.getMessage('selectTenant')}`}
              value={tenant}
              onChange={(event) => setTenant(alphaNumericString((event.target.value as string)).toLowerCase())}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  handleOnClickOk();
                  ev.preventDefault();
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button onClick={handleOnClickOk}
              className={classes.button}
              disabled={!tenantIsSet}
              color="primary" variant="contained">
              {languageContext.getMessage('ok')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>}
  </>);
}

export default ProjectDashboardSelectTenant