import { Contract } from "../../contracts/contracts";
import { capitalizeString } from "../../utils/stringTools";
import { AccountContext } from "../account/accountContext";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

const CONTRACT_LIST_TEMPLATE_FILENAME = 'aimz/template_contract_list.xlsx'
const CONTRACT_LIST_TEMPLATE_SHEET_NAME = 'contract_list'

const sortContractByName = (a: Contract, b: Contract) => {
    if ((a.name ?? '') < (b.name ?? '')) { return -1; }
    if ((a.name ?? '') > (b.name ?? '')) { return 1; }
    return 0;
}

export const queryTemplateEngineToProduceContractExcelList = (
    contracts: Contract[], 
    templateEngineQueriesContext: TemplateEngineQueriesContext, 
    languageContext: LanguageContext, 
    accountContext: AccountContext): void => {
    const templateFilename: string = CONTRACT_LIST_TEMPLATE_FILENAME;
    const templateVariables: object[] = getTemplateEngineContractListReportVariables(contracts, languageContext, accountContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, `${languageContext.getMessage('contracts')}`);
}

const getTemplateEngineContractListReportVariables = (
    contracts: Contract[], 
    languageContext: LanguageContext, 
    accountContext: AccountContext): object[] => {
    const date: Date = new Date();
    const title: string = `${languageContext.getMessage('contracts')}`;
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return [{
        'tpl_name': CONTRACT_LIST_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': getContractListVariables(title, monthString, contracts, accountContext),
    }]
}

const getContractListVariables = (
    title: string, 
    monthString: string, 
    contracts: Contract[], 
    accountContext: AccountContext): object => {
    const listVariables = {
        'language': {},
        'title': title,
        'date': monthString,
        'contracts': contracts.sort(sortContractByName).map(contract => {
            return {
                ...contract, 
                account: accountContext.getAccount(contract.accountId)?.name ?? '',
            }
        }),
    }
    return listVariables;
} 
