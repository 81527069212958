import { DialogActions, FormControl, TextField, MenuItem, InputLabel, useTheme, Select, Typography, Slider, Box, MobileStepper, FormControlLabel, Switch, OutlinedInput, Checkbox, FormGroup, Grid, DialogContent, IconButton, Input } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withTheme from '@material-ui/styles/withTheme';
import React, { useEffect, useState } from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { RiskType, ProbType, Risk, ActiveState } from '../../contracts/contracts';
import { useRiskContext } from '../../contexts/risk/riskContext';
import NumberFormatField from '../generalComponents/NumberFormatField';
import CloseIcon from '@material-ui/icons/Close';
import { useProjectContext } from '../../contexts/project/projectContext';
import { useAccountContext } from '../../contexts/account/accountContext';
import ConfirmDeleteDialog from '../deleteComponent/ConfirmDeleteDialog';
import SelectAccount from '../accountComponents/SelectAccount';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  stepper: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  containerFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    flexGrow: 1,
    // margin: theme.spacing(1),
  },
  formControlTop: {
    marginTop: theme.spacing(1),
  },
  slider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function getStyles(subject: string, subjectName: string[], theme: Theme) {
  return {
    fontWeight:
      subjectName.indexOf(subject) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export interface RiskDialogProps {
  risk: Risk | undefined,
  open: boolean;
  onClose: () => void;
}

const RiskDialog: React.FC<RiskDialogProps> = (props: RiskDialogProps) => {

  const languageContext = useLanguageContext();
  const riskContext = useRiskContext();
  const projectContext = useProjectContext();
  const accountContext = useAccountContext();

  const classes = useStyles();
  const { risk, onClose, open } = props;
  const handlingNewRisk = risk === undefined || risk.id === undefined;
  const theme = useTheme();
  const [existingRisk, setExistingRisk] = React.useState<boolean>(false);
  const [canSave, setCanSave] = React.useState<boolean>(true);

  const [editedRisk, setEditedRisk] = React.useState<Risk | undefined>({});

  const projectId = projectContext.getSelectedProject();

  useEffect(() => {
    if (risk?.name && risk?.name.length > 0) {
      risk.changeComment = '';
      setExistingRisk(true);
      setCanSave(false);
    }
    setEditedRisk({ ...risk });
    setProbType(editedRisk?.riskVariable)
  }, [risk])

  useEffect(() => {
    if (editedRisk?.changeComment && editedRisk?.changeComment?.length > 1) {
      setCanSave(true);
    }
    else { setCanSave(false) };
  }, [editedRisk?.changeComment])

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      const mutatedRisk = { ...risk };
      mutatedRisk.state = ActiveState.INACTIVE;
      riskContext.updateRisk(mutatedRisk);
      onClose();
    }
    setOpenConfirmDeleteDialog(false);
  }

  const resetForm = () => {
    setActiveStep(0);
  }

  const handleClose = (save: boolean) => {
    if (save && editedRisk) {
      riskContext.updateRisk(editedRisk);
    }
    onClose();
    setTimeout(function () { resetForm(); }, 500);
  };

  const [checked, setChecked] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [probType, setProbType] = React.useState<ProbType>();
  const [useWithForecast, setUseWithForecast] = React.useState<boolean>(true);

  let title = risk ? languageContext.getMessage('new') : languageContext.getMessage('edit');
  title = `${title} ${languageContext.getMessage('RISK')}`;

  const marks = [
    {
      value: 500000,
      label: '500,000 NOK',
    },
    {
      value: 1000000,
      label: '1 MNOK',
    },
  ];

  const probMarks = [
    {
      value: 10,
      label: '10%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];

  const unitMarks = [
    {
      value: 500,
      label: '500',
    },
    {
      value: 1000,
      label: '1000',
    },
  ];

  function valuetext(value: number) {
    return `${value} %`;
  }
  function valuetextCost(value: number) {
    return `${value} NOK`;
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };


  const [state, setState] = React.useState({
    checkedMax: true,
    checkedMin: true,
    checkedMaxP: true,
    checkedMinP: true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <Dialog fullWidth={true}
        maxWidth={'xs'}
        onClose={handleClose}
        open={open}
        disableBackdropClick={true}>
        <DialogTitle>
          {title}
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {activeStep === 0 &&
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    label={languageContext.getMessage('title')}
                    value={editedRisk?.name ?? ''}
                    fullWidth
                    onChange={(event) => {
                      if (editedRisk) {
                        editedRisk.name = event.target.value;
                        editedRisk.projectId = projectId?.id;
                        setEditedRisk({ ...editedRisk });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth={true}>
                    <SelectAccount 
                      selectedAccount={accountContext.getAccount(editedRisk?.accountId)}
                      onSelectedAccountChange={account => {
                        if (editedRisk) {
                          editedRisk.accountId = account?.id;
                          setEditedRisk({ ...editedRisk });
                        }
                      }}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth={true}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={editedRisk?.riskType ? editedRisk?.riskType : ''}
                      onChange={(event) => {
                        if (editedRisk) {
                          editedRisk.riskType = event.target.value as RiskType;
                          setEditedRisk({ ...editedRisk });
                        }
                      }}
                    >
                      <MenuItem value={RiskType.RISK}>{languageContext.getMessage(RiskType.RISK)}</MenuItem>
                      <MenuItem value={RiskType.OPPORTUNITY}>{languageContext.getMessage(RiskType.OPPORTUNITY)}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth={true}>
                    <InputLabel>Variabel</InputLabel>
                    <Select
                      value={editedRisk?.probabilityType ?? ''}
                      onChange={(event) => {
                        let probChoice = event.target.value as ProbType;
                        if (editedRisk) {
                          editedRisk.probabilityType = probChoice
                          setEditedRisk({ ...editedRisk })
                          setProbType(probChoice);
                        }
                      }}
                    >
                      <MenuItem value={ProbType.PROBABILITY}>{languageContext.getMessage('probability')}</MenuItem>
                      <MenuItem value={ProbType.QUANTITIES}>{languageContext.getMessage(ProbType.QUANTITIES)}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField
                    label={languageContext.getMessage('description')}
                    multiline
                    rows={5}
                    fullWidth
                    value={editedRisk?.comment ?? ''}
                    onChange={(event) => {
                      if (editedRisk) {
                        editedRisk.comment = event.target.value
                        setEditedRisk({ ...editedRisk });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </>
          }

          {activeStep === 1 &&
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                  <Typography >
                    <b>{languageContext.getMessage('consequence')}</b>{editedRisk?.probabilityType == ProbType.QUANTITIES ? ' [NOK/per]' : ' [NOK]'}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth={true}>
                    <NumberFormatField
                      onInputChange={(event) => {
                        if (editedRisk?.consequenceMin) {
                          editedRisk.consequenceMin = Number(event.target.value)
                          setEditedRisk({ ...editedRisk });
                        }
                      }}
                      label="Min"
                      value={editedRisk?.consequenceMin}>
                    </NumberFormatField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth={true}>
                    <NumberFormatField
                      onInputChange={(event) => {
                        if (editedRisk?.consequence) {
                          editedRisk.consequence = Number(event.target.value)
                          setEditedRisk({ ...editedRisk });
                        }
                      }}
                      label="Forventet"
                      value={editedRisk?.consequence}>
                    </NumberFormatField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth={true}>
                    <NumberFormatField
                      onInputChange={(event) => {
                        if (editedRisk?.consequenceMax) {
                          editedRisk.consequenceMax = Number(event.target.value)
                          setEditedRisk({ ...editedRisk });
                        }
                      }}
                      label="Max"
                      value={editedRisk?.consequenceMax}>
                    </NumberFormatField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.slider}>
                    <Slider
                      value={[editedRisk?.consequenceMin ?? 10000, editedRisk?.consequence ?? 300000, editedRisk?.consequenceMax ?? 750000]}
                      aria-labelledby="track-false-range-slider"
                      onChange={(event, value) => {
                        if (editedRisk) {
                          if (typeof value == 'object') {
                            editedRisk.consequenceMin = value[0];
                            editedRisk.consequence = value[1];
                            editedRisk.consequenceMax = value[2];
                          }
                          setEditedRisk({ ...editedRisk });
                        }
                      }}
                      getAriaValueText={valuetext}
                      marks={marks}
                      step={1000}
                      max={1000000}
                      min={0}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          }

          {activeStep === 2 &&
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                  <Typography >
                    <b>{languageContext.getMessage(editedRisk?.probabilityType ?? 'probability')}</b>{editedRisk?.probabilityType == ProbType.QUANTITIES ? ' [antall]' : ' [0 - 100%]'}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth={true}>
                    <NumberFormatField
                      onInputChange={(event) => {
                        if (editedRisk?.probabilityMin) {
                          editedRisk.probabilityMin = Number(event.target.value)
                          setEditedRisk({ ...editedRisk });
                        }
                      }}
                      label="Min"
                      value={editedRisk?.probabilityMin}>
                    </NumberFormatField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth={true}>
                    <NumberFormatField
                      onInputChange={(event) => {
                        if (editedRisk?.probability) {
                          editedRisk.probability = Number(event.target.value)
                          setEditedRisk({ ...editedRisk });
                        }
                      }}
                      label="Forventet"
                      value={editedRisk?.probability}>
                    </NumberFormatField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth={true}>
                    <NumberFormatField
                      onInputChange={(event) => {
                        if (editedRisk?.probabilityMax) {
                          editedRisk.probabilityMax = Number(event.target.value)
                          setEditedRisk({ ...editedRisk });
                        }
                      }}
                      label="Max"
                      value={editedRisk?.probabilityMax}>
                    </NumberFormatField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.slider}>
                    <Slider
                      value={[editedRisk?.probabilityMin ?? 0,
                      editedRisk?.probability ?? (editedRisk?.probabilityType == ProbType.QUANTITIES ? 500 : 50),
                      editedRisk?.probabilityMax ?? (editedRisk?.probabilityType == ProbType.QUANTITIES ? 800 : 80)]}
                      onChange={(event, value) => {
                        if (editedRisk) {
                          if (typeof value == 'object') {
                            editedRisk.probabilityMin = value[0];
                            editedRisk.probability = value[1];
                            editedRisk.probabilityMax = value[2];
                          }
                          setEditedRisk({ ...editedRisk });
                        }
                      }}
                      getAriaValueText={valuetext}
                      marks={editedRisk?.probabilityType == ProbType.QUANTITIES ? unitMarks : probMarks}
                      step={1}
                      max={editedRisk?.probabilityType == ProbType.QUANTITIES ? 1000 : 100}
                      min={0}
                    />
                  </div>
                </Grid>
              </Grid>
            </>}

          {activeStep === 3 &&
            <Grid container spacing={1}>
              {existingRisk &&
                <Grid item xs={12}>
                  <TextField
                    label={languageContext.getMessage('comment')}
                    multiline
                    rows={3}
                    fullWidth
                    value={editedRisk?.changeComment ?? ''}
                    onChange={(event) => {
                      if (editedRisk) {
                        editedRisk.changeComment = event.target.value
                        setEditedRisk({ ...editedRisk });
                      }
                    }}
                  />
                </Grid>
              }
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={editedRisk?.riskCostToForecast ?? false} onChange={(event) => {
                    if (editedRisk) {
                      editedRisk.riskCostToForecast = event.target.checked
                      setEditedRisk({ ...editedRisk });
                    }
                  }} />}
                  label="Inkluder i prognose? "
                  labelPlacement='start'
                />
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          {!handlingNewRisk && <Button onClick={handleOnClickDelete} color="primary" variant="contained">
            {languageContext.getMessage('delete')}
          </Button>}
          <Button onClick={handleBack} color="default" variant="contained" disabled={activeStep === 0 ? true : false}>
            {languageContext.getMessage('back')}
          </Button>
          {activeStep === 3 ?
            <Button onClick={() => handleClose(true)} disabled={!canSave && existingRisk} color="primary" variant="contained">
              {languageContext.getMessage('save')}
            </Button>
            :
            <Button onClick={handleNext} color="default" variant="contained">
              {languageContext.getMessage('next')}
            </Button>
          }
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog
        title={languageContext.getMessage('doYouWantToDeleteRisk')}
        open={openConfirmDeleteDialog}
        onClose={async (deleteIsConfirmed) => handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)}
      />
    </>
  );
}

export default withTheme(RiskDialog);