import { Grid, makeStyles, Paper, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Account, AccountType } from "../../contracts/contracts";
import SelectAccount from "../../component/accountComponents/SelectAccount";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useAccrualAndOperationsExpensesContext } from "../../contexts/accrualAndOperationsExpenses/accrualAndOperationsExpensesContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import AccrualAndOperationDetailsList from "../../component/accrualAndOperationComponents/AccrualAndOperationDetailsList";
import { useAccrualAndOperationsContext } from "../../contexts/accrualAndOperations/accrualAndOperationsContext";


const useStyles = makeStyles(() => ({
    card: {
        padding: '1em'
    }
}));

type props = {
    from: Date | undefined;
    to: Date | undefined;
    editingExpenses: boolean;
    setEditingExpenses: (updateEditingExpenses: boolean) => void;
    selectedAccount: Account | undefined;
    onSelectedAccountChange: (newSelectedAccount: Account | undefined) => void;
}

const AccrualAndOperationsExpensesDetailsView: React.FC<props> = ({
    from,
    to,
    editingExpenses,
    setEditingExpenses,
    selectedAccount,
    onSelectedAccountChange}) => {
        
    const urlContext = useUrlContext();
    const accountContext = useAccountContext();
    const accrualAndOperationsContext = useAccrualAndOperationsContext();
    const accrualAndOperationsExpensesContext = useAccrualAndOperationsExpensesContext();

    const classes = useStyles();

    const accountSearch = accountContext.getAccountSearch();
    const account = accountContext.getAccount(selectedAccount?.id);
    const accrualAndOperationsExpensesSearch = accrualAndOperationsExpensesContext.getAccrualAndOperationsExpensesSearch();
    const accrualAndOperationSearch = accrualAndOperationsContext.getAccrualAndOperationsSearch();

    useEffect(() => {
        accountContext.searchAccounts(accountSearch);
        if (selectedAccount?.id) {
            accrualAndOperationsExpensesContext.searchAccrualAndOperationsExpenses(accrualAndOperationsExpensesSearch, false);
            accrualAndOperationsContext.searchAccrualAndOperations(accrualAndOperationSearch);
        }
    }, [urlContext.currentLocation, selectedAccount])

    const accrualAndOperations = accrualAndOperationsContext.getAccrualAndOperation(selectedAccount?.id);
    const accrualAndOperationsExpenses = accrualAndOperationsExpensesContext.getAccrualAndOperationsExpenses(account?.id);

    return (<>
        <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
                <Paper className={classes.card} elevation={4}>
                    <SelectAccount 
                        selectedAccount={account}
                        accountTypeFocus={AccountType.ACCRUAL}
                        onSelectedAccountChange={onSelectedAccountChange} />
                </Paper>
            </Grid>
            {accrualAndOperationsExpenses &&
                <Grid item xs={12}>
                    <AccrualAndOperationDetailsList 
                        from={from}
                        to={to}
                        editingExpenses={editingExpenses}
                        setEditingExpenses={setEditingExpenses}
                        selectedAccount={selectedAccount}
                        accrualAndOperations={accrualAndOperations}
                        accrualAndOperationsExpenses={accrualAndOperationsExpenses}
                        isProjectAccrualAndOperationsExpenses={false}
                    />
                </Grid>
            }
        </Grid>
    </>
    );
}

export default withTheme(AccrualAndOperationsExpensesDetailsView);