import { Invoice, InvoiceFlag } from "../contracts/contracts";

export const getInvoiceFlags = (invoice: Invoice): InvoiceFlag[] => {
    const selectedInvoiceFlags: InvoiceFlag[] = [];
    if (invoice.unknownVat) {
        selectedInvoiceFlags.push(InvoiceFlag.UNKNOWN_VAT);
    }
    if (invoice.unknownCurrency) {
        selectedInvoiceFlags.push(InvoiceFlag.UNKNOWN_CURRENCY);
    }
    if (invoice.externalIdMissingWithProvider) {
        selectedInvoiceFlags.push(InvoiceFlag.EXTERNAL_ID_MISSING_WITH_PROVIDER);
    }
    return selectedInvoiceFlags
}

export const updateInvoiceFlags = (invoice: Invoice, invoiceFlags: InvoiceFlag[]): void => {
    invoice.unknownVat = invoiceFlags.findIndex(invoiceFlag => invoiceFlag === InvoiceFlag.UNKNOWN_VAT) >= 0;
    invoice.unknownCurrency = invoiceFlags.findIndex(invoiceFlag => invoiceFlag === InvoiceFlag.UNKNOWN_CURRENCY) >= 0;
    invoice.externalIdMissingWithProvider = invoiceFlags.findIndex(invoiceFlag => invoiceFlag === InvoiceFlag.EXTERNAL_ID_MISSING_WITH_PROVIDER) >= 0;
}