import React from "react";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { HealthStatus } from "../../contracts/contracts";
import { getRenderedStatus } from "../../utils/statusToPalettes";

const formatter = new Intl.NumberFormat('nb-NO', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

const displayPercentage = (percentage: number): string => {
    return formatter.format(percentage);
};

export function getPercentageStatus(percentage: number): HealthStatus {
    if (percentage < 0) {
        return HealthStatus.UNHEALTHY;
    } else if (percentage > 100) {
        return HealthStatus.DEGRADED;
    } else {
        return HealthStatus.HEALTHY;
    }
}

type props = {
    percentage?: number;
    useDefaultPercentage?: boolean;
    defaultPercentage?: number;
    showHealthStatus?: boolean;
}

const PercentageFormat: React.FC<props> = ({
    percentage,
    useDefaultPercentage,
    defaultPercentage, 
    showHealthStatus}) => {
    useDefaultPercentage = useDefaultPercentage ?? true;
    defaultPercentage = defaultPercentage ?? useDefaultPercentage ? 0.0 : undefined; 
    showHealthStatus = showHealthStatus ?? true;
    
    percentage = percentage ?? defaultPercentage;
    const themeContext = useThemeContext();
    const theme = themeContext.getTheme();

    if (percentage === undefined) {
        return (<></>)
    }

    const percentageStatus = getPercentageStatus(percentage);

    const percentageComponent = (<>
        {displayPercentage(percentage)}
        </>);

    if (!showHealthStatus || percentageStatus === HealthStatus.HEALTHY) {
        return percentageComponent;
    }

    return (<>
        <div style={{ display: 'inline-flex', alignItems: 'middle' }}>
            {getRenderedStatus(percentageStatus, theme)}
            &nbsp;
            {percentageComponent} 
        </div>
        </>);
}

export default PercentageFormat