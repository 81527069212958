import { Grid, makeStyles, Tab, Tabs, Theme, Toolbar, withTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "../../contexts/account/accountContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Account, DocumentType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { Guid } from "../../utils/common-types";
import AccountDetailsView from "./AccountDetailsView";
import AccountList from "../../component/accountComponents/AccountList";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useContractContext } from "../../contexts/contract/contractContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useChangeOrderLandlordContext } from "../../contexts/changeOrderLandlord/changeOrderLandlordContext";
import { useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { useChangeOrderExpectedContext } from "../../contexts/changeOrderExpected/changeOrderExpectedContext";
import { useChangeOrderCounterClaimContext } from "../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";
import { useAccrualAndOperationsContext } from "../../contexts/accrualAndOperations/accrualAndOperationsContext";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const AccountView: React.FC<{}> = () => {
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const urlContext = useUrlContext();
  const projectContext = useProjectContext();
  const accountContext = useAccountContext();
  const contractContext = useContractContext();
  const changeOrderSubContractorContext = useChangeOrderSubContractorContext();
  const changeOrderExpectedContext = useChangeOrderExpectedContext();
  const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();
  const accrualAndOperationContext = useAccrualAndOperationsContext();
  const ticketContext = useTicketContext();
  const userRoleContext = useUserRoleContext();

  const urlState = urlContext.getUrlState();
  const tabIndex = urlState.tab ? (urlState.tab as string) : "all";
  const detailTabIndex = urlState.detailTab ? (urlState.detailTab as string) : "general";
  const selectedAccountId = urlState.accountId ? (urlState.accountId as Guid) : null;
  const selectedAccount = accountContext.getAccount(selectedAccountId ?? "");
  const accountSearch = accountContext.getAccountSearch();
  const contractSearch = contractContext.getContractSearch();
  const changeOrderSubContractorSearch = changeOrderSubContractorContext.getChangeOrderSubContractorSearch();
  const changeOrderExpectedSearch = changeOrderExpectedContext.getChangeOrderExpectedSearch();
  const changeOrderCounterClaimSearch = changeOrderCounterClaimContext.getChangeOrderCounterClaimSearch();
  const accrualAndOperationSearch = accrualAndOperationContext.getAccrualAndOperationsSearch();

  useEffect(() => {
    userRoleContext.queryUsers();
    ticketContext.setDocumentTypesToWatch([
      DocumentType.ACCOUNT,
      DocumentType.CONTRACT,
      DocumentType.CHANGE_ORDER_SUB_CONTRACTOR,
      DocumentType.CHANGE_ORDER_EXPECTED,
      DocumentType.CHANGE_ORDER_COUNTER_CLAIM,
      DocumentType.ACCRUAL_AND_OPERATIONS,
    ]);
    accountContext.searchAccounts(accountSearch);
    accrualAndOperationContext.searchAccrualAndOperations(accrualAndOperationSearch);
    if (contractSearch.accountId) {
      contractContext.searchContracts(contractSearch);
    }
    if (changeOrderSubContractorSearch.accountId) {
      changeOrderSubContractorContext.searchChangeOrderSubContractors(changeOrderSubContractorSearch);
    }
    if (changeOrderCounterClaimSearch.accountId) {
      changeOrderCounterClaimContext.searchChangeOrderCounterClaims(changeOrderCounterClaimSearch);
    }
    if (changeOrderExpectedSearch.accountId) {
      changeOrderExpectedContext.searchChangeOrderExpecteds(changeOrderExpectedSearch);
    }
  }, [urlContext.currentLocation, projectContext.getSelectedProject()]);

  const updateUrlState = (newAccount: Account | undefined, newTabIndex: string, newDetailTabIndex: string): void => {
    const newUrlState = {
      ...urlState,
      ...accountSearch,
      ...{ accountId: newAccount?.id },
      ...{ tab: newTabIndex },
      ...{ detailTab: newDetailTabIndex },
    };
    const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
    urlContext.pushUrlQuery(urlQuery);
  };

  const handleTabIndexChange = (event: React.ChangeEvent<{}>, newTabIndex: string) => {
    updateUrlState(selectedAccount, newTabIndex, "");
  };

  const handleDetailTabIndexChange = (event: React.ChangeEvent<{}>, newTabIndex: string) => {
    updateUrlState(selectedAccount, tabIndex, newTabIndex);
  };

  const onSelectedAccountChange = (newSelectedAccount: Account | undefined, newTabIndex?: string, newDetailTabIndex?: string): void => {
    newTabIndex = newTabIndex ?? tabIndex;
    newDetailTabIndex = newDetailTabIndex ?? detailTabIndex;
    updateUrlState(newSelectedAccount, newTabIndex, newDetailTabIndex);
  };

  const onClickAccount = (accountId: Guid) => {
    const newSelectedAccount = accountContext.getAccount(accountId);
    onSelectedAccountChange(newSelectedAccount, "details", detailTabIndex);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
          <Tabs value={tabIndex} onChange={handleTabIndexChange}>
            <Tab label={languageContext.getMessage("all")} value="all" />
            <Tab label={languageContext.getMessage("details")} value="details" />
          </Tabs>
        </Toolbar>
      </Grid>
      {tabIndex === "all" && (
        <>
          {/* {(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 400 ?
                        <Grid item xs={12}><AccountList onClickAccount={onClickAccount}></AccountList></Grid>
                    :
                        <Grid item xs={12}><AccountMobileList onClickAccount={onClickAccount}></AccountMobileList></Grid>
                    } */}
          <Grid item xs={12}>
            <AccountList onClickAccount={onClickAccount}></AccountList>
          </Grid>
        </>
      )}
      {tabIndex === "details" && (
        <Grid item xs={12}>
          <AccountDetailsView selectedAccount={selectedAccount} onSelectedAccountChange={onSelectedAccountChange} detailTabIndex={detailTabIndex} handleDetailTabIndexChange={handleDetailTabIndexChange} />
        </Grid>
      )}
    </Grid>
  );
};

export default withTheme(AccountView);
