import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { StorageFile } from "../../../contracts/contracts";

export const MUTATE_STORAGE_FILE: DocumentNode = gql`
  mutation storageFile($delete: Boolean, $id: UUID, $key: String, $metaData: String, $content: String) {
    mutateStorageFile(delete: $delete, id: $id, key: $key, metaData: $metaData, content: $content) {
      files {
        id
        key
        changed
        changedBy
        metaData
        content
      }
    }
  }
`;

export const getStorageFileGraphqlMutationOptions = (storageFile: StorageFile): QueryHookOptions => {
  const options: QueryHookOptions = {};
  options.variables = {};
  if (storageFile.delete !== undefined) options.variables["delete"] = storageFile.delete;
  if (storageFile.id !== undefined) options.variables["id"] = storageFile.id;
  if (storageFile.key !== undefined) options.variables["key"] = storageFile.key;
  if (storageFile.metaData !== undefined) options.variables["metaData"] = storageFile.metaData;
  if (storageFile.content !== undefined) options.variables["content"] = storageFile.content;

  return options;
};
