import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ChangeOrderCounterClaim, ActiveState } from "../../../contracts/contracts";
import { guidIsNullOrEmpty } from "../../../utils/guidTools";

export const MUTATE_CHANGE_ORDER: DocumentNode = gql`
    mutation changeOrderCounterClaim(
            $id: UUID
            $state: ActiveState
            $projectId: UUID
            $accountId: UUID
            $contractId: UUID
            $name: String
            $group: String
            $sent: DateTime
            $answered: DateTime
            $changeOrderDescription: String
            $changeOrderComment: String
            $contractor: String
            $costRequirements: Float
            $cost: Float
            $status: ChangeOrderStatus
            $progressConsequenceEnabled: Boolean
            $progressConsequenceDescription: String
        ) {
        mutateChangeOrderCounterClaim(
            id: $id
            state: $state
            projectId: $projectId
            accountId: $accountId
            contractId: $contractId
            name: $name
            group: $group
            sent: $sent
            answered: $answered
            changeOrderDescription: $changeOrderDescription
            changeOrderComment: $changeOrderComment
            contractor: $contractor
            costRequirements: $costRequirements
            cost: $cost
            status: $status
            progressConsequenceEnabled: $progressConsequenceEnabled
            progressConsequenceDescription: $progressConsequenceDescription
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`;

export const getChangeOrderCounterClaimsGraphqlMutationOptions = (changeOrderCounterClaim: ChangeOrderCounterClaim): QueryHookOptions => {

    changeOrderCounterClaim.state = changeOrderCounterClaim.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (changeOrderCounterClaim.id !== undefined) options.variables['id'] = changeOrderCounterClaim.id;
    if (changeOrderCounterClaim.state !== undefined) options.variables['state'] = changeOrderCounterClaim.state;
    if (!guidIsNullOrEmpty(changeOrderCounterClaim.projectId)) options.variables['projectId'] = changeOrderCounterClaim.projectId;
    if (!guidIsNullOrEmpty(changeOrderCounterClaim.accountId)) options.variables['accountId'] = changeOrderCounterClaim.accountId;
    if (changeOrderCounterClaim.contractId !== undefined) options.variables['contractId'] = changeOrderCounterClaim.contractId;
    if (changeOrderCounterClaim.name !== undefined) options.variables['name'] = changeOrderCounterClaim.name;
    if (changeOrderCounterClaim.group !== undefined) options.variables['group'] = changeOrderCounterClaim.group;
    if (changeOrderCounterClaim.sent !== undefined) options.variables['sent'] = changeOrderCounterClaim.sent.toISOString();
    if (changeOrderCounterClaim.answered !== undefined) options.variables['answered'] = changeOrderCounterClaim.answered.toISOString();
    if (changeOrderCounterClaim.changeOrderDescription !== undefined) options.variables['changeOrderDescription'] = changeOrderCounterClaim.changeOrderDescription;
    if (changeOrderCounterClaim.changeOrderComment !== undefined) options.variables['changeOrderComment'] = changeOrderCounterClaim.changeOrderComment;
    if (changeOrderCounterClaim.contractor !== undefined) options.variables['contractor'] = changeOrderCounterClaim.contractor;
    if (changeOrderCounterClaim.costRequirements !== undefined) options.variables['costRequirements'] = changeOrderCounterClaim.costRequirements;
    if (changeOrderCounterClaim.cost !== undefined) options.variables['cost'] = changeOrderCounterClaim.cost;
    if (changeOrderCounterClaim.status !== undefined) options.variables['status'] = changeOrderCounterClaim.status;
    if (changeOrderCounterClaim.progressConsequenceEnabled !== undefined) options.variables['progressConsequenceEnabled'] = changeOrderCounterClaim.progressConsequenceEnabled;
    if (changeOrderCounterClaim.progressConsequenceDescription !== undefined) options.variables['progressConsequenceDescription'] = changeOrderCounterClaim.progressConsequenceDescription;
    return options;
}
