import { AccrualAndOperationsExpensesContext } from "../../contexts/accrualAndOperationsExpenses/accrualAndOperationsExpensesContext";
import { AccrualAndOperationExpense, AccrualAndOperationPostedExpense, AccrualAndOperations, AccrualAndOperationsExpenses } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { datesAreOnSameMonth } from "../../utils/dateTools";

export const extractUsedMonths = (
    accrualAndOperations: AccrualAndOperations | undefined,
    accrualAndOperationsExpenses: AccrualAndOperationsExpenses[], 
    monthlyExpenseItems: Dictionary<Dictionary<AccrualAndOperationExpense>>, 
    monthlyPostedExpenseItems: Dictionary<AccrualAndOperationPostedExpense>): Date[] => {
    let usedDates: Date[] = [];
    accrualAndOperationsExpenses.forEach(accrualAndOperationsExpense => {
        accrualAndOperationsExpense?.monthlyExpenseItems?.forEach(monthlyExpenseItem => {
            if (!monthlyExpenseItem.date || !accrualAndOperationsExpense.id) {
                return;
            }
            const dateStr = monthlyExpenseItem.date?.toISOString();
            if (!(accrualAndOperationsExpense.id in monthlyExpenseItems)) {
                monthlyExpenseItems[accrualAndOperationsExpense.id] = {}
            }
            monthlyExpenseItems[accrualAndOperationsExpense.id][dateStr] = monthlyExpenseItem;
            if (usedDates.findIndex(usedDate => usedDate?.toISOString() === dateStr) < 0) {
                usedDates.push(new Date(monthlyExpenseItem.date));
                const monthlyPostedExpenseItem = accrualAndOperations?.monthlyPostedExpenseItems?.find(expense => expense.date?.toISOString() === dateStr) ?? {};
                monthlyPostedExpenseItems[dateStr] = monthlyPostedExpenseItem;
            }
        })
    })
    return usedDates;
}


export const MutateDefaultAccrualAndOperationsMonths = (
    accrualAndOperationExpensesContext: AccrualAndOperationsExpensesContext,
    accrualAndOperationsExpenses: AccrualAndOperationsExpenses[], 
    usedDates: Date[],
    emptyMonths: Date[],
    monthlyAccrualAndOperationsExpenses: Dictionary<Dictionary<AccrualAndOperationsExpenses>>,
    defaultMutatedMonths?: Dictionary<Dictionary<AccrualAndOperationsExpenses>>, 
    setDefaultMutatedMonths?: React.Dispatch<React.SetStateAction<Dictionary<Dictionary<AccrualAndOperationsExpenses>>>>, 
    accrualAndOperationsExpensesToUpdate?: Dictionary<AccrualAndOperationsExpenses>, 
    setAccrualAndOperationsExpensesToUpdate?: React.Dispatch<React.SetStateAction<Dictionary<AccrualAndOperationsExpenses>>>): void => {
    const mutatedMonths: Dictionary<Dictionary<AccrualAndOperationsExpenses>> = defaultMutatedMonths ?? {};
    const accrualAndOperationsToMutate: Dictionary<AccrualAndOperationsExpenses> = {};
    accrualAndOperationsExpenses.forEach(accrualAndOperationExpense => {
        emptyMonths.forEach(emptyMonth => {
            const dateStr = emptyMonth.toISOString();
            if (accrualAndOperationExpense.id 
                && mutatedMonths[accrualAndOperationExpense.id] && 
                dateStr in mutatedMonths[accrualAndOperationExpense.id]) {
                return;
            }
            if (accrualAndOperationExpense.id && !(accrualAndOperationExpense.id in monthlyAccrualAndOperationsExpenses)) {
                monthlyAccrualAndOperationsExpenses[accrualAndOperationExpense.id] = {}
            }
            if (accrualAndOperationExpense.id 
                && monthlyAccrualAndOperationsExpenses[accrualAndOperationExpense.id] && 
                !(dateStr in monthlyAccrualAndOperationsExpenses[accrualAndOperationExpense.id])) {

                const accrualAndOperationDetails = monthlyAccrualAndOperationsExpenses[accrualAndOperationExpense.id][dateStr];
                if (!(accrualAndOperationExpense.id in mutatedMonths)) {
                    mutatedMonths[accrualAndOperationExpense.id] = {}
                }
                mutatedMonths[accrualAndOperationExpense.id][dateStr] = accrualAndOperationDetails;

                if (!(accrualAndOperationExpense.id in accrualAndOperationsToMutate)) {
                    const accrualAndOperationCopy = {...accrualAndOperationExpense};
                    accrualAndOperationsToMutate[accrualAndOperationExpense.id] = accrualAndOperationCopy;
                }

                const accrualAndOperationExpenseCopy = accrualAndOperationsToMutate[accrualAndOperationExpense.id]
                accrualAndOperationExpenseCopy.monthlyExpenseItems = accrualAndOperationExpenseCopy.monthlyExpenseItems ?? [];
                accrualAndOperationExpenseCopy.monthlyExpenseItems.push(GetNewAccrualAndOperationsDetails(emptyMonth));
            }
        });
        accrualAndOperationExpense.monthlyExpenseItems?.forEach(monthlyExpenseItem => {
            const dateIsDisabled = usedDates.findIndex(usedDate => datesAreOnSameMonth(monthlyExpenseItem.date ?? new Date(0), usedDate)) < 0;
            const addToAccrualAndOperationsToMutate = monthlyExpenseItem.disabled !== dateIsDisabled;
            if (addToAccrualAndOperationsToMutate && accrualAndOperationExpense.id && !(accrualAndOperationExpense.id in accrualAndOperationsToMutate)) {
                monthlyExpenseItem.disabled = dateIsDisabled;
                accrualAndOperationsToMutate[accrualAndOperationExpense.id] = accrualAndOperationExpense;
            }
        })
    });
    if (setDefaultMutatedMonths) {
        setDefaultMutatedMonths({...mutatedMonths});
    }
    let updateAccrualAndOperations = false;
    for (var id in accrualAndOperationsToMutate) {
        updateAccrualAndOperations = true;
        const accrualAndOperationCopy = accrualAndOperationsToMutate[id];
        accrualAndOperationExpensesContext.updateAccrualAndOperationsExpenses(accrualAndOperationCopy, true);
        if (accrualAndOperationsExpensesToUpdate && accrualAndOperationCopy.id) {
            accrualAndOperationsExpensesToUpdate[accrualAndOperationCopy.id] = accrualAndOperationCopy;
        }
    }
    if (updateAccrualAndOperations && setAccrualAndOperationsExpensesToUpdate && accrualAndOperationsExpensesToUpdate) {
        setAccrualAndOperationsExpensesToUpdate({ ...accrualAndOperationsExpensesToUpdate });
    }
}

export const GetNewAccrualAndOperationsDetails = (date: Date): AccrualAndOperationExpense => {
    const newAccrualAndOperationsDetails: AccrualAndOperationExpense = {expense: 0.0, date: date};
    return newAccrualAndOperationsDetails;
}