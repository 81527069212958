import React, { useEffect, useState } from "react";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { withTheme, makeStyles, Theme, Grid, TextField, Button } from '@material-ui/core';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useThemeContext } from '../../contexts/theme/ThemeContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { Project, RoleType } from '../../contracts/contracts';
import PercentageInput from '../percentageComponents/PercentageInput';
import { displayCurrency } from '../currencyComponents/CurrencyFormat';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: '1em'
    },
    divider: {
        marginBottom: '1em'
    },
    button: {
        margin: theme.spacing(1),
      },
}));

export interface RiskProjectSettingsProps {
    project: Project | undefined,
  }

const RiskProjectSettings: React.FC<RiskProjectSettingsProps> = (props: RiskProjectSettingsProps) => {

    const classes = useStyles();
    const languageContext = useLanguageContext();
    const themeContext = useThemeContext();
    const projectContext = useProjectContext();
    const currentProject = projectContext.getSelectedProject();

    const { project } = props;

    const [editingProject, setEditingProject] = useState<boolean>(false);

    const [editedProject, setEditedProject] = React.useState<Project | undefined>(projectContext.getSelectedProject());


    useEffect(() => {
        setEditedProject({...project});
      }, [project])


    const onEditProject = (save: boolean) => {
        if (editedProject && editingProject) {
            if (save) {
                projectContext.updateProject({...editedProject});
            }
            else {
                setEditedProject({...project});
            }
        }
        setEditingProject(!editingProject);
    }


    const readonly = { readOnly: true };

    return (<>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField
                fullWidth
                label={languageContext.getMessage('projectName')}
                value={editedProject?.name ?? ''} />
            </Grid>
            <Grid item xs={3} sm={2} lg={1}>
                  <PercentageInput
                    label={`${languageContext.getMessage('max')} ${languageContext.getMessage('percentage')}`}
                    percentage={editedProject?.riskConsequenceLimit ?? 0}
                    onChange={(newAssignmentPercentage) => {
                        if(editedProject) {
                            editedProject.riskConsequenceLimit = newAssignmentPercentage;
                            setEditedProject({...editedProject});
                        }
                    }}
                    fullWidth={true}
                    readOnly={editingProject ? false : true}
                    updateOnDefaultPercentageChange={true}
                    />
            </Grid>
            <Grid item xs={3} sm={2} lg={2}>
            <TextField
                fullWidth
                label={languageContext.getMessage('accountBudget')}
                value={displayCurrency(editedProject?.forecast?.revisedAccountBudget)}
                InputProps={readonly} />
            </Grid>
            <Grid item xs={3} sm={2} lg={2}>
            <TextField
                fullWidth
                label={`${languageContext.getMessage('max')} ${languageContext.getMessage('consequence')}`}
                value={displayCurrency((editedProject?.forecast?.revisedAccountBudget ?? 0) * (editedProject?.riskConsequenceLimit ?? 0) * 1/100)}
                InputProps={readonly} />
            </Grid>
        {projectContext.hasProjectAccess(RoleType.WRITER) && editedProject && 
        <>
            <Button className={classes.button} color="primary" variant="contained" onClick={() => onEditProject(true)}>
                    {editingProject ? <SaveIcon /> : <EditIcon />}
            </Button>
            {editingProject &&
                <Button className={classes.button} color="default" variant="contained" onClick={() => onEditProject(false)}>
                    <CancelIcon />
                </Button>
            }
        </>}
            </Grid> 
        </>);
}

export default withTheme(RiskProjectSettings)