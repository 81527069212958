import { makeStyles, Theme, Toolbar } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { withTheme } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import MaterialTable, { Components, MTableToolbar } from "material-table";
import React, { useState } from "react";
import { useInterimContext } from "../../contexts/interim/InterimContext";
import { InterimExpenseWithAccumulatedTag } from "../../contexts/interim/InterimProjectExportTools";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { useWidthContext } from "../../contexts/WidthContext";
import { InterimExpense, RoleType } from "../../contracts/contracts";
import { tableIcons } from "../../ui/table-icons";
import CurrencyFormat from "../currencyComponents/CurrencyFormat";
import TicketLoading from "../generalComponents/TicketLoading";
import EditProjectInterimDialog from "./EditProjectInterimDialog";
import { CustomTableStyle } from "../../utils/common-types";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type props = {hideToolbar?: boolean};

const ProjectInterimDetailsList: React.FC<props> = ({hideToolbar}) => {
  const classes = useStyles();
  const languageContext = useLanguageContext();
  const { getLocalization } = useMaterialTableLanguageContext();
  const themeContext = useThemeContext();
  const theme = themeContext.getTheme();
  const interimContext = useInterimContext();
  const projectContext = useProjectContext();
  const projectInterim = interimContext.getProjectInterim();
  const projectInterimExpense = interimContext.getProjectInterimExpense();

  const widthContext = useWidthContext();
  const width = widthContext.getWidth();
  const smallWindow = widthContext.isMobileScreen();

  const projectInterimExpenses: InterimExpenseWithAccumulatedTag[] = [
    { accumulated: false, ...projectInterimExpense },
    { accumulated: true, ...projectInterimExpense },
  ];

  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const openEditDialog = () => {
    setShowEditDialog(true);
  };

  const onCloseEditDialog = () => {
    setShowEditDialog(false);
  };

  const editProjectInterimTitle = `${languageContext.getMessage("edit")} ${languageContext.getMessage("monthly")} ${languageContext.getMessage("interim")}`;
  const renderToolbar = (props: any) => (
    <div>
      <MTableToolbar {...props} />
      <Toolbar className={classes.toolbar} disableGutters={true} variant="dense">
        <Button color="inherit" size="medium" className={classes.button} startIcon={<Edit />} onClick={() => openEditDialog()}>
          {editProjectInterimTitle}
        </Button>
      </Toolbar>
    </div>
  );

  const renderGroupbar = (props: any) => <div></div>;

  const tableComponents: Components = {
    Groupbar: renderGroupbar,
  };
  if (projectContext.hasProjectAccess(RoleType.WRITER)) {
    tableComponents.Toolbar = renderToolbar;
  }

  return (
    <>
      <EditProjectInterimDialog projectInterimExpense={projectInterimExpense} projectInterim={projectInterim} title={editProjectInterimTitle} open={showEditDialog} onClose={onCloseEditDialog} />
      <MaterialTable
        icons={tableIcons}
        localization={getLocalization()}
        columns={[
          {
            title: "",
            editable: "never",
            hidden: false,
            cellStyle: CustomTableStyle,
            render: (projectInterimExpense) =>
              projectInterimExpense?.accumulated ? (
                languageContext.getMessage("accumulated")
              ) : (
                <TicketLoading documentId={projectInterim?.id} circularProgressProps={{ size: 15 }} setComponentBeforeProgress={true} component={languageContext.getMessage("monthly")} />
              ),
          },
          {
            title: languageContext.getMessage("constructionCosts"),
            editable: "never",
            type: "numeric",
            hidden: false,
            cellStyle: CustomTableStyle,
            render: (projectInterimExpense) =>
              projectInterimExpense?.accumulated ? (
                <CurrencyFormat amount={projectInterimExpense?.accumulatedConstructionCosts} />
              ) : (
                <CurrencyFormat amount={projectInterimExpense?.constructionCosts} />
              ),
          },
          {
            title: languageContext.getMessage("administrationCosts"),
            editable: "never",
            type: "numeric",
            hidden: false,
            cellStyle: CustomTableStyle,
            render: (projectInterimExpense) =>
              projectInterimExpense?.accumulated ? (
                <CurrencyFormat amount={projectInterimExpense?.accumulatedAdministrationCosts} />
              ) : (
                <CurrencyFormat amount={projectInterimExpense?.administrationCosts} />
              ),
          },
          {
            title: languageContext.getMessage("surcharge"),
            editable: "never",
            type: "numeric",
            hidden: false,
            cellStyle: CustomTableStyle,
            render: (projectInterimExpense) =>
              projectInterimExpense?.accumulated ? <CurrencyFormat amount={projectInterimExpense?.accumulatedSurcharge} /> : <CurrencyFormat amount={projectInterimExpense?.surcharge} />,
          },
        ]}
        data={projectInterimExpenses}
        title={""}
        options={{
          padding: "dense",
          paging: true,
        //   showTitle: false,
          search: hideToolbar === true ? false : true,
          showFirstLastPageButtons: false,
          exportButton: hideToolbar === true ? undefined : { csv: true, pdf: false },
          exportCsv: (columns, renderData) => {
            interimContext.downloadInterimProject(projectInterimExpenses);
          },
          columnsButton: hideToolbar === true ? undefined : true,
          emptyRowsWhenPaging: false,
          toolbarButtonAlignment: smallWindow ? "left" : "right",
          searchFieldAlignment: "right",
          detailPanelColumnAlignment: "right",
          headerStyle: { position: "sticky", top: 0 },
          maxBodyHeight: 500,
        }}
        style={{
          width: width,
        }}
        components={tableComponents}
      />
    </>
  );
};

export default withTheme(ProjectInterimDetailsList);
