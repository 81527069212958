import { AccountContext } from "../contexts/account/accountContext";
import { LanguageContext } from "../contexts/language/LanguageContext";
import { TemplateEngineQueriesContext } from "../contexts/templateEngine/queries/templateEngineQueriesContext";
import { Account, ChangeOrderCounterClaim, ChangeOrderExpected, ChangeOrderSubContractor, Contract } from "../contracts/contracts";
import { ChangeOrderCounterClaimContext } from "../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext";
import { ChangeOrderExpectedContext, sortChangeOrderExpectedByGroup } from "../contexts/changeOrderExpected/changeOrderExpectedContext";
import { ChangeOrderSubContractorContext, sortChangeOrderSubContractorByGroup } from "../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { ContractContext, sortContractByName } from "../contexts/contract/contractContext";
import { sortChangeOrderCounterClaimByGroup } from "../contexts/changeOrderCounterClaim/ChangeOrderCounterClaimExportTools";

const ACCOUNT_REPORT_TEMPLATE_FILENAME = "aimz/template_account_report.xlsx";
const ACCOUNT_REPORT_TEMPLATE_SHEET_NAME = "konto";

export const sortAccountByGroup = (a: Account, b: Account) => {
  if ((a.accountGroup ?? '') < (b.accountGroup ?? '')) { return -1; }
  if ((a.accountGroup ?? '') > (b.accountGroup ?? '')) { return 1; }
  return 0;
}

export const sortAccountByAccountNumber = (a: Account, b: Account) => {
    if ((a.accountNumber ?? '') < (b.accountNumber ?? '')) { return -1; }
    if ((a.accountNumber ?? '') > (b.accountNumber ?? '')) { return 1; }
    return 0;
}

export const sortAccountByAccountName = (a: Account, b: Account) => {
    if ((a.name ?? '') < (b.name ?? '')) { return -1; }
    if ((a.name ?? '') > (b.name ?? '')) { return 1; }
    return 0;
}

export const sortContractByAccountNumber = (a: Contract, b: Contract, accountContext: AccountContext) => {
    return sortAccountByAccountNumber(accountContext.getAccount(a.accountId) ?? {}, accountContext.getAccount(b.accountId) ?? {});
}

export function downloadAccountReport(
  templateEngineQueriesContext: TemplateEngineQueriesContext,
  contractContext: ContractContext,
  changeOrderCounterClaimContext: ChangeOrderCounterClaimContext,
  changeOrderExpectedContext: ChangeOrderExpectedContext,
  changeOrderSubContractorContext: ChangeOrderSubContractorContext,
  languageContext: LanguageContext,
  accountContext: AccountContext,
  accountId?: string
) {
  var account = accountContext.getAccount(accountId);
  var contracts = contractContext.getContracts(accountId).sort((a, b) => sortContractByAccountNumber(a, b, accountContext))
  var changeOrderCounterClaims: ChangeOrderCounterClaim[] = changeOrderCounterClaimContext.getChangeOrderCounterClaimsByAccountId(accountId).sort(sortChangeOrderCounterClaimByGroup);
  var changeOrderExpecteds: ChangeOrderExpected[] = changeOrderExpectedContext.getChangeOrderExpecteds(accountId).sort(sortChangeOrderExpectedByGroup);
  var changeOrderSubContractors: ChangeOrderSubContractor[] = changeOrderSubContractorContext.getChangeOrderSubContractors(undefined, undefined, accountId).sort(sortChangeOrderSubContractorByGroup);
  if (account) {
    var reportVariables: object[] = [
      {
        tpl_name: ACCOUNT_REPORT_TEMPLATE_SHEET_NAME,
        sheet_name: ACCOUNT_REPORT_TEMPLATE_SHEET_NAME,
        ctx: getAccountReportVariables(account, contracts, changeOrderExpecteds, changeOrderCounterClaims , changeOrderSubContractors, languageContext),
      },
    ];
    var fileName = `${account?.accountNumber} ${account?.name} - rapport`
    templateEngineQueriesContext.queryTemplateEngine(ACCOUNT_REPORT_TEMPLATE_FILENAME, reportVariables, fileName);
  }
}

function getAccountReportVariables(
  account: Account,
  contracts: Contract[],
  changeOrderExpected: ChangeOrderExpected[],
  changeOrderCounterClaims: ChangeOrderCounterClaim[],
  changeOrderSubContractor: ChangeOrderSubContractor[],
  languageContext: LanguageContext
) {
  return {
    account: account,
    contracts: contracts.map((contract) => {
      return {
        name: contract.name,
        budget: contract.budget ?? 0.0,
        procurement: contract.procurement ?? 0.0,
        supplier: contract.supplier,
        invoiced: contract.sumInvoicedAmount ?? 0,
        description: contract.contractDescription,
      };
    }),
    counterClaims: changeOrderCounterClaims.map((changeOrder) => {
      return {
        group: changeOrder.group,
        name: changeOrder.name,
        contract: contracts.find((contract) => contract.id === changeOrder.contractId)?.name,
        description: changeOrder.changeOrderDescription,
        comment: changeOrder.changeOrderComment,
        sent: changeOrder?.sent ? changeOrder?.sent?.toDateString() : "",
        answered: changeOrder?.answered ? changeOrder?.answered?.toDateString() : "",
        cost: changeOrder.cost,
        costRequirements: changeOrder.costRequirements,
        sumInvoicedAmount: changeOrder.sumInvoicedAmount,
        status: changeOrder.status ? languageContext.getMessage(changeOrder.status) : "",
      };
    }),
    expectedChanges: changeOrderExpected.map((changeOrderExpected) => {
      return {
        group: changeOrderExpected.group,
        name: changeOrderExpected.name,
        contract: contracts.find((contract) => contract.id === changeOrderExpected.contractId)?.name,
        description: changeOrderExpected.changeOrderDescription,
        comment: changeOrderExpected.changeOrderComment,
        expected: changeOrderExpected?.expected ? changeOrderExpected?.expected?.toDateString() : "",
        cost: changeOrderExpected.cost,
        adjustedCost: changeOrderExpected.adjustedCost,
      };
    }),
    changeOrders: changeOrderSubContractor.map((changeOrder) => {
      return {
        group: changeOrder.group,
        name: changeOrder.name,
        contract: contracts.find((contract) => contract.id === changeOrder.contractId)?.name,
        description: changeOrder.changeOrderDescription,
        comment: changeOrder.changeOrderComment,
        received: changeOrder?.received ? changeOrder?.received?.toDateString() : "",
        answered: changeOrder?.answered ? changeOrder?.answered?.toDateString() : "",
        cost: changeOrder.cost,
        costRequirements: changeOrder.costRequirements,
        sumInvoicedAmount: changeOrder.sumInvoicedAmount,
        status: changeOrder.status ? languageContext.getMessage(changeOrder.status) : "",
      };
    }),
  };
}
