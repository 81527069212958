    import React, { createContext, useContext, useEffect, useState } from "react";
import { useUrlContext } from "../url/urlContext";
import { ActiveState, AccrualAndOperations, DocumentType } from "../../contracts/contracts";
import { receiveAccrualAndOperations } from "./accrualAndOperationsTools";
import { AccrualAndOperationsQueriesContextContext, useAccrualAndOperationsQueriesContext } from "./queries/accrualAndOperationsQueriesContext";
import { AccrualAndOperationsSubscriptionsContextProvider, useAccrualAndOperationsSubscriptionsContext } from "./subscriptions/accrualAndOperationsSubscriptionsContext";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { datesAreOnSameDay } from "../../utils/dateTools";
import { Guid } from "../../utils/common-types";
import { useAuthContext } from "../auth/authContext";
import { useProjectContext } from "../project/projectContext";

export interface AccrualAndOperationsContext {
    getAccrualAndOperationsSearch: () => AccrualAndOperations,
    searchAccrualAndOperations: (accrualAndOperationsSearch: AccrualAndOperations | undefined) => void,
    getAccrualAndOperations: () => AccrualAndOperations[],
    getAccrualAndOperation: (accountId: string | undefined) => AccrualAndOperations | undefined,
}

const AccrualAndOperationsContext = createContext<AccrualAndOperationsContext>(null as unknown as AccrualAndOperationsContext);

export const AccrualAndOperationsContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <AccrualAndOperationsQueriesContextContext>
            <AccrualAndOperationsSubscriptionsContextProvider>
                <AccrualAndOperationsSubContextProvider>
                    {children}
                </AccrualAndOperationsSubContextProvider>
            </AccrualAndOperationsSubscriptionsContextProvider>
        </AccrualAndOperationsQueriesContextContext>
    );
}

export const AccrualAndOperationsSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const projectContext = useProjectContext();
    const accrualAndOperationsQueriesContext = useAccrualAndOperationsQueriesContext();
    const accrualAndOperationsSubscriptionsContext = useAccrualAndOperationsSubscriptionsContext();
    const [currentAccrualAndOperationsSearch, setCurrentAccrualAndOperationsSearch] = useState<AccrualAndOperations | undefined>(undefined);
    const [accrualAndOperations, setAccrualAndOperations] = useState<Array<AccrualAndOperations>>([]);

    const mergeAccrualAndOperations = (oldAccrualAndOperations: Array<AccrualAndOperations>, newAccrualAndOperations: Array<AccrualAndOperations>): Array<AccrualAndOperations> => {
        let updatedAccrualAndOperations = oldAccrualAndOperations.slice();
        if (!newAccrualAndOperations) {
            console.error(`Received undefined set of accrualAndOperations: ${newAccrualAndOperations}`);
            return [];
        }
        newAccrualAndOperations.forEach(newAccrualAndOperation => {
            newAccrualAndOperation.created = new Date(newAccrualAndOperation.created ?? 0);
            newAccrualAndOperation = receiveAccrualAndOperations(currentAccrualAndOperationsSearch, newAccrualAndOperation);
            const index = updatedAccrualAndOperations.findIndex(account => account.id === newAccrualAndOperation.id);
            newAccrualAndOperation.state = ActiveState.ACTIVE;
            if (index >= 0) {
                if (newAccrualAndOperation.state === ActiveState.ACTIVE) {
                    updatedAccrualAndOperations[index] = newAccrualAndOperation;
                }
                else {
                    updatedAccrualAndOperations.splice(index, 1);
                }
            } 
            else {
                if (newAccrualAndOperation.state === ActiveState.ACTIVE) {
                    updatedAccrualAndOperations.push(newAccrualAndOperation); 
                }
            }
        });
        return updatedAccrualAndOperations;
    }

    const getAccrualAndOperationsSearch = (): AccrualAndOperations => {
        const urlState = urlContext.getUrlState();

        const parentDocumentId = urlState.parentDocumentId ? urlState.parentDocumentId as Guid : undefined;
        const fromDate = urlState.fromDate ? new Date(urlState.fromDate as string) : undefined;
        const toDate = urlState.toDate ? new Date(urlState.toDate as string) : undefined;
        const fromDateIsEmpty = urlState.fromDateIsEmpty === 'true';
        const toDateIsEmpty = urlState.toDateIsEmpty === 'true';
    
        return {
            parentDocumentId,
            fromDate: fromDateIsEmpty === true ? undefined : fromDate,
            toDate: toDateIsEmpty === true ? undefined : toDate,
            fromDateIsEmpty: fromDateIsEmpty,
            toDateIsEmpty: toDateIsEmpty,
        }
    }

    const searchAccrualAndOperations = (accrualAndOperationsSearch: AccrualAndOperations | undefined): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(accrualAndOperationsSearch, currentAccrualAndOperationsSearch);
        // matched = matched && accrualAndOperationsSearch?.parentDocumentId === currentAccrualAndOperationsSearch?.parentDocumentId;
        matched = matched && accrualAndOperationsSearch?.parentDocumentType === currentAccrualAndOperationsSearch?.parentDocumentType;
        const mandatoryPropertiesIsSet = projectContext.getSelectedProject()?.id !== undefined;
        if (mandatoryPropertiesIsSet && !matched) {
            setCurrentAccrualAndOperationsSearch(accrualAndOperationsSearch);
            setAccrualAndOperations([]);
        }
    }
    
    const getAccrualAndOperations = (): AccrualAndOperations[] => {
        return accrualAndOperations;
    }

    const getAccrualAndOperation = (accountId: string | undefined): AccrualAndOperations | undefined => {
        return accrualAndOperations.find((accrualAndOperation) => accrualAndOperation?.accountId === accountId);
    }

    useEffect(() => {
        if (currentAccrualAndOperationsSearch) {
            accrualAndOperationsQueriesContext.queryAccrualAndOperations(currentAccrualAndOperationsSearch)
        }
    }, [currentAccrualAndOperationsSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setAccrualAndOperations([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setAccrualAndOperations(mergeAccrualAndOperations([], accrualAndOperationsQueriesContext.fetchedAccrualAndOperations));
    }, [accrualAndOperationsQueriesContext.fetchedAccrualAndOperations]);

    useEffect(() => {
        setAccrualAndOperations(mergeAccrualAndOperations(accrualAndOperations, accrualAndOperationsSubscriptionsContext.subscribedAccrualAndOperations));
    }, [accrualAndOperationsSubscriptionsContext.subscribedAccrualAndOperations]);

    const value = {
        getAccrualAndOperationsSearch,
        searchAccrualAndOperations,
        getAccrualAndOperations,
        getAccrualAndOperation,
    };

    return (
        <AccrualAndOperationsContext.Provider value={value}>
            {children}
        </AccrualAndOperationsContext.Provider>
    );
}

export const useAccrualAndOperationsContext = (): AccrualAndOperationsContext => {
    return useContext(AccrualAndOperationsContext);
}
