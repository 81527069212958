import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ChangeOrderCounterClaim, ActiveState } from "../../../contracts/contracts";

export const QUERY_CHANGE_ORDER_COUNTER_CLAIM: DocumentNode = gql`
    query changeOrderCounterClaims(
            $id: UUID
            $state: ActiveState
            $projectId: UUID
            $contractId: UUID
            $accountId: UUID
            $name: String
            $group: String
            $sent: DateTime
            $answered: DateTime
            $changeOrderDescription: String
            $changeOrderComment: String
            $contractor: String
            $costRequirements: Float
            $cost: Float
            $status: ChangeOrderStatus
            $progressConsequenceEnabled: Boolean
            $progressConsequenceDescription: String
        ) {
        changeOrderCounterClaims(
            id: $id
            state: $state
            projectId: $projectId
            contractId: $contractId
            accountId: $accountId
            name: $name
            group: $group
            sent: $sent
            answered: $answered
            changeOrderDescription: $changeOrderDescription
            changeOrderComment: $changeOrderComment
            contractor: $contractor
            costRequirements: $costRequirements
            cost: $cost
            status: $status
            progressConsequenceEnabled: $progressConsequenceEnabled
            progressConsequenceDescription: $progressConsequenceDescription
        ) {
            id
            documentType
            stateTime
            state
            created
            changedBy
            projectId
            contractId
            accountId
            contractName
            name
            group
            sent
            answered
            changeOrderDescription
            changeOrderComment
            contractor
            costRequirements
            cost
            sumInvoicedAmount
            remainingCost
            status
            progressConsequenceEnabled
            progressConsequenceDescription
        }
    }
`;

export const getChangeOrderCounterClaimsGraphqlQueryOptions = (changeOrderCounterClaim: Partial<ChangeOrderCounterClaim>): QueryHookOptions => {
    changeOrderCounterClaim.state = changeOrderCounterClaim.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (changeOrderCounterClaim.id !== undefined) options.variables['id'] = changeOrderCounterClaim.id;
    if (changeOrderCounterClaim.state !== undefined) options.variables['state'] = changeOrderCounterClaim.state;
    if (changeOrderCounterClaim.projectId !== undefined) options.variables['projectId'] = changeOrderCounterClaim.projectId;
    if (changeOrderCounterClaim.contractId !== undefined) options.variables['contractId'] = changeOrderCounterClaim.contractId;
    if (changeOrderCounterClaim.accountId !== undefined) options.variables['accountId'] = changeOrderCounterClaim.accountId;
    if (changeOrderCounterClaim.name !== undefined) options.variables['name'] = changeOrderCounterClaim.name;
    if (changeOrderCounterClaim.sent !== undefined) options.variables['sent'] = changeOrderCounterClaim.sent.toISOString();
    if (changeOrderCounterClaim.changeOrderDescription !== undefined) options.variables['changeOrderDescription'] = changeOrderCounterClaim.changeOrderDescription;
    if (changeOrderCounterClaim.changeOrderComment !== undefined) options.variables['changeOrderComment'] = changeOrderCounterClaim.changeOrderComment;
    if (changeOrderCounterClaim.contractor !== undefined) options.variables['contractor'] = changeOrderCounterClaim.contractor;
    if (changeOrderCounterClaim.costRequirements !== undefined) options.variables['costRequirements'] = changeOrderCounterClaim.costRequirements;
    if (changeOrderCounterClaim.cost !== undefined) options.variables['cost'] = changeOrderCounterClaim.cost;
    if (changeOrderCounterClaim.status !== undefined) options.variables['status'] = changeOrderCounterClaim.status;
    if (changeOrderCounterClaim.progressConsequenceEnabled !== undefined) options.variables['progressConsequenceEnabled'] = changeOrderCounterClaim.progressConsequenceEnabled;
    if (changeOrderCounterClaim.progressConsequenceDescription !== undefined) options.variables['progressConsequenceDescription'] = changeOrderCounterClaim.progressConsequenceDescription;

    return options;
}