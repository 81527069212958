import { makeStyles, Theme, Tooltip, withTheme } from "@material-ui/core";
import { Info, Warning } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    infoIcon: {
        color: theme.palette.info.main
    },
    warningIcon: {
        color: theme.palette.warning.main
    },
    errorIcon: {
        color: theme.palette.error.main
    },
    root: {
        display: 'inline-flex',
        alignItems: 'center'
    }
}));

type props = {
    status?: "info" | "warning" | "error";
    placement?: "left" | "right";
    tooltip?: string;
    hidden?: boolean;
}

const StatusTooltip: React.FC<props> = ({
    status,
    placement,
    tooltip,
    hidden,
    children
}) => {

    status = status ?? "info";
    placement = placement ?? "left";

    const classes = useStyles();

    const getIcon = () => {
        switch (status) {
            case "warning": return (<Warning className={classes.warningIcon} />);
            case "error": return (<Warning className={classes.errorIcon} />);
        }
        return (<Info className={classes.infoIcon} />);
    }

    const getIconWithTooltip = () => {
        const title = tooltip ?? "";
        return (<Tooltip title={title}>
            {getIcon()}
        </Tooltip>);
    }

    return (
        <div className={classes.root}>
            {!hidden && placement === "left" && getIconWithTooltip()}
            {!hidden && placement === "left" && <>&nbsp;</>}
            {children && children}
            {!hidden && placement === "right" && <>&nbsp;</>}
            {!hidden && placement === "right" && getIconWithTooltip()}
        </div>
    )
}

export default withTheme(StatusTooltip);