import { Divider, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Theme, Typography, withTheme } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { ReactElement, useState } from "react";
import { useChangeOrderCounterClaimContext } from "../../contexts/changeOrderCounterClaim/changeOrderCounterClaimContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useMaterialTableLanguageContext } from "../../contexts/language/MaterialTableLanguageContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { ChangeOrderCounterClaim, ChangeOrderStatus, Contract, ElementStatusEnum } from "../../contracts/contracts";
import { getRenderedElementStatus } from "../../utils/statusToPalettes";
import EditChangeOrderCounterClaimDialog from "./EditChangeOrderCounterClaimDialog";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '1em'
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    button: {
        margin: theme.spacing(1),
    },
    root: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
      },
}));

type props = {
    selectedContract: Contract | undefined;
    selectedStatus: ChangeOrderStatus | undefined;
    onSelectedChangeOrderCounterClaimChange?: (newSelectedChangeOrderCounterClaim: ChangeOrderCounterClaim | undefined) => void;
}

const ChangeOrderCounterClaimMobileList: React.FC<props> = ({
    selectedContract,
    selectedStatus,
    onSelectedChangeOrderCounterClaimChange }) => {

    const classes = useStyles();

    const changeOrderCounterClaimContext = useChangeOrderCounterClaimContext();
    const languageContext = useLanguageContext();
    const { getLocalization } = useMaterialTableLanguageContext();
    const themeContext = useThemeContext();
    const theme = themeContext.getTheme();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [changeOrderCounterClaimToEdit, setChangeOrderCounterClaimToEdit] = useState<ChangeOrderCounterClaim>({});
    const changeOrderCounterClaims = changeOrderCounterClaimContext.getChangeOrderCounterClaims(selectedContract?.id, selectedStatus);

    const openEditChangeOrderCounterClaimDialog = (changeOrderCounterClaimToEdit: ChangeOrderCounterClaim) => {
        setChangeOrderCounterClaimToEdit({...changeOrderCounterClaimToEdit});
        setShowDialog(true);
    };

    const onCloseChangeOrderCounterClaimDialog = () => {
        setShowDialog(false);
    };

    const fabs = 
        {
          color: 'primary' as 'primary',
          className: classes.fab,
          icon: <AddIcon />,
          label: 'Add',
        }

    function data(changeOrderCounterClaims: ChangeOrderCounterClaim[]): ReactElement {
        let buffer = [];
        for (var i=0; i<changeOrderCounterClaims.length; i++) {
                buffer.push(<>
                <ListItem>
                    <ListItemAvatar>
                        {getRenderedElementStatus(changeOrderCounterClaims[i]?.elementStatus ?? ElementStatusEnum.ALERT, theme)}
                    </ListItemAvatar>
                    <ListItemText primary={changeOrderCounterClaims[i].name} secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            >
                        
                        </Typography>
                    {changeOrderCounterClaims[i].changeOrderDescription?.substring(0,50)} ...
                    <Typography>
                    Kost: {changeOrderCounterClaims[i].cost} NOK
                    </Typography>
                    </React.Fragment> 
                        } />
                 </ListItem>
                 <Divider variant="inset" component="li" />
                 </>)
        }

        return <>{buffer}</>
    }  


    return (<>
        <EditChangeOrderCounterClaimDialog 
            changeOrderCounterClaim={changeOrderCounterClaimToEdit} 
            open={showDialog} 
            onClose={onCloseChangeOrderCounterClaimDialog} />
        <List className={classes.root}>
            {data(changeOrderCounterClaims)}
        </List>
        <Fab aria-label={'Add'} className={classes.fab} color={'primary'} onClick={() => openEditChangeOrderCounterClaimDialog({
                        contractId: selectedContract?.id,
                        status: selectedStatus,
                    })}>
            {<AddIcon />}
        </Fab>
    </>
    );
}

export default withTheme(ChangeOrderCounterClaimMobileList);