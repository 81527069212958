import { Grid, Paper, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { VisibilityOff, Equalizer, VerifiedUser, Loop } from "@material-ui/icons";
import { ToggleButton } from "@material-ui/lab";
import React, { useState } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { useThemeContext } from "../../contexts/theme/ThemeContext";
import { displayCurrency } from "../currencyComponents/CurrencyFormat";


const ProjectForecastViewer: React.FC<{}> = () => {
  const languageContext = useLanguageContext();
  const projectContext = useProjectContext();
  const project = projectContext.getSelectedProject();

  const [showInfo, setShowInfo] = useState<boolean>(false);
  const theme = useThemeContext().getTheme();

  return (
    <>
      <ToggleButton
        style={{ position: "absolute", bottom: 16, right: 20, zIndex: 10000, backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, opacity: 0.95 }}
        onClick={() => setShowInfo(!showInfo)}
        value={showInfo}
      >
        {showInfo ? <VisibilityOff /> : <Equalizer />}
      </ToggleButton>
      {showInfo && (
        <div
          style={{
            position: "absolute",
            bottom: 16,
            right: 80,
            zIndex: 10000,
            opacity: 0.95,
          }}
        >
          <div>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <Paper elevation={4} style={{ padding: "1em" }}>
                  <Grid container direction="row" spacing={1}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                              <Grid item>{project?.validateForecast ? <Loop style={{ color: "#FFA500" }} /> : <VerifiedUser style={{ color: "#31d655" }} />}</Grid>
                              {/* <Grid item>{projectForecast?.validating ? <CircularProgress size='small' /> : <VerifiedUser style={{ color: "#31d655" }} />}</Grid> */}
                              <Grid item>
                                <b>{languageContext.getMessage("forecastLong")}</b>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="right">{displayCurrency(project?.forecast?.forecast)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                              <Grid item>{project?.validateForecast ? <Loop style={{ color: "#FFA500" }} /> : <VerifiedUser style={{ color: "#31d655" }} />}</Grid>
                              {/* <Grid item>{projectForecast?.validating ? <CircularProgress size='small' /> : <VerifiedUser style={{ color: "#31d655" }} />}</Grid> */}
                              <Grid item>
                                <b>{languageContext.getMessage("revisedAccountBudget")}</b>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="right">{displayCurrency(project?.forecast?.revisedAccountBudget)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectForecastViewer;
