import { TextField, withTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { sortChangeOrderSubContractorByName, useChangeOrderSubContractorContext } from "../../contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { sortContractByName, useContractContext } from "../../contexts/contract/contractContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectContext } from "../../contexts/project/projectContext";
import { ChangeOrderSubContractor, Contract, RoleType } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { getEmptyGuid } from "../../utils/guidTools";
import { Autocomplete } from "@material-ui/lab";

type ContractOrChangeOrder = ChangeOrderSubContractor & Contract & {
    displayName: string;
};

type props = {
    accountId?: Guid,
    changeOrderId?: Guid,
    contractId?: Guid,
    onChange?: (contractId: Guid, changeOrderId: Guid) => void,
    dense?: boolean,
    readonly?: boolean,
    fullWidth?: boolean,
    maxWidth?: number,
    minWidth?: number,
};

const InvoiceChangeOrderSelect: React.FC<props> = ({
    accountId,
    changeOrderId,
    contractId,
    onChange,
    dense,
    readonly,
    fullWidth,
    maxWidth,
    minWidth,
}) => {

    const languageContext = useLanguageContext();
    const contractContext = useContractContext();
    const projectContext = useProjectContext();
    const changeOrderSubContractorContext = useChangeOrderSubContractorContext();

    let selectedId = "";
    if (changeOrderId && changeOrderId !== "" && changeOrderId !== getEmptyGuid()) {
        selectedId = changeOrderId;
    } else if (contractId && contractId !== "" && contractId !== getEmptyGuid()) {
        selectedId = contractId;
    } else {
        selectedId = "";
    }

    const contracts = contractContext.getContracts(accountId).sort(sortContractByName);
    let changeOrders = changeOrderSubContractorContext
        .getChangeOrderSubContractors(undefined, undefined)
        .filter(changeOrder => changeOrder.accountId === accountId).sort((a, b) => sortChangeOrderSubContractorByName(a, b, true));
    const contractsWithDisplayName: ContractOrChangeOrder[] = contracts.map(contract => { return { ...contract, displayName: `${languageContext.getMessage("contract")}:${contract.name}` } as ContractOrChangeOrder; });
    const changeOrdersWithDisplayName: ContractOrChangeOrder[] = changeOrders.map(changeOrder => { return { ...changeOrder, displayName: `${changeOrder.name} (${languageContext.getMessage("contract")}:${changeOrder.contractName})` } as ContractOrChangeOrder; });
    const contractOrChangeOrders: ContractOrChangeOrder[] = [...contractsWithDisplayName, ...changeOrdersWithDisplayName];
    const defaultContractOrChangeOrder: ContractOrChangeOrder = { id: "", displayName: ""};
    const selectedContractOrChangeOrder: ContractOrChangeOrder = contractOrChangeOrders.find(contractOrChangeOrder => contractOrChangeOrder.id === selectedId) ?? defaultContractOrChangeOrder;

    useEffect(() => {
    }, [changeOrderId, contractId]);

    const handleChange = (newValue: Guid) => {
        var id = newValue;
        var idIsContractId = contracts.filter(contract => contract.id === id).length > 0;
        var idIsChangeOrderId = changeOrders.filter(changeOrder => changeOrder.id === id).length > 0;

        if (onChange) {
            if (idIsContractId) {
                onChange(id, "");
            } else if (idIsChangeOrderId) {
                onChange("", id);
            } else {
                onChange("", "");
            }
        }
    };

    const getText = (): string => {
        const changeOrderList = changeOrders.filter(changeOrder => changeOrder.id === selectedId);
        if (changeOrderList.length > 0) {
            return changeOrderList[0].name
                ? `${languageContext.getMessage("changeOrderShort")}:${changeOrderList[0].name}`
                : "";
        }

        const contractList = contracts.filter(contract => contract.id === selectedId);
        if (contractList.length > 0) {
            return contractList[0].name
                ? `${languageContext.getMessage("contract")}:${contractList[0].name}`
                : "";

        }

        return languageContext.getMessage('notSelected');
    }

    const renderText = () => {
        return (<TextField
            label={languageContext.getMessage("contractOrChange")}
            value={getText()}
            fullWidth={fullWidth}
        />);
    };

    const renderSelect = () => <Autocomplete
        options={contractOrChangeOrders}
        getOptionLabel={(changeOrderSubContractorGroup) => changeOrderSubContractorGroup.displayName ?? ''}
        value={selectedContractOrChangeOrder}
        onChange={(event, newSelectedContractOrChangeOrder) => handleChange(newSelectedContractOrChangeOrder?.id ?? "")}
        fullWidth={fullWidth}
        style={{ maxWidth: maxWidth ?? 500, minWidth: minWidth ?? 300 }}
        disabled={!projectContext.hasProjectAccess(RoleType.WRITER)}
        renderInput={(params) => <TextField {...params}
            label={languageContext.getMessage("contractOrChange")}
            variant="standard" />}
    />

    return (<>
        {(!readonly && !dense) && <>{renderSelect()}</>}
        {(readonly && !dense) && renderText()}
        {dense && renderSelect()}
    </>);
}

export default withTheme(InvoiceChangeOrderSelect);