import { InterimExpense } from "../../contracts/contracts";
import { capitalizeString } from "../../utils/stringTools";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

export type InterimExpenseWithAccumulatedTag = InterimExpense & {
    accumulated: boolean;
}

const INTERIM_PROJECT_LIST_TEMPLATE_FILENAME = 'aimz/template_interim_project_list.xlsx'
const INTERIM_PROJECT_LIST_TEMPLATE_SHEET_NAME = 'interim_project_list'

export const queryTemplateEngineToProduceInterimProjectExcelList = (
    interims: InterimExpenseWithAccumulatedTag[], 
    templateEngineQueriesContext: TemplateEngineQueriesContext, 
    languageContext: LanguageContext): void => {
    const templateFilename: string = INTERIM_PROJECT_LIST_TEMPLATE_FILENAME;
    const templateVariables: object[] = getTemplateEngineInterimProjectListReportVariables(interims, languageContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, `${languageContext.getMessage('project')} ${languageContext.getMessage('interim')}`);
}

const getTemplateEngineInterimProjectListReportVariables = (
    interims: InterimExpenseWithAccumulatedTag[], 
    languageContext: LanguageContext): object[] => {
    const date: Date = (interims.length > 0 && interims[0].date) ? interims[0].date : new Date();
    const title: string = `${languageContext.getMessage('project')} ${languageContext.getMessage('interim')}`;
    const month: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long' }));
    const monthString: string = `${month} ${date.getFullYear()}`;
    return [{
        'tpl_name': INTERIM_PROJECT_LIST_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': getInterimProjectListVariables(title, monthString, interims),
    }]
}

const getInterimProjectListVariables = (
    title: string, 
    monthString: string, 
    interims: InterimExpenseWithAccumulatedTag[]): object => {
    const listVariables = {
        'language': {},
        'title': title,
        'date': monthString,
        'monthlyInterims': interims.filter(interim => interim.accumulated === false),
        'accumulatedInterims': interims.filter(interim => interim.accumulated === true),
    }
    return listVariables;
} 
