import { Guid } from "../utils/common-types"

export type TemplateEngineResult = {
    templatedResult?: string;
    templatedFileType?: string;
    outputFilename?: string;
}

export type StateDocument = {
    id?: Guid;
    documentType?: DocumentType;
    state?: ActiveState;
    created?: Date;
    changed?: Date;
    changedBy?: string;
    stateTime?: Date;
    content?: string;
    changes?: string;
    events?: string;
}

export type StateDocumentEvents = {
    id?: Guid;
    documentType?: DocumentType;
    state?: ActiveState;
    created?: Date;
    changed?: Date;
    changedBy?: string;
    stateTime?: Date;
    content?: string;
    changes?: string;
    events?: object[];
}

export type StorageDocument = {
    id?: Guid;
    documentType?: DocumentType;
    state?: ActiveState;
    created?: Date;
    changed?: Date;
    changedBy?: string;
    stateTime?: Date;
    content?: string;
    changes?: string;
}

export type Ticket = {
    id?: Guid;
    parentDocumentId?: Guid;
    parentDocumentType?: DocumentType;
    processTag?: ProcessTag;
}

export type AccrualAndOperations = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    parentDocumentId?: Guid;
    parentDocumentType?: DocumentType;
    fromDate?: Date;
    toDate?: Date;
    firstDate?: Date;
    lastDate?: Date;
    fromDateIsEmpty?: boolean;
    toDateIsEmpty?: boolean;
    expectedPostedTotalExpense?: number;
    actuallyPostedTotalExpense?: number;
    sumExpenseForecasts?: number;
    remainingForecastsToDistribute?: number;
    monthlyPostedExpenseItems?: AccrualAndOperationPostedExpense[];
    projectId?: Guid;
    accountId?: Guid;
}

export type AccrualAndOperationPostedExpense = {
    date?: Date;
    expectedPostedExpense?: number;
    expectedAccumulatedPostedExpense?: number;
    actuallyPostedExpense?: number;
    actuallyAccumulatedPostedExpense?: number;
    revisedForecast?: number;
    revisedForecastIsAboveAccountForecast?: boolean;
    disabled?: boolean;
    projectId?: Guid;
    accountId?: Guid;
}

export type AccrualAndOperationsExpenses = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    projectId?: Guid;
    accountId?: Guid;
    name?: string;
    fromDate?: Date;
    toDate?: Date;
    forecast?: number;
    remainingForecastsToDistribute?: number;
    monthlyExpenseItems?: AccrualAndOperationExpense[];
}

export type AccrualAndOperationExpense = {
    date?: Date;
    expense?: number;
    disabled?: boolean;
    projectId?: Guid;
    accountId?: Guid;
}

export type Forecast = {
    status?: HealthStatus;
    date?: Date;
    accountGroup?: string;
    accountBudget?: number;
    contractBudget?: number;
    contractProcurement?: number;
    contractBudgetUsedInCostScope?: number;
    contractProcurementUsedInCostScope?: number;
    contractCostScope?: number;
    contractCostScopeUsedInForecast?: boolean;
    sumContractInvoicedAmount?: number;
    sumContractInvoicedWithheldAmount?: number;
    changeOrderSubContractorCosts?: number;
    changeOrderSubContractorApprovedCosts?: number;
    changeOrderSubContractorNotApprovedCosts?: number;
    changeOrderLandlordCosts?: number;
    changeOrderLandlordApprovedCosts?: number;
    changeOrderLandlordNotApprovedCosts?: number;
    changeOrderExpectedCosts?: number;
    changeOrderCounterClaimCosts?: number;
    netAccountBudget?: number;
    revisedAccountBudget?: number;
    riskAdjustedCosts?: number;
    adjustmentCosts?: number;
    forecast?: number;
    riskAdjustedForecast?: number;
    riskAdjustedStatus?: HealthStatus;
    forecastWithSurcharge?: number;
    projectSurcharge?: number;
    surchargePercent?: number;
    projectId?: Guid;
    accountId?: Guid;
}

export type ForecastReport = {
    parentDocumentId?: Guid;
    parentDocumentType?: DocumentType;
    date?: Date;
    group?: string;
    budget?: number;
    revisedBudget?: number;
    forecast?: number;
    forecastDeviationFromBudget?: number;
    previousForecast?: number;
    forecastChange?: number;
    forecastWithSurcharge?: number;
    projectSurcharge?: number;
    surchargePercent?: number;
    projectBudgetSurcharge?: number;
    projectRevisedBudgetSurcharge?: number;
    projectForecastDeviationSurcharge?: number;
    projectPreviousForecastSurcharge?: number;
    projectForecastChangeSurcharge?: number;
    projectBudgetWithSurcharge?: number;
    projectRevisedBudgetWithSurcharge?: number;
    projectForecastDeviationWithSurcharge?: number;
    projectPreviousForecastWithSurcharge?: number;
    projectForecastChangeWithSurcharge?: number;
    projectId?: Guid;
    accountId?: Guid;
}

export type Forecasts = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    changed?: Date;
    parentDocumentId?: Guid;
    parentDocumentType?: DocumentType;
    fromDate?: Date;
    toDate?: Date;
    forecasts?: Array<Forecast>;
    forecastsGroups?: Array<Array<Forecast>>;
    monthlyForecastReports?: Array<Array<ForecastReport>>;
    monthlyForecastGroupsReports?: Array<Array<Array<ForecastReport>>>;
    projectId?: Guid;
    accountId?: Guid;
}

export type Project = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    changed?: Date;
    name?: string;
    externalProvider?: string;
    externalId?: string;
    validateForecast?: boolean;
    forecast?: Forecast;
    activeForecastGroups?: string[];
    forecastGroups?: Forecast[];
    forecastReport?: ForecastReport;
    forecastGroupsReport?: ForecastReport[];
    interimExpense?: InterimExpense;
    projectStart?: Date;
    projectEnd?: Date;
    surchargePercent?: number;
    riskConsequenceLimit?: number;
}

export type ProjectSettings = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
}

export type GanttItem = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    name?: string;
    fromDate?: Date;
    toDate?: Date;
    ganttChild?: GanttItem[];
}

export type ChangeOrderSubContractor = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    changedBy?: string;
    projectId?: Guid;
    contractId?: Guid;
    changeOrderExpectedId?: Guid;
    contractName?: string;
    accountId?: Guid;
    name?: string;
    group?: string;
    received?: Date;
    answered?: Date;
    changeOrderDescription?: string;
    changeOrderComment?: string;
    contractor?: string;
    costRequirements?: number;
    cost?: number;
    billed?: number;
    sumInvoicedAmount?: number;
    sumInvoicedWithheldAmount?: number;
    remainingCost?: number;
    status?: ChangeOrderStatus;
    elementStatus?: ElementStatusEnum;
    progressConsequenceEnabled?: boolean;
    progressConsequenceDescription?: string;
}

export type ChangeOrderLandlord = {
    id?: Guid;
    parentId?: Guid;
    state?: ActiveState;
    created?: Date;
    changedBy?: string;
    projectId?: Guid;
    accountId?: Guid;
    relationType?: RelationType;
    name?: string;
    group?: string;
    sent?: Date;
    answered?: Date;
    changeOrderDescription?: string;
    changeOrderComment?: string;
    costRequirements?: number;
    cost?: number;
    costSurcharge?: number;
    costWithSurcharge?: number;
    billed?: number;
    remainingBilled?: number;
    status?: ChangeOrderStatus;
    progressConsequenceEnabled?: boolean;
    progressConsequenceDescription?: string;
}

export type ChangeOrderExpected = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    changedBy?: string;
    projectId?: Guid;
    accountId?: Guid;
    contractId?: Guid;
    name?: string;
    group?: string;
    expected?: Date;
    changeOrderDescription?: string;
    changeOrderComment?: string;
    cost?: number;
    adjustedCost?: number;
    status?: ChangeOrderStatus;
    elementStatus?: ElementStatusEnum;
    progressConsequenceEnabled?: boolean;
    progressConsequenceDescription?: string;
}

export type ChangeOrderCounterClaim = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    changedBy?: string;
    projectId?: Guid;
    contractId?: Guid;
    contractName?: string;
    accountId?: Guid;
    name?: string;
    group?: string;
    sent?: Date;
    answered?: Date;
    changeOrderDescription?: string;
    changeOrderComment?: string;
    contractor?: string;
    costRequirements?: number;
    cost?: number;
    billed?: number;
    sumInvoicedAmount?: number;
    remainingCost?: number;
    status?: ChangeOrderStatus;
    elementStatus?: ElementStatusEnum;
    progressConsequenceEnabled?: boolean;
    progressConsequenceDescription?: string;
}

export type Account = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    changedBy?: string;
    projectId?: Guid;
    name?: string;
    externalProvider?: string;
    externalId?: string;
    accountGroup?: string;
    accountType?: AccountType;
    accountNumber?: string;
    budget?: number;
    invoicedAmount?: number;
    invoicedWithheldAmount?: number;
    invoicedAmountTotal?: number;
    remainingBudget?: number;
    sumContractBudget?: number;
    sumContractProcurement?: number;
    sumContractCostScope?: number;
    sumContractRemainingBudget?: number;
    sumContractInvoicedAmount?: number;
    sumContractInvoicedWithheldAmount?: number;
    sumContractInvoicedAmountTotal?: number;
    sumChangeOrderSubContractorCost?: number;
    sumChangeOrderSubContractorRemainingCost?: number;
    sumChangeOrderSubContractorInvoicedAmount?: number;
    sumChangeOrderSubContractorInvoicedWithheldAmount?: number;
    adjustmentCosts?: number;
    unforeseenBudget?: number;
    forecast?: Forecast;
    forecastReport?: ForecastReport;
    interimExpense?: InterimExpense;
    sumExpenseForecastsOnAccrualAndOperations?: number;
    remainingForecastsToDistributeOnAccrualAndOperations?: number;
    riskConsequenceLimit?: number;
    riskCostAdjustment?: number;
    comment?: string;
}


export type GroupedAccount = Account & {
    parentGroup?: string;
    interimExpense?: InterimExpense;
    numberOfAccountsInGroup?: number;
    accrualLastExpense?: AccrualAndOperationPostedExpense;
};

export type Interim = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    parentDocumentId?: Guid;
    parentDocumentType?: DocumentType;
    projectId?: Guid;
    fromDate?: Date;
    toDate?: Date;
    firstDate?: Date;
    lastDate?: Date;
    fromDateIsEmpty?: boolean;
    toDateIsEmpty?: boolean;
    monthlyInterimItems?: InterimExpense[];
}

export type InterimExpense = {
    date?: Date;
    accumulatedAccruedPosted?: number;
    receivedRegistered?: number;
    producedNotBilled?: number;
    accumulatedProduced?: number;
    constructionCosts?: number;
    administrationCosts?: number;
    surcharge?: number;
    accumulatedConstructionCosts?: number;
    accumulatedAdministrationCosts?: number;
    accumulatedSurcharge?: number;
    forecast?: number;
    producedPercentageOfForecast?: number;
    parentDocumentId?: Guid;
    parentDocumentType?: DocumentType;
}

export type AccountGroup = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    projectId?: Guid;
    name?: string;
    totalContractBudget?: number;
    totalContractProcurement?: number;
    totalRemainingContractBudget?: number;
    totalRemainingContractProcurement?: number;
}

export type Contract = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    changedBy?: string;
    projectId?: Guid;
    accountId?: Guid;
    name?: string;
    comment?: string;
    contractDescription?: string;
    budget?: number;
    adjustedBudget?: number;
    procurement?: number;
    contractCostScope?: number;
    sumInvoicedAmount?: number;
    sumInvoicedWithheldAmount?: number;
    unforeseenBudget?: number;
    remainingBudget?: number;
    supplier?: string;
    useContractProcurementWithForecast?: boolean;
    contractProcurementIsUsedWithForecast?: boolean;
    sumChangeOrderSubContractorCost?: number;
    sumChangeOrderSubContractorApprovedCost?: number;
    sumChangeOrderSubContractorRemainingCost?: number;
    sumChangeOrderSubContractorInvoicedAmount?: number;
    sumChangeOrderSubContractorInvoicedWithheldAmount?: number;
    sumChangeOrderExpectedCost?: number;
    sumChangeOrderCounterClaimCost?: number;
}

export type CrewList = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    parentDocumentId?: Guid;
    parentDocumentType?: DocumentType;
    fromDate?: Date;
    toDate?: Date;
    firstDate?: Date;
    lastDate?: Date;
    fromDateIsEmpty?: boolean;
    toDateIsEmpty?: boolean;
    constructionAdministrationBudget?: number;
    salaryAdjustmentPercentageDate?: Date;
    monthlyCrewDetails?: CrewDetails[];
    projectId?: Guid;
}

export type CrewDetails = {
    date?: Date;
    sumAssignedPercentages?: number;
    numberOfAvailableCrew?: number;
    expectedExpense?: number;
    expectedAccumulatedExpense?: number;
    actuallyPostedExpense?: number;
    actuallyAccumulatedPostedExpense?: number;
    revisedForecast?: number;
    revisedForecastIsAboveConstructionAdministrationBudget?: boolean;
    disabled?: boolean;
    projectId?: Guid;
}

export type CrewResource = {
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    projectId?: Guid;
    name?: string;
    fromDate?: Date;
    toDate?: Date;
    defaultCrewResourceDetails?: CrewResourceDetails;
    monthlyCrewResourceDetails?: CrewResourceDetails[];
}

export type CrewResourceDetails = {
    date?: Date;
    role?: CrewResourceRole;
    salaryAdjustmentPercentage?: number;
    assignmentPercentage?: number;
    salary?: number;
    disabled?: boolean;
    projectId?: Guid;
}


export type Invoice = {
    searchIndexStart?: number;
    searchIndexStop?: number;
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    projectId?: Guid;
    parentId?: Guid;
    invoiceIsSplit?: boolean;
    unknownVat?: boolean;
    unknownCurrency?: boolean;
    accountId?: Guid;
    contractId?: Guid;
    changeOrderId?: Guid;
    externalProvider?: string;
    externalId?: string;
    externalReferenceId?: string;
    externalIdMissingWithProvider?: boolean;
    externalAttachmentId?: string;
    externalProjectId?: string;
    externalAccountId?: string;
    paid?: boolean;
    postedStatus?: InvoicePostedStatus;
    approvedStatus?: InvoiceApprovedStatus;
    invoiceNumber?: string;
    accountNumber?: string;
    scannedDate?: Date;
    voucherDate?: Date;
    dueDate?: Date;
    interimAccounted?: boolean;
    interimAccountedDate?: Date;
    interimAccountedAmount?: number;
    interimAccountedWithheldAmount?: number;
    amount?: number;
    withheldAmount?: number;
    vatCode?: string;
    comments?: string[];
    supplier?: string;
}

export type Risk = {
    id?: Guid;
    state?: ActiveState;
    documentType?: DocumentType;
    created?: Date;
    changed?: Date;
    changedBy?: string;
    changedByUsername?: string;
    projectId?: Guid;
    accountId?: Guid;
    name?: string;
    comment?: string;
    changeComment?: string;
    riskType?: RiskType;
    riskVariable?: ProbType;
    consequence?: number;
    consequenceMin?: number;
    consequenceMax?: number;
    consequenceMatrix?: number;
    consequenceType?: ConsequenceType;
    probabilityType?: ProbType;
    probability?: number;
    probabilityMin?: number;
    probabilityMax?: number;
    probabilityMatrix?: number;
    riskCost?: number;
    riskCostVar?: number;
    riskCostDistribution?: number[];
    riskCostDistributionP?: number[];
    riskCostToForecast?: boolean;
    fromDate?: Date;
    toDate?: Date;
}

export type UserRole = {
    id?: Guid;
    name?: string;
    delete?: boolean;
}

export type User = {
    id?: Guid;
    name?: string;
    email?: string;
    roles?: UserRole[];
}

export type StorageFile = {
    delete?: boolean;
    searchIndexStart?: number;
    searchIndexStop?: number;
    includeContent?: boolean;
    searchKey?: String;
    id?: Guid;
    key?: string;
    changed?: string;
    changedBy?: string;
    metaData?: string;
    content?: string;
}

export type ChangeLog = {
    id?: Guid;
    documentType?: DocumentType;
    documentId?: string;
    changeType?: ChangeType;
    changedBy?: string;
    timeStamp?: Date;
    content?: string;
}

export enum ChangeType {
    CREATE = 'CREATE',
    CHANGE = 'CHANGE',
}


export enum RoleType {
    READER = 'reader',
    WRITER = 'writer',
    ADMIN = 'admin',
    OWNER = 'owner',
}


export enum CurrencyType {
    NOK = 'NOK',
    US_DOLLAR = 'US_DOLLAR',
    UNDEFINED = 'UNDEFINED',
}


export enum ActiveState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum AccountType {
    NORMAL = 'NORMAL',
    ACCRUAL = 'ACCRUAL',
    ADMINISTRATION = 'ADMINISTRATION',
}

export enum TransactionTypeEnum {
    CONTRACT_BUDGET = 'CONTRACT_BUDGET',
    CONTRACT_PROCUREMENT = 'CONTRACT_PROCUREMENT',
    CHANGE_ORDER = 'CHANGE_ORDER',
    CHANGE_ORDER_SUBCONTRACTOR = 'CHANGE_ORDER_SUBCONTRACTOR',
    ADJUSTMENT = 'ADJUSTMENT',
}

export enum HealthStatus {
    HEALTHY = 'HEALTHY',
    DEGRADED = 'DEGRADED',
    UNHEALTHY = 'UNHEALTHY',
}

export enum RelationType {
    PARENT = 'PARENT',
    CHILD = 'CHILD'
}

export enum ChangeOrderStatus {
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
    NOT_PROCESSED = 'NOT_PROCESSED',
}

export enum ElementStatusEnum {
    INFORMATION = 'INFORMATION',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    CANCEL = 'CANCEL',
    ALERT = 'ALERT',
}

export enum RiskType {
    OPPORTUNITY = 'OPPORTUNITY',
    RISK = 'RISK',
}

export enum ProbType {
    PROBABILITY = 'PROBABILITY',
    QUANTITIES = 'QUANTITIES',
}

export enum ConsequenceType {
    COST = 'COST',
    TIME = 'TIME',
}

export enum CrewResourceRole {
    PROJECT_CHIEF = 'PROJECT_CHIEF',
    PROJECT_MANAGER = 'PROJECT_MANAGER',
    PROJECT_MANAGER_ASSISTANT = 'PROJECT_MANAGER_ASSISTANT',
    CONSTRUCTION_MANAGER = 'CONSTRUCTION_MANAGER',
    OPERATIONS_MANAGER = 'OPERATIONS_MANAGER',
    PROJECT_SECRETARY = 'PROJECT_SECRETARY',
    ENVIRONMENTAL_MANAGER = 'ENVIRONMENTAL_MANAGER',
    HEALTH_SAFETY_AND_ENVIRONMENT_LEADER = 'HEALTH_SAFETY_AND_ENVIRONMENT_LEADER',
    ENGINEERING_MANAGER = 'ENGINEERING_MANAGER',
    TECHNICAL_ENGINEERING_MANAGER = 'TECHNICAL_ENGINEERING_MANAGER',
    INTEGRATED_TECHNICAL_BUILDING_INSTALLATIONS_RESPONSIBLE = 'INTEGRATED_TECHNICAL_BUILDING_INSTALLATIONS_RESPONSIBLE',
    CONTROLLER = 'CONTROLLER',
    PURCHASER = 'PURCHASER',
    OTHER = 'OTHER',
}

export enum DocumentType {
    PROJECT = 'PROJECT',
    ACCOUNT = 'ACCOUNT',
    CONTRACT = 'CONTRACT',
    INVOICE = 'INVOICE',
    CHANGE_ORDER_SUB_CONTRACTOR = 'CHANGE_ORDER_SUB_CONTRACTOR',
    CHANGE_ORDER_LANDLORD = 'CHANGE_ORDER_LANDLORD',
    CHANGE_ORDER_EXPECTED = 'CHANGE_ORDER_EXPECTED',
    CHANGE_ORDER_COUNTER_CLAIM = 'CHANGE_ORDER_COUNTER_CLAIM',
    RISK = 'RISK',
    INTERIM = 'INTERIM',
    CREW_LIST = 'CREW_LIST',
    CREW_RESOURCE = 'CREW_RESOURCE',
    ACCRUAL_AND_OPERATIONS = 'ACCRUAL_AND_OPERATIONS',
    ACCRUAL_AND_OPERATIONS_EXPENSES = 'ACCRUAL_AND_OPERATIONS_EXPENSES',
    FORECASTS = 'FORECASTS',
    GENERAL = 'GENERAL',
}

export enum ProcessTag {
    NORMAL = 'NORMAL',
    CALCULATIONS = 'CALCULATIONS',
    HEAVY_CALCULATIONS = 'HEAVY_CALCULATIONS',
}

export enum InvoicePostedStatus {
    NOT_POSTED = 'NOT_POSTED',
    POSTED = 'POSTED',
    PARTLY_POSTED = 'PARTLY_POSTED',
}

export enum InvoiceApprovedStatus {
    NOT_DISTRIBUTED = 'NOT_DISTRIBUTED',
    DISTRIBUTED = 'DISTRIBUTED',
    APPROVED = 'APPROVED',
    RETURNED = 'RETURNED',
    REJECTED = 'REJECTED',
}

export enum InvoiceFlag {
    UNKNOWN_VAT = 'UNKNOWN_VAT',
    UNKNOWN_CURRENCY = 'UNKNOWN_CURRENCY',
    EXTERNAL_ID_MISSING_WITH_PROVIDER = 'EXTERNAL_ID_MISSING_WITH_PROVIDER',
}

export type InvoiceFilter = {
    approved?: boolean;
    showInterimAccountedInvoices?: boolean;
    showProcessedInvoices?: boolean;
    showRejectedInvoices?: boolean;
    fromDate?: Date;
    toDate?: Date;
    supplierFilter?: string[];
    accountIdFilter?: string[];
    contractIdFilter?: string[];
    changeOrderSubContractorIdFilter?: string[];
    externalProjectIdFilter?: string[];
    externalProviderFilter?: string[];
    accountId?: Guid;
    contractId?: Guid;
    changeOrderId?: Guid;
    invoiceFlags?: InvoiceFlag[];
}